import React, { useState, useContext } from 'react';
import { Popover, Button } from '@didi/ec-base';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import { PublicStore, recordTrackEvent } from '../../../../utils/utils';
import classNames from 'classnames';
import useStyle from './styles';

const CycleContent = () => {
  // 样式
  const prefixCls = 'cycle-content';
  const wrapSSR = useStyle('cycle-content');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, props } = useContext(PublicStore);
  const { apolloConfig, assessmentInfo } = stateStore;
  const { pageLetInfo } = apolloConfig;
  const { BeforeCompanyAudit, AfterCompanyAudit } = pageLetInfo;
  const { stateCode } = assessmentInfo;
  const {
    params: { apiHost },
  } = props;
  // 卡片状态
  const [open, setOpen] = useState(false);
  // 打开变更
  const handleOpenChange = newOpen => setOpen(newOpen);
  // 关闭卡片
  const hide = () => setOpen(false);
  // 跳转绩效
  const jumpEvent = () => {
    window.open(`${apiHost}${'/new/perf/workTeam/managementTeams'}`, '_blank');
    if (apiHost.includes('o.didichuxing.com')) {
      recordTrackEvent(); // 记录埋点
    }
  };

  return wrapSSR(
    <div className={classes}>
      <div className="text">
        {(stateCode <= 150 ? BeforeCompanyAudit : AfterCompanyAudit)?.map((item, index) => (
          <p key={index}>
            {item?.content}
            {item?.type === 'href' && (
              <Popover
                overlayClassName="viewRhythm"
                placement="bottomLeft"
                trigger="click"
                content={<>
                  <CloseOutlined
                    onClick={hide}
                    style={{ position: 'absolute', top: '15px', right: '15px', fontSize: '20px' }}
                  />
                  <img src={item?.url} alt="" className="src" />
                </>}
                open={open}
                onOpenChange={handleOpenChange}
              >
                <div className="popoverBtn">
                  {item?.urlText}
                  <img src='//img-ys011.didistatic.com/static/ddo_web_static/do1_ByWnfLYe1Ja4xpG96jv9' />
                </div>
              </Popover>
            )}
          </p>
        ))}
      </div>
      <div className="jumpButton">
        <Button type="primary" onClick={jumpEvent}>
          前往绩效
        </Button>
      </div>
    </div>
  );
};

export default CycleContent;
