import React, { useContext, useMemo, useState } from 'react';
import { Dropdown } from '@didi/ec-base';
import { PublicStore } from '../../utils/utils';
import classNames from 'classnames';
import useStyle from './styles';

const PageBottom = () => {
  // 样式
  const prefixCls = 'supported-pagelet-Bottom';
  const wrapSSR = useStyle('supported-pagelet-Bottom');
  const classes = classNames(prefixCls);
  // 状态
  const [hoverState, setHoverState] = useState(false);
  const { stateStore } = useContext(PublicStore);
  const { apolloConfig } = stateStore;
  const { helpInfo } = apolloConfig
  const { name, options } = helpInfo;

  const items = useMemo(() => {
    return options?.map(item => ({
      key: item?.label,
      label: <a target="_blank" rel="noopener noreferrer" href={item?.url}>
        {item?.label}
      </a>
    }))
  }, [options]);

  if (!items) {
    return null;
  }

  return wrapSSR(
    <div className={classes}>
      <Dropdown menu={{ items }} arrow={false}>
        <a
          onClick={e => e.preventDefault()}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
        >
          <p>{name}</p>
          {hoverState
            ? <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_W6nUTG5j6mhVvHuulVdM'} />
            : <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_29ftiY2RZCRT98ssn4hu'} />
          }
        </a>
      </Dropdown>
    </div>,
  );
};

export default PageBottom;
