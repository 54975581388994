import React from "react";
import { di18n } from '@ultra/common';
import './index.scss';
const { intl } = di18n;


export default function NoPermission(props) {
  return (
    <div className="ct-not-permission-wrap">
      <div className="ct-component-not-permission">
        {
          <>
            <div>
              <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/ct/NoPermission.png" />
            </div>
            <div>{intl.t('当前用户暂无使用权限')}</div>
          </>
        }
      </div>
    </div>
  );
};
