import React from 'react';
import useStyle from './style';

export default ({ text, statusCode }) => {

  const prefixCls = 'approval-list-status';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(<div className='approval-list-status'>
    <span className={`status-dot status-dot-${statusCode}`} />
    {text}
  </div>);
}
