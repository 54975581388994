import React, { useState, useEffect, useRef } from 'react';
import { List, Checkbox, TextareaItem } from 'antd-mobile';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { di18n, CardIcon } from '@ultra/common';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.scss';
import {
  setGeneralData,
  setValidateResult,
  updateAttributeData,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject,
  setPrevOptionIds,
  setCurOptionIds
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
import { textHeight } from '../../utils/const';

const { intl } = di18n;
const CheckboxItem = Checkbox.CheckboxItem;


const radioWidgetMobile = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    validateFlag,
    currentRespondentCode,
    surveyBusType
  } = useSelector((state) => state.questionContent);
  // console.log('processRate', processRate);
  // console.log('validateRules', validateRules);
  // 处理失焦和勾选的时差。当为失焦时，延迟200ms 200ms内存在勾选 不执行失焦函数
  let blurTimer = useRef(false);

  const dispatch = useDispatch();

  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    mDirection,
    effectOption,
    options,
    hasSerialNumber
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];
  const otherRules = rules.otherRules;
  const effect = effectData[widgetKey];

  const [radioValue, setRadioValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [inputValueMap, setInputValueMap] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);

  const [expand, setExpand] = useState(true);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData && draftData[widgetKey]) {
      const widgetDraftData = draftData[widgetKey];
      const draftItem = widgetDraftData[currentRespondentCode];
      let inputValueMap = {};
      other.map((otherItem, i) => {
        inputValueMap[otherItem] = '';
      })
      if (draftItem.length > 0) {
        const radio = draftItem[0].option;
        const input = draftItem[0].value;
        setRadioValue(radio);
        setInputValue(input);
        if (effectOption.length > 0) {
          effectFun(radio);
        }
        if (other.includes(radio)) {
          inputValueMap[radio] = input;
        }

        // 初始化赋值&校验
        setValueAndValidate(draftItem);
      }
      setInputValueMap(inputValueMap);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (radioValue) {
        setRadioValue(null);
      }
      if (inputValue) {
        setInputValue(null);
      }
    }
  }, [hidden, initFlag, radioValue, inputValue])

  // 如果题目描述的高度超过了200，默认给收起
  useEffect(() => {
    if (tips && textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 200) {
      setExpand(false);
    }
  }, []);

  const setValueAndValidate = (paramData) => {
    const { option, value } = paramData[0];
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: paramData }));
    dispatch(updateCurrentSubject({ data: widgetKey }));
    // 数据校验
    const widgetValidate = validateFun(rules, paramData);
    let otherValidate;
    if (other.includes(option)) {
      otherValidate = { [option]: validateFun(otherRules[option], value, true) };
    }
    const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
    dispatch(setValidateResult({ id: widgetKey, data: validateData }));
  }

  const handleOnChange = (e) => {
    if (blurTimer.current) {
      blurTimer.current = false;
    };
    const option = e;
    setRadioValue(option);

    // 记录上次选择的id
    const previewOptionList = [];
    if (radioValue) {
      previewOptionList.push(radioValue);
      dispatch(setPrevOptionIds({ data: previewOptionList }));
    }

    // 记录本次选择的id
    const curOptionList = [];
    curOptionList.push(option);
    dispatch(setCurOptionIds({ data: curOptionList }));

    let value = '';
    if (other.includes(option) && inputValueMap) {
      value = inputValueMap[option] ? inputValueMap[option]?.replace(/(\s*$)/g, '') : '';
    }
    const data = [
      {
        option: option,
        value: value
      }
    ]
    setUpdateData(data);
    if (effectOption.length > 0) {
      effectFun(option);
    }

  }

  const handleChangeInput = (e, option) => {
    setInputValue(e.length > 2000 ? e.slice(0, 2000) : e);
    setInputValueMap({ ...inputValueMap, [option]: e.length > 2000 ? e.slice(0, 2000) : e })
  }
  const handleBlur = (v) => {
    blurTimer.current = true;
    setTimeout(() => {
      if (blurTimer.current) {
        const data = [
          {
            option: radioValue,
            value: inputValue ? inputValue.replace(/(\s*$)/g, '') : ''
          }
        ]
        setUpdateData(data);
      };
      blurTimer.current = false;
    }, 200);
  }

  // 显隐逻辑处理
  const effectFun = (option) => {
    // let effectAction = []
    // for (let i in effect) {
    //   effect[i].map((item, j) => {
    //     if (!effectAction.includes(item.effectId)) {
    //       effectAction = [...effectAction, item.effectId];
    //     }
    //   })
    // }

    if (effectOption.includes(option)) {
      let effectIdArr = []
      effect[option].map((item, i) => {
        const { hidden, effectId } = item;
        effectIdArr = [...effectIdArr, effectId];
        const data = {
          hidden: hidden,
          currentRespondentCode: currentRespondentCode
        }
        dispatch(updateAttributeData({ id: effectId, data: data }));
      })

      // console.log('effectAction43544646', effectAction);
      // effectAction.forEach(effItem => {
      //   if (!effectIdArr.includes(effItem)) {
      //     const data = {
      //       hidden: !hidden,
      //       currentRespondentCode: currentRespondentCode
      //     }

      //     console.log('fdgdfgdfg',effItem ,data);
      //     dispatch(updateAttributeData({ id: effItem, data: data }));
      //   }
      // })
    } else {
      for (let j in effectOption) {
        effect[effectOption[j]].map((item, i) => {
          const { hidden, effectId } = item;
          const data = {
            hidden: !hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effectId, data: data }));
        })
      }
    }
  }

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const { max, min, required } = rule;
    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + min + ' ' + intl.t('个选项，最多选择') + ' ' + max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ]

    if (max && max !== 0) {
      if (content.length > max) {
        return {
          error: false,
          msg: other ? msgArr[1] : msgArr[0]
        }
      }
    }

    if (min && min !== 0) {
      if (content.length < min) {
        return {
          error: false,
          msg: other ? msgArr[1] : msgArr[0]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[1]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }
  const optionItem = () => {
    let optionOrderly = [...options];
    if (mDirection === 'verticalReverse') {
      optionOrderly.reverse();
    }
    return optionOrderly.map((item, i) => {
      if (radioValue && (radioValue === item.key)) {
        return {
          key: item.key,
          value: item.value,
          checked: true,
          des: item.des,
          bgClassName: 'checked-bg-mobile'
        }
      } else {
        return {
          key: item.key,
          value: item.value,
          des: item.des,
          checked: false,
          bgClassName: ''
        }
      }
    })

  }

  const renderRadio = () => {
    return (
      <>
        <List>
          {
            optionItem().map((opt, k) => {
              return (
                <div 
                  key={widgetKey + 'div' + opt.key} 
                  className={classnames('radio-widget-mobile', {
                    'radio-widget-mobile-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                  })}
                >
                  <CheckboxItem
                    className={opt.bgClassName}
                    value={opt.key}
                    key={widgetKey + opt.key}
                    checked={opt.checked}
                    onChange={() => handleOnChange(opt.key)}
                  >
                    <div>
                      <div>{decodeURIComponent(opt.value)}</div>
                      <List.Item.Brief>{(other.length > 0) && other.includes(opt.key) ? null : opt.des}</List.Item.Brief>
                    </div>
                  </CheckboxItem >
                  {
                    ((other.length > 0) &&
                      radioValue &&
                      other.includes(radioValue) &&
                      radioValue === opt.key) ?
                      renderInput(opt.key) : ''
                  }
                </div>
              )
            })
          }
        </List>
      </>
    );
  }

  const handleExpand = () => {
    setExpand(!expand);
  };

  const formatterCount = (e = '', optionKey = null) => {
    const { min } = otherRules[optionKey];
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    const max = otherRules[optionKey].max ? otherRules[optionKey].max : 2000;
    if (min && Number(min)) {
      return `${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
    }
    return `${count}/${max}`;
  };

  const renderInput = (optionKey) => {
    return (
      <div className={classnames("radio-textarea-mobile", {
          'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
          'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
        })}>
        <TextareaItem
          className={
            validateFlag ? (validateResult[widgetKey]?.otherResult ?
              (validateResult[widgetKey]?.otherResult[optionKey]?.error ? '' : 'ct-textarea-status') : '') : ''
          }
          key={widgetKey + 'textarea'}
          placeholder={placeholder(optionKey)}
          rows={2}
          onChange={(e) => handleChangeInput(e, optionKey)}
          onBlur={handleBlur}
          defaultValue={inputValueMap ? inputValueMap[optionKey] : ''}
        />
        <p className="radio-ct-textarea-text">
          {formatterCount(inputValueMap ? inputValueMap[optionKey] : '', optionKey)}
        </p>
      </div>
    )
  }

  const placeholder = (optionKey) => {
    const requiredContent = otherRules[optionKey].required ? intl.t('必填') : '';
    let desContent = '';
    options.map((item, i) => {
      if (item.key === optionKey && item.des !== '') {
        desContent = '(' + item.des + ')';
      }
    });
    return requiredContent + desContent;
  }

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="ct-questionnaire-mobile-error ct-questionnaire-mobile-radio-error error-tip">
                <ExclamationCircleFilled />
                <div style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </div>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden} question-related-${related}`}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={'div-label'}
          style={{ fontSize: '14px' }}
        >
          <div className={`radio-div-label-${required}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div className="m-number">{number}.</div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-content radio-content-related-${related}`}>
          {tips ? (
            <div className="mobile-question-tip">
              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60
                  && !expand ? (
                  <>
                    {decodeURIComponent(tips).length > 100 ? `${decodeURIComponent(tips).slice(0, 60)}...` : `${decodeURIComponent(tips).slice(0, 54)}...`}
                  </>
                ) : (
                  <>{decodeURIComponent(tips)}</>
                )
              }

              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && (
                  <div onClick={handleExpand}>
                    {expand
                      ? (
                        <div className='expand-btn-container'>
                          {intl.t('收起')}
                          <CardIcon type="iconshouqi1" className="expand-icon" />
                        </div>
                      ) : (
                        <div className='expand-btn-container'>
                          {intl.t('查看全部')}
                          <CardIcon type="iconxiala2" className="expand-icon" />
                        </div>
                      )}
                  </div>
                )
              }
            </div>
          ) : null}

          {renderRadio()}
        </div>

        {renderErrorMsg()}
      </div>
    </>
  );
};

export default radioWidgetMobile