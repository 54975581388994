import React, { useMemo, useCallback, useContext, useEffect, useRef, useState } from 'react';

import CustomInfoPanel from '@/cpns/CustomInfoPanel';
import CustomCard from '@/cpns/CustomCard';
import CustomTagCell from '@/cpns/CustomTagCell';
import { createForm, onFieldChange, onFieldInputValueChange, onFieldInit } from '@formily/core';
import { FormProvider, ObjectField, ArrayField } from '@formily/react';
import CustomPageHead from '@/cpns/CustomPageHead';
import { TagTypeXCpnMap, PanelConfig } from './constant';
import { LangTranslate, ScrollInToViewBySelector } from '@/utils';
import {
  getTagGroup,
  saveTagGroup,
  getTagGroupVersion,
  getTagGroupInfoMultilingual,
  checkDimensionAndTagGroupDept,
} from '@/services';
import { useRequest } from 'ahooks';
import cloneDeep from 'lodash/cloneDeep';
import { useObjAndDimData } from '@/hooks';
import SvgIcon from '@/cpns/SvgIcon';
import { HandelRefContext, BricksInfoContext } from '@/context.jsx';
import { message, Button, Spin, Modal } from 'antd';
import CustomVersionPanel from '@/cpns/CustomVersionPanel';

import './index.less';

function TagConfig(props) {
  const panelFormRef = useRef();
  const searchParams = new URLSearchParams(window.location.search);
  const oriTagPoolId = searchParams?.get('id');
  const tagPoolId = ['null', 'undefined', null, undefined].includes(oriTagPoolId)
    ? ''
    : oriTagPoolId;
  const nowayFlag = searchParams?.get('noway');

  const BricksInfoContextVal = useContext(BricksInfoContext);
  const HandelRefContextVal = useContext(HandelRefContext);
  const { data: [objOption, dimsTree] = [], loading: objAndDimsTreeLoading } = useObjAndDimData();
  const [readPretty, setReadPretty] = useState(props?.readPretty || false);
  const [currentSelectVersion, setCurrentSelectVersion] = useState();

  useEffect(() => {
    setReadPretty(() => props?.readPretty || false);
  }, [props.readPretty]);

  useEffect(() => {
    const panelForm = panelFormRef.current;
    panelForm.setFieldState('panelInfos.subjectId', filed => {
      filed.setComponentProps({ options: objOption });
    });
    panelForm.setFieldState('panelInfos.dimensionId', filed => {
      filed.setComponentProps({
        treeData: dimsTree,
        // treeDefaultExpandedKeys: dimsTree?.map(it => it?.id),
      });
    });
  }, [objOption, dimsTree]);

  // 标签列表表单
  const tagListForm = useMemo(
    () =>
      createForm({
        initialValues: {
          tagList: [
            {
              tagOptionList: [{}],
              type: 1,
            },
          ],
        },
        effects() {
          onFieldInit('tagList.0.type', field => {
            field.setComponentProps({ option: Object.values(TagTypeXCpnMap) });
          });
          onFieldInit('tagList.0.tagOptionList', field => {
            field.setDisplay('visible');
          });
          onFieldInputValueChange('tagList.0.type', parentField => {
            if (parentField.modified) {
              tagListForm?.setValuesIn?.('tagList.0.tagOptionList', [{}]);
              tagListForm?.setValuesIn?.('tagList.0.indicatorField', {});
              tagListForm?.setValuesIn?.('tagList.0.logicDesc', '');
            }
          });
          onFieldChange('tagList.0.type', parentField => {
            const value = parentField?.value;
            if (Object.keys(TagTypeXCpnMap)?.includes(value + '')) {
              if ([1, 2].includes(value)) {
                tagListForm.setFieldState('tagList.0.tagOptionList', field => {
                  field.setDisplay('visible');
                });
                tagListForm.setFieldState(
                  'tagList.0.*(logicDesc,logicDescLodash,indicatorField,anotherField)',
                  field => {
                    field.setDisplay('none');
                  },
                );
                tagListForm.setFieldState('tagList.0.tagOptionList.otherBtnWrap', field => {
                  field?.setDisplay?.('visible');
                });
              } else if ([5].includes(value)) {
                tagListForm.setFieldState('tagList.0.logicDesc', field => {
                  field.setDisplay('visible');
                });
                tagListForm.setFieldState(
                  'tagList.0.*(tagOptionList,indicatorField,anotherField,logicDescLodash)',
                  field => {
                    field.setDisplay('none');
                  },
                );
              } else if ([6].includes(value)) {
                tagListForm.setFieldState(
                  'tagList.0.*(tagOptionList,logicDesc,logicDescLodash)',
                  field => {
                    field.setDisplay('visible');
                  },
                );
                tagListForm.setFieldState('tagList.0.*(indicatorField,anotherField)', field => {
                  field.setDisplay('none');
                });
                tagListForm.setFieldState('tagList.0.tagOptionList.otherBtnWrap', field => {
                  field?.setDisplay?.('none');
                });
              } else if ([7].includes(value)) {
                tagListForm.setFieldState(
                  'tagList.0.*(tagOptionList,logicDesc,logicDescLodash,anotherField)',
                  field => {
                    field.setDisplay('none');
                  },
                );
                tagListForm.setFieldState('tagList.0.indicatorField', field => {
                  field.setDisplay('visible');
                });
              } else {
                tagListForm.setFieldState(
                  'tagList.0.*(tagOptionList,indicatorField,logicDesc,logicDescLodash)',
                  field => {
                    field.setDisplay('none');
                  },
                );
                tagListForm.setFieldState('tagList.0.anotherField', field => {
                  field.setDisplay('visible');
                });
                const newCpnObj = TagTypeXCpnMap?.[value];
                tagListForm.setFieldState('tagList.0.anotherField', field => {
                  field.setComponent(newCpnObj?.cpn);
                });
              }
            }
          });
        },
      }),
    [],
  );

  // 获取标签组数据
  const {
    data: tagGroupInfo = {},
    loading: fetchTagGroupInfoLoading,
    runAsync: fetchTagGroupInfo,
  } = useRequest(
    async params => {
      try {
        const res = await (readPretty ? getTagGroupInfoMultilingual : getTagGroup)({
          // const res = await getTagGroup({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return { ...res?.data, dept: res?.data?.dept?.split?.(',') };
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
      refreshDeps: [BricksInfoContextVal, readPretty],
    },
  );

  // 获取版本数据
  const {
    data: tagPoolVersionInfo = {},
    loading: getTagGroupVersionLoading,
    runAsync: runGetTagGroupVersion,
  } = useRequest(
    async params => {
      try {
        const res = await getTagGroupVersion({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        setCurrentSelectVersion(() => res?.data?.find?.(it => it?.latest)?.id || '');

        return res?.data;
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
      refreshDeps: [BricksInfoContextVal],
    },
  );
  const {
    loading: checkDimensionAndTagGroupDeptLoading,
    runAsync: runCheckDimensionAndTagGroupDept,
  } = useRequest(
    async params => {
      try {
        const res = await checkDimensionAndTagGroupDept({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContextVal] },
  );
  // 保存数据
  const { loading: saveTagGroupLoading, runAsync: runSaveTagGroup } = useRequest(
    async params => {
      try {
        const panelForm = panelFormRef.current;

        const baseConfig = panelForm.getValuesIn('panelInfos');
        const checkParams = {
          dimensionId: baseConfig?.dimensionId,
          dept: baseConfig?.dept?.join?.(',') || baseConfig?.dept,
        };
        const checkRes = await runCheckDimensionAndTagGroupDept(checkParams);

        if (!checkRes?.resultFlag) {
          message.warning(checkRes?.msg);
          panelForm.setValuesIn('panelInfos.dept', checkRes?.dept.split?.(','));
          return Promise.reject(checkRes?.msg);
        }

        const res = await saveTagGroup({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
      refreshDeps: [BricksInfoContextVal],
    },
  );

  // 点击保存
  const handelSave = async () => {
    try {
      const panelFrom = panelFormRef.current;
      await panelFrom.validate('*');

      const tagPoolBaseInfoVal = cloneDeep(panelFrom?.getValuesIn?.('panelInfos'));
      const curTagVal = cloneDeep(tagListForm?.getValuesIn?.('tagList'))?.[0] || {};
      let newTagOptionList = [];
      if (curTagVal?.type === 7) {
        newTagOptionList = [
          {
            ...curTagVal?.indicatorField.dataSource,
            name: 'dataSource',
          },
          {
            ...curTagVal?.indicatorField.apiUrl,
            name: 'apiUrl',
          },
          {
            ...curTagVal?.indicatorField.indicatorLogicDesc,
            name: 'indicatorLogicDesc',
          },
        ];
      }
      const newTagPoolConfig = {
        ...tagGroupInfo,
        ...tagPoolBaseInfoVal,
        id: tagGroupInfo?.id || tagPoolBaseInfoVal?.id || tagPoolId,
        name: tagPoolBaseInfoVal?.name,
        code: tagPoolBaseInfoVal?.code,
        description: tagPoolBaseInfoVal?.description,
        dimensionId: tagPoolBaseInfoVal?.dimensionId,
        subjectId: tagPoolBaseInfoVal?.subjectId,
        customAuth: tagPoolBaseInfoVal?.customAuth,
        tagList: [
          {
            ...curTagVal,
            code: tagPoolBaseInfoVal?.code,
            name: tagPoolBaseInfoVal?.name,
            description: tagPoolBaseInfoVal?.description,
            dimensionId: tagPoolBaseInfoVal?.dimensionId,
            subjectId: tagPoolBaseInfoVal?.subjectId,
            tagOptionList: curTagVal?.type === 7 ? newTagOptionList : curTagVal?.tagOptionList,
            indicatorField: undefined,
          },
        ],
        actionFlag: 1,
        type: 1,
        dept: tagPoolBaseInfoVal?.dept?.join?.(',') || '',
      };
      const saveRes = await runSaveTagGroup(newTagPoolConfig);
      if (!saveRes?.resultFlag) {
        message.warning({
          content: <div style={{ whiteSpace: 'pre-line' }}>{saveRes?.msg}</div>,
        });
        return Promise.reject(saveRes?.msg);
      } else {
        await message.success(LangTranslate('操作成功'), 0.5);
        window.location.href = window.location.origin + '/labelSystem/home';
      }
      return;
    } catch (error) {
      console.log('error', error);
      ScrollInToViewBySelector('.label-system-Pagelet-antd-formily-item-error-help');

      return Promise.reject(error);
    }
  };

  // 点击立即生效
  const handelSubmit = async () => {
    try {
      const panelFrom = panelFormRef.current;
      await panelFrom.validate('*');
      await tagListForm?.validate?.('*');
      const tagPoolBaseInfoVal = cloneDeep(panelFrom?.getValuesIn?.('panelInfos'));
      const curTagVal = cloneDeep(tagListForm?.getValuesIn?.('tagList'))?.[0] || {};
      let newTagOptionList = [];
      if (curTagVal?.type === 7) {
        newTagOptionList = [
          {
            ...curTagVal?.indicatorField.dataSource,
            name: 'dataSource',
          },
          {
            ...curTagVal?.indicatorField.apiUrl,
            name: 'apiUrl',
          },
          {
            ...curTagVal?.indicatorField.indicatorLogicDesc,
            name: 'indicatorLogicDesc',
          },
        ];
      }
      const newTagPoolConfig = {
        ...tagGroupInfo,
        ...tagPoolBaseInfoVal,
        id: tagGroupInfo?.id || tagPoolBaseInfoVal?.id || tagPoolId,
        name: tagPoolBaseInfoVal?.name,
        code: tagPoolBaseInfoVal?.code,
        description: tagPoolBaseInfoVal?.description,
        dimensionId: tagPoolBaseInfoVal?.dimensionId,
        subjectId: tagPoolBaseInfoVal?.subjectId,
        customAuth: tagPoolBaseInfoVal?.customAuth,
        tagList: [
          {
            ...curTagVal,
            code: tagPoolBaseInfoVal?.code,
            name: tagPoolBaseInfoVal?.name,
            description: tagPoolBaseInfoVal?.description,
            dimensionId: tagPoolBaseInfoVal?.dimensionId,
            subjectId: tagPoolBaseInfoVal?.subjectId,
            tagOptionList: curTagVal?.type === 7 ? newTagOptionList : curTagVal?.tagOptionList,
            indicatorField: undefined,
          },
        ],
        actionFlag: 2,
        type: 1,
        dept: tagPoolBaseInfoVal?.dept?.join?.(','),
      };
      const submitRes = await runSaveTagGroup(newTagPoolConfig);
      if (submitRes?.resultFlag) {
        await message.success(LangTranslate('操作成功'), 0.5);
        // 返回主页
        window.location.href = window.location.origin + '/labelSystem/home';
      } else {
        message.warning({
          content: <div style={{ whiteSpace: 'pre-line' }}>{submitRes?.msg}</div>,
        });
        return Promise.reject(submitRes?.msg);
      }
    } catch (error) {
      console.log('error', error);
      ScrollInToViewBySelector('.label-system-Pagelet-antd-formily-item-error-help');
      return Promise.reject(error);
    }
    return;
  };

  useEffect(() => {
    if (!Object.values(tagGroupInfo || {})?.length) {
      return;
    }
    const panelFrom = panelFormRef.current;

    const curTagInfos = tagGroupInfo?.tagList?.[0];

    panelFrom.setValues({
      panelInfos: {
        ...curTagInfos,
        dimensionId: tagGroupInfo?.dimensionId,
        subjectId: tagGroupInfo?.subjectId,
        belongSystemId: tagGroupInfo?.belongSystemId,
        dept: tagGroupInfo?.dept,
        rootGroupId: tagGroupInfo?.rootGroupId,
        customAuth: tagGroupInfo?.customAuth,
      },
    });
    if (curTagInfos?.type === 7) {
      tagListForm.setValues({
        tagList: [
          {
            ...curTagInfos,
            indicatorField: {
              dataSource: curTagInfos?.tagOptionList?.find(it => it?.name === 'dataSource'),
              apiUrl: curTagInfos?.tagOptionList?.find(it => it?.name === 'apiUrl'),
              indicatorLogicDesc: curTagInfos?.tagOptionList?.find(
                it => it?.name === 'indicatorLogicDesc',
              ),
            },
          },
        ],
      });
    } else {
      tagListForm.setValues({
        tagList: [
          {
            ...curTagInfos,
          },
        ],
      });
    }
    if ([2, 3].includes(tagGroupInfo?.status)) {
      panelFrom.setFieldState('panelInfos.subjectId', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.code', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.belongSystemId', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.dept', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.customAuth', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.dimensionId', field => {
        field.setPattern('readPretty');
      });
      tagListForm.setFieldState('tagList.0.type', field => {
        field.setPattern('readPretty');
      });
    }
  }, [tagGroupInfo]);

  useEffect(() => {
    if (!!tagPoolId?.length && !nowayFlag) {
      fetchTagGroupInfo({ tagGroupId: tagPoolId });
    }
  }, [tagPoolId, BricksInfoContextVal, nowayFlag]);

  useEffect(() => {
    if (readPretty) {
      runGetTagGroupVersion({ tagGroupId: tagPoolId });
    }
  }, [tagPoolId, BricksInfoContextVal, readPretty]);

  useEffect(() => {
    const panelFrom = panelFormRef.current;

    if (!readPretty) {
      panelFrom.setFieldState('panelInfos.*(id,rootGroupId)', field => {
        field.setDisplay('hidden');
      });
      return;
    } else {
      panelFrom.setFieldState('panelInfos.*(id,rootGroupId)', field => {
        field.setDisplay('visible');
      });
    }
    panelFrom.setFieldState('*', field => {
      field.setPattern('readPretty');
    });
    tagListForm.setFieldState('*', field => {
      field.setPattern('readPretty');
    });
  }, [readPretty]);

  // 按钮组
  const renderBtns = () => {
    if (readPretty) {
      return (
        <>
          <Button
            onClick={() => {
              window.location.href =
                window.location.origin +
                '/labelSystem/version' +
                '?id=' +
                tagGroupInfo?.tagList?.[0]?.id +
                '&nodeType=label';
            }}
          >
            {LangTranslate('标签版本')}
          </Button>
          {!!tagGroupInfo?.editFlag && (
            <Button
              onClick={() => {
                HandelRefContextVal.current?.setVisibleLangModal?.({
                  visible: true,
                  tagPoolId,
                  afterCallBack: () => {
                    // window?.location?.reload?.();
                  },
                });
              }}
            >
              {LangTranslate('多语言配置')}
            </Button>
          )}
          <Button
            onClick={() => {
              HandelRefContextVal.current?.setVisibleChangeAffiliationModal?.({
                visible: true,
                tagPoolId,
                afterCallBack: () => {
                  fetchTagGroupInfo({ tagGroupId: tagPoolId });
                  // window?.location?.reload?.();
                },
              });
            }}
          >
            {LangTranslate('调整归属')}
          </Button>
          {!!tagGroupInfo?.editFlag && (
            <Button
              onClick={() => {
                window.location.href =
                  window.location.origin +
                  window.location.pathname +
                  '?id=' +
                  tagPoolId +
                  '&pageType=tagConfig';
              }}
              type="primary"
            >
              {LangTranslate('编辑标签')}
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        {![2, 3].includes(tagGroupInfo?.status) && (
          <Button onClick={handelSave}>{LangTranslate('保存')}</Button>
        )}
        <Button onClick={handelSubmit} type="primary">
          {LangTranslate('立即生效')}
        </Button>
      </>
    );
  };

  // 返回
  const handelReturnHome = () => {
    if (readPretty) {
      window.location.href = window.location.origin + '/labelSystem/home';
      return;
    }
    if (![2, 3].includes(tagGroupInfo?.status)) {
      const cModal = Modal.confirm({
        title: LangTranslate('确定返回'),
        content: LangTranslate('请确认是否需要保存当前编辑内容。若不保存，本次编辑内容会被丢弃。'),
        okButtonProps: {},
        className: 'pagelet-label-system-warn-confirm-wrap',
        footer: (
          <div className="pagelet-label-system-warn-confirm-footer">
            <div
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('取消')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                window.location.href = window.location.origin + '/labelSystem/home';
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('不保存并返回')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={async () => {
                try {
                  await handelSave();
                  window.location.href = window.location.origin + '/labelSystem/home';
                } catch (error) {
                  console.log('error', error);
                }
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('保存并返回')}
            </div>
          </div>
        ),
      });
    } else {
      const cModal = Modal.confirm({
        title: LangTranslate('确定返回'),
        content: LangTranslate(
          '请确认是否需要生效当前编辑的内容。若不生效，本次编辑内容会被丢弃。若生效，标签版本会更新。',
        ),
        okButtonProps: {},
        className: 'pagelet-label-system-warn-confirm-wrap',
        footer: (
          <div className="pagelet-label-system-warn-confirm-footer">
            <div
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('取消')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                window.location.href = window.location.origin + '/labelSystem/home';
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('放弃本次编辑内容')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={async () => {
                try {
                  cModal?.destroy?.();
                  await handelSubmit();
                  window.location.href = window.location.origin + '/labelSystem/home';
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              {LangTranslate('生效本次编辑内容')}
            </div>
          </div>
        ),
      });
    }
  };

  // 初始化
  useEffect(() => {
    try {
      if (nowayFlag) {
        const tempTagInfo = JSON.parse(
          localStorage.getItem('pagelet-labelSystem-tmp-data-tagInfo') || {},
        );
        // setTimeout(() => {
        //   localStorage.removeItem('pagelet-labelSystem-tmp-data-tagInfo');
        // });
        console.log('tempTagInfo2', tempTagInfo);
        const panelFrom = panelFormRef.current;

        panelFrom.setValues({
          panelInfos: {
            name: tempTagInfo?.name || undefined,
            description: tempTagInfo?.description || undefined,
            id: tempTagInfo?.tagPoolId || undefined,
            subjectId: tempTagInfo?.subjectId || undefined,
            dimensionId: tempTagInfo?.dimensionId || undefined,

            belongSystemId: tempTagInfo?.belongSystemId || undefined,
            dept: tempTagInfo?.dept || undefined,
            customAuth: tempTagInfo?.customAuth || undefined,
          },
        });

        tagListForm.setValuesIn('tagList.0', {
          id: tempTagInfo?.tagId || undefined,
          type: tempTagInfo?.type || undefined,
          logicDesc: tempTagInfo?.logicDesc || undefined,
          tagOptionList: tempTagInfo?.tagOptionList || undefined,
          indicatorField: tempTagInfo?.indicatorField || undefined,
          anotherField: tempTagInfo?.anotherField || undefined,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [nowayFlag]);

  // 切换至标签组
  const handleSwitchTagPoolConfig = () => {
    const panelFrom = panelFormRef.current;
    const tagPoolBaseInfoVal = cloneDeep(panelFrom?.getValuesIn?.('panelInfos'));
    const curTagVal = cloneDeep(tagListForm?.getValuesIn?.('tagList'))?.[0] || {};
    const newTagVal = {
      tagPoolId: tagGroupInfo?.id,
      tagId: curTagVal?.id,
      name: tagPoolBaseInfoVal?.name || undefined,
      description: tagPoolBaseInfoVal?.description || undefined,
      subjectId: tagPoolBaseInfoVal?.subjectId || undefined,
      dimensionId: tagPoolBaseInfoVal?.dimensionId || undefined,
      belongSystemId: tagPoolBaseInfoVal?.belongSystemId || undefined,
      dept: tagPoolBaseInfoVal?.dept || undefined,
      customAuth: tagPoolBaseInfoVal?.customAuth || undefined,

      type: curTagVal?.type || undefined,
      logicDesc: curTagVal?.logicDesc || undefined,
      tagOptionList: curTagVal?.tagOptionList || undefined,
      indicatorField: curTagVal?.indicatorField || undefined,
      anotherField: curTagVal?.anotherField || undefined,
    };
    localStorage.setItem('pagelet-labelSystem-tmp-data-tagInfo', JSON.stringify(newTagVal));
    window.location.href =
      window.location.origin +
      window.location.pathname +
      '?id=' +
      tagPoolId +
      '&pageType=tagPoolConfig&noway=true';
  };

  return (
    <div className="pagelet-label-system-tag-config-page-wrap">
      <Spin spinning={saveTagGroupLoading || fetchTagGroupInfoLoading}>
        <div className="pagelet-label-system-tag-config-page-base-info-wrap">
          <CustomInfoPanel {...(PanelConfig(readPretty) || {})} panelFormRef={panelFormRef} />
        </div>
        <div className="pagelet-label-system-tag-config-page-content">
          <div className="pagelet-label-system-tag-config-page-content-head">
            <CustomPageHead
              title={
                readPretty
                  ? LangTranslate('查看标签')
                  : !!tagPoolId?.length
                    ? LangTranslate('编辑标签')
                    : LangTranslate('创建标签')
              }
              switchTxt={LangTranslate('切换至创建标签组')}
              handleSwitch={handleSwitchTagPoolConfig}
              switchWarnTxt={LangTranslate('点击切换后，已存在的标签作为标签组的第一个标签')}
              renderBtns={renderBtns}
              hasSwitch={![2, 3].includes(tagGroupInfo?.status) && !readPretty}
              handelReturnHome={handelReturnHome}
            />
          </div>
          <div className="pagelet-label-system-tag-config-page-content-body">
            <div
              id="pagelet-label-system-tag-pool-tag-config-page-scroll-content-body"
              style={{ width: '100%', height: '100%', overflowY: 'auto', paddingBottom: '50px' }}
            >
              <CustomCard>
                <div className="pagelet-label-system-tag-config-page-content-body-label-wrap">
                  {!readPretty && (
                    <div className="pagelet-label-system-tag-config-page-content-body-label-tips-wrap">
                      <div className="pagelet-label-system-tag-config-page-content-body-label-tips-icon">
                        <SvgIcon type="icon-shuomingicon" size={16} />
                      </div>
                      <div className="pagelet-label-system-tag-config-page-content-body-label-tips">
                        {`${LangTranslate('请根据需要，选择你需要的标签类型，并完成具体设置')}`}
                      </div>
                    </div>
                  )}

                  <div className="pagelet-label-system-tag-config-page-content-body-label">
                    <div className="pagelet-label-system-tag-pool-config-cell-tag-config-label-require-icon">
                      *
                    </div>
                    <div className="pagelet-label-system-tag-config-page-content-body-label-txt">
                      {`${LangTranslate('标签类型')}:`}
                    </div>
                  </div>
                </div>
                <FormProvider form={tagListForm}>
                  <ArrayField name="tagList">
                    <ObjectField name={0} component={[CustomTagCell]} />
                  </ArrayField>
                </FormProvider>
              </CustomCard>
            </div>
            <div className="pagelet-label-system-tag-pool-config-page-version-panel">
              {readPretty && (
                <CustomVersionPanel
                  versionList={tagPoolVersionInfo}
                  onChange={curSelectVerId => {
                    setCurrentSelectVersion(() => curSelectVerId);
                    fetchTagGroupInfo({ tagGroupId: curSelectVerId });
                  }}
                  currentSelectVersion={currentSelectVersion}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default TagConfig;
