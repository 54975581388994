import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // get请求示例
  getRequestData: async (host, params) => {
    const result = await PageletApi.request(`${host}/${apis.getList}`, {
      method: 'get',
      params
    });

    return result;
  },

  // post请求示例
  getRequestData: async (host, data) => {
    const result = await PageletApi.request(`${host}/${apis.getList}`, {
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return result;
  }
}

export default PageletServices;
