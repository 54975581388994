import { useRegisterStyle, getLang } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-job-position-chart', () => [
    {
      ['.ehr-jobposition-cloneNode .now-on-job']: {
        boxShadow: 'none !important'
      },
			// base
      [`.${prefixCls}`]: {
        minHeight: '288px',

        ['.job-position-detail']: {
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.72)',

          ['.downLoad-btn']: {
            background: 'none !important',
            padding: '0 !important',
            height: '20px',
            color: 'rgba(0, 0, 0, 0.72)',
            fontWeight: 400,
            marginBottom: '16px',

            ['.ant-btn-icon']: {
              marginRight: '4px',
            },

            ['&:hover']: {
              color: '#1473FF',
            },
          },

          ['.detail-info']: {
            fontFamily: 'PingFang SC',
            fontWeight: 400,

            ['&>span']: {
              display: 'inline-block',
              marginBottom: '16px',

              ['&:last-child::after']: {
                content: getLang() === 'en-US' ? '". "' : '"。"',
              },

              ['&:not(:last-child)::after']: {
                content: getLang() === 'en-US' ? '", "' : '"，"',
                marginRight: getLang() === 'en-US' ? '4px' : '0px',
              },

              ['&>span']: {
                fontWeight: 600,
                color: '#1473FF',
                margin: '0 5px',
              },
            }
          }
        }
			}
		}
	]);
};

export default useStyle;
