import React, { useState, useEffect } from 'react';
import { getLocale, ConfigProvider, Drawer, Segmented } from '@didi/ec-base';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import { Intl, newRequest } from '@didi/pagelets-sdk';
import { RightThickOutlined } from '@didi/iconflow/ec';
import manifest from '../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import useDrawerStyle from './style/drawer.js';
import PageletServices from "./services/index.js";
import DynamicBody from "./components/DynamicBody/index.jsx";
import { typeList } from './constant.js'

import Parcel from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import { getModuleInfo } from '../utils/Parcel.js';
const loadParcel = async (url) => (await window.System.import(url));

const localLocales = manifest.locales || {};
const prefixCls = 'pagelet-team-dynamic';


const Pagelet = props => {
  const {
    params: { auth, headers = {}, apiHost, locales, item, pageCode = 'default', deptCodes, deptInfos, },
    store
  } = props;

  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(auth.functionName)
  }

  newRequest.init(newHeaders, '@didi/pagelet-team-dynamic');

  const wrapSSR = useStyle(prefixCls);
  const drawerWrapSSR = useDrawerStyle(prefixCls)
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const classes = classNames(prefixCls);

  const [parcel, setParcel] = useState({});
  const [open , setOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [childQuery, setChildQuery] = useState({});
  const [subNum, setsubNum] = useState('');
  const [subNumDarwer, setSubNumDarwer] = useState('');
  const [typeCodes, setTypeCodes] = useState('1');
  const [permissionScope, setPermissionScope] = useState([]);
  const [systemList, setSystemList] = useState([]);

  useEffect(()=>{
    const params = {
      "pageType": pageCode,
    };
    PageletServices.initFilter(apiHost, params).then((res) => {
      if (res?.code === '100200' && res?.data) {
        const list = res?.data;
        let subNumInfo = '-1';
        list.map((v)=>{
          if(v?.selected){
            subNumInfo = v?.code
          }
        })
        setPermissionScope(res?.data || []);
        setsubNum(subNumInfo);
        setSubNumDarwer(subNumInfo)
      } else {
        console.log('卡片初始化错误, 展示兜底卡片!!!')
        setInitError(true);
      }
    }, (error) => {
      console.log('卡片初始化错误, 展示兜底卡片!!!', error)
      setInitError(true);
    });
    PageletServices.initSystemList(apiHost).then((res) => {
      if (res?.code === '100200' && res?.data) {
        console.log(res, 1111)
        setSystemList([{code: 'all', name: '全部'}, ...res?.data]);
      }
    }, (error) => {
    });
  },[])

  const dealQuery = (search) => {
    const query = {};
    const queryList = search.split('&');
    queryList.forEach(item => {
      const curQuery = item.split('=');
      query[curQuery[0]] = curQuery[1];
    })
    setChildQuery(query);
  }

  const showDetail = async (value) => {
    if (value?.detailUrl) {
      const moduleInfo = await getModuleInfo({
        // url: 'http://dhr-dev.intra.xiaojukeji.com/process/apply/holiday?type=approve&busCode=HD20240717006&taskId=d01146cd-4420-11ef-840c-b2bc9ba9cc91'
        // url: 'http://dhr-test.intra.xiaojukeji.com/p/appointment?uuid=21b91e2ed9444dbe8a4e9069ecf9d6b1&taskId=2aa3bece-4045-11ef-b4b4-9a11b74f4de0'
        url: value.detailUrl
      })
      const p = await loadParcel(`${moduleInfo.pageletName}`);
      const target = p[moduleInfo.moduleName];
      setParcel(target);
      setDetailOpen(true);

      const search = new URL(value.detailUrl)?.search?.slice?.(1);
      dealQuery(search);
    }
  };

  const closeDetail = () => {
    setDetailOpen(false);
  };

  const onChangeType = (value)=>{
    console.log(value)
    if (typeCodes !== value) {
      setTypeCodes(value);
    }
  }

  return wrapSSR(
    <div className={classes}>
      <ConfigProvider prefixCls="ant5" autoInsertSpaceInButton={false}>
      <div className={`${prefixCls}-title`}>
          <DHRCardTitle
            title={
              <>
              <div>{locale.teamDynamic}</div>
              {item?.cardHeight === 1 && <div className={`${prefixCls}-title-filter`}>
                <Segmented
                  value={typeCodes}
                  options={typeList}
                  size="small"
                  onChange={(value) => {
                    onChangeType(value); // string
                  }}
                />
              </div>}
              </>
            }
            showPrefixTag={false}
            type="grey"
            titleAdded={
              <DHRCardButton onClick={() => {setOpen(true);}}>
                {locale.dynamicSummary} <RightThickOutlined cursor="pointer" size={14} className="new-home-holiday-card-title-url-icon"/>
              </DHRCardButton>
            }
          />
        </div>
        <DynamicBody
          type="card"
          auth={auth}
          deptCodes={deptCodes}
          deptInfos={deptInfos}
          prefixCls={prefixCls}
          apiHost={apiHost}
          item={item}
          pageCode={pageCode}
          pageSize={20}
          showDetail={showDetail}
          headers={headers}
          subNum={subNum}
          typeCodes={typeCodes}
          permissionScope={permissionScope}
          onChange={(key)=>{
            setsubNum(key);
            setSubNumDarwer(key);
          }}
        />
        {
          drawerWrapSSR(
            <>
              <Drawer
                title='动态汇总'
                className={`${prefixCls}-drawer`}
                width='800px'
                open={open}
                destroyOnClose
                onClose={() => {
                  setOpen(false)
                }}
              >
                <DynamicBody
                  type="drawer"
                  auth={auth}
                  deptCodes={deptCodes}
                  deptInfos={deptInfos}
                  prefixCls={prefixCls}
                  apiHost={apiHost}
                  typeCodes={typeCodes}
                  pageCode={pageCode}
                  pageSize={60}
                  showDetail={showDetail}
                  headers={headers}
                  subNum={subNumDarwer}
                  permissionScope={permissionScope}
                  systemList={systemList}
                  onChange={(key)=>{
                    setSubNumDarwer(key);
                  }}
                />
              </Drawer>
              <Drawer
                title="动态详情"
                rootClassName={`${prefixCls}-detail-drawer`}
                width={1000}
                open={detailOpen}
                destroyOnClose
                onClose={closeDetail}
              >
                <Parcel
                  mountParcel={mountRootParcel}
                  config={parcel}
                  store={store}
                  viewModel="card"
                  query={childQuery}
                />
              </Drawer>
            </>
          )
        }
      </ConfigProvider>
    </div>,
  );
};

export default Pagelet;
