import React, { useState, useMemo, useEffect } from 'react';
import { connect, mapReadPretty } from '@formily/react';
import DepartmentTreeSelect from '@/cpns/DepartmentTreeSelect';
import { Tooltip } from 'antd';
import './index.less';

const CustomReadPrettyDepartmentTreeSelect = connect(
  DepartmentTreeSelect,
  mapReadPretty(props => {
    const [vals, setVals] = useState([]);

    const tagRender = curTagVal => {
      if (
        !vals.find(it => it?.label === curTagVal?.label) &&
        curTagVal?.label !== curTagVal?.value &&
        !curTagVal?.isMaxTag
      ) {
        setVals(old => [...old, curTagVal]);
      }
    };

    const valStr = useMemo(() => vals?.map(it => it?.label)?.join('、'), [vals]);

    useEffect(() => {
      setVals(() => []);
    }, [props?.value]);

    return (
      <div className="label-system-pagelet-custom-dept-tree-select-readPretty-wrap">
        <Tooltip placement="topLeft" title={valStr}>
          <div className="label-system-pagelet-custom-dept-tree-select-readPretty-content">
            {valStr}
          </div>
        </Tooltip>
        <div style={{ height: 0, opacity: 0 }}>
          <DepartmentTreeSelect
            {...props}
            tagRender={tagRender}
            maxTagTextLength={false}
            maxTagCount={false}
          />
        </div>
      </div>
    );
  }),
);
export default CustomReadPrettyDepartmentTreeSelect;
