import React from 'react';
import { Field, ArrayField, ObjectField, observer, VoidField } from '@formily/react';
import CustomRadioGroup from '@/cpns/CustomRadioGroup';
import OptionItemCell from '@/cpns/OptionItemCell';
import CustomTagTextAreaCpn from '@/cpns/CustomTagTextAreaCpn';
import CustomTagIndicatorCpn from '@/cpns/CustomTagIndicatorCpn';
import CustomLogicDescLodash from '@/cpns/CustomLogicDescLodash';
import { Input } from 'antd';
import { FormItem, FormLayout } from '@formily/antd-v5';
import CustomReadPrettyToolTipInput from '@/cpns/CustomReadPrettyToolTipInput';
import './index.less';
import { LangTranslate } from '@/utils';
const LogicDescInput = props => {
  return <CustomReadPrettyToolTipInput {...props} />;
};
const TagConfigCell = observer(() => {
  return (
    <div className="pagelet-label-system-tag-config-cell-wrap">
      <Field hidden name="id" component={[Input, {}]} />
      <div className="pagelet-label-system-tag-config-cell-tag-type">
        <Field name="type" component={[CustomRadioGroup]} decorator={[FormItem]} />
      </div>
      <div className="pagelet-label-system-tag-config-cell-logic-desc-field">
        <FormLayout layout={['vertical']} labelAlign={['left']}>
          <Field
            display="none"
            name="logicDesc"
            title={LangTranslate('逻辑说明')}
            component={[
              LogicDescInput,
              { placeholder: LangTranslate('非必填，可以用文字说明具体标签计算逻辑') },
            ]}
            decorator={[FormItem]}
            validator={[
              val =>
                /^\s+|\s+$/.test(val)
                  ? Promise.reject(LangTranslate('前后不能有空格'))
                  : Promise.resolve(),

              { maxLength: 1000, message: LangTranslate('字数超长') },
            ]}
          />
          <VoidField disabled="none" name="logicDescLodash" component={[CustomLogicDescLodash]} />
        </FormLayout>
      </div>

      <div className="pagelet-label-system-tag-config-cell-option-list">
        <ArrayField name="tagOptionList" component={[OptionItemCell]} />
      </div>
      <div className="pagelet-label-system-tag-config-cell-indicator-field">
        <ObjectField display="none" name="indicatorField" component={[CustomTagIndicatorCpn]} />
      </div>
      <div className="pagelet-label-system-tag-config-cell-another-field">
        <Field display="none" name="anotherField" component={[Input]} />
      </div>
    </div>
  );
});
export default TagConfigCell;
