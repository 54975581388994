import { useRegisterStyle } from '@didi/ec-base';


const useStyle = (prefixCls) => {

	return useRegisterStyle('page-table-panel', () => [
		{
			// base
      '.pagelet-table-panel': {
        position: 'relative',
        background: '#ffffff',
        '.table-container': {
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          marginTop: '20px',
          '.export-button-view': {
            height: '50px',
            position: 'relative',
            '.export-button': {
              position: 'absolute',
              right: '0px',
              top: '9px',
              backgroundColor: '#ff6400'
            }
          },
          '.table-wrapper': {
            // padding: '20px'
          },
          '.theme-text': {
            color: '#FF6400 !important',
          },
        }
      },
      '.team-members-probation-info': {
        display: 'grid',
        gridTemplateColumns: '24px auto',
        alignItems: 'center',
        '.is-probation': {
          position: 'relative',
          left: '-20px',
          top: '-6px',
          display: 'block',
          fontSize: '20px',
          color: '#4070F4',
          background: 'rgba(71, 117, 244, 0.1)',
          padding: '0 4px',
          borderRadius: '5px',
          transform: 'scale(0.5)',
        }
      },
      ['.ant5-drawer-header']: {
        background: 'none',
      },
      ['.ant5-drawer-body']: {
        background: 'none',
      }
		}
	])
};

export default useStyle;
