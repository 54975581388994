import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
const DonutCharts = (props) => {
  const donutRef = useRef(null);
  const { data } = props;

  const initDonut = () => {
    const myDonut = echarts.init(donutRef.current);
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => !!params.value && `${params?.marker} ${params?.value}人`
      },
      legend: {
        bottom: 38,
        icon: 'roundRect',
        itemHeight: 11,
        itemWidth: 11,
        selectedMode: false,
        textStyle: {
          color: '#ffffff',
          fontSize: 16,
          rich: {
            name: {
              fontSize: 10,
              color: 'rgba(0, 0, 0, 0.6)',
              padding: [38, 0, 0, 0]
            },
            num: {
              fontSize: 24,
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.84)',
              padding: [15, 0, 0, 0]
            }
          }
        },
        formatter(name) {
          let tarValue;
          for (let i = 0; i < data.length; i++) {
            if (data[i].name == name) {
              tarValue = data[i]?.percent;
            }
          }
          let v = tarValue;
          return [`{name|${name}}`, `{num|${v}}`].join('\n');
        }
      },
      series: [
        {
          top: -80,
          type: 'pie',
          radius: ['40px', '68px'],
          roundCap: true,
          avoidLabelOverlap: false,
          hoverAnimation: false,
          itemStyle: {
            // borderColor: '#fff',
            // borderRadius: 4,
            // borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          color: [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#82A6F5' },
              { offset: 1, color: '#5889F2' }
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#37C553' },
              { offset: 1, color: '#84E48E' }
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#BC8AFC' },
              { offset: 1, color: '#BD39FF' }
            ])
          ],
          data
        }
      ],
      graphic: [
        {
          type: 'text', // 类型，可以是文字、图片或其它类型
          left: 'center',
          top: 85,
          style: {
            text: '剩余可休',
            fill: 'rgba(0, 0, 0, 0.6)', // 文字的颜色
            fontSize: 12
          }
        },
        {
          type: 'text', // 类型，可以是文字、图片或其它类型
          left: 'center',
          top: 105,
          style: {
            text: '年假总览',
            fill: 'rgba(0, 0, 0, 0.6)', // 文字的颜色
            fontSize: 12
          }
        }
      ]
    };

    option && myDonut.setOption(option);
  }
  useEffect(() => {
    setTimeout(() => {  //节点消失是echarts会抓不到dom节点，设置延时器放进队列中
      initDonut();
    }, 1);
  }, [data]);
  return <div ref={donutRef} style={{ width: 300, height: 275 }} />;
};

export default DonutCharts;
