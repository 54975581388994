import React, { useState, useEffect, useContext } from 'react';
import { TreeSelect, Empty, message, Tooltip } from 'antd';
import { BricksInfoContext } from '@/context';
import { LangTranslate, textEllipsisFormate } from '@/utils';
import { authScopeOrgTree } from '@/services';

import './index.less';
import { useRequest } from 'ahooks';

// 归属部门选择组件
function DepartmentTreeSelect(props) {
  const { maxTagTextLength = 10, maxTagCount = 1, disabled = false } = props;
  const BricksInfoContextVal = useContext(BricksInfoContext);

  const { data:treeData =[], loading :treeLoading} = useRequest(
    async params => {
      const res = await authScopeOrgTree({
        apiHost: BricksInfoContextVal?.apiHost,
        headers:
          {
            ...BricksInfoContextVal?.headers,
            'bricks-function-id': BricksInfoContextVal?.orgDeptTree,
          } || [],
        params: {
          deptIds: ['100001'],
        },
        usePermissionResponse: BricksInfoContext?.usePermissionResponse,
      });
      if (res?.code === '100200') {
        return res.data;
      } else {
        message.warning(LangTranslate('获取数据失败'));
        return []
      }
    },
    {
      refreshDeps:[BricksInfoContext],
      onError: error => {
        console.log('error', error);
        message.warning(LangTranslate('获取数据失败'));
      },
    },
  );


  const treeTitleRender = nodeData => {
    return nodeData?.deptName?.length
      ? textEllipsisFormate(nodeData?.deptName, 30)
      : nodeData?.deptName;
  };

  const maxTagPlaceholder = rest => {
    return <Tooltip title={rest?.map?.(it => it?.label)?.join(',')}>{`+${rest.length}`}</Tooltip>;
  };

  const tagRender = item => {
    return (
      <div className="label-system-pagelet-department-tree-select-tag-render-item">
        <div className="label-system-pagelet-department-tree-select-tag-render-item-title">
          <Tooltip title={item?.label} placement="topLeft">
            {item?.label}
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div className="label-system-pagelet-department-tree-select">
      <TreeSelect
        {...props}
        value={props?.value || undefined}
        allowClear
        multiple={true}
        treeData={treeData}
        placeholder={LangTranslate('请选择')}
        loading={treeLoading}
        style={{ ...props.style }}
        popupClassName={props.popupClassName}
        dropdownStyle={props.dropdownStyle}
        // getPopupContainer={() => document.getElementById('department-tree-select')}
        fieldNames={{
          label: 'deptName',
          value: 'deptCode',
          children: 'childList',
        }}
        maxTagCount={maxTagCount}
        maxTagTextLength={maxTagTextLength}
        // virtual={false}
        treeNodeFilterProp={'name'}
        showSearch={true}
        notFoundContent={
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={LangTranslate('暂无数据')} />
        }
        treeTitleRender={treeTitleRender}
        maxTagPlaceholder={disabled ? undefined : maxTagPlaceholder}
        tagRender={disabled ? props?.tagRender : tagRender}
      />
    </div>
  );
}

export default DepartmentTreeSelect;
