import React, { useState } from 'react';
import classNames from 'classnames';
import Header from '../Header';
import ObjectiveRead from '../ObjectiveRead';
import { DownThickOutlined, UpThickOutlined } from '@didi/iconflow/ec';
import useStyle from './styles';

const RegularCard = props => {
  const { activedDeptInfo = {} } = props; // 部门信息
  const { intervalInfo = {} } = activedDeptInfo; // 周期信息
  const [objecitveList, setObjecitveList] = useState([]); // 目标列表
  const [isExpand, setIsExpand] = useState(false); // 是否展开
  // 样式
  const prefixCls = 'regular-card';
  const wrapSSR = useStyle('regular-card');
  const classes = classNames(prefixCls);

  // 状态枚举
  const stateEnum = {
    500: '（已结束）',
  };

  return wrapSSR(
    <div className={classes}>
      <Header {...props} />
      {intervalInfo?.intervalYear && (
        <div className="intervalShow">
          <span className="intervalShowContext">
            <span>{intervalInfo.intervalYear}</span>
            <span>年目标</span>
            <span>{stateEnum[intervalInfo.state]}</span>
          </span>
        </div>
      )}
      <ObjectiveRead
        {...props}
        isExpand={isExpand}
        setObjecitveList={setObjecitveList}
        objecitveList={objecitveList}
      />
      {/* ddo数量大于2，并且不是待审批中 */}
      {objecitveList.length >= 3 && props?.activedDeptInfo?.state !== Number('3') && (
        <div
          className={`more ${isExpand && 'more-active'}`}
          onClick={() => {
            setIsExpand(!isExpand);
          }}
        >
          <span>{isExpand ? '收起' : `展开剩余${objecitveList.length - 2}项`}</span>
          {isExpand ? <UpThickOutlined size={14} /> : <DownThickOutlined size={14} />}
        </div>
      )}
    </div>,
  );
};

export default RegularCard;
