import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-team-calendar', () => [
		{
			// base
			[`.${prefixCls}`]: {
        color: chartToken.$font_color,

        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute'
        }
			}
		}
	]);
};

export default useStyle;
