import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-team-care', () => [
		{
			// base
			[`.${prefixCls}`]: {
        width: '100%',
        height: '100%',
        padding: '18px 7px 0px 16px',
        background: '#ffffff',
        borderRadius: '12px',
        [`.${prefixCls}-title`]: {
          // display: 'flex',
          // alignItems: 'center',
          color: 'rgba(0, 0, 0, 0.84)',
          paddingRight: '8px',
          position: 'relative',
          [`.${prefixCls}-url-icon`]: {
            marginLeft: '2px',
          },
          ['.title-info']:{
            fontSize: '20px',
            fontWeight: '600',
          },
          ['.ec-ehr-base-card-title-text']:{
            display: 'flex',
            alignItems: 'center',
          },
          [`.${prefixCls}-title-filter`]: {
            display: 'flex',
            alignItems: 'center',
            userSelect: 'none',
            marginLeft: '16px',
            [`.${prefixCls}-time`]: {
              marginRight: '6px',
              borderRadius: '8px',
              background: 'rgba(0,0,0,0.04)',
              cursor: 'pointer',
              '.time-switch-popconfirm-content-item': {
                display: 'flex',
                padding: '5px 0px',
                position: 'relative',
                '.prev,.next': {
                  position: 'absolute',
                  top: '5px',
                  width: '20px',
                  height: '22px',
                  textAlign: 'center',
                  lineHeight: '24px',
                  zIndex: '99',
                },
                '.prev:hover, .next:hover':{
                  background: 'rgba(0, 0, 0, 0.08)',
                  borderRadius: '6px',
                  cursor: 'pointer'
                },
                '.prev': {
                  left: '4px'
                },
                '.next': {
                  right: '4px'
                },
                '.ant5-picker': {
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  '.ant5-picker-input input':{
                    textAlign: 'center',
                    width: '80px',
                    margin: '0 25px',
                    '&:hover':{
                      background: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '6px',
                      cursor: 'pointer'
                    }
                  }
                },
                '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                  boxShadow: 'none',
                }
              }
            },
            [`.${prefixCls}-scope`]: {
              marginRight: '6px',

              ['.ant-select, .ant5-select']: {
                ['.ant-select-selector, .ant5-select-selector']: {
                  padding: '5px 6px',
                  borderRadius: '8px',
                  background: 'rgba(0,0,0,0.04)',
                  border: 'none',
                  ['.ant-select-selection-item, .ant5-select-selection-item']: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                  }
                },
                ['.ant-select-arrow, .ant5-select-arrow']: {
                  right: '6px',
                  width: '14px',
                  height: '14px',
                  color: 'rgb(12, 21, 46)',
                },

                ['.ant-select-dropdown, .ant5-select-dropdown']: {
                  width: 'auto !important',
                  padding: '4px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                  ['.ant-select-item, .ant5-select-item']: {
                    minWidth: '100px !important',
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['&:hover']: {
                      background: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  ['.ant-select-item-option-selected, .ant5-select-item-option-selected']: {
                    color: '#FF6400',
                    background: '#FFF7F3',
                    fontWeight: '500',
                    ['&:hover']: {
                      background: '#FFF7F3'
                    }
                  }
                },
                ['&:hover ']: {
                  ['.ant-select-selector, .ant5-select-selector']: {
                    background: 'rgba(0, 0, 0, 0.08)',
                  },
                  ['.ant-select-arrow, .ant5-select-arrow']: {
                    opacity: '1',
                  },
                },
                ['&.ant-select-focused .ant-select-selector, &.ant5-select-focused .ant5-select-selector']: {
                  border: 'none',
                  boxShadow: 'none'
                }
              }
            },
            [`.${prefixCls}-summary`]: {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0px',
              color: 'rgba(0, 0, 0, 0.72)',
            }
          },
          ['.title-msg']: {
            position:'absolute',
            right: '16px',
            top: '16px',
            fontSize: '14px',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.84)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '32px',
            padding: '0 6px',
            color: 'rgba(0, 0, 0, 0.72)',
          },
          ['.title-msg:hover']:{
            cursor: 'pointer',
            borderRadius: '8px',
            background: 'rgba(0, 0, 0, 0.04)',
          }
        },
        [`.${prefixCls}-filter`]: {
          display: 'flex',
          alignItems: 'center',
          userSelect: 'none',
          padding: '16px 0px',
          [`.${prefixCls}-time`]: {
            marginRight: '6px',
            borderRadius: '8px',
            background: 'rgba(0,0,0,0.04)',
            cursor: 'pointer',
            '.time-switch-popconfirm-content-item': {
              display: 'flex',
              padding: '5px 0px',
              position: 'relative',
              '.prev,.next': {
                position: 'absolute',
                top: '5px',
                width: '20px',
                height: '22px',
                textAlign: 'center',
                lineHeight: '26px',
                zIndex: '99',
              },
              '.prev:hover, .next:hover':{
                background: 'rgba(0, 0, 0, 0.08)',
                borderRadius: '6px',
                cursor: 'pointer'
              },
              '.prev': {
                left: '4px'
              },
              '.next': {
                right: '4px'
              },
              '.ant5-picker': {
                background: 'none',
                border: 'none',
                padding: '0px',
                '.ant5-picker-input input':{
                  textAlign: 'center',
                  width: '80px',
                  margin: '0 25px',
                  '&:hover':{
                    background: 'rgba(0, 0, 0, 0.08)',
                    borderRadius: '6px',
                    cursor: 'pointer'
                  }
                }
              },
              '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                boxShadow: 'none',
              }
            }
          },
          [`.${prefixCls}-scope`]: {
            marginRight: '6px',

            ['.ant-select, .ant5-select']: {
              ['.ant-select-selector, .ant5-select-selector']: {
                padding: '5px 6px',
                borderRadius: '8px',
                background: 'rgba(0,0,0,0.04)',
                border: 'none',
                ['.ant-select-selection-item, .ant5-select-selection-item']: {
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: 0,
                  color: 'rgba(0, 0, 0, 0.84)',
                }
              },
              ['.ant-select-arrow, .ant5-select-arrow']: {
                right: '6px',
                width: '14px',
                height: '14px',
                color: 'rgb(12, 21, 46)',
              },

              ['.ant-select-dropdown, .ant5-select-dropdown']: {
                width: 'auto !important',
                padding: '4px',
                borderRadius: '8px',
                background: '#FFFFFF',
                boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                ['.ant-select-item, .ant5-select-item']: {
                  minWidth: '100px !important',
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: 0,
                  color: 'rgba(0, 0, 0, 0.84)',
                  borderRadius: '4px',
                  ['&:hover']: {
                    background: 'rgba(0, 0, 0, 0.04)'
                  }
                },
                ['.ant-select-item-option-selected, .ant5-select-item-option-selected']: {
                  color: '#FF6400',
                  background: '#FFF7F3',
                  fontWeight: '500',
                  ['&:hover']: {
                    background: '#FFF7F3'
                  }
                }
              },
              ['&:hover ']: {
                ['.ant5-select-selector, .ant-select-selector']: {
                  background: 'rgba(0, 0, 0, 0.08)',
                },
                ['.ant-select-arrow, .ant5-select-arrow']: {
                  opacity: '1',
                },
              },
              ['&.ant-select-focused .ant-select-selector, &.ant5-select-focused .ant5-select-selector']: {
                border: 'none',
                boxShadow: 'none'
              }
            }

          },
          [`.${prefixCls}-summary`]: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0px',
            color: 'rgba(0, 0, 0, 0.72)',
          }
        },
        ['.ant5-spin-nested-loading']: {
          height: '100%',
          ['.ant5-spin-container']: {
            height: '100%',
            paddingBottom: '16px',
          }
        },

        [`.${prefixCls}-content`]: {
          height: '100%',
          overflowY: 'auto',
          paddingRight: '7px',
          // boxShadow: 'inset 0px 6px 10px -8px rgba(0, 0, 0, 0.08)',
          ['&::-webkit-scrollbar']: {
            display: 'block',
            width: '2px',
          },
          ['&::-webkit-scrollbar-track']: {
            background: 'transparent',
          },

          [`.${prefixCls}-content-item`]: {
            marginBottom: '16px',
            ['.content-item-header']: {
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              ['svg']: {
                marginRight: '4px',
              },
              ['.title']: {
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: 'normal',
                letterSpacing: 0,
                color: 'rgba(0, 0, 0, 0.84)',
              },
              ['.num']: {
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: 'normal',
                letterSpacing: 0,
                color: 'rgba(0, 0, 0, 0.7)',
                marginLeft: '4px',
              }
            },
            ['.content-item-body']: {
              ['.care-list']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '8px',
                ['.care-card']: {
                  position: 'relative',
                  height: '56px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 12px',
                  borderRadius: '8px',
                  background: 'rgba(245, 245, 245, 0.6)',
                  boxSizing: 'border-box',
                  border: '1px solid transparent',
                  overflow: 'hidden',
                  ['.care-card-avatar .ant5-avatar-image']: {
                    border: '1px solid #E5E5E5',
                  },
                  ['.care-card-content']: {
                    width: 'calc(100% - 52px)',
                    marginLeft: '8px',
                    ['.care-card-content-item']: {
                      display: 'flex',
                      alignItems: 'center',
                      ['.care-card-content-name']: {
                        // maxWidth: 'calc(100% - 52px)',
                        // flex: 1,
                        marginRight: '4px',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '18px',
                        letterSpacing: 0,
                        color: '#333333',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        ['.anniversary-name']: {
                          maxWidth: '52px',
                        }
                      },
                      ['.anniversary']: {
                        padding: '0px 4px',
                        borderRadius: '4px',
                        background: '#FFFFFF',
                        // border: '0.5px solid rgba(255, 100, 0, 0.5)',
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '16px',
                        letterSpacing: 0,
                        color: '#FF6400',
                        marginLeft: '4px'
                      },
                      ['.care-card-content-date']: {
                        display: 'flex',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: 0,
                        color: 'rgba(0, 0, 0, 0.72)',
                        marginTop: '4px',
                      },
                      ['.message']:{
                        display: 'none',
                        position: 'absolute',
                        right: '2px',
                        bottom: '10px',
                        fontSize: '12px',
                        lineHeight: '12px',
                        color: '#FF6400',
                        ['svg']:{
                          paddingTop: '3px',
                        }
                      }
                    }
                  },

                  ['&:hover']: {
                    color: '#FF6400',
                    background: 'rgba(255, 100, 0, 0.02)',
                    border: '1px solid #FF6400',
                    cursor: 'pointer',
                    ['.care-card-content .message']:{
                      display: 'block',
                    }
                  }
                }
              },
              ['.operation']: {
                display: 'flex',
                justifyContent: 'end',
                marginTop: '8px',
                fontSize: '12px',
                fontWeight: 'normal',
                textAlign: 'center',
                lineHeight: 'normal',
                letterSpacing: 0,
                color: 'rgba(0, 0, 0, 0.6)',
                cursor: 'pointer',
                '.ant-pagination-item-active a': {
                  color: '#ff6400',
                  fontWeight: 'normal',
                },
                'li':{
                  height: '20px',
                  minWidth: '20px',
                  lineHeight: '20px',
                  marginRight: '4px',
                  'a':{
                    fontSize: '12px',
                    lineHeight: '20px',
                    textAlign: 'center',
                  }
                },
                ['.expand, .collapse']: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },

                ['.ant-pagination-mini']: {
                  textAlign: 'right',
                  ['.ant-pagination-item']: {
                    borderRadius: '4px',

                    '&:hover': {
                      background: '#fff'
                    },

                    ['a']: {
                      padding: 0,
                    }
                  },
                  ['.ant-pagination-prev, .ant-pagination-next']: {
                    '&:hover': {
                      background: '#fff'
                    },
                    '.ant-pagination-item-link': {
                      background: 'rgba(255, 255, 255, 1)'
                    }
                  }
                }
              }
            }
          },
        },

        [`.${prefixCls}-content-null`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          ['&::-webkit-scrollbar']: {
            display: 'block',
            width: '2px',
          },
          ['&::-webkit-scrollbar-track']: {
            background: 'transparent',
          },
          ['img']: {
            width: '120px'
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
          ['.text-item-2']: {
            marginTop: '6px',
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#ABACB3',
          },
        },

        [`.${prefixCls}-content-err`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          ['&::-webkit-scrollbar']: {
            display: 'block',
            width: '2px',
          },
          ['&::-webkit-scrollbar-track']: {
            background: 'transparent',
          },
          ['img']: {
            width: '120px',
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
          // ['.text-item-2']: {
          //   marginTop: '6px',
          //   fontSize: '12px',
          //   fontWeight: 'normal',
          //   lineHeight: '14px',
          //   letterSpacing: 0,
          //   color: '#ABACB3',
          // },
        },
			}
		}
	]);
};

export default useStyle;
