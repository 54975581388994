import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { di18n } from '@ultra/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Must from '../../images/must.svg';
import classnames from 'classnames';
import './index.scss';
import {
  setValidateResult,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const { intl } = di18n;
const { TextArea } = Input;

const textareaCombineWidget = props => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    attributeData,
    respondentsGroup,
    validateFlag,
    surveyBusType,
  } = useSelector((state) => state.questionContent);

  const dispatch = useDispatch();
  const {
    label,
    labelStyle,
    tips,
    hidden,
    number,
    related,
    required
  } = attributeData[widgetKey];

  const rules = validateRules[widgetKey];
  const requiredIcon = !!((related || number === 1));

  const [value, setValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup) {
      let input = {};
      respondentsGroup.map((item, i) => {
        input[item.respondentCode] = '';
      });
      if (draftData[widgetKey]) {
        const widgetDraftData = draftData[widgetKey];
        for (const i in widgetDraftData) {
          const val = (widgetDraftData[i].length > 0) ? widgetDraftData[i][0].value : '';
          input = { ...input, [i]: val };
          dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: i, data: widgetDraftData[i] }));
        }
        // 数据校验
        const widgetValidate = validateFun(rules, widgetDraftData);
        let otherValidate;

        const validateData = { ...widgetValidate, otherResult: otherValidate };
        dispatch(setValidateResult({ id: widgetKey, data: validateData }));
      }
      setValue(input);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      for (const i in updateData) {
        dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: i, data: updateData[i] }));
      }
      dispatch(updateCurrentSubject({ data: widgetKey }));
      // 数据校验
      const widgetValidate = validateFun(rules);
      let otherValidate;

      const validateData = { ...widgetValidate, otherResult: otherValidate };
      dispatch(setValidateResult({ id: widgetKey, data: validateData }));
    }
  }, [updateData]);
  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  const handleOnChange = (e, item) => {
    setValue({ ...value, [item]: e.target.value });
  };

  const handleBlur = (v, item) => {
    const data = {};
    data[item] = [
      {
        option: '',
        value: value[item] ? value[item]?.replace(/(\s*$)/g, '') : ''
      }
    ];
    setUpdateData(data);
  };

  const formatterCount = (e = '') => {
    const { max, min } = rules.controlRegular;
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    const maxDefault= 2000;
    const maxLength = max || maxDefault;
    if (min && Number(min)) {
      return {
        formatter() {
          return `${intl.t('为了反馈更有效，')}${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
        }
      };
    }
    return {
      formatter() {
        return `${count}/${maxLength}`;
      }
    };
  };

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const { required } = rule;
    const { min } = rule.controlRegular;
    const max = rule.controlRegular.max ? rule.controlRegular.max : 2000;
    const current = content || value;

    const msgArr = [
      intl.t('亲，此项必填的哈~')
    ];
    if (max && max !== 0) {
      for (const i in current) {
        // 草稿模式的数据格式（{option:,value:}）
        if (content) {
          if (current[i].length > 0) {
            if (current[i][0].value.trim().length > max) {
              console.log('draft max');
              return {
                error: false,
                msg: msgArr[0]
              };
            }
          }
        } else if (current[i].trim().length > max) {
          console.log('user max');
          return {
            error: false,
            msg: msgArr[0]
          };
        }
      }
    }

    if (min && min !== 0) {
      for (const i in current) {
        // 草稿模式的数据格式（{option:,value:}）
        if (content) {
          if (current[i].length > 0) {
            if (current[i][0].value.trim().length < min) {
              console.log('draft min', current[i][0].value.trim());
              return {
                error: false,
                msg: msgArr[0]
              };
            }
          }
        } else if (current[i].trim().length < min) {
          console.log('user min', current[i].trim());
          return {
            error: false,
            msg: msgArr[0]
          };
        }
      }
    }

    if (required) {
      for (const i in current) {
        // 草稿模式的数据格式（{option:,value:}）
        if (content) {
          if (current[i].length > 0) {
            if (current[i][0].value.trim().length === 0) {
              console.log('draft required', current[i][0].value.trim());
              return {
                error: false,
                msg: msgArr[0]
              };
            }
          }
          if (current[i].length === 0 || Object.keys(current).length < respondentsGroup.length) {
            return {
              error: false,
              msg: msgArr[0]
            };
          }
        } else if (current[i].trim().length === 0) {
          console.log('user required', current[i].trim());
          return {
            error: false,
            msg: msgArr[0]
          };
        }
      }
    }
    return {
      error: true,
      msg: ''
    };
  };

  const renderInput = () => (
    <>
      <div>
        {
            respondentsGroup.map((item, i) => (
              <div style={{ padding: '6px 0' }} key={`${widgetKey}combine-textarea-div${item.respondentCode}`}>
                <div className="combine-textarea-respondent" style={{ paddingBottom: '8px', color: '#0C152E' }}>{item.activityRespondentFullName}</div>
                <div className={classnames('textarea-combine-textarea', {
                    'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
                    'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
                  })}>
                  <TextArea
                    key={`${widgetKey}combine-textarea${item.respondentCode}`}
                    value={value ? value[item.respondentCode] : ''}
                    placeholder={rules?.required ? '必填' : ''}
                    rows={4}
                    maxLength={2000} // 根据产品要求所有的输入框控制超出2000字不可输入
                    showCount={formatterCount(value ? value[item.respondentCode] : '')}
                    onChange={e => handleOnChange(e, item.respondentCode)}
                    onBlur={e => handleBlur(e, item.respondentCode)}
                  />
                </div>
              </div>
            ))
          }
      </div>
    </>
  );

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          );
        }
      }
    }
    return '';
  };

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden}`}
        style={{ position: 'relative', paddingTop: '14px' }}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={`div-label ${related}`}
        >
          <div className={`textarea-div-label-${required}-${requiredIcon}`}>
            <img src={Must} />
          </div>
          {
            number ? (
              <div>
                {number}
                .
                {' '}
              </div>
            ) : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-content radio-content-related-${related}`}>
          {tips ? (
            <div className="pc-question-tip">{decodeURIComponent(tips)}</div>
          ) : null}

          {renderInput()}
        </div>

        {renderErrorMsg()}
      </div>
      {/* <Divider dashed /> */}
    </>
  );
};

export default textareaCombineWidget;
