/* eslint-disable no-tabs */
import React, {useState, useRef, useEffect} from 'react';
import { getLocale, Avatar, Tooltip, ConfigProvider, Modal, Button, getLang } from '@didi/ec-base';
import manifest from '../../../../manifest.json';
import classNames from 'classnames';
import useModalStyle from './style/modal';
import useStyle from './style';
import RightThickSvg from '../../assets/rightThick.svg';
import ResignSvg from '../../assets/resign.svg';
import { getOperateLog } from '../../services';

const localLocales = manifest.locales;

const FaceProcess = (props) => {
  /*
		data: 面试流程数据,
		onClick: 卡片点击事件
	*/
  const {
    data = [], onClick, showTitle = true, activedStyle = {}, footerRender, bodyRender, size, resumeEvaluateAuth, scroll = true, locales
  } = props;

  const prefixCls = 'interview-face-process';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls, {
    'interview-face-scroll-content': scroll
  });
  const ref = useRef();

  useEffect(() => {
    if (ref?.current && scroll) {
      ref.current.parentNode.scrollLeft = ref?.current?.offsetWidth > 0 ? ref?.current?.offsetWidth - ref?.current?.parentNode?.offsetWidth + 10 : 0;
    }
  }, [ref?.current, scroll]);

  return wrapSSR(
    <div className={classes}>
      <div className="face-process-content" ref={ref}>
        {
          data?.length > 0 && data?.map((item, index) => (
            <FaceProcessItem
              noArrow={index === data.length - 1}
              {...props}
              data={item}
              onClick={() => {
                if (onClick) {
                  onClick(item, index);
                }
              }}
              key={index}
              index={index}
            />
          ))
        }
      </div>
    </div>
  );
};

const FaceProcessItem = (props) => {
  const {
    onClick,
    noArrow,
    showTitle,
    title = '面试官',
    footerRender,
    bodyRender,
    resumeEvaluateAuth,
    apiHost,
    headers,
    data: {
      imageUrl,
      interviewerName,
      type,
      result,
      interviewerId,
      interviewTypeDesc,
      interviewerStatus,
      score,
      suggestJobLevel,
      professEvaluationDetail,
      generalEvaluationDetail,
      leaderEvaluationDetail
    },
    size,
    index,
    activeKey,
    employeeId,
    locales
  } = props;

  const { getPrefixCls } = React.useContext(ConfigProvider.ConfigContext);
  const modalPrefixCls = getPrefixCls('modal', null);
  const wrapModalSSR = useModalStyle(modalPrefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const [showModal, setShowModal] = useState(false);

  const getInterviewStatus = (result) => {
    let color, text;
    switch (result) {
      case '0':
        color = '#0A70F5';
        text = locale.interviewArranged;
        break;
      case '1':
        color = '#666666';
        text = locale.pending;
        break;
      case '2':
        color = '#00B365',
        text = locale.pass;
        break;
      case '3':
        color = '#F5483B';
        text = locale.fail;
      default:
        color = '';
        text = '';
    }

  return {
    color,
    text
  }
}


  const handleReview = () => {
    setShowModal(true);

    getOperateLog(apiHost, headers, {
      operateName: "点击",
      operateContent: "面试关系",
      operateParams: {
        "操作": "查看面评",
        "被预览员工工号": employeeId,
      }
    })
  };

  const handleCancel = () => {
    setShowModal(false);
  }

  return (
    <div className='face-item-container'>
      <div className='line'>
        <Tooltip title={interviewTypeDesc}>
          <div className={classNames('line-title', {
            'line-title-en': getLang() === 'en-US'
          })}>{interviewTypeDesc}</div>
        </Tooltip>
        <div className='line-right-part'></div>
      </div>
      <div className={`face-items-box ${activeKey === index ? 'active-box' : ''}`} onClick={() => onClick && onClick(props.data)}>
        <div className="face-item-content">
          <Avatar size="middle" src={imageUrl} className="face-avatar-box"/>
          {/* 离职标记 */}
          {interviewerStatus === "I" && (
            <div className={classNames("item-tag-con", {
              'item-tag-auth': resumeEvaluateAuth
            })}>
              <ResignSvg className="card-icon" />
            </div>
          )}
          <div className="face-name-box">
            <Tooltip title={interviewerName}>
              <span>{interviewerName}</span>
            </Tooltip>
          </div>
          <div className="face-workNo-box">
            <span>{interviewerId || '-'}</span>
          </div>
          {footerRender && footerRender(data, index)}
          {/* {
            !footerRender && (
              <div className="face-type-box">
                <span>{type}</span>
              </div>
            )
          } */}

          {
            resumeEvaluateAuth && (
              <div className={classNames('interview-evaluation-container', {
                'interview-evaluation-container-en' : getLang() === 'en-US'
              })} onClick={handleReview}>
                {locale.interviewEvaluation}
                <RightThickSvg className="card-icon" />
              </div>
            )
          }
        </div>
      </div>


      {
        wrapModalSSR(
          <Modal
            title={interviewTypeDesc}
            width={800}
            visible={showModal}
            onCancel={handleCancel}
            destroyOnClose
            maskClosable={false}
            className='resume-modal-container'
            footer={
              <Button onClick={handleCancel} type="primary">{locale.close}</Button>
            }
          >
            <div className="interview-container">
              <div className='interview-result'>
                <div className='interview-single'>
                  {locale.interviewResults}:
                  <span style={{ color: getInterviewStatus(result).color }}>{getInterviewStatus(result).text}</span>
                </div>

                <div className='interview-single'>
                  {locale.interviewRating}:
                  <span>{score}</span>
                </div>

                <div className='interview-single'>
                  {locale.suggestedJobLevel}:
                  <span>{suggestJobLevel}</span>
                </div>
              </div>

              {
                professEvaluationDetail && (
                  <div className='interview-detail'>
                    <div className='interview-detail-title'>
                      {locale.expertEvaluation}
                    </div>

                    <div className='interview-detail-content'>
                      {professEvaluationDetail}
                    </div>
                  </div>
                )
              }

              {
                generalEvaluationDetail && (
                  <div className='interview-detail'>
                    <div className='interview-detail-title'>
                      {locale.generalEvaluation}
                    </div>

                    <div className='interview-detail-content'>
                      {generalEvaluationDetail}
                    </div>
                  </div>
                )
              }

              {
                leaderEvaluationDetail && (
                  <div className='interview-detail'>
                    <div className='interview-detail-title'>
                      {locale.managerEvaluation}
                    </div>

                    <div className='interview-detail-content'>
                      {leaderEvaluationDetail}
                    </div>
                  </div>
                )
              }

            </div>

          </Modal>
        )
      }
    </div>
  );
};

export default FaceProcess;
