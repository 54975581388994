import React, { useEffect, useState, useRef } from 'react';
import { getLocale, ConfigProvider, en_US, zh_CN, Spin, message } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import { ListViewOutlined, EditOutlined, FileDownloadOutlined, LoadOutlined, LandOutlined } from '@didi/iconflow/ec';
import ScopeFilter from './components/ScopeFilter';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import screenShots from '@didi/screenshots';

import Flow from './components/Flow';
import List from './components/List';

import './style.scoped.less';
import './index.less';
import dayjs from 'dayjs';
// 图片建议手动上传到 gift 使用

const localLocales = manifest.locales || {};

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, auth }
  } = props;
  const baseHeight = 628;
  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN');
  const [providerLocale, setProviderLocale] = React.useState({});
  const [data, setData] = useState([]);
  const [containerHeight, setContainerHeight] = useState(600);
  const [currentTab, setCurrentTab] = useState('flow');
  const [timeRangeType, setTimeRangeType] = useState('all');
  const [downLoading, setDownLoading] = useState(false);
  const [initError, setInitError] = useState(false);
  const downLoadingRef = useRef(false);

  const [timeRangeTypeList, setTimeRangeTypeList] = useState([]);
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionId: auth.id,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
  };

  const handleRequest = async () => {
      await PageletServices.getWaterfall(apiHost, newHeaders).then(res => {
        const { code, data } = res || {};
        if (res && code === '100200' && data) {
          setData(data.itemList);
          const yearList = [{ code: 'all', name: '全部' }];
          data?.years?.map((v) => {
            yearList.push({
              code: v,
              name: v + '年'
            });
          });
          setTimeRangeTypeList(yearList);
        } else {
          setInitError(true);
          console.log(res,999)

        }
      }).catch((err)=>{
        console.log(err)
      });
  };


  useEffect(() => {
    setContainerHeight(data.length * 80);
  }, [data])

  // 接口请求示例
  useEffect(() => {
    handleRequest();
    if (lang !== 'zh-CN') {
      setProviderLocale(en_US)
    } else {
      setProviderLocale(zh_CN)
    }
  }, []);

  const prefixCls = 'pagelet-personal-didi';

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  const download = () => {
    if (downLoadingRef.current) return;
    downLoadingRef.current = true;
    setDownLoading(true);
    console.log(document.querySelector(`#${prefixCls}-content-wrapper`),)
    setTimeout(() => {
      screenShots({
        selector: document.querySelector(`#${prefixCls}-content-wrapper`),
        type: 'png',
        fileName: `我与滴滴${dayjs().format('YYYYMMDDHH')}`,
        chunkHeight: 10000
      }).then(() => {
        message.success('下载成功');
      }).finally(() => {
        downLoadingRef.current = false;
        setDownLoading(false);
      })
    })
  }
  const onTimeRangeType = (v) => {
    setTimeRangeType(v);
  }

  const changeViewModel = (v) => {
    setCurrentTab(v);
    setTimeRangeType('all');
  }

  return (
    <ConfigProvider
      prefixCls='ant5'
      locale={providerLocale}
    >
      <div className={classes}>
        <div className={`${prefixCls}-header`}>
          <div>
            我与DiDi
          </div>
          <div className={`${prefixCls}-header-warp`}>

            {((currentTab === 'flow' || downLoading) && localStorage.getItem('downloading') === 'debugger') && <>
              { !downLoading ? <div className="warp-icon">
                <FileDownloadOutlined onClick={download}  color='rgba(0, 0, 0, 0.9)' size={16}/>
              </div> : <div className={`${prefixCls}-download-loading`}>
                下载中，请耐心等待...<div className="warp-icon" style={{display: 'inline-block', marginRight: '0px'}}>
                  <Spin style={{fontSize: '16px', marginTop: '4px'}} className={`${prefixCls}-header-downloading`} />
              </div>
              </div> }
              </>}
            <div className="warp-icon-warp">
              <div
                className="warp-icon"
                onClick={() => changeViewModel('flow')}
                style={{ background: `${currentTab === 'flow' ? 'rgba(255, 100, 0, 0.1)' : 'rgba(0, 0, 0, 0.04)'}` }}>
                <LandOutlined color={currentTab === 'flow' ? "#FF6400" : "rgba(0, 0, 0, 0.72)"} size={16}/>
              </div>
              <div
                className="warp-icon"
                onClick={() => changeViewModel('list')}
                style={{ background: `${currentTab === 'list' ? 'rgba(255, 100, 0, 0.1)' : 'rgba(0, 0, 0, 0.04)'}` }}>
                <ListViewOutlined color={currentTab === 'list' ? "#FF6400" : "rgba(0, 0, 0, 0.72)"} size={16}/>
              </div>
            </div>

            {/* <div className="warp-icon">
              <EditOutlined />
            </div> */}
            {timeRangeTypeList.length > 0 && <ScopeFilter
              prefixCls={prefixCls}
              subordinate={timeRangeType}
              list={timeRangeTypeList}
              onScopeChange={onTimeRangeType}
            />}
          </div>

        </div>
        <div
          style={{ minHeight: '360px', maxHeight: '568px' ,height: 'auto', overflow: 'auto', position: 'relative' }}
          className={currentTab === 'flow' ? `${prefixCls}-flow` : ''}
        >
          <>
            {currentTab === 'flow' && !initError &&<div style={{ height: currentTab === 'flow' ? 'auto' : 0 }}>
              <Flow apiHost={apiHost} newHeaders={newHeaders} data={data} prefixCls={prefixCls} baseHeight={baseHeight} containerHeight={containerHeight} year={timeRangeType} timeRangeTypeList={timeRangeTypeList} />
            </div>}
            {currentTab === 'list' && !initError && <div style={{ height: currentTab === 'list' ? 'auto' : 0 }}>
              <List apiHost={apiHost} newHeaders={newHeaders} prefixCls={prefixCls} year={timeRangeType} providerLocale={providerLocale}/>
            </div>}
            {initError && <div className={`${prefixCls}-error`}>
            <img src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didiError1.png' alt="" srcset="" />
            <span>服务不可用，请稍后再试</span>
            </div>}
          </>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
