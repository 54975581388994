import React, { useRef, useEffect, useState } from 'react';
import * as echarts from 'echarts';

function AutoChart(props) {
  const { option = {}, chartRef } = props;
  const innerChartRef = useRef();
  const [chart, setChart] = useState();
  const handleResize = () => chart?.resize();

  const init = () => {
    if (chart) {
      window.removeEventListener('resize', handleResize);
    }

    const chartInstance = echarts?.init?.(innerChartRef.current);
    chartInstance.setOption(props?.option);
    {
      const { xData, yData, peakIndex, genLocDot, defaultDot } = props?.option;

      chartInstance?.on?.('showTip', function (params) {
        if (params.type === 'showtip') {
          const dataIndex = params.dataIndex;
          const newDot = genLocDot([xData?.[dataIndex], yData?.[dataIndex]]);
          // 更新markPoint的data配置来显示圆点
          chartInstance.setOption({
            series: [
              {
                markPoint: {
                  data: [...defaultDot, ...newDot],
                },
              },
            ],
          });
        }
      });

      chartInstance?.on?.('hideTip', function () {
        chartInstance.setOption({
          series: [
            {
              markPoint: {
                data: defaultDot, // 清除圆点
              },
            },
          ],
        });
      });
    }
    {
      chartInstance.on('mouseover', e => {
        if (e.componentType === 'xAxis') {
          let tipNameDom = document.getElementById('xAxisTipNameDom');
          if (!tipNameDom) {
            console.log('tipNameDom null');
            return;
          }
          tipNameDom.style.display = 'block';
          tipNameDom.style.position = 'absolute';
          tipNameDom.style.top = e.event.offsetY - 20 + 'px';
          tipNameDom.style.left = e.event.offsetX - 10 + 'px';
          tipNameDom.innerHTML = e.value;
        }
      });

      chartInstance.on('mouseout', function (params) {
        if (params.componentType === 'xAxis') {
          let tipNameDom = document.getElementById('xAxisTipNameDom');
          // setTimeout(() => {
          tipNameDom.style.display = 'none';
          // }, 300);
        }
      });
    }
    window.addEventListener('resize', handleResize);
    setChart(() => chartInstance);
  };
  useEffect(() => {
    if (!chartRef) {
      return;
    }
    chartRef.current = chart;
  }, [chart]);
  useEffect(() => {
    init();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props?.option]);

  useEffect(() => {
    setTimeout(() => {
      init();
    });
  }, [props?.depResize, chart]);

  return (
    <>
      <div ref={innerChartRef} style={{ height: '100%', width: '100%' }} />
      <div
        id="xAxisTipNameDom"
        style={{
          minWidth: '1em',
          minHeight: '32px',
          padding: '6px 8px',
          color: '#fff',
          textAlign: 'start',
          textDecoration: 'none',
          wordWrap: 'break-word',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          borderRadius: '6px',
          boxShadow:
            '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          boxSizing: 'border-box',
          fontSize: '12px',
          'max-height': '180px',
          'max-width': '288px',
          overflow: 'auto',
          display: 'none',
        }}
      />
    </>
  );
}

export default AutoChart;
