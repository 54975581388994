import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('bar-chart', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        width: '200px',
        display: 'flex',

        ['.eachState']: {
          flex: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'end',

          ['.graphical']: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '27px 15px 0',
            position: 'relative',

            ['.numericalValue']: {
              marginBottom: '4px',
              fontFamily: 'DIDI FD',
              textWrap: 'nowrap',
              position: 'absolute',
              transform: 'translateY(-100%)',

              // 数字部分
              ['.num']: {
                fontWeight: 900,
                fontSize: '16px',
              },

              // 人
              ['.people']: {
                fontWeight: 400,
                fontSize: '12px',
              },

              // 百分比
              ['.percentage']: {
                fontWeight: 400,
                fontSize: '10px',
              },
            },

            ['.bar']: {
              width: '20px',
              borderRadius: '4px',
            },
          },

          ['.checked']: {
            borderRadius: '4px',
            background: 'rgba(255, 148, 80, 0.1)',
          },

          // 横线
          ['.line']: {
            width: '100%',
            height: '1px',
            margin: '1px 0 2px',
            background: 'rgba(149, 63, 16, 0.24)',
          },

          // 状态按钮
          ['.ant5-radio-wrapper']: {
            marginInlineEnd: '0px',

            ['.ant5-radio .ant5-radio-inner']: {
              width: '12px',
              height: '12px',
            },

            ['span:last-child']: {
              paddingInlineStart: '2px',
              paddingInlineEnd: '0px',
            },
          },
        },
      },
    },
  ]);
};

export default useStyle;
