import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import errorIcon from "../../images/errorIcon.png";
import { getErrorLocationSort } from "../../utils/Tool";
import { setErrorListSoreMap } from "../../store/questionnaireNew/questionContentSlice";
import "./index.scss";

// 为了保证返回顺序值准确性，所以用时间戳来确定返回值准确性
let timestamp;
// pc问卷右侧进度条
const questionnaireErrorLocation = (props) => {
  const dispatch = useDispatch();

  const { questionnaireId, respondentTypeCode } = props;

  const {
    errorListMap,
    validateFlag,
    attributeData,
    generalMultiData,
    currentRespondentCode,
    processRate,
    initRequired,
    errorListSoreMap,
  } = useSelector((state) => state.questionContent);

  const renderHiddenNumber = (hiddenKey) => {
    const relatedId = attributeData[hiddenKey]?.relatedKey;
    const relatedOption =
      generalMultiData[currentRespondentCode]?.[relatedId]?.[0]?.option;
    const hiddenNumber =
      attributeData?.[hiddenKey]?.hiddenSubjectIndex?.[relatedOption];
    const relatedNumber = attributeData?.[relatedId]?.number;
    return hiddenNumber ? relatedNumber + "-" + hiddenNumber : "";
    // return relatedNumber + '-' + hiddenNumber
  };

  useEffect(() => {
    if (validateFlag) {
      getErrorListSort();
    }
  }, [errorListMap, validateFlag]);

  const renderString = (item) => {
    return attributeData[item].number
      ? attributeData[item].number
      : renderHiddenNumber(item);
  };

  const getErrorListSort = async () => {
    timestamp = Date.now();
    const { data } = await getErrorLocationSort(
      questionnaireId,
      respondentTypeCode,
      errorListMap,
      timestamp
    );
    if (timestamp == data.timestamp) {
      dispatch(setErrorListSoreMap({ data: data.orderSubjects }));
    }
  };
  const renderNumber = () => {
    return errorListSoreMap.map((item, i) => {
      if (i < 5) {
        return (
          <div style={{ padding: "6px 0" }} key={`error-btn-${item}`}>
            <div className="error-btn-wapper">
              <Button
                type="primary"
                shape="circle"
                // href={`#${item}`}
                onClick={() => {
                  // 合并问卷增加历史页面，为了浏览器退回操作，PS:时间紧迫，暂时没有更好的方法，玉燕同学后续可以继续调研
                  // if(questionaireType === 2){
                  //   const pushStateTimeout = setTimeout(() => {
                  //     window.history.pushState(null, '', `${location.origin}/questionnaire/navigation/pc/${activityId}`)
                  //     window.history.pushState(null, '', `${origin}/questionnaire/pc/${activityId}/${questionnaireId}/${respondentCode}/submit/${index}#${item}`)
                  //     clearTimeout(pushStateTimeout)
                  //   }, 200)
                  // }
                  document.querySelector(`#id_${item}`).scrollIntoView({
                    block: 'center'
                  });
                }}
                className={renderString(item).length > 2 ? "long" : ""}
              >
                {renderString(item)}
              </Button>
            </div>
          </div>
        );
      }
      if (i === 5) {
        return (
          <div style={{ padding: "6px 0" }} key={`error-btn-${item}`}>
            <div className="error-btn-wapper more">...</div>
          </div>
        );
      }
    });
  };

  return (
    <div className="question-right-location">
      {validateFlag && !props.saveModalConfirm.visible ? (
        <>
          {errorListSoreMap.length > 0 ? (
            <img
              style={{ width: "50px", marginLeft: "12px" }}
              src={errorIcon}
            ></img>
          ) : (
            ""
          )}
          {renderNumber()}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default questionnaireErrorLocation;
