import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-contractual-relationships', () => [
		{
			// base
      [`.${prefixCls}`]: {
        ['.ehr-timeline-card-template']: {
          padding: '4px 4px 0px 4px',
        },

        ['.contract-card-container']: {
					padding: '16px 16px 12px',
					width: '200px',
          minHeight: '78px',
					borderRadius: '10px',
          background: 'linear-gradient(10deg, #FFFFFF 42%, #F0F6FF 97%), linear-gradient(195deg, #F6F9FF 22%, #FFFFFF 71%)',
          border: '2px solid #FFFFFF',
          boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.02),0px 2px 6px 6px rgba(0, 0, 0, 0.02),0px 2px 6px 0px rgba(0, 0, 0, 0.12)',

					['.contract-card-train']: {
						fontFamily: 'PingFang SC',
						fontSize: '14px',
						fontWeight: 500,
						lineHeight: '22px',
						letterSpacing: 0,
						color: '#000000DE',
            overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},

					['.contract-card-tag']: {
						display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
						marginTop: '4px',
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '18px',
            color: '#00000099',
					}
				}
			}
		}
	]);
};

export default useStyle;
