import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('management-pagelet-Bottom', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        paddingLeft: '16px',
        paddingRight: '16px',

        ['a']: {
          padding: ' 4px',
          display: 'inline-flex',
          alignItems: 'center',
          ['&:hover']: {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '6px'
          }
        }
      },
    },
  ]);
};

export default useStyle;
