import React, { useState, useEffect } from 'react';
import { di18n } from '@ultra/common';
import { Picker, List } from 'antd-mobile';
import { useSelector } from 'react-redux';
import { getLocale, setLocale } from '@didi/di18n-react';
import { setCookie, getCookie, handleLangCookie } from '../../utils/Tool';
import './index.scss';

const { intl } = di18n;
const TopGuide = props => {
  const { title, questionDes, langKeyList, source } = props;
  const {
    lanMap,
    lanDes
  } = useSelector(state => state.questionContent);
  const [currentLangDes, setCurrentLangDes] = useState('');
  const [district, setDistrict] = useState([]);

  useEffect(() => {
    if (langKeyList) {
      const langList = langKeyList.map(item => ({
        label: lanDes[item],
        value: item
      }));
      setDistrict(langList);
    }
  }, [langKeyList]);


  const handleLangChange = value => {
    handleLangCookie(lanMap, value);
  };

  useEffect(() => {
    const ctLang = getCookie('ct-lang');
    const lang = getLocale('lang');
    if (ctLang) {
      if (lang !== ctLang) {
        setLocale(ctLang, {
          cookieLocaleKey: 'lang',
        });
      }
    }
    setCurrentLangDes((ctLang || getLocale('lang')) === 'zh-CN' ? '中文' : 'English');
  }, []);

  return (
    <>
      <div className={`question-main-${source}-banner`} style={{ fontSize: source === 'pc' ? '32px' : '24px' }}>
        {title ? <div className="question-main-title">{title}</div> : ''}

      </div>
      {
        langKeyList && source === 'h5' ? <div>
          <div className="ct-lang-h5-select">
            <Picker data={district} cols={1} onOk={(e) => { handleLangChange(e[0]) }} okText={intl.t('确定')} dismissText={intl.t('取消')}>
              <List.Item arrow="horizontal">{currentLangDes}</List.Item>
            </Picker>
          </div>
        </div> : ''
      }
      {
        langKeyList && source === 'pc' ? <div>
          <div className="ct-lang-pc-select">
            <span onClick={() => { handleLangChange(0) }} className={currentLangDes === '中文' ? 'ct-lang-pc-select-active' : ''}>中文</span>
            <span></span>
            <span onClick={() => { handleLangChange(1) }} className={currentLangDes === 'English' ? 'ct-lang-pc-select-active' : ''}>En</span>
          </div>
        </div> : ''
      }
      {
        questionDes ?
          <div className="question-main-des-mobile">{questionDes}</div> :
          ''
      }

    </>
  );
};

export default TopGuide;
