import { useRegisterStyle } from '@didi/ec-base';


const useStyle = (prefixCls) => {

  return useRegisterStyle('pagelet-transfer', () => [
    {
      // base
      [`.${prefixCls}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        '.transferInto-modal-toolbar': {
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'flex-end'
        },
        '.tableBox': {
          ['.ant5-table-expanded-row-fixed .ec-ehr-base-table-empty,.ec-ehr-base-table-empty']: {
            marginBlock: '96px',
            position: 'sticky',
            left: '0',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ['img']: {
              display: 'inline-block',
              width: '180px'
            },
            ['.ec-ehr-base-table-empty-text']: {
              fontSize: '12px',
              color: '#2D3040',
              letterSpacing: '0',
              lineHeight: '14px',
            }
          }
        },
      }
    }
  ])
};

export default useStyle;
