import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  initPersonalInfo: async (host, params, headers) => {
    const request = PageletApi.newRequest.getInstance('personal-hr');
    const result = await request(`${host}/${apis.initPersonalInfo}`, {
    // const result = await PageletApi.request(`//mock.xiaojukeji.com/mock/22307/api/unit/chain/links`, {
    // const result = await PageletApi.request(`/api/unit/chain/links`, {
      method: 'get',
      // params,
      // headers
    });
    return result;
  },

  // // get请求示例
  // getRequestData: async (host, params) => {
  //   const result = await PageletApi.request(`${host}/${apis.getList}`, {
  //     method: 'get',
  //     params
  //   });

  //   return result;
  // },

  // // post请求示例
  // getRequestData: async (host, data) => {
  //   const result = await PageletApi.request(`${host}/${apis.getList}`, {
  //     method: 'post',
  //     data,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });

  //   return result;
  // }
}

export default PageletServices;
