import {message} from 'antd';
const axios = require('axios').default;
import {emit} from './event';
import { navigateToUrl } from 'single-spa';

const request = axios.create({
  baseURL: '/',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'x-kylin-proxy-with': 'apirequest',
  },
});

request.interceptors.response.use(
  function(res) {
    if (res?.data?.code === '100200') {
      return Promise.resolve(res?.data);
    } else if (res?.data?.code === '100403'){ // 没有权限
      emit('sidebar:hide', true)
      emit('appbar:hide', true)
      navigateToUrl('/no-permission')
      // message.error('暂无数据权限')

    } else {
      return Promise.reject(res);
    }
  },
  function(error) {
    let res = error.response;
    
    if (res) {
      switch (res.status) {
        case 401:
          if (!window.redirectLogin && res.data.loginUrl) {
            window.redirectLogin = true;
            window.location.href = res.data.loginUrl;
          }
          break;
        default:
          // TODO
      }
    }

    return Promise.reject(res);
  }
);

request.interceptors.request.use(
  config => {
    if (config.url.includes('http') || config.url.includes('//')) {
      return config
    }
  
    return config
  },
  error => {
  }
);

export default request;
