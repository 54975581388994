import React from 'react';
import classNames from 'classnames';
import PostSelect from '../PostSelect';
import { handleLink } from '../../utils/utils';
import { RightThickOutlined } from '@didi/iconflow/ec';
import { DHRCardButton } from '@didi/ec-ehr-base';
import useStyle from './styles';

const Header = props => {
  const { deptList, objecitveList, activedDeptInfo, apiHost } = props;

  // 样式
  const prefixCls = 'ddo-header';
  const wrapSSR = useStyle('ddo-header');
  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={`${classes} ${objecitveList?.length >= 3 && 'header-box'}`}>
      <div className="head_top">
        <span className="title">我Di目标</span>
        {deptList?.length && (
          <div className="head_post">
            <span className="line"></span>
            <PostSelect {...props} />
          </div>
        )}
      </div>
      <DHRCardButton onClick={() => handleLink(activedDeptInfo, apiHost)}>
        <span style={{marginRight: '2px'}}>查目标 </span>
        <RightThickOutlined cursor="pointer" size={14} className="new-home-holiday-card-title-url-icon"/>
      </DHRCardButton>
    </div>,
  );
};

export default Header;
