import React, { useRef, useMemo } from 'react';
import { PieChart, getLang } from '@didi/ec-base';

export default function Chart(props) {
  const { data } = props;
  const chartRef = useRef();

  const options = useMemo(() => {
    const seriesData = data.map(item => ({ name: item?.answer, value: item?.count || '', rate: item?.rate, }));
    return {
      color: ['#66D1A3', '#A7E6C7', '#FFD54D', '#FEAEAB', '#F65A4F', '#DCDCDC'],
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      legend: {
        top: 'middle',
        left: 'auto',
        right: getLang() === 'en-US' ? 30 : 60,
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        itemGap: 12,
        selectedMode: false
      },
      series: [
        {
          name: '',
          radius: ['40%', '60%'],
          center: [getLang() === 'en-US' ? '37%' : '40%', '50%'],
          labelLine: {
            length: 20,
            length2: 60
          },
          avoidLabelOverlap: false,
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            alignTo: 'none',
            overflow: 'none',
            formatter: function (params) {
              const { percent } = params;
              if (percent) {
                return `${percent}%`;
              }
            }
          },
          labelLayout(params) {
            if (!params.text) {
              return
            }
            const isLeft = params.labelRect.x < chartRef?.current?.chart.getWidth() * 0.4;
            const points = params?.labelLinePoints;
            let x, y;
            if (points) {
              x = isLeft
                ? points[2][0] + (params.labelRect.width / 2 + 6)
                : points[2][0] - (params.labelRect.width / 2 + 6);
              y = points[2][1] - 4;
            }
            return {
              labelLinePoints: points,
              verticalAlign: 'bottom',
              x: x,
              y: y
            };
          },
          data: seriesData
        }
      ]
    };
  }, [data]);

  return (
    <PieChart
      ref={chartRef}
      height='212px'
      options={options}
    />
  )
}
