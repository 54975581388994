/*
 * @Author       : didi
 * @Date         : 2024/7/6 下午6:23
 * @Description  :
 */
export const TIME_RANGE_TYPE = { week: 'week', month: 'month', date: 'date' };

export const ACTION_TYPE = { prev: "prev", next: "next" };
export const timeRangeTypeList = [
  {
    code: 'month',
    name: '月'
  },
  {
    code: 'week',
    name: '周'
  },
  {
    code: 'date',
    name: '日'
  }
]
