import React, { useState, useEffect } from 'react';
import { Spin, ConfigProvider } from '@didi/ec-base';
import { mountRootParcel } from 'single-spa';
import Parcel from 'single-spa-react/parcel';
import PageletApi from '@didi/pagelets-sdk';

console.log(4545, PageletApi);

const loadParcel = async (url) => (await window.System.import(url));

export default (props) => {
  const {
    name,       // pagelet名称
    url,        // cdn地址
    auth,       // 权限集
    data,       // 数据集
    onMounted,  // 生命周期
    ...restProps // 额外参数
  } = props;

  if (!name && !url) {
    return <div>Pagelet loading failed, please check if the pagelet url is correct!!!</div>
  }

  const [parcel, setParcel] = useState();

  useEffect(() => {
    if (!name && !url) return;

    (async () => {
      let cdnUrl = ''

      const pagelets = window.$CONFIG.pagelets;
      if(name && pagelets?.[name]) {
        cdnUrl = pagelets[name]?.url;
      } else if (url) {
        cdnUrl = url;
      }

      const p = await loadParcel(cdnUrl);
      setParcel(Object.values(p.default)[0]);
    })()
  }, [name, url]);

  if (!parcel) {
    return <ConfigProvider prefixCls='ant5'><Spin></Spin></ConfigProvider>;
  }

  return (
    <Parcel
      mountParcel={mountRootParcel}
      config={parcel}
      params={{
        auth,
        data,
        onMounted,
        ...restProps,
      }}
    />
  )
}
