//准备初始数据

export default {
  loading: true,
  resultLoading: false,
  apolloConfig: {
    pageLetInfo: {}, // 组件的信息
    helpInfo: {}, // 帮助信息
    monthUrl: '',
    cycleUrl: '',
  }, // apollo 配置信息
  text: '占位数据',
  // 搜索人员
  searchValue: '',
  searchName: '',
  // 筛选条件
  searchType: '',
  searchText: '',
  // 选中状态
  distributionCode: '',
  distributionName: '',
  // 周期
  assessmentInfo: {
    assessmentName: '暂无周期'
  },
  // 状态图数据
  distributions: [],
  totalNum: 0,
  // 人员列表
  searchLoading: false,
  pageNum: 0,
  list: [],
  hasMore: true,
  // 筛选条件
  staffChildSearchTypes: [],
  // 兼岗/虚线下级
  multiChildSearchType: '',
  // 不同部门提醒
  hasDiffCompareToVersion: false
};
