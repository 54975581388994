import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-personal-ei-service', () => [
		{
			// base
			[`.${prefixCls}`]: {
        background: '#fff',
        padding: '16px',
        borderRadius: '12px',
        minHeight: '226px',
        [`.${prefixCls}-header-url-icon`]: {
          marginLeft: '2px'
        },
        ['.personal-ei-service-card']: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
					gridColumnGap: '16px',
          borderRadius: '12px',
          marginTop: '16px',
          ['.personal-ei-service-card-item']: {
            position: 'relative',
            padding: '20px 6px 32px 6px',
            borderRadius: '8px',
            background: 'linear-gradient(243deg, #F7FBFF 16%, #EEF7FF 100%)',
            overflow: 'hidden',
            ['.personal-ei-service-card-icon']: {
              position: 'absolute',
              bottom: '-8px',
              right: '-3px',
              width: '50px',
              height: '50px',
              ['& > img']: {
                display: 'block',
                width: '100%',
                height: '100%',
              },
            },
            ['.personal-ei-service-card-tip']: {
              position: 'absolute',
              top: '0px',
              right: '0px',
              fontFamily: 'PingFang SC',
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '14px',
              letterSpacing: '1px',
              color: '#7FB3E7',
              background: 'rgba(10, 112, 245, 0.06)',
              borderRadius: '0px 8px 0px 12px',
              // width: '37px',
              // height: '26px',
              padding: '4px',
              textAlign: 'center',
            },
            ['.personal-ei-service-card-value']: {
              position: 'relative',
              zIndex: '1',
              display: 'flex',
              alignItems: 'center',
              padding: '4px 8px',
              cursor: 'pointer',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: 'normal',
              lineHeight: '22px',
              letterSpacing: '0em',
              fontVariationSettings: '"opsz" auto',
              color: 'rgba(0, 0, 0, 0.84)',
              marginBottom: '4px',
              ['&:last-child']: {
                marginBottom: '0',
              },
              ['&:hover']: {
                borderRadius: '8px',
                background: 'rgba(10, 112, 245, 0.06)',
              },
              ['.personal-ei-service-card-label']: {
                // marginRight: '4px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                // display: 'block',
              }
            },
            ['.personal-ei-service-card-page']:{
              position: 'absolute',
              bottom: '12px',
              left: '12px',
              display: 'flex',
              alignItems: 'center',
              'img': {
                width: '20px',
                height: '20px',
              },
              '.personal-ei-service-card-left': {
                marginRight: '12px',
                display: 'flex',
                alignItems: 'center',
                '.hover':{
                  display: 'none',
                },
                ['&:hover']: {
                  cursor: 'pointer',
                  '.hover':{
                    display: 'block',
                  },
                  '.leftDisabled':{
                    display: 'block',
                  },
                  '.leftArrow': {
                    display: 'none',
                  }
                }
              },
              '.personal-ei-service-card-right': {
                display: 'flex',
                alignItems: 'center',
                '.hover':{
                  display: 'none',
                },
                ['&:hover']: {
                  cursor: 'pointer',
                  '.hover':{
                    display: 'block',
                  },
                  '.rightDisabled':{
                    display: 'block',
                  },
                  '.rightArrow': {
                    display: 'none',
                  }
                }
              },
            }
          },
          ['.personal-ei-service-card-item-proof']: {
            // width: '66%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            ['.personal-ei-service-card-value']: {
              width: '44%',
              ['&:nth-child(5)']: {
                marginBottom: '0',
              }
            }
          }
        },
        ['.personal-ei-service-card-proof']: {
          gridTemplateColumns: '2fr 1fr',
        },
        [`.${prefixCls}-content-err`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '118px',
          justifyContent: 'center',
          background: '#fff',
          ['img']: {
            width: '120px',
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
        }
			},
      '.yuyue-home-modal-wrap': {
        color: 'red',
        '.ant5-modal-content': {
          borderRadius: '8px',
          padding: '0px !important',
          '.ant5-modal-header': {
            borderRadius: '8px 8px 0 0',
            height: '57px',
            fontFamily: 'PingFangSC-Medium, sans-serif',
            fontSize: '16px',
            color: '#000000e6',
            padding: '18px 20px 16px',
            background: 'url(https://img-hxy021.didistatic.com/static/ehr_static/imgs/yuyue/modal_header_bg.png) no-repeat',
            border: 'none',
            '.ant5-modal-title .title': {
              '.yuyue-home-modal-wrap-title': {
                width: '360px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              '.yuyue-home-modal-wrap-title-qa': {
                cursor: 'pointer',
                fontFamily: 'PingFangSC-Regular, sans-serif',
                fontSize: '12px',
                color: '#838e8b',
                letterSpacing: '0.2px',
                textAlign: 'center',
                fontWeight: '400',
                'img': {
                  verticalAlign: 'sub',
                  marginRight: '3px',
                  width: '18px',
                  height: '16px',
                }
              }
            }
          },
          '.ant5-modal-body': {
            padding: '12px',
            maxHeight: '443px',
            overflow: 'auto',
            '&::-webkit-scrollbar:vertical,::-webkit-scrollbar-button,::-webkit-resizer': {
              display: 'contents',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '.categoryName-list-item': {
              padding: '9px 16px',
              height: '38px',
              width: '100%',
              fontFamily: 'PingFangSC-Regular, sans-serif',
              fontSize: '14px',
              color: '#000000e6',
              cursor: 'pointer',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              '&:hover': {
                borderRadius: '8px',
                background: '#ebf6f3',
              }
            }
          }
        }
		  }
    }
	]);
};

export default useStyle;
