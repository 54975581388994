import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('cycle-content', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        margin: '0 5px 14px',
        display: 'flex',
        justifyContent: 'space-between',
        // 内容区域
        ['.text']: {
          // 文字
          ['p']: {
            marginTop: '4px',
            marginBottom: '0px',
            fontSize: '12px',
            lineHeight: '18px',
            color: 'rgba(0, 0, 0, 0.84)',

            ['&:last-child']: {
              marginTop: '0px',
            },

            // 卡片文字
            ['.popoverBtn']: {
              padding: '4px',
              display: 'inline-block',
              marginLeft: '8px',
              color: '#FF6400',
              cursor: 'pointer',
              ['&:hover']: {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px'
              }
            },
          },
        },
      },
      ['.viewRhythm']: {
        width: '600px',

        ['.ant5-popover-title']: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          // 卡片标题
          ['div']: {
            fontFamily: 'PingFang SC',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            color: 'rgba(0, 0, 0, 0.84)'
          },
        },

        // 标题
        ['img']: {
          width: '100%'
        }
      },
    },
  ]);
};

export default useStyle;
