import React from 'react';
import { ConfigProvider } from 'antd';
import CustomBigTabs from './cnps/CustomBigTabs';
import CustomTimeLineCpn from './TimeLineCpn/CustomTimeLineCpn';
import { LangTranslate } from './utils';
import { ConfigProvider as ECConfigProvider, zh_CN, en_US, getLang } from '@didi/ec-base';
import { Tooltip } from 'antd';

import './index.less';

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, employeeId, customTheme },
  } = props;
  const customThemeParam = customTheme || {
    timelineColor: 'rgba(245, 107, 64, 0.6)',
    timelineDotColor: '#F56B40',
    timelineDotShadowColor: 'rgba(245, 107, 64, 0.1)',
  };

  return (
    <ConfigProvider
      prefixCls={'talent-market-Pagelet-antd'}
      theme={{
        token: {
          colorPrimary: '#FF7813',
          fontFamily:
            '"Helvetica Neue", Helvetica, Arial, "PingFang SC","Heiti SC","Hiragino Sans GB","Microsoft YaHei",sans-serif',
        },
      }}
    >
      <ECConfigProvider
        prefixCls="talent-market-Pagelet-antd"
        locale={getLang() === 'en-US' ? en_US : zh_CN}
        theme={{
          token: {
            colorPrimary: '#0a70f5',
          },
        }}
      >
        <div
          style={{
            '--talent-market-timeline-timelineColor': customThemeParam?.timelineColor || '#FF7813',
            '--talent-market-timeline-tabsInkBar': customThemeParam?.tabsInkBar || '#FF7813',
            '--talent-market-timeline-timelineDotColor':
              customThemeParam?.timelineDotColor || '#FF7813',
            '--talent-market-timeline-timelineDotShadowColor':
              customThemeParam?.timelineDotShadowColor || '#FF7813',
          }}
        >
          <CustomBigTabs
            items={[
              {
                key: '1',
                label: LangTranslate('按时间查看'),
                children: (
                  <CustomTimeLineCpn
                    apiHost={apiHost}
                    locales={locales}
                    headers={headers}
                    employeeId={employeeId}
                  />
                ),
              },
              {
                key: '2',
                label: (
                  <div>
                    <Tooltip placement="topLeft" title={LangTranslate('功能建设中，敬请期待')}>
                      {LangTranslate('按标签查看')}
                    </Tooltip>
                  </div>
                ),
                children: null,
                disabled: true,
              },
            ]}
          />
        </div>
      </ECConfigProvider>
    </ConfigProvider>
  );
};

export default Pagelet;
