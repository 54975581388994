import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { LangTranslate } from '../../utils';

import { Select, Tooltip, Popover } from 'antd';
import CustomTimeLineItemBodyCpnListItem from '../CustomTimeLineItemBodyCpnListItem';
import CustomTimeLineNineBox from '../CustomTimeLineNineBox';
import CustomModalGridBoxHistoryModal from '../CustomModalGridBoxHistory';
import { AllProfileInfoContext } from '../CustomTimeLineCpn/context';

import './index.less';
import CardIcon from '../../cnps/SvgIcon';

function CustomTimeLineItemBodyCpn(props) {
  const { data, setGridInfo = () => {}, activityId } = props;
  const tagListWrapRef = useRef();
  const [indicatorSelect, setIndicatorSelect] = useState();
  const [curSelectBlock, setCurSelectBlock] = useState();
  const [curSelectAxis, setCurSelectAxis] = useState();
  const [curGridBoxHistory, setCurGridBoxHistory] = useState({});
  const allProfileInfo = useContext(AllProfileInfoContext);
  const [gridBoxHistoryVisible, setGridBoxHistoryVisible] = useState(false);
  // gridBoxHistoryVisible
  const curSelectIndicatorInfo = useMemo(
    () =>
      indicatorSelect?.value
        ? data?.gridIndicators?.find(it => it?.indicatorId === indicatorSelect?.value)
        : {},
    [indicatorSelect, data],
  );

  const showGridBoxHistory = () => {
    setGridBoxHistoryVisible(() => true);
  };
  useEffect(() => {
    const curGridBoxKey = `${data?.positionCode}#${indicatorSelect?.value}`;

    setCurGridBoxHistory(
      () =>
        ({
          allActivityMap: allProfileInfo?.activityMap,
          current: allProfileInfo?.historyGridIndicator?.[curGridBoxKey],
        }) || {},
    );
  }, [data, allProfileInfo, indicatorSelect]);

  useEffect(() => {
    if (data?.gridIndicators?.length) {
      const defaultVal = data?.gridIndicators?.[0];
      setIndicatorSelect(() => ({
        value: defaultVal?.indicatorId,
        label: defaultVal?.indicatorName,
        ...defaultVal,
      }));
    }
  }, [data]);

  useEffect(() => {
    setGridInfo(() => ({
      setCurSelectAxis,
      data: curSelectIndicatorInfo,
      curSelectBlock,
      setCurSelectBlock,
    }));
  }, [setCurSelectAxis, curSelectIndicatorInfo, curSelectBlock, setCurSelectBlock]);

  const curIndicatorAxisName = useMemo(() => {
    if (indicatorSelect?.value) {
      const curIndicator = data?.gridIndicators?.find(
        it => it?.indicatorId === indicatorSelect?.value,
      );
      return [
        curIndicator?.axisInfo?.x_axis?.axisReference?.name,
        curIndicator?.axisInfo?.y_axis?.axisReference?.name,
      ];
    }
  }, [indicatorSelect]);

  const curIndicatorAxisLabel = useMemo(
    () =>
      `${curIndicatorAxisName?.[0]}：${curSelectAxis?.[0]?.segmentLabel}，${curIndicatorAxisName?.[1]}： ${curSelectAxis?.[1]?.segmentLabel}`,
    [curIndicatorAxisName, curSelectAxis],
  );

  useEffect(() => {
    setTimeout(() => {
      if (tagListWrapRef.current.scrollTop) {
        tagListWrapRef.current.scrollTop = 0;
      }
    });
  }, [data]);

  return (
    <div className="talent-market-custom-time-line-item-body-cpn-wrap">
      <div className="talent-market-custom-time-line-item-body-cpn-head">
        <Popover
          getPopupContainer={e => e?.parentNode}
          overlayClassName="talent-market-custom-time-line-item-body-cpn-head-popover-wrap"
          placement="bottom"
          content={
            <div className="talent-market-custom-time-line-item-body-cpn-head-popover-content-wrap">
              {[
                {
                  label: '岗位名称',
                  value: data?.positionRoleName,
                  mainPost: data?.mainPost,
                  showMainPost: true,
                },
                {
                  label: '是否管理岗',
                  value: data?.managementPost ? LangTranslate('是') : LangTranslate('否'),
                },
                { label: '组织层级', value: data?.organizationName },
                { label: '部门路径', value: data?.positionDeptNamePath },
                {
                  label: '岗位上级',
                  value: `${data?.postSuperiorEmployeeName}(${data?.postSuperiorEmployeeId})`,
                },
              ]?.map(itm => (
                <div className="talent-market-custom-time-line-item-body-cpn-head-popover-content-item">
                  <div>{`${LangTranslate(itm?.label)}: `}</div>
                  <div style={!!itm?.showMainPost ? { maxWidth: 212 } : {}}>
                    <span className="talent-market-custom-time-line-item-body-cpn-head-popover-content-item-content">
                      <Tooltip placement="topLeft" destroyTooltipOnHide title={itm?.value}>
                        {itm?.value}
                      </Tooltip>
                    </span>
                    {!!itm?.showMainPost && (
                      <span data-mainPost={itm?.mainPost}>
                        {itm?.mainPost ? LangTranslate('主') : LangTranslate('兼')}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          }
          title={null}
          arrow={false}
          trigger="hover"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              columnGap: '6px',
            }}
          >
            <div
              style={{
                ...{
                  minWidth: '20px',
                  height: '18px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  padding: '0 4px',
                },
                ...(data?.mainPost
                  ? {
                      color: '#00B365',
                      backgroundColor: 'rgba(0, 179, 101, 0.1)',
                    }
                  : {
                      color: 'rgba(0, 0, 0, 0.84)',
                      backgroundColor: 'rgba(0, 0, 0, 0.06)',
                    }),
              }}
            >
              {data?.mainPost ? LangTranslate('主') : LangTranslate('兼')}
            </div>
            {!!data?.managementPost && (
              <div
                style={{
                  minWidth: '20px',
                  height: '18px',
                  color: '#005ADE',
                  backgroundColor: 'rgba(0, 90, 222, 0.1)',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '18px',
                }}
              >
                {LangTranslate('管')}
              </div>
            )}
            <div style={{ flex: '1' }}>{data?.positionRoleName}</div>
          </div>
        </Popover>
      </div>
      <div className="talent-market-custom-time-line-item-body-cpn-body">
        {!!data?.gridIndicators?.length && (
          <div
            style={{ marginBottom: '16px' }}
            className="talent-market-custom-time-line-item-body-cpn-nine-box-wrap"
          >
            <div className="talent-market-custom-time-line-item-body-cpn-nine-box-left">
              <CustomTimeLineNineBox
                setCurSelectAxis={setCurSelectAxis}
                data={curSelectIndicatorInfo}
                curSelectBlock={curSelectBlock}
                setCurSelectBlock={setCurSelectBlock}
              />
            </div>
            <div className="talent-market-custom-time-line-item-body-cpn-nine-box-right">
              <div className="talent-market-custom-time-line-item-body-cpn-nine-box-right-select">
                <Select
                  fieldNames={{ label: 'indicatorName', value: 'indicatorId' }}
                  options={data?.gridIndicators}
                  placeholder={LangTranslate('请选择')}
                  value={indicatorSelect}
                  labelInValue
                  onChange={newVal => setIndicatorSelect(() => newVal)}
                />
                {!!curGridBoxHistory?.current && (
                  <div
                    onClick={showGridBoxHistory}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      destroyTooltipOnHide
                      placement="topLeft"
                      title={LangTranslate('历史记录')}
                      arrow={false}
                    >
                      <CardIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.24)',
                        }}
                        type="icon-biaoqianlishi"
                        size={16}
                        pointer
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
              <CustomModalGridBoxHistoryModal
                data={curGridBoxHistory}
                isModalOpen={gridBoxHistoryVisible}
                setIsModalOpen={setGridBoxHistoryVisible}
                activityId={activityId}
              />
              {curSelectBlock !== -1 && (
                <div className="talent-market-custom-time-line-item-body-cpn-nine-box-right-axis">
                  <Tooltip placement="topLeft" destroyTooltipOnHide title={curIndicatorAxisLabel}>
                    {curIndicatorAxisLabel}
                  </Tooltip>
                </div>
              )}
              {curSelectBlock !== -1 && (
                <div className="talent-market-custom-time-line-item-body-cpn-nine-box-right-show-tag">
                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide
                    title={curSelectBlock?.blockName}
                  >
                    {curSelectBlock?.blockName}
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        )}

        <div
          ref={tagListWrapRef}
          className="talent-market-custom-time-line-item-body-cpn-body-tag-list"
        >
          {data?.tagPoolInfos?.map?.(it => (
            <CustomTimeLineItemBodyCpnListItem data={it} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CustomTimeLineItemBodyCpn;
