import { createSlice } from '@reduxjs/toolkit';

export const portraits = createSlice({
  name: 'portrait', // 命名空间，在调用action的时候会默认的设置为action的前缀
  // 初始值
  initialState: {
    portraitData: null, // 人头集合
    refuseTypeCode: '', // 拒评类别
    refuseTypeName: '', // 拒评名称
    refuseQuestionnaireId: '', // 拒评list
    refuseReasonList: [], // 拒评原因list
  },
  // 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
  reducers: {
    changePortraits(state, { payload }) {
      const { respondentGroup, activityType } = payload;
      const result = {};
      result.respondentGroup = respondentGroup.map((item, groupIndex) => {
        const totalList = []; // 头像组数据存储
        let completeNum = 0; // 完成数量
        // 渲染头像类型type  MultipleAvatars 人员问卷 MultipleOrganizes 组织问卷 ORGANIZATION
        const renderType = activityType === 'ORGANIZATION' ? 'MultipleOrganizes' : 'MultipleAvatars';
        item.contentInfos.map(i => {
          i.activityRespondents.map((j, d) => {
            j.questionnaireId = i.contentId;
            totalList.push(j)
            if (j.recycleStatus === 2) {
              completeNum++
            }
          })
        })
        // if (totalList?.length) {
          result.reasonType = activityType; // activityType
        // }
        item.totalList = totalList;
        item.completeNum = completeNum;
        item.renderType = renderType;
        item.groupIndex = groupIndex;
        return item;
      })
      state.portraitData = {...result, ...payload}; // 内置了immutable
    },
    changePortraitsPreview(state, { payload }) {
      const { respondentGroup, activityType } = payload; 
      const result = {};
      result.respondentGroup = respondentGroup.map((item, groupIndex) => {
        const totalList = []; // 头像组数据存储
        // 渲染头像类型type  MultipleAvatars 人员问卷 MultipleOrganizes 组织问卷 ORGANIZATION
        const renderType = activityType === 'ORGANIZATION' ? 'MultipleOrganizes' : 'MultipleAvatars';
        item.activityRespondents.map((j, d) => {
          totalList.push(j)
        })

        item.totalList = totalList;
        item.renderType = renderType;
        item.groupIndex = groupIndex;
        return item;
      })
      state.portraitData = {...result, ...payload}; // 内置了immutable
    },
    changeRefuseTypeCode(state, { payload }) {
      state.refuseTypeCode = payload;
    },
    changeRefuseTypeName(state, { payload }) {
      state.refuseTypeName = payload;
    },
    changeRefuseQuestionnaireId(state, { payload }) {
      state.refuseQuestionnaireId = payload;
    },
    changeRefuseReasonList(state, { payload }) {
      state.refuseReasonList = payload;
    },

  },
});

// 导出actions
export const {
  changePortraits,
  changeRefuseTypeCode,
  changeRefuseTypeName,
  changeRefuseQuestionnaireId,
  changeRefuseReasonList,
  changePortraitsPreview
} = portraits.actions;

// 内置了thunk插件，可以直接处理异步请求
// export const asyncIncrement = (payload) => (dispatch) => {
//     setTimeout(() => {
//         dispatch(increment(payload));
//     }, 2000);
// };

export default portraits.reducer; // 导出reducer，在创建store时使用到