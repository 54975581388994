/* eslint-disable-next-line no-alert, quotes, semi */
import React from "react";
import {
  Progress,
} from 'antd';
import './index.scss'
// pc问卷进度条
const questionnaireProcessPc = props => {
  const { processRate } = props;
  return (
    <div  className="process-pc-wapper">
      <div>
        <Progress
          percent={processRate?.progressRate}
          showInfo={false}
          strokeColor={{
            '0%': '#0F9159',
            '100%': '#04C074',
          }}
          strokeWidth={8}
        />
      </div>
      {/* 进度数 */}
      {
        processRate?.progressRate >= 0 && (
          <div className="process-pc-rate" style={{ paddingLeft: `${processRate?.progressRate < 88 ? processRate?.progressRate : 88}%` }}>{processRate?.progressRate}%</div>
        )
      }
    </div>
  );
};

export default questionnaireProcessPc;
