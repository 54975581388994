import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // post 员工目标组件 (岗位树状结构)
  getUnitUser: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.unitUser}`, {
      method: 'post',
      data,
    });
  },
  // post 根据 ownerId 获取目标详情
  getDetailByOwnerId: async (host, ownerId, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.detailByOwnerId}?ownerId=${ownerId}`, {
      method: 'post',
      data,
    });
  },
}

export default PageletServices;
