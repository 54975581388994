import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('dhr-people-select', () => [
		{
			// base
      '.dhr-people-select': {

        '.ant-tooltip': {
          maxWidth: 'initial',
        },
        '.dhr-people-select-loading': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        // '.ant-select-arrow': {
        //   color: 'rgb(12, 21, 46)',
        // }
      },
      '.dhr-people-select-label-item': {
          fontSize: '12px',
          textAlign: 'left',
          '.dhr-people-select-deptPath': {
            color: '#AEAEAE',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            direction: 'rtl',
            display: 'flex',
            alignItems: 'flex-start',
            display: 'inline-block',
            width: '100%',
          },
          '.dhr-people-select-label-detail': {
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
      }
		}
	])
};

export default useStyle;
