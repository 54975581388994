import React, { useState } from 'react';
import { LangTranslate } from '../../utils';
import { Tooltip } from 'antd';
import CustomTimeLineNineBox from '../CustomTimeLineNineBox';
import './index.less';

function CustomTimeLineISubitemBodyCpn(props) {
  const { data, onClick = () => {} } = props;
  return (
    <div
      onClick={onClick}
      className="talent-market-custom-time-line-sub-item-body-cpn-wrap"
      style={
        props?.isChosePosition
          ? {
              background:
                ' linear-gradient(6deg, #FFF 28%, #F0F6FF 96%), linear-gradient(190deg, #F6F9FF 11%, #FFF 76%)',
              border: '1.5px solid rgba(255, 100, 0, 0.4)',
              boxShadow:
                '0 -2px 4px 0 rgba(0, 0, 0, 0.02),0 2px 6px 6px rgba(0, 0, 0, 0.02),0 2px 6px 0 rgba(0, 0, 0, 0.12)',
            }
          : {}
      }
    >
      <div className="talent-market-custom-time-line-sub-item-body-title">
        {data?.mainPost ? (
          <div className="talent-market-custom-time-line-sub-item-body-title-man-post">
            {LangTranslate('主')}
          </div>
        ) : (
          <div className="talent-market-custom-time-line-sub-item-body-title-sub-post">
            {LangTranslate('兼')}
          </div>
        )}

        {!!data?.managementPost && (
          <div
            style={{
              minWidth: '20px',
              height: '18px',
              color: '#005ADE;',
              backgroundColor: 'rgba(0, 90, 222, 0.1)',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '18px',
              padding: '0 4px',
            }}
          >
            {LangTranslate('管')}
          </div>
        )}
        <div
          className="talent-market-custom-time-line-sub-item-body-cpn-title"
          style={{ marginLeft: '6px' }}
        >
          <Tooltip placement="topLeft" title={data?.positionRoleName}>
            {data?.positionRoleName}
          </Tooltip>
        </div>
      </div>
      {data?.gridIndicators?.length ? (
        <div className="talent-market-custom-time-line-sub-item-body-nine-box-wrap">
          <CustomTimeLineNineBox data={data?.gridIndicators?.[0]} scale={0.5} />
        </div>
      ) : null}
    </div>
  );
}

export default CustomTimeLineISubitemBodyCpn;
