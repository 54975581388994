import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';

const singleSpaReact = require('single-spa-react').default;

const { bootstrap, mount, unmount } = singleSpaReact.default({
  React,
  ReactDOM,
  rootComponent: Root,
});

export {
  bootstrap,
  mount,
  unmount,
};
