import React, { useContext } from 'react';
import { Avatar } from '@didi/ec-base';
import { SearchOutlined } from '@ant-design/icons';
import DebounceSelect from './debounceSelect';
import { PublicStore } from '../../../../utils/utils';
import PageletServices from '../../../../services'; //页面请求方法
import classNames from 'classnames';
import useStyle from './styles';

const FilterContent = () => {
  // 样式
  const prefixCls = 'filter-content';
  const wrapSSR = useStyle('filter-content');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const { searchValue } = stateStore;
  const {
    params: { deptCodes = [] },
  } = props;

  // 搜索员工
  const fetchUserList = async username => {
    // 有输入的情况下
    if (username) {
      try {
        const { data } = await PageletServices.postSearch('', {
          tabType: 'HELP',
          pageNum: Number('1'),
          pageSize: Number('10'),
          deptCodes: deptCodes,
          keyword: username
        });
        return data.list.map(item => ({
          label: (
            <div className="searchItem">
              <div className="head">
                <Avatar
                  size={'small'}
                  src={item?.avatar || "//img-ys011.didistatic.com/static/ddo_web_static/do1_uSZhhctpi1afbFsNH8WL"}
                />
              </div>
              <div className="people">
                <p className="fullName">
                  <span>{item?.name}</span>
                  <span>({item?.ldap})</span>
                </p>
                <div className="details">
                  <span className="jobID">{item?.empId}</span>
                </div>
              </div>
            </div>
          ),
          text: item?.name,
          value: item?.empId,
        }));
        return [
          {
            label: (
              <div className="searchItem">
                <div className="head">
                  <Avatar
                    size={'small'}
                    src="//img-ys011.didistatic.com/static/ddo_web_static/do1_uSZhhctpi1afbFsNH8WL"
                  />
                </div>
                <div className="people">
                  <p className="fullName">
                    <span>向微微</span>
                    <span>{`(${'aamer aa'})`}</span>
                  </p>
                  <div className="details">
                    <span className="jobID">D00002</span>
                    <div className="line" />
                    <span className="dept">{'李有忠李有忠>杨增会杨增会'}</span>
                  </div>
                </div>
              </div>
            ),
            text: 'user.name',
            value: 'user.empId',
          },
        ];
      } catch (e) {
        // 抛出异常
        return [];
      }
    }
    return [];
  };

  // 结果请求
  const resultRequest = async ({ selectEmpIds }) => {
    stateDispatch({ resultLoading: true });
    try {
      const { data } = await PageletServices.postSearchAndDistribution('', {
        tabType: 'HELP',
        pageNum: Number('1'),
        pageSize: Number('10'),
        deptCodes,
        selectEmpIds
      });
      const { searchParam, pageResult, progressDistribution } = data;
      const { distributions, totalNum } = progressDistribution;
      stateDispatch({
        ...searchParam,
        distributions,
        totalNum,
        distributionName: distributions?.find(item => item.code === searchParam?.distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        resultLoading: false,
      });
    } catch (e) {
      // 抛出异常
      return [];
    }
  };

  return wrapSSR(
    <div className={classes}>
      <DebounceSelect
        value={searchValue || null}
        fetchOptions={fetchUserList}
        onChange={(newValue, optionValue) => {
          stateDispatch({
            searchValue: newValue,
            searchName: optionValue?.text
          });
          resultRequest({ selectEmpIds: newValue ? [newValue] : [] });
        }}
        suffixIcon={<img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_44lPDL4WGzMKYiABjsNh'} />}
      />
    </div>,
  );
};

export default FilterContent;
