import React, { useEffect, useReducer, useMemo } from 'react';
import PageHeader from './components/PageHeader'; // 页面头部
import PageContent from './components/PageContent'; // 页面内容
import PageBottom from './components/PageBottom'; // 页面底部
import { initial, reducer } from './store'; // 全局数据
import { PublicStore, getApolloConfig } from './utils/utils';
import classNames from 'classnames';
import useStyle from './style';

//页面请求方法
import PageletServices from './services';

// 国际化翻译文件
import { ConfigProvider, Spin } from '@didi/ec-base';
import { utils, newRequest } from '@didi/pagelets-sdk';

const Pagelet = props => {
  const {
    params: { apiHost, auth, headers = {} },
  } = props;
  const { functionId, functionName } = utils.getFunctionData(auth, '@didi/pagelet-perf-overview') || {}; // 获取组件的id
  // 配置请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  newRequest.init(newHeaders); // 初始化请求头

  const [stateStore, stateDispatch] = useReducer(reducer, initial); // 全局数据
  const contextValue = useMemo(
    () => ({
      // 存储全局数据
      stateStore,
      stateDispatch,
      props
    }),
    [stateStore],
  );
  const { loading } = stateStore;

  // 初始化接口请求
  const handleInitRequest = async () => {
    try {
      const { data } = await PageletServices.getPergUnitUser();
      stateDispatch({
        empId: data.empId,
        performanceId: data.performanceId,
        stateCode: data?.perfStateInfo?.stateCode,
        stateDesc: data?.perfStateInfo?.stateDesc,
        assessmentInfo: data?.assessmentInfo,
        loading: false
      });
    } catch (e) {
      console.log(e);
      stateDispatch({
        loading: false
      });
    }
  };

  // 接口请求示例
  useEffect(async () => {
    handleInitRequest();
    const res = await getApolloConfig(apiHost); // 获取apollo配置
    stateDispatch({ apolloConfig: res });
  }, []);

  const prefixCls = 'pagelet-perf-overview';
  const wrapSSR = useStyle(prefixCls, { size: '12px' });
  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <PublicStore.Provider value={contextValue}>
        <div className={classes}>
          {
            loading ? <div className='spin'>
              <Spin />
            </div> : <>
              <PageHeader />
              <PageContent />
              <PageBottom />
            </>
          }
          <img className='leftImg' height='43' src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_DwgcQj3rC8fqEDbw6mye'} />
          <img className='rightImg' width='57' src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_Gdz63ZfVjKFcQPiBxYIK'} />
        </div>
      </PublicStore.Provider>
    </ConfigProvider>
  );
};

export default Pagelet;
