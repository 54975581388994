import React, { useState, useEffect } from 'react';
import { TreeSelect, Tag } from 'antd';
import request from '../../utils/request';
import CardIcon from '../CardIcon';
// import {
//   getSubjectDimension,
// } from "ct-service/editService";
import './index.scss';

const DimensionSelect = (props) => {
  const { url, handleLabelAndvalue, value, onChange, disabled = false, tagDisabledList, source = '', sceneId = '', ...other } = props; 

  const { SHOW_ALL } = TreeSelect;
  const [dimensionValue, setDimensionValue] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const handleChange = (v) => {
    const ids = v.map((item) => item.value);
    setDimensionValue(ids);
    onChange(ids);
  };

  const tagRender = props => {
    const { label: tagLabel, closable: isCloseble, onClose } = props;

    // 处理无权限的维度不可编辑的逻辑
    let closable = isCloseble;
    let label = tagLabel;
    if (tagDisabledList?.length > 0) {
      tagDisabledList?.forEach(element => {
        if (element?.dimensionId == label && !element?.permissions) {
          closable = false;
          label = element.dimensionName;
        }

        if (element?.dimensionId == label && element?.permissions && element?.dimensionStatus === 0) {
          closable = true;
          label = element.dimensionName;
        } 
      });
    }

    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    }

    return (
      <Tag
        key={label}
        style={{ marginRight: 3 }}
        closable={closable}
        onMouseDown={onPreventMouseDown}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  // 转换后端数据
  const handleTree = (data) => {
    // 数据转换
    if (url) {
      data = handleLabelAndvalue(data);
    } else {
      data = data.map((item) => {
        // 场景时 把sceneId,sceneName 转成 tree依赖字段
        if (item.sceneId) {
          item.value = item.key = item.sceneId;
          item.title = item.sceneName;
          item.checkable = false;
        }
  
        // 维度时 把dimensionId 转成 tree依赖字段
        if (item.dimensionId) {
          item.value = item.key = item.dimensionId;
        }
  
        // 有孩子递归转数据
        if (item.children?.length) {
          item.children = handleTree(item.children);
        }
  
        // 给指定的维度标记加icon
        if (item.tag) {
          item.icon = item.tag === 'Group' ? (
            <CardIcon type="iconjituan-copy" className="quesionlb-dimension-select-icon icon-iconjituan-copy" />
          ) : (
            <CardIcon type="iconzidingyi-copy" className="quesionlb-dimension-select-icon icon-iconzidingyi-copy" />
          );
        }
        return item;
      });
      return data;
    }
  };

  // 维度树接口
  const getDimensionTree = async () => {
    let params = {}

    if (source && sceneId && source === 'questionnaire') {
      params.sceneId = sceneId;
    }
    const res = await request({
      url : url || '/ctQuestionnaire/api/subject/subjectDimension', 
      method: 'GET',
      params
    });
    console.log(res);
    setTreeData(handleTree(res || []));
  };

  // // 维度树接口
  // const getDimensionTree = async () => {
  //   try {
  //     const data = await getSubjectDimension({});
  //     setTreeData(handleTree(data));
  //   } catch (error) {
  //     console.log("getQuestionnaireLanguage", error);
  //   }
  // };

  // 默认参数
  const tProps = {
    onChange: handleChange,
    treeCheckable: true,
    dropdownClassName: 'quesionlb-dimension-select-dropdown',
    showCheckedStrategy: SHOW_ALL,
    treeCheckStrictly: true,
    placeholder: '请选择关联维度',
    treeIcon: true,
    size: 'small',
    showArrow: true,
    // height: 100,
    style: {
      width: '100%',
    },
  };

  useEffect(() => {
    getDimensionTree();
  }, []);

  useEffect(() => {
    setDimensionValue(value);
  }, [value])

  return (
    <div className="quesionlb-dimension-select">
      <TreeSelect
        getPopupContainer={(trigger) => trigger.parentNode}
        {...tProps}
        {...other}
        disabled={disabled}
        treeData={treeData}
        value={dimensionValue}
        treeDefaultExpandedKeys={dimensionValue}
        tagRender={tagRender}
      />
    </div>
  );
};

export default DimensionSelect;
