import React, { useEffect, useState, useRef } from 'react';
import { getLocale } from '@didi/ec-base';
import { Intl, utils, newRequest } from '@didi/pagelets-sdk';
import { Spin, Row, Col, Tabs, Table, message } from 'antd';
import classNames from 'classnames';
import * as echarts from 'echarts';
import moment from 'moment';
import manifest from '../manifest.json';
import PageletServices from './services';
import useStyle from './style';
import { leaveBgConfig, holidayTypeList } from '../utils/config';

const localLocales = manifest.locales || {};
const { TabPane } = Tabs;
const Pagelet = props => {
  const {
    params: { headers = {}, apiHost = '//dhr-dev.intra.xiaojukeji.com', locales, empId, auth },
  } = props;

  const authId = utils.getFunctionId(auth, 'holiday_person');
  const newHeaders = {
    ...headers,
    'bricks-function-id': authId
  };
  newRequest.init(newHeaders, 'holiday_person');

  const prefixCls = 'pagelet-employee-leave-details';
  const wrapSSR = useStyle(prefixCls);
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const classes = classNames(prefixCls);
  const [date, setDate] = useState(1);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [allList, setAllList] = useState([]);
  const [baseInfo, setBaseInfo] = useState({});
  const [activeTab, setActiveTab] = useState('');
  const barData = useRef(null);
  const [holidayHeight, setHolidayHeight] = useState(0);

  const columns = [
    {
      title: '开始时间',
      dataIndex: 'starDate',
      width: 30,
      ellipsis: true,
      render: text => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : '--'}</div>
      )
    },
    {
      title: '结束时间',
      dataIndex: 'endDate',
      width: 30,
      ellipsis: true,
      render: text => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : '--'}</div>
      )
    },
    {
      title: '天数',
      dataIndex: 'days',
      width: 20,
      ellipsis: true
    },
    {
      title: '申请时间',
      dataIndex: 'createDate',
      width: 30,
      ellipsis: true,
      render: text => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : '--'}</div>
      )
    }
  ];

  const colorList = {
    '年假': ['rgba(255, 162, 84, 1)', 'rgba(255, 125, 65, 1)'],
    '事假': ['rgba(130, 166, 245, 1)', 'rgba(88, 137, 242, 1)'],
    '病假': ['rgba(109, 214, 179, 1)', 'rgba(54, 191, 145, 1)'],
    '婚假': ['rgba(255, 158, 158, 1)', 'rgba(250, 112, 112, 1)'],
    '产假': ['rgba(202, 175, 255, 1)', 'rgba(161, 126, 230, 1)'],
    '产前检查假': ['rgba(245, 122, 163, 1)', 'rgba(255, 160, 192, 1)'],
    '陪产假': ['rgba(202, 175, 255, 1)', 'rgba(161, 126, 230, 1)'],
    '育儿假': ['rgba(165, 211, 73, 1)', 'rgba(144, 192, 45, 1)'],
    '备用育儿假': ['rgba(165, 211, 73, 1)', 'rgba(144, 192, 45, 1)'],
    '探亲假': ['rgba(255, 206, 106, 1)', 'rgba(242, 178, 48, 1)'],
    '丧假': ['rgba(146, 146, 146, 1)', 'rgba(102, 102, 102, 1)']
  }

  var chartDom = barData.current;
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      color: '#3d82dd',
      tooltip: {
        // trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        top: 39,
        left: '3%',
        right: '4%',
        bottom: 10,
        containLabel: true
        // height: 145
      },
      xAxis: [
        {
          type: 'category',
          data: Object.keys(data).length > 0 && data.holidayType.map(i => i.name),
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 30,
            fontSize: 10
          }
        }
      ],
      yAxis: [
        {
          name: '单位：天',
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#f5f5f5']
            }
          }
        }
      ],
      series: [
        {
          name: '请假天数',
          type: 'bar',
          barWidth: '60%',
          data: Object.keys(data).length > 0 && data.holidayType.map(i => data.employeeList[i.code]),
          itemStyle: {
            normal: {
              barBorderRadius: [5, 5, 0, 0],
              color: (params) => {
                return {
                  x: 0,
                  y: 0,
                  x1: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: colorList[params?.name][0]
                    },
                    {
                      offset: 1,
                      color: colorList[params?.name][1]
                    }
                  ]
                }
              }
            }
          }
        }
      ]
    };
  }
  chartDom && myChart.setOption(option);

  const getDetail = async dateType => {
    setLoading(true);
    const res = await PageletServices.getPerson(apiHost,
      {
        dateType,
        holidayType:  holidayTypeList.annual,
        empId
      }
    );
    if (res?.code === '100200' && res?.data) {
      setData(res?.data);
      // 根据order排序展示
      const minSort = arr => {
        const newArr = arr.sort((a, b) => a.order - b.order);
        return newArr;
      };
      const allListData = minSort(Object.values(res?.data?.allList));
      console.log(Object.values(res?.data?.allList), allListData, '---🐷allListData')
      setAllList(allListData);
      setBaseInfo({ empName: res?.data?.employeeName, empId: res?.data?.employeeId });
      setLoading(false);
      setActiveTab(`#${leaveBgConfig[allListData[0]?.holidayType]?.title}${allListData[0]?.holidayType}`);
      handleWindowSizeChange();
    } else {
      setLoading(false);
      message.error(res?.data?.msg);
    }
  };
  // 计算假期list高度
  const handleWindowSizeChange = () => {
    const viewportHeight = document.body.offsetHeight;
    const holidayDrawerHeight = document.getElementById('holiday-history-drawer-top').offsetHeight
    const DrawerTitleHeight = 62;
    const holidayListHeight =  viewportHeight - holidayDrawerHeight - DrawerTitleHeight;
    setHolidayHeight(holidayListHeight);
  };

  useEffect(() => {
    if(empId){
      getDetail(date);
    }
  }, [empId]);
  // 监听页面变化
  useEffect(() => {
    // 初始化执行一下
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return wrapSSR(
    <div className={classes}>
      <div className="holiday-history-drawer">
        <Spin spinning={loading}>
          <div id="holiday-history-drawer-top" className="holiday-history-drawer-top">
            <div className="name-id">
              <div>
                <span className="baseInfo-empname">{baseInfo && baseInfo?.empName}</span>
                <span className="baseInfo-empid">{baseInfo && baseInfo?.empId}</span>
                <span className="baseInfo-empid">（周期：年初至今）</span>
              </div>
            </div>
            {!loading && <Row className="bar-card">
                <Col span={5}>
                  <div className="bar-days">
                    <div className="center">
                      <div className="all-days"><span>共休假(天)</span></div>
                      <div className="top-10"><span className="days">{Object.keys(data).length > 0 && data?.countDays}</span></div>
                    </div>
                  </div>
                </Col>
                <Col span={19}>
                  <div style={{ width: '420px', height: '180px' }} ref={barData}></div>
                </Col>
            </Row>}
            <Tabs
              activeKey={activeTab}
              default
              onChange={(key) => {
                setActiveTab(key);
                const content = document.getElementById(key);
                content.scrollIntoView({ behavior: 'smooth' });
              }}
              className="to-holiday"
            >
              {allList.length > 0 && allList.map(i =>
                <TabPane tab={leaveBgConfig[i.holidayType].title} key={`#${leaveBgConfig[i.holidayType].title}${i.holidayType}`} forceRender />
              )}
            </Tabs>
          </div>
          <div className="holiday-history-drawer-bottom" style={{height: holidayHeight}}>
            {
              allList.length > 0 && allList.map(i => {
                return (
                  Object.values(i.leaveStateCount).length > 0 &&
                  <Row className={`leave-card leave-card-top${i.holidayType}`} id={`#${leaveBgConfig[i.holidayType].title}${i.holidayType}`}>
                    <div className={`top-logo top-logo${i.holidayType}`}>{leaveBgConfig[i.holidayType].title}</div>
                    <Row className="leave-all-days">
                      <Col span={6}>
                        <div>已休</div>
                        <div><span className="big-days leaved-days">{i.leaveStateCount[1]}</span><span className="day-font-size">天</span></div>
                      </Col>
                      <span className="leaved-line-days"></span>
                      <Col span={5}>
                        <div>审批中</div>
                        <div><span className="big-days">{i.leaveStateCount[2]}</span><span className="day-font-size">天</span></div>
                      </Col>
                      {(i.holidayType === holidayTypeList.marriage || i.holidayType === holidayTypeList.maternity || i.holidayType === holidayTypeList.paternity) && i.leaveStateCount[4] &&
                        <Col span={5}>
                          <div>政策天数</div>
                          <div><span className="big-days">{i.leaveStateCount[4]}</span><span className="day-font-size">天</span></div>
                        </Col>
                      }
                      {i.holidayType === holidayTypeList.annual &&
                        <Col span={6}>
                          <div>剩余</div>
                          <div><span className="big-days">{i.leaveStateCount[3]}</span><span className="day-font-size">天</span></div>
                        </Col>
                      }
                      {i.holidayType === holidayTypeList.sick &&
                        <Col span={5}>
                          <div>全薪病假剩余</div>
                          <div><span className="big-days">{i.leaveStateCount[3]}</span><span className="day-font-size">天</span></div>
                        </Col>
                      }
                      {i.holidayType === holidayTypeList.prenatal &&
                        <Col span={5}>
                          <div>当前孕</div>
                          <div><span className="big-days">{i.leaveStateCount[3]}</span><span>月</span></div>
                        </Col>
                      }
                      {i.holidayType === holidayTypeList.family &&
                        <Col span={5}>
                          <div>探亲周期剩余</div>
                          <div><span className="big-days">{i.leaveStateCount[3]}</span><span className="day-font-size">天</span></div>
                        </Col>
                      }
                      {(i.holidayType === holidayTypeList.marriage || i.holidayType === holidayTypeList.maternity || i.holidayType === holidayTypeList.paternity) && i.leaveStateCount[3] &&
                        <Col span={5}>
                          <div>社保地</div>
                          <div className="social-security-land"><span>{i.leaveStateCount[3]}</span></div>
                        </Col>
                      }
                      {(i.holidayType === holidayTypeList.annual) &&
                        <Col span={5}>
                          <div>未释放</div>
                          <div><span className="big-days">{i.leaveStateCount[4]}</span><span className="day-font-size">天</span></div>
                        </Col>
                      }
                      {i.holidayType === holidayTypeList.prenatal &&
                        <Col span={5}>
                          <div>剩余</div>
                          <div><span className="big-days">{i.leaveStateCount[4]}</span><span className="day-font-size">天</span></div>
                        </Col>
                      }
                    </Row>
                    {
                      i.leaveApprovaListDtos.length > 0 &&
                      <Row className="leave-table">
                        <Table
                          dataSource={i.leaveApprovaListDtos}
                          columns={columns}
                          rowKey={record => record.id}
                          pagination={false}
                          size="small"
                        />
                      </Row>
                    }
                    <div className={`icon-bg icon-bg${i.holidayType}`}>
                      <img src={leaveBgConfig[i.holidayType].icon}/>
                    </div>
                  </Row>
                );
              })
            }
          </div>
        </Spin>
      </div>
    </div>,
  );
};

export default Pagelet;
