import React, { useState, useEffect } from 'react';
import { getLocale, Tooltip, ConfigProvider, Modal } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import PageletApi from '@didi/pagelets-sdk';
import useStyle from './style';
import { RightThickOutlined } from '@didi/iconflow/ec';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import {
  BASE_URL,
  HOME_PAGE_CATEGORY_IMG_MAP,
} from './utils/constant.jsx';
const localLocales = manifest.locales || {};
const Pagelet = props => {
  const prefixCls = 'pagelet-personal-ei-service';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const classes = classNames(prefixCls);

  const {
    params: { apiHost, locales, auth, headers },
  } = props;

  const [yuyueData, setYuyueData] = useState(null);
  const [yuyueShowData, setYuyueShowData] = useState(null);
  const [yuyuePage, setYuyuePage] = useState(0); // 当前页数
  const [yuyueTotal, setYuyueTotal] = useState(0); // 总页数
  const [proofData, setProofData] = useState(null);
  const [proofShowData, setProofShowData] = useState(null);
  const [proofPage, setProofPage] = useState(0); // 当前页数
  const [proofTotal, setProofTotal] = useState(0); // 总页数
  const [initError, setInitError] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionId: auth.id,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
  };
  PageletApi.newRequest.init(newHeaders, 'personal-ei-service');

  const handleEiData = (dataList) => {
    let yuyueTempList = [];  //预约总数据
    let proofTempList = [];  //证明总数据
    let showYuyueDate = [];  // 预约展示数据
    let showProofDate = [];  // 证明展示数据
    const yuyueList = dataList.find(item => item.type === 'yuyue');
    const proofList = dataList.find(item => item.type === 'proof');
    yuyueList?.categories?.forEach(value => {
      yuyueTempList.push({
        ...value,
        type: yuyueList?.type
      });
    })
    proofList?.categories[0]?.smallCatgoryDatas?.forEach(pValue => {
      proofTempList.push({
        ...pValue,
        type: proofList?.type
      });
    })
    // 当预约大类大于3时，展示分页
    if(yuyueTempList.length > 3) {
      showYuyueDate = yuyueTempList.slice(0, 3);
      setYuyuePage(1);
    } else {
      showYuyueDate = [...yuyueTempList];
    }
    // 当证明大于3时，并且预约大类有值，展示分页，否则证明平铺展示
    if(proofTempList.length > 3 && yuyueTempList.length !== 0 ) {
      showProofDate = proofTempList.slice(0, 3);
      setProofPage(1);
    } else {
      showProofDate = [...proofTempList];
    }
    setYuyueTotal(Math.ceil(yuyueTempList.length / 3));
    setProofTotal(Math.ceil(proofTempList.length / 3));
    setYuyueData(yuyueTempList);
    setYuyueShowData(showYuyueDate);
    setProofData(proofTempList);
    setProofShowData(showProofDate);
  }

  const handleRequest = async () => {
    try {
      const data = await PageletServices.initPersonalInfo(apiHost);
      if (data?.code === '100501') {
        setInitError(true);
      } else {
        if (data?.data?.categoryList?.length > 0) {
          handleEiData(data?.data?.categoryList);
        }
      }
    } catch (e) {
      setInitError(true);
      console.log(e);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);


  const handleUrl = (item) => {
    if (item.type === 'yuyue') {
      setModalFlag(true);
      setModalContent(item);
    }
    if (item.type === 'proof') {
      return window.open(
        `/home/my/selfservice/proof/detail?proofCode=${item.categoryCode}`,
        '_blank'
      );
    }
  }

  const previousPage = (type)=>{
    if(type === 'yuyue'){
      const page  = yuyuePage - 1;
      const info = yuyueData.slice(page*3-3, 3*page);
      if( page === 1){
        setYuyuePage(1);
        setYuyueShowData(info);
      } else {
        setYuyuePage(page);
        setYuyueShowData(info);
      }
    }
    if(type === 'proof'){
      const page  = proofPage - 1;
      const info = proofData.slice(page*3-3, 3*page);
      if( page === 1){
        setProofPage(1);
        setProofShowData(info);
      } else {
        setProofPage(page);
        setProofShowData(info);
      }
    }
  }
  const nextPage = (type)=>{
    if(type === 'yuyue'){
      const page  = yuyuePage + 1;
      const info = yuyueData.slice(page*3-3, 3*page);
      if( page === yuyueTotal){
        setYuyuePage(yuyueTotal);
        setYuyueShowData(info);
      } else {
        setYuyuePage(page);
        setYuyueShowData(info);
      }
    }
    if(type === 'proof'){
      const page  = proofPage + 1;
      const info = proofData.slice(page*3-3, 3*page);
      if( page === proofTotal){
        setProofPage(proofTotal);
        setProofShowData(info);
      } else {
        setProofPage(page);
        setProofShowData(info);
      }
    }
  }
  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <DHRCardTitle
            title="员工服务"
            showPrefixTag={false}
            type="grey"
            titleAdded={
              <DHRCardButton onClick={() => {window.open(`${window.location.origin}/home/my/selfservice`, '_blank')}}>
                更多 <RightThickOutlined cursor="pointer" size={14} className={`${prefixCls}-header-url-icon`}/>
              </DHRCardButton>
            }
        />
        {/* <div className={`${prefixCls}-header`}>
          <span className="header-tit">员工服务</span>
          <span className="header-link" onClick={()=>{
            window.open(`${window.location.origin}/home/my/selfservice`, '_blank')
          }}>
            <span>更多</span>
            <RightThickOutlinedCommonDirection size={14} className={`${prefixCls}-headerurl-icon`}/>
          </span>
        </div> */}
        {!initError && <div className={`personal-ei-service-card ${(yuyueData && yuyueData.length > 0) ? '' : ( proofData && proofData.length < 4 ? '' : 'personal-ei-service-card-proof')}`}>
          {(yuyueData && yuyueData.length > 0) && <div className="personal-ei-service-card-item">
            <div className="personal-ei-service-card-icon">
              <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/yuyue_card_icon.png" />
            </div>
            <div className="personal-ei-service-card-tip">预约</div>
            {
              yuyueShowData?.map(item => {
                return (
                  <div
                    className="personal-ei-service-card-value"
                    onClick={() => {handleUrl(item)}}
                  >
                    <Tooltip title={item.bigCategoryName}>
                      <span className="personal-ei-service-card-label">{item.bigCategoryName}</span>
                    </Tooltip>
                    {/* <img src={Right} /> */}
                  </div>
                )
              })
            }
            {
              yuyuePage !== 0 && <div className="personal-ei-service-card-page">
                <div className="personal-ei-service-card-left">
                  <img
                    className={`${yuyuePage === 1 ? 'leftDisabled' : 'leftArrow'}`}
                    src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/${yuyuePage === 1 ? 'leftDisabled' : 'leftArrow'}.png`}
                  />
                  {yuyuePage !== 1 && <img
                      src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/leftHover.png`}
                      onClick={()=>{yuyuePage !== 1 && previousPage('yuyue')}}
                      className="hover"
                    />}
                </div>
                <div className="personal-ei-service-card-right">
                  <img
                    className={`${yuyuePage === yuyueTotal ? 'rightDisabled' : 'rightArrow'}`}
                    src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/${yuyuePage === yuyueTotal ? 'rightDisabled' : 'rightArrow'}.png`}
                  />
                  {yuyuePage !== yuyueTotal && <img
                      src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/rightHover.png`}
                      onClick={()=>{ yuyuePage !== yuyueTotal && nextPage('yuyue')}}
                      className="hover"
                    />}
                </div>
              </div>
            }
          </div>}
          <div className={`personal-ei-service-card-item ${((yuyueData && yuyueData.length > 0) || (proofData && proofData.length < 4)  ) ? '' : 'personal-ei-service-card-item-proof'}`}>
            <div className="personal-ei-service-card-icon">
              <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/proof_card_icon.png" />
            </div>
            <div className="personal-ei-service-card-tip">证明</div>
            {
              proofShowData?.map(item => {
                return (
                  <div
                    className="personal-ei-service-card-value"
                    onClick={() => {handleUrl(item)}}
                  >
                    <Tooltip title={item.categoryName}>
                      <span className="personal-ei-service-card-label">{item.categoryName}</span>
                    </Tooltip>
                    {/* <img src={Right} /> */}
                  </div>
                )
              })
            }
            {
              proofPage !== 0 && <div className="personal-ei-service-card-page">
                <div className="personal-ei-service-card-left">
                  <img
                    className={`${proofPage === 1 ? 'leftDisabled' : 'leftArrow'}`}
                    src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/${proofPage === 1 ? 'leftDisabled' : 'leftArrow'}.png`}
                  />
                  {proofPage !== 1 && <img
                      src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/leftHover.png`}
                      onClick={()=>{ proofPage !== 1 && previousPage('proof')}}
                      className="hover"
                    />}
                </div>
                <div className="personal-ei-service-card-right">
                  <img
                    className={`${proofPage === proofTotal ? 'rightDisabled' : 'rightArrow'}`}
                    src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/${proofPage === proofTotal ? 'rightDisabled' : 'rightArrow'}.png`}
                  />
                  {proofPage !== proofTotal && <img
                      src={`//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/rightHover.png`}
                      onClick={()=>{ proofPage !== proofTotal && nextPage('proof')}}
                      className="hover"
                    />}
                </div>
              </div>
            }
          </div>
          <div className="personal-ei-service-card-item">
            <div className="personal-ei-service-card-icon">
              <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/qita_card_icon.png" />
            </div>
            <div className="personal-ei-service-card-tip">其他</div>
            <div
              className="personal-ei-service-card-value"
              onClick={() => {
                window.open(
                  `//dhr.didichuxing.com/service-center/visa/create`,
                  '_blank'
                );
              }}
            >
              <Tooltip title={'国际差旅'}>
                <span className="personal-ei-service-card-label">国际差旅</span>
              </Tooltip>
              {/* <img src={Right} /> */}
            </div>
            <div
              className="personal-ei-service-card-value"
              onClick={() => {
                window.open(
                  `/home/my/selfservice/archivesBorrowApply`,
                  '_blank'
                );
              }}
            >
              <Tooltip title={'档案借阅'}>
                <span className="personal-ei-service-card-label">档案借阅</span>
              </Tooltip>
              {/* <img src={Right} /> */}
            </div>
            <div
              className="personal-ei-service-card-value"
              onClick={() => {
                window.open(
                  `/home/my/selfservice/off-boarding/apply`,
                  '_blank'
                );
              }}
            >
              <Tooltip title={'离职申请'}>
                <span className="personal-ei-service-card-label">离职申请</span>
              </Tooltip>
              {/* <img src={Right} /> */}
            </div>
          </div>
        </div>}
        {initError && <div className={`${prefixCls}-content-err`}>
          <img
            src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
          />
          <span className='text-item-1'>服务不可用，请稍候再试。</span>
        </div>}
        <Modal
           wrapClassName="yuyue-home-modal-wrap"
           title={(
             <div className="yuyue-home-modal-wrap-title">
               <span className="yuyue-home-modal-wrap-title-category">
                 {modalContent?.bigCategoryName}
               </span>
                <span
                  className="yuyue-home-modal-wrap-title-qa"
                  onClick={() => {
                    window.open(
                      HOME_PAGE_CATEGORY_IMG_MAP[modalContent?.bigCategoryCode]?.qaLink
                    );
                  }}
                >
                  <img src={`${BASE_URL}icon_question.png`} alt="" />
                  <span>政策解惑</span>
                </span>
             </div>
           )}
           centered
           open={modalFlag}
           onOk={() => setModalFlag(false)}
           onCancel={() => setModalFlag(false)}
           footer={null}
           closable={false}
           width={400}
        >
          <div className="categoryName-list-wrap">
            {modalContent?.smallCatgoryDatas?.map(it => (
                <div
                  className="categoryName-list-item"
                  key={it?.categoryCode}
                  onClick={() => {
                    window.open(
                      `/home/my/selfservice/yuyue/detail?bigCategoryCode=${modalContent?.code}&smallCategoryCode=${it?.categoryCode}`,
                      '_blank'
                    );
                  }}
                >
                  <Tooltip title={it?.categoryName}>{it?.categoryName}</Tooltip>
                </div>
              ))
            }
          </div>
        </Modal>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
