import React, { useRef, useEffect, useMemo, useContext } from 'react';
import { Modal } from 'antd';
import CardIcon from '../../cnps/SvgIcon';
import CustomTimeLineHistoryNineBox from '../CustomTimeLineHistoryNineBox';
import './index.less';
import { LangTranslate } from '../../utils';
function CustomModalGridBoxHistoryModal(props) {
  const { data = {}, isModalOpen = false, setIsModalOpen = () => {}, activityId } = props;
  const modalBodyRef = useRef();
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      destroyOnClose
      width={600}
      height={409}
      title={
        <div className="talent-mark-custom-modal-gird-box-title-wrap">
          <div className="title">{LangTranslate('宫格落位')}</div>
          <div className="close-icon" onClick={handleCancel}>
            <CardIcon type="icon-quxiao" size={16} pointer />
          </div>
        </div>
      }
      open={isModalOpen}
      footer={null}
      className="talent-mark-custom-modal-gird-box-wrap"
      getContainer={e => e?.parentNode || document.body}
      closeIcon={null}
    >
      <div ref={modalBodyRef} className="talent-mark-custom-modal-line-chart-inner">
        <CustomTimeLineHistoryNineBox data={data} activityId={activityId} />
      </div>
    </Modal>
  );
}

const CustomModalGridBoxHistoryModalWrap = props => {
  if (props?.isModalOpen) {
    return <CustomModalGridBoxHistoryModal {...props} />;
  }
  return null;
};

export default CustomModalGridBoxHistoryModalWrap;
