import React, { useEffect, useReducer, useMemo } from 'react';
import { ConfigProvider, Spin } from '@didi/ec-base';
import { initial, reducer } from './store'; // 全局数据
import { PublicStore, getApolloConfig } from './utils/utils';
import { enumText } from './components/PageContent/components/FilterContent/utils';
import PageHeader from './components/PageHeader'; // 页面头部
import PageContent from './components/PageContent'; // 页面头部
import PageBottom from './components/PageBottom'; // 页面底部
import classNames from 'classnames';
import useStyle from './style';
import PageletServices from './services';  //页面请求方法
import { utils, newRequest } from '@didi/pagelets-sdk'; // 国际化翻译文件

const Pagelet = props => {
  const {
    params: { apiHost, auth, headers = {}, deptCodes = [] },
    store: {
      getState
    }
  } = props;

  const { functionId, functionName } = utils.getFunctionData(auth, '@didi/pagelet-perf-overview-management') || {}; // 获取组件的id
  // 配置请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  // 初始化请求头
  newRequest.init(newHeaders);
  // 样式
  const prefixCls = 'pagelet-perf-overview-management';
  const wrapSSR = useStyle(prefixCls, { size: '12px' });
  const classes = classNames(prefixCls);
  // 全局数据
  const [stateStore, stateDispatch] = useReducer(reducer, initial);
  const contextValue = useMemo(
    () => ({  // 存储全局数据
      stateStore,
      stateDispatch,
      props
    }),
    [stateStore]
  );
  const { loading } = stateStore;

  // 初始化接口请求
  const handleInitRequest = async () => {
    try {
      const res = await PageletServices.postDept('', {})
      const { data } = await PageletServices.postmManager('', { deptCodes, topDeptCodes: res?.data.map(item => item?.deptCode) });
      const {
        assessmentInfo, progressDistribution, searchParam,
        pageResult, staffChildSearchTypes, multiChildSearchType, hasDiffCompareToVersion } = data;
      const { distributions, totalNum } = progressDistribution;
      const { distributionCode, searchType } = searchParam;
      const findType = staffChildSearchTypes.find(item => item.selected);
      stateDispatch({
        ...searchParam, // 筛选条件
        assessmentInfo, // 周期
        distributions, // 状态图
        totalNum, // 柱状图数量
        staffChildSearchTypes: staffChildSearchTypes.map(item => item.searchType), // 筛选条件
        searchText: enumText[findType?.searchType],
        multiChildSearchType, // 兼岗/虚线下级
        hasDiffCompareToVersion,
        distributionName: distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        loading: false
      });
    } catch (e) {
      stateDispatch({ loading: false });
      console.log(e);
    }
  };

  // 接口请求示例
  useEffect(async () => {
    handleInitRequest();
    const res = await getApolloConfig(apiHost); // 获取apollo配置
    stateDispatch({ apolloConfig: res });
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <PublicStore.Provider value={contextValue}>
        <div className={classes}>
          {
            loading ? <div className='spin'>
              <Spin />
            </div> : <>
              <PageHeader />
              <PageContent />
              <PageBottom />
            </>
          }
        </div>
      </PublicStore.Provider>
    </ConfigProvider>
  );
};

export default Pagelet;
