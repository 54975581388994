import React, { useState, useEffect, useRef } from "react";
import { getLocale, getLang } from '@didi/ec-base';
import { Modal, message, Form } from "antd";
import * as antd from 'antd';
import newRequest from '../../utils/newRequest';
import PeopleSelect from '../PeopleSelectWithAuth/PeopleSelect';
import useStyle from './style';
const { ConfigProvider } = antd;
const en_US = antd.locales.en_US;
const zh_CN = antd.locales.zh_CN;
const TraineeTransfer = ({ internInfo, closeModal, successCallback, headers, authId, apiHost }) => {

  const prefixCls = 'trainee-transfer';
  const wrapSSR = useStyle(prefixCls);

  const [loading, setLoading] = useState(false);
  const langEn = getLang()?.toLowerCase().includes('us');
  const [form] = Form.useForm();
  const request = newRequest.getInstance();
  const close = () => {
    closeModal();
    form.resetFields();
  };

  const sure = () => {
    form.submit();
  };

  const onFinish = async (values) => {
    setLoading(true);
    const res = await request.post(`${apiHost}/gateway/bricks/api/workbench/personal/intern/transfer`, {
      newTutorLdap: values.nowTeacher.value,
      traineeLdap: internInfo.empLdap,
      // tutorType: "traineePassIntern",
    });
    if (res.code === "100200") {
      close();
      message.success("导师变更申请成功").then(() => {
        successCallback();
      });
      setLoading(false);
    } else if (res.code === "100400") {
      message.error(res?.msg || "系统错误");
      close();
      setLoading(false);
    } else {
      close();
      setLoading(false);
    }
  };

  const onMentorChange = (v, options) =>
    Object.assign({}, v, { deptPath: options?.data.deptPath });

  return wrapSSR(
    <ConfigProvider locale={langEn ? en_US : zh_CN}>
      <Modal
        title="实习生移交"
        visible={!!internInfo?.empLdap}
        maskClosable={false}
        cancelText="取消"
        okText="提交"
        confirmLoading={loading}
        onCancel={close}
        onOk={sure}
        width={600}
        className="card-my-mentor-modal"
        destroyOnClose
      >
        <Form name="complex-form" form={form} onFinish={onFinish}>
          <div className="mentor-detail">
            <div className="mentor-info">
              <div className="mentor-modal-title">实习生</div>
              <div className="mentor-info-content">
                <div className="mentor-info-name">
                  <div className="mentor-info-label">姓名:</div>
                  <div className="mentor-info-value">
                    {`${internInfo?.empName}(${internInfo?.empLdap}) ${internInfo?.empId}`}
                  </div>
                </div>
                <div className="mentor-info-dept">
                  <div className="mentor-info-label">部门路径:</div>
                  <div className="mentor-info-value" style={{width: '80%'}}>
                    {internInfo?.deptPath || "-"}
                  </div>
                </div>
              </div>
              <div className="mentor-modal-title">新导师</div>
              <div className="mentor-info-content" style={{ paddingBottom: 0 }}>
                <div className="mentor-info-name">
                  <div className="mentor-info-label">姓名:</div>
                  <div className="mentor-info-value">
                    <Form.Item
                      name="nowTeacher"
                      label=""
                      colon={false}
                      getValueFromEvent={onMentorChange}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <PeopleSelect
                        headers={headers}
                        authId={authId}
                        style={{ width: "200px" }}
                        placeholder="请输入员工姓名/工号/邮箱前缀"
                        labelInValue
                        listHeight={150}
                        empClass="CTR,RLB,CLB,PLB,CST"
                        hrStatus="A"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="mentor-info-dept">
                  <div className="mentor-info-label">部门路径:</div>
                  <div className="mentor-info-value" style={{width: '80%'}}>
                    <Form.Item shouldUpdate>
                      {() => {
                        return (
                          <span>
                            {form.getFieldValue("nowTeacher")
                              ? form.getFieldValue("nowTeacher")?.deptPath
                              : "-"}
                          </span>
                        );
                      }}
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default TraineeTransfer;
