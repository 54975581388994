import React from 'react';
import { di18n } from '@ultra/common';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import './index.scss';

const { intl } = di18n;

export default function QuestionnaireProcessH5(props) {
  const { activityId, singleQuestionaireList } = props;

  function handleEnter(e) {
    const {
      currentTarget: {
        dataset: {
          recyclestatus,
          respondentcode,
          source,
          contentid
        }
      }
    } = e;
    if (recyclestatus !== '2') {
      window.location.href = `${window.location.origin}/questionnaire/h5/${activityId}/${contentid}/${respondentcode}/${source}`;
    }
  }

  return (
    <>
      {
        singleQuestionaireList?.map((item, index) => (
          <div key={item.respondentTypeCode} style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 14, paddingLeft: 16, fontFamily: 'PingFangSC-Medium', color: 'rgba(0,0,0,0.84)' }}>
              {item.respondentTypeDesc}
            </div>

            {item?.contentInfos?.map((opt, key) => {
              const { contentId, activityRespondents } = opt;
              return (
                <div style={{ marginTop: key === 0 ? 12 : 8 }}>
                  {
                    activityRespondents?.map(value => (
                      <div
                        role="button"
                        tabIndex={0}
                        key={value.respondentCode}
                        onClick={handleEnter}
                        data-recyclestatus={value.recycleStatus}
                        data-respondentcode={value.respondentCode}
                        data-contentid={contentId}
                        data-source={index}
                      >
                        <div className="process-group-h5">
                          <div className="process-label">{value.activityRespondentFullName}</div>
                          <div className="process-right-card">
                            {
                              value.recycleStatus === 2 && (
                                <div className="process-finish">{intl.t('已完成')}</div>
                              )
                            }
                            <RightOutlined style={{ fontSize: 15, opacity: 0.24, display: value.recycleStatus === 2 ? 'none' : 'inline' }} />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              );
            })}

          </div>
        ))
      }
    </>
  );
}
