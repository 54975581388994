import { CloseOutlined } from '@ant-design/icons';
import {
  Form,
  FormEffectHooks,
  FormItem,
  FormPath,
  FormSpy,
  Reset,
  InternalVirtualField as VirtualField,
  useFormEffects,
  useFormSpy,
  useFormTableQuery
} from '@formily/antd';
import { Checkbox, Spin, Tooltip } from "antd";
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, memo } from "react";

import { DatePicker, Input, Select } from '@formily/antd-components';
import newRequest from '../../utils/newRequest';
import CardIcon from '../CardIcon';
import CopyableTag from '../CopyableTag';
import DeptSelect from '../DeptSelect';

import useStyle from './style';
import classNames from 'classnames';
import { zh_CN } from '@didi/ec-base';
import { ConfigProvider } from 'antd';


function useOnClickOutside(ref, handler) {
    const [visible, setVisible] = useState(false);
    const eventRef = useRef((event)=>{
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    })
    const binded = useRef(false)

    useEffect(
      () => {
      if(visible && !binded.current){
          binded.current = true
          document.addEventListener('mousedown', eventRef.current);
      }
      if(binded.current && !visible){
          binded.current = false
          document.removeEventListener('mousedown', eventRef.current);
      }
    },
    [visible]
  );

  return [visible, setVisible]
}

const isNotEmpty = (filterValue)=>{
    let hasValue = 0
    for (const key in filterValue) {
      let item = filterValue[key]
      if(Array.isArray(item)){
        if(!!item.length){
          hasValue = hasValue + 1
        }
      }else if(typeof item === 'object' && !!item){
        if(Object.keys(item).length || moment.isMoment(item)){
          hasValue = hasValue + 1
        }
      }else if(!!item){
        hasValue = hasValue + 1
      }
    }
    return !!hasValue
}

const DeptmentSelect = (props) => {
    const {value, onChange,deptKey,mode,childKey,getExtraValue, ...other} = props
    const changedept = (v,extraData)=>{
      if(deptKey){
        let newValue = JSON.parse(JSON.stringify(value||{}))
        newValue[deptKey] = v
        onChange(newValue,extraData)
      }else{
        onChange(v,extraData)
      }
    }
    const changeChid=(e)=>{
      let newValue = JSON.parse(JSON.stringify(value||{}))
      newValue[childKey] = e.target.checked
      onChange(newValue)
    }

    return <div style={{display: 'flex',alignItems: "center"}}>
      <DeptSelect multiple={mode === 'multiple'} value={childKey?value?.[deptKey]:value} onChange={changedept} {...other} labelInValue noCopyableTag />
      {childKey && <Checkbox checked={value?.[childKey]} onChange={changeChid}>包含子部门</Checkbox>}
    </div>
}

// 分为输入框，筛选，多选，日期，
const FormFilterItem = ({ data, logConfig, stashValue, request })=>{

    const ref = useRef();
    const [value, setValue] = useState(null);

    const [left, setleft] = useState(0);

    const hasChanged = useRef(false)
    const {searchUrl,initNotEmpty} = data
    const popWidth = 320

    const stashFetch = useRef(false)
    const isFetch = useRef(false)

    const filterINT = useRef(!window.FilterStorage?.[window.location.pathname])

    const { form } = useFormSpy({ selector: '*', reducer: v => v })

    const getPopPosition = ()=>{
        const {left} = ref.current.getBoundingClientRect();
        const viewWidth = window.innerWidth || document.documentElement.clientWidth;
        if((left + popWidth) > viewWidth){
            setleft(viewWidth - (left + popWidth) - 24)
        } else {
            setleft(0)
        }
    }

    const showPop = ()=>{
        if (data.disabled) return
        getPopPosition()
        setVisible(true)
        form.notify('filterShowPop',data.name)
    }

    const restField = ()=>{
        let restValue = data.mode === 'multiple' ? [] : undefined
        if(typeof data.initValue !== "undefined"){
            restValue = data.initValue
        }
        form.setFieldValue(data.name, restValue)
        setValue(null)
    }

    const HidePop = ()=>{
        // 在这里上报
        // console.log('name', data.name, form.getFieldValue(data.name))
        let value = form.getFieldValue(data.name)
        if (logConfig && window.taotieCommandQueue && value) {
            const logValue = moment.isMoment(value) ? value.format('YYYY-MM-DD') : value instanceof Object ? JSON.stringify(value) : value?.toString()
            let tmp1 = {};
            (value instanceof Object && !moment.isMoment(value)) && Object.entries(value).forEach(([key, v], index) => {
                tmp1['dimension' + (index + 3) ] = v
            })
            window.taotieCommandQueue.push({
                command: 'sendEventlogMessageToKafka',
                parameter: {
                  category: logConfig.category, // 埋点类别
                  action: logConfig.action,
                  dimension1: data.name,
                  dimension2: logValue,
                  ...tmp1
                }
              });
        }
        // 设置值，刷新表单
        setValue(value)
        setVisible(false)
        form.validate(data.name).then((res) => {
            if(hasChanged.current){
                hasChanged.current = false;
                form.submit()
            }
        });
        if(form.getFieldState(data.name).errors.length){
            form.clearErrors(data.name)
            restField()
        }
    }

    const emptyThis = (e)=>{
        setVisible(false)
        e.stopPropagation();
        restField(data)
        form.submit()
    }

    const [visible, setVisible] = useOnClickOutside(ref,HidePop);

    const getComponentProp = (component,option)=>{
      let defaultOption = {}
      switch(component){
        case 'Select':
          defaultOption = {
              getPopupContainer:trigger => trigger.parentNode,
              component: Select,
              labelInValue: true,
          }
          if(!option.searchUrl){
              defaultOption.open = true;
              defaultOption.dropdownMatchSelectWidth = 266
              defaultOption.placeholder = option.filterTip || '请选择'
          }
          if(option.searchUrl){
              defaultOption.placeholder = option.filterTip || '请输入搜索'
          }
          if(option.initNotEmpty){
              defaultOption.notFoundContent= null
          }
          return defaultOption
        case 'Input':
          defaultOption = {
              component: Input,
              maxLength: 150,//太长不好看
              onPressEnter: ()=>{HidePop()},
              placeholder: option.filterTip
          }
          return defaultOption
        case 'RangePicker':
          defaultOption = {
              open: true,
              component: DatePicker.RangePicker,
              getPopupContainer:trigger => trigger.parentNode,
          }
          return defaultOption
        case 'DeptmentSelect':
          defaultOption = {
              component: DeptmentSelect,
              copyableTag:true,
              open:true
          }
          return defaultOption
        default:
          return defaultOption
      }
    }

    const FromItemProps = useMemo(()=>{
        const tempData = {}
        Object.keys(data).forEach(i=>{
            if(['labelKey','searchKey','valueKey','searchUrl'].indexOf(i)>=0 ){
                return
            }
            tempData[i] = data[i]
        })
        return Object.assign({},tempData,{title:null},getComponentProp(data.component,data))
    },[data]);

    const initprops = useRef(FromItemProps);
    // initprops = FromItemProps

    const { onFieldValueChange$,onFieldInit$,onFormReset$,onFormSubmitEnd$,onFormSubmitStart$  } = FormEffectHooks

    useFormEffects(($, { setFieldState,getFieldState,getFormState,getFieldInitialValue }) => {
      onFieldValueChange$(data.name).subscribe((state) => {
          if(isFetch.current){
              stashFetch.current = true
          }
          hasChanged.current = true
          setValue(data.value || state.value)
          if(data.component === 'Select'  && (data.mode !== 'multiple') || data.onValueChangeHide){
              if(stashValue && !filterINT.current){
                  return
              }
              HidePop()
          }
      })
      $('filterValueSetQuery').subscribe(async() => {
          filterINT.current = true
      })
      onFormReset$().subscribe(() => {
          if(data.noClear && getFieldInitialValue(data.name)){
              setValue(getFieldInitialValue(data.name))
          }else{
              setValue(null)
          }
      })
      onFormSubmitStart$().subscribe(() => {
          isFetch.current = true
      })
      onFormSubmitEnd$().subscribe(() => {
          isFetch.current = false
          if(stashFetch.current){
              stashFetch.current = false
              form.submit()
          }
      })
      $('refreshFilteritemValue').subscribe((name) => {
        if(name === data.name){
          setValue(getFieldState(name).value)
        }
      })
      onFieldInit$(data.name).subscribe(() => {
        if(searchUrl){
          const {searchKey,labelKey,valueKey,namekey} = data
          setFieldState(data.name, state => {
            FormPath.setIn(state, 'props.filterOption', false)
            FormPath.setIn(state, 'props.showSearch', true)
            FormPath.setIn(state, 'props.onSearch', value => {
              if (!value) return;
              setFieldState(data.name, state => {
                if(initNotEmpty){
                  FormPath.setIn(state, 'props.notFoundContent', <Spin size="small" />)
                }
                FormPath.setIn(state, 'props.loading', true)
              })
              const getValueType = (key,v,type,all)=>{
                if (typeof key === 'string') {
                  v[type] = all[key];
                } if (typeof key === 'function') {
                  v[type] = key(all);
                }
                return v
              }
              const fetchData = async ()=>{
                // 兼容报表中心searchKey 不存在，直接将value拼接到配置的url上
                const params = searchKey ? {
                  [searchKey]:value
                } : null
                let url = searchKey ? searchUrl : `${searchUrl}${value}`
                const res = await request({
                    method: 'get',
                    url: url,
                    params,
                });
                if(!res.data){
                    return
                }
                setFieldState(data.name, state => {
                  FormPath.setIn(state, 'props.loading', false)
                  if(initNotEmpty){
                      FormPath.setIn(state, 'props.notFoundContent', null)
                  }
                  const result = res.data.map(v=>{
                    let defaultValue = {
                        value: v[valueKey],
                        title: v[valueKey]
                    };
                    if (typeof labelKey === 'string') {
                        defaultValue.label = v[labelKey];
                    } if (typeof labelKey === 'function') {
                        defaultValue.label = labelKey(v);
                    }
                    defaultValue = getValueType(valueKey,defaultValue,'value',v)
                    if (namekey) {
                        defaultValue.namekey = v[namekey];
                    }
                    return defaultValue;
                  })

                  FormPath.setIn(state, 'props.dataSource', result)
                })
              }
              fetchData()
            })
          })
        }

        if(data.component === 'DeptmentSelect' && data.deptKey){
            setFieldState(data.name, state => {
                state.initialValue = {[data.childKey]: true}
                state.value = {[data.childKey]: true}
            })
        }
      })
    })

    const renderFitter = useMemo(()=>{
      let valueText = ''
      let renderTip = false
      let tempValue = value
      if(!!tempValue && tempValue.copyableTag && data.copyableTag){
        renderTip = tempValue.copyableTag
      }
      if(!!value && data.component === 'DeptmentSelect'){
        tempValue = data.deptKey?value[data.deptKey]:value
        if(data.mode !== 'multiple'){
          renderTip = value.label
        }
      }
      if(!!tempValue && data.component === 'RangePicker'){
        const arr = !tempValue[0] && !tempValue[1]  ? [] : tempValue.map(v => {
          return v || '-'
        })
        valueText = arr.map(v=>v).join('至')
      }else if(!!tempValue && Array.isArray(tempValue)){
        const list = tempValue.map(v=>v.label)
        if(data.copyableTag){
            renderTip = list
        }else{
            renderTip = list.join('、')
        }
        valueText = tempValue.length ? `(${tempValue.length})` : ''
      }else if(!!tempValue && moment.isMoment(tempValue)){
          valueText = moment(tempValue).format(data.format||'YYYY-MM-DD')
      }else if(!!tempValue){
          valueText = typeof tempValue === 'object' ?tempValue.label:tempValue
      }
      const inner = <span onClick={() => {
        showPop()
      }} className={"form-filter-label "}>
          <span>
            {data.title}
            {(valueText)?' : ':null}
            <span>{valueText}</span>
          </span>
          <span className={"form-filter-icon " + (data.noClear?'no-clear':'')}>
            <span className="form-filter-down"><CardIcon type="icondropdown" className="form-filter-icon-down" /></span>
            <span className="form-filter-clear" onClick={emptyThis}><CloseOutlined style={{verticalAlign: 'middle'}}/></span>
            {/* <span className="form-filter-clear" onClick={emptyThis}><CardIcon type="iconeliminate" className="form-filter-icon-down" /></span> */}
          </span>
        </span>
      if(renderTip){
          if(data.copyableTag){
            return <CopyableTag tooltiplist={renderTip} multiple={data.mode === 'multiple'} noCtrl>
              {inner}
            </CopyableTag>
          }
          return <Tooltip title={renderTip}>
          {inner}
        </Tooltip>
      }
      return inner;
    },[value, data.disabled])

    let tempValue = data.component === 'DeptmentSelect' && data.deptKey && value?value[data.deptKey]:value

    return <VirtualField name={`${data.name}field`}>
      <div className={"form-filter-item " + ((Array.isArray(tempValue) ? tempValue.length :  !!tempValue)?'has-value':'') + (data.disabled ? ' form-filter-item-disabled' : '')} ref={ref}>
        {renderFitter}
        <div className={"form-filter-dropdown " + (visible?'form-filter-dropdown-show ':'') + (data.component === 'DeptmentSelect'?'form-filter-dropdown-deptmentselect ':'') + (data.component === 'DeptmentSelect' && data.childKey ?'form-filter-dropdown-deptmentselect-has-child ':'')} style={{left:left}}>
          <FormItem  {...initprops.current} />
        </div>
      </div>
    </VirtualField>
}

const FormFilter = forwardRef(({form,filter,total, unShowFormSpy, logConfig,stashValue,filterExceptKey, intl},ref) => {
    const request = newRequest.getInstance();

    const filterINT = useRef(!window.FilterStorage?.[window.location.pathname])
    let filterStorage = window.FilterStorage?.[window.location.pathname]
    const filterStorageList = useRef(typeof filterStorage === 'object' && Object.keys(filterStorage).length > 0?Object.keys(filterStorage):[])

    useImperativeHandle(ref, () => ({
      getfilterINT:()=>{
        return filterINT.current
      }
    }));

    const getValueText = (value, data) => {
      let valueText = ''
      let renderTip = false
      if(!!value && data.component === 'RangePicker'){
        const arr = !value[0] && !value[1]  ? [] : value.map(v => {
            return v || '-'
        })
        valueText = arr.map(v=>v).join('至')
      }else if(!!value && Array.isArray(value)){
        const list = value.map(v=>v.label)
        renderTip = list.join('、')
        valueText = value.length ? `(${value.length})` : ''
      }else if(!!value && moment.isMoment(value)){
        valueText = moment(value).format(data.format||'YYYY-MM-DD')
      }else if(!!value){
        valueText = typeof value === 'object' ?value.label:value
      }

      return renderTip || valueText
    }
    const rendeFilterTip = (renderTipData) => {
      return (
        <div className="rende-filter-tip">
          {
            Object.keys(renderTipData).map((v, i) => {
              return renderTipData[v].value ? <div key={i} className="rende-filter-tip-item">
                <div className="rende-filter-tip-label">{renderTipData[v].label}：</div>
                <div className="rende-filter-tip-value"><span></span> {renderTipData[v].value}</div>
                </div> : null

            })
          }
        </div>
      )
    }


    const prefixCls = 'pagelet-form-filter';
    const wrapSSR = useStyle(prefixCls);

    const classes = classNames(prefixCls, 'form-filter', {
      'form-filter-visible': true
    })

    return wrapSSR(
      <ConfigProvider locale={zh_CN}>
        <Form
          {...form}
          effects={($,action) => {
            form.effects($,action);

            $('onFormMount').subscribe(async() => {
              if(filterStorage && typeof filterStorage === 'object' && Object.keys(filterStorage).length > 0 && stashValue){
                //存在筛选值
                await action.setFormState((state) => {
                  state.values = filterStorage
                })
                action.dispatch('filterValueSet',action.getFormState())
              }
            })

            $('onFieldValueChange').subscribe(async(state) => {
              if(!filterINT.current){
                if(filterStorageList.current.indexOf(state.name)>-1){
                  filterStorageList.current = filterStorageList.current.filter((i) => i !== state.name);
                }
                if(filterStorageList.current.length === 0){
                  action.dispatch('filterValueSetFinished',action.getFormState())
                }
              }else{
                action.dispatch('filterValueSetFinished',action.getFormState())
              }
            })

            const setParama = (value)=>{
              const dealObject = value
              if(dealObject && typeof dealObject === 'object' && Object.keys(dealObject).length > 0){
                Object.keys(dealObject).forEach(i=>{
                  if(typeof dealObject[i] === 'undefined' || dealObject[i] === null){
                    delete dealObject[i]
                  }
                })
              }
              window.FilterStorage = window.FilterStorage || {};
              window.FilterStorage[window.location.pathname] = dealObject;
            }

            $('filterValueSetFinished').subscribe(async() => {
              filterINT.current = true
            })

            $('onFormSubmit').subscribe(async() => {
              setParama(isNotEmpty(action.getFormState().values)?action.getFormState().values:undefined)
            })
            $('onFormReset').subscribe(async() => {
              setParama(undefined)
            })
          }}
          className={classes}
        >
          <div className="form-filter-container">
            {filter.map((v,i)=><FormFilterItem data={v}  key={v.name} logConfig={logConfig} stashValue={stashValue} request={request} />)}
          </div>

          {
            unShowFormSpy ? null : (
              <FormSpy selector={["onFormSubmit","onFormReset","filterValueSet"]}>
                {({ state,type,form}) => {
                  let renderTipData = {};
                  let keyNumber = 0;
                  for (const key in state.values) {
                    let item = state.values[key]
                    let nowItem = filter.filter(i=>i.name === key)[0]
                    if(nowItem?.component === 'DeptmentSelect' && nowItem?.deptKey){
                      item = item[nowItem?.deptKey]||undefined
                    }
                    if(key.indexOf(filterExceptKey) >=0){
                    }else if(Array.isArray(item)){
                      if(!!item.length){
                        keyNumber = keyNumber + 1
                      }
                    }else if(typeof item === 'object' && !!item){
                      if(!!item.value || moment.isMoment(item)){
                        keyNumber = keyNumber + 1
                      }
                    }else if(!!item){
                      keyNumber = keyNumber + 1
                    }
                  }

                  for(const item of filter) {
                    if (state?.values && state?.values[item.name]) {
                      renderTipData[item.name] = {
                        label: item.title,
                        value: item.component === 'DeptmentSelect'?
                        getValueText(item.deptKey?state.values[item.name][item.deptKey]:state.values[item.name], item) + (item.childKey && state.values[item.name][item.childKey] ?' （包含子部门）' :''):
                        getValueText(state.values[item.name], item)
                      }
                    }
                  }

                  return <div className={"table-detail" + (keyNumber > 0 ?' has-select': '')}>
                    <span>{intl('TOTAL {number} RESULTS', { number: total })}</span>
                    <span className="has-many">
                      <Tooltip placement="bottomLeft" overlayStyle={{width: '294px'}} title={() => rendeFilterTip(renderTipData)} >
                        <span>{keyNumber} {intl('FILTERING_CRITERIA')}</span>
                      </Tooltip>
                    </span>
                    <Reset type="link" className="empty-this"><CardIcon type="iconLine-delete" className="form-filter-icon-empty" />{intl('CLEAR_CONDITIONS')}</Reset>
                  </div>
                }}
              </FormSpy>
            )
          }
      </Form>
    </ConfigProvider>
  )
})

FormFilter.useFormTableQuery = (service,middlewares,defaultProps)=>{
  const initFilterWithValueMiddleware = (pattern, value) => ({ waitFor,actions }) => ({
    async onFormFirstQuery(payload, next) {
      let filterStorage = window.FilterStorage?.[window.location.pathname]
      let payloadnew = payload
      let hasFilter = isNotEmpty(filterStorage)
      if(filterStorage && typeof filterStorage === 'object' && Object.keys(filterStorage).length > 0 && hasFilter){
        await waitFor('filterValueSetFinished', state => {
          payloadnew = Object.assign(payload,state.values)
          window.FilterStorage = window.FilterStorage || {};
          window.FilterStorage[window.location.pathname] = filterStorage;
          actions.dispatch('filterValueSetQuery')
          return true
        })
      }else{
        actions.dispatch('filterValueSetQuery')
      }
      return next(payloadnew)
    },
    async onFormQueryFailed(error, next) {
      // if(error.status !== 504 && error.status !== 500 && actions){
      //     actions.reset()
      // }
      return next(error)
    },
  })

  return useFormTableQuery(service,[...middlewares,initFilterWithValueMiddleware()],defaultProps)
}

FormFilter.dealWithValue = (fitterList,values)=>{
  const getValue = {}
  fitterList.forEach(v=>{
    let tempvalue = null
    let thisValue = values[v.name]
    if(!thisValue){
      return
    }
    if(v.component === 'Select'){
      if(Array.isArray(thisValue)){
        tempvalue = thisValue.map(v=>v.value).join(',')
      }else{
        tempvalue = thisValue.value
      }
    } else if(v.component === 'Input'){
      tempvalue = thisValue.replace(/(^\s*)|(\s*$)/g,'')
    }else{
      tempvalue = thisValue
    }
    if(v.component === 'RangePicker'){
      if(v.rangePickerSingleKey === 'stringKey') {
        getValue[v.name] = thisValue.join(',')
        if(v.timeFormat === 'timestamp' && thisValue) {
          getValue[v.name] = thisValue.map(valueTimestamp => moment(valueTimestamp).hours(23).minutes(59).seconds(59).valueOf()).join(',')
        }
      } else {
        getValue[v.startKey] = thisValue[0]
        getValue[v.endKey] = thisValue[1]
        if(v.timeFormat === 'timestamp' && getValue[v.startKey]){
          getValue[v.startKey] = moment(getValue[v.startKey]).valueOf()
        }
        if(v.timeFormat === 'timestamp' && getValue[v.endKey]){
          getValue[v.endKey] = moment(getValue[v.endKey]).hours(23).minutes(59).seconds(59).valueOf()
        }
      }
    }else if (v.component === 'DeptmentSelect'){
      if(v.deptKey){
        if(v.mode === 'multiple'){
          getValue[v.deptKey] = thisValue[v.deptKey]?thisValue[v.deptKey].map(v=>v.value).join(','):undefined
        }else{
          console.log(thisValue[v.deptKey])
          getValue[v.deptKey] = thisValue[v.deptKey]?.value
        }
        getValue[v.childKey] = thisValue[v.childKey]
      }else{
        if(v.mode === 'multiple'){
          getValue[v.name] = thisValue?thisValue.map(v=>v.value).join(','):undefined
        }else{
          getValue[v.name] = thisValue?.value
        }
      }
    }else{
      getValue[v.name] = tempvalue
    }
  })
  if (values.extendsValues) {
    Object.keys(values.extendsValues).forEach(key => {
      getValue[key] = values.extendsValues[key]
    })
  }
  return getValue
}

FormFilter.hasStahValue = ()=>{
  return !!window.FilterStorage?.[window.location.pathname]
}
export default FormFilter;
