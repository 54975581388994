import React, { useEffect, useState, useRef  } from 'react';
import { getLocale, Avatar, Tooltip, AutoComplete, message, Spin, Empty } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import * as antd from 'antd';
import PageletApi from '@didi/pagelets-sdk';
import debounce from "lodash/debounce"; //函数防抖

import useStyle from './style';
import lockUrl, { ReactComponent as Lock } from './assets/lock.svg';
// 图片建议手动上传到 gift 使用
// import comment from './assets/comment.png';

const localLocales = manifest.locales || {};
const { ConfigProvider } = antd;
// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost = '//dhr-dev.intra.xiaojukeji.com', headers, locales, pageCode, permissionScope, auth, deptCodes, item },
  } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth?.id,
  };
  PageletApi.newRequest.init(newHeaders, '@didi/pagelet-canghai-talent-search');
  const prefixCls = 'pagelet-canghai-talent-search';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);
  const classes = classNames(prefixCls);
  const searchRef = useRef(null);
  const [showText, setShowText] = useState(false); // hover展示
  const [showSearchModal, setShowSearchModal] = useState(false); // 搜索的小弹窗展示
  const [options, setOptions] = useState(); // 搜索下拉内容
  const [modalHeight, setModalHeight] = useState(); // 搜索弹窗的高度
  const [searchValue, setSearchValue] = useState(undefined); // 搜索的内容
  const [loading, setLoading] = useState(false); // loading
  const [topNum, setTopNum] = useState('126px'); // 固定定位距离顶部位置
  const initSrc = '//img-hxy021.didistatic.com/static/ehr_static/imgs/canghai/cards/search.png';
  const emptySrc = '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/no_data_image.png';

  // 点击搜索外区域
  const handleDocumentClick = (event) => {
    if (searchRef?.current &&!searchRef?.current?.contains(event.target)) {
      setShowSearchModal(false);
      setModalHeight();
      setOptions([]);
      setSearchValue(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);
    const dom = document.querySelector('#gridContainer');
    setTopNum(dom?.offsetTop);
    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, []);

  // 搜索方法
  const handleSearch = async (value) => {
    const param = { deptCodes: deptCodes.join(), pageType: pageCode, keyWord: value };
    const res = await PageletServices.getSearchData(apiHost, param);
    if (res?.code === "100200") {
      setOptions(res?.data || { data: [] });
    } else {
      message.error(res?.msg);
    }
    setLoading(false);
  };

  // 使用 useEffect 来监听 searchValue 变化
  useEffect(() => {
    // 无内容不搜索
    if(!searchValue) {
      setOptions({ data: [] });
      setLoading(false);
      return;
    };
    setLoading(true);
    const debouncedHandleSearch = debounce(() => handleSearch(searchValue), 200);
    debouncedHandleSearch();
    return () => debouncedHandleSearch.cancel(); // 清除防抖函数
  }, [searchValue]);

  // 高亮提示搜索的内容
  const highlightText = (text, search) => {
    if (!search) return text;
    const regex = new RegExp(`(${search})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part?.toLowerCase() === search?.toLowerCase() ?
        <span key={index} style={{ color: '#FF6400' }}>{part}</span> :
        part
    );
  };

  // 处理部门展示
  const handleDeptpatd = value => {
    const deptArr = value.split('>');
    const str = deptArr.length > 2
      ? ''.concat(deptArr[0], '>...>').concat(deptArr[deptArr.length - 1])
      : value;
    return str;
  };

  // 下拉选项内容
  const optionsList = options?.data?.map(item => {
    const { photo, name, empId, deptPath, label } = item;
    return {
      value: `${name}-${empId}-${deptPath}`,
      label: (
        <div className='item-option'>
          <Avatar size={28} src={photo} />
          <div className='item-option-content'>
            <p className='item-option-content-name'>
              {highlightText(name, searchValue)}
              {label === 1 && <span className='quit'>离职</span>}
              {label === 2 && <span className='internship'>实习</span>}
            </p>
            <Tooltip title={deptPath} placement="bottom" overlayClassName='canghai-search-tooltip'>
              <div className='item-option-content-dept'>
                {highlightText(empId, searchValue)}
                <span> | </span>
                <span>{highlightText(handleDeptpatd(deptPath), searchValue)}</span>
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      key: `${options?.naviUrl}empId=${item?.empId}`
    };
  });

  useEffect(() => {
    setTimeout(() => {
      const dom = document.querySelector('.show-search-modal');
      const dropdownDom = document.getElementById('canghai-search-modal')?.querySelector('.rc-virtual-list-holder');
      if(dropdownDom){
        dropdownDom.style.maxHeight = (window?.innerHeight - 245) + 'px';
        const height = dropdownDom?.offsetHeight + 126 + 'px';
        setModalHeight(height);
      };
      if((!optionsList || !optionsList?.length) && dom){
        setModalHeight('260px');
      };
      if(!searchValue) {
        setOptions({ data: [] });
      };
    }, 100);
  }, [optionsList?.length, searchValue, loading]);

  return wrapSSR(
    <div
      className={classNames(classes, {'show-search-modal': showSearchModal })}
      style={{ height: modalHeight, top: topNum }}
      ref={searchRef}
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
      onClick={() => setShowSearchModal(true)}
    >
      {
        !showSearchModal
        ? <>
            <img src={initSrc} alt='' />
            {showText && <span className='text'>人才搜索</span>}
          </>
        : (
          <div className='search-modal' id='canghai-search-modal'>
            <div className='search-modal-title'>
              <img src={initSrc} alt='' />
              <span>查询员工档案</span>
            </div>
            <AutoComplete
              virtual={false}
              options={optionsList}
              placeholder='请输入邮箱前缀/姓名/工号进行搜索'
              onSearch={setSearchValue}
              value={searchValue}
              open={true}
              onSelect={(_, option) => window.open(option?.key, '_blank')}
              getPopupContainer={e => e.parentNode}
              notFoundContent={(searchValue && !optionsList?.length) ? (loading ? <div className='data-loading'>数据加载中</div> : <Empty  image={emptySrc} description='暂无数据' />) : null}
            />
            <Spin spinning={loading} style={{ zIndex: loading ? '99999' : '0' }} />
          </div>
        )
      }
    </div>
  )
};

export default Pagelet;
