import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider, Row, Col, Tooltip, Spin, Empty, zh_CN, en_US, getLang } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { getPositionInfo } from './services';
import Position from './assets/position.svg';

const localLocales = manifest.locales;

const isNull = (str) => {
  if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
    return '-';
  };
  return str;
}

const Pagelet = (props) => {
  const { params: { locales, apiHost, headers, auth, employeeId, usePermissionResponse, colorPrimary = '#0A70F5' } } = props;

  const positionAuth = auth?.filter(item => item.elementTag === 'archive-job-basic-info')?.length > 0;
  const leaveAuth = auth?.filter(item => item.elementTag === 'archive-job-leave-info')?.length > 0;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getPositionDetail = async () => {
    try {
      setLoading(true);
      const res = await getPositionInfo(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res.data).length > 0) {
        setData(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log('getOnboardingInfo', error);
    }
  };

  useEffect(() => {
    getPositionDetail();
  }, []);

  const prefixCls = 'pagelet-position-info';
  const wrapSSR = useStyle(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{ token: { colorPrimary } }}>
      <Spin spinning={loading}>
        <div className={classes}>
          <div className='position-title'>
            <Position />
            <div className='title-info'>{locale.jobInfo}</div>
          </div>
          {
            data && Object.keys(data).length > 0 && <div className='position-content'>
              <Row gutter={[16, 16]}>
                <Col span={leaveAuth && data?.hrStatus === 'I' ? 6 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.sequence}:</div>
                        {
                          data?.jobFunctionDesc || data?.jobFunctionDesc || data?.jobSubFuctionDesc ? (
                            <div className='single-data'>{data?.jobFunctionDesc}{data?.jobFunctionDesc && data?.jobSubFuctionDesc && '-'}{data?.jobSubFuctionDesc}{data?.jobFamilyDesc && '-'}{data?.jobFamilyDesc}</div>
                          ) : '-'
                        }
                      </div>
                    )
                  }
                </Col>

                <Col span={leaveAuth && data?.hrStatus === 'I' ? 6 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.jobLevel}:</div>
                        <div className='single-data'>{isNull(data?.jobLevelDesc)}</div>
                      </div>
                    )
                  }

                </Col>
                <Col span={leaveAuth && data?.hrStatus === 'I' ? 6 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.lineManager}:</div>
                        <div className='single-data'>{isNull(data?.managerName)}{data?.managerId && <span style={{ marginLeft: '6px' }}>({data?.managerId})</span>}</div>
                      </div>
                    )
                  }
                </Col>

                {leaveAuth && data?.hrStatus === 'I' && (
                  <Col span={6}>
                    <div className='single-info'>
                      <div className='single-title'>{locale.lastWorkDate}:</div>
                      <div className='single-data'>{isNull(data?.terminationDate)}</div>
                    </div>
                  </Col>
                )}

                <Col span={leaveAuth && data?.hrStatus === 'I' ? 6 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.jobTitle}:</div>
                        <Tooltip title={isNull(data?.positionName)} placement="topLeft">
                          <div className='single-data single-data-more'>{isNull(data?.positionName)}</div>
                        </Tooltip>
                      </div>
                    )
                  }

                </Col>

                <Col span={leaveAuth && data?.hrStatus === 'I' ? 6 : 8}>
                  {
                    positionAuth && (
                      <>
                        <div className='single-info'>
                          <div className='single-title'>{locale.currentLevelDuration}:</div>
                          <div className='single-data'>{isNull(data?.jobLevelTime)}</div>
                        </div>
                      </>
                    )
                  }

                </Col>

                <Col span={leaveAuth && data?.hrStatus === 'I' ? 6 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.HRBP}:</div>
                        <div className='single-data'>{isNull(data?.hrbpName)}{data?.hrbpId && <span style={{ marginLeft: '4px' }}>({data?.hrbpId})</span>}</div>
                      </div>
                    )
                  }
                </Col>

                {
                  leaveAuth && data?.hrStatus === 'I' && (
                    <Col span={6}>
                      <div className='single-info'>
                        <div className='single-title'>{locale.leaveReason}:</div>
                        <div className='single-data'>{isNull(data?.terminationType)}{data?.terminationReason && <span style={{ marginLeft: '4px' }}>({data?.terminationReason})</span>}</div>
                      </div>
                    </Col>
                  )
                }

                <Col span={leaveAuth && data?.hrStatus === 'I' ? 12 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.departmentRoute}:</div>
                        <Tooltip title={isNull(data?.deptNamePath)} placement="topLeft">
                          <div className='single-data single-data-more'>{isNull(data?.deptNamePath)}</div>
                        </Tooltip>
                      </div>
                    )
                  }
                </Col>

                <Col span={leaveAuth && data?.hrStatus === 'I' ? 12 : 8}>
                  {
                    positionAuth && (
                      <div className='single-info'>
                        <div className='single-title'>{locale.companyName}:</div>
                        <Tooltip
                          title={data?.companyDesc + `${(data?.contractStartDate || data?.contractEndDate) ? `(${isNull(data?.contractStartDate)}${locale?.to}${isNull(data?.contractEndDate)})` : ''}`}
                          placement="topLeft"
                        >
                          <div className='single-data single-data-more'>{isNull(data?.companyDesc)}{(data?.contractStartDate || data?.contractEndDate) && <span style={{ marginLeft: '4px' }}>({isNull(data?.contractStartDate)}{locale?.to}{isNull(data?.contractEndDate)})</span>}</div>
                        </Tooltip>
                      </div>
                    )
                  }
                </Col>
              </Row>
            </div>
          }
          {
            (!data || Object.keys(data).length === 0) && !loading && <Empty mode="part" style={{ marginTop: '-8px' }} />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
