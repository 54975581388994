import React, { useState, useEffect } from 'react';
import { getLocale, Row, Col, Tooltip, getLang, ConfigProvider } from '@didi/ec-base';
import manifest from '../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { getPositionInfo } from './services';
import { ReactComponent as Position } from './assets/position.svg';

const localLocales = manifest.locales || {};

const isNull = str => {
  if (`${str}`.replace(/\s/gi, '') === '' || str === null || str === undefined) {
    return '-';
  }
  return str;
};

const pagelet = props => {
  const {
    params: { locales, apiHost, headers, employeeId, usePermissionResponse }
  } = props;

  console.log('locales=====pagelet', locales);

  const [data, setData] = useState({});

  const prefixCls = 'pagelet-inventory-position-info';
  const wrapSSR = useStyle(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  const handleRequest = async () => {
    try {
      const res = await getPositionInfo(apiHost, headers, {
        employeeId,
      }, usePermissionResponse);

      console.log('res=====', res);
      const { data } = res;
      setData(data);
    } catch (error) {
      console.log('getPositionInfo', error);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <div className="position-title">
          <Position />
          <div className="title-info">{locale.jobInfo}</div>
        </div>
        <div className="position-content">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <div className="single-info">
                <div className="single-title">{locale.sequence}：</div>
                {data?.jobFunctionDesc || data?.jobFunctionDesc || data?.jobSubFuctionDesc ? (
                  <div className="single-data">
                    {data?.jobFunctionDesc}
                    {data?.jobFunctionDesc && data?.jobSubFuctionDesc && '-'}
                    {data?.jobSubFuctionDesc}
                    {data?.jobFamilyDesc && '-'}
                    {data?.jobFamilyDesc}
                  </div>
                ) : (
                  <>-</>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="single-info">
                <div className="single-title">{locale.jobLevel}：</div>
                <div className="single-data">{isNull(data?.jobLevelDesc)}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="single-info">
                <div className="single-title">{locale.lineManager}：</div>
                <div className="single-data">
                  {isNull(data?.managerName)}
                  {data?.managerId &&
                    (getLang() === 'en-US' ? `(${data?.managerId})` : `（${data?.managerId}）`)}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <>
                <div className="single-info">
                  <div className="single-title">{locale.JobFamilyDuration}：</div>
                  <div className="single-data">
                    {isNull(data?.jobFuncStayTime)}
                    {data?.lessHalfYearSequ && (
                      <span className="year-sequ-text">{data?.lessHalfYearSequ}</span>
                    )}
                  </div>
                </div>
              </>
            </Col>
            <Col span={8}>
              <>
                <div className="single-info">
                  <div className="single-title">{locale.currentLevelDuration}：</div>
                  <div className="single-data">{isNull(data?.jobLevelTime)}</div>
                </div>
              </>
            </Col>
            <Col span={8}>
              <div className="single-info">
                <div className="single-title">{locale.departmentRoute}：</div>
                <Tooltip title={isNull(data?.deptNamePath)} placement="topLeft">
                  <div className="single-data single-data-more">{isNull(data?.deptNamePath)}</div>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
    </div>
  </ConfigProvider>,
  );
};

export default pagelet;
