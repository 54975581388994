import React, { useEffect, useState } from 'react';
import { getLocale, Tooltip, Divider } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { TimelineCard } from '@didi/ehr-timeline-card';
import { getEduExp } from './services';
import SchoolSvg from './assets/school.svg';

const isNull = (str) => {
	if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
		return '-';
	};
	return str;
}

const localLocales = manifest.locales;
const { render: { LineItem } } = TimelineCard;

const Pagelet = (props) => {
  const { params: { locales, apiHost, headers, employeeId, theme = 'blue' } } = props;

  const [data, setData] = useState([]);

  const getEduDetail = async () => {
    try {
      const res = await getEduExp(apiHost, headers, {
        employeeId,
      });

      const { data } = res;
      setData(data);
    } catch (error) {
      console.log('getEduExp', error);
    }
  }

  useEffect(() => {
    getEduDetail();
  }, []);

  const prefixCls = 'pagelet-education';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  return wrapSSR(
    <div className={classes}>
      {
        data?.length > 0 && (
          <TimelineCard>
            {data?.map(item => (
              <LineItem startTime={item.startTimeStr} endTime={item.endTimeStr} theme={theme}>
                <div className='edu-card-container'>
                  <div className='edu-card-icon'>
                    <SchoolSvg />
                  </div>
    
                  <Tooltip title={item.schoolName} placement="topLeft">
                    <div className='edu-card-school'>
                      {isNull(item.schoolName)}
                    </div>
                  </Tooltip>
                  <Tooltip title={<>{item.country}{item.education && (<Divider type="vertical" style={{background: '#fff'}} />)}{item.education}{item.major && (<Divider type="vertical" style={{background: '#fff'}}/>)}{item.major}</>} placement="topLeft">
                    <div className='edu-card-major'>
                      {item.country}{item.education && <Divider type="vertical" />}{item.education}{item.major && <Divider type="vertical" />}{item.major}
                    </div>
                  </Tooltip>
                  
                  <div className='edu-card-tag'>
                    {item.schoolTag && <div className='tag'>{item.schoolTag}</div>}
                  </div>
                </div>
              </LineItem>
            ))}
          </TimelineCard>
        )
      }
    </div>,
  );
};

export default Pagelet;
