import { ConfigProvider, en_US, getLang, getLocale, zh_CN  } from '@didi/ec-base';
import { DHRTable as AntdTable } from '@didi/ec-ehr-base';
import React, { useCallback, useEffect, useState } from 'react';
import * as ReactDragListView from 'react-drag-listview';
import EmptyRender from '../EmptyRender';

/**
 * Table 组件
*/

const Table = (props) => {
  const { children, className, size, zebraGrid, emptyDom, showFilter, columns, dragColumns, radiusSize, intl, ...restProps } = props;
  const [columnsList,setColumnsList] = useState(columns || [])
  const langEn = getLang()?.toLowerCase().includes('us');
  useEffect(() => {
      if(columns){
          setColumnsList(columns)
      }
  },[columns])

  const emptyRender = () => {
    return <div className="empry-render-info">
      {
        emptyDom ? emptyDom : <div className="empry-render">
          {intl('NO_DATA')}
        </div>
      }
    </div>
  }

  const onDragEnd = useCallback((fromIndex, toIndex) => {
      const arr =  [...columnsList];
      const item = arr.splice(fromIndex, 1)[0];
      arr.splice(toIndex, 0, item);
      setColumnsList([...arr]);
  }, [columnsList]);


  return (
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN} renderEmpty={emptyRender}>
      {
        dragColumns ? <ReactDragListView.default.DragColumn
          nodeSelector='th'
          onDragEnd={onDragEnd}
        >
          <EmptyRender intl={intl}>
            <AntdTable
              locale={langEn ? en_US : zh_CN}
              size="small"
              {...restProps}
              columns={ columnsList}
            >
              {children}
            </AntdTable>
          </EmptyRender>
        </ReactDragListView.default.DragColumn>
        : (
          <EmptyRender intl={intl}>
            <AntdTable
              locale={langEn ? en_US : zh_CN}
              size="small"
              {...restProps}
              columns={ columnsList}
            >
              {children}
            </AntdTable>
          </EmptyRender>
        )
      }
    </ConfigProvider>
  );
};


export default Table;
