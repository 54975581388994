import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('work-relationship-modal-tree-diagram', () => [
		{
			// base
			[`.${prefixCls}`]: {
        width: 'max-content',
        height: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',

        ['.complete-tree']: {
          ['.org-tree, .org-tree-node-label-inner']: {
            padding: '0 !important',
            boxShadow: 'none !important',
            borderRadius: '0 !important',
          }
        },

        ['.node-container']: {
          width: '182px',
          height: '70px',
          padding: '16px 0 16px 12px',
          borderRadius: '12px',
          border: '1px solid rgba(0, 0, 0, 0.06)',
          background: 'linear-gradient(193deg, #DFE5FF 0%, #FFFFFF 41%)',
          margin: '0 auto',
          position: 'relative',
          textAlign: 'left',

          ['&>div']: {
            width: '100%',
            overflow: 'hidden',
          },

          ['.tree-avatar']: {
            ['.ec-base-avatar-content']: {
              ['.username']: {
                fontWeight: '500',
              }
            }
          },

          ['.item-tag-con']: {
            position: 'absolute',
            top: '31px',
            left: '27px',

            ['svg']: {
              fontSize: '24px',
              transform: 'scale(0.6)',
            }
          }
        },
			}
		}
	]);
};

export default useStyle;
