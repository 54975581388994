import React, { useState, useRef } from 'react';
import { Select, Tooltip, Spin, Button } from 'antd';
import CopyableTag from '../CopyableTag';
import request from '../../utils/request';
import publicRequest from '../../utils/publicRequest';
import useDebouncedCallback from '../../utils/useDebouncedCallback';
import './index.scss';

const { Option } = Select;

const PeopleSelectEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <p>没有结果</p>
  </div>
);

const PeopleSelect = props => {
  const [peopleList, setPeopleList] = useState([]);
  const [tooltipvisible, settooltipvisible] = useState(false);
  const [tooltipvalue, settooltipvalue] = useState(undefined);
  const [loading, setloading] = useState(false);
  const [fristloading, setfristloading] = useState(true);
  const [srollLoading, setSrollLoading] = useState(false);
  const istreeOpen = useRef(false);
  const isLastPage = useRef(false);
  const currentPage = useRef(0);
  const currentSearchWord = useRef('');
  const { mode, valueSelectKey, getlabelKey, empClass, hrStatus, deptCodeList, hasAuth, hasSave, onSubmit, getChildData, type, containerClassName = '', self = false, ...other } = props;
  const [value, setValue] = useState([]);

  const searchFetch = async (keyword, pageNum, callback = () => { }) => {
    if (!keyword) {
      setPeopleList([]);
      setloading(false);
      isLastPage.current = false;
      currentSearchWord.current = '';
      setfristloading(true);
      return;
    }
    if (isLastPage.current && pageNum > 1) {
      setSrollLoading(false);
      return;
    }
    if (pageNum > 1) {
      setSrollLoading(true);
    }
    if (pageNum === 1) {
      setPeopleList([]);
    }
    currentPage.current = pageNum;
    currentSearchWord.current = keyword;
    setloading(true);
    let res = null;
    let data = null;
    if (self) { // 通过本人权限查
      res = await request({
        method: 'post',
        url: '/ctActivity/api/common/getUserInfoByAuthDeptCodes',
        data: { keyword, pageNum, empClassList: empClass }
      });
      data = res;
    } else if (other.source) { // 不同来源从系统查
      res = await request({
        method: 'post',
        url: '/ctActivity/api/activity/participant/searchAllByKeyWord',
        data: { keyword, pageNum, activityId: other.activityId }
      });
      data = res;
    } else { // 权限中心查
      const getALL = {
        method: 'post',
        url: '/open/v1/emp/component/searchAll',
        data: { keyword, pageNum, empClass, hrStatus, dsfDataSource: 'ALL' }
      };
      res = await publicRequest(hasAuth ? {
        method: 'post',
        url: '/open/v1/emp/component/search',
        data: { keyword, pageNum, empClass, hrStatus, deptCodeList, dsfDataSource: 'ALL' }
      } : getALL);
      data = res.data;
    }
    setfristloading(false);
    setloading(false);

    callback(data);
    if (data && data.list) {
      if (pageNum !== currentPage.current) {
        return;
      }
      setSrollLoading(false);
      isLastPage.current = data.isLastPage;
      console.log('data.list', data.list, pageNum)
      if (pageNum === 1) {
        setPeopleList(data.list);
      } else {
        setPeopleList([...peopleList, ...data.list]);
      }
    }
  };

  const debounceSearch = useDebouncedCallback(searchFetch, 1000);

  const onSearch = keyword => {
    debounceSearch(keyword, 1);
  };

  const onVisibleChange = visible => {
    if (istreeOpen.current && visible) {
      return;
    }
    settooltipvisible(visible);
  };
  const onDropdownVisibleChange = open => {
    if (open) {
      settooltipvisible(false);
    }
    istreeOpen.current = open;
  };

  const selectThis = (value, valueObject) => {
    setValue(value);
    if (typeof getChildData === 'function') {
      getChildData(value);
    }
    if (props.mode === 'multiple') {
      settooltipvalue(valueObject.map(i => i.tooltip));
    } else {
      settooltipvalue(valueObject ? valueObject.tooltip : null);
    }
    if (props.labelInValue && value) {
      value.copyableTag = props.mode === 'multiple' ? valueObject.map(i => i.tooltip) : valueObject.tooltip;
    }
    props.onChange && props.onChange(value, valueObject);
  };

  const scroll = e => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
      debounceSearch(currentSearchWord.current, currentPage.current + 1);
    }
  };

  const getOptionLabel = i => {
    if (other.source) {
      return (mode === 'multiple' ? i.name || i.empName : `${i.name || i.empName}(${i.ldap})`);
    }
    return (mode === 'multiple' ? i.name || i.empName : `${i.name || i.empName}(${i.ldap}) ${i.empId}`);
  };

  const getOptionTooltip = i => {
    if (other.source) {
      return `${i.name || i.empName}(${i.ldap}), ${i.deptPath}`;
    }
    return `${i.name || i.empName}(${i.ldap}) ${i.empId} ${i.deptPath || ''}`;
  };

  const options = peopleList.map(i => (
    <Option key={i.ldap} value={i[valueSelectKey || 'ldap']} label={getlabelKey ? getlabelKey(i) : getOptionLabel(i)} tooltip={getOptionTooltip(i)} data={i}>
      <Tooltip
        title={(
          <>
            <div>{`${i.name || i.empName}(${i.ldap}) ${other.source ? '' : i.empId}`}</div>
            {i.deptPath && <div>{`${i.deptPath}`}</div>}
          </>
        )}
        overlayClassName="copyable-tooltip"
      >
        <div className="dhr-people-select-label-item">
          <div className="dhr-people-select-label-detail">{`${i.name || i.empName}(${i.ldap}) ${other.source ? '' : i.empId}`}</div>
          {i.deptPath && <div className="dhr-people-select-deptPath">{i.deptPath}</div>}
        </div>
      </Tooltip>
    </Option>
  ));

  return (
    <div className={`dhr-people-select ${containerClassName}`}>
      <CopyableTag tooltiplist={tooltipvalue} multiple={mode === 'multiple'} onVisibleChange={onVisibleChange} tooltipvisible={tooltipvisible} noCopyableTag={props.noCopyableTag}>
        <Select
          mode={mode}
          placeholder={other.placeholder ? other.placeholder : `请输入姓名/邮箱前缀/工号`}
          showSearch
          allowClear
          onSearch={onSearch}
          onDropdownVisibleChange={onDropdownVisibleChange}
          style={{ width: '100%' }}
          optionLabelProp="label"
          filterOption={false}
          onPopupScroll={scroll}
          maxTagCount={20}
          value={value}
          maxTagPlaceholder={v => (
            <div>
              +
              {v.length}
            </div>
          )}
          notFoundContent={loading ? <Spin size="small" /> : (fristloading ? null : <PeopleSelectEmpty />)}
          {...other}
          onChange={selectThis}
          dropdownRender={menu => (
            <div>
              {menu}
              {srollLoading && (
                <div className="dhr-people-select-loading">
                  <Spin size="small" />
                </div>
              )}
            </div>
          )}
        >
          {options}
        </Select>
      </CopyableTag>

      {
        hasSave && typeof onSubmit === 'function' && (
          <Button
            className="add-user-btn"
            // type="text"
            type="primary"
            shape="round"
            disabled={typeof type === 'string' && value.length === 0}
            onClick={() => {
              onSubmit({ value }).then(() => {
                setValue([]);
                settooltipvalue(null);
              })
                .catch(() => {
                  setValue(value);
                });
            }}
          >
            确定添加
          </Button>
        )
      }

    </div>
  );
};

export default PeopleSelect;
