/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { message } from 'antd';
import { matchPath } from 'react-router';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { axios } from './common';
import ERROR_CODE from '../constants/error_code';
import { recordPageTiming, getPendingRequestCancelTypeFn, getWeakNetwork } from './perf';
// import {
//   setExceptionMes
// } from '../store/questionnaireNew/questionContentSlice';

const pendingRequest = new Map(); // 请求重复key队列

// ### PERF TIMING ###
const perfUrlMapping = $CONFIG.customProps.perfUrlMapping || [];
window.perf = window.perf || {};

const recordTimes = 0; // 记录上报次数
window.pathCache = {};

const isMatchPath = (currentPath, routePath) => matchPath(currentPath, { path: routePath, exact: true, strict: false });

// const matchApi = currentPath => {
//   if (window.pathCache[currentPath]) return window.pathCache[currentPath];
//   let ret;
//   // eslint-disable-next-line no-restricted-syntax
//   for (const { path, apiUrl } of perfUrlMapping) {
//     if (isMatchPath(currentPath, path)) {
//       ret = apiUrl;
//       window.pathCache[currentPath] = apiUrl;
//       break;
//     }
//   }
//   return ret;
// };

// const recordPageTiming = (time, requestTime) => {
//   const dimensions = {
//     dimension1: location.pathname,
//     dimension2: time,
//     dimension3: requestTime,
//     dimension4: location.search,
//   };
//   if (recordTimes > 0) {
//     dimensions['dimension5'] = recordTimes;
//   }
//   window.taotieCommandQueue && window.taotieCommandQueue.push({
//     command: 'sendEventlogMessageToKafka',
//     parameter: {
//       category: 'performance',
//       action: 'timing',
//       ...dimensions,
//     }
//   });
//   console.log('[recordPageTiming]', time, requestTime, {
//     path: location.pathname,
//     search: location.search,
//     recordTimes,
//   });
//   recordTimes += 1;
// };
// ### PERF TIMING ###

const forbiddenPage = `${location.origin}/questionnaire/no-permission`;
const endPage = `${location.origin}/questionnaire/activity/not/started`;
const success = `${location.origin}/questionnaire/success`;
const errorPage = `${location.origin}/questionnaire/ExceptionPage`;
const weekNetworkPage = `${location.origin}/questionnaire/weekNetwork`;

function getLang() {
  return Cookies.default.get('ct-lang');
}

function useLoadErrPage() {
  // const dispatch = useDispatch();
  const setData = msg => {
    // setExceptionMes({data: msg})
    sessionStorage.setItem('mesData', msg);
    location.replace(errorPage);
  };
  return [setData];
}
const [setData] = useLoadErrPage();
window.addEventListener('popstate', () => {
  window.isPageLoaded = true;
  window.perf['startTime'] = new Date().getTime();
  window.perf['lastRecordPath'] = '';
  window.perf['lastRecordApi'] = '';
});
const HTTP = axios.create({
  baseURL: '/',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'x-kylin-proxy-with': 'apirequest',
    'Content-Type': 'application/json',
    lang: getLang(),
  },
});

// 处理请求返回
const opResponse = res => {
  const { code, msg } = res;
  switch (code) {
    case '100200': {
      return Promise.resolve(res);
    }
    case '699999': {
      return Promise.resolve(res);
    }
    case '190016': {
      // 活动已结束
      location.replace(endPage);
      break;
    }
    // case '190017': {
    //   // 无权限跳转
    //   location.replace(forbiddenPage);
    //   break;
    // }
    case '190018': {
      // 跳转已提交页面
      location.replace(success);
      break;
    }
    case '190046': case '190047': case '190048': case '100011': case '190009': {
      message.error(msg);
      return Promise.reject({
        code,
        error: ERROR_CODE.UNDEFINED,
        message: msg,
      });
    }
    // case '19000020': {
    //   // 无权限跳转
    //   location.replace(forbiddenPage);
    //   break;
    // }
    default:
      setData(msg);
      break;
    // message.error(msg);
    // return Promise.reject({
    //   code,
    //   error: ERROR_CODE.UNDEFINED,
    //   message: msg,
    // });
  }
};

HTTP.interceptors.response.use(res => {
  // ### PERF TIMING ###
  // console.log('res?.config', res?.config);

  const mapPath = res?.config.requestPath.split('/').slice(1, 3).join('/');
  // console.log('mapPath', mapPath);
  // console.log('res.config.url', res.config.url);
  for (const { path, apiUrl } of perfUrlMapping) {
    if (path === mapPath && apiUrl === res.config.url) {
      const endTime = new Date().getTime() - window.perf['startTime'];
      const requestTime = new Date().getTime() - res.config.requestStartTime;
      window.perf['lastRecordPath'] = location.pathname;
      window.perf['lastRecordApi'] = res.config.url;
      const requestUrl = res.config?.url;
      recordPageTiming(endTime, requestTime, requestUrl);
    }
  }
  // if (res?.config
  //   && res?.config.requestPath === location.pathname
  //   && (window.perf['lastRecordPath'] !== location.pathname || window.perf['lastRecordApi'] !== res.config.url)
  //   && matchApi(res?.config.requestPath) === res.config.url
  // ) {
  //   const endTime = new Date().getTime() - window.perf['startTime'];
  //   const requestTime = new Date().getTime() - res.config.requestStartTime;
  //   window.perf['lastRecordPath'] = location.pathname;
  //   window.perf['lastRecordApi'] = res.config.url;
  //   const requestUrl = res.config?.url;
  //   recordPageTiming(endTime, requestTime, requestUrl);
  // }
  // ### PERF TIMING ###

  const { data } = res;

  return opResponse(data);
}, responseData => {
  const { response } = responseData;
  if (response) {
    // message.error(response.data?.message);
    if (response.status === 401) {
      if ($CONFIG.customProps.newRequest) {
        if (!window.redirectLogin && response.data.loginUrl) {
          window.redirectLogin = true;
          window.location.href = response.data.loginUrl;
        }
      } else {
        location.reload();
      }
    } else if (response.status === 400 || response.status === 500 || response.status === 403) {
      return opResponse(response.data); // 当成200处理
    } else {
      return Promise.reject(response);
    }
  } else {
    // 不是手动触发cancel的请求 是真实弱网跳弱网页
    if (!pendingRequest.has(responseData?.message)) {
      location.replace(getWeakNetwork('https://img-hxy021.didistatic.com/static/ehr_static/files/ct/weakNetwork1.0.html'));
    }
    return {};
  }
});

HTTP.interceptors.request.use(config => {
  const cfg = { ...config };
  // 判断是否需要携带ct_role
  if (config.role) {
    cfg.headers = { ...config.params, ct_role: config.role, lang: getLang() };
  }
  // ### PERF TIMING ###
  if (!window.perf['firstPath']) {
    window.perf['startTime'] = performance.timing.connectStart;
    window.perf['firstPath'] = location.pathname;
  }
  cfg.requestPath = location.pathname;
  cfg.requestStartTime = new Date().getTime();
  // ### PERF TIMING ###

  if (config.method === 'get') {
    if (config.params) {
      cfg.params = { ...config.params };
    }
  } else if (config.method === 'post') {
    if (config.data) {
      cfg.data = JSON.stringify(config.data);
    }
  }

  // 处理重复请求
  pendingRequestConfig(cfg);
  return cfg;
}, error => {
  console.log('系统错误', error);
  // message.info('系统错误');
});

// addPendingRequest 将重复请求添加到pendingRequest中
const addPendingRequestFn = (key, config) => {
  if (!pendingRequest.has(key)) { // 判断pendingRequest中是否存在key
    config.cancelToken = new axios.CancelToken(cancel => {
      pendingRequest.set(key, cancel); // 将key和cancel以键值对的方式保存
    });
  }
};

// 取消重复请求
const cancelPendingRequestFn = key => {
  if (pendingRequest.has(key)) { // 判断pendingRequest中是否存在key
    const cancelToken = pendingRequest.get(key);
    cancelToken(key); // 取消之前发送的请求
    pendingRequest.delete(key);// 请求对象中删除requestKey
  }
};

// 接口取消逻辑判断
const pendingRequestConfig = config => {
  const requestKey = getPendingRequestCancelTypeFn(config);
  if (requestKey) {
    // 在范围内的接口做取消逻辑
    cancelPendingRequestFn(requestKey);
    addPendingRequestFn(requestKey, config);
  }
};

export default HTTP;
