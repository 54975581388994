import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-performance-records-modal', () => [
    {

			// base
      [`.${prefixCls}`]: {

        ['.title']: {
          fontSize: '18px!important',
        },

        ['.performance-records-modal-title']: {
          fontFamily: 'PingFang SC',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          color: 'rgba(0, 0, 0, 0.84)',
          marginTop: '12px',
        },

        ['.performance-records-modal-rating-info']: {
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: 500,
          color: 'rgba(0, 0, 0, 0.84)',
          marginTop: '8px',

          ['&>span:nth-of-type(odd)']: {
            color: 'rgba(0, 0, 0, 0.6)',
            marginRight: '6px',
          },

          ['&>span:nth-of-type(even)']: {
            marginRight: '24px',
          },
        },

        ['.performance-records-modal-evaluate-info']: {
          borderRadius: '10px',
          border: '0.5px solid rgba(0, 0, 0, 0.2)',
          padding: '16px 5px 16px 16px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '12px',
          lineHeight: '20px',
          margin: '12px 0',

          ['&>div']: {
            maxHeight: '338px',
            overflow: 'auto',
            paddingRight: '7px',
          },

          ['::-webkit-scrollbar:vertical,::-webkit-scrollbar-button,::-webkit-resizer'] : {
            display: 'inline-block'
          },

          ['::-webkit-scrollbar-thumb']: {
            background: 'rgba(0, 0, 0, 0.13)!important',
          },

          ['::-webkit-scrollbar-track']: {
            background: 'none !important',
          },
        },

        ['.performance-records-modal-evaluator-info']: {
          fontFamily: 'PingFang SC',
          color: 'rgba(0, 0, 0, 0.84)',
          fontWeight: 500,
          marginTop: '20px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',

          ['>span:nth-of-type(1)']: {
            fontSize: '16px',
            whiteSpace: 'nowrap'
          },

          ['span:nth-of-type(2)']: {
            color: 'rgba(0, 0, 0, 0.6)',
            marginRight: '8px',
          },

          ['.performance-records-modal-evaluator-info-name']: {
            display: 'flex',
            alignItems: 'center',

            [' > span']: {
              maxWidth: '260px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }
          }
        }
			}
		}
	]);
};

export default useStyle;
