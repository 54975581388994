import { Tooltip } from '@didi/ec-base';
import moment from 'moment';
import React from 'react';
const showEllipseWord = (str, splitWord = '>', isShowHover = true) => {
  const arr = str.split(splitWord);
  const len = arr.length;
  if (len < 4) return str;
  if (isShowHover) {
    return (
      <Tooltip title={str}>
        {`${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`}
      </Tooltip>
    );
  }
  return `${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`;
};

const deptFormat = text => (
    showEllipseWord(text)
)
//文本格式化  超出15字节tooltip
const textFormat = text => (
  <Tooltip placement='topLeft' title={String(text).length > 15 && text}>
    {text}
  </Tooltip>
)

//文本格式化   不显示15字节tooltip
const tooltipFormat = text => (
  <Tooltip placement='topLeft' title={text}>
    {text}
  </Tooltip>
)

//时间格式化  时间戳转化为具体时间
const timeFormat = text => (
  <div>{text ? moment(text).format('YYYY-MM-DD') : '-'}</div>
)
export { textFormat, timeFormat, tooltipFormat , deptFormat};

