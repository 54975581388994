import React from 'react';
import { SvgIconMaps } from './constant.jsx';

export default function (props) {
  const { type, styles, size, pointer = false } = props;
  const style = {
    fontSize: size ? `${size}px` : 'inherit',
    width: size,
    height: size,
    cursor: pointer ? 'pointer' : 'auto',
    width: '1em',
    height: '1em',
    verticalAlign: 'middle',
    fill: 'currentColor',
    overflow: 'hidden',
    ...styles,
  };
  const className = `talent-market-custom-icon ${props?.className}`;
  return React.cloneElement(SvgIconMaps?.[type] || SvgIconMaps?.['__default__'], {
    ...props,
    style,
    className,
    'aria-hidden': true,
  });
}
