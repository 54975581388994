import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { Radio } from 'antd';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent, AriaComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { TIME_RANGE_TYPE } from "../../../utils/constant";
// import { DrawerContext } from '../context';
import NoData from '../NoData';
import { careImageUrl } from "../../../utils/common";

echarts.use([TooltipComponent, GridComponent, AriaComponent, BarChart, CanvasRenderer]);

// 前五字段及颜色
const holidayInit = (leftW, rightW) => {
  return {
    noTop: [
      {
        id: 'annualLeaveBalanceDays',
        name: '未休年假',
        barMinHeight: 48,
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          { offset: 0, color: '#FFA425' },
          { offset: 1, color: '#FF8800' }
        ]),
        borderRadius: [6, 0, 0, 6],
        label: {
          show: true, //开启显示
          position: 'insideRight', //在哪显示
          textStyle: { //数值样式
            color: '#FFFFFF',//字体颜色
            fontSize: 12//字体大小
          },
          formatter: params => `${params?.value-leftW} 天`
        },
      },
      {
        id: 'annualLeaveDueSoonDays',
        name: '年底到期',
        color: 'rgba(255, 138, 84, 0.2)',
        borderRadius: [0, 6, 6, 0],
        barMinHeight: 100,
        label: {
          show: true, //开启显示
          position: 'insideRight', //在哪显示
          textStyle: { //数值样式
            color: 'rgba(0, 0, 0, 0.84)',//字体颜色
            fontSize: 10,//字体大小
            rich: {
              value: {
                fontSize: 12,
                color: '#E71221',
              }
            }
          },
          formatter: params => `年底到期 {value|${params?.value-rightW}} 天`
        },
      },
    ],
    hasTop: [
      {
        id: 1,
        name: '年假',
        color: 'rgba(255, 126, 65)',
      },
      {
        id: 3,
        name: '病假',
        color: 'rgba(54, 191, 145)',
      },
      {
        id: 2,
        name: '事假',
        color: 'rgba(85, 135, 242)',
      },
      {
        id: 4,
        name: '婚假',
        color: 'rgba(250, 112, 112)',
      },
      {
        id: 5,
        name: '产假',
        color: 'rgba(161, 126, 229)',
      },
      {
        id: 6,
        name: '流产假',
        color: 'rgba(161, 126, 229)',
      },
      {
        id: 7,
        name: '产检假',
        color: 'rgba(245, 122, 163)',
      },
      {
        id: 8,
        name: '陪产假',
        color: 'rgba(161, 126, 229)',
      },
      {
        id: 9,
        name: '丧假',
        color: 'rgba(102, 102, 102)',
      },
      {
        id: 10,
        name: '探亲假',
        color: 'rgba(242, 178, 48)',
      },
      {
        id: 13,
        name: '育儿假',
        color: 'rgba(144, 192, 45)',
      },
      {
        id: 14,
        name: '备用育儿假',
        color: 'rgba(244, 192, 86)',
      },
    ],
  }
};

// 自定义tooltip
const defineTooltip = (params, type, nowTime, allChartType = '') => {
  let total = 0;
  const details = [];
  let header = `${params[0].axisValue}:`;
  let body = '';

  params.filter((item) => item.data).map((item) => {
    total += item.data;
    details.push({
      data: item.data,
      holidayType: item.seriesId,
      holidayName: item.seriesName,
    });
  });

  if (type === 'hasAll') {
    if (allChartType === 'H') {
      header = `
        <span class="title-icon" style="background-color: ${params[0].color}"></span>
        <span>${params[0].axisValue}</span>
      `;
    } else {
      header = `
        <span>${new Date(nowTime).getFullYear()} ${params[0].axisValue}</span>
      `;
    }
    body = `
      <div class="body-detail">
        <div>天数：${params[0].data}天</div>
      </div>
    `;
  } else {
    body = `
      ${
  (type === 'hasTop')
    ? `<div class="total">已休总天数：${total}天</div>`
    : ''
}
      ${
  details.map((item) => (
    `<div class="body-detail">
              <span class="holiday-type-${item.holidayType}"></span>
              <span>${item.holidayName}：${item.holidayType === 'annualLeaveBalanceDays' ? total : item.data}天</span>
            </div>`
  )).join('')
}
    `;
  }

  return (
    `<div class="define-tooltip">
      <div class="define-tooltip-header">
        <div class="header-title">
          ${header}
        </div>
      </div>

      <div class="define-tooltip-body">
        ${body}
      </div>
    </div>`
  );
};

const sortList = (list) => {
  list.sort((a, b) => {
    if (a.sort === b.sort) return b.employeeId.localeCompare(a.employeeId, 'zh-cn');
    return a.sort - b.sort;
  });

  return list;
};

// 前五
const TopCharts = (props) => {
  const {
    type: chartsType,
    chartsData,
  } = props;

  const [noData, setData] = useState(false);

  const noTopCharts = useRef(null);
  const hasTopCharts = useRef(null);

  const findMaxValue = (arr) => {
    let max = 0; // 最大值
    let index = 0
    for (let i=0; i<arr.length; ++i) {
        if (arr[i].anualLeaveTotalDays > max) { // 当前值大于最大值，赋值为最大值
          max = arr[i].anualLeaveTotalDays
          index = i
        }
    }
    return {
      max,
      index
    };
  }

  const MIN_WIDTH = 148;  // 柱状图最小宽度
  const USE_WIDTH = 237;  //柱状图可用宽度
  const LEFT_MIN_WIDTH = 48;  // 柱状图左边最小宽度
  const RIGHT_MIN_WIDTH = 100;  // 柱状图右边最小宽度

  const initTopChart = () => {
    const topChart = echarts.init(chartsType === 'noHoliday' ? noTopCharts.current : hasTopCharts.current);
    topChart.clear();
    let { max, index } = findMaxValue(chartsData);
    let leftW = Math.ceil((MIN_WIDTH * max / USE_WIDTH) * (LEFT_MIN_WIDTH/MIN_WIDTH));   //根据数据多少计算柱状图左边的占一份
    let rightW = Math.ceil((MIN_WIDTH * max / USE_WIDTH) * (RIGHT_MIN_WIDTH / MIN_WIDTH));   //根据数据多少计算柱状图右边的占一份
    const type = chartsType === 'noHoliday' ? 'noTop' : 'hasTop';
    const list = sortList(chartsData);
    const initList = holidayInit(leftW, rightW)[type];
    const yAxisData = [];
    const seriesData = [];
    const decals = [{ symbol: 'none' }];
    list.map((item) => {
      yAxisData.push(`${item.employeeName}(${item.sort}天)`);
    });

    initList.map((init) => {
      // 每列默认数据，倒序
      let data = [];
      // 假期类型相同，替换对应值
      if (chartsType === 'noHoliday') {
        list.map((item, index) => {
            data.splice(index, 1, item[init.id]);
        });
        if (init.id === 'annualLeaveBalanceDays') {
          data = data.map(j => j + leftW)
        } else {
          data = data.map(j => j + rightW)
        }
      } else {
        list.map((listItem, index) => {
          listItem.leaveDts.map((detail) => {
            if (detail.holidayType === init.id) {
              data.splice(index, 1, detail.days);
            }
          });
        });
      }

      // 全为空不渲染
      if (data.every((days) => !days)) return;

      // 数据倒序使用push
      seriesData.push({
        id: init.id,
        name: init.name,
        type: 'bar',
        stack: 'total',
        label: init.label,
        barMinHeight: init.barMinHeight,
        barWidth: 35,
        itemStyle: {
          color: init.color,
          borderRadius: init.borderRadius,
        },
        emphasis: {
          focus: 'none',
        },
        data,
      });
    });
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        className: 'define-echarts-tooltip',
        // formatter: (params) => defineTooltip(params, type),
        formatter: (params) => '',
      },
      grid: {
        top: 0,
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value', // value 改成log 目的是为了支持最小值
        show: false,
        min: 0,
        max: max + leftW + rightW,
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      yAxis: {
        type: 'category',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(0, 0, 0, 0.84)',
            fontSize: 14
          }
        },
        data: yAxisData,
      },
      series: [...seriesData],
      aria: {
        enabled: true,
        decal: {
          show: true,
          decals,
        },
      },
    };

    topChart.setOption(option);
    window.onresize = function () {
      topChart.resize();
    };
  };

  useEffect(() => {
    if (!chartsData?.length){
      setData(true);
      return;
    } else {
      setData(false);
      setTimeout(() => {  //节点消失是echarts会抓不到dom节点，设置延时器放进队列中
        initTopChart();
      }, 1)
    }
  }, [chartsData]);

  return (
    <div className="top-charts">
      <div className="title">
        {`${chartsType === 'noHoliday' ? '剩余年假' : '已休总'}天数 Top5`}
      </div>

      {
        !noData
          ? <div ref={chartsType === 'noHoliday' ? noTopCharts : hasTopCharts} style={{ width: '520px', height: '275px' }} />
          : <NoData />
      }
    </div>
  );
};


const daysOffColorList = {
  '年假': ['#FFA254', '#FF7D41'],
  '事假': ['#6DD6B3', '#36BF91'],
  '病假': ['#82A6F5', '#5889F2'],
  '其他': ['#82DCF5', '#58CBF2']
}
const leaveUndoColorList = {
  '年假': 'rgba(255, 138, 84, 0.2)',
  '事假': 'rgba(74, 197, 156, 0.2)',
  '病假': 'rgba(102, 147, 243, 0.15)',
  '其他': 'rgba(88, 203, 242, 0.2)'
}

const AllCharts = (props) => {
  const {
    chartsData = {},
    timeDimension,
    currentMonth
  } = props;

  const sum = (arr) => {
    var s = 0;
    arr.forEach((val, idx, arr) => {
      if (idx < 3) return;
        s += val;
    }, 0);
    return s;
  }

  const findMaxValue = (arr) => {
    let max = 0; // 最大值
    let index = 0
    for (let i=0; i<arr.length; ++i) {
        if (arr[i] > max) { // 当前值大于最大值，赋值为最大值
          max = arr[i];
          index = i
        }
    }
    return {
      max,
      index
    };
  }

  const leaveTooltip = (params, w) => {
    let total = 0;
    const details = [];
    let body = '';
    params.map((item) => {
      total += item.data;
      details.push({
        data: item.data,
        name: item.name,
        seriesName: item.seriesName,
      });
    });

    if (params[0].name === '其他') {
      body = `<div class="daysOff-leaveUndo-tooltip">
      <div style="display: flex; justify-content: space-between">
      <div class="days-off-content">
      <div class="header-title">
      ${timeDimension === TIME_RANGE_TYPE?.week ? '当周' : ''}${timeDimension === TIME_RANGE_TYPE?.month ? `${currentMonth}月` : ''}${timeDimension === 2 ? '年初至今' : ''}${params[0].name}假种${params[0].seriesName} <span style="color: #FF9450; margin: 0 5px"> ${params[0].data - w} </span> 天：
        </div>
        ${ !!chartsData?.daysOff[5] || !!chartsData?.leaveUndo[5] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-marriage.png"
          width="14"
          height="14"
        />
        婚假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[5]}</span>天
        </div>` : '' }
        ${ !!chartsData?.daysOff[8] || !!chartsData?.leaveUndo[8] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-prenatal.png"
          width="14"
          height="14"
        />
        产检假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[8]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[6] || !!chartsData?.leaveUndo[6] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-maternity.png"
          width="14"
          height="14"
        />
        产假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[6]}</span>天
        </div>` : '' }
        ${ !!chartsData?.daysOff[7] || !!chartsData?.leaveUndo[7] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-paternity.png"
          width="14"
          height="14"
        />
        陪产假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[7]}</span>天
        </div>` : '' }
        ${ !!chartsData?.daysOff[3] || !!chartsData?.leaveUndo[3] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-parenting.png"
          width="14"
          height="14"
        />
        育儿假<span style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[3]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[9] || !!chartsData?.leaveUndo[9] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-parentBack.png"
          width="14"
          height="14"
        />
        备用育儿假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[9]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[4] || !!chartsData?.leaveUndo[4] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-family.png"
          width="14"
          height="14"
        />
        探亲假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[4]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[10] || !!chartsData?.leaveUndo[10] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-funeral.png"
          width="14"
          height="14"
        />
        丧假<span style="font-weight: 500; margin: 0 5px">${chartsData?.daysOff[10]}</span>天
        </div>` : ''}
        </div>
        <div class="leave-undo-content">
        <div class="header-title">
        ${timeDimension ===  TIME_RANGE_TYPE?.week? '当周' : ''}${timeDimension === TIME_RANGE_TYPE?.month ? `${currentMonth}月` : ''}${timeDimension === 2 ? '年初至今' : ''}${params[1].name}假种${params[1].seriesName} <span style="color: #FF9450; margin: 0 5px"> ${params[1].data - w} </span> 天：
        </div>
        ${ !!chartsData?.daysOff[5] || !!chartsData?.leaveUndo[5] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-marriage.png"
          width="14"
          height="14"
        />
        婚假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[5]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[8] || !!chartsData?.leaveUndo[8] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-prenatal.png"
          width="14"
          height="14"
        />
        产检假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[8]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[6] || !!chartsData?.leaveUndo[6] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-maternity.png"
          width="14"
          height="14"
        />
        产假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[6]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[7] || !!chartsData?.leaveUndo[7] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-paternity.png"
          width="14"
          height="14"
        />
        陪产假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[7]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[3] || !!chartsData?.leaveUndo[3] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-parenting.png"
          width="14"
          height="14"
        />
        育儿假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[3]}</span>天
        </div>` : '' }
        ${ !!chartsData?.daysOff[9] || !!chartsData?.leaveUndo[9] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-parentBack.png"
          width="14"
          height="14"
        />
        备用育儿假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[9]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[4] || !!chartsData?.leaveUndo[4] ?  `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-family.png"
          width="14"
          height="14"
        />
        探亲假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[4]}</span>天
        </div>` : ''}
        ${ !!chartsData?.daysOff[10] || !!chartsData?.leaveUndo[10] ? `<div class="body-detail">
        <img
          src="${careImageUrl}icon-cards-funeral.png"
          width="14"
          height="14"
        />
        丧假<span  style="font-weight: 500; margin: 0 5px">${chartsData?.leaveUndo[10]}</span>天
        </div>` : ''}
        </div>
        </div>
        </div>`
    }
    // else {
    //   body = details.map((item) => (
    //     `<div class="header-title">
    //     本月${item.name}假期${item.seriesName} <span style="color: #FF9450; margin: 0 5px"> ${item.data} </span> 天：
    //     </div>
    //   <div class="body-detail">
    //   ${item.name}<span style="font-weight: 500; margin: 0 5px">${item.data}</span>天
    //   </div>`
    //   )).join('')
    // }
    return body;

  }

  const [noData, setData] = useState(false);
  const hasTopCharts = useRef(null);

  const initTopChart = () => {
    if(Object.keys(chartsData).length === 0) return;
    const topChart = echarts.init(hasTopCharts.current);
    topChart.clear();
    const daysOff = [chartsData?.daysOff[0], chartsData?.daysOff[1], chartsData?.daysOff[2], sum(chartsData?.daysOff)];
    const leaveUndo = [chartsData?.leaveUndo[0], chartsData?.leaveUndo[1], chartsData?.leaveUndo[2], sum(chartsData?.leaveUndo)];
    const newArr = [];
    for (let i = 0; i < daysOff.length; i++){
      newArr.push(daysOff[i] + leaveUndo[i]);
    }
    let { max, index } = findMaxValue(newArr);
    // max为0的时候需要重新设置值，不然会重叠
    max = max || 50;
    let w = Math.ceil((270 * max / 565) / 2);   //根据数据多少计算柱状图的占一份
    const newDaysOff = [chartsData?.daysOff[0] + w, chartsData?.daysOff[1] + w, chartsData?.daysOff[2] + w, sum(chartsData?.daysOff) + w];
    const newLeaveUndo = [chartsData?.leaveUndo[0] + w, chartsData?.leaveUndo[1] + w, chartsData?.leaveUndo[2] + w, sum(chartsData?.leaveUndo) + w];
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: params => leaveTooltip(params, w)
      },
      grid: {
        top: 0,
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: max+(2*w),
        show: false,
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      yAxis: {
        type: 'category',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(0, 0, 0, 0.84)',
            fontSize: 16,
            fontWeight: 500,
          }
        },
        data: ['其他', '事假', '病假', '年假']
      },
      series: [
        {
          name: '已休',
          id: 'daysOff',
          type: 'bar',
          stack: 'total',
          barWidth: 40,
          barMinHeight: 135,
          clip: false,
          itemStyle: {
            normal: {
              barBorderRadius: [8, 0, 0, 8],
              color: (params) => {
                  return {
                      x: 0,
                      y: 0,
                      x1: 0,
                      y2: 1,
                      colorStops: [
                          {
                              offset: 0,
                              color: daysOffColorList[params?.name][0]
                          },
                          {
                              offset: 1,
                              color: daysOffColorList[params?.name][1]
                          }
                      ]
                  }
              }
            },
          },
          label: {
            show: true, //开启显示
            position: 'insideRight', //在哪显示
            textStyle: { //数值样式
              color: 'rgba(255, 255, 255, 0.8)',//字体颜色
              fontSize: 14,//字体大小
              rich: {
                value: {
                  color: '#FFFFFF',
                  fontSize: 14,
                }
              }
            },
            formatter: params => `已休 {value|${params?.value - w}} 天 `
          },
          emphasis: {
            focus: 'none'
          },
          data: newDaysOff.reverse()
        },
        {
          name: '已批待休',
          id: 'leaveUndo',
          type: 'bar',
          stack: 'total',
          barWidth: 40,
          barMinHeight: 135,
          clip: false,
          itemStyle: {
            normal: {
              barBorderRadius: [0, 8, 8, 0],
              color: (params) => leaveUndoColorList[params.name]
            },
          },
          label: {
            show: true, //开启显示
            position: 'insideRight', //在哪显示
            textStyle: { //数值样式
              color: 'rgba(0, 0, 0, 0.6)',//字体颜色
              fontSize: 14,//字体大小
              rich: {
                value: {
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.84)',
                }
              }
            },
            formatter: params => `已批待休 {value|${params?.value - w}} 天 `
          },
          emphasis: {
            focus: 'none'
          },
          data: newLeaveUndo.reverse()
        }
      ]
    };
    topChart.setOption(option);
    window.onresize = function () {
      topChart.resize();
    };
  };

  useEffect(() => {
    if (!chartsData){
      setData(true);
      return;
    } else {
      setData(false);
      setTimeout(() => {  //节点消失是echarts会抓不到dom节点，设置延时器放进队列中
        initTopChart();
      }, 1)
    }
  }, [chartsData]);
  return (
    <div className="top-charts">
      <div className="title">
        <div>
          {timeDimension === TIME_RANGE_TYPE?.week  && '当周休假汇总'}
          {timeDimension === TIME_RANGE_TYPE?.date  && '当日休假汇总'}
          {timeDimension === TIME_RANGE_TYPE?.month && `${currentMonth}月休假汇总`}
          {timeDimension === TIME_RANGE_TYPE?.year && '年初至今休假汇总'}
        </div>
        <div className="leave-day-people">
          {chartsData?.totalPeopleOff} 人请假，共<span className="total-days"> {chartsData?.totalDaysOff} </span>天
        </div>
      </div>

      {
        !noData
          ?
          <div className="echarts-wrapper">
            <div className="icon-annual-wrapper">
              <img
                src={`${careImageUrl}icon-cards-annual.png`}
                width="40"
                height="40"
              />
            </div>
            <div className="icon-sick-wrapper">
              <img
                src={`${careImageUrl}icon-cards-sick.png`}
                width="40"
                height="40"
              />
            </div>
            <div className="icon-compassionate-wrapper">
              <img
                src={`${careImageUrl}icon-cards-compassionate.png`}
                width="40"
                height="40"
              />
            </div>
            <div className="icon-other-wrapper">
              <img
                src={`${careImageUrl}icon-cards-others.png`}
                width="40"
                height="40"
              />
            </div>
            <div className="has-top-echarts">
              <div ref={hasTopCharts} style={{ width: '920px', height: '268px' }} />
            </div>
          </div>
          : <NoData />
      }
    </div>
  );
};

export { TopCharts, AllCharts };
