import React, { useState, useContext, useRef, useMemo, useEffect } from 'react';
import CardIcon from '../../cnps/SvgIcon';
import { LangTranslate } from '../../utils';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import CustomTimeLineISubitemBodyCpn from '../CustomTimeLineISubitemBodyCpn';
import CustomTimeLineItemBodyCpn from '../CustomTimeLineItemBodyCpn';
import {
  CurrentYearActivityInfoContext,
  AllProfileInfoContext,
} from '../CustomTimeLineCpn/context';

import './index.less';

function CustomTimeLineCpn(props) {
  const { data, isLastOne, curTabIdx, isFirstOne, handelPinOriginLoc } = props;
  const [expand, setExpand] = useState(false);
  const [expandChild, setExpandChild] = useState(false);
  const [chosePosition, setChosePosition] = useState(0);
  const expendAllWrapRef = useRef();
  const curActivityInfo = useContext(CurrentYearActivityInfoContext);
  const allProfileInfo = useContext(AllProfileInfoContext);

  const handelAnimation = expendFlag => {
    if (expendFlag) {
      setExpand(() => true);
      setTimeout(() => setExpandChild(true), 0);
    } else {
      setExpandChild(() => false);
      setTimeout(() => setExpand(false), 200);
    }
  };

  useEffect(() => {
    setChosePosition(() => 0);
    setExpandChild(() => false);
    setExpand(() => false);
  }, [curTabIdx]);

  const activityInfoTitle = useMemo(() => {
    const curActivityInfoObj = allProfileInfo?.activityMap?.[data?.activityId];
    return `${curActivityInfoObj?.activityName || '-'} (${dayjs(curActivityInfoObj?.activityStartTime).format('MM-DD')} ~ ${dayjs(curActivityInfoObj?.activityEndTime).format('MM-DD')})`;
  }, [curActivityInfo, data, allProfileInfo]);

  return (
    <div className="talent-market-custom-time-line-item-cpn-wrap">
      <div className="talent-market-custom-time-line-item-head-place">
        <div className="talent-market-custom-time-line-item-head-title-place" />
        <div className="talent-market-custom-time-line-item-head-line" />
      </div>
      {data?.positionInfos?.length > 1 ? (
        <div className="talent-market-custom-time-line-item-body-wrap">
          {
            <div>
              <div
                className="talent-market-custom-time-line-item-body-left-sub-content"
                style={{
                  background: expand ? '' : '#f5f5f5',
                  width: expand ? '100px' : '222px',
                }}
              >
                <div className="talent-market-custom-time-line-item-body-left-sub-content-title">
                  {!expand ? <div>{LangTranslate('岗位选择')}</div> : <div></div>}
                  <div className="talent-market-custom-time-line-item-body-left-sub-content-btn">
                    {!expand && (
                      <div className="talent-market-custom-time-line-item-body-left-sub-content-btn-icon-wrap">
                        <CardIcon type="icon-zhankaisuoyou" pointer size={16} />
                      </div>
                    )}
                    <div
                      onClick={e => {
                        if (!expand) {
                          handelAnimation(true);
                          setChosePosition(() => 0);
                          setTimeout(()=>document.getElementsByClassName('talent-market-custom-time-line-items-wrap')[0].scrollLeft += 392 * (data?.positionInfos?.length - 1) + 6 - 122, 0)
                        } else {
                          handelAnimation(false);
                        }
                      }}
                    >
                      {LangTranslate(expand ? '收起所有' : '展开所有')}
                    </div>
                  </div>
                </div>
                {!expand && (
                  <div className="talent-market-custom-time-line-item-body-left-sub-content-position-list">
                    {data?.positionInfos?.map?.((itx, idx) => (
                      <CustomTimeLineISubitemBodyCpn
                        data={itx}
                        onClick={() => setChosePosition(() => idx)}
                        isChosePosition={idx === chosePosition}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          }

          {
            <div
              classNames="talent-market-custom-time-line-item-body-left-sub-content-expend-all-wrap-fade"
              style={{
                display: expand ? 'block' : 'none',
                width: expandChild ? 392 * (data?.positionInfos?.length - 1) + 6 + 'px' : '0%',
                // transition: 'all .2s ease-in-out',
              }}
            >
              <div
                ref={expendAllWrapRef}
                style={{
                  width: expandChild ? '100%' : '0%',
                  transition: 'all .2s ease-in-out',
                  overflowX: 'hidden',
                }}
                id="talent-market-custom-time-line-item-body-left-sub-content-expend-all-wrap"
                className="talent-market-custom-time-line-item-body-left-sub-content-expend-all-wrap"
              >
                {data?.positionInfos
                  ?.slice(1)
                  ?.reverse()
                  ?.map?.(itx => (
                    <CustomTimeLineItemBodyCpn data={itx} activityId={data?.activityId} />
                  ))}
              </div>
            </div>
          }

          <div className="talent-market-custom-time-line-item-first-one-wrap">
            <div className="talent-market-custom-time-line-item-first-one-title">
              <Tooltip destroyTooltipOnHide placement="topLeft" title={activityInfoTitle}>
                {activityInfoTitle}
              </Tooltip>
            </div>
            <div className="talent-market-custom-time-line-item-first-one-cursor">
              <div className="talent-market-custom-time-line-item-first-one-cursor-dot" />
              <div className="talent-market-custom-time-line-item-first-one-cursor-ver-line" />
            </div>
            <CustomTimeLineItemBodyCpn
              data={data?.positionInfos?.[chosePosition]}
              activityId={data?.activityId}
            />
          </div>
          {!isLastOne && <div className="talent-market-custom-time-line-item-empty-gap" />}
        </div>
      ) : (
        <div className="talent-market-custom-time-line-item-body-wrap">
          <div className="talent-market-custom-time-line-item-first-one-wrap">
            <div className="talent-market-custom-time-line-item-first-one-title">
              <Tooltip destroyTooltipOnHide placement="topLeft" title={activityInfoTitle}>
                {activityInfoTitle}
              </Tooltip>
            </div>

            <div className="talent-market-custom-time-line-item-first-one-cursor">
              <div className="talent-market-custom-time-line-item-first-one-cursor-dot" />
              <div className="talent-market-custom-time-line-item-first-one-cursor-ver-line" />
            </div>
            <CustomTimeLineItemBodyCpn
              data={data?.positionInfos?.[chosePosition]}
              activityId={data?.activityId}
            />
          </div>
          {!isLastOne && <div className="talent-market-custom-time-line-item-empty-gap" />}
        </div>
      )}
    </div>
  );
}

export default CustomTimeLineCpn;
