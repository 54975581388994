import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-placeholder-card', () => [
		{
			// base
      [`.${prefixCls}`]: {
        width: '100%',
        height: '100%',
        padding: '16px',
        background: '#ffffff',
        borderRadius: '12px',
        ['img']: {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }
      },

      [`.${prefixCls}-resource`]: {
        padding: '0px',
        ['img']: {
          width: '100%',
          height: '100%',
          objectFit: 'fill',
        }
      },
		}
	]);
};

export default useStyle;
