import React, {useEffect} from 'react';
import {ConfigProvider} from 'antd';
import Nothing from './nothing.svg'
import Empty from './empty.svg'

export default (props) => {
    
    const customizeRenderEmpty = () => (
        <div style={{padding: '80px 0 120px'}}>
            <div style={{textAlign: 'center'}}><img width="150px" style={{display: 'inline-block'}} height="150px" src={props.isCb ? Empty : Nothing}/></div>
            <span style={{color: '#9B9B9B'}}>{props.isCb ? '暂无单据' :'暂无数据'}</span>
        </div>

    )
    return (
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div className="tableBox">
            {props.children}
            </div>
        </ConfigProvider>
    )
}