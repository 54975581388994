(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@ant-design/icons"), require("@didi/ec-base"), require("@didi/ec-base-icon"), require("@didi/ec-ehr-base"), require("@didi/pagelets-sdk"), require("react"), require("react-dom"), require("single-spa-react"));
	else if(typeof define === 'function' && define.amd)
		define(["@ant-design/icons", "@didi/ec-base", "@didi/ec-base-icon", "@didi/ec-ehr-base", "@didi/pagelets-sdk", "react", "react-dom", "single-spa-react"], factory);
	else if(typeof exports === 'object')
		exports["personal-hr"] = factory(require("@ant-design/icons"), require("@didi/ec-base"), require("@didi/ec-base-icon"), require("@didi/ec-ehr-base"), require("@didi/pagelets-sdk"), require("react"), require("react-dom"), require("single-spa-react"));
	else
		root["personal-hr"] = factory(root["@ant-design/icons"], root["@didi/ec-base"], root["@didi/ec-base-icon"], root["@didi/ec-ehr-base"], root["@didi/pagelets-sdk"], root["react"], root["react-dom"], root["single-spa-react"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__375__, __WEBPACK_EXTERNAL_MODULE__593__, __WEBPACK_EXTERNAL_MODULE__637__, __WEBPACK_EXTERNAL_MODULE__53__, __WEBPACK_EXTERNAL_MODULE__709__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__514__, __WEBPACK_EXTERNAL_MODULE__25__) => {
return 