export const recordPageTiming = (time, requestTime, requestUrl) => {
  const records = {
    url_path: location.pathname.split('/').slice(1, 3).join('/'),
    page_time: time,
    request_time: requestTime,
    url_search: location.search,
    request_url: requestUrl,
    system_name: 'ct',
    is_https: location.protocol === 'https:',
    is_frist_load: !window.isPageLoaded,
  }
  const { isIntra } = $CONFIG?.status || {};
  if (typeof isIntra !== 'undefined') {
    records.is_intra = isIntra;
  }
  const perfLimitTime = $CONFIG?.customProps?.perfLimitTime || 30000;
  if (time < perfLimitTime) {
    Omega?.trackEvent && Omega.trackEvent('ep_ct_perf_bt', '', records);
  }
  if (localStorage.perf) {
    const perfDebugId = 'perfDebug';
    let perfDebugElem = document.getElementById(perfDebugId);
    if (!perfDebugElem) {
      perfDebugElem = document.createElement('span');
      perfDebugElem.style.cssText = 'position:sticky;bottom:0;pointer-events:none;font-size:12px;padding:8px;opacity:0.5';
      perfDebugElem.id = perfDebugId;
      document.body.appendChild(perfDebugElem);
    }
    perfDebugElem.innerHTML = `
      <span style="color:${time > 1500 ? 'red' : 'green'}">${time}</span>&nbsp;&nbsp;<span style="color:${requestTime > 800 ? 'red' : 'green'}">${requestTime}</span>
    `
  }
};

// 匹配（url）的接口
const exactCancel = [
  '/ctAnswer/api/answer/saveDraft', // 按题目维度保存草稿
];

/**
 * 生成请求key
 * @param {*} config 请求信息
 * @returns 
 */
export const generateRequestKey = (url, config) => {
  const arr = [url, config.method];
  // 如果有cancelTokenQesId，则加上cancelTokenQesId
  if (config.method === 'post') {
    const data = JSON.parse(config?.data);
    if (data?.cancelTokenQesId) {
      arr.push(data?.cancelTokenQesId);
    };
  };
  return arr.join('&');
};

/**
 * 获取请求取消的种类
 * @param {*} config 请求信息
 * @returns key
 */
export const getPendingRequestCancelTypeFn = config => {
  const url = config.url?.split('?')?.[0]; // 获取接口url
  let key = ''; // 存储的key值
  if (exactCancel.includes(url)) {
    key = generateRequestKey(url, config);
  }
  return key;
};


/**
 * 获取请求失败重试的种类
 * @param {*} config 请求信息
 * @returns 类型
 */
export const getPendingRequestCatchRetryFn = config => {
  const url = config.url?.split('?')?.[0]; // 获取接口url
  return exactCancel.includes(url);
};


/**
 * 弱网下获取问卷链接 跳转弱网
 * @param {*}  weekNetworkPage 请求信息
 * @returns 类型
 */
export const getWeakNetwork = weekNetworkPage => {
  const url = encodeURIComponent(`jumpto=${window.location.href}`);
  return `${weekNetworkPage}?${url}`;
};
