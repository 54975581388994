import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';


const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 年度多维
 * url: /api/data/archive/feedback
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */

export const getFeedback = (apiHost, headers, params,usePermissionResponse) => request(`${apiHost}${apis.getFeedback}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);

/**
 * 日志
 * url: /api/archive/operateLog
 * method: POST
 * @param  {object}  params
 * @return {Promise}
 */

export const getOperateLog = (apiHost, headers, data, usePermissionResponse) => request(`${apiHost}${apis.getOperateLog}`,
  {
    method: 'post',
    headers,
    data,
    usePermissionResponse
  }
);

/**
 * 年度多维年份列表
 * url: /api/data/archive/feedback-year-list
 * method: GET
 */
export const getFeedbackYearList = (apiHost, headers, params,usePermissionResponse) => request(`${apiHost}${apis.getFeedbackYearList}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);
