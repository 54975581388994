import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-talent-inventory', () => [
		{
			// base
      [`.${prefixCls}`]: {

        ['.ehr-timeline-card-template']: {
          padding: '4px 4px 0px 4px',
        },

				['.talent-inventory-container']: {
					padding: '20px 16px',
					minWidth: '200px',
          minHeight: '70px',
          borderRadius: '10px',
          background: 'linear-gradient(10deg, #FFFFFF 41%, #F0F6FF 97%), linear-gradient(194deg, #F6F9FF 21%, #FFFFFF 72%)',
          border: '2px solid #FFFFFF',
          boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.02),0px 2px 6px 6px rgba(0, 0, 0, 0.02),0px 2px 6px 0px rgba(0, 0, 0, 0.12)',

					['.inventory-name']: {
						fontFamily: 'PingFang SC',
						fontSize: '14px',
						fontWeight: 500,
            color: '#000000DE',
            display: 'flex',
            alignItems: 'center',

            ['.talent-icon']: {
              width: '24px',
              height: '24px',
              marginRight: '4px',
              border: '1px solid #A6DAFF',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
					},
				}
			}
		}
	]);
};

export default useStyle;
