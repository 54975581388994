import Cookies from 'js-cookie';

export const getCookieValue = () => Cookies.default.get('ct_role') || '';

export const tabsLangMaps = {
  0: '中文',
  1: '英文',
  2: '日语',
  3: '西班牙语',
  4: '巴西葡语',
  5: '俄语',
};

export const langMaps = {
  0: '中',
  1: '英',
  2: '日',
  3: '西',
  4: '巴',
  5: '俄',
};

export const messageMaps = {
  'TITLE': '题目',
  'OPTION': '选项',
}

export const validateNumber = (rule, value, callback, trimText, text) => {
  if (value) {
    const reg = /^[1-9]\d*$/g;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error(trimText));
    }
  } else {
    callback(new Error(text));
  }
};

export const validateCapital = (rule, value, callback, trimText, text) => {
  if (value) {
    const val = value?.trim();
    const reg = /^[A-Z]+$/g;
    if (reg.test(val)) {
      callback();
    } else {
      callback(new Error(trimText));
    }
  } else {
    callback(new Error(text));
  }
};

export const validateLangs = (rule, value, callback, messageType, maxLength, key = 'name') => {
  let text = '';
  if (value?.length === 0) {
    text = `${messageMaps[messageType]}内容中文必填`
  }
  value.some(item => {
    // 中文必填校验
    if (item?.languageType === 0 && !item[key]) {
      text = `${messageMaps[messageType]}内容中文必填`
      return true;
    } else if (item[key] && item[key].trim().length === 0) { // 去空格非空
      text= `${messageMaps[messageType]}内容不能为空！`
      return true;
    } else if (item[key].trim().length > maxLength) { // 不超过最大限制
      text= `${messageMaps[messageType]}长度不能超过${maxLength}字`
      return true;
    }
  })
  if (text) {
    callback(new Error(trimText));
  } else {
    callback();
  }
};
