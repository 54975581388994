import CryptoJS from 'crypto-js';
import { isProdEnv } from './env';

// https://cooper.didichuxing.com/docs/sheet/2199927606356
const offlineProjectsToKeys = new Map([
  ['/gateway/dhr-workbench', {
    key: 'qGjT$KEpYAB77xfM',
    iv: 'xyycAALQ%g<l/UtH'
  }],
  ['/gateway/probation', {
    key: 'm})E%+[2F/bf$"v9',
    iv: "c;?}sQ3uM*E{,Tmz"
  }],
  ['/gateway/leave', {
    key: 'ZyGzSVSFt2zwxGMM',
    iv: 'QkwoE9g3FGH7DXgb'
  }],
  ['/gateway/employee', {
    key: "DI`N&oa)W|U*ti'}",
    iv: 'CZ!wb=`|g-q]&HKb'
  }],
  ['/gateway/transfer', {
    key: 'XvBa5Nnfm43tFE8Q',
    iv: 'yaVXVj242TFPFdUE'
  }],
  ['/gateway/holiday', {
    key: 'ZikT8svSfrm3kjzj',
    iv: '42AMC1BNhgzj2VPp'
  }],
  ['/gateway/record', {
    key: 'fhsmsvJOwXhMffud',
    iv: 'LrIglHLGfdniYsvf'
  }]
])

const onlineProjectsToKeys = new Map([
  ['/gateway/dhr-workbench', {
    key: 'C@Qd|t74HE]`EbU^',
    iv: "g6['V86;8y^_%Xp<"
  }],
  ['/gateway/probation', {
    key: 'AP-):aLH8DB7Z]G&',
    iv: ';b|pYS2]VqMK}h-('
  }],
  ['/gateway/leave', {
    key: 'GBvAqcBtBaL0RVK7',
    iv: 'QLuiWpBnduj2c8od'
  }],
  ['/gateway/employee', {
    key: '~gt4P5/wPVVUqeT<',
    iv: 'dZ9r^(3N4B8,?J+p'
  }],
  ['/gateway/transfer', {
    key: '4brVSjcL4GtpAUkG',
    iv: 'J3fa8LwuSWmf2Zv8'
  }],
  ['/gateway/holiday', {
    key: 'aHKeXgW0c7wPdBxH',
    iv: 'lTU6wpMp9iPjuE6z'
  }],
  ['/gateway/record', {
    key: 'FyopLKMKNPmHeRkx',
    iv: 'fmFaWfrwZjiiCXHZ'
  }]
])

const getApiPrefix = (config) => {
  const {baseURL, url} = config;
  let api = ''
  if (!baseURL) {
    api = url
  } else if (url.startsWith('/') && baseURL.endsWith('/')) {
    api = baseURL?.substring(0, baseURL.length - 1)+ url;
  } else {
    api = baseURL + url;
  }
  api = api.replace(/^\//, '');
  // 返回 /gateway/项目名称  的格式
  return '/' + api.split('/').slice(0, 2).join('/');
}

// 根据请求头，获取key 和 iv，每个项目拥有不同的key和iv
// TODO 记得补全一下  有可能有前缀没有加进URL里面
const getProjectKeyAndIv = (config) => {
  const prefix = getApiPrefix(config);
  return (isProdEnv() ? onlineProjectsToKeys.get(prefix) : offlineProjectsToKeys.get(prefix)) || {}
}

// 加密方法
const encrypt = (str, key, iv) => CryptoJS.AES.encrypt(
  CryptoJS.enc.Utf8.parse(str),
  key,
  {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }
).ciphertext.toString();

//解密方法
const decrypt = (str, key, iv) => {
  let encryptedHexStr = CryptoJS.enc.Hex.parse(str);
  return CryptoJS.AES.decrypt(
    CryptoJS.enc.Base64.stringify(encryptedHexStr),
    CryptoJS.enc.Utf8.parse(key), // 秘钥,
    {
      iv: CryptoJS.enc.Utf8.parse(iv), // 偏移量,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8).toString();
};

const encryptParams = async(config) => {
  const encryption = config.encryption
  const method = config.method
  if (!encryption || !window.$CONFIG?.settings?.encryption) return;
  const {key: originKey, iv: originIV} = getProjectKeyAndIv(config)
  if (!originKey || !originIV) return;
  const key = await CryptoJS.enc.Utf8.parse(originKey)
  const iv = await CryptoJS.enc.Utf8.parse(originIV)
  if (method === 'post') {
    if (config.data) {
      config.data = {
        param: encrypt(JSON.stringify(config.data), key, iv),
      };
    }
  } else if (method === 'get') {
    if (config.params) {
      let str = '';
      let arr = Object.keys(config.params);
      arr.forEach((item, index) => {
        str += `${item}=${config.params[item]}${index !== arr.length - 1 ? '&' : ''}`;
        return item;
      });
      config.params = {
        param: encrypt(str, key, iv),
      };
    }
  }
}

const decryptResponse = (res) => {
  const {key: originKey, iv: originIV} = getProjectKeyAndIv(res.config)
  if (!originKey || !originIV) return;
  // 成功，需返回data
  if (res.config.certification) {
    res.data.data = JSON.parse(decrypt(res.data.data, key, iv));
  }
}

export {
  decrypt,
  decryptResponse, encrypt, encryptParams, getProjectKeyAndIv, offlineProjectsToKeys,
  onlineProjectsToKeys
};

