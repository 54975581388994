import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

  return useRegisterStyle('pagelet-personal-ei', () => [
    {
      // base
      [`.${prefixCls}`]: {
        'width': '100%',
        'height': '100%',
        minHeight: '164px',
        'borderRadius': '12px',
        'background': '#FFFFFF',
        'padding': '16px',
        [`.${prefixCls}-header-url-icon`]: {
          marginLeft: '2px'
        },
        [`.${prefixCls}-content`]: {
          'marginTop': '16px',
          // 'display': 'flex',
          // 'flexWrap': 'wrap',
          display: 'grid',
          gridTemplateColumns: ' repeat(3, 1fr)',
          gridGap: '12px 16px',
          ['.content-warp']: {
            // width: '30%',
            // margin: '12px 16px 0 0',
            background: 'rgba(245, 245, 245, 0.6)',
            borderRadius: '8px',
            'display': 'flex',
            'flex-direction': 'row',
            'alignItems': 'center',
            padding: '8px 0',
            border: '1px solid transparent',
            position: 'relative',
            ['img']: {
              marginLeft: '8px',
              width: '18px',
              height: '18px'
            },
            ['span']: {
              marginLeft: '6px',
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.84)',
            },
            ['.content-msg']: {
              position: 'absolute',
              top: '0px',
              right: '0px',
              fontFamily: 'PingFang SC',
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '14px',
              letterSpacing: '1px',
              color: '#DE7575',
              background: 'rgba(228, 82, 82, 0.08)',
              borderRadius: '0px 8px 0px 12px',
              // width: '37px',
              // height: '26px',
              padding: '4px',
              textAlign: 'center',
            },
            ['.content-edit']: {
              display: 'none',
            }
          },
          ['.content-warp:hover']: {
            border: '1px solid #FF6400',
            background: 'rgba(255, 100, 0, 0.02)',
            position: 'relative',
            cursor: 'pointer',
            ['.content-msg']: {
              display: 'none',
            },
            ['.content-edit']: {
              display: 'block',
              position: 'absolute',
              right: '0',
              top: '0',
              height: '36px',
              width: '40px',
              borderRadius: '7px',
              background: 'linear-gradient(270deg, #F9F9F9 72%, rgba(249, 249, 249, 0) 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'

            }
          }
        },
        [`.${prefixCls}-content-err`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '118px',
          justifyContent: 'center',
          ['img']: {
            width: '120px',
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
        }
      }
    }
  ]);
};

export default useStyle;
