import React, { useState, useEffect } from "react";
import { di18n } from "@ultra/common";
import netWork from "../../images/yellow-loadpic.png";
import "./index.scss";

const { intl } = di18n;

export default function WeekNetwork(props) {
  return (
    <div className="week-network-page">
      <img src={netWork} />
      <span>网络连接异常，请稍后再试</span>
      <span>Network anomaly. Please try again later.</span>
    </div>
  );
}
