import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider, Row, Col, getLang, Spin, Empty, zh_CN, en_US, Tooltip } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { getOnboardingInfo } from './services';
import Onboarding from './assets/onboarding.svg';

const localLocales = manifest.locales;

const isNull = (str) => {
  if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
    return '-';
  };
  return <Tooltip>{str}</Tooltip>;
}

const Pagelet = (props) => {
  const { params: { locales, apiHost, headers, employeeId, usePermissionResponse, colorPrimary = '#0A70F5' } } = props;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getOnboardingDetail = async () => {
    try {
      setLoading(true);
      const res = await getOnboardingInfo(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res.data).length > 0) {
        setData(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log('getOnboardingInfo', error);
    }
  }

  useEffect(() => {
    getOnboardingDetail();
  }, []);

  const prefixCls = 'pagelet-onboarding-info';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{ token: { colorPrimary } }}>
      <Spin spinning={loading}>
        <div className={classes}>
          <div className='onboarding-title'>
            <Onboarding />
            <div className="title-info">{locale.entryInfo}</div>
          </div>
          {
            data && Object.keys(data).length > 0 && <div className='onboarding-content'>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.currentHireDate}:</div>
                    <div className='single-data'>{isNull(data?.lastHireDate)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.tenure}:</div>
                    <div className='single-data'>{isNull(data?.companyAge)}</div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.employeeClass}:</div>
                    <div className='single-data'>{isNull(data?.empClassDesc)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.workLocation}:</div>
                    {
                      data?.workNation || data?.workLocation ? (
                        <Tooltip title={<>{data?.workNation}{data?.workLocation && '-'}{data?.workLocation}</>}>
                          <div className='single-data'>{data?.workNation}{data?.workLocation && '-'}{data?.workLocation}</div>
                        </Tooltip>
                      ) : '-'
                    }
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.recruitmentType}:</div>
                    <div className='single-data'>{isNull(data?.recruitmentType)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.recruitmentChannel}:</div>
                    <div className='single-data'>{isNull(data?.recruitmentChannel)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.rehire}:</div>
                    <div className='single-data'>{isNull(data?.rehired)}</div>
                  </div>
                </Col>
              </Row>
            </div>
          }
          {
            (!data || Object.keys(data).length === 0) && !loading && <Empty mode="part" style={{ marginTop: '-10px' }} />
          }
        </div>
      </Spin>
    </ConfigProvider >
  );
};

export default Pagelet;
