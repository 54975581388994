const axios = require('axios').default;

const HTTP = axios.create({
  baseURL: '/',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'x-kylin-proxy-with': 'apirequest',
    // Authorization: cookie.get('ticket'),
    // post: {
    //   'Content-Type': 'application/json',
    // },
    "baseInfo": "{tenantId:123,appId:390765}"
  
  },
});

HTTP.interceptors.response.use(
  function(res) {
    if (res?.data?.code === '100200') {
      return res.data;
    } else {
      // message.error(res?.data?.msg || intl.t('系统错误'));
      return {};
    }
  },
  function(error) {
    let res = error.response;
    
    if (res) {
      switch (res.status) {
        case 401:
          if (!window.redirectLogin && res.data.loginUrl) {
            window.redirectLogin = true;
            window.location.href = res.data.loginUrl;
          }
          break;
        default:
          // message.error(
          //   res?.data?.msg || res?.data?.message || intl.t('系统错误')
          // );
      }
    } else {
      // message.error(intl.t('系统错误'));
      return {};
    }
  }
);

HTTP.interceptors.request.use(
  config => config,
  error => {
    // message.error(intl.t('系统错误'));
  }
);

export default HTTP;
