import React from "react";
import { useSelector } from "react-redux";
import RenderWidget from '../renderWidget';
const questionnaireOfMulti = props => {
  const {
    renderBanner,
    renderSubmitBtn,
    renderFootRemark,
    renderProcess,
    renderErrorLocation,
    renderFoot,
    pageDataAll,
    reviewType,
    renderType,
    respondentTypeCodePreview,
    getClassNames
  } = props;
  // 渲染合并（多人）问卷模板
  // 里面的组件由于与其他问卷模板公用，所以用父组件传进来的方法渲染
  const {
    surveyBusType
  } = useSelector((state) => state.questionContent);

  return (
    <>
      <div className={getClassNames?.prefixCls}>
        {/* 渲染问卷头部 */}
        {renderProcess && surveyBusType === 'PROFESSIONAL_SERVICES' ? renderProcess : ''}
        {renderBanner}
      </div>
      <div className={getClassNames?.mainPrefixCls}>
        <div className="question-left" style={{ width: '90px' }} />
        <div className="question-main-pc">
          {pageDataAll
            ? (
              <>
                <RenderWidget
                  pageDataAll={pageDataAll}
                  itemType={1}
                  multi={2}
                  renderType={renderType ? renderType : 2}
                  reviewType={reviewType ? reviewType : 2}
                  respondentTypeCodePreview={respondentTypeCodePreview}
                />
              </>
            )
            : ''}
          {renderFoot}
          {renderSubmitBtn ? renderSubmitBtn : ''}
          {/* {renderFootRemark} */}
        </div>
        {/* 渲染问卷进度条 */}
        <div className="question-right" style={{ width: '90px' }}>
          {renderProcess && surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? renderProcess : ''}
          {renderErrorLocation}
        </div>
      </div>
    </>
  );
};

export default questionnaireOfMulti;
