import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider, Row, Col, getLang, Tooltip, Spin, Empty, zh_CN, en_US } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { getEmployeeInfo } from './services';
import Employee from './assets/employee.svg';

const localLocales = manifest.locales;

const isNull = (str) => {
  if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
    return '-';
  };
  return <Tooltip>{str}</Tooltip>;
}

const Pagelet = (props) => {
  const { params: { apiHost, locales, headers, employeeId, usePermissionResponse, colorPrimary = '#0A70F5' } } = props;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getEmployeeDetail = async () => {
    try {
      setLoading(true);
      const res = await getEmployeeInfo(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res.data).length > 0) {
        setData(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log('getEmployeeInfo', error);
    }
  };

  // 接口请求示例
  useEffect(() => {
    getEmployeeDetail();
  }, []);

  const prefixCls = 'pagelet-employee-info';
  const wrapSSR = useStyle(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{ token: { colorPrimary } }}>
      <Spin spinning={loading}>
        <div className={classes}>
          <div className='employee-title'>
            <Employee />
            <div className='title-info'>{locale.personalInfo}</div>
          </div>
          {
            data && Object.keys(data).length > 0 && <div className='employee-content'>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.personalName}:</div>
                    <div className='single-data single-data-en'>{isNull(data?.name)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.gender}:</div>
                    <div className='single-data'>{isNull(data?.sex)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.age}:</div>
                    <div className='single-data'>{isNull(data?.age)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.brithday}:</div>
                    <div className='single-data'>{isNull(data?.birthday)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.education}:</div>
                    <div className='single-data'>{isNull(data?.education)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info'>
                    <div className='single-title'>{locale.politicalStatus}:</div>
                    <div className='single-data'>{isNull(data?.political)}</div>
                  </div>
                </Col>

                <Col span={6}>
                  <div className='single-info single-info-en'>
                    <div className='single-title'>{locale.email}:</div>
                    <div className='single-data'>{isNull(data?.workEmail)}</div>
                  </div>
                </Col>
              </Row>
            </div>
          }
          {
            (!data || Object.keys(data).length === 0) && !loading && <Empty mode="part" style={{ marginTop: '-10px' }} />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
