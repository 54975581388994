import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

  return useRegisterStyle('pagelet-canghai-carousel', () => [
    {
      // base
      [`.${prefixCls}`]: {
        color: chartToken.$font_color,
        fontFamily: chartToken.$font_family,
        fontSize: variable.size,
        background: 'antiquewhite',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        background: 'linear-gradient(232deg, #D0FFFC -11%, rgba(255, 255, 255, 0) 48%), #FFFFFF',
        boxShadow: '0px -2px 4px 0px rgb(70 40 13 / 2%)',
        cursor: 'pointer',
        position: 'relative',
        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute'
        },
        ['.canghai-current-index']: {
          position: 'absolute',
          bottom: '18px',
          left: '50px',
          zIndex: '2',
          fontFamily: chartToken.$font_family,
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '17px',
          letterSpacing: '0px',
          color: 'rgba(0, 0, 0, 0.36)',
        },
        ['.carousel-root, .carousel, .slick-slider']: {
          height: '100%',
          position: 'relative',
          '.carousel-status': {
            position: 'absolute',
            top: '186px',
            left: '54px',
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: 'rgba(0, 0, 0, 0.36)',
            textShadow: 'none',
          },
          ['.control-dots']: {
            display: 'none'
          },
          '.control-arrow': {
            opacity: '100 !important'
          },
          '.control-prev.control-arrow': {
            position: 'absolute',
            top: '178px',
            left: '16px',
            height: '40px',
            width: '40px',
          },
          '.control-prev.control-arrow:hover': {
            padding: '5px !important',
          },
          '.control-prev.control-arrow:before': {
            content: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/left.svg)',
            margin: '0px',
            border: 'none',
          },
          '.control-next.control-arrow': {
            position: 'absolute',
            top: '178px',
            left: '82px',
            height: '40px',
            width: '40px',
          },
          '.control-next.control-arrow:hover': {
            padding: '5px !important',
          },
          '.control-next.control-arrow:before': {
            content: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/right.svg)',
            margin: '0px',
            border: 'none',
          },
          '.control-prev.control-arrow:hover,.control-next.control-arrow:hover': {
            background: 'none',
            padding: '0',
          },
          // ['.control-dots']: {
          // 	display: 'flex',
          // 	margin: '8px 16px',
          //   width: 'auto',
          // 	['li']: {
          // 		cursor: 'pointer',
          //     margin: '0 3px 0 0 !important',
          //     padding: '8px 0',
          //     ['div']: {
          //       width: '20px',
          //       height: '6px',
          //       borderRadius: '2px',
          //       opacity: '0.7041',
          //       background: 'rgba(38, 17, 6, 0.12)',
          //     }
          // 	}
          // },
          ['.slider-wrapper, .slider']: {
            height: '100%'
          },
          ['.slick-prev']: {
            left: '12px',
            top: '188px',
            zIndex: '2'
          },
          ['.slick-next']: {
            left: '75px',
            top: '188px',
            zIndex: '2'
          },
          ['.slick-prev:before']: {
            content: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/left.svg)',
          },
          ['.slick-next:before']: {
            content: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/right.svg)',
          },
          ['.slide, .slick-list, .slick-track']: {
            // maxWidth: '100%',
            height: '100%',
            ['.slick-slide>div']:{
              height: '100%',
            },
            // 卡片
            ['.default-card']: {
              display: 'flex',
              justifyContent: 'space-between',
              height: '100%',
              // 卡片左边部分
              ['.default-card-left']: {
                minWidth: '160px',
                maxWidth: '45%',
                padding: '16px 0 16px 16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                zIndex: '999',
                ['p']: {
                  margin: 0,
                  textAlign: 'left',
                  wordBreak: 'break-all',
                },
                ['.title']: {
                  fontSize: '20px',
                  fontWeight: '600',
                  lineHeight: '28px',
                  color: 'rgba(0, 0, 0, 0.84)'
                },
                ['.desc']: {
                  marginTop: '16px',
                  fontSize: '12px',
                  fontWeight: '400',
                  color: 'rgba(0, 0, 0, 0.72)'
                },
                ['.desc-bold']: {
                  fontWeight: '500',
                },
                ['.digitalize-organization, .digitalize-change, .digitalize-atmosphere']: {
                  fontFamily: 'DIDI FD',
                  fontWeight: '900',
                  fontSize: '34px',
                  color: 'rgba(0, 0, 0, .84)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  textAlign: 'left',
                  marginTop: '12px',
                  ['span']: {
                    fontSize: '12px',
                    fontWeight: '400',
                    fontFamily: chartToken.$font_family,
                  }
                },
                // 看氛围
                ['.digitalize-atmosphere']: {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  ['p']: {
                    fontWeight: '400',
                    color: 'rgba(0, 0, 0, 0.72)',
                    fontSize: '11px'
                  },
                  ['>div']: {
                    marginRight: '20px'
                  },
                }
              },
              // 卡片右边部分
              ['.default-card-right']: {
                // width: '100%',
                flex: '1',
                height: '100%',
                display: 'grid',
                zIndex: '999',
                alignItems: 'center',
                position: 'relative',
                ['.echarts-change, .echarts-organization, .echarts-atmosphere']: {
                  padding: '16px 16px 16px 0',
                },
                // 看变动图表
                ['.echarts-change']: {
                  width: '100%',
                  height: '100%'
                },
                // 看变动，看氛围，看组织默认图片
                ['.echarts-change-img, .echarts-atmosphere-img, .echarts-organization-img, .echarts-talent-img']: {
                  height: '100%',
                  width: '100%',
                  backgroundColor: '#fff',
                  backgroundPositionX: 'right',
                  backgroundPositionY: 'top',
                  backgroundSize: 'contain',
                  borderRadius: '10px',
                  backgroundRepeat: 'no-repeat',
                  position: 'absolute',
                  right: '0',
                  top: '0'
                },
                // 看组织图表
                ['.echarts-organization']: {
                  paddingRight: '40px',
                  ['table']: {
                    borderCollapse: 'collapse',
                    width: '100%',
                    fontSize: '10px',
                    color: 'rgba(0,0,0,.72)'
                  },
                  ['th, td']: {
                    padding: '4px 0',
                    textAlign: 'left',
                  },
                  ['.count']: {
                    width: '100%',
                    position: 'relative',
                    ['.percentage-bar']: {
                      backgroundColor: '#47C88D',
                      color: '#fff',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      textAlign: 'center',
                      borderRadius: '4px',
                      maxWidth: '90%'
                    },
                  },
                  ['.ratio']: {
                    minWidth: '40px'
                  },
                  ['.job-level-name']: {
                    maxWidth: '80px',
                    whiteSpace: 'nowrap'
                  }
                },
                // 看氛围图表
                ['.echarts-atmosphere']: {
                  width: '100%',
                  height: '100%',
                  textAlign: 'left'
                }
              },
            },
          },
        },
        ['.ant-spin-spinning']: {
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }
    }
  ]);
};

export default useStyle;
