import React, { useEffect, useState, useRef } from 'react';
import { getLocale, ConfigProvider, en_US, zh_CN, Tabs, Segmented} from '@didi/ec-base';
import * as antd from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import classNames from 'classnames';
import manifest from '../manifest.json';
import useStyle from './style/index.js';
import useDrawerStyle from './style/drawer.js';
import DepartmentSelection from './components/DepartmentSelection/index.jsx';
import ScopeFilter from './components/ScopeFilter/index.jsx';
import TimeFilter from './components/TimeFilter/timeFilter.js';
import NotUsed from './components/NotUsed/index.jsx';
import HasUsed from './components/HasUsed/index.jsx';
import PageletApi from '@didi/pagelets-sdk';
import { timeRangeTypeList, TIME_RANGE_TYPE } from '../utils/constant.js';

const { TabPane } = Tabs;
const localLocales = manifest.locales || {};
const Pagelet = (props) => {
  const {
    params: {
      auth,
      headers = {},
      apiHost = '//dhr-dev.intra.xiaojukeji.com',
      locales,
      subordinate,
      defaultDeptCode,
      defaultDeptInfo,
      pageType,
      timeRangeType,
      startDate = moment().startOf('month'),
      endDate = moment().endOf('month'),
      close = () => { },
      permissionScope
    },
  } = props;

  const authId = PageletApi.utils.getFunctionId(auth, 'holiday_summary');
  const newHeaders = {
    ...headers,
    'bricks-function-id': authId,
    functionId: authId,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
  };
  PageletApi.newRequest.init(newHeaders, 'holiday_summary');

  const prefixCls = 'pagelet-leave-summary';
  const wrapSSR = useStyle(prefixCls);
  const { getPrefixCls } = React.useContext(ConfigProvider.ConfigContext);
  const modalPrefixCls = getPrefixCls('drawer', null); // ant-drawer
  const wrapDrawerSSR = useDrawerStyle(modalPrefixCls);
  const locale = getLocale(locales || localLocales);
  const classes = classNames(prefixCls);
  const TITLE_HEIGHT = 130; //头部高度

  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN');
  const [providerLocale, setProviderLocale] = React.useState({});


  const [activeTab, setActiveTab] = useState('hasHoliday'); // 未休or已休
  const [maxHeight, setMaxHeight] = useState(null);

  const [timeDimension, setTimeDimension] = useState(timeRangeType);
  const [startDateInfo, setStartDateInfo] = useState(startDate);
  const [leaveBeginTime, setLeaveBeginTime] = useState('');
  const [leaveEndTime, setLeaveEndTime] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [deptCodes, setDeptCodes] = useState([]); // 部门
  const [searchType, setSearchType] = useState(subordinate);
  const [page, setPage] = useState({ pageNum: 1, pageSize: 10 });
  const [clear, setClear] = useState(false);
  const resizeUpdate = (e) => {
    setMaxHeight(e.target.innerHeight - TITLE_HEIGHT);
  }

  useEffect(() => {
    // 页面刚加载完成后获取浏览器窗口的大小
    setMaxHeight(window.innerHeight - TITLE_HEIGHT);
    // 页面变化时获取浏览器窗口的大小
    window.addEventListener('resize', resizeUpdate);
    if (lang !== 'zh-CN') {
      setProviderLocale(en_US)
    } else {
      setProviderLocale(zh_CN)
    }
    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener('resize', resizeUpdate);
    }
  }, []);
  useEffect(() => {
    setTimeDimension(timeRangeType);
    setStartDateInfo(startDate);
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    setLeaveBeginTime(start)
    setLeaveEndTime(end)
    if (timeRangeType === 'month') {
      setCurrentMonth(moment(startDate).format('M'))
    }
  }, [timeRangeType, startDate, endDate])
  const onDeptChange = (value, clear) => {
    const deptList = value?.length !== 0 && value[0] ? value : defaultDeptCode
    setDeptCodes(deptList);
    setPage({ pageNum: 1, pageSize: 10 });
    if (clear) {
      setClear(true);
    } else {
      setClear(false);
    }
  };
  // 下属
  const onScopeChange = (scope) => {
    setSearchType(scope);
    setPage({ pageNum: 1, pageSize: 10 });
  };
  const onTimeRangeType = (v, item) => {
    setTimeDimension(v);
    setStartDateInfo(dayjs().valueOf());
    setPage({ pageNum: 1, pageSize: 10 });
    if (v === TIME_RANGE_TYPE.week) {
      setLeaveBeginTime(dayjs().startOf('isoWeek').format('YYYY-MM-DD'));
      setLeaveEndTime(dayjs().endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (v === TIME_RANGE_TYPE.month) {
      setLeaveBeginTime(dayjs().startOf(TIME_RANGE_TYPE.month).format('YYYY-MM-DD'));
      setLeaveEndTime(dayjs().endOf(TIME_RANGE_TYPE.month).format('YYYY-MM-DD'));
      setCurrentMonth(moment().format('M'))
    } else if (v === TIME_RANGE_TYPE.date){
      setLeaveBeginTime(dayjs().startOf(TIME_RANGE_TYPE.date).format('YYYY-MM-DD'));
      setLeaveEndTime(dayjs().endOf(TIME_RANGE_TYPE.date).format('YYYY-MM-DD'));
    } else {
      setLeaveBeginTime(dayjs().startOf('year').format('YYYY-MM-DD'));
      setLeaveEndTime(dayjs().format('YYYY-MM-DD'));
    }
  }
  // 时间
  const onTimeChange = (time) => {
    const startTime = dayjs(time[0]).format('YYYY-MM-DD');
    const endTime = dayjs(time[1]).format('YYYY-MM-DD');
    setLeaveBeginTime(startTime);
    setLeaveEndTime(endTime);
    setCurrentMonth(moment(startTime).format('M'))
  };
  // 已休假汇总
  const hasUsedWarp = <div>
    <div className="holiday-used-options">
      <div className="holiday-dept-select">
        {
          activeTab === 'hasHoliday' && <DepartmentSelection headers={newHeaders} apiHost={apiHost} auth={auth} defaultDeptCode={defaultDeptCode} defaultDeptInfo={defaultDeptInfo} onDeptChange={onDeptChange} />
        }
      </div>
      <div className="holiday-warp">
        <div className="holiday-used-scope">
          {
            permissionScope?.length > 0 && <ScopeFilter subordinate={subordinate} list={permissionScope} onScopeChange={onScopeChange} />
          }
        </div>
        <div className="year-month-week">
          <div className={`${prefixCls}-title-filter`}>
            <div className={`${prefixCls}-scope`}>
              <ScopeFilter
                subordinate={timeDimension}
                list={timeRangeTypeList}
                onScopeChange={onTimeRangeType}
              />
            </div>
           { timeDimension === '2' ? '' : <div className={`${prefixCls}-time`}>
              <TimeFilter
                source='team-holidays'
                lang={lang}
                timeRangeTypeInfo={timeDimension}
                startDate={startDateInfo}
                onTimeChange={onTimeChange}
                onTimeRangeType={onTimeRangeType}
              />
            </div>}
        </div>
        </div>
      </div>
    </div>
    <HasUsed
      apiHost={apiHost}
      auth={auth}
      deptCodes={deptCodes}
      pageType={pageType}
      page={page}
      searchType={searchType}
      maxHeight={maxHeight}
      close={close}
      lang={lang}
      timeDimension={timeDimension}
      leaveBeginTime={leaveBeginTime}
      leaveEndTime={leaveEndTime}
      currentMonth={currentMonth}
      activeTab={activeTab}
      clear={clear}
      headers={newHeaders}
      onSetPage={(value) => {
        setPage(value);
      }}
    />
  </div>
  // 未休
  const notUsedWarp = <div>
    <div className="holiday-dept-select">
      {
        activeTab === 'noHoliday' && <DepartmentSelection apiHost={apiHost} auth={auth} headers={newHeaders} defaultDeptCode={defaultDeptCode} defaultDeptInfo={defaultDeptInfo} onDeptChange={onDeptChange} />
      }
    </div>
    <NotUsed
      apiHost={apiHost}
      lang={lang}
      auth={auth}
      functionId={authId}
      deptCodes={deptCodes}
      pageType={pageType}
      searchType={searchType}
      maxHeight={maxHeight}
      close={close}
      page={page}
      activeTab={activeTab}
      clear={clear}
      headers={newHeaders}
      onSetPage={(value) => {
        setPage(value);
      }}
    />
  </div>
  const items = [
    {
      key: 'hasHoliday',
      label: '已休假汇总',
      children: hasUsedWarp,
    },
    {
      key: 'noHoliday',
      label: '未休假汇总',
      children: notUsedWarp,
    }
  ];
  return wrapSSR(
    <div className={classes}>
      {
        wrapDrawerSSR(
          <div className="drawer-content">
            <ConfigProvider
              locale={providerLocale}
            >
              <Tabs
                 activeKey={activeTab}
                 items={items}
                 className="drawer-content-tabs"
                 centered
                 onChange={(key) => {
                  setActiveTab(key);
                }}
              />
              <div className="tips-icon">
                <span>
                  {activeTab === 'noHoliday' ? '每2小时更新数据，展示本年度假期数据' : '每3小时更新数据，最长向前追溯2年'}
                </span>
              </div>
            </ConfigProvider>
          </div>
        )
      }

    </div>,

  );
};

export default Pagelet;
