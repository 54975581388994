const MenuMapping = {
  'home_team_transfer_manage': '调动管理',
  'home_team_transfer_manage_approve': '我的单据',
  'home_team_transfer_manage_group': '团队调动记录',
  // 'hr_workspace_on_boarding_apply': '入职申请-发起申请',
  // 'hr_workspace_on_boarding_entry': '入职申请',

  //调动
  'hr-workspace_transfer_manage': 'HR工作台-调动管理-调动流程管理',
  'hr-workspace_transfer_manage_approve': 'HR工作台-调动管理-调动流程管理-我的单据',
  'hr-workspace_transfer_manage_approve_toApprove': 'HR工作台-调动管理-调动流程管理-我的单据-待我审批-去审批',
  'hr-workspace_transfer_manage_approve_toCheck': 'HR工作台-调动管理-调动流程管理-我的单据-我已审批-去查看',
  'hr-workspace_transfer_manage_group': 'HR工作台-调动管理-调动流程管理-团队调动记录',
  'hr-workspace_manage_group_GoCheck':'HR工作台-调动管理-调动流程管理-团队调动记录-去查看',
  'hr-workspace_manage_group_GoCheck_revokePerson':'HR工作台-调动管理-调动流程管理-团队调动记录-去查看-单人撤销',
  'hr-workspace_manage_group_GoCheck_revokeBill':'HR工作台-调动管理-调动流程管理-团队调动记录-去查看-整单撤销',
  'hr-workspace_manage_group_GoCheck_changeDate':'HR工作台-调动管理-调动流程管理-团队调动记录-去查看-改期',
  'hr-workspace_transfer_handle': 'HR工作台-调动管理-调动手续办理',
  'hr-workspace_transfer_handle_btnSave': 'HR工作台-调动管理-调动手续办理-保存',
  'hr-workspace_transfer_handle_btnSync': 'HR工作台-调动管理-调动手续办理-同步电子签',
  'hr-workspace_transfer_handle_btnPaperSignature': 'HR工作台-调动管理-调动手续办理-已纸质签署',
  'hr-workspace_transfer_handle_btnCompleted': 'HR工作台-调动管理-调动手续办理-已完成无需签署',
  'hr-workspace_transfer_handle_claimPaper': 'HR工作台-调动管理-调动手续办理-已领取纸质',

  //入职
  'hr-workspace_on-boarding_tripartite': 'HR工作台-入职管理-校招签署办理',
  'accept_letter': 'HR工作台-入职管理-校招签署办理-接收函',
  'trip_online': 'HR工作台-入职管理-校招签署办理-三方网签',
  'trip_offline': 'HR工作台-入职管理-校招签署办理-三方纸签',
  'doub_online': 'HR工作台-入职管理-校招签署办理-两方网签',
  'hr-workspace_on-boarding_procedure': 'HR工作台-入职管理-入职手续办理',
  'procedure_in': 'HR工作台-入职管理-入职手续办理-已入职',
  'procedure_not': 'HR工作台-入职管理-入职手续办理-未到岗',
  'procedure_emergency': 'HR工作台-入职管理-入职手续办理-紧急入职',
  'procedure_remind': 'HR工作台-入职管理-入职手续办理-信息补充提醒',
  'procedure_contract': 'HR工作台-入职管理-入职手续办理-同步电子签',
  'procedure_sso': 'HR工作台-入职管理-入职手续办理-重新生成/激活账号',
  'procedure_editper': 'HR工作台-入职管理-入职手续办理-编辑个人信息',
  'procedure_editjob': 'HR工作台-入职管理-入职手续办理-编辑职务信息',
  'procedure_thrid': 'HR工作台-入职管理-入职手续办理-编辑三方信息',
  'eec_special': 'HR工作台-入职管理-入职手续办理-EEC专用',
  'hr-workspace_on-boarding_special-procedure': 'HR工作台-入职管理-特殊入职办理',
  'special_procedure_apply': 'HR工作台-入职管理-特殊入职办理-发起特殊入职',
  'special_procedure_sso': 'HR工作台-入职管理-特殊入职办理-生成账号',
  'special_procedure_in': 'HR工作台-入职管理-特殊入职办理-已入职',
  'special_procedure_editjob': 'HR工作台-入职管理-特殊入职办理-编辑职务信息',
  'special_procedure_pr': 'HR工作台-入职管理-特殊入职办理-编辑个人信息',
  'special_procedure_jobcard': 'HR工作台-入职管理-特殊入职办理-生成工卡',
  'special_procedure_not': 'HR工作台-入职管理-特殊入职办理-拒绝入职',

  //离职
  'off_boarding_handover_bench': 'HR工作台-离职管理-离职交接办理',
  'off_boarding_sscwork_bench': 'HR工作台-离职管理-离职信息管理',
  'off_boarding_send_protocol': 'HR工作台-离职管理-发送协商解除协议',
  'off_boarding_special_bench': 'HR工作台-离职管理-特殊离职流程管理',
  

  //合同续签
  'renew_sign_contract': 'HR工作台-合同续签管理-续签手续办理',
  'renew_sign_contract_detail': 'HR工作台-合同续签管理-续签手续办理-单据详情页',
  'renew_sign_contract_synchronization': 'HR工作台-合同续签管理-续签手续办理-同步电子签',
  'renew_sign_contract_signed': 'HR工作台-合同续签管理-续签手续办理-员工已签署',
  'renew_sign_company_signed': 'HR工作台-合同续签管理-续签手续办理-公司已签署',
  'renew_sign_offline_signed': 'HR工作台-合同续签管理-续签手续办理-线下办理',
  'renew_new_contract': 'HR工作台-合同续签管理-续签流程管理-新发起合同续签',
  'renew_renewal_mgmt_new_agreement': 'HR工作台-合同续签管理-续签流程管理-新发起协议续签',

  //员工服务
  'service_appointment_approve': 'HR工作台-员工服务管理-预约服务管理-单据处理-详情',
  'service_appointment_management': 'HR工作台-员工服务管理-预约服务管理-工单管理-详情',
  'service_proof_approve': 'HR工作台-员工服务管理-证明服务管理-单据处理-详情',
  'service_proof_management': 'HR工作台-员工服务管理-证明服务管理-工单管理-详情',
  'service_borrow_documentProcess': 'HR工作台-员工服务管理-档案服务管理-单据处理-详情',
  'service_borrow_orderManagement': 'HR工作台-员工服务管理-档案服务管理-工单管理-详情',
  'service_card_approve': 'HR工作台-员工服务管理-卡类服务管理-单据处理-详情',
  'service_card_management': 'HR工作台-员工服务管理-卡类服务管理-工单管理-详情',


  'process_approval_todo': '审批中心-我的审批-我的待办',
  'process_approval_done': '审批中心-我的审批-我的已办',

  'ei_applylist_applydetail': 'DHR系统-HR工作台-员工信息管理-员工信息审批',
  'tutor_list_change': 'DHR系统-HR工作台-实习生导师管理-导师信息管理',
}

export default MenuMapping;