import React, { useState, useCallback, useEffect } from "react";
import { groupRespondentAndContentId, getDeviceType } from '../../utils/Tool';
import { Button } from 'antd';
import succeed from '../../images/succeed.svg';
import nothing from '../../images/yellow-nothing.svg';
import { di18n } from '@ultra/common';
import { useHistory } from "react-router-dom";
import noHrUrl from '../../images/no-hr-pc-top.png';
import noHrH5Url from '../../images/no-hr-h5.png';
import './index.scss';
const { intl } = di18n;
const statusShow = (props) => {
  const { status } = props; // 1已提交 2失效
  const [btnHide, setBtnHide] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [pageType, setPageType] = useState('');
  const history = useHistory(); // 路由信息
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const paramId = params.get("id");

  const groupRespondentAndContentIdFn = async () => {
    try {
      const res = await groupRespondentAndContentId(paramId);
      if (res?.code === '699999') {
        location.replace(`${location.origin}/q/${paramId}`);
        return;
      }
      const hide = res?.data?.lotteryInfo?.supportLottery;
      if (hide) {
        setBtnHide(hide);
      }
    } catch (error) {
      
    }
  };

  function generateToken() {
    const payload = {
      exp: Date.now() + 5 * 1000, // 5秒后过期
      random: Math.random().toString(36).substring(7), // 添加随机字符串防止重复
    };
    return btoa(JSON.stringify(payload));
  }

  // 跳转抽奖页面
  const handleJump = () => {
    const token = generateToken();
    history.push(`/lotteryRedirectsPC?token=${encodeURIComponent(token)}&id=${encodeURIComponent(btoa(JSON.stringify(paramId)))}`);
  };

  useEffect(() => {
    const hrStatus = sessionStorage.getItem('surveyBusType_error');
    const deviceType = getDeviceType();
    //如果是非HR
    if (hrStatus === 'NON_PROFESSIONAL_SERVICES') {
      // h5的图片在顶部
      if (deviceType === 'H5') {
        // setImgUrl(noHrH5Url);
        setImgUrl(noHrH5Url);
      } else {
        setImgUrl(noHrUrl);
      }
      setPageType(deviceType);
    }
    groupRespondentAndContentIdFn();
  }, [])

  return (
    <div className="status-wapper">
      <div className="status-banner">
        {/* 如果是非HR的就用img覆盖了之前的老图片样式，老的图片是svg格式不好调位置，所以这么搞的 */}
        {pageType && <img src={imgUrl} style={{ width: '100vw' }} />}
      </div>
      <div className="succeed h5">
        {status === 1 ? <img src={succeed} /> : <img src={nothing} />}
      </div>
      <div className="status-text">
        <div className="text-des h5">
          <div>
            {status === 1 ? intl.t('您已提交成功，感谢您的反馈')
              : intl.t('本次调研活动已结束，感谢您的访问~')}
          </div>
          <div>
            {status === 1
              ? 'Successfully submitted. Thanks for your feedback! '
              : 'The research is over,thanks for your visit!'}
          </div>
        </div>
      </div>
      {btnHide && (
        <div className="btn-content">
          <Button onClick={handleJump} className="jump-btn" type="primary">
            {intl.t('登录星云系统进行抽奖')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default statusShow;
