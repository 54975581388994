// 事件触发
const emit = (name, data) => {
  window.dispatchEvent(new CustomEvent(name, { detail: data }));
}

// 事件监听
const on = (name, callback) => {
  window.addEventListener(
    name,
    evt => callback(evt.detail, evt),
  );
}

export {
  emit,
  on,
}
