import React, { useState, useEffect } from 'react';
import NotProgress from '../../components/NotProgress';
import './index.scss';
import { di18n } from '@ultra/common';
const { intl } = di18n;


export default function NotStarted(props) {
  return (
    <div className="page-warpper">
      <NotProgress text={intl.t('活动已结束或未开始')} />
    </div>
  );
};
