import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-canghai-talent-search', () => [
		{
			// base
			[`.${prefixCls}`]: {
        color: chartToken.$font_color,
				fontFamily: chartToken.$font_family,
        fontSize: variable.size,
        cursor: 'pointer',
        position: 'fixed',
        right: '0',
        width: '34px',
        background: 'linear-gradient(121deg, #FFFFFF -1%, rgba(255, 255, 255, 0) 33%), linear-gradient(221deg, #FFEFE3 5%, #FFFFFF 92%)',
        boxShadow: ' 0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: '8px 0 0 8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '12px 6px',
        transition: 'all 0.2s',
        ['img']: {
          width: '20px',
          height: '20px',
        },
        ['.text']: {
          marginTop: '8px',
          textAlign: 'center',
          fontSize: '11px',
          fontWeight: '500',
          lineHeight: '14px',
          color: '#74787D'
        }
			},
      // 搜索弹窗样式
      [`.${prefixCls}.show-search-modal`]: {
        zIndex: '199',
        width: '329px',
        height: '140px',
        cursor: 'inherit',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '12px',
        background: 'linear-gradient(0deg, #FFFFFF 54%, rgba(255, 255, 255, 0) 100%), linear-gradient(243deg, #FFEFE3 8%, #FFFFFF 89%)',
        ['.search-modal']: {
          width: '100%',
          height: '100%',
          position: 'relative',
          ['.search-modal-title']: {
            display: 'flex',
            alignItems: 'center',
            ['span']: {
              fontSize: '14px',
              marginLeft: '6px',
              fontWeight: '500',
            }
          },
          ['.ec-base-auto-complete']: {
            marginTop: '12px',
            width: '100%',
            ['.ant-select-selector']: {
              borderRadius: '8px',
              ['.ant-select-selection-placeholder']: {
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.6)',
              }
            }
          },
          ['.ant-select:not(.ant-select-disabled):hover .ant-select-selector, .ant-select-focused .ant-select-selector']: {
            borderColor: '#ff9d69 !important',
            boxShadow: '0 0 0 2px rgba(255,126,65,.2) !important'
          },
          ['.ant-select-dropdown']: {
            backgroundColor: 'unset',
            boxShadow: 'none',
            height: 'auto',
            ['.ant-select-item']: {
              padding: '6px 12px',
              marginBottom: '6px',
              borderRadius: '8px',
              background: 'rgba(245, 245, 245, 0.6)',
              border: '1px solid rgba(0, 0, 0, 0.04)',
            },
            ['.ant-select-item-option-active']: {
              background: 'rgba(255, 100, 0, 0.02)',
              border: '1px solid #FF6400',
            },
            ['.item-option']: {
              display: 'flex',
              alignItems: 'center',
              ['.ec-base-avatar']: {
                borderColor: 'rgba(0, 0, 0, 0.04)',
                minWidth: '28px',
                ['img']: {
                  width: '28px',
                  height: '28px',
                }
              },
              ['.item-option-content']: {
                width: 'calc(100% - 32px)',
                marginLeft: '8px',
                color: 'rgba(0, 0, 0, 0.84)',
                ['.item-option-content-name']: {
                  marginBottom: '4px',
                  lineHeight: '18px',
                  fontWeight: '500',
                  ['.quit, .internship']: {
                    fontSize: '12px',
                    fontWeight: '600',
                    borderRadius: '4px',
                    padding: '2px 4px',
                    marginLeft: '6px',
                  },
                  ['.quit']: {
                    color: '#F5483B',
                    backgroundColor: 'rgba(245, 72, 59, 0.1)',
                  },
                  ['.internship']: {
                    color: 'rgba(0, 150, 89, 0.8)',
                    backgroundColor: 'rgba(0, 150, 89, 0.1)',
                  }
                },
                ['.item-option-content-dept']: {
                  marginBottom: '0',
                  lineHeight: '16px',
                }
              }
            },
            ['.ec-base-empty']: {
              marginTop: '28px',
              ['.ant-empty-image']: {
                height: 'auto',
                ['img']: {
                  width: '80px',
                  height: '66.67px'
                }
              },
              ['.ant-empty-description']: {
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '12px',
                fontWeight: 'normal',
              }
            },
            ['.data-loading']: {
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '12px',
              fontWeight: 'normal',
              marginTop: '94px',
              textAlign: 'center'
            }
          },
          ['.ec-base-spin']: {
            width: '100%',
            height: 'calc(100% - 65px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            background: 'rgba(255, 255, 255, 0.2)'
          }
        }
      },
      ['.canghai-search-tooltip .ant-tooltip-arrow']: {
        display: 'none'
      }
		}
	]);
};

export default useStyle;
