import React, { useState,useEffect,useRef,useMemo } from "react";
import {Tag,Tooltip,Spin} from "antd";
import {
    Form,
    FormItem,
    Reset,
    FormEffectHooks,
    useFormEffects,
    InternalVirtualField as VirtualField,
    useFormSpy,
    FormPath,
    FormSpy,
    registerFormField, 
    connect,
    LifeCycleTypes,
    createFormActions
  } from '@formily/antd';
  import {CloseOutlined} from '@ant-design/icons'
  import moment from 'moment'

import { Input,Select,DatePicker } from '@formily/antd-components'
import request from './request'
import CardIcon from '../CardIcon'
import './index.scss'

function useOnClickOutside(ref, handler) {
    const [visible, setVisible] = useState(false);
    const eventRef = useRef((event)=>{
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
            return;
        }
        handler(event);
    })
    const binded = useRef(false)

    useEffect(
        () => {
            if(visible && !binded.current){
                binded.current = true
                document.addEventListener('mousedown', eventRef.current);
            }
            if(binded.current && !visible){
                binded.current = false
                document.removeEventListener('mousedown', eventRef.current);
            }
    },
    [visible]
  );

  return [visible, setVisible]
}
// 分为输入框，筛选，多选，日期，
const FormFilterItem = ({data})=>{

    const ref = useRef();
    const [value, setValue] = useState(null);

    const [left, setleft] = useState(0);

    const hasChanged = useRef(false)
    const {searchUrl,initNotEmpty} = data
    const popWidth = 290

    const { form } = useFormSpy({ selector: '*', reducer: v => v })

    const getPopPosition = ()=>{
        const {left} = ref.current.getBoundingClientRect();
        const viewWidth = window.innerWidth || document.documentElement.clientWidth;
        if((left + popWidth) > viewWidth){
            setleft(viewWidth - (left + popWidth) - 24)
        } else {
            setleft(0)
        }
    }

    const showPop = ()=>{
        if (data.disabled) return
        getPopPosition()
        setVisible(true)
    }

    const restField = ()=>{
        form.setFieldValue(data.name, data.mode === 'multiple' ? [] : '') 
        setValue(null)
    }

    const HidePop = ()=>{ 
        // 设置值，刷新表单
        setValue(form.getFieldValue(data.name))
        setVisible(false)
        form.validate(data.name).then((res) => {
            if(hasChanged.current){
                hasChanged.current = false
                form.submit()
            }
        });
        if(form.getFieldState(data.name).errors.length){
            form.clearErrors(data.name)
            restField()
        }
    }

    const emptyThis = (e)=>{
        setVisible(false)
        e.stopPropagation();
        restField(data)
        form.submit()
    }

    const [visible, setVisible] = useOnClickOutside(ref,HidePop);

    const getComponentProp = (component,option)=>{
        let defaultOption = {}
        switch(component){
            case 'Select':
                defaultOption = {
                    getPopupContainer:trigger => trigger.parentNode,
                    component: Select,
                    labelInValue: true,
                }
                if(!option.searchUrl){
                    defaultOption.open = true;
                    defaultOption.dropdownMatchSelectWidth = 266
                }
                if(option.searchUrl){
                    defaultOption.placeholder = '请输入搜索'
                }
                if(option.initNotEmpty){
                    defaultOption.notFoundContent= null
                }
                return defaultOption
            case 'Input':
                defaultOption = {
                    component: Input,
                    onPressEnter: ()=>{HidePop()}
                }
                return defaultOption
            case 'RangePicker':
                defaultOption = {
                    open: true,
                    component: DatePicker.RangePicker,
                    getPopupContainer:trigger => trigger.parentNode,
                }
                return defaultOption
            default:
                return defaultOption
        }
    }

    const FromItemProps = useMemo(()=>{
        const tempData = {}
        Object.keys(data).forEach(i=>{
            if(['labelKey','searchKey','valueKey','searchUrl'].indexOf(i)>=0 ){
                return 
            }
            tempData[i] = data[i]
        })
        return Object.assign({},tempData,{title:null},getComponentProp(data.component,data))
    },[data])

    const initprops = useRef(FromItemProps)

    const { onFieldValueChange$,onFieldInit$,onFormReset$  } = FormEffectHooks

    useFormEffects(($, { setFieldState }) => {
        onFieldValueChange$(data.name).subscribe((state) => {
            hasChanged.current = true
            setValue(data.value)
            if(data.component === 'Select'  && (data.mode !== 'multiple') || data.onValueChangeHide){
                HidePop()
            } 
        })
        onFormReset$().subscribe(() => {
            setValue(null)
        })
        onFieldInit$(data.name).subscribe(() => {
            if(searchUrl){
                const {searchKey,labelKey,valueKey,namekey} = data
                setFieldState(data.name, state => {
                    FormPath.setIn(state, 'props.filterOption', false)
                    FormPath.setIn(state, 'props.showSearch', true)
                    FormPath.setIn(state, 'props.onSearch', value => {
                        if (!value) return
                        setFieldState(data.name, state => {
                            if(initNotEmpty){
                                FormPath.setIn(state, 'props.notFoundContent', <Spin size="small" />)
                            }
                            FormPath.setIn(state, 'props.loading', true)
                        })
                        const getValueType = (key,v,type,all)=>{
                            if (typeof key === 'string') {
                                v[type] = all[key];
                            } if (typeof key === 'function') {
                                v[type] = key(all);
                            }
                            return v
                        }
                        const fetchData = async ()=>{
                            // 兼容报表中心searchKey 不存在，直接将value拼接到配置的url上
                            const params = searchKey ? {
                                [searchKey]:value
                            } : null
                            let url = searchKey ? searchUrl : `${searchUrl}${value}`
                            const res = await request({
                                method: 'get',
                                url: url,
                                params,
                            }); 
                            if(!res.data){
                                return 
                            }
                            setFieldState(data.name, state => {
                                FormPath.setIn(state, 'props.loading', false)
                                if(initNotEmpty){
                                    FormPath.setIn(state, 'props.notFoundContent', null)
                                }
                                const result = res.data.map(v=>{
                                    let defaultValue = {
                                        value: v[valueKey],
                                        title: v[valueKey]
                                    };
                                    console.log(defaultValue.value)
                                    if (typeof labelKey === 'string') {
                                        defaultValue.label = v[labelKey];
                                    } if (typeof labelKey === 'function') {
                                        defaultValue.label = labelKey(v);
                                    }
                                    defaultValue = getValueType(valueKey,defaultValue,'value',v)
                                    if (namekey) {
                                        defaultValue.namekey = v[namekey];
                                    }
                                    return defaultValue;
                                })
                              
                                FormPath.setIn(state, 'props.dataSource', result)
                            })
                        }
                        fetchData()
                    })
                })
            }
        })
    })

    const renderFitter = useMemo(()=>{
        let valueText = ''
        let renderTip = false
        if(!!value && data.component === 'RangePicker'){
            const arr = !value[0] && !value[1]  ? [] : value.map(v => {
                return v || '-'
            })
            valueText = arr.map(v=>v).join('至')
        }else if(!!value && Array.isArray(value)){
            const list = value.map(v=>v.label)
            renderTip = list.join('、')
            valueText = value.length ? `(${value.length})` : ''
        }else if(!!value){
            valueText = typeof value === 'object' ?value.label:value
        }

        const inner = <span onClick={() => {
            showPop()
        }} className={"form-filter-label "}>
            {data.title}
            {(Array.isArray(value) ? value.length :  !!value)?':':null}
            <span>{valueText}</span>
            <span className="form-filter-icon">
                <span className="form-filter-down"><CardIcon type="icondropdown" className="form-filter-icon-down" /></span>
                <span className="form-filter-clear" onClick={emptyThis}><CloseOutlined style={{verticalAlign: 'middle'}}/></span>
                {/* <span className="form-filter-clear" onClick={emptyThis}><CardIcon type="iconeliminate" className="form-filter-icon-down" /></span> */}
            </span>
         </span>
        if(renderTip){
            return <Tooltip title={renderTip}>
            {inner}
          </Tooltip>
        }
        return inner
    },[value, data.disabled])

    return <VirtualField name={`${data.name}field`}>
        <div className={"form-filter-item " + ((Array.isArray(value) ? value.length :  !!value)?'has-value':'') + (data.disabled ? ' form-filter-item-disabled' : '')} ref={ref}>
            {renderFitter}
            <div className={"form-filter-dropdown" + (visible?' form-filter-dropdown-show':'')} style={{left:left}}>
                <FormItem  {...initprops.current} />
            </div>
            
        </div>
    </VirtualField>
}

const FormFilter = ({form,filter,total, unShowFormSpy}) => {
    const [tipsValue, setTipsValue] = useState({})

    const getValueText = (value, data) => {
        let valueText = ''
        let renderTip = false
        if(!!value && data.component === 'RangePicker'){
            const arr = !value[0] && !value[1]  ? [] : value.map(v => {
                return v || '-'
            })
            valueText = arr.map(v=>v).join('至')
        }else if(!!value && Array.isArray(value)){
            const list = value.map(v=>v.label)
            renderTip = list.join('、')
            valueText = value.length ? `(${value.length})` : ''
        }else if(!!value){
            valueText = typeof value === 'object' ?value.label:value
        }

        return renderTip || valueText
    }
    const rendeFilterTip = (renderTipData) => {
        return (
            <div className="rende-filter-tip">
                {
                    Object.keys(renderTipData).map((v, i) => {
                         return renderTipData[v].value ? <div key={i} className="rende-filter-tip-item">
                             <div className="rende-filter-tip-label">{renderTipData[v].label}：</div>
                             <div className="rende-filter-tip-value"><span></span> {renderTipData[v].value}</div> 
                             </div> : null
                             
                    })
                }
            </div>
        )
    }
    return <Form
        {...form}
        className="form-filter"
    >
        <div className="form-filter-container">
            {

                filter.map((v,i)=><FormFilterItem data={v}  key={v.name}/>)
            }
        </div>
        {
            unShowFormSpy ? null : <FormSpy selector={["onFormSubmit","onFormReset"]}>
            {({ state}) => {
                let renderTipData = {};
                let keyNumber = 0;
                for (const key in state.values) {
                    const item = state.values[key]
                    if(Array.isArray(item)){
                        if(!!item.length){
                            keyNumber = keyNumber + 1
                        }
                    }else if(typeof item === 'object'){
                        if(!!item.value){
                            keyNumber = keyNumber + 1
                        }
                    }else if(!!item){
                        keyNumber = keyNumber + 1
                    }
                }

                for(const item of filter) {
                    if (state?.values && state?.values[item.name]) {
                        renderTipData[item.name] = {
                            label: item.title,
                            value: getValueText(state.values[item.name], item)
                        }
                    }
                    
                }

                return <div className={"table-detail" + (keyNumber > 0 ?' has-select': '')}>
                <span>共{total}条结果</span>
                <span className="has-many">
                
                    <Tooltip placement="bottomLeft" overlayStyle={{width: '294px'}} title={() => rendeFilterTip(renderTipData)} >
                    <span>{keyNumber}个筛选条件</span>
                    </Tooltip>
                    
                </span>
                <Reset type="link" className="empty-this"><CardIcon type="iconLine-delete" className="form-filter-icon-empty" />清空条件</Reset>
            </div>
            }}
        </FormSpy>
        }
  </Form>

}

FormFilter.dealWithValue = (fitterList,values)=>{
    const getValue = {}
    fitterList.forEach(v=>{
        let tempvalue = null
        let thisValue = values[v.name]
        if(!thisValue){
            return
        }
        if(v.component === 'Select'){
            if(Array.isArray(thisValue)){
            tempvalue = thisValue.map(v=>v.value).join(',')
            }else{
            tempvalue = thisValue.value
            }
        } else if(v.component === 'Input'){
            tempvalue = thisValue.replace(/(^\s*)|(\s*$)/g,'')
        }else{
            tempvalue = thisValue
        }
        if(v.component === 'RangePicker'){
            if(v.rangePickerSingleKey === 'stringKey') {
                getValue[v.name] = thisValue.join(',')
                if(v.timeFormat === 'timestamp' && thisValue) {
                    getValue[v.name] = thisValue.map(valueTimestamp => moment(valueTimestamp).hours(23).minutes(59).seconds(59).valueOf()).join(',')
                }
            } else {
                getValue[v.startKey] = thisValue[0]
                getValue[v.endKey] = thisValue[1]
                if(v.timeFormat === 'timestamp' && getValue[v.startKey]){
                    getValue[v.startKey] = moment(getValue[v.startKey]).valueOf()
                }
                if(v.timeFormat === 'timestamp' && getValue[v.endKey]){
                    getValue[v.endKey] = moment(getValue[v.endKey]).hours(23).minutes(59).seconds(59).valueOf()
                }
            }
        }else{
            getValue[v.name] = tempvalue
        }
    })
    if (values.extendsValues) {
        Object.keys(values.extendsValues).forEach(key => {
            getValue[key] = values.extendsValues[key]
        })
    }
    return getValue
}

export default FormFilter;