import React, { useRef, useEffect } from 'react';
import { LangTranslate } from '@/utils';
import SvgIcon from '@/cpns/SvgIcon';
import { Tooltip, Modal } from 'antd';
import './index.less';

function CustomPageHead(props) {
  const {
    title,
    switchTxt,
    handleSwitch,
    switchWarnTxt = LangTranslate('确定切换吗？'),
    hasSwitch = false,
    renderBtns = () => <div />,
    handelReturnHome = () => {},
  } = props;

  const wrapRef = useRef();

  useEffect(() => {
    console.log('111');
    const dom = document.querySelector(
      '#pagelet-label-system-tag-pool-tag-config-page-scroll-content-body',
    );
    if (!dom) {
      return;
    }
    const curDom = wrapRef.current;
    // 监听滚动事件
    dom?.addEventListener?.('scroll', () => {
      if (dom?.scrollTop > 5) {
        curDom?.classList?.add?.('pagelet-label-system-custom-content-head-wrap-scroll');
      } else {
        curDom?.classList?.remove?.('pagelet-label-system-custom-content-head-wrap-scroll');
      }
    });
  }, []);
  return (
    <div ref={wrapRef} className="pagelet-label-system-custom-content-head-wrap">
      <div className="page-head-left">
        <div
          className="page-head-left-back-icon"
          onClick={() => {
            handelReturnHome?.();
          }}
        >
          <Tooltip placement="topLeft" title={LangTranslate('返回')}>
            <SvgIcon type="icon-fanhuianniu" size={20} pointer />
          </Tooltip>
        </div>
        <div className="split-line" />
        <div className="page-head-left-title">
          <Tooltip placement="topLeft" title={title || '-'}>
            {title || '-'}
          </Tooltip>
        </div>
        {hasSwitch && (
          <div
            onClick={() => {
              const cModal = Modal.confirm({
                title: LangTranslate('确定切换吗？'),
                content: switchWarnTxt,
                okButtonProps: {},
                className: 'pagelet-label-system-warn-confirm-wrap',
                footer: (
                  <div className="pagelet-label-system-warn-confirm-footer">
                    <div
                      className="pagelet-label-system-warn-confirm-footer-btn"
                      onClick={() => {
                        cModal?.destroy?.();
                      }}
                    >
                      {LangTranslate('取消')}
                    </div>
                    <div
                      data-btn-primary={true}
                      className="pagelet-label-system-warn-confirm-footer-btn"
                      onClick={async () => {
                        await handleSwitch();
                        cModal?.destroy?.();
                      }}
                    >
                      {LangTranslate('确定')}
                    </div>
                  </div>
                ),
              });
            }}
            className="page-head-left-undo-wrap"
          >
            <SvgIcon type="icon-undo-o" size={16} pointer />
            <div>{switchTxt}</div>
          </div>
        )}
      </div>
      <div className="page-head-right-btns">{renderBtns()}</div>
    </div>
  );
}

export default CustomPageHead;
