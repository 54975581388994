import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 工作经历
 * url: /api/data/growthTrain
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getLearnTrain = (apiHost, headers, params) => request(`${apiHost}${apis.getLearnTrain}`, 
  {
    method: 'get',
    headers,
    params,
  }
);

export default getLearnTrain;
