import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('filter-content', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        margin: '0 5px 12px',
        display: 'flex',

        ['.screen']: {
          padding: '3px 8px',
          marginRight: '16px',
          borderRadius: '6px',
          fontFamily: 'PingFang SC',
          fontWeight: '500',
          lineHeight: '18px',
          color: 'rgba(0, 0, 0, 0.72)',
          background: 'rgba(0, 0, 0, 0.04)',
          cursor: 'pointer',

          ['&:hover']: {
            background: 'rgba(255, 100, 0, 0.1)',
            color: '#FF6400',
          },
        },
        ['.checkFilter']: {
          background: 'rgba(255, 100, 0, 0.1)',
          color: '#FF6400',
        },

        ['.ec-base-select.debounce-select']: {
          transition: 'all 0.3s',

          ['.ant5-select-selector']: {
            border: 'none',
            boxShadow: 'none !important',
            background: 'rgba(0, 0, 0, 0.04)'
          },
          ['.ant5-select-arrow']: {
            height: '16px',
            transform: 'translateX(3px) translateY(-2px)'
          }
        },
      },
      ['.debounce-select-popup']: {
        minWidth: '220px',

        ['.ant5-select-item']: {
          padding: '6px 12px',
        },

        // 搜索下拉展示
        ['.searchItem']: {
          display: 'flex',

          // 头像
          ['.head']: {
            width: '24px',
            marginRight: '8px',
          },

          // 人
          ['.people']: {
            lineHeight: '16px',
            color: 'rgba(0, 0, 0, 0.84)',

            // 姓名
            ['.fullName']: {
              marginBottom: '4px',

              ['span:first-child']: {
                fontWeight: '500',
              },
            },

            // 人员信息
            ['.details']: {
              display: 'flex',
              alignItems: 'center',

              ['.line']: {
                margin: '0 6px',
                width: '1px',
                height: '12px',
                background: 'rgba(0, 0, 0, 0.84)',
              },
            },
          },
        },
      },
    },
  ]);
};

export default useStyle;
