import { LoadingOutlined } from '@ant-design/icons';
import { Tag, Tooltip, TreeSelect, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import newRequest from '../../utils/newRequest';
import useDebouncedCallback from '../../utils/useDebouncedCallback';
import CardIcon from '../CardIcon';
import CopyableTag from '../CopyableTag';

import useStyle from './style';



const DeptSelectTitle = ({data})=><Tooltip title={`${data.deptPath} (${data.deptCode}) ${data.deptLevelName}`}><div className="dhr-dept-title">
    <p className="dhr-dept-title-detail">{`${data.deptName} (${data.deptCode}) ${data.deptLevelName}`}</p>
    {data.managerLdap && <p className="dhr-dept-title-manager"><span>{data.managerName}</span><span> {`(${data.managerLdap})`}</span></p>}
    </div></Tooltip>;

const RecentSearch = ({searchList,delRecentSearch,choseThis})=>{
    const delThis = async (e,value,index)=>{
        e.preventDefault();
        delRecentSearch(value,index);
    }
    return <div className="tree-node-recent-search">
    <div className="tree-node-recent-search-title">最近搜索</div>
    <div className="tree-node-recent-search-content">
        {searchList.map((v,i)=>{return <Tooltip title={`${v.deptPath} (${v.deptCode}) ${v.deptLevelName}`} key={v.deptCode}><Tag closable onClose={(e)=>delThis(e,v,i)} onClick={()=>choseThis(v)}>{v.deptName}</Tag></Tooltip>})}
    </div>
</div>
}


// 主函数
const DeptSelect = (props)=>{
  const request = newRequest.getInstance();

  const [tree,setTree] = useState([])
  const [search,setSearch] = useState([])
  const [tooltipvisible,settooltipvisible]= useState(false)
  const [tooltipvalue,settooltipvalue] = useState(undefined)
  const [loading,setloading]= useState(true)
  const [treeExpandedKeys,settreeExpandedKeys] = useState([])
  const istreeOpen = useRef(false)
  const treenode = useRef(null)
  const preValue = useRef([])
  const searchMap = useRef(new Map())
  const extraMap = useRef(new Map())
  const { params = {}, attributes = {}, allowClear=true } = props;

  const filterOptionKeysFunction = useRef((inputValue,treeNode)=>{
    const lowerSearchValue = String(inputValue).toLowerCase();
    if (!lowerSearchValue) {
      return false;
    }
    if(/^[\d]+$/.test(lowerSearchValue) && lowerSearchValue.length < 6){
      return false;
    }
    return String(treeNode.filter).toLowerCase().includes(lowerSearchValue);
  })

  const getRecentSearch= async (props)=>{
    const res = await request({
      method: 'post',
      url: `/open/api/open/v1/visitHistory/getDeptVisitHistory`,
      data:{...props.params}
    });
    setSearch(res.data)
  }

  const dealLabel = (text="")=>{
    if(typeof text !=='string'){
      return text
    }
    let textarr = text.split(' (')
    if(!textarr.length){
      return text
    }
    let deptNode = textarr[0].split(">")
    if(deptNode.length > 2){
      return `${deptNode[0]}>...>${deptNode[deptNode.length-1]} (${textarr[1]}`
    }else{
      return text
    }
  }


  const dealWithData = (data)=>{
    let temData = []
    const renderNode = (nodeList,origin,parentKey)=>{
      nodeList.forEach(i=>{
        const {deptCode,deptLevelName,deptName,deptPath} = i
        const tempData = {deptCode,deptLevelName,deptName,deptPath}
        let templabel = props.labelKey?i[props.labelKey]:`${i.deptPath} (${i.deptCode}) ${i.deptLevelName}`
        let tem = {
          text: (!props.labelInValue && !props.multiple) && !props.labelKey? dealLabel(templabel) :templabel,
          value: i.deptCode,
          filter: `${i.deptName}${i.deptCode}${i.managerName}${i.managerLdap}`,
          label: <DeptSelectTitle data={i}/>,
          data: tempData,
          deptName: deptName,
          fullPath: `${i.deptPath} (${i.deptCode}) ${i.deptLevelName}`
        }
        if(i.childList){
          tem.children = []
          renderNode(i.childList,tem.children,`${parentKey?parentKey+(','):''}${i.deptCode}`)
        }
        tem.searchKey = parentKey?parentKey+(',') + deptCode:deptCode
        searchMap.current.set(tem.filter,tem.searchKey)
        extraMap.current.set(deptCode,tempData)
        origin.push(tem)
      })
    }
    renderNode(data,temData,false)
    return temData
  }

  useEffect(()=>{
    if (!props.params || !props.functionId) return;
    const getData= async ()=>{
      const res = await request({
        method: 'get', // post
        url: '/gateway/bricks/api/global/permissions/dept',
      });
      const dealwith = dealWithData(res.data)
      setTree(dealwith)
      setloading(false)
      if(props.afterLoading){
        props.afterLoading(dealwith)
      }
    }
    getData();
    if(!props.notShowRecentSearch){
      getRecentSearch(props);
    }
  },[props?.value])
  // },[props])

  const onVisibleChange = (visible)=>{
    if(istreeOpen.current && visible){
      return
    }
    settooltipvisible(visible)
  }

  const onDropdownVisibleChange = (open)=>{
    if(open){
      settooltipvisible(false)
    }else{
      settreeExpandedKeys([])
    }
    istreeOpen.current = open
  }

  const selectTree = async (value, label, extra)=>{
    let tempValue = value?JSON.parse(JSON.stringify(value)):null
    if(props.multiple){
      label = props.labelInValue?value.map(i=>i.label):label
    }else{
      const tempLabel = typeof extra === 'string'?extra:(extra?.triggerNode?.props?.fullPath || undefined)
      if(tempValue && props.labelInValue){
        tempValue.label = dealLabel(value.label)
        label = tempLabel
      }else if(tempValue){
        label = tempLabel
      }else{
        label = undefined
      }
    }
    settooltipvalue(label)
    preValue.current = value
    if(props.needExtra){
      let extraData = undefined
      if(tempValue){
        if(tempValue.length){
          extraData = tempValue.map(i=>extraMap.current.get(props.labelInValue?i.value:i))
        }else{
          extraData = [extraMap.current.get(props.labelInValue?tempValue.value:tempValue)]
        }
      }
      props.onChange(tempValue,extraData)
    }else{
      props.onChange(tempValue)
    }
  }

  const choseThis = (v)=>{
    let tempValue = v.deptCode
    let tempLabel = props.labelKey?v[props.labelKey]:`${v.deptPath} (${v.deptCode}) ${v.deptLevelName}`
    if(props.multiple){
      let haschosen = false
      if(props.labelInValue && props.value){
        let flattenValue = props.value.map(i=>i.value)
        if(flattenValue.includes(v.deptCode)){
          haschosen = true
        }
      }else if(props.value && props.value.includes(v.deptCode)){
        haschosen = true
      }
      if(haschosen){
        message.error('已选择')
        return
      }
      let newItem = props.labelInValue?[{value:v.deptCode,label:tempLabel}]:[v.deptCode]
      let newlabel = [tempLabel]
      tempValue = props.value ? props.value.concat(newItem):newItem
      tempLabel = tooltipvalue ?tooltipvalue.concat(newlabel):newlabel
      console.log(tempLabel)
    }else{
      if(props.value === v.deptCode){
        message.error('已选择')
        return
      }
      if(props.labelInValue){
        tempValue = {value:tempValue,label: !props.labelKey? dealLabel(tempLabel) :tempLabel}
      }
    }
    selectTree(tempValue,tempLabel, `${v.deptPath} (${v.deptCode}) ${v.deptLevelName}`)
    onSelect(v.deptCode, {data: v})
    if(!props.multiple){
      treenode.current.blur()
    }
  }

  const onSelect = async (value, node, extra)=>{
    const res = await request({
      method: 'post',
      url: `/open/api/open/v1/visitHistory/saveOrgVisitHistory`,
      data:{codeList:[value],functionId:props.functionId, ...params}
    });
    if(res){
      const {deptCode,deptLevelName,deptName,deptPath}=node.data
      let tempSearch = search.filter(i=> i.deptCode !== deptCode)
      tempSearch.unshift({deptCode,deptLevelName,deptName,deptPath})
      setSearch(tempSearch)
    }
  }

  const onTreeExpand = (exendKey)=>{
    settreeExpandedKeys(exendKey)
  }


  const SearchExend = (input)=>{
    let searchList = Array.from(searchMap.current, ([name]) => (name));
    let searchKeyExend = []
    searchList.forEach(i=>{
      const lowerSearchValue = String(input).toLowerCase();
      if(String(i).toLowerCase().includes(lowerSearchValue)){
        let thisExend = searchMap.current.get(i)
        searchKeyExend = searchKeyExend.concat(thisExend?thisExend.split(','):[])
      }
    })
    settreeExpandedKeys(Array.from(new Set(searchKeyExend)))
  }
  const debounceSearchExend = useDebouncedCallback(SearchExend,500)

  const onSearch = (v)=>{
    if(!v){
      settreeExpandedKeys([])
    }else{
      debounceSearchExend(v)
    }
  }

  const delRecentSearch = async (value,index)=>{
    const res = await request({
      method: 'post',
      url: `/open/api/open/v1/visitHistory/deleteOrgVisitHistory`,
      data:{codeList:[value.deptCode],functionId:props.functionId, ...params}
    });
    if(res){
      let tempSearch = search.filter(i=>i.deptCode!== value.deptCode)
      setSearch(tempSearch)
    }
  }

  const tagRender = (props)=>{
    const { label, value, closable, onClose } = props;
    if(!props.label){
      return null
    }
    const labeldeal = dealLabel(label)
    return (
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>{labeldeal}</Tag>
    );
  }
  const showSearch = !props.notShowRecentSearch && search.length;


  const prefixCls = 'dhr-dept-select';
  const wrapSSR = useStyle(prefixCls);


  return wrapSSR(
    <div className="dhr-dept-select">
      <CopyableTag tooltiplist={tooltipvalue} multiple={props.multiple} onVisibleChange={onVisibleChange} tooltipvisible={tooltipvisible} noCopyableTag={props.noCopyableTag}>
        <TreeSelect
          {...props}
          ref={treenode}
          allowClear={allowClear}
          style={{ width: '100%' }}
          loading={loading}
          disabled={loading && props.value || props.disabled}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="部门编号或名称/管理者姓名或邮箱前缀"
          showSearch
          treeNodeFilterProp="filter"
          filterTreeNode={filterOptionKeysFunction.current}
          treeNodeLabelProp="text"
          dropdownMatchSelectWidth={showSearch?540:320}
          maxTagCount={1}
          tagRender={tagRender}
          treeExpandedKeys={treeExpandedKeys}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onTreeExpand={onTreeExpand}
          getPopupContainer={trigger => trigger.parentNode}
          onSearch={onSearch}
          showArrow
          suffixIcon={
            loading ? <LoadingOutlined spin /> :<CardIcon type="icondropdown" className="form-filter-icon-down" />
          }
          maxTagPlaceholder={(v)=>{return <div>+{v.length}</div>}}
          dropdownRender={treenode => (<div className="dhr-dept-select-tree-node-container">
            <div className="tree-node-list">{treenode}</div>
            {showSearch ? <RecentSearch searchList={search} delRecentSearch={delRecentSearch} choseThis={choseThis}/>:null}
          </div>)}
          onChange={selectTree}
          onSelect={onSelect}
          treeData={tree}
          value={props.value}
          {...attributes}
        />
      </CopyableTag>
    </div>
  )
}

export default DeptSelect
