import { request } from '@dhr/common';
import { useRef, useState } from 'react';

export function useToGetData(url, params, keyItem, notAllowEmptyKey = false) {
  const [data, setData] = useState([]);
  const dataAll = useRef([]);
  const fetchId = useRef(0);
  const getData = async (data) => {
    fetchId.current += 1;
    const nowId = fetchId.current;
    const res = await request({
      method: 'get',
      url,
      params: data || params,
    });
    if (res.data && res.code === '100200') {
      let result = res.data.map((v) => {
        const defaultValue = {
          value: v[keyItem.valueKey],
        };
        if (typeof keyItem.labelKey === 'string') {
          defaultValue.label = v[keyItem.labelKey];
        } if (typeof keyItem.labelKey === 'function') {
          defaultValue.label = keyItem.labelKey(v);
        }
        if (keyItem.name) {
          defaultValue.name = v[keyItem.name];
        }
        if (keyItem.filter) {
          if (typeof keyItem.filter === 'string') {
            defaultValue.filter = v[keyItem.filter];
          } if (typeof keyItem.filter === 'function') {
            defaultValue.filter = keyItem.filter(v);
          }
        }
        return defaultValue;
      });
      if (notAllowEmptyKey) {
        result = result.filter((v) => !!v.value);
      }
      if (nowId === fetchId.current) {
        dataAll.current = result;
        setData(result);
      }
    }
    return res.data;
  };
  return [data, getData, setData, dataAll];
}

export function useToGetDeptData(url, params, keyItem, notAllowEmptyKey = false) {
  const [data, setData] = useState([]);
  const dataAll = useRef([]);
  const fetchId = useRef(0);
  const getData = async (data, init = false) => {
    fetchId.current += 1;
    const nowId = fetchId.current;
    let help = false;
    console.log(init);
    const res = await request({
      method: 'get',
      url,
      params: data || params,
    });
    if (res.data && res.code === '100200') {
      let result = res.data.map((v) => {
        const defaultValue = {
          value: v[keyItem.valueKey],
        };
        if (typeof keyItem.labelKey === 'string') {
          defaultValue.label = v[keyItem.labelKey];
        } if (typeof keyItem.labelKey === 'function') {
          defaultValue.label = keyItem.labelKey(v);
        }
        if (keyItem.name) {
          defaultValue.name = v[keyItem.name];
        }
        if (keyItem.tooltip) {
          if (typeof keyItem.tooltip === 'string') {
            defaultValue.tooltip = v[keyItem.tooltip];
          } if (typeof keyItem.tooltip === 'function') {
            defaultValue.tooltip = keyItem.tooltip(v);
          }
        }
        if (keyItem.filter) {
          if (typeof keyItem.filter === 'string') {
            defaultValue.filter = v[keyItem.filter];
          } if (typeof keyItem.filter === 'function') {
            defaultValue.filter = keyItem.filter(v);
          }
        }
        if (init && v.deptCode === init) {
          help = defaultValue.tooltip;
        }
        return defaultValue;
      });
      if (notAllowEmptyKey) {
        result = result.filter((v) => !!v.value);
      }
      if (nowId === fetchId.current) {
        dataAll.current = result;
        setData(result);
      }
    }
    console.log(help, '2333');
    return { data: res.data, help };
  };
  return [data, getData, setData, dataAll];
}

export function useQuery() {
  return new URLSearchParams(window.location.search);
}

export function useResetDataSources(...args) {
  let isObj = (typeof args[0] == 'object' ) && args[0].url
  let paramsObj = args[0] || {}
  if (!isObj) {
    const [url, name, keyItem, params = {}, method = 'get'] = args
    paramsObj = {url, name, keyItem, params, method}
  }
  const {url, name, keyItem, params = {}, method = 'get'} = paramsObj
  const fetchData = async (actions, FormPath) => {
    const param = {
      method,
      url,
    }
    if (method == 'get') {
      param.params = params
    } else {
      param.data = params
    }

    const res = await request(param);
    // 多个字段取值相同
    const names = name.join(',');
    actions.setFieldState(`*(${names})`, (state) => {
      FormPath.setIn(state, 'props.dataSource', res.data.map((v) => ({ label: v[keyItem.labelKey], value: v[keyItem.valueKey] })));
    });
  };
  return [fetchData];
}

export function useGetSort(sort) {
  if (!sort || !Object.keys(sort).length || !sort.order) return {};
  const { columnKey, field, order } = sort;
  const sortName = columnKey || field;
  const sortOrder = order === 'ascend' ? 'asc' : 'desc';
  return { [sortName]: sortOrder };
}

function checkCode(val) {
  const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/;
  const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  const code = val.substring(17);
  if (p.test(val)) {
    let sum = 0;
    for (let i = 0; i < 17; i++) {
      sum += val[i] * factor[i];
    }
    if (parity[sum % 11].toString() === code.toUpperCase().toString()) {
      return true;
    }
  }
  return false;
}

function checkDate(val) {
  const pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
  if (pattern.test(val)) {
    const year = val.substring(0, 4);
    const month = val.substring(4, 6);
    const date = val.substring(6, 8);
    const date2 = new Date(`${year}-${month}-${date}`);
    if (date2 && date2.getMonth() === (parseInt(month) - 1)) {
      return true;
    }
  }
  return false;
}

function checkProv(val) {
  const pattern = /^[1-9][0-9]/;
  const provs = {
    11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江 ', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北 ', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏 ', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门',
  };
  if (pattern.test(val)) {
    if (provs[val]) {
      return true;
    }
  }
  return false;
}

export function checkId(val) {
  if (checkCode(val)) {
    const date = val.substring(6, 14);
    if (checkDate(date)) {
      if (checkProv(val.substring(0, 2))) {
        return true;
      }
    }
  }
  return false;
}
