import React from 'react';
import { Tabs } from 'antd';
import './index.less';
import classNames from 'classnames';

function CustomBigTabs(props) {
  const newClassName = classNames('talent-market-custom-big-tabs', props?.className);
  return (
    <div className="talent-market-custom-big-tabs-wrap">
      <Tabs
        tabBarGutter={36}
        indicator={{
          size: origin => 28,
          align: 'center',
        }}
        {...props}
        className={newClassName}
      />
    </div>
  );
}

export default CustomBigTabs;
