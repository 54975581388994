import { useRegisterStyle, getLang } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-performance-records', () => [
    {
			// base
      [`.${prefixCls}`]: {
        minHeight: '270px',

        ['.performance-record-detail-info']: {
          fontFamily: 'PingFang SC',
          fontWeight: 400,
          paddingLeft: '24px',

          ['&>div']: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '12px',

            ['&>div']: {
              color: 'rgba(0, 0, 0, 0.72)',

              ['&:first-of-type']: {
                marginRight: '6px'
              },

              ['&:last-of-type::after']: {
                content: getLang() === 'en-US' ? '". "' : '"。"',
              },

              ['&:not(:last-of-type):not(:first-of-type)::after']: {
                content: getLang() === 'en-US' ? '", "' : '"，"',
                marginRight: getLang() === 'en-US' ? '4px' : '0px',
              },

              ['&>span']: {
                fontWeight: 600,
                color: '#1473FF',
                margin: '0 5px',
              },
            },
          }
        }
			}
		}
	]);
};

export default useStyle;
