import React, { useState, useEffect } from "react";
import { di18n } from "@ultra/common";
import noHrUrl from "../../images/no-hr-pc.png";
import noHrH5Url from "../../images/no-hr-h5.png";
import { getDeviceType } from '../../utils/Tool';
const { intl } = di18n;

import "./index.scss";

export default () => {
  const [imgUrl, setImgUrl] = useState("");
  const [data, setData] = useState("");
  const [pageType, setPageType] = useState('PC');

  useEffect(() => {
    const data = sessionStorage.getItem("mesData");
    const hrStatus = sessionStorage.getItem("surveyBusType_error");
    const deviceType = getDeviceType();
    //如果是非HR
    if (hrStatus === 'NON_PROFESSIONAL_SERVICES') {
      // h5的图片在顶部
      if (deviceType === 'H5') {
        setPageType(deviceType);
      } else {
        setImgUrl(noHrUrl);
      }
    } else {
      setImgUrl("//img-hxy021.didistatic.com/static/ehr_static/imgs/ct/error_bg.png");
    }
    setData(data);
  }, []);

  return (
    <div className="error-wrpper" style={{ background: `url(${imgUrl})` }}>
      {
        pageType === 'H5' && <img className="h5-img" src={noHrH5Url} />
      }
      <div className="center-content">
        <div className="img-content">
          <img src='//img-hxy021.didistatic.com/static/ehr_static/imgs/ct/no_data.png' />
        </div>
        <div className="world-content">
          {data || intl.t("请刷新链接，重新打开问卷")}
        </div>
        {
          data === '您不在本次参与调研的名单范围内' && (
          <div className="world-content">
          You are not on the participant list for this survey
          </div>
          )
        }
      </div>
    </div>
  );
};
