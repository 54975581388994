import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  // post请求示例
  getPergUnitManager: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.pergUnitManager}`, {
      method: 'post',
      data,
    });
  },
  // 初始化 bp
  postBp: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.bp}`, {
      method: 'post',
      data,
    });
  },
  // post 人员列表接口
  postSearch: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.search}`, {
      method: 'post',
      data,
    });
  },
  // post 筛选条件变更，结果区域调整
  postSearchAndDistribution: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.searchAndDistribution}`, {
      method: 'post',
      data,
    });
  },
  // post 获取部门
  postDept: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.postDept}`, {
      method: 'get',
      data,
    });
  },
  // post 柱状图接口
  postDistribution: async (host, data) => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`${host}/${apis.postDistribution}`, {
      method: 'post',
      data,
    });
  }
};

export default PageletServices;
