import lodash from 'lodash';
/** 获取隐藏题 ids */
/**
 * 获取题目的跳题配置信息
 * @param subject 题目信息
 * @returns 题目跳题信息
 */
const answerJump = subject => {
  const jumpMapping = new Map();
  if (!subject.jumpSubjects || subject.jumpSubjects.length === 0) {
    return jumpMapping;
  }
  subject.jumpSubjects.forEach(jumpConfig => {
    jumpConfig.triggerConditions.forEach(trigger => {
      if (!jumpMapping.has(trigger.triggerQuestionnaireOption)) {
        jumpMapping.set(trigger.triggerQuestionnaireOption, []);
      }
      jumpMapping.get(trigger.triggerQuestionnaireOption).push(trigger);
    });
  });
  return jumpMapping;
};
/**
 * 获取后续隐藏题
 * @param subject 跳题题目信息
 * @param subjectMap 完整的题目映射
 * @returns 后续所有的隐藏题题目id列表
 */
export const allHiddenSubject = (subject, subjectMap) => {
  const hideSubjects = [];
  const jumpMapping = answerJump(subject);
  jumpMapping.forEach(jumps => {
    jumps.forEach(jump => {
      const { jumpQuestionnaireSubjectId } = jump;
      if (!hideSubjects.includes(jumpQuestionnaireSubjectId)) {
        hideSubjects.push(jumpQuestionnaireSubjectId);
        const afterHideSubjects = allHiddenSubject(subjectMap[jumpQuestionnaireSubjectId], subjectMap);
        hideSubjects.push(...afterHideSubjects);
      }
    });
  });
  return hideSubjects;
};

/**
 * 处理跳题配置
 * @param subject 当前事件对应的题目信息
 * @param subjectMap 问卷上所有题目的映射
 * @param checkedOptionIds 当前题目前已经选择的题目信息
 * @param lastDraftOption 当前题目上次所选择的题目信息
 * @returns 需要隐藏和现实的题目列表   只返回新增的
 */
export const processJump = (subject, subjectMap, checkedOptionIds, lastDraftOption) => {
  const jumpMapping = answerJump(subject);
  const showSubjects = []; // string[]
  const allShowSubjects = []; // string[]
  let hideSubjects = []; // string[]
  jumpMapping.forEach((jumps, triggerOptionId) => {
    const checked = checkedOptionIds.includes(triggerOptionId);
    const lastChecked = lastDraftOption.includes(triggerOptionId);
    jumps.forEach(jump => {
      const { jumpQuestionnaireSubjectId } = jump;
      if (allShowSubjects.includes(jumpQuestionnaireSubjectId)) {
        return;
      }
      const afterHideSubjects = allHiddenSubject(subjectMap[jumpQuestionnaireSubjectId], subjectMap);
      if (checked) {
        allShowSubjects.push(jumpQuestionnaireSubjectId);
        // 本次选中 上次未选中, 需要增加隐藏题显示
        if (!lastChecked) {
          showSubjects.push(jumpQuestionnaireSubjectId);
        }
        // 从隐藏的题目列表中删除后续的隐藏题
        hideSubjects = hideSubjects.filter(e => e !== jumpQuestionnaireSubjectId && !afterHideSubjects.includes(e));
        // 本次未选中 上次选中,需要删除隐藏记录
      } else if (!checked && lastChecked) {
        hideSubjects.push(jumpQuestionnaireSubjectId);
        hideSubjects.push(...afterHideSubjects);
      }
    });
  });
  // return [hideSubjects, showSubjects]
  return hideSubjects;
};

/**
 * 获取隐藏题
 * @param generalMultiData 当前事件对应的题目信息
 * @param attributeData 问卷上所有题目的映射
 * @param currentSubject 当前题目前已经选择的题目信息
 * @param prevOptionIds 当前题目上次所选择的题目信息
 * @param currentRespondentCode 当前问卷所选择的被评估对象，360场景的单人问卷可以选择 某一个人进行评价的  这个就是 currentRespondentCode
 * @returns 需要隐藏的题目Ids   只返回新增的
 */
export const getHideSubjectIds = ({
  generalMultiData, attributeData, currentSubject, prevOptionIds,
  currentRespondentCode
}) => {
  // console.log('======generalMultiData', generalMultiData);
  // console.log('======attributeData', attributeData);
  // console.log('======currentSubject', currentSubject);
  // console.log('======currentRespondentCode', currentRespondentCode);
  // console.log('======prevOptionIds', prevOptionIds);

  let currentOption = [];
  const options = generalMultiData[currentRespondentCode][currentSubject] || [];
  currentOption = options.map(val => val.option);
  const currentData = attributeData[currentSubject];

  // 找到需要联动隐藏的id
  const hideSubjectIds = processJump(currentData, attributeData, currentOption, prevOptionIds);
  // console.log('======options', options);
  console.log('======hideSubjectIds', hideSubjectIds);
  const cloneData = lodash.cloneDeep(attributeData);
  hideSubjectIds.forEach(item => {
    cloneData[item].hidden = true;
  });

  return {
    hideSubjectIds,
    cloneData
  };
};
