import React from "react";
import RenderWidget from '../renderWidget';
const questionnaireOfPre = props => {
  const {
    renderBanner,
    renderFootRemark,
    renderFoot,
    pageDataAll,
    questionaireType
  } = props;

  // 渲染活动配置部分的问卷预览模板
  // 里面的组件由于与其他问卷模板公用，所以用父组件传进来的方法渲染
  return (
    <>
      <div className="question-main-banner-pc">
        {/* 渲染问卷头部 */}
        {renderBanner}
      </div>
      <div className="flex questionnaire-main">
        <div className="question-main-pc">
          {pageDataAll
            ? (
              <>
                <RenderWidget
                  pageDataAll={pageDataAll}
                  itemType={1}
                  multi={questionaireType}
                  renderType={2}
                />
              </>
            )
            : ''}
          {/* 渲染问卷卷首语 */}
          {renderFoot}
          {/* 渲染问卷底部说明 */}
          {renderFootRemark}
        </div>
      </div>
    </>
  );
};

export default questionnaireOfPre;
