import React, { useState, useEffect, useRef } from 'react';
import { di18n, CardIcon } from '@ultra/common';
import { Spin, Button } from 'antd';
import { getLocale, setLocale } from '@didi/di18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { StopOutlined } from '@ant-design/icons';

import {
  getCookie,
  getQuestionnaireGeneralFromSetting,
  groupRespondentAndContentId
} from '../../utils/Tool';
import request from '../../utils/request';
import MultipleOrganizes from '../../components/MultipleOrganizes';
import MultipleAvatars from '../../components/MultipleAvatars';
import './index.scss';
import NavigationTopGuide from '../../components/NavigationTopGuide';
import pcopen from '../../images/pcopen.png';
import RefuseComment from '../../components/RefuseComment';
import {
  changePortraits,
  changeRefuseTypeCode,
  changeRefuseTypeName,
  changeRefuseQuestionnaireId,
  changePortraitsPreview
} from '../../store/navigationStore/portraits';

const { intl } = di18n;
const { origin } = window.location;

export default function Navigations(props) {
  const { match: { params: { activityId, source, participantWorkNo } } } = props;
  const dispatch = useDispatch();
  const avatarRef = useRef(null);
  const [wrapWidth, setWrapWidth] = useState(window.innerWidth);
  const [participantCode, setParticipantCode] = useState('');
  const currentLangs = useRef('zh-CN');
  const {
    portraitData,
    refuseTypeCode,
  } = useSelector(state => state.portraits);
  const [title, setTitle] = useState('');
  const [langKeyList, setLangKeyList] = useState([0, 1]);


  // 获取匿名key, busType随便传的，后端王斌说只要不是一就行
  // 原接口：/api/anymous/encode
  const getKey = busType => request('/ctAnonymity/api/answer/anymous/encode', {
    method: 'POST',
    data: { encodeText: activityId, busType },
  });

  const changeLang = () => {
    const ctLang = getCookie('ct-lang');
    const lang = getLocale('lang');
    let currentLang = lang;
    if (ctLang) {
      if (lang !== ctLang) {
        setLocale(ctLang, {
          cookieLocaleKey: 'lang',
        });
        currentLang = ctLang;
      }
    }
    currentLangs.current = currentLang;
  };

  const init = async () => {
    let resData;
    if (participantWorkNo) {
      const { data } = await getQuestionnaireGeneralFromSetting(activityId, participantWorkNo);
      resData = data;
      resData.source = source;
      dispatch(changePortraitsPreview(resData));

    } else {
      const participantCodeData = await getKey(5);
      setParticipantCode(participantCodeData.data);
      const { data } = await groupRespondentAndContentId(activityId, participantCodeData.data);
      resData = data;
      if (resData?.activityStatus !== 1) {
        props.history.push('/activity/not/started');
        return;
      }
      resData.source = source;
      dispatch(changePortraits(resData));
    }

    setTitle(currentLangs.current === 'zh-CN' ? resData.activityName : resData.englishActivityName);
  };

  const components = {
    MultipleOrganizes: n => <MultipleOrganizes {...n} />,
    MultipleAvatars: n => <MultipleAvatars {...n} />,
  };

  const listenResize = () => {
    setWrapWidth(avatarRef.current.offsetWidth);
  };

  // 合并问卷时，根据未完成的respondentCode进入问卷页
  const toInfo = (questionnaireId, totalList, index) => {
    const respondentCode = totalList?.filter(item => item.recycleStatus === 0)?.[0]?.respondentCode;
    if (source === 'pc') {
      window.location.href = `${origin}/questionnaire/pc/${activityId}/${questionnaireId}/${respondentCode}/submit/${index}`;
    }
    if (source === 'h5') {
      window.location.href = `${origin}/questionnaire/h5/${activityId}/${questionnaireId}/${respondentCode}/${index}`;
    }
  };

  const toInfoPreview = item => {
    const { activityRespondents, groupIndex, respondentTypeCode } = item;
    if (source === 'pc') {
      window.location.href = `${origin}/questionnaire/pc/preview/setting/${activityId}/${participantWorkNo}/${activityRespondents[0]?.respondentCode}/${respondentTypeCode}/${groupIndex}`;
    }
    if (source === 'h5') {
      window.location.href = `${origin}/questionnaire/h5/preview/setting/${activityId}/${participantWorkNo}/${activityRespondents[0]?.respondentCode}/${respondentTypeCode}/${groupIndex}`;
    }
  }

  const toRefuse = async (code, name, refuseQuestionnaireId) => {
    const participantCodeData = await getKey(5);
    setParticipantCode(participantCodeData.data);
    if (participantCodeData.code === '100200') {
      dispatch(changeRefuseQuestionnaireId(refuseQuestionnaireId));
      dispatch(changeRefuseTypeName(name));
      dispatch(changeRefuseTypeCode(code));
      if (source === 'h5') {
        console.log(portraitData.reasonType, refuseQuestionnaireId)
        props.history.push(`/h5RefuseComment/${activityId}/${code}/${portraitData.reasonType}/${refuseQuestionnaireId}`);
      }
    }
  };
  // 清空所有的数据浏览历史
  const clearHistoryState = () => {
    window.history.replaceState(null, '', null)
  }

  useEffect(() => {
    // 数据如果赋值，证明dom构建完成，处理计算展示条数(h5合并问卷人员组件单用)
    if (portraitData) {
      setWrapWidth(avatarRef.current.offsetWidth);
      window.addEventListener('resize', listenResize);
      return () => {
        window.removeEventListener('resize', listenResize);
      };// 这里需要使用defaultConfig, 否则会丢失属性
    }
    return '';
  }, [portraitData]);

  useEffect(() => {
    // 初始化对 lang 处理
    changeLang();
    init();
    clearHistoryState()
  }, []);

  return (
    <div className="page-warpper">

      <Spin spinning={!portraitData} wrapperClassName="w-full h-full" style={{ height: '100%' }}>
        {
          portraitData
            ? (
              <div style={{
                backgroundColor: '#FFFDFB',
                minHeight: '100vh',
              }}
              >
                <div ref={avatarRef} className="ct-questionnaire-portraits-ref" />
                {/* 顶导 */}
                <NavigationTopGuide source={source} title={title} questionDes="" langKeyList={langKeyList} />
                {/* 拒评 */}
                {!!refuseTypeCode && source === 'pc'
                  && (
                    <RefuseComment
                      activityId={activityId}
                      source={source}
                      participantCode={participantCode}
                      reasonType={portraitData.reasonType}
                    />
                  )}
                <div className="ct-questionnaire-portraits-wrap">
                  {
                    source === 'h5' && <div className="ct-questionnaire-portraits-title">{intl.t('总览')}</div>
                  }
                  {
                    portraitData.respondentGroup.length
                      ? portraitData.respondentGroup.map(item => {

                        const { respondentTypeDesc, totalList, completeNum } = item;
                        // 渲染单人问卷、合并问卷人头导航
                        const comp = components[item.renderType];
                        return (
                          // 角色组
                          <div className="ct-questionnaire-portraits-groups" key={item.respondentTypeCode}>
                            <div className="ct-questionnaire-portraits-groups-header">
                              <span className="portraits-groups-header-typedesc">{respondentTypeDesc}</span>
                              {!participantWorkNo ?
                                <span className="portraits-groups-header-num">
                                  (
                                  {completeNum}
                                  /
                                  {totalList?.length}
                                  )
                                </span> : ''
                              }

                              {/* 已完成未完成 */}
                              {
                                !participantWorkNo ?
                                  <span
                                    className={totalList?.length === completeNum ? 'ct-questionnaire-portraits-groups-status-suc' : 'ct-questionnaire-portraits-groups-status-err'}
                                  >
                                    {source === 'pc' && (totalList?.length === completeNum ? <CardIcon type="iconwancheng" /> : <CardIcon type="iconweifaqi" />)}
                                    {totalList?.length === completeNum ? intl.t('已完成') : intl.t('未完成')}
                                  </span>
                                  : ''
                              }
                              <div style={{ marginLeft: 'auto', display: 'flex' }}>
                                {/* 拒评 */}
                                {
                                 !participantWorkNo ?
                                    <div
                                      className={`portraits-groups-${source}-refuse `}
                                      onClick={() => {
                                       toRefuse(item.respondentTypeCode, respondentTypeDesc, totalList?.length ? totalList[0].questionnaireId : '');
                                      }}
                                      type="primary"
                                      shape="circle"
                                    >
                                      {source === 'h5' && <StopOutlined />}
                                      {intl.t('拒评管理')}
                                    </div>
                                    : ''
                                }
                                {/* 去评价 */}
                                {source === 'pc' && (
                                  <Button
                                    className="portraits-groups-evaluate"
                                    disabled={participantWorkNo ? false : !(totalList?.length > completeNum)}
                                    onClick={() => {
                                      if (participantWorkNo) {
                                        toInfoPreview(item);
                                      } else {
                                        if (totalList?.length > completeNum) {
                                          toInfo(totalList[0].questionnaireId, totalList, item.groupIndex);
                                        }
                                      }
                                    }}
                                    type="primary"
                                    shape="circle"
                                  >
                                    {!participantWorkNo ? intl.t('去评价') : intl.t('查看问卷')}
                                  </Button>
                                )}
                              </div>

                            </div>

                            {/* 渲染人员、组织组件 */}
                            {
                              totalList?.length ? (
                                <div className="ct-questionnaire-portraits-groups-content">
                                  <div>
                                    {comp({
                                      totalList, wrapWidth, source, toInfo: participantWorkNo ? () => { toInfoPreview(item); } : toInfo, groupIndex: item.groupIndex, groupRecycleStatus: item.groupRecycleStatus,
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <div className={`portraits-${source}-pcopen`}><img src={pcopen} /></div>
                              )
                            }
                          </div>
                        );
                      })
                      : <div className={`portraits-${source}-pcopen`}><img src={pcopen} /></div>
                  }
                </div>
              </div>
            )
            : <div style={{ height: '100vh' }}></div>
        }
      </Spin>
    </div>
  );
}
