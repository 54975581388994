import React from 'react';
import { getLocale } from '@didi/ec-base';
import manifest from '../manifest.json';
import classNames from 'classnames';
import useStyle from './style/onboardingInfo';
import { ReactComponent as Onboarding } from './assets/onboarding.svg';

const localLocales = manifest.locales;

const isNull = str => {
  if (`${str}`.replace(/\s/gi, '') === '' || str === null || str === undefined) {
    return '-';
  }
  return str;
};

const OnboardingInfo = props => {
  const {
    params: { locales },
    data,
  } = props;

  const prefixCls = 'pagelet-inventory-onboarding-info';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  return wrapSSR(
    <div className={classes}>
      <div className="onboarding-title">
        <Onboarding />
        <div className="title-info">{locale.entryInfo}</div>
      </div>
      <div className="onboarding-content">
        <div className="single-info">
          <div className="single-title">{locale.currentHireDate}：</div>
          <div className="single-data">{isNull(data?.lastHireDate)}</div>
        </div>
        <div className="single-info">
          <div className="single-title">{locale.tenure}：</div>
          <div className="single-data">{isNull(data?.companyAge)}</div>
        </div>
        <div className="single-info">
          <div className="single-title">{locale.employeeClass}：</div>
          <div className="single-data">{isNull(data?.empClassDesc)}</div>
        </div>
        <div className="single-info">
          <div className="single-title">{locale.recruitmentType}：</div>
          <div className="single-data">{isNull(data?.recruitmentType)}</div>
        </div>
      </div>
    </div>,
  );
};

export default OnboardingInfo;
