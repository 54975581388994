import React, { useRef, useEffect, useState, useMemo } from 'react';

import './index.less';

function CustomTimeLineNineBox(props) {
  const {
    data: originData = {},
    scale = 1,
    setCurSelectAxis = () => {},
    setCurSelectBlock = () => {},
  } = props;
  const XAxis = useMemo(() => originData?.axisInfo?.x_axis?.segments || [], [originData]);
  const YAxis = useMemo(
    () => [...originData?.axisInfo?.y_axis?.segments]?.reverse() || [],
    [originData],
  );

  const gridBlocks = useMemo(() => originData?.gridBlocks, [originData]);
  const [choseIdx, setChoseIdx] = useState([-1, -1]);
  const isSmallDot = XAxis?.length > 5 || YAxis?.length > 5;

  useEffect(() => {
    if (!gridBlocks?.length) {
      return;
    }
    const defaultCheckedBlock = gridBlocks?.find(it => it?.blocked);
    setChoseIdx(() => [
      defaultCheckedBlock?.x_axisInfo?.axisId || -1,
      defaultCheckedBlock?.y_axisInfo?.axisId || -1,
    ]);
  }, [gridBlocks]);

  useEffect(() => {
    const [choseIdxXAxis, choseIdxYAxis] = choseIdx;
    if (choseIdxXAxis === -1 || choseIdxYAxis === -1) {
      setCurSelectBlock(() => -1);
      setCurSelectAxis(() => -1);
    }
    const curBlock = gridBlocks?.find(
      block =>
        block?.x_axisInfo?.axisId === choseIdxXAxis && block?.y_axisInfo?.axisId === choseIdxYAxis,
    );
    setCurSelectBlock(() => curBlock);
    setCurSelectAxis(() => [
      XAxis?.find(xAxis => xAxis?.axisId === choseIdxXAxis),
      YAxis?.find(yAxis => yAxis?.axisId === choseIdxYAxis),
    ]);
  }, [choseIdx]);

  const genCurDotStyle = ({ x, y }) => {
    const curBlock = gridBlocks?.find(
      block => block?.x_axisInfo?.axisId === x && block?.y_axisInfo?.axisId === y,
    );
    if (isSmallDot) {
      return {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: curBlock?.blockColor,
        '--chose-block-bgColor': curBlock?.selectedColor,
      };
    }
    return {
      width: '19px',
      height: '19px',
      borderRadius: '6px',
      backgroundColor: curBlock?.blockColor,
      '--chose-block-bgColor': curBlock?.selectedColor,
    };
  };

  const genClassName = ({ curXAxisId, curYAxisId, idx, idy }) => {
    if (choseIdx[0] === -1 && choseIdx[1] === -1 && idx === 0 && idy === 0) {
      return 'talent-market-custom-time-line-nine-box-cell-checked';
    }
    if (choseIdx[0] === curXAxisId && choseIdx[1] === curYAxisId) {
      return 'talent-market-custom-time-line-nine-box-cell-checked';
    }
    return '';
    // choseIdx[0] == -1 && choseIdx[1] === -1;
  };
  return (
    <div className="talent-market-custom-time-line-nine-box-cpn-wrap" style={{ zoom: scale }}>
      {YAxis?.map?.((ity, idy) => (
        <div className="talent-market-custom-time-line-nine-box-cpn-row">
          {XAxis?.map?.((itx, idx) => (
            <div
              // onClick={() => {
              //   setChoseIdx(() => [itx?.axisId, ity?.axisId]);
              // }}
              data-block-iloc={`${itx?.axisId}-${ity?.axisId}`}
              className={genClassName({
                curXAxisId: itx?.axisId,
                curYAxisId: ity?.axisId,
                idx,
                idy,
              })}
              style={{ ...genCurDotStyle({ x: itx?.axisId, y: ity?.axisId }) }}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default CustomTimeLineNineBox;
