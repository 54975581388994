import React, { useEffect, useState } from 'react';
import { getLocale, getLang, Tooltip, ConfigProvider } from '@didi/ec-base';
import { Intl, drawer } from '@didi/pagelets-sdk';
import {
  teamEmplValueMap,
  teamEmplOutsourcingLabelMap,
  teamEmplLabelMap,
  teamTitleMap,
  onBoardingTypeMap,
  onTheWayTypeMap,
  transferKeyMap,
  transferTitleMap,
  onTheWayTitleMap,
} from './constants';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';

import { ReactComponent as Arrow } from './assets/arrow.svg';
import useStyle from './style';

const localLocales = manifest.locales || {};

// 团队成员抽屉
const teamDrawer = drawer({
  title: '团队成员',
  // pageletUrl: '//127.0.0.1:9101/overview-members.runtime.js',
  pageletName: '@didi/pagelet-overview-members',
  pageletProps: {},
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    teamDrawer.hide();
  },
});

// 试用期人员
const probationDrawer = drawer({
  title: '试用期人员',
  // pageletUrl: '//127.0.0.1:9300/overview-probation.runtime.js',
  pageletName: '@didi/pagelet-overview-probation',
  pageletProps: {},
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    probationDrawer.hide();
  },
});

// 在途人数
const onthewayDrawer = drawer({
  title: '在途人数',
  // pageletUrl: '//127.0.0.1:9200/overview-ontheway.runtime.js',
  pageletName: '@didi/pagelet-overview-ontheway',
  pageletProps: { type: 'tomorrow', deptCodes: [] },
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    onthewayDrawer.hide();
  },
});

// 预计离职人员
const transferDrawer = drawer({
  title: '预计转入人员',
  // pageletUrl: '//127.0.0.1:9400/overview-transfer.runtime.js',
  pageletName: '@didi/pagelet-overview-transfer',
  pageletProps: { type: 'offBoarding', deptCodes: [] },
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    transferDrawer.hide();
  },
});

// 团队总览
const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, item, deptCodes, deptInfos, pageCode, headers },
  } = props;

  // 卡片信息
  const [overviewData, setOverviewData] = useState({});
  const [abnormal, setAbnormal] = useState(false); //是否展示异常页面

  // class
  const prefixCls = 'pagelet-team-overview';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');

  // 跳转组织
  const handleToOrg = () => {
    window.open(`${location.origin}/team`, '_blank');
  };

  // 正式员工、实习生、工时制外包
  const handleTeamDrawer = emplValue => {
    // emplValue => OUTSOURCING
    try {
      let label = '';
      let type = '';
      if (emplValue === teamEmplValueMap.OUTSOURCING) {
        type = 'OUT_HRO';
        label = teamEmplOutsourcingLabelMap['OUT_HRO'];
      } else {
        type = emplValue;
        label = intl(teamEmplLabelMap[emplValue]);
      }

      teamDrawer.show({
        dynamicDrawerProps: { title: intl(teamTitleMap[emplValue]) },
        dynamicPageletProps: {
          auth,
          headers,
          deptCodes,
          deptInfos,
          pageCode,
          emplValue: type,
          emplLabel: label,
          type: emplValue,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // 试用期人员
  const handleProbationDrawer = (evt, viewType) => {
    probationDrawer.show({
      dynamicPageletProps: {
        auth,
        headers,
        deptCodes,
        deptInfos,
        pageCode,
        defaultViewType: viewType,
      },
    });
    evt.stopPropagation();
  };

  // 在途人员、预计入职、预计离职
  const handleOnthewayDrawer = (evt, type, boardingType) => {
    onthewayDrawer.show({
      dynamicDrawerProps: { title: intl(onTheWayTitleMap[type]) },
      dynamicPageletProps: {
        auth,
        headers,
        deptCodes,
        deptInfos,
        pageCode,
        type,
        boardingType,
      },
    });
    evt.stopPropagation();
  };

  // 转入转出
  const handleTransferDrawer = transferKey => {
    transferDrawer.show({
      dynamicDrawerProps: { title: intl(transferTitleMap[transferKey]) },
      dynamicPageletProps: {
        auth,
        headers,
        deptCodes,
        deptInfos,
        pageCode,
        transferKey,
      },
    });
  };

  // 获取卡片数据
  const getOverviewData = async (auth, deptCodes) => {
    try {
      const authId = auth.id;
      const { data } = await PageletServices.getStatistics(
        apiHost,
        { deptCodes },
        {
          ...headers,
          functionId: authId,
          'bricks-function-id': authId,
        },
      );
      setOverviewData(data);
    } catch (e) {
      setAbnormal(true);
      console.log(e);
    }
  };

  // 接口请求示例, 部门变动后重新请求
  useEffect(() => {
    if (!auth?.id) return;
    getOverviewData(auth, deptCodes);
  }, [deptCodes, auth]);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <section className="title">
          <h3>{intl('TEAM_OVERVIEW')}</h3>

          <div className="title-org" onClick={handleToOrg}>
            <p>{intl('MY_ORG')}</p>
            <Arrow />
          </div>
        </section>
        {abnormal && (
          <div className="overview-abnormal">
            <img
              src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'}
              style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }}
            />
            <span>服务不可用，请稍候再试。</span>
          </div>
        )}

        {!abnormal && (
          <section className={classNames('content', { 'content-english': langEn })}>
            <div className="present">
              {/* 正式员工、实习生、工时制外包 */}
              <div className="present-employee">
                <div className="space" onClick={() => handleTeamDrawer(teamEmplValueMap.CTR)}>
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.employeeStatisticsVO?.onJobPermanentNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('FULL-TIME_EMPLOYEE')}</h4>
                          <span>
                            正式员工在岗人数，正式员工指员工性质为正式员工、劳务，不含实习生、外包人员
                          </span>
                        </div>
                      }
                    >
                      <p className="block-name">{intl('FULL-TIME_EMPLOYEE')}</p>
                    </Tooltip>
                  </div>
                </div>
                <div className="space" onClick={() => handleTeamDrawer(teamEmplValueMap.INT)}>
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.employeeStatisticsVO?.onJobTraineeNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('INTERN')}</h4>
                          <span>实习生在岗人数</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('INTERN')}>
                        {intl('INTERN')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space"
                  onClick={() => handleTeamDrawer(teamEmplValueMap.OUTSOURCING)}
                >
                  <div className="block">
                    <p className="block-number outsourcing">
                      {overviewData?.outsourcingStatisticsVO?.onJobManhourNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('HOURLY_OUTSOURCING')}</h4>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('HOURLY_OUTSOURCING')}>
                        {intl('HOURLY_OUTSOURCING')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* 试用期人数 */}
              <div className="present-probation">
                <div className="space" onClick={evt => handleProbationDrawer(evt, 1)}>
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.probationStatisticsVO?.allQuantity}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('PROBATION_PERIOD')}</h4>
                          <span>尚未转正的在职员工。</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('PROBATION_PERIOD')}>
                        {intl('PROBATION_PERIOD')}
                      </p>
                    </Tooltip>
                  </div>
                  {overviewData?.probationStatisticsVO?.allQuantity > 0 && (
                    <div className="block-expand">
                      <Tooltip
                        placement="left"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('PENDING')}</h4>
                            <span>展示所有待您处理的团队试用期流程</span>
                          </div>
                        }
                      >
                        <p onClick={evt => handleProbationDrawer(evt, 2)}>
                          <span title={intl('PENDING')}>{intl('PENDING')}：</span>
                          {overviewData?.probationStatisticsVO?.pendingQuantity}
                        </p>
                      </Tooltip>
                      <Tooltip
                        placement="left"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('RISK')}</h4>
                            <span>
                              展示团队内处于试用期且中期/转正评估结果为不合格/不同意转正的成员
                            </span>
                          </div>
                        }
                      >
                        <p onClick={evt => handleProbationDrawer(evt, 3)}>
                          <span title={intl('RISK')}>{intl('RISK')}：</span>
                          {overviewData?.probationStatisticsVO?.riskQuantity}
                        </p>
                      </Tooltip>
                      <Tooltip
                        placement="left"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('TIMEOUT')}</h4>
                            <span>展示所有进行中且相应审批节点已超时未审批的流程</span>
                          </div>
                        }
                      >
                        <p onClick={evt => handleProbationDrawer(evt, 4)}>
                          <span title={intl('TIMEOUT')}>{intl('TIMEOUT')}：</span>
                          {overviewData?.probationStatisticsVO?.timeOutQuantity}
                        </p>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="ongoing">
              {/* 在途人数、预计转入、已入职/预入职 */}
              <div className="ongoing-entry">
                <div
                  className="space ontheway"
                  onClick={evt =>
                    handleOnthewayDrawer(evt, onTheWayTypeMap.tomorrow, onBoardingTypeMap.all)
                  }
                >
                  <div className="block">
                    <p className="block-number">{overviewData?.entryStatisticsVO?.onTheWayNum}</p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('IN_TRANSIT')}</h4>
                          <span>社招在途和校招在途的总人数</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('IN_TRANSIT')}>
                        {intl('IN_TRANSIT')}
                      </p>
                    </Tooltip>
                  </div>
                  {/* {overviewData?.entryStatisticsVO?.onTheWayNum > 0 && (
                    <div className="block-expand">
                      <Tooltip
                        placement="right"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('SOCIAL')}</h4>
                            <span>
                              offer审批中或offer发放状态为未发送、已发送、已确认、未到岗，且入职日期在今天之后的社招人数
                            </span>
                          </div>
                        }
                      >
                        <p
                          onClick={evt =>
                            handleOnthewayDrawer(
                              evt,
                              onTheWayTypeMap.tomorrow,
                              onBoardingTypeMap.social,
                            )
                          }
                        >
                          <span title={intl('SOCIAL')}>{intl('SOCIAL')}：</span>
                          {overviewData?.entryStatisticsVO?.socialNum}
                        </p>
                      </Tooltip>
                      <Tooltip
                        placement="right"
                        title={
                          <div className="tooltip-title">
                            <h4>{intl('SCHOOL')}</h4>
                            <span>已确认offer且入职日期在今天之后的校招人数</span>
                          </div>
                        }
                      >
                        <p
                          onClick={evt =>
                            handleOnthewayDrawer(
                              evt,
                              onTheWayTypeMap.tomorrow,
                              onBoardingTypeMap.campus,
                            )
                          }
                        >
                          <span title={intl('SCHOOL')}>{intl('SCHOOL')}：</span>
                          {overviewData?.entryStatisticsVO?.campusNum}
                        </p>
                      </Tooltip>
                    </div>
                  )} */}
                </div>
                <div
                  className="space transfer-in"
                  onClick={() => handleTransferDrawer(transferKeyMap.into)}
                >
                  <div className="block">
                    <p className="block-number">{overviewData?.transferStatisticsVO?.transferIn}</p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('TRANSFER_IN')}</h4>
                          <span>
                            调动流程审批状态为【审批中】或审批状态为【已完成】，
                            预计调动日期为当前日期及以后的转入人数（从外部门转到所在部门）。
                          </span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('TRANSFER_IN')}>
                        {intl('TRANSFER_IN')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space onboarding"
                  onClick={evt => handleOnthewayDrawer(evt, onTheWayTypeMap.today, null)}
                >
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.entryStatisticsVO?.todayHiredNum}
                      <span>/{overviewData?.entryStatisticsVO?.todayNum}</span>
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('ONBOARD')}</h4>
                          {/* <span>社招在途和校招在途的总人数</span> */}
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('ONBOARD')}>
                        {intl('ONBOARD')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* 预计转出、预计离职 */}
              <div className="ongoing-depart">
                <div
                  className="space transfer-out"
                  onClick={() => handleTransferDrawer(transferKeyMap.out)}
                >
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.transferStatisticsVO?.transferOut}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('TRANSFER_OUT')}</h4>
                          <span>
                            调动流程审批状态为【已完成】，
                            预计调动日期为当前日期及以后的转出人数（从所在部门转到外部门）。
                          </span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('TRANSFER_OUT')}>
                        {intl('TRANSFER_OUT')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="space off-boarding"
                  onClick={evt => handleOnthewayDrawer(evt, onTheWayTypeMap.offBoarding, null)}
                >
                  <div className="block">
                    <p className="block-number">
                      {overviewData?.leaveStatisticsVO?.expectedLeaveNum}
                    </p>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        <div className="tooltip-title">
                          <h4>{intl('ATTRITION')}</h4>
                          <span>员工状态为预计离职的正式员工数量。</span>
                        </div>
                      }
                    >
                      <p className="block-name" title={intl('ATTRITION')}>
                        {intl('ATTRITION')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </ConfigProvider>,
  );
};

export default Pagelet;
