import React, { useContext } from 'react';
import { Tooltip, Avatar, Spin } from '@didi/ec-base';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoData from '../NoData';
import { PublicStore, handleJumpUrl, recordTrackEvent } from '../../../../utils/utils';
import PageletServices from '../../../../services'; //页面请求方法
import classNames from 'classnames';
import useStyle from './styles';

const PersonList = () => {
  // 样式
  const prefixCls = 'person-list';
  const wrapSSR = useStyle('person-list');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const {
    distributionName, list, assessmentInfo,
    hasMore, searchType, distributionCode, searchLoading, pageNum
  } = stateStore;
  const {
    params: { apiHost, deptCodes = [] },
  } = props;
  // 上拉加载数据
  const loadMoreData = async () => {
    if (searchLoading) {
      return;
    }
    stateDispatch({ searchLoading: true });
    try {
      const { data } = await PageletServices.postSearch('', {
        tabType: 'MANAGE',
        pageNum: Number(pageNum + 1),
        pageSize: Number('10'),
        deptCodes: deptCodes,
        progressDistributionCode: distributionCode,
        searchType,
      });
      stateDispatch({
        searchLoading: false,
        pageNum: data.pageNum,
        list: [...list, ...data.list],
        hasMore: [...list, ...data.list].length < data?.total,
      });
    } catch (e) {
      stateDispatch({ searchLoading: false });
      console.log(e);
    }
  };

  // 跳转到详情页
  const jumpTo = (item) => {
    if (searchType === 'MULTI_CHILD') {
      if (distributionCode === 'BEFORE_SELF_EVALUATION') {
        return;
      }
      const { assessmentId, ldap, performanceId, currentPerfStateInfo, empId, virtualState, isMultiEvaluateSubmit } = item;
      const { periodType } = assessmentInfo;
      const isWhat = isMultiEvaluateSubmit ? '2' : '1';
      const paramsObj = {
        periodType,
        assessmentId,
        ldap,
        performanceId,
        state: currentPerfStateInfo?.stateCode,
        targetEmpId: empId,
        periodId: assessmentId,
        curTabType: 'MANAGE_TEAM',
        allowVirtual: virtualState === 'UNDER_VIRTUAL' ? isWhat : '2'
      };
      const qs = handleJumpUrl(paramsObj, '&management=1&scene=2');
      const url = `/new/perf/overallEvaluation/endMultiEvaluation?${qs}`;
      window.open(`${apiHost}${url}`, '_blank');
      if (apiHost.includes('o.didichuxing.com')) {
        recordTrackEvent(); // 记录埋点
      }
    } else {
      const { ldap, performanceId, empId, currentPerfStateInfo } = item;
      const { periodType, assessmentId } = assessmentInfo;
      // 查看详情的url参数
      const params = {
        // 周期字段
        periodType,
        periodId: assessmentId,
        assessmentId,
        // 人身字段
        ldap,
        performanceId,
        state: currentPerfStateInfo?.stateCode,
        targetEmpId: empId,
        // 固定值
        curTabType: 'MANAGE_TEAM', // 当前选中tab，通过url传给详情页，判断按钮展示逻辑
      };
      const url = `/new/perf/overallEvaluation/endManagerEvaluation?${handleJumpUrl(params, '')}`
      window.open(`${apiHost}${url}`, '_blank');
      if (apiHost.includes('o.didichuxing.com')) {
        recordTrackEvent(); // 记录埋点
      }
    }
  };

  return wrapSSR(
    <div className={classes}>
      <p className="title">{distributionName}:</p>
      {
        list?.length === 0
          ? <NoData text='暂无数据' style={{ minHeight: '200px', paddingTop: '45px', background: 'rgba(245, 245, 245, 0.6)' }} />
          : <div className="personScroll" id="scrollableDiv">
            <InfiniteScroll
              height={200}
              dataLength={list?.length}
              next={loadMoreData}
              hasMore={hasMore}
              loader={<Spin />}
              endMessage={null}
              scrollableTarget="scrollableDiv"
            >
              {list?.map(item => {
                const { performanceId, name, empId, tagCodes, avatar } = item;
                const tagsState = tagCodes.length > 0;
                return (
                  <Tooltip title={`${name} ${empId}`}>
                    <div
                      className="personItem"
                      key={performanceId}
                      style={{ alignItems: tagsState ? 'start' : 'center' }}
                      onClick={() => jumpTo(item)}
                    >
                      <div className="head">
                        <Avatar
                          size='small'
                          src={avatar || "//img-ys011.didistatic.com/static/ddo_web_static/do1_uSZhhctpi1afbFsNH8WL"}
                        />
                      </div>
                      <div className="personName">
                        <p className="name">
                          <span>{name}</span>
                          <span>{empId}</span>
                        </p>
                        {tagsState && (
                          <div className="tag">
                            {tagCodes.includes('FINISH_MODIFY') && <span className="operat">已上评修改</span>}
                          </div>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </InfiniteScroll>
          </div>
      }
    </div>
  );
};

export default PersonList;
