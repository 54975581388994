import React, { useState, useEffect, useRef } from 'react';
import { Spin, Table, Tooltip, message, Button, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import { Avatar, CardIcon, EmptyRender } from '@dhr/common';
import PageletApi from '@didi/pagelets-sdk';
import lodash from 'lodash';
import PageletServices from '../../services';
import Donut from './Donut';
import { TopCharts } from '../Charts';
import NoData from '../NoData';
import ExportButton from '../ExportButton';

// 水波图字段及颜色
const percent = {
  greaterThan10: {
    name: '> 10天',
    color: '#FF932F'
  },
  between5_10: {
    name: '5-10天',
    color: '#4CC4EC'
  },
  lessThan5: {
    name: '< 5天',
    color: '#52DDBD'
  }
}

const NotUsed = (props) => {
  const { apiHost, lang, deptCodes, searchType, page, activeTab, clear,
    onSetPage=()=>{}, pageType, maxHeight, close = () => { }, headers, auth } = props;
  const [loading, setLoading] = useState(false);
  const [liquidList, setLiquidList] = useState([]);//全年剩余可休年假总览人数占比
  const [topChartsList, setTopChartsList] = useState([]); // 剩余年假天数Top5
  const [dataSource, setDataSource] = useState({});
  const [allBalance, setAllBalance] = useState(null);
  const [params, setParams] = useState({});
  const [sorter, setSorter] = useState({ orderByField: 1, reverseOrder: true });
  const getUsableData = async () => {
    const paramsData = {
      pageNum: page?.pageNum,
      pageSize: page?.pageSize,
      reverseOrder: sorter?.reverseOrder,
      orderByField: sorter?.orderByField,
      searchType,
      pageType,
      deptCodes
    }
    if(lodash.isEqual(params, paramsData) && !clear){
      return;
    }
    setParams(paramsData);
    setLoading(true);
    await PageletServices.getUsableTeam(apiHost, paramsData).then((res) => {
      const { usableList, usableTotal } = res?.data;
      if (!usableList && !usableTotal) {
        setLoading(false);
        setTopChartsList([]);
        setAllBalance(usableTotal?.allBalance);
        setDataSource({ list: [], total: 0 });
        return;
      }
      let list = [];
      if (usableTotal) {
        list = [
          {
            ...percent.greaterThan10,
            value: usableTotal?.greaterThan10?.name,
            percent: usableTotal?.greaterThan10?.code
          },
          {
            ...percent.between5_10,
            value: usableTotal?.between5_10?.name,
            percent: usableTotal?.between5_10?.code
          },
          {
            ...percent.lessThan5,
            value: usableTotal?.lessThan5?.name,
            percent: usableTotal?.lessThan5?.code
          }
        ];
      }
      setLiquidList(list);
      usableTotal?.holidayBalanceList?.map(item => {
        item.sort = item.annualLeaveBalanceDays;
        item.annualLeaveBalanceDays -= item.annualLeaveDueSoonDays;
      })
      setTopChartsList(usableTotal?.holidayBalanceList);
      setAllBalance(usableTotal?.allBalance);
      setDataSource({ list: usableList?.list, total: usableList?.total })
      setLoading(false);
    }, () => {
      setLoading(false);
    })
  }
  useEffect(() => {
    if (activeTab === 'noHoliday' && deptCodes?.length > 0 && searchType && pageType && sorter && page ) {
      getUsableData();
    }
  }, [deptCodes, searchType, pageType, page, sorter, clear])
  const handleDrawer = (empId) => {
    const drawer = PageletApi.drawer({
      title: '员工休假详情',
      width: '600px',
      pageletName: '@didi/pagelet-employee-leave-details',
      // pageletUrl: '//127.0.0.1:9004/employee-leave-details.runtime.js', // 使用pageletUrl
      pageletProps: { empId, headers, auth }, // 透传给pagelet的props
      onClose: () => {
        // 点击关闭
        drawer.destroy();
      }
    });
    drawer.show();
  }

  const sorterList = (pagination, filters, sorter) => {
    if (sorter.order) {
      setSorter({ orderByField: sorter?.column?.sorter, reverseOrder: sorter?.order === 'ascend' ? true : false })
    } else {
      setSorter({ orderByField: 1, reverseOrder: true })
    }
  }

  const noHoliday = [
    {
      title: '姓名',
      dataIndex: 'employeeName',
      width: 140,
      ellipsis: true,
      render: (text, records) => {
        return (
          <Tooltip title={`${records?.employeeName}(${records?.ldap})`}>
            <div className="detail name-ldap" onClick={() => handleDrawer(records?.employeeId)}>
              <Avatar src={records?.employeePhoto} size={32} />
              <span style={{ marginLeft: '4px' }}>{`${records?.employeeName}(${records?.ldap})`}</span>
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: '工号',
      dataIndex: 'employeeId',
      width: 60,
      ellipsis: true
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>全年</div>
        <div>剩余可休年假</div>
      </>,
      dataIndex: 'annualLeaveBalanceDays',
      width: 75,
      sorter: 1
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>年底</div>
        <div>即将到期年假</div>
      </>,
      dataIndex: 'annualLeaveDueSoonDays',
      width: 75,
      sorter: 2
    },
    {
      title: '年假总额度',
      dataIndex: 'anualLeaveTotalDays',
      width: 70,
      sorter: 9
    },
    {
      title: '未休占比',
      dataIndex: 'anualLeaveUnusedRate',
      width: 60,
      sorter: 3,
      render: text => `${parseFloat((text * 100).toPrecision(12))}%`
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>全年</div>
        <div>剩余全薪病假</div>
      </>,
      dataIndex: 'fullSalaryBlanceDays',
      width: 75,
      sorter: 4
    }
  ]
  return (
    <>
    <ConfigProvider locale={lang !== 'zh-CN' ? enUS : zhCN} prefixCls='ant5' theme={{ token: { colorPrimary: '#ff6400' } }} >
      <Spin spinning={loading}>
        {
          <div className="holiday-not-used-page" style={{ height: maxHeight, overflow: 'hidden', overflowY: 'scroll' }}>
            <div className="holiday-charts">
              {/* 总览 */}
              <div className="all-charts">
                <div className="title">全年剩余可休年假总览(人数占比)</div>
                {
                  liquidList.length > 0 ?
                    <Donut data={liquidList} />
                    : <NoData />
                }
              </div>

              <TopCharts type={'noHoliday'} chartsData={topChartsList} />
            </div>
            <div className="holiday-total-year-detail">
              <div className="holiday-total-year-detail-title">全年未休年假、病假明细</div>
              <div className="total-days-export">
                <span className="holiday-total-year-detail-days">团队全年剩余年假总天数
                  <span className="holiday-total-year-detail-total-days"> {allBalance} </span>天</span>
                <span className="holiday-diver"></span>
                {dataSource?.list?.length > 0 ? (
                  <ExportButton
                    url="/gateway/bricks/api/unit/holiday/team/usable/export"
                    data={{
                      fileName: " 团队未休假汇总",
                      export: 1,
                      ...params,
                    }}
                    encryption={true}
                    defaultFile=" 团队未休假汇总"
                    text={<><CardIcon type="icon-file-export-o" /> 导出</>}
                    closeDrawer={close}
                    headers={headers}
                  // showEmployeeCare={showEmployeeCare}
                  >
                  </ExportButton>
                ) : (
                  <Button className="disabled-btn" disabled={true}><CardIcon type="icon-file-export-o" /> 导出 </Button>
                )}
              </div>
            </div>
            <div className="holiday-detail-table-wrapper">
              <EmptyRender>
                <Table
                  dataSource={dataSource.list}
                  columns={noHoliday}
                  rowKey={record => record.id}
                  scroll={{ x: 900 }}
                  showSorterTooltip={false}
                  onChange={sorterList}
                  size="small"
                  pagination={{
                    total: dataSource.total,
                    current: page.pageNum,
                    pageSize: page.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50],
                    onChange: (current, size) => {
                      onSetPage({
                        pageNum: current,
                        pageSize: size
                      });
                    }
                  }}
                />
              </EmptyRender>

            </div>
          </div>
        }
      </Spin>
    </ConfigProvider>
      {/* <HolidayHistory
        closeDrawer={() => setVisible(false)}
        visible={visible}
        empId={empId}
      /> */}
    </>
  )
}

export default NotUsed;
