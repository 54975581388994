import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 工作关系
 * url: /api/archive/work-relationship
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getWorkRelationshipInfo = (apiHost, headers, params, usePermissionResponse) => request(`${apiHost}${apis.getWorkRelationshipInfo}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);

/**
 * 工作关系-弹出框
 * url: /api/archive/didiWorkRelationshipInfoDetail
 */
export const getDidiWorkRelationshipInfoDetail = (apiHost, headers, params, usePermissionResponse) => request(`${apiHost}${apis.getRelationshipDetail}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);
