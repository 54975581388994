import React, { useEffect, useState } from 'react';
import { getLocale, Modal, ConfigProvider } from '@didi/ec-base';
// import { Intl} from '@didi/pagelets-sdk';
import {
  BankCardModal, PersonalModal, PersonalContactInfoModal, EmergencyContactInfoModal, FamilyInfoModal, LanguageInfoModal, WorkInfoModal
} from '@dhr/ei';
import { message } from 'antd';
import { BUSINESS_TYPE_CODE } from './utils/utils';
import PageletApi from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import useStyle from './style';
import Edit from './assets/edit.svg';
import { RightThickOutlined } from '@didi/iconflow/ec';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';

const localLocales = manifest.locales || {};
const Pagelet = props => {
  const prefixCls = 'pagelet-personal-ei';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  // const intl = Intl(locale);
  const classes = classNames(prefixCls);

  const {
    params: { apiHost, locales, headers = {}, auth },
  } = props;
  const [eiList, setEiList] = useState([]);
  const [confirmLoading, setconfirmLoading] = useState(false);
  const [item, setItem] = useState({});
  const [type, setType] = useState(null);
  const [initError, setInitError] = useState(false);
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth?.id,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(auth?.functionName)
  }
  PageletApi.newRequest.init(newHeaders, 'personal-ei');

  const getPersonalInfo = () => {
    PageletServices.initPersonalEi(apiHost, {}).then((res) => {
      if (res?.code === '100200') {
        setEiList(res?.data);
      } else {
        setInitError(true);
      }
    }, () => {
      setInitError(true);
    }).catch(()=>{
      setInitError(true);
    });
  }
  useEffect(() => {
    getPersonalInfo();
  }, []);

  const openEditModal = (value) => {
    setItem(value);
    setType(value?.businessCode);
  }
  // 弹窗确认
  const handleOnSave = (info, type) => {
    if(type !== BUSINESS_TYPE_CODE.workMainInfo){
      setType(null);
    }
    setconfirmLoading(true);
    PageletServices.saveStaffInfo(apiHost, info).then((res) => {
      setconfirmLoading(false);
      if (res?.code === '100200' && res?.data) {
        Modal.success({
          content: <span dangerouslySetInnerHTML={{ __html: res.data }} />,
          onOk() {
            getPersonalInfo()
          },
          okText: '知道了',
        });
        // setEiList(res?.data);
      }
      if(type === BUSINESS_TYPE_CODE.workMainInfo){
        setType(null);
      }
    }, (error) => {
      message.error({
        content: `${error?.message}`,
      });
      setconfirmLoading(false);
    });
  };
  // 弹窗取消
  const handleOnCancel = () => {
    setType(null);
  };
  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <DHRCardTitle
          title="员工信息"
          showPrefixTag={false}
          type="grey"
          // titleAdded={
          //   <DHRCardButton onClick={() => {window.open(`${window.location.origin}/home/ei/staffinfo/detail`, '_blank')}}>
          //     更多信息 <RightThickOutlined cursor="pointer" size={14} className={`${prefixCls}-header-url-icon`}/>
          //   </DHRCardButton>
          // }
        />
        {!initError &&<div className={`${prefixCls}-content`}>
          {
            eiList?.length > 0 && eiList.map((v) => {
              return <div className="content-warp" onClick={() => {
                openEditModal(v)
              }}>
                <img src={v?.iconUrl} />
                <span>{v?.businessName}</span>
                {/* {v?.status && <div className="content-msg">{v?.status}</div>} */}
                <div className="content-edit">
                  <img src={Edit} alt="" />
                </div>
              </div>
            })
          }
        </div>}
        {initError && <div className={`${prefixCls}-content-err`}>
          <img
            src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
          />
          <span className='text-item-1'>服务不可用，请稍候再试。</span>
        </div>}
        {/* 编辑银行卡信息 */}
        <BankCardModal
          type='bankCardInfo'
          businessTypeCode={BUSINESS_TYPE_CODE.bankCardInfo}
          visiable={BUSINESS_TYPE_CODE.bankCardInfo === type}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          empId={item?.empId}
          confirmLoading={confirmLoading}
        />
        {/* 编辑个人信息 */}
        <PersonalModal
          type='personalInfo'
          businessTypeCode={BUSINESS_TYPE_CODE.personalInfo}
          visiable={BUSINESS_TYPE_CODE.personalInfo === type}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          empId={item?.empId}
          confirmLoading={confirmLoading}
        />
        {/* 本人联系方式 */}
        <PersonalContactInfoModal
          type='personalContactInfo'
          businessTypeCode={BUSINESS_TYPE_CODE.personalContactInfo}
          visiable={BUSINESS_TYPE_CODE.personalContactInfo === type}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          empId={item?.empId}
          confirmLoading={confirmLoading}
        />
        {/* 编辑紧急联系人 */}
        <EmergencyContactInfoModal
          type='emergencyContactInfo'
          businessTypeCode={BUSINESS_TYPE_CODE.emergencyContactInfo}
          visiable={BUSINESS_TYPE_CODE.emergencyContactInfo === type}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          empId={item?.empId}
          confirmLoading={confirmLoading}
          personalEmail={'D21144@qq.com'}
        />
        {/* 家庭成员 */}
        <FamilyInfoModal
          type='familyInfo'
          businessTypeCode={BUSINESS_TYPE_CODE.familyInfo}
          visiable={BUSINESS_TYPE_CODE.familyInfo === type}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          empId={item?.empId}
          needInit={item?.needInit}
          confirmLoading={confirmLoading}
        />
        {/* 外语能力 */}
        {/* <LanguageInfoModal
          type='languageInfo'
          businessTypeCode={BUSINESS_TYPE_CODE.languageInfo}
          visiable={BUSINESS_TYPE_CODE.languageInfo === type}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
          empId={item?.empId}
          needInit={item?.needInit}
          confirmLoading={confirmLoading}
        /> */}
        {/* 工作履历 */}
        <WorkInfoModal
          visiable={BUSINESS_TYPE_CODE.workMainInfo === type}
          onSave={(value)=>{handleOnSave(value, BUSINESS_TYPE_CODE.workMainInfo)}}
          onCancel={handleOnCancel}
          type="workMainFormVO"
          needInit={false}
          empId={item?.empId}
          businessTypeCode={BUSINESS_TYPE_CODE.workMainInfo}
          confirmLoading={confirmLoading}
          again={false}
          againInfo={{}}
        />
      </div>
    </ConfigProvider>

  );
};

export default Pagelet;
