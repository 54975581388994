import React from 'react';
import { Input, Form, Tooltip } from 'antd';
import { LangTranslate } from '@/utils';
function CustomLangObjInput(props) {
  const { value, namePath } = props;

  const form = Form.useFormInstance();
  const curLang = namePath?.[namePath?.length - 1];
  const zhCNVal = form?.getFieldValue?.([...namePath.slice(0, -1), 'zh_CN']);

  // console.log('CustomLangObjInput props', props);
  if (!value?.content?.length) {
    if (props?.readOnly) {
      return (
        <div className="label-system-Pagelet-change-langs-modal-pure-txt-field">
          <Tooltip placement="topLeft" title={LangTranslate('未填写')}>
            {LangTranslate('未填写')}
          </Tooltip>
        </div>
      );
    }
    if (!zhCNVal?.content?.length) {
      return <div className="label-system-Pagelet-change-langs-modal-blank-field">--</div>;
    }
  }
  const handleValChange = e => {
    const { value } = e.target;
    props?.onChange({ ...props?.value, content: value });
  };
  if (props?.readOnly) {
    return (
      <div className="label-system-Pagelet-change-langs-modal-pure-txt-field">
        <Tooltip placement="topLeft" title={props?.value?.content}>
          {props?.value?.content}
        </Tooltip>
      </div>
    );
  }
  return (
    <Input
      {...props}
      value={props?.value?.content}
      onChange={handleValChange}
      placeholder={zhCNVal?.content || undefined}
    />
  );
}

export default CustomLangObjInput;
