import { textFormat as renderData, timeFormat, tooltipFormat, deptFormat } from './formatFun';

export const InColumns = (intl) => [
  {
      title: intl('TRANSFER_EMPLOYEE_NAME'),
      dataIndex: 'transferEmpName',
      key: 'transferEmpName',
      ellipsis: true,
      width: 130,
      fixed: 'left',
      render:  (text, row) => tooltipFormat(`${text}（${row?.transferEmpLdap}）`)
  },
  {
      title: intl('TRANSFER_EMPLOYEE_JOB_NUMBER'),
      dataIndex: 'transferEmpId',
      key: 'transferEmpId',
      ellipsis: true,
      width: 110,
      fixed: 'left',
      render: renderData
  },
  {
      title: intl('OUT_DEPARTMENT_PATH'),
      dataIndex: 'beforeDeptPathName',
      key: 'beforeDeptPathName',
      ellipsis: true,
      width: 300,
      render: deptFormat
  },
  {
      title: intl('IN_DEPARTMENT_PATH'),
      dataIndex: 'afterDeptPathName',
      key: 'afterDeptPathName',
      ellipsis: true,
      width: 300,
      render: deptFormat
  },
  {
      title: intl('TRANSFER_MAIN_SCENE'),
      dataIndex: 'sceneName',
      key: 'sceneName',
      ellipsis: true,
      width: 110,
      render: renderData
  },
  {
      title: intl('EXPECTED_EFFECTIVE_DATE'),
      dataIndex: 'preEffectiveDate',
      key: 'preEffectiveDate',
      ellipsis: true,
      width: 110,
      render: timeFormat
  },
  {
      title: intl('PROMOTER_NAME'),
      dataIndex: 'applyEmpName',
      key: 'applyEmpName',
      ellipsis: true,
      width: 150,
      render: (text, row) => tooltipFormat(`${text}（${row?.applyEmpLdap}）`)
  },
  {
      title: intl('PROMOTER_JOB_NUMBER'),
      dataIndex: 'applyEmpId',
      key: 'applyEmpId',
      ellipsis: true,
      width: 90,
      render: renderData
  },
  {
      title: intl('APPROVAL_NUMBER'),
      dataIndex: 'approveBusinessCode',
      key: 'approveBusinessCode',
      ellipsis: true,
      width: 130,
      render: renderData
  },
  {
      title: intl('APPROVAL_STATUS'),
      dataIndex: 'approveStatusName',
      key: 'approveStatusName',
      ellipsis: true,
      width: 90,
      render: renderData
  }
]

export const OutColumns = (intl) => [
  {
      title: intl('TRANSFER_EMPLOYEE_NAME'),
      dataIndex: 'transferEmpName',
      key: 'transferEmpName',
      ellipsis: true,
      width: 110,
      fixed: 'left',
      render: (text, row) => tooltipFormat(`${text}（${row?.transferEmpLdap}）`)
  },
  {
      title: intl('TRANSFER_EMPLOYEE_JOB_NUMBER'),
      dataIndex: 'transferEmpId',
      key: 'transferEmpId',
      ellipsis: true,
      width: 110,
      fixed: 'left',
      render: renderData
  },
  {
      title: intl('OUT_DEPARTMENT_PATH'),
      dataIndex: 'beforeDeptPathName',
      key: 'beforeDeptPathName',
      ellipsis: true,
      width: 300,
      render: deptFormat
  },
  {
      title: intl('IN_DEPARTMENT_PATH'),
      dataIndex: 'afterDeptPathName',
      key: 'afterDeptPathName',
      ellipsis: true,
      width: 300,
      render: deptFormat
  },
  {
      title: intl('TRANSFER_MAIN_SCENE'),
      dataIndex: 'sceneName',
      key: 'sceneName',
      ellipsis: true,
      width: 110,
      render: renderData
  },
  {
      title: intl('EXPECTED_EFFECTIVE_DATE'),
      dataIndex: 'preEffectiveDate',
      key: 'preEffectiveDate',
      ellipsis: true,
      width: 110,
      render: timeFormat
  },
  {
      title: intl('PROMOTER_NAME'),
      dataIndex: 'applyEmpName',
      key: 'applyEmpName',
      ellipsis: true,
      width: 140,
      render: (text, row) => tooltipFormat(`${text}（${row?.applyEmpLdap}）`)
  },
  {
      title: intl('PROMOTER_JOB_NUMBER'),
      dataIndex: 'applyEmpId',
      key: 'applyEmpId',
      ellipsis: true,
      width: 90,
      render: renderData
  },
  {
      title: intl('APPROVAL_NUMBER'),
      dataIndex: 'approveBusinessCode',
      key: 'approveBusinessCode',
      ellipsis: true,
      width: 130,
      render: renderData
  },
  {
      title: intl('APPROVAL_STATUS'),
      dataIndex: 'approveStatusName',
      key: 'approveStatusName',
      ellipsis: true,
      width: 90,
      render: renderData
  }
]

