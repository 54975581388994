import React, { useContext } from 'react';
import { Popover } from '@didi/ec-base';
import { PublicStore, recordTrackEvent } from '../../utils/utils';
import classNames from 'classnames';
import useStyle from './styles';

const PageHeader = () => {
  // 样式
  const prefixCls = 'management-pagelet-header';
  const wrapSSR = useStyle('management-pagelet-header');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, props } = useContext(PublicStore);
  const { apolloConfig, hasDiffCompareToVersion } = stateStore;
  const { monthUrl, cycleUrl } = apolloConfig;
  const {
    params: { apiHost },
  } = props;


  // 跳转绩效
  const jumpEvent = () => {
    window.open(`${apiHost}${'/new/perf/workTeam/managementTeams'}`, '_blank');
    if (apiHost.includes('o.didichuxing.com')) {
      recordTrackEvent(); // 记录埋点
    }
  };

  return wrapSSR(
    <div className={classes}>
      <img height='28' src={cycleUrl} />
      {hasDiffCompareToVersion && <Popover
        overlayClassName="diffTipsPopover"
        placement="bottomLeft"
        trigger="hover"
        content={<div className='diffTipsContent'>
          <img src="//img-ys011.didistatic.com/static/ddo_web_static/do1_cgjKIsGQgXNFIgBNrfT7" alt="" className="src" />
          <p>因绩效考核以12月1日的管辖部门的人员范围为准，如与当前人员范围有差异，可直接点击
            <span onClick={jumpEvent}>【前往绩效】</span>
            进入绩效系统。</p>
        </div>}
      >
        <img className="diffTips" src="//img-ys011.didistatic.com/static/ddo_web_static/do1_gqZpReyX4zaDAe2wW4yw" alt="" />
      </Popover>}
      {monthUrl && <img className='monthUrl' src={monthUrl} alt="" />}
    </div>
  );
};

export default PageHeader;
