import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-interview-relationship', () => [
		{
			// base
			[`.${prefixCls}`]: {

        ['.interview-position-container, .interview-source-container']: {
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: 'normal',
          letterSpacing: 0,
          lineHeight: 'normal',

          ['.interview-title']: {
            color: '#00000099',
            marginRight: '4px',
          },

          ['.empty-span']: {
            fontFamily: 'PingFang SC',
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '20px',
            color: '#00000099',
            marginRight: '24px',
          },

          ['.text-color']: {
            fontFamily: 'PingFang SC',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '14px',
            color: '#547BFA',
            marginRight: '16px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',

            ['svg']: {
              transform: 'rotate(-90deg)',
              fontSize: '14px'
            }
          },

          ['.interview-position']: {
            color: '#000000D6',
            display: 'flex',
            alignItems: 'center',

            ['svg']: {
              marginLeft: '2px',
              marginRight: '8px',
            }
          },

          ['.interview-backtrack']: {
            cursor: 'pointer',

            ['&:hover']: {

              ['.interview-backtrack-level4']: {
                color: 'rgb(55 175 44)!important',
              },

              ['.interview-backtrack-level3']: {
                color: 'rgb(79 108 255)!important',
              },

              ['.interview-backtrack-level2']: {
                color: 'rgb(244 132 48)!important',
              },

              ['.interview-backtrack-level1']: {
                color: 'rgb(250 48 48)!important',
              }
            },
          },

          ['.interview-resume']: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '16px',
            cursor: 'pointer',

            ['svg']: {
              transform: 'rotate(-90deg)',
            }
          },
        },

        ['.interview-source-container']: {
          display: 'flex',
          flexWrap: 'nowrap',
          marginTop: '16px',

          ['.interview-source-content']: {
            display: 'flex',
            marginRight: '24px',
          },

          ['.user-type-icon']: {
            display: 'inline-block',
            padding: '0 2px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            textAlign: 'center',
            fontSize: '12px',
            marginLeft: '2px',
          },
        },

        ['.interview-process-container']: {
          marginTop: '32px',
          marginBottom: '40px'
        }
			}
		}
	]);
};

export default useStyle;
