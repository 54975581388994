import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-ct-survey-feedback', () => [
    {
      // base
      [`.${prefixCls}`]: {
        minHeight: '120px',
        color: chartToken.$font_color,
        fontSize: variable.size,

        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute',
        },
        ['.survey-title-right']: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          ['.right-name']: {
            fontSize: '14px',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.72)',
          },
        },
        ['.survey-title']: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '14px',
          ['.survey-title-left']: {
            ['.left-sign']: {
              display: 'inline-block',
              width: '3px',
              height: '12px',
              marginRight: '9px',
              borderRadius: '3px',
              background: '#FF6400',
            },
            ['.left-name']: {
              display: 'inline-block',
              fontSize: '16px',
              fontWeight: '600',
              color: 'rgba(0, 0, 0, 0.84)',
            },
          },
        },
        ['.survey-content']: {
          maxHeight: '440px',
          overflowY: 'auto',
          marginTop: '12px',
          borderRadius: '8px',
          transition: 'box-shadow 0.3s ease',
          ['.content-item']: {
            padding: '12px 16px',
            marginBottom: '12px',
            borderRadius: '8px',
            border: '1px solid rgba(245, 245, 245, 0.6)',
            background: 'rgba(245, 245, 245, 0.6)',
            cursor: 'pointer',
            ['.item-title']: {
              fontSize: '14px',
              fontWeight: '500',
              maxWidth: '255px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'rgba(0, 0, 0, 0.84)',
            },
            ['.item-content']: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              ['.content-left']: {
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.72)',
              },
              ['.content-right']: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#FF6400',
                padding: '4px 0px 4px 4px',
                borderRadius: '6px',
              },
              ['.content-right:hover']: {
                background: 'rgba(255, 100, 0, 0.06)',
              },
            },
          },
          ['.content-item:hover']: {
            border: '1px solid #FF6400',
            background: 'rgba(255, 100, 0, 0.02)',
          },
          ['.no-data']: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 0px 12px 0px',
            backgroundColor: 'rgba(245, 245, 245, 0.6)',
            ['.no-data-center']: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              ['.no-data-text']: {
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.60)',
              },
              ['.no-data-img']: {
                width: '80px',
                height: '66px',
              },
            },
          },
        },
        ['.survey-content.shadow']: {
          boxShadow: '0px 5px 10px -8px rgba(0, 0, 0, 0.08)',
        },
        ['.survey-content::-webkit-scrollbar']: {
          display: 'block',
          width: '6px',
        },
        ['.expand-button']: {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '-4px',
          ['.but-name']: {
            fontSize: '12px',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.36)',
            cursor: 'pointer',
            ['.arrow-content']: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
  ]);
};

export default useStyle;
