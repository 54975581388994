import React, { useEffect, useRef, useState } from 'react';
import { getLocale, Spin, ConfigProvider, en_US, zh_CN } from '@didi/ec-base';
import PageletApi from '@didi/pagelets-sdk';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import { RightThickOutlined, LeftThickOutlined } from '@didi/iconflow/ec';
import dayjs from 'dayjs';
import manifest from '../../manifest.json';
import useStyle from './style';
import PageletServices from "./services/index.js";
import TimeFilter from './components/TimeFilter/timeFileter.jsx';
import ScopeFilter from "./components/ScopeFilter/index.jsx";
import CareList from "./components/CareList/index.jsx";
import Anniversary from './assets/anniversary.svg';
import RegularWorker from './assets/regular_worker.svg';
import Birthday from './assets/birthday.svg';
import { CARE_CODE_ENUM, TIME_RANGE_TYPE, timeRangeTypeList } from './constant.js'

const checkCode = (a, b) => a === b;

const prefixCls = 'pagelet-team-care';
const localLocales = manifest.locales;
const Pagelet = props => {
  const { params: { auth, headers = {}, apiHost, locales, item, pageCode = 'default', deptCodes, deptInfos } } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(auth.functionName)
  }
  PageletApi.newRequest.init(newHeaders, '@didi/pagelet-team-care');

  const locale = getLocale(locales || localLocales);
  const wrapSSR = useStyle(prefixCls);

  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN')
  const [providerLocale, setProviderLocale] = React.useState({});

  const [preQuery, setPreQuery] = useState(null);
  const [initError, setInitError] = React.useState(false);
  const [initing, setIniting] = useState(false);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [curScope, setCurScope] = useState('');
  const [total, setTotal] = useState(null);
  const [totalCareText, setTotalCareText] = useState('');

  const { current: pageSize } = useRef(4);
  const [anniversaryPageNum, setAnniversaryPageNum] = useState(1);
  const [anniversaryDesc, setAnniversaryDesc] = useState({});
  const [anniversaryList, setAnniversaryList] = useState([]);
  const [regularWorkerPageNum, setRegularWorkerPageNum] = useState(1);
  const [regularWorkerDesc, setRegularWorkerDesc] = useState({});
  const [regularWorkerList, setRegularWorkerList] = useState([]);
  const [birthdayPageNum, setBirthdayPageNum] = useState(1);
  const [birthdayDesc, setBirthdayDesc] = useState({});
  const [birthdayList, setBirthdayList] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [permissionScope, setPermissionScope] = useState([]);
  const [timeRangeType, setTimeRangeType] = useState(TIME_RANGE_TYPE.month);
  const [subCode, setSubCode] = useState('2');

  const initData = (query) => {
    const params = {
      "deptCodes": deptCodes,
      "endDate": query.endTime,
      "pageNum": 1,
      "pageSize": pageSize,
      "pageType": pageCode,
      "searchType": query.curScope,
      "startDate": query.startTime,
      "subType": CARE_CODE_ENUM.ALL
    };
    setPreQuery(query);
    setIniting(true);
    PageletServices.initCardData(apiHost, params).then((res) => {
      if (res?.code === '100200' && res?.data) {
        setInitError(false);
        setIniting(false);
        const num = res.data?.totalNum ?? 0;
        setTotal(num);
        const text = locale.howManyPeopleNeedCareInTotal.replace('几', `${num}`);
        setTotalCareText(num !== 0 ? text : '');

        const careList = res.data?.careList?.filter(item => Boolean(item)) || [];
        careList.forEach(({ empInfoList, ...item }) => {
          if (checkCode(item.subCode, CARE_CODE_ENUM.ANNIVERSARY)) {
            setAnniversaryDesc(item || {});
            setAnniversaryList(empInfoList || []);
          }
          if (checkCode(item.subCode, CARE_CODE_ENUM.REGULAR_WORKER)) {
            setRegularWorkerDesc(item || {});
            setRegularWorkerList(empInfoList || []);
          }
          if (checkCode(item.subCode, CARE_CODE_ENUM.BIRTHDAY)) {
            setBirthdayDesc(item || {});
            setBirthdayList(empInfoList || []);
          }
        })
        const list = res.data?.careList;
        let subcodeInfo = '';
        list.map((v) => {
          if (v?.empInfoList.length > 0 && !subcodeInfo) {
            subcodeInfo = v?.subCode;
          }
        });
        setSubCode(subcodeInfo || '2');
      } else {
        console.log('卡片初始化错误, 展示兜底卡片!!!')
        setInitError(true);
        setIniting(false);
      }
    }, (error) => {
      console.log('卡片初始化错误, 展示兜底卡片!!!', error)
      setInitError(true);
      setIniting(false);
    });
  };
  const initFilter = () => {
    const params = {
      // "deptCodes": deptCodes,
      "pageType": pageCode,
      // "module": 'team-care'
    };
    PageletServices.initFilter(apiHost, params).then((res) => {
      if (res?.code === '100200' && res?.data) {
        const list = res?.data;
        let subordinateInfo = '2';
        list.map((v) => {
          if (v?.selected) {
            subordinateInfo = v?.code
          }
        })
        setPermissionScope(res?.data || []);
        setCurScope(subordinateInfo)
      } else {
        console.log('卡片初始化错误, 展示兜底卡片!!!')
        setInitError(true);
      }
    }, (error) => {
      console.log('卡片初始化错误, 展示兜底卡片!!!', error)
      setInitError(true);
    });
  };

  const changePage = (query, successCallback = () => { }) => {
    setCardLoading(true);
    PageletServices.getNextDataByQuery(apiHost, query).then((res) => {
      if (res.code === '100200' && res.data) {
        successCallback(res.data)
      } else {
        console.log('加载下一页失败!!!')
      }
    }, (error) => {
      console.log('error', error);
    }).finally(() => {
      setCardLoading(false);
    })
  };

  const onClickShowDrawer = () => {
    drawer.show();
  };

  const onClickHiddenDrawer = () => {
    drawer.hide();
  };

  const drawer = PageletApi.drawer({
    title: '关怀汇总',
    width: '1000px',
    pageletName: '@didi/pagelet-team-care-table',
    // pageletUrl: '//127.0.0.1:9009/team-care-table.runtime.min.js', // 使用pageletUrl
    pageletProps: {
      auth,
      headers,
      pageCode,
      permissionScope,
      defaultDeptCode: deptCodes,
      defaultDeptInfo: deptInfos,
      startDate: startTime,
      endDate: endTime,
      timeRangeType,
      subordinate: curScope,
      subCode,
      onClickHiddenDrawer
    }, // 透传给pagelet的props
    destroyOnClose: true,
    onClose: onClickHiddenDrawer,
  });

  const onTimeChange = (time) => {
    setStartTime(dayjs(time[0]).valueOf());
    setEndTime(dayjs(time[1]).valueOf());
    setAnniversaryPageNum(1);
    setRegularWorkerPageNum(1);
    setBirthdayPageNum(1);
  };
  const onTimeRangeType = (v, item) => {
    setTimeRangeType(v);
    if (v === TIME_RANGE_TYPE.week) {
      setStartTime(dayjs().startOf('isoWeek').valueOf());
      setEndTime(dayjs().endOf('isoWeek').valueOf());
    } else if (v === TIME_RANGE_TYPE.month) {
      setStartTime(dayjs().startOf(TIME_RANGE_TYPE.month).valueOf());
      setEndTime(dayjs().endOf(TIME_RANGE_TYPE.month).valueOf());
    } else {
      setStartTime(dayjs().startOf(TIME_RANGE_TYPE.date).valueOf());
      setEndTime(dayjs().endOf(TIME_RANGE_TYPE.date).valueOf());
    }
  }
  const onScopeChange = (scope) => {
    setCurScope(scope);
    setAnniversaryPageNum(1);
    setRegularWorkerPageNum(1);
    setBirthdayPageNum(1);
  };

  const onChangePageSize = (typeCode, num) => {
    const params = {
      "deptCodes": deptCodes,
      "endDate": endTime,
      "pageNum": num,
      "pageSize": pageSize,
      "pageType": pageCode,
      "searchType": curScope,
      "startDate": startTime,
      "subType": typeCode
    }
    const successCallback = (data) => {
      const { empInfoList, ...desc } = data;
      if (checkCode(desc.subCode, CARE_CODE_ENUM.ANNIVERSARY)) {
        setAnniversaryPageNum(params.pageNum)
        setAnniversaryDesc(desc || {});
        setAnniversaryList(empInfoList || []);
      }
      if (checkCode(desc.subCode, CARE_CODE_ENUM.REGULAR_WORKER)) {
        setRegularWorkerPageNum(params.pageNum)
        setRegularWorkerDesc(desc || {});
        setRegularWorkerList(empInfoList || []);
      }
      if (checkCode(desc.subCode, CARE_CODE_ENUM.BIRTHDAY)) {
        setBirthdayPageNum(params.pageNum)
        setBirthdayDesc(desc || {});
        setBirthdayList(empInfoList || []);
      }
    }
    changePage(params, successCallback)
  };

  useEffect(() => {
    if (lang !== 'zh-CN') {
      // moment.locale('en');
      setProviderLocale(en_US)
    } else {
      // moment.locale('zh-cn');
      setProviderLocale(zh_CN)
    }
  }, []);

  useEffect(() => {
    if (deptCodes?.length > 0) {
      initFilter();
    }
  }, [deptCodes]);

  useEffect(() => {
    const query = {
      deptCodes, startTime, endTime, curScope
    }
    if (deptCodes?.length > 0 && startTime && endTime && curScope && !initing) {
      initData(query)
    }
  }, [deptCodes, startTime, endTime, curScope, preQuery]);

  return wrapSSR(
    <div className={prefixCls}>
      <ConfigProvider
        locale={providerLocale}
        prefixCls='ant5'
      >
        <div className={`${prefixCls}-title`}>
          <DHRCardTitle
            title={
              <>
                <div>{locale.teamCare}</div>
                {item?.cardHeight === 1 && <div className={`${prefixCls}-title-filter`}>
                  <div className={`${prefixCls}-scope`}>
                    <ScopeFilter
                      subordinate={timeRangeType}
                      list={timeRangeTypeList}
                      onScopeChange={onTimeRangeType}
                    />
                  </div>
                  <div className={`${prefixCls}-time`}>
                    <TimeFilter
                      source='team-holidays'
                      lang={lang}
                      timeRangeTypeInfo={timeRangeType}
                      startTime={startTime}
                      onTimeChange={onTimeChange}
                      onTimeRangeType={onTimeRangeType}
                    />
                  </div>
                </div>}
              </>
            }
            showPrefixTag={false}
            type="grey"
            titleAdded={
              <DHRCardButton onClick={() => { onClickShowDrawer(); }}>
                {locale.careSummary} <RightThickOutlined cursor="pointer" size={14} className={`${prefixCls}-url-icon`} />
              </DHRCardButton>
            }
          />
        </div>
        {
          item?.cardHeight !== 1 && <div className={`${prefixCls}-filter`}>
            <div className={`${prefixCls}-scope`}>
              <ScopeFilter
                subordinate={timeRangeType}
                list={timeRangeTypeList}
                onScopeChange={onTimeRangeType}
              />
            </div>
            <div className={`${prefixCls}-time`}>
              <TimeFilter source='team-care' startTime={startTime} lang={lang} timeRangeTypeInfo={timeRangeType} onTimeChange={onTimeChange} onTimeRangeType={onTimeRangeType} />
            </div>
            <div className={`${prefixCls}-scope`}>
              {
                permissionScope?.length > 0 && <ScopeFilter
                  subordinate={curScope}
                  list={permissionScope}
                  onScopeChange={onScopeChange}
                />
              }
            </div>
            <div className={`${prefixCls}-summary`}>
              {totalCareText}
            </div>
          </div>
        }
        <div
          style={{
            height: `${item?.cardHeight === 1 ? 'calc(100% - 50px)' : 'calc(100% - 92px)'}`,
            marginTop: `${item?.cardHeight === 1 ? '16px' : ''}`
          }}
        >
          <Spin
            spinning={initing}
          >
            {
              preQuery && initError && (
                <div className={`${prefixCls}-content-err`}>
                  <img src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png' style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }} />
                  <span className='text-item-1'>服务不可用，请稍候再试。</span>
                </div>
              )
            }
            {preQuery && !initError && total !== 0 && (
              <div className={`${prefixCls}-content`}>
                {
                  anniversaryDesc.subNum ? (
                    <div className={`${prefixCls}-content-item anniversary`}>
                      <div className="content-item-header">
                        <Anniversary />
                        <span className='title'>周年</span>
                        <span className="num">{`(${anniversaryDesc.subNum ?? 0}人)`}</span>
                      </div>
                      <div className="content-item-body">
                        {/*<CareList typeCode={CARE_CODE_ENUM.ANNIVERSARY} pageSize={pageSize} info={anniversaryDesc}*/}
                        {/*          list={anniversaryList} preQuery={preQuery} onClickMoreData={onClickMoreData} onChangePageSize={onChangePageSize}/>*/}
                        <CareList
                          cardLoading={cardLoading}
                          typeCode={CARE_CODE_ENUM.ANNIVERSARY}
                          pageNum={anniversaryPageNum}
                          pageSize={pageSize}
                          info={anniversaryDesc}
                          list={anniversaryList}
                          onChangePageSize={onChangePageSize}
                        />
                      </div>
                    </div>
                  ) : null
                }
                {
                  birthdayDesc.subNum ? (
                    <div className={`${prefixCls}-content-item birthday`}>
                      <div className="content-item-header">
                        <Birthday />
                        <span className='title'>生日</span>
                        <span className="num">{`(${birthdayDesc.subNum ?? 0}人)`}</span>
                      </div>
                      <div className="content-item-body">
                        {/*<CareList typeCode={CARE_CODE_ENUM.BIRTHDAY} pageSize={pageSize} info={birthdayDesc}*/}
                        {/*          list={birthdayList} preQuery={preQuery} onClickMoreData={onClickMoreData}*/}
                        {/*          onChangePageSize={onChangePageSize}/>*/}
                        <CareList
                          cardLoading={cardLoading}
                          typeCode={CARE_CODE_ENUM.BIRTHDAY}
                          pageNum={birthdayPageNum}
                          pageSize={pageSize}
                          info={birthdayDesc}
                          list={birthdayList}
                          onChangePageSize={onChangePageSize}
                        />
                      </div>
                    </div>
                  ) : null
                }
                {
                  regularWorkerDesc?.subNum ? (
                    <div className={`${prefixCls}-content-item regular-worker`}>
                      <div className="content-item-header">
                        <RegularWorker />
                        <span className='title'>转正</span>
                        <span className="num">{`(${regularWorkerDesc.subNum ?? 0}人)`}</span>
                      </div>
                      <div className="content-item-body">
                        {/*<CareList typeCode={CARE_CODE_ENUM.REGULAR_WORKER} pageSize={pageSize} info={regularWorkerDesc}*/}
                        {/*          list={regularWorkerList} preQuery={preQuery} onClickMoreData={onClickMoreData}*/}
                        {/*          onChangePageSize={onChangePageSize}/>*/}
                        <CareList
                          cardLoading={cardLoading}
                          typeCode={CARE_CODE_ENUM.REGULAR_WORKER}
                          pageNum={regularWorkerPageNum}
                          pageSize={pageSize}
                          info={regularWorkerDesc}
                          list={regularWorkerList}
                          onChangePageSize={onChangePageSize}
                        />
                      </div>
                    </div>
                  ) : null
                }
              </div>
            )}
            {preQuery && total === 0 && !initError && (
              <div className={`${prefixCls}-content-null`}>
                <img src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/no_data_image.png' style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }} />
                <span className='text-item-1'>暂无数据</span>
                {/* <span className='text-item-2'>记得多关注大家，要劳逸结合哦～</span> */}
              </div>
            )
            }
          </Spin>
        </div>
      </ConfigProvider>
    </div>,
  );
};

export default Pagelet;
