import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

  return useRegisterStyle('pagelet-personal-info', () => {
    return [
      {
        // base
        [`.${prefixCls}`]: {
          'width': '100%',
          'height': '100%',
          minHeight: '261px',
          'background': '#FFFFFF',
          'padding': '2px',
          'overflow': 'auto',
          borderRadius: '12px 12px 0 0',
          [`.${prefixCls}-info`]: {
            'minHeight': '270px',
            'background': "url('//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/personal-info.png')",
            'backgroundRepeat': 'no-repeat',
            'backgroundSize': '100% 400px',
            'paddingTop': '24px',
            ['.info-avater']: {
              'borderRadius': '50%',
              'border': '2px solid #FFFFFF',
              'position': 'relative',
              'display': 'flex',
              'alignItems': 'center',
              'justifyContent': 'center',
              'width': '78px',
              'height': '78px',
              'margin': '0 auto',
              ['.ant-avatar ']: {
                'width': '78px',
                'height': '78px',
                'borderRadius': '50%',
                border: 'none',
              },
              ['.info-birthday']: {
                'position': 'absolute',
                'left': '42px',
                'top': '56px',
                'padding': '5px',
                'borderRadius': '12px 4px 12px 4px',
                'opacity': '1',
                'background': '#FFFFFF',
                'fontSize': '12px',
                'fontWeight': '600',
                'letterSpacing': '1px',
                'color': '#FF6400',
                'whiteSpace': 'nowrap',
              },
              ['.info-birthday-icon']: {
                'position': 'absolute',
                'left': '52px',
                'top': '-10px',
                'width': '30px',
                'height': '22px'
              }
            },
            ['.info-name']: {
              'display': 'flex',
              'flex-direction': 'column',
              'alignItems': 'center',
              'marginTop': '8px',
              ['.info-name-id']: {
                'fontSize': '18px',
                'fontWeight': '600',
                'color': 'rgba(0, 0, 0, 0.84)',
              },
              ['.info-name-num']: {
                'marginTop': '4px',
                'fontSize': '12px',
                'color': 'rgba(0, 0, 0, 0.72)',
              }
            },
            ['.info-msg']: {
              'display': 'flex',
              'flex-direction': 'row',
              'marginTop': '4px',
              justifyContent: 'space-around',
              ['.info-msg-warp']: {
                'display': 'flex',
                'flex-direction': 'column',
                'marginTop': '8px',
                'fontSize': '12px',
                'fontWeight': 'normal',
                ['.info-msg-tit']: {
                  'color': 'rgba(0, 0, 0, 0.6)',
                  marginLeft: '4px',
                },
                ['.info-msg-content']: {
                  'color': 'rgba(0, 0, 0, 0.84)',
                  'display': 'flex',
                  'flex-direction': 'row',
                  marginTop: '4px',
                  cursor: 'pointer',
                  padding: '2px 4px',
                  ['div']: {
                    'whiteSpace': 'nowrap',
                    'overflow': 'hidden',
                    'textOverflow': 'ellipsis',
                  },
                  ['&:hover']: {
                    background: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: '6px',
                  }
                },
              },
              ['.info-msg-more-warp']: {
                // 'marginTop': '24px',
                // 'marginLeft': '-5px',
                'fontSize': '12px',
                'fontWeight': 'normal',
                'color': 'rgba(0, 0, 0, 0.72)',
                padding: '2px 4px',
                width: '72px',
              },
              ['.info-msg-more-warp:hover']: {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px',
                cursor: 'pointer',
              }
            }
          },
          [`.${prefixCls}-warp`]: {
            'height': '80px',
            'margin': '32px 16px 0px 16px',
            'background': '#F2F8FF',
            'display': 'flex',
            'flex-direction': 'row',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0 20px',
            ['.warp-intern,.warp-outsource']: {
              'display': 'flex',
              'flex-direction': 'column',
              width: '50%',
              ['.warp-intern-num']: {
                fontFamily: 'DIDI FD',
                'fontSize': '32px',
                'fontWeight': '900',
                'color': 'rgba(0, 0, 0, 0.7)',
                'padding': '3px 16px 0px',
                lineHeight: '40px',
                marginBottom: '4px',
              },
              ['span']: {
                'fontSize': '12px',
                'fontWeight': 'normal',
                'color': 'rgba(0, 0, 0, 0.84)',
                'padding': '0px 16px 3px',
                lineHeight: '18px',
              }
            },
            ['.warp-outsource']: {
              marginLeft: '4px'
            },
            ['.warp-outsource:hover,.warp-intern:hover']: {
              background: 'rgba(10, 112, 245, 0.06)',
              cursor: 'pointer',
              borderRadius: '8px',
            }
          },
          [`.${prefixCls}-content-err`]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '262px',
            justifyContent: 'center',
            ['img']: {
              width: '120px',
            },
            ['.text-item-1']: {
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '14px',
              letterSpacing: 0,
              color: '#2D3040',
            },
          }
        }
      }
    ];
  });
};

export default useStyle;
