import React, { useMemo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomInfoPanel from '@/cpns/CustomInfoPanel';
import CustomCard from '@/cpns/CustomCard';
import CustomTagPoolCell from '@/cpns/CustomTagPoolCell';
import {
  createForm,
  onFieldValueChange,
  onFieldInputValueChange,
  onFieldInit,
  onFieldChange,
} from '@formily/core';
import { FormProvider, ArrayField, useField } from '@formily/react';
import CustomPageHead from '@/cpns/CustomPageHead';
import { TagTypeXCpnMap } from '@/pages/TagConfig/constant';
import { PanelConfig } from './constant';
import { LangTranslate, ScrollInToViewBySelector } from '@/utils';
import {
  getTagGroup,
  saveTagGroup,
  getTagGroupVersion,
  getTagGroupInfoMultilingual,
  checkDimensionAndTagGroupDept,
} from '@/services';
import { useRequest } from 'ahooks';
import './index.less';
import SvgIcon from '@/cpns/SvgIcon';
import cloneDeep from 'lodash/cloneDeep';
import { useObjAndDimData } from '@/hooks';
import CustomVersionPanel from '@/cpns/CustomVersionPanel';

import { BricksInfoContext, HandelRefContext } from '@/context.jsx';

import { message, Button, Spin, Modal } from 'antd';

function TagPoolConfig(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const oriTagPoolId = searchParams?.get('id');

  const tagPoolId = ['null', 'undefined', null, undefined].includes(oriTagPoolId)
    ? ''
    : oriTagPoolId;
  const nowayFlag = searchParams?.get('noway');
  const BricksInfoContextVal = useContext(BricksInfoContext);
  const HandelRefContextVal = useContext(HandelRefContext);
  const panelFormRef = useRef();
  const [currentSelectVersion, setCurrentSelectVersion] = useState();
  const { data: [objOption, dimsTree] = [], loading: objAndDimsTreeLoading } = useObjAndDimData();
  const [readPretty, setReadPretty] = useState(props?.readPretty || false);

  useEffect(() => {
    setReadPretty(() => props?.readPretty || false);
  }, [props.readPretty]);

  useEffect(() => {
    const panelForm = panelFormRef.current;
    panelForm.setFieldState('panelInfos.subjectId', filed => {
      filed.setComponentProps({ options: objOption });
    });
    panelForm.setFieldState('panelInfos.dimensionId', filed => {
      filed.setComponentProps({
        treeData: dimsTree,
        // treeDefaultExpandedKeys: dimsTree?.map(it => it?.id),
      });
    });
  }, [objOption, dimsTree]);

  const {
    data: tagPoolVersionInfo = {},
    loading: getTagGroupVersionLoading,
    runAsync: runGetTagGroupVersion,
  } = useRequest(
    async params => {
      try {
        const res = await getTagGroupVersion({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        setCurrentSelectVersion(() => res?.data?.find?.(it => it?.latest)?.id || '');
        return res?.data;
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
      refreshDeps: [BricksInfoContextVal],
    },
  );

  const {
    data: tagGroupInfo = {},
    loading: fetchTagGroupInfoLoading,
    runAsync: fetchTagGroupInfo,
  } = useRequest(
    async params => {
      try {
        const res = await (readPretty ? getTagGroupInfoMultilingual : getTagGroup)({
          // const res = await getTagGroup({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return { ...res?.data, dept: res?.data?.dept?.split?.(',') };
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
      refreshDeps: [BricksInfoContextVal, readPretty],
    },
  );
  const {
    loading: checkDimensionAndTagGroupDeptLoading,
    runAsync: runCheckDimensionAndTagGroupDept,
  } = useRequest(
    async params => {
      try {
        const res = await checkDimensionAndTagGroupDept({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContextVal] },
  );

  const { loading: saveTagGroupLoading, runAsync: runSaveTagGroup } = useRequest(
    async params => {
      try {
        const panelForm = panelFormRef.current;

        const baseConfig = panelForm.getValuesIn('panelInfos');
        const checkParams = {
          dimensionId: baseConfig?.dimensionId,
          dept: baseConfig?.dept?.join?.(',') || baseConfig?.dept,
        };
        const checkRes = await runCheckDimensionAndTagGroupDept(checkParams);

        if (!checkRes?.resultFlag) {
          message.warning(checkRes?.msg);
          panelForm.setValuesIn('panelInfos.dept', checkRes?.dept.split?.(','));
          return Promise.reject(checkRes?.msg);
        }
        const res = await saveTagGroup({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });

        return res?.data;
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
      refreshDeps: [BricksInfoContextVal],
    },
  );

  // 表单
  const tagListForm = useMemo(
    () =>
      createForm({
        initialValues: { tagList: [{ tagConfigDetail: { type: 1, tagOptionList: [{}] } }] },
        effects() {
          onFieldInit('tagList.*.tagConfigDetail.type', field => {
            field.setComponentProps({ option: Object.values(TagTypeXCpnMap) });
          });

          onFieldInputValueChange('tagList.*.tagConfigDetail.type', parentField => {
            if (parentField.modified) {
              const newPath = parentField.parent.path;
              tagListForm?.setValuesIn?.(`${newPath}.tagOptionList`, [{}]);
              tagListForm?.setValuesIn?.(`${newPath}.indicatorField`, {});
              tagListForm?.setValuesIn?.(`${newPath}.logicDesc`, '');
            }
          });
          onFieldChange('tagList.*.tagConfigDetail.type', parentField => {
            const newPath = parentField.parent.path;
            const value = parentField?.value;
            if (Object.keys(TagTypeXCpnMap)?.includes(value + '')) {
              if ([1, 2].includes(value)) {
                tagListForm.setFieldState(`${newPath}.tagOptionList`, field => {
                  field.setDisplay('visible');
                });
                tagListForm.setFieldState(
                  `${newPath}.*(logicDesc,indicatorField,anotherField,logicDescLodash)`,
                  field => {
                    field.setDisplay('none');
                  },
                );
                tagListForm.setFieldState(`${newPath}.tagOptionList.otherBtnWrap`, field => {
                  field?.setDisplay?.('visible');
                });
              } else if ([5].includes(value)) {
                tagListForm.setFieldState(`${newPath}.logicDesc`, field => {
                  field.setDisplay('visible');
                });
                tagListForm.setFieldState(
                  `${newPath}.*(tagOptionList,indicatorField,anotherField,logicDescLodash)`,
                  field => {
                    field.setDisplay('none');
                  },
                );
              } else if ([6].includes(value)) {
                tagListForm.setFieldState(
                  `${newPath}.*(tagOptionList,logicDesc,logicDescLodash)`,
                  field => {
                    field.setDisplay('visible');
                  },
                );
                tagListForm.setFieldState(`${newPath}.*(indicatorField,anotherField)`, field => {
                  field.setDisplay('none');
                });
                tagListForm.setFieldState(`${newPath}.tagOptionList.otherBtnWrap`, field => {
                  field?.setDisplay?.('none');
                });
              } else if ([7].includes(value)) {
                tagListForm.setFieldState(
                  `${newPath}.*(tagOptionList,logicDesc,anotherField,logicDescLodash)`,
                  field => {
                    field.setDisplay('none');
                  },
                );
                tagListForm.setFieldState(`${newPath}.indicatorField`, field => {
                  field.setDisplay('visible');
                });
              } else {
                tagListForm.setFieldState(
                  `${newPath}.*(tagOptionList,indicatorField,logicDesc,logicDescLodash)`,
                  field => {
                    field.setDisplay('none');
                  },
                );
                tagListForm.setFieldState(`${newPath}.anotherField`, field => {
                  field.setDisplay('visible');
                });
                const newCpnObj = TagTypeXCpnMap?.[value];
                tagListForm.setFieldState(`${newPath}.anotherField`, field => {
                  field.setComponent(newCpnObj?.cpn);
                });
              }
            }
          });
        },
      }),
    [],
  );

  useEffect(() => {
    if (!Object.values(tagGroupInfo || {})?.length) {
      return;
    }
    const panelFrom = panelFormRef.current;
    panelFrom.setValues({
      panelInfos: {
        ...tagGroupInfo,
      },
    });
    const tagListVals = tagGroupInfo?.tagList?.map?.(curTag => {
      if (curTag?.type === 7) {
        return {
          id: curTag?.id,
          code: curTag?.code,
          name: curTag?.name,
          description: curTag?.description,
          tagConfigDetail: {
            ...curTag,
            indicatorField: {
              dataSource: curTag?.tagOptionList?.find(it => it?.name === 'dataSource'),
              apiUrl: curTag?.tagOptionList?.find(it => it?.name === 'apiUrl'),
              indicatorLogicDesc: curTag?.tagOptionList?.find(
                it => it?.name === 'indicatorLogicDesc',
              ),
            },
          },
        };
      }
      return {
        id: curTag?.id,
        code: curTag?.code,
        name: curTag?.name,
        description: curTag?.description,
        tagConfigDetail: {
          ...curTag,
          tagOptionList: curTag?.tagOptionList,
        },
      };
    });
    tagListForm.setValues({ tagList: tagListVals });
    if ([2, 3].includes(tagGroupInfo?.status)) {
      panelFrom.setFieldState('panelInfos.subjectId', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.code', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.dimensionId', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.belongSystemId', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.dept', field => {
        field.setPattern('readPretty');
      });
      panelFrom.setFieldState('panelInfos.customAuth', field => {
        field.setPattern('readPretty');
      });
      tagListForm.setFieldState('tagList.*.tagConfigDetail.type', field => {
        field.setPattern('readPretty');
      });
      tagListForm.setFieldState('tagList.*.code', field => {
        field.setPattern('readPretty');
      });
    }
  }, [tagGroupInfo]);

  // 保存
  const handelSave = async () => {
    try {
      const panelFrom = panelFormRef.current;

      await panelFrom.validate('*');

      const tagPoolBaseInfoVal = cloneDeep(panelFrom?.getValuesIn?.('panelInfos'));
      const tagListVal = cloneDeep(tagListForm?.getValuesIn?.('tagList'));
      const newTagListVal = tagListVal?.map(originIt => {
        const it = { ...originIt, ...originIt?.tagConfigDetail };
        console.log('it', it);
        if (it?.type === 7) {
          let newTagOptionList = [];
          newTagOptionList = [
            {
              ...it?.tagConfigDetail?.indicatorField?.dataSource,
              name: 'dataSource',
            },
            {
              ...it?.tagConfigDetail?.indicatorField?.apiUrl,
              name: 'apiUrl',
            },
            {
              ...it?.tagConfigDetail?.indicatorField?.indicatorLogicDesc,
              name: 'indicatorLogicDesc',
            },
          ];
          return {
            ...it,
            ...it?.tagConfigDetail,
            tagOptionList: newTagOptionList,
            code: originIt?.code,
            tagConfigDetail: undefined,
            indicatorField: undefined,
          };
        }
        return {
          ...it,
          ...it?.tagConfigDetail,
          code: originIt?.code,
          tagConfigDetail: undefined,
          indicatorField: undefined,
        };
      });
      const newTagPoolConfig = {
        ...tagPoolBaseInfoVal,
        id: tagGroupInfo?.id || tagPoolBaseInfoVal?.id || tagPoolId,
        tagList: newTagListVal,
        actionFlag: 1,
        type: 2,
        customAuth: tagPoolBaseInfoVal?.customAuth,
        dept: tagPoolBaseInfoVal?.dept?.join?.(','),
      };
      const saveRes = await runSaveTagGroup(newTagPoolConfig);
      if (!saveRes?.resultFlag) {
        message.warning({
          content: <div style={{ whiteSpace: 'pre-line' }}>{saveRes?.msg}</div>,
        });
        return Promise.reject(saveRes?.msg);
      } else {
        await message.success(LangTranslate('操作成功'), 0.5);
        window.location.href = window.location.origin + '/labelSystem/home';
      }
    } catch (error) {
      ScrollInToViewBySelector('.label-system-Pagelet-antd-formily-item-error-help');
      console.log('error', error);
      return Promise.reject(error);
    }
  };

  // 提交
  const handelSubmit = async () => {
    try {
      const panelFrom = panelFormRef.current;
      await panelFrom.validate('*');
      await tagListForm?.validate?.('*');
      const tagPoolBaseInfoVal = cloneDeep(panelFrom?.getValuesIn?.('panelInfos'));
      const tagListVal = cloneDeep(tagListForm?.getValuesIn?.('tagList'));

      const newTagListVal = tagListVal?.map(originIt => {
        const it = { ...originIt?.tagConfigDetail, ...originIt };

        let newTagOptionList = [];

        if (it?.type === 7) {
          newTagOptionList = [
            {
              ...it?.tagConfigDetail?.indicatorField?.dataSource,
              name: 'dataSource',
            },
            {
              ...it?.tagConfigDetail?.indicatorField?.apiUrl,
              name: 'apiUrl',
            },
            {
              ...it?.tagConfigDetail?.indicatorField?.indicatorLogicDesc,
              name: 'indicatorLogicDesc',
            },
          ];

          return {
            ...it?.tagConfigDetail,
            ...it,
            tagOptionList: newTagOptionList,
            code: originIt?.code,
            indicatorField: undefined,
            tagConfigDetail: undefined,
          };
        }
        return {
          ...it?.tagConfigDetail,
          ...it,
          code: originIt?.code,
          tagConfigDetail: undefined,
          indicatorField: undefined,
        };
      });

      const newTagPoolConfig = {
        ...tagPoolBaseInfoVal,
        id: tagGroupInfo?.id || tagPoolBaseInfoVal?.id || tagPoolId,
        tagList: newTagListVal,
        actionFlag: 2,
        type: 2,
        dept: tagPoolBaseInfoVal?.dept?.join?.(','),
      };

      const submitRes = await runSaveTagGroup(newTagPoolConfig);
      if (submitRes?.resultFlag) {
        await message.success(LangTranslate('操作成功'), 0.5);

        window.location.href = window.location.origin + '/labelSystem/home';
      } else {
        message.warning({
          content: <div style={{ whiteSpace: 'pre-line' }}>{submitRes?.msg}</div>,
        });
        return Promise.reject(submitRes?.msg);
      }
    } catch (error) {
      ScrollInToViewBySelector('.label-system-Pagelet-antd-formily-item-error-help');

      console.log('error', error);
      return Promise.reject(error);
    }
    return;
  };

  const handleAddTag = useCallback(() => {
    tagListForm.setFieldState('tagList', field => {
      field?.push?.({ tagConfigDetail: { isLocNewItem: true, type: 1, tagOptionList: [{}] } });
    });
  }, [tagListForm]);

  useEffect(() => {
    if (!!tagPoolId?.length && !nowayFlag) {
      fetchTagGroupInfo({ tagGroupId: tagPoolId });
    }
  }, [tagPoolId, BricksInfoContextVal, nowayFlag]);

  useEffect(() => {
    if (readPretty) {
      runGetTagGroupVersion({ tagGroupId: tagPoolId });
    }
  }, [tagPoolId, BricksInfoContextVal, readPretty]);

  useEffect(() => {
    const panelFrom = panelFormRef.current;

    if (!readPretty) {
      panelFrom.setFieldState('panelInfos.*(id,rootGroupId)', field => {
        field.setDisplay('hidden');
      });
      return;
    } else {
      panelFrom.setFieldState('panelInfos.*(id,rootGroupId)', field => {
        field.setDisplay('visible');
      });
    }
    panelFrom.setFieldState('*', field => {
      field.setPattern('readPretty');
    });
    tagListForm.setFieldState('*', field => {
      field.setPattern('readPretty');
    });
  }, [readPretty]);

  // 初始化
  useEffect(() => {
    try {
      if (nowayFlag) {
        const tempTagInfo = JSON.parse(
          localStorage.getItem('pagelet-labelSystem-tmp-data-tagInfo') || {},
        );
        console.log('tempTagInfo', tempTagInfo);
        // setTimeout(() => {
        //   localStorage.removeItem('pagelet-labelSystem-tmp-data-tagInfo');
        // });
        const panelFrom = panelFormRef.current;
        // panelFrom.setValuesIn('panelInfos.id', tempTagInfo?.tagPoolId || undefined);

        panelFrom.setValues({
          panelInfos: {
            name: tempTagInfo?.name || undefined,
            description: tempTagInfo?.description || undefined,
            id: tempTagInfo?.tagPoolId || undefined,
            subjectId: tempTagInfo?.subjectId || undefined,
            dimensionId: tempTagInfo?.dimensionId || undefined,

            belongSystemId: tempTagInfo?.belongSystemId || undefined,
            dept: tempTagInfo?.dept || undefined,
            customAuth: tempTagInfo?.customAuth || undefined,
          },
        });

        tagListForm.setValuesIn('tagList.0', {
          name: tempTagInfo?.name || undefined,
          description: tempTagInfo?.description || undefined,
          tagConfigDetail: {
            type: tempTagInfo?.type || undefined,
            logicDesc: tempTagInfo?.logicDesc || undefined,
            id: tempTagInfo?.tagId || undefined,

            tagOptionList: tempTagInfo?.tagOptionList || undefined,
            indicatorField: tempTagInfo?.indicatorField || undefined,
            anotherField: tempTagInfo?.anotherField || undefined,
          },
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [nowayFlag]);

  // 切换至标签
  const handleSwitchTagConfig = () => {
    const panelFrom = panelFormRef.current;
    const tagPoolBaseInfoVal = cloneDeep(panelFrom?.getValuesIn?.('panelInfos'));
    const curTagVal = cloneDeep(tagListForm?.getValuesIn?.('tagList'))?.[0] || {};
    const newTagVal = {
      tagPoolId: tagGroupInfo?.id || tagPoolBaseInfoVal?.id || undefined,
      tagId: curTagVal?.id || undefined,
      name: curTagVal?.name || undefined,
      description: curTagVal?.description || undefined,
      subjectId: tagPoolBaseInfoVal?.subjectId || undefined,
      dimensionId: tagPoolBaseInfoVal?.dimensionId || undefined,
      belongSystemId: tagPoolBaseInfoVal?.belongSystemId || undefined,
      dept: tagPoolBaseInfoVal?.dept || undefined,
      customAuth: tagPoolBaseInfoVal?.customAuth || undefined,

      type: curTagVal?.tagConfigDetail?.type || undefined,
      logicDesc: curTagVal?.tagConfigDetail?.logicDesc || undefined,
      tagOptionList: curTagVal?.tagConfigDetail?.tagOptionList || undefined,
      indicatorField: curTagVal?.tagConfigDetail?.indicatorField || undefined,
      anotherField: curTagVal?.tagConfigDetail?.anotherField || undefined,
    };
    localStorage.setItem('pagelet-labelSystem-tmp-data-tagInfo', JSON.stringify(newTagVal));
    window.location.href =
      window.location.origin +
      window.location.pathname +
      '?id=' +
      tagPoolId +
      '&pageType=tagConfig&noway=true';
  };

  // 按钮组
  const renderBtns = () => {
    if (readPretty) {
      return (
        <>
          <Button
            onClick={() => {
              window.location.href =
                window.location.origin +
                '/labelSystem/version' +
                '?id=' +
                tagGroupInfo?.rootGroupId +
                '&nodeType=labelList';
            }}
          >
            {LangTranslate('标签组版本')}
          </Button>
          {!!tagGroupInfo?.editFlag && (
            <Button
              onClick={() => {
                HandelRefContextVal.current?.setVisibleLangModal?.({
                  visible: true,
                  tagPoolId,
                  afterCallBack: () => {
                    // window?.location?.reload?.();
                  },
                });
              }}
            >
              {LangTranslate('多语言配置')}
            </Button>
          )}

          <Button
            onClick={() => {
              HandelRefContextVal.current?.setVisibleChangeAffiliationModal?.({
                visible: true,
                tagPoolId,
                afterCallBack: () => {
                  fetchTagGroupInfo({ tagGroupId: tagPoolId });
                  // window?.location?.reload?.();
                },
              });
            }}
          >
            {LangTranslate('调整归属')}
          </Button>
          {!!tagGroupInfo?.editFlag && (
            <Button
              onClick={() => {
                window.location.href =
                  window.location.origin +
                  window.location.pathname +
                  '?id=' +
                  tagPoolId +
                  '&pageType=tagPoolConfig';
              }}
              type="primary"
            >
              {LangTranslate('编辑标签组')}
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        {![2, 3].includes(tagGroupInfo?.status) && (
          <Button onClick={handelSave}>{LangTranslate('保存')}</Button>
        )}
        <Button onClick={handelSubmit} type="primary">
          {LangTranslate('立即生效')}
        </Button>
      </>
    );
  };

  // 返回
  const handelReturnHome = () => {
    if (readPretty) {
      window.location.href = window.location.origin + '/labelSystem/home';
      return;
    }
    if (![2, 3].includes(tagGroupInfo?.status)) {
      const cModal = Modal.confirm({
        title: LangTranslate('确定返回'),
        content: LangTranslate('请确认是否需要保存当前编辑内容。若不保存，本次编辑内容会被丢弃。'),
        okButtonProps: {},
        className: 'pagelet-label-system-warn-confirm-wrap',
        footer: (
          <div className="pagelet-label-system-warn-confirm-footer">
            <div
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('取消')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                window.location.href = window.location.origin + '/labelSystem/home';
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('不保存并返回')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={async () => {
                try {
                  cModal?.destroy?.();
                  await handelSave();
                  window.location.href = window.location.origin + '/labelSystem/home';
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              {LangTranslate('保存并返回')}
            </div>
          </div>
        ),
      });
    } else {
      const cModal = Modal.confirm({
        title: LangTranslate('确定返回'),
        content: LangTranslate(
          '请确认是否需要生效当前编辑的内容。若不生效，本次编辑内容会被丢弃。若生效，标签版本会更新。',
        ),
        okButtonProps: {},
        className: 'pagelet-label-system-warn-confirm-wrap',
        footer: (
          <div className="pagelet-label-system-warn-confirm-footer">
            <div
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('取消')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={() => {
                window.location.href = window.location.origin + '/labelSystem/home';
                cModal?.destroy?.();
              }}
            >
              {LangTranslate('放弃本次编辑内容')}
            </div>
            <div
              data-btn-primary={true}
              className="pagelet-label-system-warn-confirm-footer-btn"
              onClick={async () => {
                try {
                  cModal?.destroy?.();
                  await handelSubmit();
                  window.location.href = window.location.origin + '/labelSystem/home';
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              {LangTranslate('生效本次编辑内容')}
            </div>
          </div>
        ),
      });
    }
  };

  return (
    <div className="pagelet-label-system-tag-pool-config-page-wrap">
      <Spin spinning={saveTagGroupLoading || fetchTagGroupInfoLoading || getTagGroupVersionLoading}>
        <div className="pagelet-label-system-tag-pool-config-page-base-info-wrap">
          <CustomInfoPanel {...(PanelConfig(readPretty) || {})} panelFormRef={panelFormRef} />
        </div>
        <div className="pagelet-label-system-tag-pool-config-page-content">
          <div className="pagelet-label-system-tag-pool-config-page-content-head">
            <CustomPageHead
              handelReturnHome={handelReturnHome}
              title={
                readPretty
                  ? LangTranslate('查看标签组')
                  : !!tagPoolId?.length
                    ? LangTranslate('编辑标签组')
                    : LangTranslate('创建标签组')
              }
              switchTxt={LangTranslate('切换至创建标签')}
              handleSwitch={handleSwitchTagConfig}
              renderBtns={renderBtns}
              switchWarnTxt={LangTranslate('切换成标签后，标签组信息将丢失，且仅保留第一个标签')}
              hasSwitch={![2, 3].includes(tagGroupInfo?.status) && !readPretty}
            />
          </div>
          <div className="pagelet-label-system-tag-pool-config-page-content-body">
            <div
              id="pagelet-label-system-tag-pool-tag-config-page-scroll-content-body"
              style={{ width: '100%', height: '100%', overflowY: 'auto', paddingBottom: '50px' }}
            >
              <CustomCard>
                {!readPretty && (
                  <div className="pagelet-label-system-tag-pool-config-page-content-body-top">
                    <div className="pagelet-label-system-tag-pool-config-page-content-body-label-wrap">
                      <div className="pagelet-label-system-tag-pool-config-page-content-body-label-tips-icon">
                        <SvgIcon type="icon-shuomingicon" size={16} />
                      </div>
                      <div className="pagelet-label-system-tag-pool-config-page-content-body-label-tips">
                        {`${LangTranslate('请根据需要，选择你需要的标签类型，并完成具体设置')}`}
                      </div>
                    </div>
                  </div>
                )}
                <FormProvider form={tagListForm}>
                  <ArrayField name="tagList" component={[CustomTagPoolCell]} />
                </FormProvider>
                {!readPretty ? (
                  <div
                    className="pagelet-label-system-tag-pool-config-page-content-body-btns"
                    onClick={handleAddTag}
                  >
                    <div className="pagelet-label-system-tag-pool-config-page-content-body-btns-add-icon">
                      <SvgIcon type="icon-add-o" size={10} pointer />
                    </div>
                    <div className="pagelet-label-system-tag-pool-config-page-content-body-btns-txt">
                      {LangTranslate('添加标签')}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </CustomCard>
            </div>
            <div className="pagelet-label-system-tag-pool-config-page-version-panel">
              {readPretty && (
                <CustomVersionPanel
                  versionList={tagPoolVersionInfo}
                  onChange={curSelectVerId => {
                    setCurrentSelectVersion(() => curSelectVerId);
                    fetchTagGroupInfo({ tagGroupId: curSelectVerId });
                  }}
                  currentSelectVersion={currentSelectVersion}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default TagPoolConfig;
