import React, { useState, useEffect, useRef } from 'react';
import { Avatar, CardIcon, EmptyRender } from '@dhr/common';
import { Spin, Tooltip, Table, Button, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import lodash from 'lodash';
import PageletApi from '@didi/pagelets-sdk';
import PageletServices from '../../services';
import { AllCharts } from '../Charts';
import ExportButton from '../ExportButton';

const HasUsed = (props) => {
  const {
    apiHost, // 域名
    auth,
    page,
    lang,
    onSetPage=()=>{},
    deptCodes, // 部门
    pageType,  //页面类型
    searchType, // 下属类别
    maxHeight,
    timeDimension,  // 时间维度
    leaveBeginTime, //开始时间
    leaveEndTime, //结束时间
    currentMonth, //
    activeTab, //切换tab
    clear,
    close = () => { },
    headers,
  } = props;
  const [loading, setLoading] = useState(false);
  const [allCharts, setAllCharts] = useState({});
  const [dataSource, setDataSource] = useState({});
  // const [tablePage, setTablePage] = useState(page);
  const [params, setParams] = useState({});
  const [sorter, setSorter] = useState({ orderByField: 8, reverseOrder: true });
  const getTeamUsed = async () => {
    let timeDimensionInfo = 2;
    if(timeDimension === 'week'){
      timeDimensionInfo = 0;
    }
    if(timeDimension === 'month'){
      timeDimensionInfo = 1;
    }
    if(timeDimension === 'date'){
      timeDimensionInfo = 3;
    }

    const paramsData = {
      pageNum: page?.pageNum,
      pageSize: page?.pageSize,
      reverseOrder: sorter?.reverseOrder,
      orderByField: sorter?.orderByField,
      timeDimension: timeDimensionInfo,
      leaveBeginTime,
      leaveEndTime,
      searchType,
      pageType,
      deptCodes
    };
    if(lodash.isEqual(params, paramsData) && !clear){
      return;
    }
    setParams(paramsData);
    setLoading(true);
    await PageletServices.getUsedTeam(apiHost, paramsData).then((res) => {
      if (!res?.data?.usedTotal) {
        setLoading(false);
        setAllCharts({});
        setDataSource([]);
        return;
      }
      const {
        totalDaysOff,
        totalPeopleOff,
        annualDaysOff,
        sickDaysOff,
        personalDaysOff,
        broodPouchDaysOff,
        familyDaysOff,
        maternityDaysOff,
        accompanyingMaternityDaysOff,
        funeralDaysOff,
        annualLeaveUndo,
        sickLeaveUndo,
        personalLeaveUndo,
        broodPouchLeaveUndo,
        familyLeaveUndo,
        maternityLeaveUndo,
        accompanyingMaternityLeaveUndo,
        funeralLeaveUndo,
        prenatalCheckupDaysOff,
        prenatalCheckupLeaveUndo,
        broodPouchBackDaysOff,
        broodPouchLeaveBackUndo,
        maritalDaysOff,
        maritalLeaveUndo
      } = res?.data?.usedTotal;
      // setAllCharts({ usedDayStatistcsVo, quarterlyUsedDaysVO });
      const daysOff = [
        annualDaysOff, sickDaysOff, personalDaysOff, broodPouchDaysOff, familyDaysOff, maritalDaysOff, maternityDaysOff, accompanyingMaternityDaysOff, prenatalCheckupDaysOff, broodPouchBackDaysOff, funeralDaysOff
      ]
      const leaveUndo = [
        annualLeaveUndo, sickLeaveUndo, personalLeaveUndo, broodPouchLeaveUndo, familyLeaveUndo, maritalLeaveUndo, maternityLeaveUndo, accompanyingMaternityLeaveUndo, prenatalCheckupLeaveUndo, broodPouchLeaveBackUndo, funeralLeaveUndo
      ]
      setAllCharts({ totalDaysOff, totalPeopleOff, daysOff, leaveUndo });
      setDataSource(res?.data?.usedList);
      setLoading(false);
    }, () => {
      setLoading(false);
    })
  }

  useEffect(() => {
    if (activeTab === 'hasHoliday' && deptCodes?.length > 0 && page && sorter) {
      getTeamUsed();
    }
  }, [activeTab, deptCodes, searchType, page, timeDimension, leaveBeginTime, leaveEndTime, sorter, clear]);


  const sorterList = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    if (sorter.order) {
      setSorter({ orderByField: sorter?.column?.sorter, reverseOrder: sorter?.order === 'ascend' ? true : false })
    } else {
      setSorter({ orderByField: 8, reverseOrder: true })
    }
  }
  const openDraw = (empId) => {
    const drawer = PageletApi.drawer({
      title: '员工休假详情',
      width: '600px',
      pageletName: '@didi/pagelet-employee-leave-details',
      // pageletUrl: '//127.0.0.1:9004/employee-leave-details.runtime.js', // 使用pageletUrl
      pageletProps: { empId, auth, headers }, // 透传给pagelet的props
      onClose: () => {
        // 点击关闭
        drawer.destroy();
      }
    });
    drawer.show();
  }
  const usedHoliday = [
    {
      title: dataSource?.titles?.employeeName,
      dataIndex: 'employeeName',
      width: 180,
      ellipsis: true,
      render: (text, records) => {
        return (
          <Tooltip title={`${records?.employeeName}(${records?.ldap})`}>
            <div className="detail name-ldap" onClick={() => openDraw(records?.employeeId)}>
              <Avatar src={records?.employeePhoto} size={32} />
              <span style={{ marginLeft: '4px' }}>{`${records?.employeeName}(${records?.ldap})`}</span>
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: dataSource?.titles?.employeeId,
      dataIndex: 'employeeId',
      width: 80,
      ellipsis: true
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.totalDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.totalDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'totalDaysOff',
      width: 100,
      sorter: 8
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.annualDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.annualDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'annualDaysOff',
      width: 80,
      sorter: 5
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.sickDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.sickDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'sickDaysOff',
      width: 80,
      sorter: 6
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.personalDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.personalDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'personalDaysOff',
      width: 80,
      sorter: 7
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.maritalDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.maritalDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'maritalDaysOff',
      width: 80,
      sorter: 17
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.prenatalCheckupDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.prenatalCheckupDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'prenatalCheckupDaysOff',
      width: 90,
      sorter: 11
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.maternityDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.maternityDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'maternityDaysOff',
      width: 80,
      sorter: 10
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.accompanyingMaternityDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.accompanyingMaternityDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'accompanyingMaternityDaysOff',
      width: 90,
      sorter: 12
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.broodPouchDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.broodPouchDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'broodPouchDaysOff',
      width: 90,
      sorter: 15
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.broodPouchBackDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.broodPouchBackDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'broodPouchBackDaysOff',
      width: 115,
      sorter: 16
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.familyDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.familyDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'familyDaysOff',
      width: 90,
      sorter: 14
    },
    {
      title: <>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 10 }}>{dataSource?.titles?.funeralDaysOff.split('\n')[0]}</div>
        <div>{dataSource?.titles?.funeralDaysOff.split('\n')[1]}</div>
      </>,
      dataIndex: 'funeralDaysOff',
      width: 80,
      sorter: 13
    }
  ]


  return (
    <>
    <ConfigProvider locale={lang !== 'zh-CN' ? enUS : zhCN} prefixCls='ant5' theme={{ token: { colorPrimary: '#ff6400' } }} >
      <Spin spinning={loading}>
        {
          <div className="holiday-not-used-page" style={{ height: maxHeight, overflow: 'hidden', overflowY: 'scroll' }}>
            {/* {children} */}

            <div className="holiday-charts">
              {/* 总览 */}
              <AllCharts chartsData={allCharts} timeDimension={timeDimension} currentMonth={currentMonth} />
            </div>
            <div className="holiday-total-year-detail">
              <div className="holiday-total-year-detail-title">休假明细</div>
              <div className="total-days-export">
                {dataSource?.list?.length > 0 ? (
                  <ExportButton
                    url="/gateway/bricks/api/unit/holiday/team/used/export"
                    data={{
                      fileName: " 团队已休假汇总",
                      export: 1,
                      ...params,
                    }}
                    headers={headers}
                    encryption={true}
                    defaultFile=" 团队已休假汇总"
                    text={<><CardIcon type="icon-file-export-o" />导出</>}
                    closeDrawer={close}
                  >
                  </ExportButton>
                ) : (
                  <Button className="disabled-btn" disabled={true}><CardIcon type="icon-file-export-o" /> 导出 </Button>
                )}
              </div>
            </div>
            <div className="holiday-detail-table-wrapper">
              <EmptyRender>
                <Table
                  dataSource={dataSource.list}
                  columns={usedHoliday}
                  rowKey={record => record.id}
                  scroll={{ x: 1800 }}
                  showSorterTooltip={false}
                  onChange={sorterList}
                  size="small"
                  pagination={{
                    total: dataSource.total,
                    current: page.pageNum,
                    pageSize: page.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50],
                    onChange: (current, size) => {
                      onSetPage({
                        pageNum: current,
                        pageSize: size
                      });
                    }
                  }}
                />
              </EmptyRender>
            </div>
          </div>
        }
      </Spin>
    </ConfigProvider>
      {/* <HolidayHistory
        closeDrawer={() => setVisible(false)}
        visible={visible}
        empId={empId}
      /> */}
    </>
  );
};

export default HasUsed;
