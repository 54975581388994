// pc蓝色主题问卷进度条
import React from "react";
import {Progress} from 'antd';
import { useSelector } from "react-redux";
import './index.scss';

const questionnaireProcessPcBlue = props => {
  const {
    processRate,
  } = useSelector((state) => state.questionContent);

  return (
    <div className="process-pc-wapper-blue">
      <Progress
        percent={processRate?.progressRate}
        type="circle"
        strokeColor={{
          '0%': '#18A9FB',
          '100%': '#0A70F5',
        }}
        size="small"
        strokeWidth={8}
      />
      {/* 进度数 */}
      {/* <div className="process-pc-rate">
        {processRate?.replySuccessSubjectCount}/{processRate?.allSubjectCount}题
      </div> */}
    </div>
  );
};

export default questionnaireProcessPcBlue;
