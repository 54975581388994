import React, { isValidElement } from 'react';
import ReactDOM from 'react-dom';
import { navigateToUrl as navigateToUrlSpa } from 'single-spa';
import { ConfigProvider, locales } from 'antd';
import { Provider } from 'react-redux';


const singleSpaReact = require('single-spa-react').default;

/**
 * 创建子应用
 * @param {*} params 
 */

const createApplication = (params) => {
  let Component;
  if (typeof params === 'function') {
    Component = params;
  } else if (typeof params === 'object' && params.component) {
    Component = params.component;
  }

  return singleSpaReact.default({
    React,
    ReactDOM,
    rootComponent: (props) => (
      <Provider  store={props.store}>
        <ConfigProvider locale={locales.zh_CN} getPopupContainer={node => {
          const cls = node?.className ||''
          // 只给select和时间加了怕影响tooltip
          if (cls && (cls.includes('ant-picker')||cls.includes('ant-select'))) {
            return node.parentNode;
            }
            return document.body;
          }}>
          <Component {...props} />
        </ConfigProvider>
      </Provider>
    )
  });
}

const navigateToUrl = (url, opts) => {
  const params = Object.assign({
    render: true,
  }, opts);
  if (!params?.render) {
    window.history.pushState({
      render: params.render,
    }, '', url);
  } else {
    navigateToUrlSpa(url);
  }
}

export {
  createApplication,
  navigateToUrl,
}
