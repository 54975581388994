import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // post请求示例
  getStatistics: async (host, data, headers) => {
    const result = await PageletApi.request(`${host}/${apis.getStatistics}`, {
      method: 'post',
      data,
      headers
    });

    return result;
  }
}

export default PageletServices;
