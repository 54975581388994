import React, { useState, useEffect } from 'react';
import { Tooltip } from '@didi/ec-base';
import classNames from 'classnames';
import { handlePostData, getCurDeptInfo } from '../../utils/utils';
import useStyle from './styles';

const PostSelect = props => {
  const { activedDeptInfo = {}, deptList = [], setActivedDeptInfo } = props;
  const [showTree, setShowTree] = useState(false); // 初始化loading状态
  const [options, setOptions] = useState([]); // 默认值

  // 切换
  const handleItem = async id => {
    if (activedDeptInfo.id === id) return;
    setShowTree(false);
    console.log(getCurDeptInfo(options, id));
    setActivedDeptInfo(getCurDeptInfo(options, id));
  };

  const handleOption = (optionsChild, id) => {
    optionsChild.forEach(ele => {
      if (ele.id === id) {
        ele.showChild = !ele.showChild;
      }
      if (ele.childrenNode && ele.childrenNode.length > 0) {
        handleOption(ele.childrenNode, id);
      }
    });
  };

  const handleItemShow = (e, id) => {
    e.stopPropagation();
    const _options = JSON.parse(JSON.stringify(options));
    handleOption(_options, id);
    setOptions(_options);
  };

  useEffect(() => {
    setOptions(handlePostData(deptList));
  }, [deptList]);

  // 下一层级
  const postChildren = item => {
    return (
      <>
        {item.childrenNode.length ? (
          <div
            className="showIcon"
            style={{
              color: item.showChild ? '#545659' : '#ADACAC',
            }}
            onClick={e => handleItemShow(e, item.id)}
          >
            {item.showChild ? (
              <img
                className="downIcon"
                src="//img-ys011.didistatic.com/static/ddo_web_static/do1_n5JadCMq17NRscFx9qGJ"
              />
            ) : (
              <img
                className="downIcon"
                src="//img-ys011.didistatic.com/static/ddo_web_static/do1_3UKtMuMIxUU8keYT6AsH"
              />
            )}
          </div>
        ) : null}
      </>
    );
  };

  // 标签
  const postSelectTag = item => {
    return (
      <>
        {/* 部门标签 */}
        {item.positionTags && item.manager && item.group ? (
          <>
            {item.roleNameOwner ? (
              <span className="dept-title">
                <Tooltip placement="topLeft" title={'负责人'}>
                  <span>负责人</span>
                </Tooltip>
              </span>
            ) : (
              <span className="dept-title">
                <Tooltip placement="topLeft" title={item.roleDesc}>
                  <span className="item_roleDesc">{item.roleDesc}</span>
                </Tooltip>
              </span>
            )}
          </>
        ) : null}
      </>
    );
  };

  // 下一层级
  const postSelectItem = (main, postFirst) => {
    return main.showChild ? (
      <div style={{ paddingLeft: postFirst !== 'first' ? '16px' : '0' }}>
        {main?.childrenNode?.map((item, index) => {
          return (
            <div className="post-select" key={item.id + index}>
              <div className={item.id === activedDeptInfo.id ? 'side-item-active' : 'side-item'}>
                <div className="side-item-content" onClick={() => handleItem(item.id)}>
                  <div
                    className="side-item-content-line1"
                    style={{
                      backgroundColor: item.notShow1 ? 'rgba(0,0,0,0)' : '#D8D8D8',
                    }}
                  >
                    <div
                      className="side-item-child-line2"
                      style={{
                        borderBottomLeftRadius: item.notShow1 ? '12px' : '0',
                      }}
                    ></div>
                  </div>
                  <div className="beyond_wrap">
                    <div className="span-beyond">
                      <Tooltip placement="topLeft" title={item.deptName}>
                        {item.deptName}
                      </Tooltip>
                      {postSelectTag(item)}
                    </div>
                  </div>
                  {postChildren(item)}
                </div>
              </div>
              {/* 下一层级 */}
              {postSelectItem(item)}
            </div>
          );
        })}
      </div>
    ) : null;
  };

  // 样式
  const prefixCls = 'post-select-com';
  const wrapSSR = useStyle('post-select-com');
  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      <div className="dept_wrap">
        <div
          className={`dept ${options.length > 1 || options[0]?.childrenNode?.length > 0 ? 'dept_more' : ''}`}
          onClick={() => {
            setShowTree(true);
          }}
        >
          <div className="dept_wrap_hover">
            <Tooltip placement="topLeft" title={activedDeptInfo.deptName}>
              <div className="dept_name">{activedDeptInfo.deptName}</div>
            </Tooltip>
            {(options.length > 1 || options[0]?.childrenNode?.length > 0) && (
              <img
                className="downIcon"
                src="//img-ys011.didistatic.com/static/ddo_web_static/do1_n5JadCMq17NRscFx9qGJ"
              />
            )}
          </div>
        </div>
        {/* 岗位选择 */}
        {showTree && (options.length > 1 || options[0]?.childrenNode?.length > 0) ? (
          <>
            <div
              class="mark"
              onClick={() => {
                setShowTree(false);
              }}
            ></div>
            <div className="post_wrap">
              {options?.map((item, index) => {
                return (
                  <div key={index}>
                    {/* 一级 */}
                    <div className="post-select">
                      <div
                        className={
                          item.id === activedDeptInfo.id ? 'side-item-active' : 'side-item'
                        }
                      >
                        <div className="side-item-content" onClick={() => handleItem(item.id)}>
                          <div className="beyond_wrap">
                            <div className="span-beyond">
                              <Tooltip placement="topLeft" title={item.deptName}>
                                {item.deptName}
                              </Tooltip>
                              {postSelectTag(item)}
                            </div>
                          </div>
                          {postChildren(item)}
                        </div>
                      </div>
                      {/* 后续层级 */}
                      {postSelectItem(item, 'first')}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </div>,
  );
};

export default PostSelect;
