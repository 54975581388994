import React, { useState, useEffect } from 'react';
import { TextareaItem } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { di18n, CardIcon } from '@ultra/common';
import classnames from 'classnames';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.scss';
import {
  setGeneralData,
  setValidateResult,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
import { textHeight } from '../../utils/const';

const { intl } = di18n;

const textareaWidgetMobile = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    attributeData,
    respondentsGroup,
    validateFlag,
    surveyBusType,
    currentRespondentCode
  } = useSelector((state) => state.questionContent);
  // console.log('processRate', processRate);
  // console.log('validateRules', validateRules);
  // console.log('generalData', generalData);
  const dispatch = useDispatch();

  const {
    label,
    labelStyle,
    tips,
    hidden,
    number,
    related,
    required,
    hasSerialNumber
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];

  const [inputValue, setInputValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);

  // 题目描述的展开收起
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    if (tips && textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 200) {
      setExpand(false);
    }
  }, []);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData && draftData[widgetKey]) {
      const widgetDraftData = draftData[widgetKey];
      const draftItem = widgetDraftData[currentRespondentCode];
      if (draftItem.length > 0) {
        const input = draftItem[0].value;
        setInputValue(input);

        // 初始化赋值&校验
        setValueAndValidate(draftItem);
      }
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag])

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (inputValue) {
        setInputValue(null);
      }
    }
  }, [hidden, initFlag, inputValue])

  const setValueAndValidate = (paramData) => {
    const { value } = paramData[0];
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: paramData }));
    dispatch(updateCurrentSubject({ data: widgetKey }));
    // 数据校验
    const widgetValidate = validateFun(rules, value);
    dispatch(setValidateResult({ id: widgetKey, data: widgetValidate }));
  }


  const handleChangeInput = (e) => {
    setInputValue(e.length > 2000 ? e.slice(0,2000) : e);
  }
  const handleBlur = (v) => {
    const data = [
      {
        option: '',
        value: inputValue ? inputValue.replace(/(\s*$)/g, '') : ''
      }
    ]
    setUpdateData(data);
  }

  // 校验方法
  const validateFun = (rule, content) => {
    const { required } = rule;
    const { max, min } = rules.controlRegular;
    const msgArr = [
      intl.t('亲，此项必填的哈~')
    ]

    if (max && max !== 0) {
      if (content && (content.length > max)) {
        return {
          error: false,
          msg: msgArr[0]
        }
      }
    }

    if (min && min !== 0) {
      if (content && (content.length < min)) {
        return {
          error: false,
          msg: msgArr[0]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[0]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const handleExpand = () => {
    setExpand(!expand);
  };

  const formatterCount = (e = '') => {
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    let max = rules.controlRegular.max ? rules.controlRegular.max : 2000;
    if (rules.controlRegular.min && Number(rules.controlRegular.min)) {
      return `${intl.t('请至少输入{num}字', { num: rules.controlRegular.min })}, ${count}/${max}`;
    }
    return `${count}/${max}`;
  };

  const renderInput = (optionKey) => {
    return (
      <div className={classnames("radio-textarea", {
          'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
          'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
        })}>
        <TextareaItem
          className={validateResult[widgetKey]?.otherResult ?
            (validateResult[widgetKey]?.otherResult[optionKey]?.error ? '' : 'ct-textarea-status') : ''}
          key={widgetKey + 'textarea'}
          rows={4}
          onChange={handleChangeInput}
          onBlur={handleBlur}
          // count={rules.max ? rules.max : 2000}
          value={inputValue}
        />
        <p className="ct-textarea-text">
          {formatterCount(inputValue)}
        </p>
      </div>
    )
  }

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="ct-questionnaire-mobile-error ct-questionnaire-mobile-textarea-error error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden} question-related-${related}`}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={'div-label'}
          style={{ fontSize: '14px' }}
        >
          <div className={`radio-div-label-${required}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div className="m-number">{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-content radio-content-related-${related}`}>
          {tips ? (
            <div className="mobile-question-tip">
              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && !expand ? (
                  <>
                    {decodeURIComponent(tips).length > 100 ? `${decodeURIComponent(tips).slice(0, 60)}...` : `${decodeURIComponent(tips).slice(0, 54)}...`}
                  </>
                ) : (
                  <>{decodeURIComponent(tips)}</>
                )
              }

              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && (
                  <div onClick={handleExpand}>
                    {expand
                      ? (
                        <div className='expand-btn-container'>
                          {intl.t('收起')}
                          <CardIcon type="iconshouqi1" className="expand-icon" />
                        </div>
                      ) : (
                        <div className='expand-btn-container'>
                          {intl.t('查看全部')}
                          <CardIcon type="iconxiala2" className="expand-icon"/>
                        </div>
                      )}
                  </div>
                )
              }
            </div>
          ) : null}
          {renderInput()}
        </div>

        {renderErrorMsg()}
      </div>
      {/* <Divider dashed /> */}
    </>
  );
};

export default textareaWidgetMobile