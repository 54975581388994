import React, { useCallback, useContext, useState, useEffect } from 'react';
import {
  getObjectAndDimsTree,
  getTagBelongInfo,
  updateTagGroupBelong,
  getAllSubSystemInfo,
} from '@/services';
import { BricksInfoContext } from '@/context';
import { useRequest } from 'ahooks';

export const useObjAndDimData = () => {
  const BricksInfoContextVal = useContext(BricksInfoContext);

  return useRequest(
    async _ => {
      try {
        const res = await getObjectAndDimsTree({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: { tagFlag: false },
          usePermissionResponse: BricksInfoContext?.usePermissionResponse,
        });
        return [
          res?.data?.subjectDtoList || [],
          res?.data?.subjectDtoList?.map(it => ({
            name: it?.name,
            id: it?.id,
            disabled: true,
            subDimensionList: it?.subDimensionList,
          })),
        ];
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { refreshDeps: [BricksInfoContextVal] },
  );
};
