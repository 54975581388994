import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {
  return useRegisterStyle('pagelet-objective-overview', () => [
    {
      // base
      [`.${prefixCls}`]: {
        color: chartToken.$font_color,
        fontSize: variable.size,

        ['.spin']: {
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
      },
    },
  ]);
};

export default useStyle;
