import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-multi-dimensional-feedback', () => [
    {
      ['.pagelet-multi-dimensional-feedback-select-item']: {
        fontSize: '14px!important',
      },

      ['.multi-dimensional-feedback-tabs-extra-tooltip']: {

        ['&>div']: {
          display: 'table-row',
          fontFamily: 'PingFang SC',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '18px',

          ['&>span:nth-of-type(1)']: {
            display: 'table-cell',
            textAlign: 'right',
            color: 'rgba(255, 255, 255, 0.8)',
          },

          ['&>span:nth-of-type(2)']: {
            marginLeft: '6px',
            color: '#fff',
          }
        }
      },
			// base
      [`.${prefixCls}`]: {
        minHeight: '266px',

        ['.cycle-select']: {

          ['>span']: {
            fontFamily: 'PingFang SC, Heiti SC, Hiragino Sans GB, Sans-serif',
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '22px',
            color: 'rgba(0, 0, 0, 0.6)',
            marginRight: '8px',
          },
          ['.ant5-select-selection-item']: {
            fontSize: '14px',
          }
        },

        ['.multi-dimensional-feedback-tabs']: {
          marginTop: '16px',

          ['.ant5-tabs-tab']: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '14px',
          },

          ['.ant5-tabs-tab+.ant5-tabs-tab']: {
            marginLeft: '12px',
          },

          ['.ant5-tabs-tab-active']: {
            borderRadius: '10px 10px 0px 0px',
            background: '#EFF2F9'
          },

          ['.multi-dimensional-feedback-tabs-panel']: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',

            ['&>div:not(:nth-last-child(-n+2))']: {
              marginBottom: '20px',
            },
          },

          ['.multi-dimensional-feedback-tabs-extra']: {
            display: 'flex',
            alignItems: 'center',

            ['&-content']: {
              display: 'flex',
              borderRadius: '6px',
              height: '22px',
              alignItems: 'center',
              padding: '0 6px',
              color: 'rgba(0, 0, 0, 0.84)',
              cursor: 'pointer',

              ['&:hover']: {
                background: 'rgba(0, 0, 0, 0.04)'
              },

              ['.recovery-rate-1']: {
                fontSize: '16px',
                fontWeight: 500,
                marginLeft: '4px',
              },

              ['.recovery-rate-2']: {
                fontSize: '16px',
                fontWeight: 600,
                marginLeft: '6px',
              }
            },
          }

        },

        ['.multi-dimensional-feedback-card']: {
          width: 'calc(50% - 12px)',
          height: '268px',
          background: 'linear-gradient(0deg, #FFFFFF 50%, #F0F6FF 97%), linear-gradient(191deg, #F6F9FF 14%, #FFFFFF 75%)',
          borderRadius: '10px',

          ['.multi-dimensional-feedback-card-head-question-title']: {
            display: 'flex',
            lineHeight: '22px',
            color: '#212529',

            ['>span:nth-of-type(2)']: {
              marginLeft: '6px',
              wordBreak: 'break-all',
              whiteSpace: 'break-spaces',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              'WebkitBoxOrient': 'vertical',
              'WebkitLineClamp': '2',
              overflow: 'hidden'
            }
          },

          ['.ant5-card-body']: {
            padding: '0px'
          },

          ['.multi-dimensional-feedback-card-body-answer']: {
            padding: '16px 20px',
            height: '212px',
            overflow: 'auto',

            ['.answer-content']: {
              fontFamily: 'PingFang SC',
              color: 'rgba(0, 0, 0, 0.84)',
              lineHeight: '20px',
              marginBottom: '12px',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all'
            }
          }
        }
			}
		}
	]);
};

export default useStyle;
