import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getLocale, Spin } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import * as antd from 'antd';
// import { Carousel } from 'react-responsive-carousel'; // 轮播图组件
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NextArrow, PrevArrow } from './CustomArrows'; // 导入自定义箭头组件
import DefaultCard from './components/DefaultCard'; // 卡片布局
import Organization from './components/Organization'; // 看组织图表
import Change from './components/Change'; // 看变动图表
import Atmosphere from './components/Atmosphere'; // 看氛围图表
import PageletApi from '@didi/pagelets-sdk';

import useStyle from './style';
import lockUrl, { ReactComponent as Lock } from './assets/lock.svg';
// 图片建议手动上传到 gift 使用
// import comment from './assets/comment.png';

const localLocales = manifest.locales || {};
const { ConfigProvider } = antd;
// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost = '//dhr-dev.intra.xiaojukeji.com', headers, locales, pageCode, permissionScope, auth, deptCodes, item },
  } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth?.id,
  };
  PageletApi.newRequest.init(newHeaders, '@didi/pagelet-canghai-carousel');
  const prefixCls = 'pagelet-canghai-carousel';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  const [data, setData] = useState([]); // 卡片数据
  const [loading, setLoading] = useState(true); // loading
  const [width, setWidth] = useState();
  const [currentIndex, setCurrentIndex] = useState(0); // 轮播索引

  const handleRequest = async () => {
    try {
      const data = await PageletServices.getCanghaiCarouselData(apiHost, { deptCodes: deptCodes.join(), pageType: pageCode });
      const res = data?.data;
      if (res && JSON.stringify(res) !== '{}') {
        const list = [
          {
            sign: 'organization',
            title: '看组织',
            desc: res?.organization?.defaultFlag === false ? '团队平均职级' : '洞察组织架构和人才分布，从不同维度看清组织情况和团队人才布局。',
            digitalize: res?.organization?.defaultFlag === false ? <div className='digitalize-organization'>{res?.organization?.average}</div> : null,
            diagram: <Organization showImg={res?.organization?.defaultFlag} data={res?.organization?.data} />,
            naviUrl: res?.organization?.naviUrl,
            defaultFlag: res?.organization?.defaultFlag
          },
          // {
          //   sign: 'talent',
          //   title: '看人才',
          //   desc: '直观看清员工职业发展、成长轨迹、标签评价等档案信息，支持全面了解小桔子。',
          //   diagram: <div className='echarts-talent-img' style={{ backgroundImage: "url('//img-hxy021.didistatic.com/static/ehr_static/imgs/canghai/cards/zh_CN/2.png')" }} />,
          //   naviUrl: res?.talent?.naviUrl
          // },
          {
            sign: 'change',
            title: '看变动',
            desc: res?.change?.defaultFlag === false ? '团队YTD离职(年初至今)' : '专注团队人才招聘、入离职管理、岗位调整与团队成员职级变动分析。',
            digitalize: ( res?.change?.defaultFlag === false ?
              <div className='digitalize-change'>
                {res?.change?.terminationSum}
                <span>人次</span>
              </div> : null
            ),
            data: res?.change,
            // diagram: <Change showImg={res?.change?.defaultFlag} data={res?.change?.data} />,
            naviUrl: res?.change?.naviUrl,
            defaultFlag: res?.change?.defaultFlag
          },
          {
            sign: 'atmosphere',
            title: '看氛围',
            desc: res?.atmosphere?.defaultFlag === false ? '团队eNPS值' : '聚焦eNPS推荐指数和团队休假，关注团队凝聚力，体现员工关怀。',
            digitalize: ( res?.atmosphere?.defaultFlag === false ?
              <div className='digitalize-atmosphere'>
                <div>{res?.atmosphere?.data[res?.atmosphere?.data?.length - 1]?.companyEnps?.split('%')[0]}<span>%</span><p>公司</p></div>
                <div>{res?.atmosphere?.data[res?.atmosphere?.data?.length - 1]?.deptEnps?.split('%')[0]}<span>%</span><p>部门</p></div>
              </div> : null
            ),
            data: res?.atmosphere,
            // diagram: <Atmosphere data={res?.atmosphere?.data} showImg={res?.atmosphere?.defaultFlag} />,
            naviUrl: res?.atmosphere?.naviUrl,
            defaultFlag: res?.atmosphere?.defaultFlag
          }
        ];
        setData(list);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  // 计算轮播图父元素的宽度
  const handleResize = useCallback(() => {
    const grandParentDom = document.getElementById('gridContainer');
    if (!(grandParentDom instanceof Element)) return;

    const parentDom = grandParentDom.querySelectorAll('#pagelet-canghai-carousel');
    if (parentDom.length) {
      const newWidth = (grandParentDom.offsetWidth - (16 * (3 - item?.cardWidth))) / 3 * item?.cardWidth;
      setWidth(newWidth);
      parentDom.forEach(p => {
        p.style.width = `${newWidth}px`;
      });
    }
  }, [item?.cardWidth]);

  useEffect(() => {
    const grandParentDom = document.getElementById('gridContainer');
    if (!(grandParentDom instanceof Element)) return;

    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === grandParentDom) {
          handleResize();
        }
      }
    });

    observer.observe(grandParentDom);

    handleResize();

    return () => {
      observer.disconnect();
    };
  }, [handleResize]);

  // // 轮播图底部按钮
  // const CustomIndicator = (onClick, selected, index) => {
  //   const selectedStyle = selected ? { backgroundColor: 'rgba(38, 17, 6, 0.8)' } : {};
  //   return (
  //     <li key={index} onClick={onClick}>
  //       <div style={{ ...selectedStyle }} />
  //     </li>
  //   );
  // };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000 * 2.6,
    cssEase: "ease",
    arrows: true, // 启用箭头
    beforeChange: (current, next) => {
      setCurrentIndex(next);
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return wrapSSR(
    <div className={classes} id='pagelet-canghai-carousel' style={{ width: width + 'px' }}>
      <ConfigProvider locale={locale}>
        { loading
          ? <Spin loading={loading} />
          : <>
             <Slider {...settings}>
              {data?.map(item => (
                <DefaultCard
                  item={item}
                  diagram={
                    item?.sign === 'change'
                    ? <Change showImg={item?.data?.defaultFlag} data={item?.data?.data} width={width} />
                    : item?.sign === 'atmosphere'
                      ? <Atmosphere showImg={item?.data?.defaultFlag} data={item?.data?.data} width={width} />
                      : item?.diagram
                  }
                />
              ))}
             </Slider>
             <div className='canghai-current-index'>{currentIndex + 1}/{data?.length}</div>
            </>
        }
      </ConfigProvider>
    </div>,
  );
};

export default Pagelet;
