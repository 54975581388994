import React, { useContext } from 'react';
import { Radio } from '@didi/ec-base';
import { PublicStore } from '../../../../utils/utils';
import PageletServices from '../../../../services';  //页面请求方法
import classNames from 'classnames';
import useStyle from './styles';

const BarChart = () => {
  // 样式
  const prefixCls = 'bar-chart';
  const wrapSSR = useStyle('bar-chart');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const { distributionCode, distributions, searchType, searchValue } = stateStore;
  const {
    params: { deptCodes = [] },
  } = props;

  // 人员列表请求
  const requestPersonList = async progressDistributionCode => {
    try {
      const { data } = await PageletServices.postSearch('', {
        tabType: 'MANAGE',
        pageNum: Number(1),
        pageSize: Number('10'),
        deptCodes,
        progressDistributionCode,
        searchType,
        selectEmpIds: searchValue ? [searchValue] : []
      });
      const { data: { distributions } } = await PageletServices.postDistribution('', {
        tabType: 'MANAGE',
        pageNum: Number(1),
        pageSize: Number('10'),
        deptCodes,
        searchType,
        selectEmpIds: searchValue ? [searchValue] : []
      });
      stateDispatch({
        distributions,
        pageNum: data.pageNum,
        list: [...data?.list],
        hasMore: data.list.length < data?.total,
        resultLoading: false
      });
    } catch (error) {
      stateDispatch({
        pageNum: Number('1'),
        list: [],
        resultLoading: false
      });
    }
  };

  return wrapSSR(
    <div className={classes}>
      {distributions?.map((item, index) => {
        const { code, desc, num, rate } = item;
        const decimalPoint = rate.includes('.');
        let str = decimalPoint ? rate.split('.')[0] : 0;
        return (
          <div className="eachState">
            <div className={`graphical ${code === distributionCode && 'checked'}`}>
              <p className="numericalValue">
                <span className="num">{num || 0}</span>
                <span className="people">人</span>
                <span className="percentage">{rate}</span>
              </p>
              <div
                className="bar"
                style={{
                  height: `${parseInt(175 * Number(str / 100), 10)}px`,
                  background: [0, 1].includes(index)
                    ? 'linear-gradient(180deg, #FFDDC8 -3%, #FF8E45 70%)'
                    : 'linear-gradient(180deg, #75D9AE 0%, #00B365 100%)',
                }}
              />
            </div>
            <div className="line" />
            <Radio
              value={code}
              checked={code === distributionCode}
              onChange={e => {
                stateDispatch({
                  distributionCode: e.target.value,
                  distributionName: desc,
                  resultLoading: true
                });
                requestPersonList(e.target.value);
              }}
            >
              {desc}
            </Radio>
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
