import { isPre, isProd } from './env';
// 测试：
// 我的工作台 691
// HR工作台 694

// stage
// 我的工作台 696
// HR工作台 699

// 生产
// 我的工作台 425
// HR工作台 429
// 团队信息
export const getFunctionId = (pageCode)=> {
    if(isProd()){
        return pageCode === 'management'?'425':'429';
    }
    if(isPre()){
        return pageCode === 'management'?'696':'699';
    }

    return pageCode === 'management'?'691':'694';
};
// 团队成员
// test：
// 我的工作台-团队成员 693
// HR工作台-团队成员 1212
// stage：
// 我的工作台-团队成员 698
// HR工作台-团队成员 1020
// 生产：
// 我的工作台-团队成员 427
// HR工作台-团队成员 616
export const getMembersFunctionId = (pageCode) => {
  if(isProd()){
    return pageCode === 'management'?'427':'616';
  }
  if(isPre()){
    return pageCode === 'management'?'698':'1020';
  }

  return pageCode === 'management'?'691':'694';
};

