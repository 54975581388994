import React, { useEffect, useRef, useState, useMemo } from 'react';
import PerformanceRecordsModal from './PerformanceRecordsModal';
import { useSize, useDebounceFn } from 'ahooks';
import { getLang, LineChart } from '@didi/ec-base';

// 折线图
const Chart = (props) => {
  const {
    xAxisData,
    yAxisData,
    yAxisTitle,
    lineColor,
    data,
    locale,
    position = 'top',// 图表位置在X坐标轴上/下方
    saveOperateLog
  } = props;

  const chartRef = useRef(null);
  const myChart = useRef(null);

  const [visible, setVisible] = useState(false);
  const [evaluateInfo, setEvaluateInfo] = useState({});

  const options = useMemo(() => {
    // 反馈y轴对应的枚举
    const feedbackYAxis = {
      [locale.NeedToImprove]: 'C',
      [locale.MeetExpectations]: 'B',
      [locale.AboveExpectations]: 'A',
    };
    const reg = new RegExp(/^(\d{4})-(\d{2})-(\d{2})$/);
    const yData = data.map(item => {
      return ((item && !reg.test(item?.year)) ? {
        value: (yAxisTitle === locale?.feedback && feedbackYAxis[item?.grade]) ? feedbackYAxis[item?.grade] : item?.gradeCode,
        label: item?.grade,
        isAppeal: item?.isAppeal,
        appealResult: item?.appealResult,
        hasEvalButton: !!item?.managerEvaluation
      } : null)
    });
    // 离职下标
    const offboardingIndexList = [];
    const offboardingData = [];
    data.forEach((element,index) => {
      if (element && reg.test(element?.year)) {
        offboardingIndexList.push(index);
        offboardingData.push({
          value: 16,
          itemStyle: { color: 'rgba(0, 0, 0, 0.04)' }
        });
      } else {
        offboardingData.push(null);
      }
    });

    const lineSeries = {
      type: 'line',
      connectNulls: true,
      itemStyle: {
        color: lineColor,
      },
      symbolSize: 8,
      label: {
        show: true,
        position: 'top',
        color: 'rgba(0, 0, 0, 0.84)',
        fontSize: 12,
        fontWeight: 'bolder',
        formatter: function (params) {
          const { data } = params;
          if (data?.isAppeal === '1') {
            return `${data?.label} {escalate|${locale?.escalate}}`
          }
          return data?.label;
        },
        rich: {
          escalate: {
            color: '#0A70F5',
            fontSize: 12,
            lineHeight: 20,
            backgroundColor: '#fff',
            align: 'right',
            padding: [3, 4, 3, 4],
            shadowColor: 'rgba(126, 143, 143, 0.2)',
            shadowBlur: 2,
            shadowOffsetY: 1,
            borderRadius: 4
          }
        }
      }
    };
    let list = [];
    if (offboardingIndexList?.length > 0) {
      offboardingIndexList.push(data.length - 1)
      list = offboardingIndexList.reduce((prev, curr, index) => {
        const seriesListData = yData.filter((_, i) => i <= curr).map((item, itemIndex) => index === 0 ? item : (itemIndex < offboardingIndexList[index - 1] ? null : item));
        return prev.concat({ ...lineSeries, data: seriesListData, z: 2 });
      }, []);
    } else {
      list.push({ ...lineSeries, data: yData, z: 2 });
    }
    const markAreaData = data.filter(item => reg.test(item?.year))?.map(item => [{ name: locale?.Offboarding, xAxis: item?.year }, { xAxis: item?.year }]);

    return {
      grid: {
        top: position === 'top' ? 40 : 0,
        bottom: position === 'top' ? 20 : 0,
        left: position === 'top' ? 30 : 0,
        right: 0,
        background: '#FBFCFE',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: false,
        boundaryGap: true,
      },
      yAxis: {
        type: 'category',
        name: yAxisTitle,
        nameTextStyle: {
          color: 'rgba(0, 0, 0, 0.84)',
          fontWeight: 'bolder',
          fontSize: 12,
          padding: position === 'top' ? [0, 36, 0, 0] : [0, getLang() === 'en-US' ? 54 : 36, -30, 0],
          fontFamily: 'PingFangSC-Medium',
        },
        boundaryGap: false,
        data: yAxisData,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: 'rgba(0, 0, 0, 0.1)'
          },
          interval: position === 'top' ? 2 : 0,
        },
        axisLabel: {
          formatter: function (value) {
            if (yAxisTitle === locale?.performance) {
              return value;
            } else if (value === 'C') {
              return locale?.NeedToImprove;
            } else if (value === 'B') {
              return locale?.MeetExpectations;
            } else if (value === 'A') {
              return locale?.AboveExpectations;
            } else {
              return value
            }
          },
          tooltip: {
            show: true
          },
          axisPointer: {
            show: true
          },
          color: 'rgba(0, 0, 0, 0.48)',
          fontSize: 12,
          lineHeight: 20,
          show: true,
          width: 50,
          overflow: 'truncate',
          ellipsis: '...',
          align: 'right',
          interval: position === 'top' ? 2 : 0,
        }
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.84)',
        borderColor: "#000",
        textStyle: {
          color: "#fff", //设置文字颜色
          fontSize: 12,
          fontWeight: 500
        },
        position(point) {
          // 固定在顶部
          return [point[0] + 10, '5%'];
        },
        formatter: param => {
          const { data: val, name, dataIndex } = param[0];
          if (val?.label) {
            window.$handleViewClick = () => {
              setVisible(true);
              setEvaluateInfo({ ...data[dataIndex], title: yAxisTitle, name });
              if (saveOperateLog) {
                saveOperateLog(position === 'top' ? '绩效' : '反馈');
              }
            }
            const buttonDom = val?.hasEvalButton ? `<div onclick=$handleViewClick() style='text-align:right;margin-top: 6px;color: #FF6400;cursor: pointer;'><span style="margin-right: 4px;">${locale?.View}${yAxisTitle}${locale?.Details}</span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="4" height="6" viewBox="0 0 4 6"><g transform="matrix(-1,0,0,1,8,0)"><path d="M5.11229,3L8,5.47234L7.38368,6L4.249345,3.31651C4.0551002,3.15021,4.0551002,2.84979,4.249345,2.68349L7.38368,0L8,0.527663L5.11229,3Z" fill="#FF6400" fill-opacity="1"/></g></svg></div>` : '';
            const appealDom = val?.isAppeal === '1' ? `<div><span style='font-family: PingFang SC;color: rgba(255,255,255,0.8);margin-right: 6px;'>${locale?.EscalateResult}:</span>${val?.appealResult}</div>` : '';

            return `<div><span style='font-family: PingFang SC;color: rgba(255,255,255,0.8);margin-right: 6px;'>${name}:</span>${val?.label}</div>
            ${appealDom}${buttonDom}
            `
          }
        },
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(116,160,250, 0.10)',
          }
        },
        extraCssText: 'z-index:10',
        enterable: true,
        confine: true
      },
      dataZoom: [
        {
          type: 'slider',
          show: false
        },
        {
          show: false
        }
      ],
      series: [
        ...list,
        {
          data: offboardingData,
          type: 'bar',
          barMaxWidth: 80,
          emphasis: {
            disabled: true,
          },
          markArea: {
            data: markAreaData,
            itemStyle: {
              color: 'rgba(0, 0, 0, 0)',
            },
            label: {
              color: 'rgba(0, 0, 0, 0.24)',
              offset: [0, position === 'top' ? 110 : 70],
            }
          },
          z: 1
        }
      ]
    };
  }, [data]);

  const size = useSize(chartRef);

  const { run } = useDebounceFn(
    () => {
      myChart.current?.resize()
    },
    {
      wait: 100,
    }
  );

  useEffect(() => {
    run();
  }, [size?.width]);

  return (
    <>
      {
        options && <LineChart
        height={position==='top' ? 270 : 160}
        options={options}
        />
      }
      <PerformanceRecordsModal visible={visible} handleCancel={() => setVisible(false)} data={evaluateInfo} locale={locale} />
    </>
  );
}

// X坐标
const XAxis = (props) => {
  const { data, marginTop } = props;

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-around',
      height: '28px',
      backgroundColor: '#E7EDF9',
      paddingLeft: '60px',
      marginTop: marginTop,
      width: '100%'
    }}>
      {
        data?.map((item, index) => {
          // 判断是否是离职日期（YYYY-MM-DD）
          const reg = new RegExp(/^(\d{4})-(\d{2})-(\d{2})$/);
          return (
            <div style={{
              transform: 'skew(-20deg)',
              backgroundColor: '#E0E7F6',
              opacity: reg.test(item) ? 0 : 1
            }} key={index}>
              <span style={{
                display: 'inline-block',
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.84)',
                fontSize: '12px',
                lineHeight: '28px',
                padding: '0 6px',
                transform: 'skew(20deg)',
              }}>{item}</span>
            </div>
          )
        })
      }
    </div>
  )
}

export default (props) => {
  const {
    xAxisData = [],
    performanceData = [],
    feedbackData = [],
    locale,
    saveOperateLog
  } = props;

  return (
    <>
      {
        performanceData?.length > 0 && (
          <Chart
            xAxisData={xAxisData}
            yAxisData={['','', 'D-','D','D+','C-','C','C+','B-','B','B+','A-','A','A+','S-','S','S+']}
            yAxisTitle={locale?.performance}
            lineColor='rgba(57, 145, 255, 0.5)'
            data={performanceData}
            locale={locale}
            saveOperateLog={saveOperateLog}
          />
        )
      }
      {
        xAxisData?.length > 0 && <XAxis data={xAxisData} marginTop={performanceData?.length > 0 ? '-40px': 0} />
      }
      {
        feedbackData?.length > 0 && (
          <Chart
            xAxisData={xAxisData}
            yAxisData={['','C', 'B', 'A', '']}
            yAxisTitle={locale?.feedback}
            lineColor='rgba(18, 202, 122, 0.5)'
            data={feedbackData}
            locale={locale}
            position='bottom'
            saveOperateLog={saveOperateLog}
          />
        )
      }
    </>
  )
};
