import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { di18n } from '@ultra/common';
import successImg from '../../images/success.svg';
import './index.scss';

const { intl } = di18n;

const MultipleAvatars = props => {
  const { totalList, source, wrapWidth, toInfo, groupIndex, groupRecycleStatus } = props;
  const [showList, setShowList] = useState([]);
  const [completion, setCompletion] = useState([]);
  const [query, setQuery] = useState({
    currentPage: 1, // 当前页
    pageNum: 1, // 共多少页
    pageSize: 1, // 每页多少条
    total: 0, // 总数
    open: false, // 展示收齐
  });

  const changeQuery = (params, arr) => {
    setQuery({
      ...query,
      ...params
    });
    setShowList(totalList.slice(arr[0], arr[1]));
  };

  const setLength = () => {
    const num = Math.floor((wrapWidth / 56) + 1); // 54是内容 2px是边框
    const shortNum = 0;
    let pageNum = Math.ceil(totalList.length / (num));
    let pageSize = num;
    if (totalList.length > num) {
      pageNum = Math.ceil(totalList.length / (shortNum || num));
      pageSize = (shortNum || num);
      changeQuery({
        currentPage: 1, // 当前页
        pageNum,
        pageSize,
        total: totalList.length,
        open: false
      }, [0, pageSize])
    } else {
      setShowList(totalList)
    }
    // 补全
    if (source === 'h5') {
      let completionNum = ((pageNum + 1) * pageSize - totalList.length)
      let completionNumList = []
      for (let a = 0; a < completionNum; a++) {
        completionNumList.push(a)
      };
      setCompletion(completionNumList);
    }
  };


  const openFn = (v) => {
    if (v) {
      setShowList(totalList)
    } else {
      setShowList(totalList.slice(0, query.pageSize))
    }
    setQuery({
      ...query,
      open: v
    });
  };

  const toParentInfo = () => {
    // 已完成状态
    const completed = 2;
    if (groupRecycleStatus === completed) {
      return;
    }
    toInfo(totalList[0].questionnaireId, totalList, groupIndex);
  };

  useEffect(() => {
    if (wrapWidth && source === 'h5') {
      setLength()
    }
  }, [wrapWidth])

  useEffect(() => {
    setShowList(totalList);
  }, [])

  return (
    <div className={`ct-questionnaire-${source}-portraits-avatars`}>
      {/* <div className={`ct-questionnaire-${source}-avatars-title`}>{totalList.length === 1 ? intl.t('人员：') : intl.t('合并人员：')}</div> */}
      <div className={`ct-questionnaire-${source}-avatars-con`}>
        <Tooltip placement="top" overlayInnerStyle={{ borderRadius: '4px' }} title={(groupRecycleStatus === 2 || source === 'h5') ? '' : intl.t('请评价')} >
          {/* <div style={{width: '100%', overflow: 'auto'}}> */}
            <div
              className={`ct-questionnaire-${source}-avatars-list`}
              onClick={() => {
                toParentInfo()
              }}
            // style={{justifyContent: query.pageNum === 1 ? 'left' : 'space-around'}}
            >
              {
                showList.map((item, index) => {
                  return (<span
                    key={index}
                  >
                    {item.recycleStatus === 2 && <img src={successImg} className={`ct-questionnaire-${source}-avatars-success`} />}
                    <div className="ct-questionaire-h5-avatars-div">
                      <img className="ct-questionnaire-h5-avatars-img" src={item.avatar} onError={(e) => {e.target.onerror = null;e.target.src="//img-hxy021.didistatic.com/static/ehr_static/imgs/ct/avatar.png"}}/>
                    </div>
                    <div>{item.activityRespondentName} </div>
                  </span>)
                })
              }
              {
                source === 'h5' && completion.map((item, index) => <span className="ct-questionnaire-none-span" key={index}>{ }</span>)
              }
            </div>
          {/* </div> */}
        </Tooltip>
        {
          query.pageNum > 1 && source === 'h5' && (
            <div className={`ct-questionnaire-${source}-avatars-page`}>
              {
                query.open ? (
                  <div onClick={() => { openFn(false) }}>
                    <span>{intl.t('收起')}</span>
                    <UpOutlined />
                  </div>
                ) : (
                  <div onClick={() => { openFn(true) }}>
                    <span>{intl.t('更多人员')}</span>
                    <DownOutlined />
                  </div>
                )
              }
            </div>
          )
        }

      </div>
    </div>
  );
};

export default MultipleAvatars;
