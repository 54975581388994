import React, { useState, useEffect } from 'react';
import { getLocale } from '@didi/di18n-react';
import './index.scss';
import { useDispatch } from 'react-redux';
import request from '../../utils/request';
import { getCookie, getLangCode, getSurveyInfo } from '../../utils/Tool';
import { changePortraits } from '../../store/navigationStore/portraits'; // 引入actions

export default function Home(props) {
  const { source, activityId: oActivityId } = props.match.params;

  // 截断活动Id后面的字符
  const activityId = oActivityId.match(/^\d+/)[0];

  const dispatch = useDispatch();

  const init = async () => {
    let participantCode;
    let res;
    const ctLang = getCookie('ct-lang') || getLocale('lang');
    try {
      const { data: { surveyBusType } } = await getSurveyInfo(activityId);
      sessionStorage.setItem('surveyBusType_error', surveyBusType);
      participantCode = await getKey(5);
    } catch (error) {
      return;
    }

    try {
      res = await groupRespondentAndContentId(
        activityId,
        participantCode.data,
        getLangCode(ctLang)
      );
    } catch (error) {
      return;
    }

    const resData = res?.data;
    if (resData?.activityStatus !== 1) {
      props.history.replace('/activity/not/started');
      return;
    }
    // 如果是调研问卷，提交问卷后直接跳转已完成页面
    if (resData.questionnaireLayoutType === 2) {
      const { recycleStatus }
        = resData.respondentGroup[0].contentInfos[0].activityRespondents[0];
      if (recycleStatus === 2) {
        props.history.replace(`/success?id=${activityId}`);
        return;
      }
    }

    // 合并问卷
    if (resData.questionnaireLayoutType === 1) {
      resData.source = source;
      dispatch(changePortraits(resData));
      props.history.replace(`/navigation/${source}/${activityId}`);
    }
    if (resData.questionnaireLayoutType === 0) {
      // 单人问卷

      const questionnaireId
        = resData.respondentGroup[0]?.contentInfos[0]?.contentId; // 问卷id
      const respondentCode
        = resData.respondentGroup?.[0].contentInfos?.[0].activityRespondents?.[0]
          .respondentCode;

      if (source === 'pc') {
        props.history.replace(
          `/pc/${activityId}/${questionnaireId}/${respondentCode}/submit/0`
        );
      }
      if (source === 'h5') {
        props.history.replace(`/h5/navigation/${activityId}`);
      }
    }
    if (resData.questionnaireLayoutType === 2) {
      // 调研问卷
      const questionnaireId
        = resData.respondentGroup[0]?.contentInfos[0]?.contentId; // 问卷id
      const respondentCode
        = resData.respondentGroup?.[0].contentInfos?.[0].activityRespondents?.[0]
          .respondentCode;
      if (source === 'pc') {
        props.history.replace(
          `/pc/${activityId}/${questionnaireId}/${respondentCode}/submit/0`
        );
      }
      if (source === 'h5') {
        props.history.replace(
          `/h5/${activityId}/${questionnaireId}/${respondentCode}/0`
        );
      }
    }
  };

  // 获取匿名key, busType随便传的，后端王斌说只要不是一就行
  // 原接口：/api/anymous/encode
  const getKey = busType => request('/ctAnonymity/api/answer/anymous/encode', {
    method: 'POST',
    data: { encodeText: activityId, busType },
  });

  // 原接口：/api/activity/respondent/groupRespondentAndContentId
  const groupRespondentAndContentId = (
    activityId,
    participantCode,
    languageType
  ) => request(
    '/ctActivity/api/answer/activity/respondent/groupRespondentAndContentId',
    {
      method: 'GET',
      params: {
        activityId,
        participantCode,
        all: true,
        languageType,
      },
    }
  );

  useEffect(() => {
    init();
  }, [source]);

  return <></>;
}
