import React, { useEffect, useState, useRef } from 'react';
import { message, Spin } from '@didi/ec-base';
import PageletServices from '../services';
import DetailModal from './DetailModal';
import { getDateShow } from '../components/DetailModal/contant';
import '../style.scoped.less';

export default function List(props) {
  const {
    apiHost, newHeaders, auth, year, prefixCls, providerLocale
  } = props;
  const [loading, setLoading] = useState(false);
  const [yearInfo, setYearInfo] = useState('');
  const [growList, setGrowList] = useState([]); //成长记录
  const [contributionList, setContributionList] = useState([]); //成长记录
  const [careerList, setCareerList] = useState([]); //生涯瞬间
  const [detailVisible, setDetailVisible] = useState(false);
  const [detialContent, setDetialContent] = useState({});
  const detailFlagRef = useRef(false);
  useEffect(() => {
    setYearInfo(year);
  }, [year])

  const getHorizon = () => {
    const params = {
      year: yearInfo === 'all' ? '' : yearInfo
    }
    setLoading(true);
    PageletServices.initHorizon(apiHost, params, newHeaders).then(res => {
      if (res?.code === "100200" && Object.keys(res?.data?.itemList).length > 0) {
        const { growth, contribution, life } = res?.data?.itemList;
        setGrowList(growth || []);
        setContributionList(contribution || []);
        setCareerList(life || []);
      }
    }).finally(() => {
      setLoading(false);
    })
  }
  useEffect(() => {
    if (yearInfo) {
      getHorizon();
    }
  }, [yearInfo]);
  const detailModal = (e) => {
    detailFlagRef.current = true;
    const params = e.situationCode === 'badge' ? {
      situationCode: e?.situationCode,
      effectDate: e?.effectDate,
      badgeName: e?.content,
    } : {
      situationCode: e?.situationCode,
      effectDate: e?.effectDate,
    }
    PageletServices.getDetail(apiHost, params, newHeaders).then(res => {
      if (res?.code === "100200") {
        const data = res?.data;
        if (data?.descriptionListMapVO.length > 0 || Object.keys(data?.descriptionMapVO).length > 0) {
          setDetailVisible(true);
          setDetialContent({
            title: e?.content,
            situationCode: e?.situationCode,
            descriptionListMapVO: data?.descriptionListMapVO || [],
            descriptionMapVO: data?.descriptionMapVO || {},
          })
        }
      }
    }).catch((error) => {
      message.error(error)
    }).finally(() => {
      detailFlagRef.current = false;
    })
  }
  const onClick = (item) => {
    if (detailFlagRef.current) {
      return;
    }
    detailModal(item);
  }
  return (<div className={`${prefixCls}-list-warp`}>
    {
      loading ? <Spin spinning={loading}/> : <div className={`${prefixCls}-list`}>
        <div className={`${prefixCls}-list-grow`}>
          <div className="pagelet-personal-didi-list-grow-header">
            成长记录{growList?.length <= 0 ? '' : <span>共{growList.length}项</span>}
          </div>
          {
            growList.length > 0 ? growList.map((v, i) => (
              <div className="pagelet-personal-didi-list-grow-warp">
                <div className="pagelet-personal-didi-list-grow-warp-left" >
                  <img src={v?.iconUrl}/>
                  {i + 1 === growList.length ? '' : <div className="pagelet-personal-didi-list-grow-tail"></div>}
                </div>
                <div className="pagelet-personal-didi-list-grow-warp-right" onClick={() => {
                  onClick(v);
                }}>
                  <div className="list-grow-title"><span>{v?.content}</span>{v?.hasNew ? <span className="pagelet-personal-didi-list-grow-new">New</span> : null}</div>
                  <div className="list-grow-content">{getDateShow(v?.effectDate)}</div>
                </div>
              </div>
            )) : <div className='list-grow-no-data'>
              <img src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didiNoData1.png'} alt="" srcset="" />
              <span>更多经历待你解锁</span>
            </div>
          }
        </div>
        <div className={`${prefixCls}-list-contribution`}>
          <div className="pagelet-personal-didi-list-grow-header">
            贡献与交付
            {contributionList?.length <= 0 ? '' : <span>共{contributionList.length}项</span>}
          </div>
          {
            contributionList.length > 0 ? contributionList.map((v, i) => (
              <div className="pagelet-personal-didi-list-grow-warp">
                <div className="pagelet-personal-didi-list-grow-warp-left">
                  <img src={v?.iconUrl}/>
                  {i + 1 === contributionList.length ? '' : <div className="pagelet-personal-didi-list-grow-tail"></div>}
                </div>
                <div className="pagelet-personal-didi-list-grow-warp-right" onClick={() => {
                  onClick(v);
                }}>
                  <div className="list-grow-title"><span>{v?.content}</span>{v?.hasNew ? <span className="pagelet-personal-didi-list-grow-new">New</span> : null}</div>
                  <div className="list-grow-content">{getDateShow(v?.effectDate)}</div>
                </div>
              </div>
            )) : <div className='list-grow-no-data'>
              <img src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didiNoData1.png'} alt="" srcset="" />
              <span>更多经历待你解锁</span>
            </div>
          }
        </div>
        <div className={`${prefixCls}-list-career`}>
          <div className="pagelet-personal-didi-list-grow-header">
            生涯瞬间
            {careerList?.length <= 0 ? '' : <span>共{careerList.length}项</span>}
          </div>
          {
            careerList.length > 0 ? careerList.map((v, i) => (
              <div className="pagelet-personal-didi-list-grow-warp">
                <div className="pagelet-personal-didi-list-grow-warp-left">
                  <img src={v?.iconUrl}/>
                  {i + 1 === careerList.length ? '' : <div className="pagelet-personal-didi-list-grow-tail"></div>}
                </div>
                <div className="pagelet-personal-didi-list-grow-warp-right" onClick={() => {
                  onClick(v);
                }}>
                  <div className="list-grow-title"><span>{v?.content}</span>{v?.hasNew ? <span className="pagelet-personal-didi-list-grow-new">New</span> : null}</div>
                  <div className="list-grow-content">{getDateShow(v?.effectDate)}</div>
                </div>
              </div>
            )) : <div className='list-grow-no-data'>
              <img src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didiNoData1.png'} alt="" srcset="" />
              <span>更多经历待你解锁</span>
            </div>
          }
        </div>
      </div>
    }
    <DetailModal
      prefixCls={prefixCls}
      detailVisible={detailVisible}
      detialContent={detialContent}
      providerLocale={providerLocale}
      onClose={() => { setDetailVisible(false); }}
    />
  </div>
  );
}
