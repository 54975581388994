import React, { useEffect, useRef, useState } from "react";
import classnames from 'classnames';
import RenderWidget from '../renderWidget';
import { useSelector } from "react-redux";

const questionnaireOfSingle = props => {
  const {
    renderBanner,
    navigate,
    renderSubmitBtn,
    renderFootRemark,
    renderProcess,
    renderErrorLocation,
    renderFoot,
    pageDataAll,
    reviewType,
    renderType,
    respondentTypeCodePreview,
    getClassNames
  } = props;
  const {
    surveyBusType
  } = useSelector((state) => state.questionContent);

  const domRef = useRef(null)
  const leftDomRef = useRef(null)
  const moveRef = useRef(null)
  const [ moveTag, setMoveTag ] = useState(false)
  moveRef.current = moveTag

  // 渲染单人问卷模板
  // 里面的组件由于与其他问卷模板公用，所以用父组件传进来的方法渲染
  // 增加拆分问卷左侧导航位移监听
  useEffect(() => {
    // 动态计算模块高度
    if (leftDomRef.current.children[0]) {
      leftDomRef.current.children[0].style.height = `${document.body.clientHeight - leftDomRef.current.offsetTop}px`
    }
    leftDomRef && document.addEventListener('scroll', moveLeftDom)
    return () => {
      leftDomRef && document.removeEventListener('scroll', moveLeftDom)
    }
  }, [])

  const moveLeftDom = () => {
    // if(document.getElementsByClassName('question-main-single-pc'))
    let offsetTop = domRef.current.offsetTop
    let pageYOffset = window.pageYOffset
    // 第一个模块滑动到顶部时,并且未修改导航位置时
    let moveDom = leftDomRef.current.children[0]
    if(offsetTop - pageYOffset < 60 && !moveRef.current ) {
      setMoveTag(true)
      moveDom.style.transform = `translateY(-192px)`
      moveDom.transtion = "all .4s"
      moveDom.style.height = `${document.body.clientHeight - leftDomRef.current.offsetTop + 192}px`
    } else if((offsetTop - pageYOffset) > 60 && moveRef.current ){
      setMoveTag(false)
      moveDom.style.transform = `translateY(0)`
      moveDom.transtion = "all .4s"
      moveDom.style.height = `${document.body.clientHeight - leftDomRef.current.offsetTop }px`
    }
  }

  return (
    <div className={classnames('question-of-single-page', {
      'question-of-single-page-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
    })}>
      {/* 渲染问卷头部 */}
      {renderProcess && surveyBusType === 'PROFESSIONAL_SERVICES' ? renderProcess : ''}
      <div className="question-of-single-content">
        <div className="question-single-main-banner-pc">
          {renderBanner}
        </div>
        <div className="flex questionnaire-main" ref={domRef}>
          <div className="question-left" ref={leftDomRef}>
            {/* 渲染左侧导航 */}
            {navigate}
          </div>
          <div className="question-main-single-pc">
            {pageDataAll
              ? (
                <>
                  <RenderWidget
                    pageDataAll={pageDataAll}
                    itemType={1}
                    multi={1}
                    renderType={renderType ? renderType : 2}
                    reviewType={reviewType ? reviewType : 2}
                    respondentTypeCodePreview={respondentTypeCodePreview}
                  />
                </>
              )
              : ''}

            {renderFoot}
            {/* 渲染提交按钮 */}
            {renderSubmitBtn ? renderSubmitBtn : ''}
            {/* {renderFootRemark} */}
          </div>
          <div className="question-right">
            {renderProcess && surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? renderProcess : ''}
            {renderErrorLocation}
          </div>
        </div>
      </div>

    </div>
  );
};

export default questionnaireOfSingle;
