import React, { useEffect, useRef, useState } from 'react';
import { Resizable } from 'react-resizable';
import Table from './Table';

import useStyle from './style';

// 为每个表格头添加可移动元素
const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  const prefixCls = 'resizeable-title';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const ResizableTable = (props) => {
  const {
    selected = true,
    resizable = true,
    dataSource = [],
    columns,
    locale
  } = props;
  const [columnsReset, setColumnsReset] = useState([{}]);
  const [rowId, setRowId] = useState(null);
  const modelStatusRef = useRef(null);

  // 点击行锁定颜色
  const onClickRow = (record) => {
    if(!selected) return;
    return {
      onClick: () => {
        setRowId(record.selectId)
      }
    };
  }
  const setRowClassName = (record) => {
    return record.selectId === rowId ? 'clickRowStyle' : '';
  }

  // 表格可自由伸缩
  const handleResize = (index) => (e, { size }) => {
    const nextColumns = [...modelStatusRef.current];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setColumnsReset(nextColumns);
  };
  useEffect(() => {
    modelStatusRef.current = columnsReset;
  }, [columnsReset]);
  useEffect(() => {
    if(!resizable) return;
    let columnsTemp = [...columns];
    columnsTemp = columnsTemp.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
      })
    }));
    setColumnsReset(columnsTemp);
  }, [columns]);


  useEffect(()=>{
    dataSource.map((col, index) => {
      dataSource[index].selectId = index;
    });
    setRowId(null)
  }, [dataSource])
  const components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  return (
    <Table
      {...props}
      locale={locale}
      columns={columnsReset}
      components={resizable ? components : {}}
      onRow={onClickRow}
      rowClassName={setRowClassName}
    >
    </Table>
  )
}

export default ResizableTable;
