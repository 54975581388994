import React, { useEffect, useState } from 'react';
import { getLocale, Avatar, Tooltip, Select, Spin, Empty, ConfigProvider } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { getInterviewInfo, getResumeExists, getPreviewResume, getOperateLog  } from './services';
import FaceProcess from './components/FaceProcess';
import UploadResume from './components/UploadResume';
import InfoCircleSvg from './assets/infoCircle.svg';
import RightThickSvg from './assets/rightThick.svg';
import GreenSvg from './assets/green.svg';
import RedSvg from './assets/red.svg';
import BlueSvg from './assets/blue.svg';
import YellowSvg from './assets/yellow.svg';

const localLocales = manifest.locales;

const isNull = (str) => {
	if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
		return '-';
	};
	return str;
};


const Pagelet = (props) => {
  const { params: { locales, apiHost, headers, auth, employeeId, usePermissionResponse } } = props;

  // 面试关系权限
  const hasAuth = auth?.filter(item => item.elementTag === 'archive-relation-interview-detail')?.length > 0;
  // 背调关系权限
  const hasBacktrackAuth = auth?.filter(item => item.elementTag === 'archive-relation-interview-bg-result')?.length > 0;
  // 上传简历权限
  const uploadResumeAuth = auth?.filter(item => item.elementTag === 'archive-relation-resume-upload')?.length > 0;
  // 预览简历权限
  const previewResumeAuth = auth?.filter(item => item.elementTag === 'archive-relation-resume-check')?.length > 0;
  // 查看面评权限
  const resumeEvaluateAuth = auth?.filter(item => item.elementTag === 'archive-relation-resume-evaluate')?.length > 0;

  const uploadResumeFunctionId = auth?.find(item => item.elementTag === 'archive-relation-resume-upload')?.functionId;

  const previewResumeFunctionId = auth?.find(item => item.elementTag === 'archive-relation-resume-check')?.functionId;

  const prefixCls = 'pagelet-interview-relationship';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const getBacktrackColor = (type) => {
    let color = '', tip = '', title = '';
    let icon;
    switch (type) {
      case '4':
        color = '#70AF2C';
        title = locale.greenLight,
        tip = locale.greenHover;
        icon = <GreenSvg />
        break;
      case '3':
        color = '#4F9BFF';
        title = locale.blueLight,
        tip = locale.blueHover;
        icon = <BlueSvg />
        break;
      case '2':
        color = '#FF9641';
        title = locale.yellowLight,
        tip = locale.yellowHover;
        icon = <YellowSvg />
        break;
      case '1':
        color = '#FC6262';
        title = locale.redLight,
        tip = locale.redHover;
        icon = <RedSvg />
        break;
      default:
        break;
    }

    return {
      color,
      tip,
      icon,
      title
    }
  };

  const [option, setOption] = useState([]);
  const [optionKey, setOptionKey] = useState({});
  const [resumeExistsInfo, setResumeExistsInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(true);

  // 查看被面试信息
  const getInterviewInfoDetail = async () => {
    try {
      setLoading(true);
      const res = await getInterviewInfo(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data?.length > 0) {
        setOption(() => res?.data.map((v) => ({
          ...v,
          value: v.sid,
          label: v.interviewPositionName,
          jobDuty: v.jobDuty,
          interviewerList: v?.interviewerList?.map(item => ({
            ...item, name: item?.interviewerName, empId: item?.interviewerId, type: item?.interviewTypeDesc
          }))
        })));
        setOptionKey({ i: 0, value: res?.data[0]?.sid, code: res?.data[0]?.interviewPositionCode });
      }
    } catch (error) {
      setLoading(false);
      console.log('getInterviewInfo', error);
    }
  };

  // 查看是否存在简历
  const getResumeExistsStatus = async () => {
    try {
      const res = await getResumeExists(apiHost, headers, { employeeId }, usePermissionResponse);
      if (res?.code === '100200') {
        setResumeExistsInfo(res?.data);
      }
    } catch (error) {
      console.log('getResumeExists', error);
    }
  }

  // 查看简历
  const handleResume = async () => {
    getOperateLog(apiHost, headers, {
      operateName: "点击",
      operateContent: "面试关系",
      operateParams: {
        "操作": "查看简历",
        "被预览员工工号": employeeId,
      }
    })

    const header = {
      ...headers,
      'bricks-function-id': previewResumeFunctionId
    }

    // 打开空页面，用于接收PDF文件
    const previewWindow = window.open('about:blank', '_blank');
    previewWindow.document.open();
    previewWindow.document.write(`<html><head><script>window.addEventListener('message',function(event){location.replace(event.data.url);},false);</script></head><body>简历加载中，请稍后...</body></html>`);
    previewWindow.document.close();

    try {
      const res = await getPreviewResume(apiHost, header, {
        employeeId,
      });

      const { data } = res;

      const type = res?.headers['content-type']?.split(';')[0];

      if (data) {
        const blob = new Blob([data], { type });

        // 发送生成的PDF链接给空页面，进行预览
        previewWindow.postMessage({ url: `${URL.createObjectURL(blob)}#toolbar=0&navpanes=0&scrollbar=0` }, '*');
      } else {
        previewWindow.close();
      }
    } catch (error) {
      console.log('getInterviewResume', error);
    }
  }

  const onChange = (value) => {
    const i = option.findIndex((v) => v.value === value);
    setOptionKey(() => ({ i, value, code: option[i]?.interviewPositionCode }));
  };

  // 接口请求示例
  useEffect(() => {
    getInterviewInfoDetail();
    getResumeExistsStatus();
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
    getResumeExistsStatus();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 上传/更新简历
  const showModal = type => {
    setIsModalOpen(true);
    setModalType(type);
  };

  return wrapSSR(
    <ConfigProvider prefixCls='ant5'>
      <div className={classes}>
        <div className='interview-position-container'>
          {
            option.length > 0 && (
              <>
                <div className='interview-title'>{locale.jobTitle}:</div>
                {
                  option.length <= 1
                  ? <div className="interview-position">{isNull(option[optionKey?.i]?.label)}</div>
                  : (
                    <Select
                      showArrow
                      style={{ width: 180 }}
                      options={option}
                      onChange={onChange}
                      value={option[optionKey?.i]?.value}
                    />
                  )
                }
                {
                  option[optionKey?.i]?.jobDuty && (
                    <Tooltip
                      overlayClassName="backtrack-tooltip"
                      title={
                        <div style={{maxHeight: '424px',overflowY: 'auto'}}>
                          <div style={{ fontWeight: '500', marginBottom: '8px' }}>{locale.jobDescription}:</div>
                          <div style={{ whiteSpace: 'pre-wrap' }}>{option[optionKey?.i]?.jobDuty}</div>
                        </div>
                      }
                    >
                      <InfoCircleSvg
                        style={{
                          marginLeft: '6px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: 'rgba(0, 0, 0, 0.65)',
                          marginRight: (previewResumeAuth || uploadResumeAuth) ? '8px' : '0px',
                        }}
                      />
                    </Tooltip>
                  )
                }
              </>
            )
          }

          {
            (previewResumeAuth || uploadResumeAuth) && option?.length === 0 && (
              <div className='empty-span'>{locale.staffNoInfo}</div>
            )
          }
          {
            previewResumeAuth && resumeExistsInfo?.resumeExists === '1' && (
              <div className="text-color" onClick={handleResume}>
                {locale.viewResume}
                <RightThickSvg className="card-icon" />
              </div>
            )
          }
          {
            uploadResumeAuth && (
              <div
                className="text-color"
                onClick={() => showModal(resumeExistsInfo?.resumeExists === '1' ? 'update' : 'add')}
              >
                {resumeExistsInfo?.resumeExists === '1' ? locale.updateResume : locale.uploadResume}
                <RightThickSvg className="card-icon" />
              </div>
            )
          }
        </div>

        <div className='interview-source-container'>
          {
            option.length > 0 && (
              <>
                {
                  !!(option[optionKey?.i]?.rcType) && (
                    <div className='interview-source-content'>
                      <div className='interview-title'>{locale.recruitmentSourceType}:</div>
                      <div className='interview-position'>{isNull(option[optionKey?.i]?.rcType)}</div>
                    </div>
                  )
                }

                {
                  option[optionKey?.i]?.rcType === '内部推荐' && (
                    <div className='interview-source-content'>
                      <div className='interview-title'>{locale.internalReferrer}:</div>
                      <div className='interview-position'>{isNull(option[optionKey?.i]?.pusher)}{option[optionKey?.i]?.pusherId && <span style={{ marginLeft: '4px'}}>({option[optionKey?.i]?.pusherId})</span>}</div>
                      {option[optionKey?.i]?.pusherStatus === 'A' && <div className='user-type-icon'>{locale.active}</div>}
                      {option[optionKey?.i]?.pusherStatus === 'I' && <div className='user-type-icon'>{locale.offboarding}</div>}
                    </div>
                  )
                }

                {
                  hasBacktrackAuth && (
                    <div className='interview-source-content'>
                      <div className='interview-title'>{locale.backgroundInvestigation}:</div>
                      {
                        option[optionKey?.i]?.levelId ? (
                          <div className='interview-position interview-backtrack'>
                            {getBacktrackColor(option[optionKey.i]?.levelId).icon}
                            <Tooltip title={getBacktrackColor(option[optionKey.i]?.levelId).tip}>
                              <span className={`interview-backtrack-level${option[optionKey.i]?.levelId}`} style={{color: getBacktrackColor(option[optionKey.i]?.levelId)?.color}}>
                                {getBacktrackColor(option[optionKey.i]?.levelId).title}
                              </span>
                            </Tooltip>
                          </div>
                        ) : (
                          <span>-</span>
                        )
                      }
                    </div>
                  )
                }
              </>
            )
          }

        </div>

        {
          loading
          ? <Spin spinning={loading}><div style={{ minHeight: '135px' }} /></Spin>
          : option?.length > 0 && option[optionKey?.i]?.interviewerList?.length === 0 && (!previewResumeAuth && !uploadResumeAuth)
            ? <Empty pageCode='talent' />
            : <div className='interview-process-container'>
                <FaceProcess
                  data={option[optionKey?.i]?.interviewerList}
                  showTitle={false}
                  resumeEvaluateAuth={resumeEvaluateAuth}
                  apiHost={apiHost}
                  headers={headers}
                  employeeId={employeeId}
                  locales={locales}
                />
              </div>
        }

        <UploadResume
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          modalType={modalType}
          employeeId={employeeId}
          apiHost={apiHost}
          headers={headers}
          uploadResumeFunctionId={uploadResumeFunctionId}
          locales={locales}
        />
      </div>
    </ConfigProvider>,
  );
};

export default Pagelet;
