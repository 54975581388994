import React, { useEffect, useState } from 'react';
import { Select } from '@didi/ec-base';
import { DownThickOutlined } from '@didi/iconflow/ec';

function ScopeFilter(props) {
  const { list, onScopeChange, subordinate } = props;
  const [scope, setScope] = useState(null);
  const onSelectChange = v => {
    setScope(v);
    onScopeChange(v);
  };
  useEffect(() => {
    if(subordinate){
      setScope(subordinate);
    }
  }, [subordinate])

  return (
    <Select getPopupContainer={node => node.parentNode} suffixIcon={<DownThickOutlined />} value={scope} onChange={onSelectChange}>
      {
        list.map((item) => {
          return (
            <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
          )
        })
      }
    </Select>
  )
}

export default ScopeFilter;
