import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('objective-read', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        overflowY: 'auto',
        // minHeight: 'calc(316px - 64px)',
        maxHeight: 'calc(600px - 108px)',
        ['.item_ul:last-child']: {
          ['.item_el']: {
            marginBottom: '0',
          }
        },
        ['.item_el']: {
          /* 自动布局 */
          display: 'flex',
          padding: '12px',
          gap: '6px',
          zIndex: '0',
          borderRadius: '8px',
          opacity: '1',
          background: 'rgba(245, 245, 245, 0.6)',
          marginBottom: '12px',
          cursor: 'pointer',
          border: '1px solid rgba(245, 245, 245, 0.6)',
          ['&:hover']: {
            background: 'rgba(255, 100, 0, 0.02)',
            borderColor: '#FF6400',
          },

          ['.orderNum']: {
            width: '24px',
            height: '24px',
            /* 自动布局 */
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            opacity: '1',
            background: '#FF6400',
            fontFamily: 'PingFang SC',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '24px',
            letterSpacing: '0em',
            color: '#FFFFFF',
            flexShrink: '0',
          },

          ['.Oidnex']: {
            fontSize: '11px',
            display: 'inline-block',
          },

          ['.item_text']: {
            fontSize: '14px',
            letterSpacing: '0em',
            color: 'rgba(0, 0, 0, 0.84)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },

          ['.item_text_norml']: {
            width: '100%',
            wordWrap: 'break-word',
            overflowWrap: 'anywhere',
            whiteSpace: 'normal',
          },

          ['.item_state']: {
            display: 'flex',
            marginTop: '8px',
            ['.item_weight']: {
              fontSize: '12px',
              fontWeight: 'normal',
              color: 'rgba(0, 0, 0, 0.72)',
            },
            ['.state']: {
              /* 自动布局 */
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '2px 4px',
              gap: '2px',
              borderRadius: '4px',
              opacity: '1',
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0px',
              marginLeft: '10px',
              height: '18px',
            },

            ['.pending']: {
              color: '#3FAD55',
              background: 'rgba(11, 187, 141, 0.1)',
            },

            ['.finish']: {
              background: '#E4E9EB',
              color: '#778791',
            },

            ['.pause']: {
              background: '#F6E8D2',
              color: '#E59E2E',
            },

            ['.break']: {
              background: '#979797',
              color: '#FFFFFF',
            },
          },
        },

        ['.peng_empty']: {
          width: '100%',
          height: '100%',
          display: 'flex',
          padding: '6px 0',
          borderRadius: '8px',
          background: 'rgba(245, 245, 245, 0.6)',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          ['.pengImg']: {
            width: '80px',
            height: '66px',
            flexShrink: '0',
          },

          ['.peng_text']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: '0px',
            color: 'rgba(0, 0, 0, 0.6)',
            margin: '8px 0 6px',
          },

          ['.peng_view_btn']: {
            fontSize: '12px',
            fontWeight: '400',
            color: '#FF6400',
            cursor: 'pointer',
          },
        },
      },
    },
  ]);
};

export default useStyle;
