import { useRegisterStyle, getLang } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('pagelet-talent-promotion', () => [
    {
      // base
      ['.ant5-modal-content']: {
        padding: '16px 24px 24px 24px !important',
      },

      ['.talent-promotion-modal-evaluation-info']: {
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        padding: '16px 5px 16px 16px',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.6)',
        lineHeight: '20px',
        marginTop: '12px',

        ['.talent-promotion-modal-evaluation-info-text']: {
          paddingRight: '7px',
          overflowY: 'auto',
          maxHeight: '338px',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',

          ['&::-webkit-scrollbar']: {
            display: 'block !important',
          },

          ['&::-webkit-scrollbar-thumb']: {
            background: 'rgba(0, 0, 0, 0.13)',
          },

          ['&::-webkit-scrollbar-track']: {
            background: 'transparent'
          },
        },
      },

      ['.talent-promotion-modal-tabs']: {
        ['.ant5-tabs-nav']: {
          height: '36px',
          margin: '12px 0px !important',
          padding: '0px !important',

          ['&::before, .ant5-tabs-ink-bar.ant5-tabs-ink-bar-animated ']: {
            display: 'none',
          },

          ['.ant5-tabs-nav-list']: {
            background: 'rgba(0, 0, 0, 0.04)',
            padding: '4px',
            borderRadius: '12px',
          },

          ['.ant5-tabs-tab']: {
            margin: '0',
            padding: '0 12px',
            position: 'relative',

            ['&:not(:first-of-type)']: {
              marginLeft: '4px',
            },

            ['.ant5-tabs-tab-btn']: {
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.6)',
            },

            ['&.ant5-tabs-tab-active, &:hover']: {
              background: '#FFFFFF',
              borderRadius: '6px',
            },

            ['&.ant5-tabs-tab-active']: {
              ['.ant5-tabs-tab-btn']: {
                color: 'rgba(0, 0, 0, 0.84) !important',
              },
            },
          },
        },
      },

      [`.${prefixCls}`]: {
        // 滚动条样式
        ['::-webkit-scrollbar']: {
          height: '6px',
        },

        ['::-webkit-scrollbar-thumb']: {
          background: 'rgba(0, 0, 0, 0.13)!important',
        },

        ['::-webkit-scrollbar-track']: {
          background: 'none !important',
        },

        ['.talent-record-promotion-box']: {
          display: 'flex',
          marginBottom: '16px',
          alignItems: 'center',

          ['.job-multip-text']: {
            borderRadius: '4px',
            boxSizing: 'border-box',
            border: '1px solid rgba(10, 112, 245, 0.3)',
            fontFamily: 'PingFang SC',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '14px',
            color: '#4183D9',
            padding: '2px 4px',
            marginLeft: '6px',
          },
        },

        ['.talent-record-promotion-info']: {
          fontFamily: 'PingFang SC',
          fontWeight: 400,
          color: 'rgba(0, 0, 0, 0.72)',

          ['&>span']: {
            ['&:not(:last-child)::after']: {
              content: getLang() === 'en-US' ? '", "' : '"，"',
            },

            ['&:not(:first-of-type):last-child::after']: {
              content: getLang() === 'en-US' ? '"."' : '"。"',
            },

            ['&>span']: {
              fontWeight: 600,
              color: '#1473FF',
              margin: '0 5px',
            },
          },
        },

        ['.ehr-timeline-card-template']: {
          padding: '4px 4px 0px 4px',
        },

        ['.talent-promotion-container']: {
          padding: '20px 16px 18px 16px',
          minWidth: '242px',
          height: '130px',
          background: 'linear-gradient(6deg, #FFFFFF 28%, #E6EFFF 100%)',
          border: '2px solid #FFFFFF',
          boxShadow:
            '0px -2px 4px 0px rgba(0, 0, 0, 0.02),0px 2px 6px 6px rgba(0, 0, 0, 0.02),0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
          position: 'relative',

          ['.promotion-result']: {
            position: 'absolute',
            right: 0,
            top: 0,
            whiteSpace: 'nowrap',

            ['b']: {
              fontFamily: 'PingFang SC',
              fontSize: '10px',
              fontWeight: 600,
              color: '#4183D9',
              marginRight: '8px',
              position: 'relative',
            },

            ['b:not(:last-of-type)::after']: {
              content: '""',
              width: '1px',
              height: '10px',
              display: 'inline-block',
              borderRight: '1px solid rgba(0, 0, 0, 0.13)',
              position: 'absolute',
              top: '2px',
              right: '-4px',
            },

            ['b:last-of-type']: {
              marginRight: getLang() === 'en-US' ? '0' : '8px',
            },

            ['.promotion-result-tag']: {
              display: 'inline-block',
              padding: '4px 5px 4px 7px',
              borderRadius: '0px 8px 0px 20px',
              background: 'rgba(0, 179, 101, 0.1)',
              fontSize: '10px',
              color: '#00B365',
              fontWeight: 500,
              fontFamily: 'PingFang SC',
            },
          },

          ['.text-box']: {
            height: '44px',
          },

          ['.promotion-level']: {
            color: '#0A70F5',
          },

          ['.promotion-name']: {
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
            letterSpacing: 0,
            color: 'rgba(0, 0, 0, 0.87)',
            // display: 'flex',
            // alignItems: 'center',
            display: 'table-row',

            ['.card-icon']: {
              margin: '0 6px',
              height: '6px',
            },

            ['&>span:nth-of-type(1)']: {
              display: 'table-cell',
              whiteSpace: 'nowrap',
              textAlign: 'right',
              fontSize: '12px',
              fontWeight: 'normal',
              color: 'rgba(0, 0, 0, 0.6)',
            },

            ['&>span:nth-of-type(2)']: {
              display: 'flex',
              alignItems: 'center',
              marginLeft: '8px',
            },
          },

          // 晋升失败
          ['&.talent-promotion-failed']: {
            background:
              'linear-gradient(6deg, #FFFFFF 28%, #F0F0F0 100%), linear-gradient(190deg, #F6F9FF 11%, #FFFFFF 76%)',

            ['.promotion-result']: {
              ['.promotion-result-tag']: {
                background: 'rgba(0, 0, 0, 0.04)',
                color: 'rgba(0, 0, 0, 0.48)',
              },
            },
          },
          ['.ant5-divider']: {
            margin: '12px 0 17.5px 0',
            // background: '#fffdfb',
            background: '#D3DCE7',
          },

          ['.promotion-item-btn']: {
            fontFamily: 'PingFang SC',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '14px',
            textAlign: 'right',
            color: 'rgba(0, 0, 0, 0.84)',
            display: 'flex',
            justifyContent: 'space-around',

            ['&>span']: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              cursor: 'pointer',
              width: '82px',

              ['svg']: {
                width: getLang() === 'en-US' ? '24px' : '14px',
                height: getLang() === 'en-US' ? '24px' : '14px',
              },

              ['&:hover']: {
                color: '#0A70F5',

                ['svg']: {
                  fill: '#0A70F5',
                }
              }
            },
          },
        },
      },

      [`.${prefixCls}-orange`]: {
        ['.promotion-item-btn > span:hover']: {
          color: '#FF6400!important',

          ['svg']: {
            fill: '#FF6400!important',
          }
        }
      }
    },
  ]);
};

export default useStyle;
