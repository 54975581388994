import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  initPersonalInfo: async (host) => {
    const request = PageletApi.newRequest.getInstance('personal-info');
    const result = await request(`${host}/${apis.initPersonalInfo}`, {
      method: 'get',
    });
    return result;
  },
}

export default PageletServices;
