import React, { useState, useEffect } from 'react';
import { di18n } from '@ultra/common';
import classnames from 'classnames';
import { Picker, List } from 'antd-mobile';
import { GlobalOutlined } from '@ant-design/icons';
import { getLocale } from '@didi/di18n-react';
import { useSelector } from 'react-redux';
import { getCookie, handleLangCookie } from '../../utils/Tool';
import './index.scss';

const { intl } = di18n;
const Introduction = props => {
  const { title, questionDes, pageRemarkStyle, langKeyList, getClassNames } = props;
  const [currentLangDes, setCurrentLangDes] = useState('');
  const [district, setDistrict] = useState([]);

  const {
    lanMap,
    lanDes,
    surveyBusType
  } = useSelector(state => state.questionContent);

  useEffect(() => {
    if (langKeyList) {
      const langList = langKeyList.map(item => ({
        label: lanDes[item],
        value: item
      }));
      setDistrict(langList);
    }
  }, [langKeyList]);

  const handleLangChange = value => {
    handleLangCookie(lanMap, value);
  };

  useEffect(() => {
    const ctLang = getCookie('ct-lang') || getLocale('lang');
    let currentMap = 0;
    lanMap.map((item, i) => {
      if (item === ctLang) {
        currentMap = i;
      }
    });
    setCurrentLangDes(lanDes[currentMap]);
  }, []);

  return (
    <>
      <div>
        <div className={getClassNames?.prefixCls}>
          {surveyBusType === 'NON_PROFESSIONAL_SERVICES' && (
            <div className="img" />
          )}
          <div className="question-main-banner-title">
            <div style={{ padding: '0 16px', width: surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? 'calc(100vw - 94px)' : 'calc(100vw - 138px)' }}>
              <span className="mobile-lang-before-icon"><GlobalOutlined /></span>
              {
                langKeyList ? (
                  <div className="ct-lang-select">
                    <Picker data={district} cols={1} onOk={handleLangChange} okText={intl.t('确定')} dismissText={intl.t('取消')}>
                      <List.Item arrow="horizontal">{currentLangDes}</List.Item>
                    </Picker>
                  </div>
                ) : ''
              }
              <div className="question-main-title" dangerouslySetInnerHTML={{ __html: decodeURIComponent(title) }} />
            </div>
            {surveyBusType === 'PROFESSIONAL_SERVICES' && (
              <div style={{height: '101px'}}>
                <div className="img" />
              </div>
            )}
          </div>
          <div>
            {
              pageRemarkStyle ? <div className="question-main-banner-des" dangerouslySetInnerHTML={{ __html: decodeURIComponent(pageRemarkStyle) }} />
                : (questionDes ?
                  <div className="question-main-banner-des">{questionDes}</div> :
                  '')
            }
          </div>
        </div>
      </div>



    </>
  );
};

export default Introduction;
