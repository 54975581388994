import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-perf-overview', () => [
		{
			// base
			[`.${prefixCls}`]: {
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				backgroundImage: 'url(//img-ys011.didistatic.com/static/ddo_web_static/do1_z7hLzL6UKvXv7hdygAD8)', 
				fontSize: variable.size,
				backgroundSize: '100% 100%',
				position: 'relative',

				['p']: {
					margin: '0'
				},
				['.leftImg']: {
					position: 'absolute',
					bottom: '0',
					left: '0',
					zIndex: -1
				},
				['.rightImg']: {
					position: 'absolute',
					right: '0',
					top: '50px'
				}
			}
		}
	]);
};

export default useStyle;
