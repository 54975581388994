import { createSlice } from '@reduxjs/toolkit';

export const questionContentSlice = createSlice({
    name: 'questionContent', // 命名空间，在调用action的时候会默认的设置为action的前缀
    // 初始值
    initialState: {
        cancelTokenQesId: '',
        generalData: {},
        generalMultiData: {},
        respondentsGroup: [],
        draftData: {},
        attributeData: {},
        validateRules: {},
        validateResult: {},
        processRate: {
            progressRate: 0,
            allSubjectCount: 0,
            replySuccessSubjectCount: 0
        },
        effectData: {},
        loadIndex: 0,
        limitIndex: 5,
        subjectsDataList: [],
        subjectsDataGroupList: [],
        respondentTypeCode: '',
        hiddenGroup: [],
        validateFlag: false,
        errorList: {
            widget: 0,
            other: 0
        },
        initRequired: 0,
        multiInitRequired: 0,
        loading: true,
        currentRespondentCode: '',
        currentLang: -1,
        colorMap: [
            '#FFBB83',
            '#FFB374',
            '#FFAB6D',
            '#FFA465',
            '#FF9C5E',
            '#FF9658',
            '#FF8F51',
            '#FF874A',
            '#FF7F42',
            '#FF742F',
            '#FF6922'
        ],
        blueColorMap: [
            '#6CACFF',
            '#62A6FE',
            '#58A0FD',
            '#4F9AFC',
            '#4594FB',
            '#3B8EFA',
            '#3188F9',
            '#2882F8',
            '#1E7CF7',
            '#1476F6',
            '#0A70F5',
        ],
        lanMap: [
            'zh-CN',
            'en-US',
            'ja-JP',
            'es-ES',
            'pt-BR',
            'ru-RU'
        ],
        lanDes: [
            'Language - 简体中文',
            'Language - English',
            'Language - 日本語',
            'Language - Español',
            'Language - Português',
            'Language - русский'
        ],
        lanPair: {
            'zh-CN': 'Language - 简体中文',
            'en-US': 'Language - English',
            'ja-JP': 'Language - 日本語',
            'es-ES': 'Language - Español',
            'pt-BR': 'Language - Português',
            'ru-RU': 'Language - русский',
        },
        activityId: '',
        currentSubject: '',
        initSubjectFlag: {},
        loadAll: false,
        respondentCodeArr: [],
        participantWorkNo: '',
        errorListMap: [],
        errorListSoreMap: [],
        testArr: [],
        surveyBusType: '',
        // 上一次选择的选项id
        prevOptionIds: [],
        // 本次选择的选项id
        curOptionIds: [],
        fetchMore: false,
    },


    // 这里的属性会自动的导出为actions，在组件中可以直接通过dispatch进行触发
    reducers: {
        updateLoadIndex(state, { payload }) {
            state.loadIndex = payload.data;
        },
        getRespondentCode(state, { payload }) {
            state.respondentsGroup = payload.data;
            if (payload.data?.length > 0) {
                payload.data.map((item, i) => {
                    state.generalMultiData[item.respondentCode] = {};
                    state.respondentCodeArr = [...state.respondentCodeArr, item.respondentCode]
                })
            }
        },
        getQuestionData(state, { payload }) {
            const data = payload.data;
            const newData = data.map((itemDetail, j) => {
                // 初始化数据，开始
                // initDataId: state.generalData = {}, 只初始化显示题目的id
                const questionnaireSubjectVersionId = itemDetail.questionnaireSubjectVersionId ? itemDetail.questionnaireSubjectVersionId : itemDetail.questionnaireSubjectId;
                state.generalData[questionnaireSubjectVersionId] = [];

                const generalMultiData = state.generalMultiData;
                for (let i in generalMultiData) {
                    state.generalMultiData[i][questionnaireSubjectVersionId] = [];
                }

                // initValidateRules: state.validateRules = {}, 初始化所有题目
                const widgetRules = {
                    controlRegular: itemDetail.controlRegular ? itemDetail.controlRegular : '',
                    required: (itemDetail.required === 1) ? true : false
                }

                let otherRules = {};
                itemDetail.options?.map(item => {
                    if (item.otherOption) {
                        otherRules[item.optionVersionId] = {
                            ...item.otherOption.controlRegular,
                            required: (item.otherOption.required === 1) ? true : false
                        }
                    }
                })
                const ruleData = {
                    ...widgetRules,
                    otherRules
                }
                state.validateRules[questionnaireSubjectVersionId] = ruleData;

                // initValidateResult: state.validateResult = {}, 只初始化显示题目的id
                if (itemDetail.show === 1) {
                    state.validateResult[questionnaireSubjectVersionId] = {};
                }

                // initAttributeData: state.attributeData = {}，初始化所有题目
                let effectOption = [];
                itemDetail.jumpSubjects?.map(item => {
                    item.triggerConditions.map(triggerItem => {
                        effectOption.includes(triggerItem.triggerQuestionnaireOption) ? '' : effectOption.push(triggerItem.triggerQuestionnaireOption);
                    })
                });
                let other = [];
                let exclusive = '';
                const options = itemDetail.options?.map(item => {
                    item.otherOption === null ? '' : other.push(item.optionVersionId);
                    if (item.exclusive) {
                        exclusive = item.optionVersionId;
                    }
                    return {
                        key: item.optionVersionId,
                        value: item.display,
                        des: item.content
                    }
                });

                let direction = 'vertical';
                let mDirection = 'vertical';
                if (itemDetail.layoutType === 1) {
                    direction = 'horizontal';
                }
                if (itemDetail.layoutType === 2) {
                    direction = 'two';
                }
                if (itemDetail.mobileLayoutType === 1) {
                    mDirection = 'verticalReverse';
                }
                if (itemDetail.mobileLayoutType === 2) {
                    mDirection = 'two';
                }

                const demoAssesses = [
                    {
                        assessCode: "100319",
                        assessName: "组织一"
                    },
                    {
                        assessCode: "102678",
                        assessName: "组织二"
                    }
                ];
                const attributeData = {
                    label: itemDetail.displayName,
                    labelStyle: itemDetail.styleDisplayName,
                    other,
                    tips: itemDetail.content,
                    hidden: itemDetail.show === 1 ? false : true,
                    related: itemDetail.show === 1 || (itemDetail.show === 0 && itemDetail.hasSerialNumber) ? false : true,
                    number: itemDetail.show === 1 ? itemDetail.subjectIndex : null,
                    required: itemDetail.required === 1 ? true : false,
                    direction,
                    mDirection,
                    effectOption,
                    options,
                    exclusive,
                    assess: itemDetail.assesses?.length > 0 ? itemDetail.assesses : demoAssesses,
                    relatedKey: itemDetail.showQuestionnaireSubjectVersionId,
                    jumpSubjects: itemDetail.jumpSubjects,
                    hiddenSubjectIndex: itemDetail.hiddenSubjectIndex,
                    separateShow: itemDetail.separateShow,
                    show: itemDetail.show,
                    hasSerialNumber: itemDetail.hasSerialNumber,
                    subjectIndex: itemDetail.subjectIndex,
                    type: itemDetail.type
                };
                state.attributeData[questionnaireSubjectVersionId] = attributeData;
                if (itemDetail.required === 1 && itemDetail.show === 1) {
                    const oldRequired = state.initRequired;
                    state.initRequired = oldRequired + 1;
                }
                // 撤回触发initRequired规则
                // itemDetail?.options.map(singleItem => {
                //     if (singleItem?.otherOption?.required === 1) {
                //         const oldRequired = state.initRequired;
                //         state.initRequired = oldRequired + 1;
                //     }
                // })

                // initEffectDataData: state.effectData = {}，初始化所有题目
                let effectData = {};
                itemDetail.jumpSubjects?.map(item => {
                    const triggerConditions = item.triggerConditions;
                    triggerConditions.map((itemOpt, j) => {
                        const { triggerQuestionnaireOption, action, jumpQuestionnaireSubjectId } = itemOpt;
                        const effectItem = {
                            hidden: action === 1 ? false : true,
                            effectId: jumpQuestionnaireSubjectId
                        }
                        if (!effectData[triggerQuestionnaireOption]) {
                            effectData[triggerQuestionnaireOption] = [];
                        }
                        effectData[triggerQuestionnaireOption].push(effectItem);
                    });
                });
                state.effectData[questionnaireSubjectVersionId] = effectData;
                // 初始化数据，结束

                // 初始化草稿
                let draftByQuestion = state.draftData;

                if (itemDetail.draft && Object.keys(itemDetail.draft).length !== 0) {
                    const subjectDraft = itemDetail.draft;
                    for (let respondent in subjectDraft) {
                        for (let i in subjectDraft[respondent]) {
                            if (!draftByQuestion.hasOwnProperty(i)) {
                                draftByQuestion[i] = {};
                            }
                            // 获取题目时，如果题目为矩阵题数据为空 则不需要存储 等焰冰方案
                            // draftByQuestion[i][respondent] = itemDetail.draft[respondent][i]?.filter(v => v.value);
                            draftByQuestion[i][respondent] = itemDetail.draft[respondent][i];
                        }
                    }
                }
                state.draftData = draftByQuestion;

                return {
                    id: questionnaireSubjectVersionId,
                    type: itemDetail.type,
                    hidden: itemDetail.show === 1 ? false : true,
                    separateShow: itemDetail.separateShow,
                    show: itemDetail.show,
                    hasSerialNumber: itemDetail.hasSerialNumber
                }
            });

            state.subjectsDataList = [...state.subjectsDataList, ...newData];
            const resultData = newData.find(v => {
                if (!v.hidden) {
                    return v
                }
            })

            let arr = [];
            let i = 0;
            if (resultData) {
                // 处理隐藏题separateShow为1的数组
                newData?.map(item => {
                    if (item.separateShow === 1) {
                        arr.push([item]);
                        i++;
                    } else {
                        arr[i-1]?.push(item);
                    }
                })
                state.subjectsDataGroupList.push(...arr);
            };
        },
        // 题目序号
        getSubjectIndex(state, { payload }) {
            const data = payload.data;

            let currentIndex = 1;
            // 如果隐藏题需要分块
            if (state.attributeData[data]?.hasSerialNumber){
                for (let attributeDataItemId in state.attributeData) {
                    if(attributeDataItemId === data) {
                        break;
                    }
                    if(!state.attributeData[attributeDataItemId].hidden && state.attributeData[attributeDataItemId].hasSerialNumber) currentIndex++;
                }
            } else {
                let subIndex = 1;
                let complements = 0;
                let isFind = false;
                let jumpMap = {};

                for (let attributeDataItemId in state.attributeData) {
                    if(attributeDataItemId === data) {
                        break;
                    }
                    // 如果隐藏题展示了且需要独立序号，则将序号补位
                    if(!state.attributeData[attributeDataItemId].hidden &&
                        state.attributeData[attributeDataItemId].show === 0 &&
                        state.attributeData[attributeDataItemId].hasSerialNumber){
                        complements++;
                    }
                    state.attributeData[attributeDataItemId].jumpSubjects?.map(item => {
                        item.triggerConditions.map(({jumpQuestionnaireSubjectId}) => {
                            jumpMap[jumpQuestionnaireSubjectId] = attributeDataItemId;
                            if(jumpQuestionnaireSubjectId === data) {

                                // 如果该题需要序号
                                if (state.attributeData[data].hasSerialNumber) {
                                    currentIndex = state.attributeData[attributeDataItemId].subjectIndex + complements
                                }
                                else {
                                    // 获取父级题目的序号
                                    const { number } = state.attributeData[jumpMap[jumpQuestionnaireSubjectId]];

                                    // 如果父级题目序号含-则取第一个数
                                    if (number.toString().indexOf('-') > -1) {
                                        currentIndex = number.split('-')[0];
                                    } else {
                                        // 否则取父级序号
                                        currentIndex = number;
                                    }

                                }
                                isFind = true
                                return;
                            }
                            if (isFind) return;
                        });
                        if (isFind) return;
                    });
                }

                for (let attributeDataItemId in state.attributeData) {
                    if(attributeDataItemId === data) {
                        break;
                    }

                    const n = state.attributeData[attributeDataItemId]?.number?.toString() || '';

                    if(!state.attributeData[attributeDataItemId].hidden && n !== null && n.indexOf(`${currentIndex}-`) >= 0){
                        subIndex++;
                    }
                }

                currentIndex = currentIndex + '-' + subIndex;
            }
            state.attributeData[data].number = currentIndex;
        },
        setDataId(state, { payload }) {
            const Id = payload.id;
            state.generalData[Id] = [];
        },
        // 一维的题目的答案会在generalData里存一份
        // 即除了respondetCode合并题以及accessCode部门聚合题
        // 后面两者对草稿的处理方式与一维题目不一致，所以此处数据存储方式有diff
        setGeneralData(state, { payload }) {
            const Id = payload.id;
            const data = payload.data;
            state.generalData[Id] = payload.data;
        },
        setGeneralMultiData(state, { payload }) {
            state.cancelTokenQesId = payload.id;
            const Id = payload.id;
            const data = payload.data;
            const respondentCode = payload.respondentCode;
            state.generalMultiData[respondentCode][Id] = payload.data;
            // dstate.validateFlag = false;
        },
        setValidateResult(state, { payload }) {
            const Id = payload.id;
            const data = payload.data;
            state.validateResult[Id] = data;
        },
        // 只有单人问卷会调这个方法
        updateAttributeData(state, { payload }) {
            const Id = payload.id;
            const data = payload.data;
            const oldData = state.attributeData[Id];
            const respondentCode = data.currentRespondentCode;
            state.attributeData[Id] = Object.assign({}, oldData, data);
            // 需要判断上一次该题是否是被隐藏状态，只有在上一次是隐藏状态下时，校验值才置空
            if (!data.hidden && oldData.hidden) {
                state.validateResult[Id] = {};
            }
            if (data.hidden) {
                delete state.validateResult[Id];
                state.errorListMap.splice(state.errorListMap.indexOf(Id), 1);
                state.generalMultiData[respondentCode][Id] = [];
            }
        },
        hiddenAllAttributeData(state, { payload }) {
            payload?.list?.map(item => {
                delete state.validateResult[item];
                state.attributeData[item] = Object.assign({}, { hidden: true });
                // state.attributeData[item]?.hidden = true;

                if (state.errorListMap.indexOf(item) > -1) {
                    state.errorListMap.splice(state.errorListMap.indexOf(item), 1);
                }

                // state.generalMultiData[payload?.respondentCode][item] = [];
            })
            state.attributeData = payload?.cloneData;
        },
        setProcessRate(state, { payload }) {
            let requiredLen = 0;
            let widgetErrorLen = 0;
            let otherErrorLen = 0;
            for (let key in payload.validateResult) {
                // 无需使用初始化的值
                if (payload.attributeData[key].required) {
                    requiredLen += 1;
                }
                // 有这道题的必填 error为false或没有error字段代表有错误，有提示错误时，漏答题队列中不存在该题时，加入漏答题队列
                if (payload.attributeData[key].required && !payload.validateResult[key].error) {
                    widgetErrorLen += 1;
                    if (state.errorListMap.indexOf(key) < 0) {
                        state.errorListMap.push(key);
                    }
                }

                // 有这道题的必填 没有提示错误时 漏答题队列中存在该题时，漏答题队列删除该题
                if (payload.attributeData[key].required
                    && payload.validateResult[key].error
                    && (state.errorListMap.indexOf(key) > -1)) {
                    state.errorListMap.splice(state.errorListMap.indexOf(key), 1);
                }

                // otherResult的必填校验处理 error代表该题如果存在otherResult 以error字段判断逻辑为主
                let error;
                if (payload.validateResult[key].otherResult) {
                    // 当前题目是否存在错误字段 error 非必答题不存在error字段 必答题存在error字段
                    const hasError = payload.validateResult[key]?.hasOwnProperty('error');
                    // 必答题
                    if (hasError) {
                        // 赋值当前题目的error字段
                        error = payload.validateResult[key].error;
                        // 当error = true时，需要判断每个option中的error字段是否为false
                        if (error) {
                            for (let i in payload.validateResult[key].otherResult) {
                                if (payload.validateResult[key].otherResult[i].error === false) {
                                    error = false;
                                    otherErrorLen += 1;
                                    break;
                                };
                            };
                        }
                    } else { // 非必答题 不存在error
                        for (let i in payload.validateResult[key].otherResult) {
                            error = true;
                            // 需要判断每个option中的error字段是否为false
                            if (payload.validateResult[key].otherResult[i].error === false) {
                                error = false;
                                otherErrorLen += 1;
                                break;
                            };
                        };
                    };
                };

                // 这道题的有其他必填项
                if (payload.validateResult[key].otherResult) {
                    for (let opt in payload.validateResult[key].otherResult) {
                        // 有错误，该题加入漏答题队
                        if (payload.validateResult[key].otherResult[opt] && !error) {
                            if (state.errorListMap.indexOf(key) < 0) {
                                state.errorListMap.push(key);
                            }
                        }

                        // 没有错误，漏答题队删除该题
                        if (payload.validateResult[key].otherResult[opt]
                            && payload.validateResult[key].otherResult[opt].error
                            && (state.errorListMap.indexOf(key) > -1) && error) {
                            state.errorListMap.splice(state.errorListMap.indexOf(key), 1);
                        }
                    }
                }

                // 这道题非必填 并且没有其他必填项 漏答题队列中存在
                if (!payload.attributeData[key].required && !payload.validateResult[key].otherResult) {
                    if (state.errorListMap.indexOf(key) > -1) {
                        state.errorListMap.splice(state.errorListMap.indexOf(key), 1);
                    }
                }
            }

            state.errorList.widget = widgetErrorLen;
            state.errorList.other = otherErrorLen;
        },
        setRespondentTypeCode(state, { payload }) {
            state.respondentTypeCode = payload.data
        },
        getProcessRate(state, { payload }) {
            state.processRate = payload.data;
        },
        updateValidateFlag(state, { payload }) {
            state.validateFlag = payload.data
        },
        resetInitRequired(state, { payload }) {
            state.initRequired = -1;
        },
        updateLimitIndex(state, { payload }) {
            state.limitIndex = payload.data;
        },
        updateLoadStatus(state, { payload }) {
            state.loading = payload.data;
        },
        updateCurrentRespondentCode(state, { payload }) {
            state.currentRespondentCode = payload.data;
        },
        setCurrentLang(state, { payload }) {
            state.currentLang = payload.data
        },
        setActivityId(state, { payload }) {
            state.activityId = payload.data;
        },
        updateCurrentSubject(state, { payload }) {
            state.currentSubject = payload.data;
        },
        updateLoadAll(state, { payload }) {
            state.loadAll = true;
        },
        setParticipantWorkNo(state, { payload }) {
            state.participantWorkNo = payload.data
        },
        setErrorListSoreMap(state, { payload }) {
            state.errorListSoreMap = payload.data
        },
        setSurveyBusType(state, {payload}) {
            state.surveyBusType = payload.data
        },
        // 记录上一次选择的选项
        setPrevOptionIds(state, {payload}) {
            state.prevOptionIds = payload.data
        },
        setCurOptionIds(state, {payload}) {
            state.curOptionIds = payload.data
        },
        updateFetchMore(state, {payload}) {
            state.fetchMore = payload.data
        }
    },
});


// 导出actions
export const {
    setDataId,
    setGeneralData,
    setValidateResult,
    updateAttributeData,
    hiddenAllAttributeData,
    getQuestionData,
    setProcessRate,
    setShowLength,
    updateLoadIndex,
    getRespondentCode,
    setGeneralMultiData,
    setRespondentTypeCode,
    deleteHiddenGroup,
    getProcessRate,
    updateValidateFlag,
    resetInitRequired,
    updateLoadStatus,
    updateCurrentRespondentCode,
    setCurrentLang,
    setActivityId,
    updateCurrentSubject,
    updateLoadAll,
    setParticipantWorkNo,
    setErrorListSoreMap,
    setSurveyBusType,
    getSubjectIndex,
    setPrevOptionIds,
    setCurOptionIds,
    updateFetchMore
} = questionContentSlice.actions;

// 内置了thunk插件，可以直接处理异步请求
// export const asyncIncrement = (payload) => (dispatch) => {
//     setTimeout(() => {
//         dispatch(increment(payload));
//     }, 2000);
// };

export default questionContentSlice.reducer; // 导出reducer，在创建store时使用到