import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 工作经历
 * url: /api/data/growthTeach
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getLectureRecords = (apiHost, headers, params) => request(`${apiHost}${apis.getLectureRecords}`, 
  {
    method: 'get',
    headers,
    params
  }
);

export default getLectureRecords;
