import React from 'react';
import './index.scss';
import { Button } from 'antd';
import { di18n } from '@ultra/common';
import { ExclamationCircleFilled, RedoOutlined } from '@ant-design/icons';

const { intl } = di18n;
const CTModalConfirm = props => {
  const { modalContent = {}, changeSaveModalConfirm, type, disabled, setSpinning, surveyBusType } = props;
  const { onOk, okText, isHaveCelBtn = true } = modalContent;

  const renderIcon = () => {
    if (type && type === 'refresh') {
      return <RedoOutlined />
    }
    return <ExclamationCircleFilled />
  }
  return (
    <div className="ct-question-modal-confirm-wrap">
      <div className="ct-question-modal-confirm-card">
        {renderIcon()}
        <p className="title">{modalContent.title}</p>
        <p className="content">{modalContent.content}</p>
        {!!onOk
          && (
            <p className="footer-btn">
            {isHaveCelBtn && <Button className={surveyBusType === 'NON_PROFESSIONAL_SERVICES' && 'cancel-blue'} onClick={() => { changeSaveModalConfirm(); setSpinning(false); }} disabled={type && type === 'submit' ? disabled : false}>{intl.t('取消')}</Button>}
              <Button className={surveyBusType === 'NON_PROFESSIONAL_SERVICES' && 'subumit-blue'} onClick={() => { onOk(); }} type="primary">{okText || intl.t('确定')}</Button>
            </p>
          )}
      </div>

    </div>
  );
};

export default CTModalConfirm;
