import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  getWaterfall: async (host, headers) => {
    const result = await PageletApi.request(`${host}/${apis.getWaterfall}`, {
    // const result = await PageletApi.request(`${apis.getDetail}`, {
      method: 'get',
      headers,
    });
    return result;
  },
  initHorizon: async (host, params, headers) => {
    const result = await PageletApi.request(`${host}/${apis.initHorizon}`, {
    // const result = await PageletApi.request(`${apis.initHorizon}`, {
      method: 'get',
      params,
      headers,
    });
    return result;
  },
  getDetail: async (host, params, headers) => {
    const result = await PageletApi.request(`${host}/${apis.getDetail}`, {
    // const result = await PageletApi.request(`${apis.getDetail}`, {
      method: 'get',
      params,
      headers,
    });
    return result;
  },
}

export default PageletServices;
