export function textHeight(fontSize, fontFamily, text) {
	const span = document.createElement("span");
	const dom = document.createElement("div");
	dom.style.width = "100%";
	dom.style.height = "10px";
	dom.style.overflow = "hidden";
	const result = {};
	result.width = span.offsetWidth;
	result.height = span.offsetHeight;
	span.style.visibility = "hidden";
	span.style.fontSize = fontSize;
	span.style.fontFamily = fontFamily;
	span.style.display = "inline-block";
	dom.appendChild(span);
	document.body.appendChild(dom);
	if (typeof span.textContent !== "undefined") {
		span.textContent = text;
	} else {
		span.innerText = text;
	}
	result.width = parseFloat(window.getComputedStyle(span).width) - result.width;
	result.height = parseFloat(window.getComputedStyle(span).height) - result.height;
	dom.remove();
	return result;
};

// 监听移动端软键盘弹起
export function monitorSoftKeyboard(callback) {
  const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
  window.addEventListener('resize', () => {
    // 键盘弹起与隐藏都会引起窗口的高度发生变化
    const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
    // 10 的数量差是因为IOS大屏手机特殊原因，弹起软键盘再恢复的大小和一开始的有落差
    if (Math.abs(resizeHeight - originalHeight) < 10) {
      // false 软键盘收起
      callback(false);
    } else {
      // true 软键盘弹起
      callback(true);
    }
  });
}
