import React from 'react';
import { Form } from 'antd';
function CustomFormItem(props) {
  return (
    <Form.Item
      {...props}
      children={
        React?.cloneElement?.(props?.children, {
          namePath: props?.name,
        }) || props?.children
      }
    />
  );
}

export default CustomFormItem;
