import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Popover} from '@didi/ec-base';
import { SOURCE_COLOR_MAP } from '../../constant.js';
import CardIcon from '../CardIcon';
import RightArrow from '../../assets/red_right_arrow.svg';
function DynamicItem(props) {
  const { prefixCls, info, showDetail, type} = props;
  const contentEle = useRef(null)
  const targetEle = useRef(null)
  const sourceTagRef = useRef(null)
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState(null);
  const [tipOpen, settipOpen] = useState(false);
  const [dynamicId, setdynamicId] = useState(null);

  const checkHeight = () => {
    const contentDom = contentEle.current;
    const contentWidth = contentDom.getBoundingClientRect().width - 21;
    const sourceTagEle = sourceTagRef.current;
    // 6是指标签距离左侧文字内容的边距
    const sourceTagWidth = sourceTagEle.getBoundingClientRect().width + 6;
    // const targetDom = targetEle.current;
    // const targetHeight = targetDom.getBoundingClientRect().height;
    // setIsEllipsis(targetHeight > 44)  // 超过两行高度的就省略，两行的高度是41，留一点buffer
    // const div = document.getElementById('myDiv');
    // const text = targetDom.textContent;
    const ctx = document.createElement('canvas').getContext('2d');
    // 字体大小变动需要更改
    ctx.font = '14px PingFang SC,Microsoft YaHei,sans-serif';
    const textWidth = ctx.measureText(info.content).width;
    // 96 是指日期的宽度
    if ((sourceTagWidth + textWidth) > (2 * contentWidth - 96)) {
      setIsEllipsis(true)
    } else {
      setContent(info.content);
      return;
    }
    const maxWidth = 2 * contentWidth - 96 - sourceTagWidth;
    // 字体大小变动需要更改
    let characterCount = 40;
    let measuredWidth = 0;
    const textLength = info?.content?.length || 0;
    let substring = ''
    if (textLength <= characterCount) {
      substring = info.content
      setContent(substring);
      return;
    }
    while (measuredWidth < maxWidth && characterCount < textLength) {
      substring = info.content.substring(0, characterCount + 1);
      measuredWidth = ctx.measureText(substring).width;
      characterCount++;
    }
    if (characterCount < textLength) {
      substring = info.content.substring(0, characterCount - 1);
    }
    setContent(substring + '...');
  }

  useEffect(() => {
    window.addEventListener('resize', checkHeight);
    setTimeout(checkHeight, 100);
    return () => {
      window.removeEventListener('resize', checkHeight);
    }
  }, [info]);
  return (
    <Tooltip
      open={tipOpen}
      title={isEllipsis ? info.content : ''}
      overlayClassName={`${prefixCls}-overlay-tooltip`}
      destroyTooltipOnHide
    >
      <Popover
        content={
          <div>
            <CardIcon type="iconwarning-circle-o" styles={{width: '14px', height: '14px'}}/>
            &nbsp;{title}
          </div>
        }
        open={info?.id === dynamicId}
        zIndex={type === 'drawer' ? 10000 : 999}
        destroyTooltipOnHide
      >
        <div
          className={`${prefixCls}-content-item`}
          ref={contentEle}
          onClick={() => {
            if(info?.detailUrl){
              showDetail(info)
            } else {
              setdynamicId(info?.id);
              setTitle(info?.toast);
              settipOpen(false);
            }
          }}
          onMouseLeave={()=>{
            setdynamicId(null);
            settipOpen(false);
          }}
          onMouseEnter={()=>{
            isEllipsis && settipOpen(true);
          }}
        >

          <div style={{height: '44px', overflow: 'hidden'}}>
            <span
              className={`source-tag ${SOURCE_COLOR_MAP[info.sourceType]}`}
              ref={sourceTagRef}
            >{info.sourceType}</span>
            <span className='content-text' ref={targetEle}>{content}</span>
            {/* {
              isEllipsis ? (
                <span className='shadow-dom'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{info.date}</span>
              ) : null
            } */}
            {/* <span className='item-date'>{isEllipsis ? <span className='content-text'>...</span> : <>&nbsp;&nbsp;&nbsp;</>}&nbsp;&nbsp;&nbsp;{(info.date || '').replace(/-/g, '.')}</span> */}
            <span className='item-date'>{(info.date || '').replace(/-/g, '.')}</span>
            <span className="item-date-message">
              <span>详情</span>
              <img src={RightArrow} />
            </span>
          </div>
        </div>
      </Popover>
    </Tooltip>
  )
}

export default DynamicItem;
