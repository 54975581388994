import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-personal-organization', () => [
		{
			// base
			[`.${prefixCls}`]: {
        width: '100%',
				['.personal-organization-modal']: {
					padding: '8px',
					// 通用模块样式
					['.personal-organization-modal-block']: {
						padding: '12px 16px',
						'border-radius': '12px',
						'box-sizing': 'border-box',
						'margin-bottom': '16px',
						background: '#fff',
						['&:last-child']: {
							'margin-bottom': 0,
						},
						// 标题
						['.personal-organization-modal-block-title']: {
							position: 'relative',
							'padding-left': '9px',
							'margin-bottom': '16px',
							'font-family': '苹方-简',
							'font-size': '16px',
							'font-weight': '500',
							'line-height': '24px',
							'letter-spacing': '0px',
							color: 'rgba(0, 0, 0, 0.84)',
							['&::before ']: {
								content: "''",
								display: 'block',
								width: '3px',
								height: '14px',
								position: 'absolute',
								left: '0',
								top: '5px',
								'border-radius': '3px',
								'background-color': 'rgba(255, 100, 0, 1)',
							},
						},
						// 展示项
						['.personal-organization-modal-item']: {
							'font-family': 'PingFang SC',
							'font-size': '14px',
							'font-weight': 'normal',
							'line-height': '22px',
							'letter-spacing': '0px',
							['.personal-organization-modal-label']: {
								'margin-bottom': '4px',
								color: 'rgba(0, 0, 0, 0.6)',
							},
							['.personal-organization-modal-value']: {
								display: 'flex',
								'flex-wrap': 'wrap',
								color: 'rgba(0, 0, 0, 0.84)',
								'word-break': 'break-all',

							},
						},
						// 一行2个样式
						['.personal-organization-modal-both']: {
							display: 'flex',
              gap: '8px',
							['.personal-organization-modal-both-card']: {
								width: "50%",
							},
						},
						// 间距
						['.personal-organization-margin-bottom-16']: {
							'margin-bottom': '16px',
						},
					},
					// 正常头像+姓名(ldap)
					['.personal-organization-modal-base']: {
            cursor: 'pointer',
						// position: 'relative',
						display: 'flex',
						// 'align-items': 'center',
						'font-family':' PingFang SC',
						'font-size': '14px',
						'font-weight': 'normal',
						// 'line-height': '22px',
						// 'min-height': '24px',
						// 'letter-spacing': '0px',
						/* ES颜色库/黑-0.84 */
						color: 'rgba(0, 0, 0, 0.84)',
						'margin-right': '12px',
						'padding': '2px 4px',
						['&:last-child']: {
							'margin-right': '0',
						},
						['.personal-organization-modal-img']: {
							width: '20px !important',
							height: '20px !important',
							borderRadius: '50%',
							// overflow: 'hidden',
							// margin-right: 4px,
							// position: 'absolute',
							// top: '2px',
							// left: '0',
							['.ant-avatar']: {
								width: '100% ',
								height: '100%',
                border: 'none',
                'img': {
                  width: '100%',
								  height: '100%',
                }
							},
						},
						['.personal-organization-modal-name']: {
							flex: 1,
              fontSize: '14px',
              lineHeight: '22px',
              marginTop: '2px',
              marginLeft: '2px',
              color: 'rgba(0, 0, 0, 0.84)',
						},
					},
					// 大号头像+姓名(ldap)
					['.personal-organization-modal-large-base']: {
						'font-size': '16px',
						'font-weight': '500',
						'line-height': '24px',
						'font-variation-settings': '"opsz" auto',
            display: 'flex',
            alignItems: 'center',
						color: 'rgba(0, 0, 0, 0.8)',
						'min-height': '34px',
            ['.ant-avatar']: {
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              border: 'none',
              'img': {
                width: '100%',
                height: '100%',
              }
            },
						['.personal-organization-modal-name']: {
							flex: '1',
              marginLeft: '12px',
						},
					},
					['.personal-organization-modal-pointer']: {
						cursor: 'pointer',
            ['&:hover']: {
              background: 'rgba(0, 0, 0, 0.04)',
              borderRadius: '6px',
            },
					},
				}
			},
			['.pagelet-personal-info-trainee-drawer-more .ant5-drawer-header']: {
				background: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/organization-title.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 62px',
        backgroundPosition: 'center',
				['.ant5-drawer-header-title']: {
					marginLeft: '8px'
				}
			},
			['.pagelet-personal-info-trainee-drawer-more .ant5-drawer-body']: {
				background: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/organization-body.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
			}
		}
	]);
};

export default useStyle;
