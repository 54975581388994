export const recordPageFMP = (fmpTime) => {
  window.taotieCommandQueue && window.taotieCommandQueue.push({
    command: 'sendEventlogMessageToKafka',
    parameter: {
      category: 'performance',
      action: 'fmp',
      dimension1: `${location.pathname}${location.search}`,
      dimension2: fmpTime
    }
  });
}

