import React, { useEffect, useRef, useState } from 'react';
import PageletServices from '../../services';
import { Template } from '@didi/ehr-department-selection';
import { DownOutlined } from '@ant-design/icons';

// const functionId = '687';

function DepartmentSelection({ apiHost, auth, defaultDeptCode, defaultDeptInfo, onDeptChange = () => { } }) {
  const { id } = auth;
  const { current: pageCode } = useRef(
    window.location.pathname.split('/').findLast(() => true) === 'manager-workspace' ? 'management' : window.location.pathname.split('/').findLast(() => true),
  );
  const hasAuth = useRef(false);
  const [deptCodes, setDeptCodes] = useState([]);
  // const [deptCodesData, setDeptCodesData] = useState([]);
  const [tooltipDefaultValue, setTooltipDefaultValue] = useState(undefined);
  const [clear, setClear] = useState(false)

  const params = JSON.parse(localStorage.getItem('authInfo'));
  const getRecentSearch = async () => await PageletServices.getRecentSearch(apiHost, { ...params });

  const onDeptSelect = async (value) => await PageletServices.onDeptSelect(apiHost, { codeList: [value], ...params });

  const delRecentSearchFunc = async (value) => await PageletServices.delRecentSearchFunc(apiHost, { codeList: [value.deptCode], ...params });

  const getDataFunc = async () => await PageletServices.getDataFunc(apiHost, { ...params });
  useEffect(() => {
    if (defaultDeptInfo?.length) {
      setDeptCodes(defaultDeptInfo)
      let fullLabel = '';
      if (defaultDeptInfo?.length > 1) {
        fullLabel = '';
      } else {
        fullLabel = defaultDeptInfo?.[0]?.fullLabel
      }
      setTooltipDefaultValue(fullLabel)
    }
  }, [defaultDeptInfo, defaultDeptCode])

  useEffect(() => {
    if (deptCodes?.length > 0 && !clear) {
      const item = deptCodes?.length > 1 ? defaultDeptCode : [deptCodes[0]?.value]
      onDeptChange(item);
    }
    if (clear && deptCodes?.length === 0) {
      setDeptCodes(defaultDeptInfo[0]?.allDeptList);
      onDeptChange(defaultDeptInfo[0]?.allDeptList || [], true);
    }
  }, [deptCodes])

  const handleChange = (item) => {
    setDeptCodes(item);
  }
  return (
    <div className="department-selection" style={{ width: '312px' }}>
      {params ? (
        <Template
          value={deptCodes?.length > 1 ? [{ value: '', label: '全部部门' }] : deptCodes}
          tooltipDefaultValue={tooltipDefaultValue}
          labelInValue
          needExtra
          allowClear={true}
          onClear={() => {
            setDeptCodes([]);
            setClear(true);
          }}
          onChange={(item) => {
            setClear(false);
            // 用户输入的时候item实际为 Event对象   是无效的值
            // 用户删除的时候item为null  为正常情况复合判断条件
            if (!item || (item?.value && item?.label)) {
              handleChange([item])
            }

          }}
          params={params}
          attributes={{
            getPopupContainer: (triggerNode) => triggerNode,
            switcherIcon: <DownOutlined />,
          }}
          recentPlacement="left"
          getRecentSearchFunc={getRecentSearch}
          onDeptSelectFunc={onDeptSelect}
          delRecentSearchFunc={delRecentSearchFunc}
          getDataFunc={getDataFunc}
        // afterLoading={(item) => {
        //   getAfterLoading(item);
        // }}

        // multiple
        // treeCheckable
        // showCheckedStrategy={"SHOW_PARENT"}
        />
      ) : null}
    </div>
  );
}

export default DepartmentSelection;
