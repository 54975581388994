import React, { useEffect, useState } from 'react';
import { Pagination, ConfigProvider } from 'antd';
import { Spin, Avatar } from '@didi/ec-base';
import moment from 'moment';
import TopArrow from '../../assets/top_arrow.svg';
import BottomArrow from '../../assets/bottom_arrow.svg';
import RedRightArrow from '../../assets/red_right_arrow.svg';
import { CARE_CODE_ENUM } from "../../constant.js";

function CareCard (props) {
  const {
    data: {
      empLdap = '',
      empName = '',
      empId = '',
      small = '',
      date = '',
      hireYear = 0,
      warnType = '',
    }
  } = props;

  return (
    <div className="care-card" >
      <div className="care-card-avatar">
        <Avatar src={small ? small : '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/avatar.png'} />
      </div>
      <div className="care-card-content">
        <div className="care-card-content-item">
          <div className={`care-card-content-name ${warnType === CARE_CODE_ENUM.ANNIVERSARY ? 'anniversary-name' : ''}`} title={empName}>
            <span style={{fontWeight: '500px'}}>{empName}</span><span style={{fontWeight: 'normal' }}>&nbsp;{empId}</span>
          </div>

        </div>
        <div className="care-card-content-item">
          <div className="care-card-content-date">
            {`${moment(date).format('MM.DD')}`}
            {
            warnType === CARE_CODE_ENUM.ANNIVERSARY ? (
              <div className="anniversary">
                {`${hireYear}周年`}
              </div>
            ) : null
          }
          </div>
          <span
            className="message"
            onClick={() => {
              window.open(`dchat://im/start_conversation?name=${empLdap}`)
            }}
          >
            <span>会话</span>
            <RedRightArrow />
          </span>

        </div>
      </div>
    </div>
  )
}

function CareList(props) {
  const { cardLoading, typeCode = '0', pageNum = 1, pageSize = 4, info = {}, list = [], preQuery = {}, onClickMoreData = () => {}, onChangePageSize = () => {} } = props;
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    if(!cardLoading){
      setLoading(false);
    }
  },[cardLoading])
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#ff6400' }}}>
      <Spin spinning={loading}>
        <div className="care-list">
          {
            list.map((item) => (
              <CareCard data={item}/>
            ))
          }
        </div>
      </Spin>
      {info?.subNum > 4 && <div className="operation">
        <Pagination hideOnSinglePage size="small" showSizeChanger={false} current={pageNum} pageSize={pageSize} total={info.subNum} onChange={(num)=>{
          setLoading(true);
          onChangePageSize(typeCode,num);
        }}/>
      </div>}
    </ConfigProvider>
  );
}

export default CareList;
