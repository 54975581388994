import React, { useState, useEffect, useRef } from "react";
import { di18n } from "@ultra/common";
import { getLocale, setLocale } from "@didi/di18n-react";
import {
  setCookie,
  getCookie,
  getQuestionnaireInfo,
  getQuestionnaireLanguage,
  getQuestionnaireGeneralFromSetting,
  getQuestionnaireInfoFromSetting,
  getSurveyInfo,
  getQuestionSurveyInfo,
  getDraftHideSubjects,
  getQuestionnareDraftHideSubjects
} from "../../utils/Tool";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import Introduction from "../../components/Introduction";
import RenderWidget from "../../components/renderWidget";
import "./index.scss";
import soSmallheart from "../../images/footremarkheart.svg";
import {
  getRespondentCode,
  setCurrentLang,
  updateCurrentRespondentCode,
  setParticipantWorkNo,
  setActivityId,
  setSurveyBusType,
  hiddenAllAttributeData,
  updateFetchMore
} from "../../store/questionnaireNew/questionContentSlice"; // 引入actions
import { getHideSubjectIds } from '../../utils/questionnaire';

const { intl } = di18n;
export default function AssessedQuestionnaireH5Preview(props) {
  const limitCount = $CONFIG.customProps.groupCount
    ? $CONFIG.customProps.groupCount
    : 5;
  const dispatch = useDispatch();
  const [pageRemark, setPageRemark] = useState("");
  const [pageRemarkStyle, setPageRemarkStyle] = useState("");
  const [pageFooting, setPageFooting] = useState("");
  const [langKeyList, setLangKeyList] = useState([]);
  const [dataStatus, setDataStatus] = useState(true);

  // 2.0新增
  const [pageDataAll, setPageDataAll] = useState();

  // 区别单人问卷和多人问卷的展示, 1:单人 2:合并 3:调研问卷
  const [questionaireTypeShow, setQuestionaireTypeShow] = useState();
  const [respondentGroup, setRespondentGroup] = useState();
  const [lanType, setLanType] = useState();

  const {
    questionnaireId,
    activityId,
    participantWorkNo,
    respondentTypeCodePreview,
    index,
  } = props.match.params;
  const title = useRef("");

  const {
    loading,
    currentLang,
    lanMap,
    surveyBusType,
    prevOptionIds,
    curOptionIds,
    currentSubject,
    attributeData,
    respondentTypeCode,
    generalMultiData,
    initRequired,
    currentRespondentCode,
    fetchMore
  } = useSelector((state) => state.questionContent);

  // 根据不同的主题给类名
  const getClassNames = () => {
    let prefixCls = '';
    let mainPrefixCls = '';
    switch (surveyBusType) {
      case 'PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
      case 'NON_PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile question-main-banner-mobile-blue';
        mainPrefixCls = 'flex questionnaire-main questionnaire-main-blue';
        break;
      default:
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
    }

    return {
      prefixCls,
      mainPrefixCls,
    };
  };

  const setPreviewData = async () => {
    // 找到需要联动隐藏的id
    const { hideSubjectIds = [], cloneData } = getHideSubjectIds({
      generalMultiData, attributeData, currentSubject, prevOptionIds, currentRespondentCode
    });
    hideSubjectIds.forEach(item => {
      cloneData[item].hidden = true;
    });
    dispatch(updateFetchMore({ data: false }));
    if (!fetchMore) {
      dispatch(hiddenAllAttributeData({ list: hideSubjectIds, respondentTypeCode, cloneData }));
    }
  };

  // 文本题不需要调接口
  const getModelData = async () => {
    if (attributeData[currentSubject]?.type !== 3 || questionaireTypeShow !== 2) {
      setPreviewData();
    }
  };

  useEffect(() => {
    if (initRequired === -1 && currentSubject) {
      getModelData();
    }
  }, [generalMultiData]);

  useEffect(() => {
    const ctLang = getCookie("ct-lang");
    const lang = getLocale("lang");
    if (ctLang) {
      if (lang !== ctLang) {
        setLocale(ctLang, {
          cookieLocaleKey: "lang",
        });
      }
    }
    const current = ctLang ? ctLang : lang;
    let currentMap = 0;
    lanMap.map((item, i) => {
      if (item === current) {
        currentMap = i;
      }
    });
    dispatch(setCurrentLang({ data: currentMap }));

    // 如果是活动配置的问卷预览，则需要同步工卡号
    if (participantWorkNo) {
      dispatch(setParticipantWorkNo({ data: participantWorkNo }));
      dispatch(setActivityId({ data: activityId }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (currentLang > -1) {
        getGeneralData();
      }
    })();
  }, [currentLang]);

  useEffect(async () => {
    if (lanType) {
      const avaliableLan = setLanguage(currentLang, lanType);
      let infoData;
      if (questionnaireId) {
        // 进入页面先获取当前问卷的主题：PROFESSIONAL_SERVICES-暖橙色；NON_PROFESSIONAL_SERVICES-科技蓝
        const {data: {surveyBusType}} = await getQuestionSurveyInfo(questionnaireId);
        dispatch(setSurveyBusType({ data: surveyBusType }));
        const { data } = await getQuestionnaireInfo(
          avaliableLan,
          questionnaireId
        );
        infoData = data;
      } else {
        // 进入页面先获取当前问卷的主题：PROFESSIONAL_SERVICES-暖橙色；NON_PROFESSIONAL_SERVICES-科技蓝
        const {data: {surveyBusType}} = await getSurveyInfo(activityId);
        dispatch(setSurveyBusType({ data: surveyBusType }));
        const { data } = await getQuestionnaireInfoFromSetting(
          activityId,
          participantWorkNo,
          avaliableLan,
          getCodePreview(props.match.params.respondentTypeCodePreview)
        );
        infoData = data;
      }
      setPageDataAll(infoData);
      title.current = infoData?.styleName || infoData?.name;
      setPageRemark(infoData.remark);
      setPageRemarkStyle(infoData.styleRemark);
      setPageFooting(infoData.pageFooting);
      setDataStatus(false);

      // 判断问卷类型
      if (infoData) {
        switch (infoData.layoutType) {
          case 0: // 单人问卷
            setQuestionaireTypeShow(1);
            break;
          case 1: // 合并问卷
            setQuestionaireTypeShow(2);
            break;
          case 2: // 调研问卷
            setQuestionaireTypeShow(3);
            break;
          default:
            console.log("undefined questionnaireLayoutType");
        }
      }
    }
  }, [lanType]);

  useEffect(() => {
    const demoRespondentParam = [
      {
        activityRespondentFullName: "被评估对象1",
        activityRespondentName: "被评估对象1",
        respondentCode: "217650583304151040",
      },
      {
        activityRespondentFullName: "被评估对象2",
        activityRespondentName: "被评估对象2",
        respondentCode: "217650434989367296",
      },
      {
        activityRespondentFullName: "被评估对象3",
        activityRespondentName: "被评估对象3",
        respondentCode: "248390436463321088",
      },
    ];
    let resRespondent = demoRespondentParam;
    let resCurrent = "217650583304151040";
    if (respondentGroup) {
      const currentRespondentGroup = respondentGroup[index];
      resRespondent = currentRespondentGroup.activityRespondents;
      resCurrent = resRespondent[0].respondentCode;
    }
    dispatch(updateCurrentRespondentCode({ data: resCurrent }));
    dispatch(getRespondentCode({ data: resRespondent }));
  }, [respondentGroup]);

  const getGeneralData = async () => {
    if (questionnaireId) {
      const { data } = await getQuestionnaireLanguage(questionnaireId);
      setLanType(data);
    } else {
      const { data } = await getQuestionnaireGeneralFromSetting(
        activityId,
        participantWorkNo
      );
      setLanType(data.languageTypes);
      setRespondentGroup(data.respondentGroup);
    }
  };

  const setLanguage = (currentLangParam, lanTypeParam) => {
    // 获取题目数据
    let avaliableLan = currentLangParam;
    //setlanQue(lanType);
    setLangKeyList(Array.from(new Set(lanTypeParam)));

    // 判断当前问卷支持的语言与cookie里存的是否匹配，如果不匹配就把cookie切成问卷返回的第一个语言

    if (!lanTypeParam.includes(currentLangParam)) {
      if (lanTypeParam.includes(1)) {
        // 若问卷包含英文则请求英文问卷
        avaliableLan = 1;
      } else {
        // 若无英文，则请求问卷的第一个语言
        avaliableLan = lanTypeParam[0];
      }

      setLocale(lanMap[avaliableLan], {
        cookieLocaleKey: "lang",
      });
      setCookie("ct-lang", lanMap[avaliableLan]);
      dispatch(setCurrentLang({ data: avaliableLan }));
    }
    return avaliableLan;
  };

  const renderResult = () => {
    if (questionaireTypeShow === 1) {
      return renderQuestionaire();
    }

    if (questionaireTypeShow === 2) {
      return renderMultiQuestionaire();
    }

    if (questionaireTypeShow === 3) {
      return renderEnpsiQuestionaire();
    }
    return "";
  };

  const renderQuestionaire = () => {
    return (
      <>
        {pageDataAll ? (
          <RenderWidget
            pageDataAll={pageDataAll}
            itemType={2}
            multi={1}
            reviewType={1}
            renderType={1}
            isMobile
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const renderMultiQuestionaire = () => {
    return (
      <>
        {pageDataAll ? (
          <RenderWidget
            pageDataAll={pageDataAll}
            itemType={2}
            multi={2}
            reviewType={1}
            renderType={1}
            isMobile
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const renderEnpsiQuestionaire = () => {
    return (
      <>
        {pageDataAll ? (
          <RenderWidget
            pageDataAll={pageDataAll}
            itemType={2}
            multi={3}
            reviewType={1}
            renderType={1}
            isMobile
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      {!dataStatus ? (
        <Spin
          spinning={loading}
          tip={intl.t("问卷加载中，请稍后…")}
          wrapperClassName="w-full"
        >
          <div className="w-full flex">
            <div className={surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? 'question-main-blue' : 'question-main'}>
              <Introduction
                title={title.current}
                questionDes={pageRemark}
                pageRemarkStyle={pageRemarkStyle}
                langKeyList={langKeyList}
                getClassNames={getClassNames()}
              />
              {renderResult()}
              {pageFooting ? (
                <div className="question-main-des-mobile-footer">
                  {pageFooting}
                </div>
              ) : (
                ""
              )}
              {/* <div
                className="question-footer-concluding-remarks"
                style={{
                  textAlign: 'center',
                  background: surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                    ? 'linear-gradient(90deg, #CFE3FE 0%, #D1E3FF 5%, #D3E4FE 11%, #D9EAFF 37%, #E3F1FF 54%, #E3EEFF 70%, #DEE9FF 88%, #DDEBFE 99%)'
                    : '#FFFDFB'
                }}
              >
                <span>{intl.t("感谢您的帮助与支持")}</span>
                <img style={{ padding: "0 0 4px 6px" }} src={soSmallheart} />
              </div> */}
            </div>
          </div>
        </Spin>
      ) : (
        ""
      )}
    </>
  );
}
