import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageletServices from '../../services';
import { handleLink, sortedListFn } from '../../utils/utils';
import useStyle from './styles';

const ObjectiveRead = props => {
  const {
    deptList,
    activedDeptInfo = {},
    isExpand,
    objecitveList,
    setObjecitveList,
    apiHost,
  } = props;
  const { intervalInfo = {} } = activedDeptInfo; // 周期信息
  const [isCanFill, setIsCanFill] = useState(false); // 是否可以填写目标
  const [isRecord, setIsRecord] = useState(false); // 是否已审批
  const [isPublished, setIsPublished] = useState(false); // 是否已发布

  // 样式
  const prefixCls = 'objective-read';
  const wrapSSR = useStyle('objective-read');
  const classes = classNames(prefixCls);

  // 根据OwnerId获取ddo
  const getDetailByOwnerIdFn = async ownerId => {
    const detailRes = await PageletServices.getDetailByOwnerId('', ownerId, {});
    const sortData = sortedListFn(detailRes?.data || []); // 排序目标列表
    sortData?.forEach((ele, key) => {
      ele.orderNum = key + 1;
    });
    setObjecitveList(sortData || []); // 获取目标列表
  };

  useEffect(() => {
    // 获取当前岗位信息
    if (activedDeptInfo?.id) {
      getDetailByOwnerIdFn(activedDeptInfo?.id); // 获取目标列表
      setIsCanFill(activedDeptInfo.objectiveCanFill); // 设置是否可以填写目标
      setIsRecord(activedDeptInfo.state == Number('3')); // 设置是否已审批
      setIsPublished(activedDeptInfo.published); // 设置是否已发布
    }
  }, [activedDeptInfo?.id]);

  return wrapSSR(
    <div className={classes}>
      <div className={`goal_wrap scrol_mac`}>
        {/* 暂不需要设定目标 */}
        {deptList.length && !isCanFill && (
          <div>
            <div className="peng_empty">
              <img
                className="pengImg"
                src="//img-ys011.didistatic.com/static/ddo_web_static/do1_A8J1DH5q72mkc8kv5Eya"
              />
              <div className="peng_text">暂不需要设定目标</div>
            </div>
          </div>
        )}
        {/* 岗位目标审批中 */}
        {isRecord && (
          <div>
            <div className="peng_empty">
              <img
                className="pengImg"
                src="//img-ys011.didistatic.com/static/ddo_web_static/do1_AbXLDjBjj3XLVipr4QMN"
              />
              <div className="peng_text">
                岗位目标审批中，
                <span
                  className="peng_view_btn"
                  onClick={() => handleLink(activedDeptInfo, apiHost)}
                >
                  查看审批
                </span>
              </div>
            </div>
          </div>
        )}
        {/* 暂未设定岗位目标 */}
        {deptList.length > 0 && !objecitveList.length && !isPublished && !isRecord && isCanFill && (
          <div>
            <div className="peng_empty">
              <img
                className="pengImg"
                src="//img-ys011.didistatic.com/static/ddo_web_static/do1_fWFP4i762kpzhXzztvAn"
              />
              <div className="peng_text">
                暂未设定岗位目标
                {intervalInfo?.state === 100 && (
                  <>
                    <span>，</span>
                    <span
                      className="peng_view_btn"
                      onClick={() => handleLink(activedDeptInfo, apiHost)}
                    >
                      点此设置
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {/* 目标展示 */}
        {isPublished &&
          !isRecord &&
          objecitveList?.slice(0, isExpand ? objecitveList?.length : 2)?.map((item, index) => {
            return (
              <div
                key={index}
                className="item_ul"
                onClick={() => handleLink(activedDeptInfo, apiHost)}
              >
                <div className="item_el">
                  <div className="orderNum">
                    <span>O</span>
                    <span className="Oidnex">{item.orderNum}</span>
                  </div>
                  <div>
                    <div className={`item_text item_text_norml`}>{item.content}</div>
                    <div className="item_state">
                      <span className="item_weight">权重：{item.weight}</span>
                      {item.completeState == '10' && (
                        <div>
                          <span className="state pending">进行中</span>
                        </div>
                      )}
                      {item.completeState == '30' && (
                        <div>
                          <span className="state finish">已完成</span>
                        </div>
                      )}
                      {item.completeState == '40' && (
                        <div>
                          <span className="state pause">已暂停</span>
                        </div>
                      )}
                      {item.completeState == '50' && (
                        <div>
                          <span className="state break">已终止</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>,
  );
};

export default ObjectiveRead;
