import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import request from "../../utils/request";
import QuestionnaireProcessH5 from "../../components/QuestionnaireProcessH5";
import Introduction from "../../components/Introduction";
import { getCookie, getSurveyInfo } from "../../utils/Tool";
import {
  setSurveyBusType,
} from "../../store/questionnaireNew/questionContentSlice"; // 引入actions
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { getLocale, setLocale } from "@didi/di18n-react";

export default function QuestionnaireContinueHomeH5(props) {
  const dispatch = useDispatch();
  const questionDes =
    "为了给您提供更好的服务，希望您抽出几分钟的时间，讲您的感受和建议告诉我们，期待您的参与！";
  const [title, setTitle] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [processDataAll, setProcessDataAll] = useState(null);
  const [listData, setListData] = useState(null);
  const [langKeyList, setLangKeyList] = useState([]);
  const [currentLanQue, setCurrentLanQue] = useState([]);
  const { activityId, questionnaireId } = props.match.params;
  // 维护一个以语言为Key的list
  const [lanQue, setlanQue] = useState([]);
  // 单人问卷导航栏数据
  const [singleQuestionaireList, setSingleQuestionaireList] = useState([]);

  const {
    surveyBusType,
  } = useSelector((state) => state.questionContent);


  // 根据不同的主题给类名
  const getClassNames = () => {
    let prefixCls = '';
    let mainPrefixCls = '';
    switch (surveyBusType) {
      case 'PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
      case 'NON_PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile question-main-banner-mobile-blue';
        mainPrefixCls = 'flex questionnaire-main questionnaire-main-blue';
        break;
      default:
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
    }

    return {
      prefixCls,
      mainPrefixCls,
    };
  };


  // 获取匿名key,提交问卷时，需要传入busType=1获取key,获取进度时，传入2
  // 原接口：/api/anymous/encode
  const getKey = (busType) =>
    request("/ctAnonymity/api/answer/anymous/encode", {
      method: "POST",
      data: { encodeText: activityId, busType },
    });

  // 获取侧边栏问卷名称
  // 原接口：/api/activity/respondent/groupRespondentAndContentId
  const getActivityDetail = (activityId, participantCode) =>
    request(
      "/ctActivity/api/answer/activity/respondent/groupRespondentAndContentId",
      {
        params: { activityId, participantCode },
      }
    );

  // 获取问卷进度
  const getProcessAll = (activityId, participantCode, respondentTypeCode) =>
    request("/ctAnswer/api/answer/gertQuestionnaireProgressRate", {
      method: "POST",
      data: {
        activityId,
        participantCode,
        respondentTypeCode,
      },
    });

  const toInfo = (o) => {
    if (o.recycleStatus === 2) return;
    location.href = `${location.origin}/questionnaire/h5/${activityId}/${o.questionnaireId}/${o.respondentCode}/0`;
  };

  useEffect(async () => {
    const ctLang = getCookie("ct-lang");
    const lang = getLocale("lang");
    if (ctLang) {
      if (lang !== ctLang) {
        setLocale(ctLang, {
          cookieLocaleKey: "lang",
        });
      }
    }
    const currentLang = (ctLang || getLocale("lang")) === "zh-CN" ? 0 : 1;

    // 进入页面先获取当前问卷的主题：PROFESSIONAL_SERVICES-暖橙色；NON_PROFESSIONAL_SERVICES-科技蓝
    const {data: {surveyBusType}} = await getSurveyInfo(activityId);
    dispatch(setSurveyBusType({ data: surveyBusType }));

    // 获取匿名key
    const keyData = await getKey(2);
    const { data } = keyData;
    // 获取侧边问卷名称及状态，传给process组件渲染侧边栏问卷名称
    const {
      data: { respondentGroup, activityName, englishActivityName },
    } = await getActivityDetail(activityId, data);
    setSingleQuestionaireList(respondentGroup);
    let _activityName;
    currentLang === 0
      ? (_activityName = activityName)
      : englishActivityName
      ? (_activityName = englishActivityName)
      : (_activityName = activityName);
    setTitle(_activityName);
    const activityData = respondentGroup[0];
    const { contentInfos } = respondentGroup[0];
    setListData(contentInfos);

    // 判断需要填写的问卷都包含哪些语言
    let langAll = [];
    const langQuestionnaireArr = new Array();
    const currentLanQueTemp = new Array();
    for (const item in contentInfos) {
      const languageType = contentInfos[item].languageTypes;
      langAll = langAll.concat(languageType);
      // 取出包含当前语言的问卷，传给process组件做判断
      if (languageType.includes(currentLang)) {
        currentLanQueTemp.push(item);
        setCurrentLanQue(currentLanQueTemp);
      }
      // 维护一个以语言为Key的问卷List，目的是为了根据当前语言判断包含哪些问卷
      languageType.map((lan) => {
        if (!langQuestionnaireArr[lan]) {
          langQuestionnaireArr[lan] = [];
        }
        langQuestionnaireArr[lan].push(
          `${activityData.contentInfos[item].contentId}/${activityData.contentInfos[item].respondentCode}`
        );
      });
    }
    setlanQue(langQuestionnaireArr);
    // 先支持1或者2国语言
    const langSimplearr = [0];
    if (langAll.includes(1)) langSimplearr.push(1);
    setLangKeyList(langSimplearr);
    // 获取问卷进度
    await getProcessAll(
      activityId,
      keyData.data,
      activityData.respondentTypeCode
    ).then((data) => {
      if (data.data) {
        setProcessDataAll(data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (listData) {
      for (const item in listData) {
        if (
          listData[item].activityStatus === 0 ||
          listData[item].activityStatus === 4
        ) {
          location.replace(
            `${location.origin}/questionnaire/activity/not/started`
          );
          return;
        }
      }
      setLoadingStatus(false);
    }
  }, [listData]);

  return (
    <>
      <Spin
        spinning={loadingStatus}
        wrapperClassName="w-full h-full"
        style={{ height: "100%" }}
      >
        {!loadingStatus ? (
          <div
            className="page-warpper"
            style={{ backgroundColor: "#FFFDFB", height: "100vh" }}
          >
            <Introduction
              title={title}
              questionDes=""
              langKeyList={[0, 1]} // h5单份问卷页面 这里写死 展示中英文(历史bug)
              getClassNames={getClassNames()}
            />
            <div className="ct-questionnaire-continueh5-dept">
              <QuestionnaireProcessH5
                listData={listData}
                activityId={activityId}
                processDataAll={processDataAll}
                questionnaireId={questionnaireId}
                currentLanQue={currentLanQue}
                toInfo={toInfo}
                singleQuestionaireList={singleQuestionaireList}
              />
            </div>
          </div>
        ) : (
          <div style={{ height: "100vh" }}></div>
        )}
      </Spin>
    </>
  );
}
