// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/enpsBackground.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".status-wapper{\n  background:rgba(255,251,244,.4);\n  height:100vh\n}\n\n.status-wapper .status-banner{\n  background-position:bottom;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right bottom;\n  background-size:cover\n}\n\n@media(min-width: 375px)and (max-width: 767px){\n  .status-wapper .status-banner{\n    height:110px\n  }\n}\n\n@media(min-width: 767px)and (max-width: 3000px){\n  .status-wapper .status-banner{\n    height:170px\n  }\n}\n\n@media(min-width: 375px)and (max-width: 767px){\n  .status-wapper .status-banner img{\n    height:110px\n  }\n}\n\n@media(min-width: 767px)and (max-width: 3000px){\n  .status-wapper .status-banner img{\n    height:170px\n  }\n}\n\n.status-wapper .succeed{\n  display:flex;\n  justify-content:center\n}\n\n@media(min-width: 375px)and (max-width: 767px){\n  .status-wapper .succeed{\n    padding-top:30px\n  }\n}\n\n.status-wapper .status-text{\n  font-family:PingFangSC-Medium;\n  text-align:center;\n  color:#333\n}\n\n@media(min-width: 375px)and (max-width: 767px){\n  .status-wapper .status-text .text-main{\n    font-size:20px;\n    padding-top:25px\n  }\n}\n\n@media(min-width: 767px)and (max-width: 3000px){\n  .status-wapper .status-text .text-main{\n    font-size:24px\n  }\n}\n\n.status-wapper .status-text .text-des{\n  font-size:16px;\n  padding:9px 12px\n}\n\n.status-wapper .btn-content{\n  display:flex;\n  align-items:center;\n  justify-content:center\n}\n\n.status-wapper .btn-content .jump-btn{\n  border-radius:8px;\n  background:#0a70f5;\n  border-color:#0a70f5\n}", ""]);
// Exports
module.exports = exports;
