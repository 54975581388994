import React, { useState, useEffect } from 'react';
import { getLocale, Divider, Tooltip, Ellipse, ConfigProvider } from '@didi/ec-base';
import { Intl, newRequest } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';

import useStyle from './style';
import { ReactComponent as OrgSvg } from './assets/org.svg';
import { ReactComponent as TreeSvg } from './assets/tree.svg';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Warning } from './assets/warning.svg';
const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, headers = {}, deptCodes, pageCode },
  } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    'bricks-request-type': pageCode === 'hr-workspace' ? 'organization' : 'team', // 区分管理者和hrbp
    'Content-Type': 'application/json'
  }
  newRequest.init(newHeaders, '@didi/pagelet-org-workbench-card');

  const [orgData, setOrgData] = useState({});

  const handleRequest = async () => {
    try {
      const data = await PageletServices.getRequestData(apiHost, { deptCodeList: deptCodes });
      const result = data?.data || {};
      setOrgData(result);
    } catch (e) {
      console.log(e);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  const prefixCls = 'pagelet-org-workbench-card';
  const wrapSSR = useStyle(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);


  const handleClick = () => {
    const path = pageCode === 'hr-workspace' ? 'organization' : 'team';
    const href = `//${location.host}/${path}`;
    // const href = `//${location.host}/org/team`;
    window.open(href, '__blank');
  }

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">

      <div className={classes}>
        <h5 className="org-workbench-card-title">组织规划</h5>

        <div className="org-workbench-card-content">
          {/* <div className="org-workbench-card-content-org">
            <div className="org-workbench-card-content-org-title">
              <div className="title-name">
                <OrgSvg />
                <h6>
                  组织信息
                  {deptCodes.length > 1 && (
                    <><Divider type="vertical" />{orgData?.deptName}</>
                  )}
                </h6>
              </div>
              <div className="title-detail" onClick={handleClick}>
                查看详情
                <Arrow />
              </div>
            </div>

            <div className="org-workbench-card-content-org-container">
              <div className="dept-left">
                <div className="dept-box">
                  <div className="dept-box-name">
                    <div className="dept-box-name-value">{orgData?.deptAge || '-'}</div>
                    {orgData?.deptAge && <div className="dept-box-name-label">年</div>}
                  </div>
                  <Ellipse text={`成立：${orgData?.establishedTime}`} tooltipProps={{ placement: "bottom" }}>
                    <div className="dept-box-desc">成立：{orgData?.establishedTime}</div>
                  </Ellipse>
                </div>
                <div className="dept-box">
                  <div className="dept-box-name">
                    <div className="dept-box-name-value">{orgData?.drCount || '-'}</div>
                  </div>
                  <div className="dept-box-desc">DR部门</div>
                </div>
              </div>
              <div className="dept-right">
                <div className="dept-box">
                  <div className="dept-box-name">
                    <div className="dept-box-name-value">{orgData?.realCount || '-'}</div>
                  </div>
                  <Tooltip title="通用类正编" placement="bottom">
                    <div className="dept-box-desc">
                      部门人数
                      <Warning />
                    </div>
                  </Tooltip>
                </div>
                <div className="dept-box">
                  <div className="dept-box-name">
                    <div className="dept-box-name-value">{orgData?.hcCount || '-'}</div>
                  </div>
                  <div className="dept-box-desc">部门编制</div>
                </div>
                <div className="dept-box">
                  <div className="dept-box-name">
                    <div className="dept-box-name-value">{orgData?.hcPercent || '-'}</div>
                    {orgData?.hcPercent && <div className="dept-box-name-label">%</div>}
                  </div>
                  <div className="dept-box-desc">满编率</div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="org-workbench-card-content-planning">
            {/* <div className="title-name">
              <TreeSvg />
              <h6>组织规划</h6>
            </div> */}
            <div className="planning-content">
              <img src="//s3-ep.didistatic.com/mix/img/org-planing2.png" />
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
