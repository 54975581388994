import React from 'react';
import { di18n } from '@ultra/common';
import './index.scss';

const { intl } = di18n;

export default function () {
  return (
    <div className="ct-not-found-wrap">
      <div className="ct-component-not-found">
        <div>
          <img alt="" src="//img-hxy021.didistatic.com/static/ehr_static/imgs/ct/NoPermission.png" />
        </div>
        <div>{intl.t('答题地址错误，请使用正确答题地址')}</div>
      </div>
    </div>
  );
}
