import React from 'react';

const CardIcon = (props) => {
  const {type, styles, className = ''} = props;
  return (
    <svg className={`card-icon ${className}`} aria-hidden="true" style={styles}>
      <use href={`#${type}`}></use>
    </svg>
  )
}

export default CardIcon;
