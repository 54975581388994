import React, { useEffect, useState, useRef } from 'react';
import { ConfigProvider, getLocale, getLang, zh_CN, en_US } from '@didi/ec-base';
import { Tabs } from 'antd';
import { Intl } from '@didi/pagelets-sdk';
import classNames from 'classnames';
import useStyle from './style';
import TraineeList from './components/TraineeList';
import ApplyList from './components/ApplyList';
import Light from './assets/light.svg'
import manifest from '../manifest.json';
import newRequest from './utils/newRequest';
const { TabPane } = Tabs;
const localLocales = manifest.locales || {};
const Pagelet = (props) => {
  const {
    params: {
      auth,
      headers = {},
      apiHost,
      locales,
      close = () => { },
    },
  } = props;
  const prefixCls = 'pagelet-personal-info-trainee';
  const wrapSSR = useStyle(prefixCls);
  const { getPrefixCls } = React.useContext(ConfigProvider.ConfigContext);
  const modalPrefixCls = getPrefixCls('drawer', null); // ant-drawer
  const locale = getLocale(locales || localLocales);
  const classes = classNames(prefixCls);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');
  let authId = {};
  auth?.children.map((v) => {
    if (v?.elementTag === '@didi/pagelet-personal-info-trainee') {
      authId = {
        id: v?.id,
        functionName: v?.functionName
      }
    }
  })
  const newHeaders = {
    ...headers,
    'bricks-function-id': authId?.id,
    functionId: authId?.id,
    functionName: window.encodeURI(authId.functionName),
    'Content-Type': 'application/json',
  };
  const request = newRequest.init({
    ...newHeaders
  });

  const [activeTab, setActiveTab] = useState('trainee');
  const [list, setList] = useState([]);
  const [pageNum, setpageNum] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    setLoading(true);
    const url = activeTab === 'trainee' ?
      'api/workbench/personal/page/intern/employees'
      : 'api/workbench/personal/page/intern/applications';
    const params = {
      pageNum,
      pageSize
    }
    await request.post(`${apiHost}/gateway/bricks/${url}`, { ...params }).then((res) => {
      setList(res?.data)
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getList();
  }, [activeTab, pageNum, pageSize])

  const onPageChange = (num, size) => {
    setpageNum(num);
    setpageSize(size);
  }

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN}>
      <div className={classes}>
        <div className={`${prefixCls}-drawer-content`}>
          <Tabs
            activeKey={activeTab}
            onChange={(key) => {
              setList([]);
              setActiveTab(key);
            }}
            className="drawer-content-tabs"
          >
            <TabPane tab="我的实习生" key="trainee" forceRender>
              <div className="trainee-tit">
                <img src={Light} />
                &nbsp;显示在职和离职三个自然月内的实习生
              </div>
              <TraineeList
                langEn={langEn}
                data={list}
                pageNum={pageNum}
                pageSize={pageSize}
                onPageChange={onPageChange}
                authId={authId?.id}
                headers={newHeaders}
                intl={intl}
                apiHost={apiHost}
                getList={getList}
                loading={loading}
              />
            </TabPane>
            <TabPane tab="我的移交申请" key="apply" forceRender>
              <ApplyList
                langEn={langEn}
                data={list}
                pageNum={pageNum}
                pageSize={pageSize}
                onPageChange={onPageChange}
                intl={intl}
                loading={loading}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </ConfigProvider>,

  );
};

export default Pagelet;
