import React, { useRef, useEffect, useState } from 'react';
import { Avatar, getLang, getLocale, Tooltip } from '@didi/ec-base';
import classNames from 'classnames';
import styled from '@emotion/styled';
import Card from './Card';
import ArrowSvg from '../../assets/right-thick-o.svg';
import manifest from '../../../../manifest.json';
import useStyle from './style';

const localLocales = manifest.locales;

const getBackGround = (level) => {
  let bg = '';
  let border = '';
  switch (level) {
    case '1':
      bg = 'linear-gradient(190deg, #E8DCFF 0%, #FFFFFF 47%), #FFFFFF';
      border = '1px solid #BD9BFF';
      break;
    case '2':
      bg = 'linear-gradient(190deg, #DFE5FF 0%, #FFFFFF 47%), #FFFFFF';
      border = '1px solid #92A6FF';
      break;
    default:
      bg = 'linear-gradient(190deg, #ECF5FF 0%, #FFFFFF 47%), #FFFFFF';
      border = '1px solid #72B5FF';
      break;
  }

  return {
    bg,
    border
  };
};

const CollapseItem = (props) => {
  const {
    selfInfo,
    data,
    index,
    changeCollapsible,
    collapsible = false,
    height,
    type,
    cardBackGround,
    defaultLine = 1,
    width,
    headers,
    apiHost,
    locales
  } = props;

  const ref = useRef(null);
  const [isMore, setIsMore] = useState(false);
  const [max, setMax] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const onChange = () => {
    if (isMore && collapsible) {
      ref.current.scrollTop = 0;
    }
    changeCollapsible(data);
  };

  const resize = () => {
    if (data?.list?.length) {
      const { offsetWidth } = ref.current;
      const d = width ? width + 12 : 188;
      const maxNum = Math.floor((offsetWidth / d));
      setMax(() => maxNum);
      setCardWidth(() => (Math.floor(((offsetWidth) / maxNum) - 16)));
      setIsMore(() => maxNum < data.list.length);
      if (isMore && !collapsible) {
        ref.current.scrollTop = 0;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    resize();
  }, [data?.list, collapsible]);

  return (
    <CollapseItemWrapper
      isMore={isMore}
      collapsible={collapsible}
      height={height}
      defaultLine={defaultLine}
    >
      <div
        className="y-data"
        style={{
          height: height > 0 ? height + 12 : 80,
        }}
      >

        {data?.desc &&
          <Tooltip title={data.desc}>
            <div style={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center'
            }}>
              {data.desc}
            </div>
          </Tooltip>
        }
        {data?.num && (
          <span>
            <b>{data.num}</b>{getLang() === 'en-US' ? '' : '人'}
          </span>
        )}
      </div>
      <div className="c-item">
        <div className="c-content" ref={ref}>
          {data?.list?.map((v, i) => (
            <Card
              {...props}
              key={v?.sid ? v?.empId + v?.sid : v?.empId}
              style={{
                width: cardWidth || 176,
                marginBottom: 12,
                height,
                marginRight: (i + 1) % max === 0 ? 8 : 16,
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid rgba(0, 0, 0, 0.06)',
                borderRadius: '10px',
              }}
              item={{ ...v, desc: data?.desc }}
              type={type}
              cardBackGround={cardBackGround}
              selfInfo={selfInfo}
              headers={headers}
              apiHost={apiHost}
            />
          ))}
        </div>
        <span className="c-button" onClick={onChange}>
          {isMore && collapsible && (
            <>
              <span>{locale.fold}</span>
            </>
          )}
          {isMore && !collapsible && (
            <>
              <span>{locale.more}</span>
            </>
          )}

          <ArrowSvg
            className="card-icon"
            style={{
              transform: isMore && collapsible ? 'rotate(180deg)' : 'rotate(0deg)',
              fontSize: '16px'
            }}
          />
        </span>
      </div>
    </CollapseItemWrapper>
  );
};

export default function Collapse(props) {
  const {
    selfInfo,
    data,
    defaultLine = 1,
    headers,
    apihost
  } = props;

  const prefixCls = 'work-relationship-collapse-container';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);

  const [collapsible, setCollapsible] = useState({});

  const changeCollapsible = (value) => {
    setCollapsible((v) => {
      if (v[value.type]) {
        return { [value.type]: !v[value.type] };
      }
      return { [value.type]: true };
    });
  };

  return wrapSSR(
    <div className={classes}>
      {
        data?.length > 0 && (
          <>
            <div className='work-relation-collapse-container-left-selft' style={{ marginTop: data?.length === 1 && data?.[0]?.descCode === '1' ? '0' : '100px' }}>
              <div
                className="self-info-container"
                style={{ background: data?.length === 1 && data?.[0]?.descCode === '1' ? 'linear-gradient(190deg, rgb(232, 220, 255) 0%, rgb(255, 255, 255) 47%), rgb(255, 255, 255)' : 'linear-gradient(183deg, #DFE5FF 0%, #FFFFFF 61%), #FFFFFF' }}
              >
                <Avatar src={selfInfo.imgUrl} className="self-avatar" size={30} />
                <Tooltip title={selfInfo.name}>
                  <span>{selfInfo.name}</span>
                </Tooltip>
              </div>
            </div>

            <div className='work-relation-collapse-container-right-content'>
              {
                data.map((v, i) => (
                  <CollapseItem
                    {...props}
                    selfInfo={selfInfo}
                    allData={data}
                    key={i}
                    index={i}
                    data={v}
                    changeCollapsible={changeCollapsible}
                    cardHoverBorder={getBackGround(v.descCode)?.border}
                    cardBackGround={getBackGround(v.descCode)?.bg}
                    collapsible={collapsible[v.type]}
                    defaultLine={defaultLine}
                    headers={headers}
                    apihost={apihost}
                  />
                ))
              }
            </div>
          </>
        )
      }

    </div>
  );
}

const CollapseItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .c-item {
    display: flex;
    flex: 1;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #A1ABC9;
      position: absolute;
      top: 0px;
      left: -84px;
    }
  }

  .y-data {
    width: 68px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: 16px;

    // 横线
    &::before{
      content: '';
      width: 16px;
      height: 1px;
      background: #A1ABC9;
      position: absolute;
      top: 50%;
      left: -16px;
    }

    > span {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.60);
      line-height: 18px;
      b {
        font-family: PingFangSC;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.72);
        line-height: 20px;
        margin-right: 2px;
      }
    }
    > div {
      font-family: PingFang SC;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .c-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    max-height: ${(props) => (props.collapsible
    ? `${props.height * props.defaultLine ? (props.height + 12) * 4 : '320'}`
    : `${props.height ? (props.height + 12) * props.defaultLine : '100'
    }`)}px;
    transition: all 0.3s;
    // background: ${(props) => (props.isMore && props.collapsible ? '#FAFBFD' : 'none')};
    overflow: ${(props) => ((props.isMore && !props.collapsible) || !props.isMore ? 'hidden' : 'auto')};
  }
  .c-button {
    width: 28px;
    height: 88px;
    background: #F3F6FC;
    border-radius: 6px;
    visibility: ${(props) => (props.isMore ? 'visible' : 'hidden')};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      .card-icon {
        color: #0A70F5 !important;
      }

      span {
        color: #0A70F5;
      }
    }

    .card-icon {
      color: #b0b0b0 !important;
    }
    span {
      height: 20px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #888888;
      transform: scale(0.86);
    }
  }
`;
