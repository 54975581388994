import React, { useState, useEffect } from 'react';
import { getLocale, ConfigProvider } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import { RightThickOutlined } from '@didi/iconflow/ec';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import useStyle from './style';

const localLocales = manifest.locales || {};

const localHeader = {
  'Accept-Language': 'zh-CN',
  'Authorization': '',
  'bricks-app-key': '653741',
  'dhrToken': 'test'
}

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, auth }, usePermissionResponse
  } = props;
  const [days, setDays] = useState(0);
  const [salaryDayMsg, setSalaryDayMsg] = useState('');
  const [serviceErr, setServiceErr] = useState(false);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  // 暂不使用多语言
  // const intl = Intl(locale);
  const intl = text => text;

  const prefixCls = 'pagelet-pay-info';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);
  const classes = classNames(prefixCls);

  const handleRequest = async () => {
    try {
      const reqHeaders = {...localHeader, ...headers, 'bricks-function-id': auth.id}
      const res = await PageletServices.getSalaryInfo(apiHost, {}, reqHeaders, usePermissionResponse);
      if (res?.code === '100200') {
        setDays(res.data.days);
        setSalaryDayMsg(res.data.salaryDayMsg);
      } else if (res?.code === '100501') {
        setServiceErr(true);
      }
    } catch (e) {
      console.log(e);
      setServiceErr(true);
    }
  };

  const handleOpenSalaryPage = () => {
    window.open('https://cb.didichuxing.com/payroll', '_blank');
  }

  useEffect(() => {
    handleRequest();
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <DHRCardTitle
          title="我Di薪酬"
          type="grey"
          titleAdded={
            <DHRCardButton onClick={handleOpenSalaryPage}>
              查工资 <RightThickOutlined cursor="pointer" size={14} className="right-icon"/>
            </DHRCardButton>
          }
        />
        {
          serviceErr ? <div className={`${prefixCls}-content-err`}>
            <img
              src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
            />
            <span className='text-item-1'>服务不可用，请稍候再试。</span>
          </div> : <div className="pay-day-block" onClick={handleOpenSalaryPage}>
            {
              salaryDayMsg.includes('距离发薪日') ? <>{intl('距离发薪日还有')}<span className="day-num">{days}</span>{intl('天')}</> : intl(salaryDayMsg)
            }
          </div>
        }
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
