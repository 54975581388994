import { useRegisterStyle } from '@didi/ec-base';

const useModalStyle = (prefixCls) => {
  const antModalCls = `.${prefixCls}`;

	return useRegisterStyle('resume-modal-container', () => [
		{
      ['.resume-modal-container']: {

        ['.interview-container']: {

          ['.interview-result']: {
            display: 'flex',
            marginTop: '16px',

            ['.interview-single']: {
              marginRight: '24px',
              fontSize: '14px',
              fontWeight: 'normal',
              lineHeight: 'normal',
              color: 'rgba(0, 0, 0, 0.6)',
            },

            ['span']: {
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.84)',
              marginLeft: '4px'
            }
          }
        },

        ['.interview-detail']: {
          padding: '10px 24px 14px',
          borderRadius: '10px',
          background: 'rgba(248, 249, 252, 0.8)',
          marginTop: '18px',

          ['.interview-detail-title']: {
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0.5px',
            color: 'rgba(0, 0, 0, 0.84)',
          },

          ['.interview-detail-content']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.84)',
            marginTop: '6px',
          },
        },

        ['.ant5-modal-footer']: {
          ['.ant5-btn-primary']: {
            background: '#0A70F5 !important',
          }
        }
      },
		}
	]);
};

export default useModalStyle;

