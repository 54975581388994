import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-team-overview', () => [
		{
			// base
			[`.${prefixCls}`]: {
        width: '100%',
        height: '100%',
        // minHeight: '220px',
        minWidth: '373px',
        padding: "16px",
        background: '#fff',
        borderRadius: '12px',

        '.title': {
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          'h3': {
            margin: '0px',
            fontSize: '20px',
            fontWeight: '600',
            height: '28px',
            lineHeight: '28px',
            color: 'rgba(0, 0, 0, 0.84)',
            padding: '1px 6px',
            borderRadius: '6px',
          },
          '.title-org': {
            display: 'flex',
            alignItems: 'center',
            marginRight: '2px',
            padding: '5px 6px',
            cursor: 'pointer',
            borderRadius: '8px',
            'p': {
              margin: '0px',
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'right',
              color: 'rgba(0, 0, 0, 0.72)'
            },
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.04)'
            }
          }
        },
        '.overview-abnormal': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '10px',
          fontSize: '12px',
          color: '#2D3040',
          ['img']: {
            width: '120px',
          },
        },
        // 中文布局
        '.content': {
          // 宽度
          '.present': {
            '.present-employee': {
              width: '65%',
              overflow: 'hidden'
            },
            '.present-probation': {
              width: '35%',
              overflow: 'hidden'
            }
          },
          '.ongoing': {
            '.ongoing-entry': {
              width: '65%',
              overflow: 'hidden'
            },
            '.ongoing-depart': {
              width: '35%',
              overflow: 'hidden'
            }
          },

          // 基础样式
          '.present, .ongoing': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '2%',
            '.present-employee, .present-probation, .ongoing-entry, .ongoing-depart': {
              minHeight: '66px',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              background: 'rgba(245, 245, 245, 0.6)',
              '.space': {
                padding: '4px',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                borderRadius: '4px',
                cursor: 'pointer',
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.03)',
                },
                '&:last-child': {
                  marginRight: '0px'
                },
                '.block': {
                  width: '100%',
                  'p': {
                    margin: '0px',
                    whiteSpace: 'nowrap'
                  },
                  '.block-number': {
                    marginBottom: '6px',
                    fontFamily: 'DIDI FD',
                    fontSize: '23px',
                    fontWeight: '900',
                    lineHeight: '20px',
                    color: 'rgba(0, 0, 0, 0.82)',
                    'span': {
                      fontFamily: 'DIDI FD',
                      fontSize: '14px'
                    }
                  },
                  '.block-name': {
                    width: '100%',
                    fontSize: '10px',
                    lineHeight: '16px',
                    color: 'rgba(0, 0, 0, 0.72)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  },
                  '.outsourcing': {
                    color: 'rgba(0, 0, 0, 0.82)'
                  }
                },
                '.block-expand': {
                  marginLeft: '6px',
                  marginBottom: '2px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  'p': {
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    marginTop: '2px',
                    marginBottom: '0px',
                    fontSize: '10px',
                    lineHeight: '10px',
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.72)',

                    'span': {
                      display: 'inline-block',
                      maxWidth: '40px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  }
                }
              }
            },

            // 覆盖样式
            '.present-employee, .ongoing-entry': {
              display: 'flex',
              justifyContent: 'flex-start',
              marginRight: '2%',
              overflow: 'hidden'
            },
            '.present-employee': {
              '.space': {
                flex: '1'
              }
            },
            '.present-probation': {
              '.space': {
                justifyContent: 'space-between',
                width: '100%',
                padding: '0px',
                marginRight: '0px',
                '&:hover': {
                  background: 'none',
                },
                '.block': {
                  // maxWidth: '50px',
                  width: '70%',
                  padding: '4px',
                  '&:hover':{
                    background: 'rgba(0, 0, 0, 0.03)',
                    borderRadius:'6px',
                  }
                },
                '.block-expand': {
                  // marginLeft: '10px',
                  'p': {
                    color: '#F5483B',
                    padding: '2px 6px',
                    'span': {
                      color: 'rgba(0, 0, 0, 0.72)'
                    },
                    '&:hover':{
                      background: 'rgba(0, 0, 0, 0.03)',
                      borderRadius:'6px',
                    }
                  }
                }
              }
            },
            '.ongoing-entry': {
              background: 'rgba(17, 159, 154, 0.05)',
              paddingBottom: '4px',
              '.space': {
                flex: '1',
                // marginRight: '2%',
                '&:hover': {
                    background: 'none',
                  },
                '.block': {
                  padding: '2%',
                  '.block-number': {
                    height: '20px',
                    color: '#009659'
                  },
                  '&:hover': {
                    background: 'rgba(0, 150, 89, 0.04)',
                    borderRadius:'6px',
                  },
                },
                '.block-expand':{
                  'p': {
                    padding: '2px 6px',
                    '&:hover':{
                      background: 'rgba(0, 150, 89, 0.04)',
                      borderRadius:'6px',
                    }
                  }
                }
              },
              // width
              '.ontheway': {
                '.block': {
                  // 去掉校招
                  // width: 'auto'
                }
              },
              '.transfer-in': {
                marginRight: '0px',
              },
              '.onboarding': {
                overflow: 'hidden'
              },
            },
            '.ongoing-depart': {
              background: 'rgba(10, 112, 245, 0.05)',
              justifyContent: 'space-between',
              paddingBottom: '4px',
              '.space': {
                padding: '4px',
                // flex: '1',
                '&:hover': {
                  background: 'rgba(10, 112, 245, 0.06)',
                },
                '.block': {
                  '.block-number': {
                    color: '#0A70F5',
                  }
                }
              },
              '.off-boarding': {
                '.block': {
                  '.block-number': {
                    color: 'rgba(0, 0, 0, 0.84)'
                  }
                }
              },
              '&:last-child': {
                marginRight: '0px'
              }
            }
          }
        },

        // 英文适配
        '.content-english': {
          '.present': {
            '.present-employee': {
              '.space': {
                overflow: 'hidden'
              }
            },
          },
          '.ongoing': {
            '.ongoing-entry': {
              '.space': {
                '.block-expand': {
                  'p': {
                    'span': {
                      minWidth: '11px',
                      maxWidth: '35px'
                    }
                  }
                }
              },
              '.transfer-in': {
                overflow: 'hidden'
              },
              '.onboarding': {
                maxWidth: '50px'
              },
            },
            '.ongoing-depart': {
              '.space': {
                maxWidth: '40px'
              }
            }
          }
        }
			},

      // tooltipo
      ['.ant5-tooltip.ec-base-tooltip']: {
        '.ant5-tooltip-content': {
          '.ant5-tooltip-inner': {
            '.tooltip-title': {
              'h4': {
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: '0px'
              },
              '.span': {
                display: 'inline-block',
                marginTop: '4px'
              }
            }
          }
        }
      }
		}
	]);
};

export default useStyle;
