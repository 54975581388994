import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider, Tabs, Card, Tooltip, Tag, Spin, Divider, Select, Empty, zh_CN, en_US, getLang } from '@didi/ec-base';
import manifest from '../manifest.json';
import { getFeedback, getOperateLog, getFeedbackYearList } from './services';
import classNames from 'classnames';
import useStyle from './style';
import AnswerPieChart from './AnswerPieChart';
import AnswerContent from './AnswerContent';
import RateRecoveryPieChart from './RateRecoveryPieChart';

const localLocales = manifest.locales || {};

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost, locales, employeeId, headers, year, usePermissionResponse, authList, colorPrimary = '#0A70F5' },
  } = props;

  const feedbackYearAuth = authList?.find(item => item.elementTag === 'archive-feedback-year');

  const [period, setPeriod] = useState('');
  const [surveyOveralList, setSurveyOveralList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [inventoryYearList, setInventoryYearList] = useState([]);

  const prefixCls = 'pagelet-multi-dimensional-feedback';

  const wrapSSR = useStyle(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  const getData = async () => {
    try {
      setLoading(true);
      getFeedbackYearList(apiHost, headers, { employeeId }, usePermissionResponse).then(res => {
        if (res?.code === '100200' && res?.data?.length > 0) {
          setPeriod(res?.data[0]);
          getFeedbackData({ employeeId, year: res?.data[0] });
          setInventoryYearList(res?.data?.map(item => ({ label: item , value: item, className: prefixCls + '-select-item' })));
        } else {
          setLoading(false);
        }
      })
    } catch (error) {
      setLoading(false);
    }
  };

  const getFeedbackData = async (queryParams) => {
    try {
      setLoading(true);
      const res = await getFeedback(apiHost, headers, queryParams, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res?.data)?.length > 0) {
        const list = res?.data?.surveyOveralList.map(item => ({ ...item, questionList: item?.questionList.filter(v => v?.answerRateList?.length > 0 || v?.answerList?.length > 0) }));
        setSurveyOveralList(list);
      } else {
        setSurveyOveralList([]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onTabChange = (value) => {
    setActiveIndex(value);
    const { roleDesc } = surveyOveralList[value];
    getOperateLog(apiHost, headers, {
      "operateName": "点击",
      "operateContent": "多维反馈",
      "operateParams": {
        "切换的角色名称": roleDesc,
        "被浏览人工号": employeeId,
      }
    }, usePermissionResponse);
  }

  const onChange = (value) => {
    setPeriod(value);
    getFeedbackData({ employeeId, year: value });
  };

  useEffect(() => {
    // 有多维周期筛选权限 且年份无值
    if (employeeId && !year && feedbackYearAuth) {
      getData();
    } else if (employeeId && year && !feedbackYearAuth) {
      // 无多维周期筛选权限 且年份有值
      getFeedbackData({ employeeId, year });
    } else {
      // 无多维周期筛选权限 且年份无值
      setLoading(false);
    }
  }, [employeeId, year]);

  return wrapSSR(
    <ConfigProvider prefixCls='ant5' theme={{ token: { colorPrimary } }} locale={getLang() === 'en-US' ? en_US : zh_CN}>
      <Spin spinning={loading}>
        <div className={classes}>
          {
            (feedbackYearAuth && inventoryYearList?.length > 0) && <div className='cycle-select'>
            <span>{locale?.Period}:</span>
            <Select
              style={{ width: '104px' }}
              options={inventoryYearList}
              value={period}
              onChange={onChange}
            />
          </div>
          }
          {
          surveyOveralList?.length > 0 && <Tabs
            className='multi-dimensional-feedback-tabs'
            size='middle'
            barType='auto'
            onChange={onTabChange}
            items={
              surveyOveralList?.map((item,i) => {
                return {
                  label: item.roleDesc,
                  key: i,
                  children: <div className='multi-dimensional-feedback-tabs-panel'>
                    {
                      item?.questionList?.map((child,index) => {
                        return <Card
                            key={item?.subjectVersionId}
                            className='multi-dimensional-feedback-card'
                            title={<div className='multi-dimensional-feedback-card-head-question-title'>
                              <span>{String(index+1).padStart(2,0)}.</span>
                              <span>{child?.subjectName}</span>
                            </div>}
                          >
                            {
                              child?.questionType === 0 && <AnswerPieChart data={child?.answerRateList} />
                            }
                            {
                              child?.questionType === 1 && <AnswerContent data={child?.answerList} />
                            }
                        </Card>
                      })
                    }
                  </div>
                }
              })
            }
            tabBarExtraContent={
              <div className='multi-dimensional-feedback-tabs-extra'>
                {
                  surveyOveralList[activeIndex]?.hasNegative && <>
                    <Tag style={{ color: '#4183D9', borderColor: '#aac7eb',fontWeight: 500 }}>{locale?.NegativeEvaluation}</Tag>
                    <Divider type='vertical' style={{ height: '16px' }} />
                  </>
                }
                <Tooltip title={<div className='multi-dimensional-feedback-tabs-extra-tooltip'>
                  <div><span>{locale?.ResponseRate1}:</span><span>{surveyOveralList[activeIndex]?.answerRate}%</span></div>
                  <div><span>{locale?.NumberOfResponse}:</span><span>{surveyOveralList[activeIndex]?.answerCnt}</span></div>
                  <div><span>{locale?.NumberOfRefusal}:</span><span>{surveyOveralList[activeIndex]?.rejectionCnt}</span></div>
                  <div><span>{locale?.NumberOfDistribution}:</span><span>{surveyOveralList[activeIndex]?.sendCnt}</span></div>
                </div>}>
                  <span className='multi-dimensional-feedback-tabs-extra-content'>
                    <span>{locale?.ResponseRate}:</span>
                    <RateRecoveryPieChart answerRate={surveyOveralList[activeIndex]?.answerRate} />
                    <span className='recovery-rate-1'>{surveyOveralList[activeIndex]?.answerRate}%</span>
                    <span className='recovery-rate-2'>{surveyOveralList[activeIndex]?.answerCnt}/{surveyOveralList[activeIndex]?.rejectionCnt}/{surveyOveralList[activeIndex]?.sendCnt}</span>
                  </span>
                </Tooltip>
              </div>
            }
            />
          }
          {
            !loading && surveyOveralList?.length === 0 && <Empty />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
