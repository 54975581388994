import React, { useEffect, useState } from 'react';
import { getLocale } from '@didi/ec-base';
import classNames from 'classnames';
import useStyle from './style';
import { TimelineCard } from '@didi/ehr-timeline-card';
import { getGroupTalent } from './services';
import manifest from '../../manifest.json';
import Filled2 from './assets/Filled2.svg';
import Filled4 from './assets/Filled4.svg';
import Filled7 from './assets/Filled7.svg';
import Filled200 from './assets/Filled200.svg';

const localLocales = manifest.locales || {};

const { render: { LineItem } } = TimelineCard;

const talentTypeIcon = {
  icon2: <Filled2 />, // 重点培养
  icon4: <Filled4 />, // 重点激励
  icon7: <Filled7 />, // 高潜
  icon200: <Filled200 />, // 200核心骨干
  icon201: <Filled200 />, // 201精英人才
};

const Pagelet = (props) => {
  const { params: { apiHost, locales, headers, employeeId } } = props;

  const [data, setData] = useState([]);

  const prefixCls = 'pagelet-talent-inventory';
  const wrapSSR = useStyle(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  const getData = async () => {
    try {
      const res = await getGroupTalent(apiHost, headers, { employeeId });
      setData(res?.data);
    } catch (error) {
      console.log('getGroupTalent', error);
    }
  };

  //接口请求
  useEffect(() => {
    if (employeeId) {
      getData();
    }
  }, [employeeId]);

  return wrapSSR(
    <div className={classes}>
      {
        data?.length > 0 && <TimelineCard>
          {data?.map(item => (
            <LineItem startTime={`${item?.year}${locale?.year}`}>
              <div className='talent-inventory-container'>
                <div className='inventory-name'>
                  {
                    talentTypeIcon['icon' + item?.talentLabelCode] && <span className='talent-icon'>{talentTypeIcon['icon'+item?.talentLabelCode]}</span>
                  }
                  {item?.talentLabelName}
                </div>
              </div>
            </LineItem>
          ))}
        </TimelineCard>
      }
    </div>
  );
};

export default Pagelet;
