import React, { useEffect, useState } from 'react';
import { ConfigProvider, Spin } from '@didi/ec-base';
import PageletServices from './services';
import classNames from 'classnames';
import useStyle from './style';
import RegularCard from './components/RegularCard'; // 目标卡片
import { utils, newRequest } from '@didi/pagelets-sdk'; // 国际化翻译文件

const Pagelet = props => {
  const {
    params: { auth, headers = {}, apiHost },
  } = props;
  const [loading, setLoading] = useState(true); // 初始化loading状态
  const [deptList, setDeptList] = useState([]); // 岗位列表
  const [activedDeptInfo, setActivedDeptInfo] = useState(null); // 选中的岗位信息

  const { functionId, functionName } =
    utils.getFunctionData(auth, '@didi/pagelet-objective-overview') || {}; // 获取组件的id
  // 配置请求头信息-临时用ep-portal-ddo的请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  // 初始化请求头
  newRequest.init(newHeaders);

  // 初始化
  const handleInitRequest = async () => {
    const unitUserRes = await PageletServices.getUnitUser('', {}); // 接口部门列表
    await handleTag(unitUserRes.data);
    if (unitUserRes?.data?.length) {
      setDeptList(unitUserRes.data || []); // 获取部门列表
      setActivedDeptInfo(unitUserRes.data[0]); // 设置选中的岗位信息
      setLoading(false); // 设置loading状态为false
    }
  };

  // 部门列表打标签
  const handleTag = async optionsChild => {
    optionsChild?.forEach(item => {
      item.manager = item.positionTags.includes('MANAGER');
      item.roleNameOwner = item.roleName == 'R0000001';
      item.function = item.deptTags.includes('BUS_UNIT_TYPE_FUNCTIONAL');
      item.group = !item.deptTags.includes('GROUP');
      if (item.childrenNode && item.childrenNode.length > 0) {
        handleTag(item.childrenNode);
      }
    });
  };

  // 接口请求示例
  useEffect(() => {
    // 初始化
    handleInitRequest();
  }, []);

  const prefixCls = 'pagelet-objective-overview';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);
  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        {loading ? (
          <div className="spin">
            <Spin />
          </div>
        ) : (
          // 目标卡片
          <RegularCard
            deptList={deptList}
            activedDeptInfo={activedDeptInfo}
            setActivedDeptInfo={setActivedDeptInfo}
            apiHost={apiHost}
          />
        )}
      </div>
    </ConfigProvider>,
  );
};

export default Pagelet;
