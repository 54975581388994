import React from 'react';
import { useField } from '@formily/react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { message } from 'antd';
import { LangTranslate } from '@/utils';

function Wrap({
  list,
  setList,
  itemRender,
  doReorder,
  idPrefix = 'custom-drag',
  droppableId = 'drop-list-warp',
}) {
  const field = useField();
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (
      !!field?.value?.[result?.destination?.index]?.otherOption ||
      !!field?.value?.[result?.source?.index]?.otherOption
    ) {
      message.warning(LangTranslate('「其他」选项位置不可变！'));
      return;
    }
    if (result.destination.index !== result.source.index) {
      doReorder(list, result.source.index, result.destination.index);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={idPrefix || droppableId}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {list?.map?.((item, index) => (
              <Draggable draggableId={idPrefix + index} index={index} key={index}>
                {(provided, snapshot) => itemRender(provided, snapshot, item, index)}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Wrap;
