import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  initPersonalEi: async (host) => {
    const request = PageletApi.newRequest.getInstance('personal-ei');
    const result = await request(`${host}/${apis.getEmpinfo}`, {
      method: 'get',
    });
    return result;
  },
  saveStaffInfo: async (host, data) => {
    const request = PageletApi.newRequest.getInstance('personal-ei');
    const result = await request(`${host}/${apis.staffSave}`, {
      method: 'post',
      data
    });

    return result;
  },
}

export default PageletServices;
