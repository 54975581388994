import HTTPRequest from './request';

const newRequest = (function () {
  let instance;

  return {
    init: function (headers) {
      instance = HTTPRequest(headers);
      return instance;
    },
    getInstance: function () {
      if (!instance) {
        throw new Error("Instance not initialized. Call init() first.");
      }
      return instance;
    }
  };
})();

export default newRequest;
