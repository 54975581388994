import React from 'react';
import { Input, Tooltip } from 'antd';
import { connect, mapReadPretty } from '@formily/react';

const ToolTipInput = connect(
  Input,
  mapReadPretty(props => (
    <Tooltip placement="topLeft" title={props?.value}>
      <Input {...props} />
    </Tooltip>
  )),
);

export default ToolTipInput;
