
import { configureStore } from "@reduxjs/toolkit";
import questionContentSlice from './questionnaireNew/questionContentSlice';
import portraits from './navigationStore/portraits';
export default configureStore({
    reducer: {
        questionContent: questionContentSlice,
        portraits
    },
});

