import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-probation', () => [
	{
			// base
      [`.${prefixCls}`]: {
        '.probation-ellipsis': {
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '.probation-modal-toolbar': {
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
          userSelect: 'none',

          '.probation-modal-radio-group': {
            display: 'flex',
            alignItems: 'center',
            '.ant5-segmented': {
              marginRight: '12px'
            },
            '.between-line': {
              width: '1px',
              height: '20px',
              background: 'rgba(0, 0, 0, 0.12)',
            }
          },

          '.button-group': {
            display: 'flex',
            alignItems: 'center',
           '.export-button-view-search': {
              fontSize: '14px',
              fontWeight: 'normal',
              lineHeight: '22px',
              letterSpacing: '0em',
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              marginRight: '16px',
              '.up-thick-outlined-icons, .down-thick-outlined-icons': {
                lineHeight: '14px',
                marginLeft: '4px',
              }
            },
          }
        },
        '.advanced-search-body-show': {
          // marginBottom: '16px',
          // height: 'auto',
          // '.ant-form.form-filter': {
          //   background: 'none',
          //   padding: 0,
          //   boxShadow: 'none',
          // }
          display: 'block',
          marginBottom: '12px'
        },
        '.advanced-search-body-hide': {
          // height: 0,
          // overflow: 'hidden',
          display: 'none'
        },
        '.tableBox': {
          ['.ant5-table-expanded-row-fixed .ec-ehr-base-table-empty,.ec-ehr-base-table-empty']: {
            marginBlock: '96px',
            position: 'sticky',
            left: '0',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ['img']: {
              display: 'inline-block',
              width: '180px'
            },
            ['.ec-ehr-base-table-empty-text']: {
              fontSize: '12px',
              color: '#2D3040',
              letterSpacing: '0',
              lineHeight: '14px',
            }
          }
        },
      }
		}
	])
}

export default useStyle;

