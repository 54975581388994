import { ConfigProvider, en_US, getLang, getLocale, zh_CN } from '@didi/ec-base';
import { Intl, utils } from '@didi/pagelets-sdk';
import React, { useEffect, useState } from 'react';
import manifest from '../manifest.json';
import { ExportButton } from './components';
import Table from './components/ResizableTable/Table';
import { InColumns, OutColumns } from './helper/options';
import newRequest from './utils/newRequest';
const localLocales = manifest.locales || {};

import useStyle from './style';


// 转入转出
const Pagelet = (props) => {
  const {
    params: {
      auth,
      headers = {},
      apiHost,
      locales,
      transferKey, // 调动转入 转出标识  转入:into 转出:out
      deptCodes = [],
    }
  } = props;

  const authId = utils.getFunctionId(auth, transferKey === 'into' ? 'page_transfer_in_employees' : 'page_transfer_out_employees');
  const newHeaders = { ...headers, 'bricks-function-id': authId };
  const request = newRequest.init(newHeaders);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');

  const columns = transferKey === 'into' ? InColumns(intl) : OutColumns(intl);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalNum, setTotalNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);

  // 转入查列表信息函数
  const getTransferList = async ({ pageNum, pageSize }) => {
    setTableLoading(true);
    const res = await request({
      method: 'post',
      url:
        transferKey === 'into'
          ? `${apiHost}/gateway/bricks/api/unit/statistics/page/transfer/in/employees`
          : `${apiHost}/gateway/bricks/api/unit/statistics/page/transfer/out/employees`,
      data: {
        deptCodes,
        pageNum,
        pageSize,
      },
      headers: {
        functionId: authId
      }
    }).finally(() => {
      setTableLoading(false);
    });
    if (res.code === '100200') {
      setTableData(res?.data?.list || []);
      setTotalNum(res?.data.total || 0);
    }
  };

  const pageInfo = {
    total: totalNum,
    current: pageNum,
    pageSize,
    showQuickJumper: true,
    showSizeChanger: true,
    // hideOnSinglePage: true,
    pageSizeOptions: [10, 20, 50, 100],
    onChange: (num, size) => {
      if (num !== pageNum) {
        setPageNum(num);
      }
      if (size !== pageSize) {
        setPageSize(size);
      }
      getTransferList({ pageNum: num, pageSize: size }); // 修改页码后刷新列表
    },
  };

  useEffect(() => {
    getTransferList({ pageNum: 1, pageSize: 10 });
  }, []);

  const prefixCls = 'pagelet-transfer';
  const wrapSSH = useStyle(prefixCls);

  return wrapSSH(
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN}>
      <div className={prefixCls}>
        <div className="transferInto-modal-toolbar">
          <ExportButton
            data={{
              deptCodes,
              pageNum,
              pageSize,
            }}
            url={
              transferKey === 'into'
                ? `${apiHost}/gateway/bricks/api/unit/statistics/export/transfer/in/employees`
                : `${apiHost}/gateway/bricks/api/unit/statistics/export/transfer/out/employees`
            }
            defaultFile={intl('EXPORT_EXPECTED_TRANSFER {transferKey} DETAILS', { transferKey: transferKey === 'into' ? intl('IN') : intl('OUT') })}
            authId={authId}
            intl={intl}
            headers={newHeaders}
          />
        </div>
        <Table
          locale={langEn ? en_US : zh_CN}
          dataSource={tableData}
          columns={columns}
          scroll={{
            x: 1800,
            y: 'calc(100vh - 212px)',
          }}
          rowKey={(record) => record.transferEmpId}
          loading={tableLoading}
          pagination={pageInfo}
          intl={intl}
        />
      </div>
    </ConfigProvider>
  )
}

export default Pagelet;
