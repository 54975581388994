/* eslint-disable */
import { Tooltip } from '@didi/ec-base';
import moment from 'moment';
import React from 'react';
import { getStr } from '../utils/common';

const showEllipseWord = (str, splitWord = '>', isShowHover = true) => {
  const arr = str.split(splitWord);
  const len = arr.length;
  if (len < 4) return str;
  if (isShowHover) {
    return (
      <Tooltip title={str}>
        {`${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`}
      </Tooltip>
    );
  }
  return `${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`;
};
const probation = (intl) => [
    {
        title: intl('EMPLOYEE'),
        dataIndex: 'empName',
        key: 'empName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 80,
        fixed: 'left',
    },
    {
        title: intl('JOB_NUMBER'),
        dataIndex: 'empId',
        key: 'empId',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 84,
        fixed: 'left',
    },
    {
        title: intl('ENTRY_DATE'),
        dataIndex: 'emplid',
        key: 'emplid',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 108,
        fixed: 'left',
    },
    {
        title: intl('CONFIRMATION_DATA'),
        dataIndex: 'beFullDate',
        key: 'beFullDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 106,
        fixed: 'left',
    },
    {
        title: intl('DEPARTMENT_PATH'),
        dataIndex: 'deptPath',
        key: 'deptPath',
        ellipsis: true,
        render: (text, record) => (
          showEllipseWord(text)
        ),
        width: 192,
        fixed: 'left',
    },
    {
        title: intl('SUPERIOR_NAME'),
        dataIndex: 'superiorName',
        key: 'superiorName',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 88,
    },
    {
        title: intl('SUPERIOR_JOB_NUMBER'),
        dataIndex: 'superiorId',
        key: 'superiorId',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 88,
    },

    {
        title: intl('HRBP_NAME'),
        dataIndex: 'hrbpName',
        key: 'hrbpName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 97,
    },
    {
        title: intl('HRBP_JOB_NUMBER'),
        dataIndex: 'hrbpId',
        key: 'hrbpId',
        ellipsis: true,
        // sorter: true,
        // sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 97,
    },
    {
        // TODO: targetStatusDescr targetApproveOvertime
        title: intl('TRIAL_PERIOD_TARGET_PROGRESS'),
        dataIndex: 'targetStatusCode',
        key: 'targetStatusCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        // TODO: middleFeedbackProgressDescr middleFeedbackOvertime
        title: intl('MID_FEEDBACK_PROCESS'),
        dataIndex: 'middleFeedbackProgressCode',
        key: 'middleFeedbackProgressCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        // TODO: middleFeedbackStatusDescr
        title: intl('MID_FEEDBACK_CONCLUSION'),
        dataIndex: 'middleFeedbackStatusCode',
        key: 'middleFeedbackStatusCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        // TODO: middleSecondFeedbackProgressDescr middleSecondFeedbackOvertime
        title: intl('MID_SECOND_FEEDBACK_PROCESS'),
        dataIndex: 'middleSecondFeedbackProgressCode',
        key: 'middleSecondFeedbackProgressCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 136,
    },
    {
        // TODO: middleSecondFeedbackStatusDescr
        title: intl('MID_SECOND_FEEDBACK_CONCLUSION'),
        dataIndex: 'middleSecondFeedbackStatusCode',
        key: 'middleSecondFeedbackStatusCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 136,
    },
    {
        // TODO: befullApproveProgressDescr befullApproveOvertime
        title: intl('PROGRESS_OF_CONVERSION_EVALUATION'),
        dataIndex: 'befullApproveProgressCode',
        key: 'befullApproveProgressCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 124,
    },
    {
        // TODO: befullOpinionDescr
        title: intl('CONCLUSION_OF_SUPERIOR_CONFIRMATION'),
        dataIndex: 'befullOpinionCode',
        key: 'befullOpinionCode',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 112,
    },
    {
        title: intl('CURRENT_STATUS'),
        dataIndex: 'empStatus',
        key: 'empStatus',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 124,
    },
    {
        title: intl('TERMINATION_DATE'),
        dataIndex: 'leaveDate',
        key: 'leaveDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 108,
    },
    {
        title: intl('RESIGNATION_TYPE'),
        dataIndex: 'leaveType',
        key: 'leaveType',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 88,
    },
    {
        title: intl('TRIAL_PERIOD_DETAILS'),
        dataIndex: 'file',
        key: 'file',
        width: 100,
        fixed: 'right',
        ellipsis: true,
        render: (text, record) => {
            return <a href={`http://canghai.didichuxing.com/talent?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text">查看</a>
        },
    }

]

const todayIsExpectedToHireEmployees = (intl) => [
    {
        title: intl('USERNAME'), dataIndex: 'name', key: 'name',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        width: 100,
        fixed: 'left',
    },
    {
        title: intl('CONTACT_INFORMATION'), dataIndex: 'phone', key: 'phone',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('PLANNED_DATA_OF_EMPLOYMENT'), dataIndex: 'entryDate', key: 'entryDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('ONBOARDING_STATUS'), dataIndex: 'handleStatus', key: 'handleStatus',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 130,
    },
    {
        title: intl('LABOR_CONTRACT_WORKPLACE'), dataIndex: 'contractLocationName', key: 'contractLocationName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('EMPLOYMENT_DEPARTMENT'), dataIndex: 'entryDeptPath', key: 'entryDeptPath',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        width: 300,
    },
    {
        title: intl('DEPARTMENT_NUMBER'), dataIndex: 'entryDeptPathCode', key: 'entryDeptPathCode',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 80,
    },
    {
        title: intl('REPORT_TO'), dataIndex: 'deptManagement', key: 'deptManagement',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 160,
    },
    {
        title: intl('JOB_NAME'), dataIndex: 'jobTitleName', key: 'jobTitleName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 160,
    },
    {
        title: intl('RANK'), dataIndex: 'rank', key: 'rank',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('EMPLOYEE_NATURE'), dataIndex: 'offerEntryTypeName', key: 'offerEntryTypeName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 80,
    },
    {
        title: intl('SEX'), dataIndex: 'gender', key: 'gender',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('JOB_NUMBER'), dataIndex: 'empId', key: 'empId',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMAIL'), dataIndex: 'email', key: 'email',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('CONSULTANT'), dataIndex: 'recruiter', key: 'recruiter',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },

]
const tomorrowToJoinEmployees = (intl) => [
    {
        title: intl('USERNAME'), dataIndex: 'name', key: 'name',
        ellipsis: true,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
        fixed: 'left',
    },
    {
        title: intl('CONTACT_INFORMATION'), dataIndex: 'phone', key: 'phone',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('PLANNED_DATA_OF_EMPLOYMENT'), dataIndex: 'entryDate', key: 'entryDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        width: 120,
    },
    {
        title: intl('RECRUITMENT_TYPE'), dataIndex: 'recuitType', key: 'recuitType',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: 'offer状态', dataIndex: 'offerStatus', key: 'offerStatus',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('OFFER_ISSUANCE_TIME'), dataIndex: 'offerSendDate', key: 'offerSendDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 110,
    },
    {
        title: intl('OFFER_ACCEPTANCE_TIME'), dataIndex: 'offerReceiveDate', key: 'offerReceiveDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 110,
    },
    {
        title: intl('LABOR_CONTRACT_WORKPLACE'), dataIndex: 'contractLocationName', key: 'contractLocationName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('EMPLOYMENT_DEPARTMENT'), dataIndex: 'entryDeptPath', key: 'entryDeptPath',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        width: 300,
    },
    {
        title: intl('DEPARTMENT_NUMBER'), dataIndex: 'entryDeptPathCode', key: 'entryDeptPathCode',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 80,
    },
    {
        title: intl('REPORT_TO'), dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 140,
    },
    {
        title: intl('JOB_NAME'), dataIndex: 'jobTitleName', key: 'jobTitleName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('RANK'), dataIndex: 'rank', key: 'rank',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('EMPLOYEE_NATURE'), dataIndex: 'offerEntryTypeName', key: 'offerEntryTypeName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 80,
    },
    {
        title: intl('SEX'), dataIndex: 'gender', key: 'gender',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('JOB_NUMBER'), dataIndex: 'empId', key: 'empId',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMAIL'), dataIndex: 'email', key: 'email',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={getStr(text)}>
                {getStr(text)}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('CONSULTANT'), dataIndex: 'recruiter', key: 'recruiter',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },
]
const turnPositiveList = (intl) => [
    {
        title: intl('USERNAME'), dataIndex: 'name', key: 'name',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
        fixed: 'left',
    },
    {
        title: intl('JOB_NUMBER'), dataIndex: 'emplid', key: 'emplid',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMAIL'), dataIndex: 'emailAddr', key: 'emailAddr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('SEX'), dataIndex: 'sexName', key: 'sexName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('ENTRY_DATE'), dataIndex: 'deptEntryDt', key: 'deptEntryDt',
        ellipsis: true,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 110,
    },
    {
        title: intl('CONFIRMATION_DATA'), dataIndex: 'estimatePositiveDate', key: 'estimatePositiveDate',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 110,
    },
    {
        title: intl('LABOR_CONTRACT_WORKPLACE'), dataIndex: 'locationDescr', key: 'locationDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('DEPARTMENT_PATH'), dataIndex: 'deptPath', key: 'deptPath',
        ellipsis: true,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <span>
                { showEllipseWord(text)}
            </span>
        ),
        width: 300,
    },
    {
        title: intl('DEPARTMENT_NUMBER'), dataIndex: 'deptCodePath', key: 'deptCodePath',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 80,
    },
    {
        title: intl('REPORT_TO'), dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 140,
    },
    {
        title: intl('JOB_NAME'), dataIndex: 'jobDesc', key: 'jobDesc',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 180,
    },
    {
        title: intl('RANK'), dataIndex: 'supvLvlIdDescr', key: 'supvLvlIdDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('EMPLOYEE_FILE'),
        dataIndex: 'file',
        key: 'file',
        width: 80,
        fixed: 'right',
        ellipsis: true,
        render: (text, record) => {
            return <a href={`http://canghai.didichuxing.com/talent?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text">查看</a>
        },
    }

]
const anniversaryList = (intl) => [
    {
        title: intl('USERNAME'), dataIndex: 'name', key: 'name',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
        fixed: 'left',
    },
    {
        title: intl('JOB_NUMBER'), dataIndex: 'emplid', key: 'emplid',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMAIL'), dataIndex: 'emailAddr', key: 'emailAddr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('SEX'), dataIndex: 'sexName', key: 'sexName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('ENTRY_DATE'), dataIndex: 'deptEntryDt', key: 'deptEntryDt',
        ellipsis: true,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 110,
    },
    {
        title: intl('MONTH_ANNIVERSARY'), dataIndex: 'anniversaryDt', key: 'anniversaryDt',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
    },
    {
        title: intl('LABOR_CONTRACT_WORKPLACE'), dataIndex: 'locationDescr', key: 'locationDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('DEPARTMENT_PATH'), dataIndex: 'deptPath', key: 'deptPath',
        ellipsis: true,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <span>
                { showEllipseWord(text)}
            </span>
        ),
        width: 300,
    },
    {
        title: intl('DEPARTMENT_NUMBER'), dataIndex: 'deptCodePath', key: 'deptCodePath',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 80,
    },
    {
        title: intl('REPORT_TO'), dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 140,
    },
    {
        title: intl('JOB_NAME'), dataIndex: 'jobDesc', key: 'jobDesc',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 180,
    },
    {
        title: intl('RANK'), dataIndex: 'supvLvlIdDescr', key: 'supvLvlIdDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('EMPLOYEE_FILE'),
        dataIndex: 'file',
        key: 'file',
        width: 80,
        fixed: 'right',
        ellipsis: true,
        render: (text, record) => {
            return <a href={`http://canghai.didichuxing.com/talent?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text">查看</a>
        },
    }

]
const birthdayList = (intl) => [
    {
        title: intl('USERNAME'), dataIndex: 'name', key: 'name',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
        fixed: 'left',
    },
    {
        title: intl('JOB_NUMBER'), dataIndex: 'emplid', key: 'emplid',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMAIL'), dataIndex: 'emailAddr', key: 'emailAddr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('EMPLOYEE_NATURE'), dataIndex: 'emplClassDescr', key: 'emplClassDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 80,
    },
    {
        title: intl('SEX'), dataIndex: 'sexName', key: 'sexName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('BIRTHDAY'), dataIndex: 'birthdate', key: 'birthdate',
        ellipsis: true,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
    },
    {
        title: intl('LABOR_CONTRACT_WORKPLACE'), dataIndex: 'locationDescr', key: 'locationDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('DEPARTMENT_PATH'), dataIndex: 'deptPath', key: 'deptPath',
        ellipsis: true,
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
            <span>
                { showEllipseWord(text)}
            </span>
        ),
        width: 300,
    },
    {
        title: intl('DEPARTMENT_NUMBER'), dataIndex: 'deptCodePath', key: 'deptCodePath',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 80,
    },
    {
        title: intl('REPORT_TO'), dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 140,
    },
    {
        title: intl('JOB_NAME'), dataIndex: 'jobDesc', key: 'jobDesc',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 180,
    },
    {
        title: intl('RANK'), dataIndex: 'supvLvlIdDescr', key: 'supvLvlIdDescr',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('EMPLOYEE_FILE'),
        dataIndex: 'file',
        key: 'file',
        width: 80,
        fixed: 'right',
        ellipsis: true,
        render: (text, record) => {
            return <a href={`http://canghai.didichuxing.com/talent?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text">查看</a>
        },
    }

]

const offBoarding = (intl) => [
    {
        title: intl('USERNAME'), dataIndex: 'empName', key: 'empName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 100,
        fixed: 'left',
    },
    {
        title: intl('JOB_NUMBER'), dataIndex: 'empId', key: 'empId',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMAIL'), dataIndex: 'email', key: 'email',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('RESIGNATION_TYPE'), dataIndex: 'leaveType', key: 'leaveType',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 80,
    },
    {
        title: intl('EXPECTED_RESIGNATION_DATE'),
        dataIndex: 'exceptedLeaveDate',
        width: 150,
        // sorter: true,
        render: (text, record) => <div>{text ? moment(text).format('YYYY-MM-DD') : '-'}</div>,
    },
    {
        title: intl('RESIGNATION_SUBMISSION_DATE'),
        dataIndex: 'submitLeaveDate',
        width: 150,
        // sorter: true,
        render: (text, record) => (
          <div>{text ? moment(text).format('YYYY-MM-DD') : '-'}</div>
        ),
    },
    {
        title: intl('APPROVAL_STATUS'),
        dataIndex: 'approveStatus',
        width: 118,
        // fixed: 'left',
    },
    {
        title: intl('HRBP_PROX_WITHDRAWAL'),
        dataIndex: 'isHrbpSubmission',
        width: 118,
        // fixed: 'left',
    },
    {
        title: intl('DEPARTMENT_PATH'),
        dataIndex: 'departFullName',
        width: 300,
        ellipsis: true,
        render: (path) => (
          <span>{path ?  showEllipseWord(path) : '-'}</span>
        ),
    },
    {
        title: intl('DEPARTMENT_NUMBER'), dataIndex: 'departCode', key: 'departCode',
        ellipsis: true,
        render: (text, record) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        },
        width: 80,
    },
    {
        title: intl('REPORT_TO'), dataIndex: 'superiorLdap', key: 'superiorLdap',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {`${record.superiorName}（${text}）`}
            </Tooltip>
        ),
        width: 170,
    },
    {
        title: intl('SUPERIOR_JOB_NUMBER'), dataIndex: 'superiorId', key: 'superiorId',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 90,
    },
    {
        title: intl('EMPLOYEE_NATURE'), dataIndex: 'empTypeName', key: 'empTypeName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 80,
    },
    {
        title: intl('ENTRY_DATE'),
        dataIndex: 'entryDate',
        width: 150,
        render: (text, record) => <div>{text ? moment(text).format('YYYY-MM-DD') : '-'}</div>,
    },
    {
        title: intl('JOB_NAME'), dataIndex: 'jobTitleName', key: 'jobTitleName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 180,
    },
    {
        title: intl('RANK'), dataIndex: 'rankName', key: 'rankName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 60,
    },
    {
        title: intl('LABOR_CONTRACT_WORKPLACE'), dataIndex: 'locationName', key: 'locationName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 120,
    },
    {
        title: intl('CONTRACT_SUBJECT'), dataIndex: 'contractSubjectName', key: 'contractSubjectName',
        ellipsis: true,
        render: (text, record) => (
            <Tooltip title={text}>
                {text}
            </Tooltip>
        ),
        width: 200,
    },
    {
        title: intl('CONTRACT_END_DATE'),
        dataIndex: 'contractEndDate',
        width: 150,
        render: (text, record) => <div>{text ? moment(text).format('YYYY-MM-DD') : '-'}</div>,
    },
]
export const columns = (intl) => {
  return {
    probation: probation(intl),
    todayIsExpectedToHireEmployees: todayIsExpectedToHireEmployees(intl),
    tomorrowToJoinEmployees: tomorrowToJoinEmployees(intl),
    turnPositiveList: turnPositiveList(intl),
    anniversaryList: anniversaryList(intl),
    birthdayList: birthdayList(intl),
    offBoarding: offBoarding(intl)
  }
}
export const columnsTemp = (intl) => {

  const turnPositiveList = turnPositiveList(intl);
  const anniversaryList = anniversaryList(intl);
  const birthdayList = birthdayList(intl);

  return [
    turnPositiveList,
    anniversaryList,
    birthdayList,
  ]
}
// 获取表格数据
export const url = {
  todayIsExpectedToHireEmployees: '/gateway/bricks/api/unit/statistics/page/entry/expectation/employees',  // 获取今天预计入职员工列表
  tomorrowToJoinEmployees: '/gateway/bricks/api/unit/statistics/page/entry/ontheway/employees',  // 获取明天预计入职列表 (在途)
  offBoarding: '/gateway/bricks/api/unit/statistics/page/leave/expectation/employees'  // 获取预计离职
}

export const urlTemp = [
  '/staff/page/list/turnPositiveList',  // 获取转正员工人数列表
  '/staff/page/list/anniversaryList',  // 获取周年员工人数列表
  '/staff/page/list/birthdayList',  // 获取生日员工人数列表
]

// 获取选项
export const optionUrls = {
  todayIsExpectedOptionUrl: '/gateway/dhr-workbench/dict/procedure/status/type', //获取手续办理状态
  tomorrowToJoinOptionUrl: '/gateway/dhr-workbench/dict/time/interval/type', //获取时间区间类型
  listOption: '/gateway/dhr-workbench/dict/list/select/type' //获取下属查询类型(直属／全部)
}

// 导出表格
export const exportUrls = {
  todayExpectedEntryPeople: '/gateway/bricks/api/unit/statistics/export/entry/expectation/employees', //导出今天预计入职人员名单
  inTransitPeople: '/gateway/bricks/api/unit/statistics/export/entry/ontheway/employees', //导出在途人员名单
  offBoarding: '/gateway/bricks/api/unit/statistics/export/leave/expectation/employees'  // 导出预计离职人员名单
}
