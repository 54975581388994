import React from 'react';
import { Input } from 'antd';
import './index.less';
import { LangTranslate } from '@/utils';
function CustomTagTextAreaCpn() {
  return <div />;
  // return (
  //   <div className="pagelet-label-system-another-item-cell-text-area-wrap">
  //     <Input.TextArea placeholder={LangTranslate('请输入')} />
  //   </div>
  // );
}

export default CustomTagTextAreaCpn;
