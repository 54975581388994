import React, { useState, useEffect, useRef } from 'react';
import { Radio, Input, Form, Tooltip } from 'antd';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { di18n } from '@ultra/common';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.scss';
import {
  setGeneralData,
  setValidateResult,
  updateAttributeData,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject,
  setPrevOptionIds,
  setCurOptionIds
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const { intl } = di18n;
const { TextArea } = Input;

const radioWidget = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    hiddenGroup,
    validateFlag,
    currentRespondentCode,
    surveyBusType
  } = useSelector((state) => state.questionContent);
  const dispatch = useDispatch();
  const disabled = false;
  // 处理失焦和勾选的时差。当为失焦时，延迟200ms 200ms内存在勾选 不执行失焦函数
  let blurTimer = useRef(false);

  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    direction,
    effectOption,
    options,
    hasSerialNumber
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];
  const otherRules = rules.otherRules;
  const effect = effectData[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;

  const [radioValue, setRadioValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [inputValueMap, setInputValueMap] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData && draftData[widgetKey]) {
      const widgetDraftData = draftData[widgetKey];
      //const draftItem = widgetDraftData[respondentsGroup[0].respondentCode];
      const draftItem = widgetDraftData[currentRespondentCode];
      let inputValueMap = {};
      other.map((otherItem, i) => {
        inputValueMap[otherItem] = '';
      })
      if (draftItem.length > 0) {
        const radio = draftItem[0].option;
        const input = draftItem[0].value;
        setRadioValue(radio);
        setInputValue(input);
        if (effectOption.length > 0) {
          effectFun(radio);
        }
        if (other.includes(radio)) {
          inputValueMap[radio] = input;
        }
        // 初始化赋值&校验
        setValueAndValidate(draftItem);
      }
      setInputValueMap(inputValueMap);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (radioValue) {
        setRadioValue(null);
      }
      if (inputValue) {
        setInputValue(null);
      }
    }
  }, [hidden, initFlag, radioValue, inputValue])

  const setValueAndValidate = (paramData) => {
    const { option, value } = paramData[0];
    //dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: respondentsGroup[0].respondentCode, data: paramData }));
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: paramData }));
    dispatch(updateCurrentSubject({ data: widgetKey }));
    // 数据校验
    const widgetValidate = validateFun(rules, paramData);
    let otherValidate;
    if (other.includes(option)) {
      otherValidate = { [option]: validateFun(otherRules[option], value, true) };
    }
    const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
    dispatch(setValidateResult({ id: widgetKey, data: validateData }));
  }

  const handleOnChange = (e) => {
    if (blurTimer.current) {
      blurTimer.current = false;
    };
    const option = e.target.value;
    setRadioValue(option);

    // 记录上次选择的id
    const previewOptionList = [];
    if (radioValue) {
      previewOptionList.push(radioValue);
      dispatch(setPrevOptionIds({ data: previewOptionList }));
    }

    // 记录本次选择的id
    const curOptionList = [];
    curOptionList.push(option);
    dispatch(setCurOptionIds({ data: curOptionList }));

    let value = '';
    if (other.includes(option)) {
      value = inputValueMap && inputValueMap[option] ? inputValueMap[option]?.replace(/(\s*$)/g, '') : '';
    }
    const data = [
      {
        option: option,
        value: value
      }
    ]
    setUpdateData(data);
    if (effectOption.length > 0) {
      effectFun(option);
    }

  }

  const handleChangeInput = (e, option) => {
    setInputValue(e.target.value);
    setInputValueMap({ ...inputValueMap, [option]: e.target.value })
  }
  const handleBlur = (v) => {
    blurTimer.current = true;
    setTimeout(() => {
      if (blurTimer.current) {
        const data = [
          {
            option: radioValue,
            value: inputValue ? inputValue.replace(/(\s*$)/g, '') : ''
          }
        ]
        setUpdateData(data);
      };
      blurTimer.current = false;
    }, 200);
  }

  // 显隐逻辑处理
  const effectFun = (option) => {
    // let effectAction = []
    // for (let i in effect) {
    //   effect[i].map((item, j) => {
    //     if (!effectAction.includes(item.effectId)) {
    //       effectAction = [...effectAction, item.effectId];
    //     }
    //   })
    // }


    if (effectOption.includes(option)) {
      let effectIdArr = []
      effect[option].map((item, i) => {
        const { hidden, effectId } = item;
        effectIdArr = [...effectIdArr, effectId];
        const data = {
          hidden: hidden,
          currentRespondentCode: currentRespondentCode
        }
        dispatch(updateAttributeData({ id: effectId, data: data }));
      })

      // console.log('effectAction43544646', effectAction);
      // effectAction.forEach(effItem => {
      //   if (!effectIdArr.includes(effItem)) {
      //     const data = {
      //       hidden: !hidden,
      //       currentRespondentCode: currentRespondentCode
      //     }

      //     console.log('fdgdfgdfg',effItem ,data);
      //     dispatch(updateAttributeData({ id: effItem, data: data }));
      //   }
      // })
    } else {
      for (let j in effectOption) {
        effect[effectOption[j]].map((item, i) => {
          const { hidden, effectId } = item;
          const data = {
            hidden: !hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effectId, data: data }));
        })
      }
    }
  }

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const { max, min, required } = rule;
    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + min + ' ' + intl.t('个选项，最多选择') + ' ' + max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ]

    if (max && max !== 0) {
      if (content.length > max) {
        return {
          error: false,
          msg: other ? msgArr[1] : msgArr[0]
        }
      }
    }

    if (min && min !== 0) {
      if (content.length < min) {
        return {
          error: false,
          msg: other ? msgArr[1] : msgArr[0]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[1]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const renderRadio = () => {
    if (direction === 'horizontal') {
      return (
        <>
          <Radio.Group
            className={`radio-option-${direction}`}
            disabled={disabled}
            onChange={handleOnChange}
            value={radioValue}
          >
            {
              options.map((opt, k) => {
                return (
                  <Radio value={opt.key} key={widgetKey + opt.key}>
                    <Tooltip placement="topLeft" title={opt.des}>
                      {decodeURIComponent(opt.value)}
                    </Tooltip>
                  </Radio>
                )
              })
            }
          </Radio.Group>
          {
            ((other.length > 0) &&
              radioValue &&
              other.includes(radioValue)) ?
              renderInput(radioValue) : ''
          }
        </>
      )
    }

    if (direction === 'vertical') {
      return (
        <>
          <Radio.Group
            className={`radio-option-${direction}`}
            disabled={disabled}
            onChange={handleOnChange}
            value={radioValue}
          >
            {
              options.map((opt, k) => {
                return (
                  <div key={widgetKey + opt.key + 'div'}>
                    <Radio value={opt.key} key={widgetKey + opt.key}>
                      <Tooltip placement="topLeft" title={opt.des}>
                        <div>{decodeURIComponent(opt.value)}</div>
                      </Tooltip>
                    </Radio>
                    {
                      ((other.length > 0) &&
                        radioValue &&
                        other.includes(radioValue) &&
                        radioValue === opt.key) ?
                        renderInput(radioValue) : ''
                    }
                  </div>
                )
              })
            }
          </Radio.Group>
        </>
      );
    }
  }


  const formatterCount = (e = '', optionKey = null) => {
    const { min } = otherRules[optionKey];
    const max = otherRules[optionKey].max ? otherRules[optionKey].max : 2000;
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    if (min && Number(min)) {
      return {
        formatter() {
          return `${intl.t('为了反馈更有效，')}${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
        }
      }
    }
    return {
      formatter() {
        return `${count}/${max}`;
      }
    }
  };

  const renderInput = (optionKey) => {
     const validateStatus =  validateFlag ? (validateResult[widgetKey]?.otherResult ?
              (validateResult[widgetKey]?.otherResult[optionKey]?.error ? '' : 'error') : '') : ''
    return (
      <div
        className={classnames("radio-textarea", {
          'radio-textarea-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
        })}
      >
        <Form.Item
          validateStatus={validateStatus}
        >
          <TextArea
            key={widgetKey + 'textarea'}
            value={inputValueMap ? inputValueMap[optionKey] : ''}
            placeholder={placeholder(optionKey)}
            disabled={disabled}
            rows={4}
            onChange={(e) => handleChangeInput(e, optionKey)}
            onBlur={handleBlur}
            showCount={formatterCount(inputValueMap ? inputValueMap[optionKey] : '', optionKey)}
            maxLength={2000}
          />
           { validateStatus === 'error' && (<div className="error-tip">
              <ExclamationCircleFilled />
              <span style={{ paddingLeft: '5px' }}>{intl.t('亲，此项必填的哈~')}</span>
            </div>
          )}
        </Form.Item>
      </div>
    )
  }

  const placeholder = (optionKey) => {
    const requiredContent = otherRules[optionKey].required ? intl.t('必填') : '';
    let desContent = '';
    options.map((item, i) => {
      if (item.key === optionKey && item.des !== '') {
        desContent = '(' + item.des + ')';
      }
    });
    return requiredContent + desContent;
  }

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden}`}
        style={{ position: 'relative', paddingTop: '14px' }}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={`div-label ${related}`}
        >
          <div className={`textarea-div-label-${required}-${requiredIcon}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div style={{ wordBreak: 'keep-all', display: 'inline-block' }}>{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={classnames(`radio-content radio-content-related-${related}`, {
          'radio-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
        })}>
          {tips ? (
            <div className="pc-question-tip">{decodeURIComponent(tips)}</div>
          ) : null}

          {renderRadio()}
        </div>
        {renderErrorMsg()}
      </div>
    </>
  );
};

export default radioWidget