import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {
  return useRegisterStyle('pagelet-employee-info', () => [
    {
      // base
      [`.${prefixCls}`]: {
        minHeight: '186px',
        display: 'flex',
        flexDirection: 'column',
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        padding: '16px',
        borderRadius: '10px',

        ['.employee-title']: {
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'PingFang SC',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0px',
          color: '#000000DE',
          marginBottom: '16px',

          ['.title-info']: {
            marginLeft: '4px',
          },
        },

        ['.employee-content']: {
          marginLeft: '34px',
        },

        ['.single-info']: {
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '22px',
          textAlign: 'left',
          letterSpacing: '0px',

          ['.single-title']: {
            color: 'rgba(0, 0, 0, 0.36)',
          },

          ['.single-data']: {
            color: 'rgba(0, 0, 0, 0.84)',
            fontWeight: 500,
            marginTop: '4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },

          ['.single-data-en']: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }
        },

        ['.single-info-en']: {
          width: '300px',
        }
      }
    }
  ]);
};

export default useStyle;
