import { createContext } from 'react';
import omegaTracker from '@didi/omega-tracker-lite';

// 注册omega
const Omega = omegaTracker.getTracker({
  appKey: 'omega9f21dcaf14',
  browserRouterEnable: true,
  autoPosition: false
});

// 公共store
export const PublicStore = createContext({});

/**
 * 防抖函数
 * @param {*} fn 
 * @param {*} wait 
 * @returns 
 */
export const debounce = (fn, wait) => {
  let timer = null;
  return function (e) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, wait);
  };
};

// 获取apollo配置信息
export const getApolloConfig = apiHost => {
  const url = '/ep/as/conf?ns=ddo_tip&name=perf_pagelets_config'; // url
  const host = apiHost.includes('o.didichuxing.com') ? '//as.xiaojukeji.com' : '//astest.intra.xiaojukeji.com'; // host
  const urlApollo = `${host}${url}`; // apollo配置地址
  return new Promise((resolve, reject) => {
    fetch(urlApollo)
      .then(data => data.json())
      .then(data => {
        if (data?.data?.length) {
          const apploObjConf = data.data[0]?.params;
          resolve({
            pageLetInfo: JSON.parse(apploObjConf?.['overviewManagement']), // 组件的信息 - 管理者（overviewManagement）
            helpInfo: JSON.parse(apploObjConf?.helpInfo), // 帮助信息
            monthUrl: apploObjConf?.monthUrl, // 月份url
            cycleUrl: apploObjConf?.cycleUrl, // 周期url
          });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 统一处理table表格操作列 链接跳转地址
 * @param {*} paramsObj 由链接参数组成的参数对象
 * @param {*} customUrl 自定义的url参数（前端自定义）
 */
export const handleJumpUrl = (paramsObj, customUrl) => {
  const urlParamsArr = [];
  for (const key in paramsObj) {
    urlParamsArr.push(`${key}=${paramsObj[key]}`);
  }
  return window.btoa(urlParamsArr.join('&') + customUrl);
};

// 记录埋点
export const recordTrackEvent = () => {
  Omega?.trackEvent && Omega.trackEvent('ep_ddo_perf_pagelet_ck', '', {
    pagelet_name: '@didi/pagelet-perf-overview-management',
  });
};
