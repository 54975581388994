import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';

const apis = manifest.apis || {};
const { request } = PageletApi;

export const getPersonalInfo = (apiHost, headers, params, usePermissionResponse) =>
  request(`${apiHost}${apis.getPersonalInfo}`, {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  });

export default getPersonalInfo;
