/*
 * @Author       : sunpenggui
 * @Date         : 2024/7/9 下午3:00
 * @Description  :
 */
export const CARE_CODE_ENUM = {
  ALL: '0',
  BIRTHDAY: '1',
  ANNIVERSARY: '2',
  REGULAR_WORKER: '3'
}
export const TIME_RANGE_TYPE = { week: 'week', month: 'month', date: 'date' };
export const timeRangeTypeList = [
  {
    code: 'month',
    name: '月'
  },
  {
    code: 'week',
    name: '周'
  },
  {
    code: 'date',
    name: '日'
  }
]
