import React, { useEffect, useState, useRef } from 'react';
import { getLocale, Tooltip, Divider } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { TimelineCard } from '@didi/ehr-timeline-card';
import { getWorkExp } from './services';
import WorkSvg from './assets/work.svg';

const localLocales = manifest.locales;
const { render: { LineItem } } = TimelineCard;

const isNull = (str) => {
	if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
		return '-';
	};
	return str;
}

const Pagelet = (props) => {
  const { params: { locales, apiHost, headers, employeeId  } } = props;
  const divRefs = useRef([]);
  const [data, setData] = useState([]);
  // 最大高度
  const [maxHeight, setMaxHeight] = useState(0);

  const getWorkExpDetail = async () => {
    try {
      const res = await getWorkExp(apiHost, headers, {
        employeeId,
      });
      const { data } = res;
      // 根据数据长度初始化ref数组
      divRefs.current = data.map(() => React.createRef());
      setData(data);
    } catch (error) {
      console.log('getWorkExp', error);
    }
  }

  useEffect(() => {
    getWorkExpDetail();
  }, []);

  useEffect(() => {
    // 当数据更新后，获取每个div的高度
    const heights = divRefs.current.map(ref => ref?.current?.offsetHeight || 0);
    const newMaxHeight = Math.max(...heights);
    // 如果新的最大高度与当前的最大高度不同，则更新它
    if (newMaxHeight !== maxHeight) {
      setMaxHeight(newMaxHeight);
    }
  }, [data]); 

  const prefixCls = 'pagelet-work-experienced';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  return wrapSSR(
    <div className={classes}>
      {
        data?.length > 0 && (
          <TimelineCard parentClassName={prefixCls}>
            {data?.map((item, index) => (
              <LineItem startTime={item.startTimeStr} endTime={item.endTimeStr}>
                <div className='work-card-container' ref={divRefs?.current?.[index]} style={{height: maxHeight}}>
                  <div className='work-card-icon'>
                    <WorkSvg />
                  </div>
    
                  <Tooltip title={item.companyName} placement="topLeft">
                    <div className='work-card-school'>
                      {isNull(item.companyName)}
                    </div>
                  </Tooltip>


                  <Tooltip title={<>{item.postTime}<Divider type="vertical" style={{ background: '#fff' }}/>{item.positionTitle}</>} placement="topLeft">
                    <div className='word-card-content'>
                      <div className='work-card-major'>
                        {item.postTime}
                      </div>

                      {
                        item.positionTitle && (
                          <Divider type="vertical" />
                        )
                      }
                      
                      <div className='work-card-major'>
                        {item.positionTitle}
                      </div>
                    </div>
                  </Tooltip>

                  <div className='work-card-tag'>
                    {
                      item?.companyNameTags?.map(single => (
                        <div className='tag'>{single}</div>
                      ))
                    }
                  </div>
                </div>
              </LineItem>
            ))}
          </TimelineCard>
        )
      }
    </div>,
  );
};

export default Pagelet;
