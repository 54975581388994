import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import * as formilyAntd from '@formily/antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  EmptyRender,
  ExportButton,
  FormFilter,
  PeopleSelect
} from './components';

import { ConfigProvider, Radio, Tooltip, en_US, getLang, getLocale, zh_CN, Segmented} from '@didi/ec-base';
import { DHRTable } from '@didi/ec-ehr-base';

import { DownThickOutlined, UpThickOutlined } from '@didi/iconflow/ec';

import { Intl, utils } from '@didi/pagelets-sdk';
import moment from 'moment';
import manifest from '../manifest.json';
import { getStr } from './utils/common';
import newRequest from './utils/newRequest';
import { useGetSort, useResetDataSources } from './utils/useHook';
import { showEllipseWord } from './utils/showEllipseWord';
const localLocales = manifest.locales || {};

const { useFormTableQuery, createFormActions, FormPath } = formilyAntd;
const actions = createFormActions();
const sourcePath = {
  management: '/home/probation/info',
  'manager-workspace': '/home/probation/info',
  'hr-workspace': '/hr-workspace/probation/info',
};
const filterMulList = [
  'businessStatusCodes',
  'targetStatusCode',
  'middleFeedbackProgressCode',
  'middleFeedbackOpinionCode',
  'middleSecondFeedbackProgressCode',
  'middleSecondFeedbackOpinionCode',
  'befullApproveProgressCode',
  'befullOpinionCode',
];

import useStyle from './style';

let ParamsRefCurrent = {
  viewRange: 1,
  queryPending: false,
  queryRisky: false,
  queryTimeOut: false,
};


// 试用期人数
const Pagelet = (props) => {
  const {
    params: { auth, headers = {}, locales, apiHost, deptInfos, defaultViewType = 1 }
  } = props;
  const authId = utils.getFunctionId(auth, 'page_probation_employees');
  const newHeaders = { ...headers, 'bricks-function-id': authId };
  const request = newRequest.init(newHeaders);

  const [params, setParams] = useState();
  const [viewType, setViewType] = useState(defaultViewType);
  const [viewRange, setViewRange] = useState(1);
  const [searchSwitch, setSearchSwitch] = useState(true);
  const [exportParams, setExportParams] = useState({});
  const ParamsRef = useRef(null);
  const PointRef = useRef(null);
  const DeptRef = useRef(undefined);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');
  const probationList = [
    {
      label: `${intl('TOTAL')} ${PointRef.current?.allQuantity ?? 0}`,
      value: 1
    },
    {
      label: `${intl('PENDING')} ${PointRef.current?.pendingQuantity ?? 0}`,
      value: 2
    },
    {
      label: `${intl('RISK')} ${PointRef.current?.riskQuantity ?? 0}`,
      value: 3
    },
    {
      label: `${intl('TIMEOUT')} ${PointRef.current?.timeOutQuantity ?? 0}`,
      value: 4
    },
  ]
  const viewRangeList = [
    {
      label: `${intl('TOTAL_EMPLOYEE')} ${PointRef.current?.allTeamsQuantity ?? 0}`,
      value: 1
    },
    {
      label: `${intl('DIRECTLY_SUBORDINATE')} ${PointRef.current?.directQuantity ?? 0}`,
      value: 2
    }
  ]


  const [getBusinessStatus] = useResetDataSources({
    url: `/gateway/probation/api/probation/basic/getApplyStatus?page=probationBoard`,
    name: ['businessStatusCodes'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });
  const [getTargetSettingProgress] = useResetDataSources({
    url: '/gateway/probation/api/probation/basic/getTargetSettingProgress',
    name: ['targetStatusCode'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });
  const [getMiddleFeedbackProgress] = useResetDataSources({
    url: '/gateway/probation/api/probation/basic/getMiddleFeedbackProgress',
    name: ['middleFeedbackProgressCode'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });
  const [getMiddleFeedback] = useResetDataSources({
    url: '/gateway/probation/api/probation/basic/getMiddleFeedback',
    name: ['middleFeedbackOpinionCode', 'middleSecondFeedbackOpinionCode'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });
  const [getSecondMiddleFeedbackProgress] = useResetDataSources({
    url:
      '/gateway/probation/api/probation/basic/getSecondMiddleFeedbackProgress',
    name: ['middleSecondFeedbackProgressCode'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });
  const [getBefullProgress] = useResetDataSources({
    url: '/gateway/probation/api/probation/basic/getBefullProgress',
    name: ['befullApproveProgressCode'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });
  const [getBeFullEstimate] = useResetDataSources({
    url: '/gateway/probation/api/probation/basic/getBeFullEstimate',
    name: ['befullOpinionCode'],
    keyItem: { valueKey: 'code', labelKey: 'descr' },
  });


  const statusSpan = (text, timeout, code) => {
    const typeObj = {
      1: 'notstart',
      4: 'complete',
    };
    let type = 'approve';
    if (code === 1 || code === 4) type = typeObj[code];
    if (timeout === 2) type = 'timeout';

    return (
      <span className="status-cell">
        <span className={code !== 0 && code !== 5 && `status-point-${type}`} />
        {getStr(text)}
        {timeout === 2 && <span style={{ color: '#F5565C' }}>（{intl('TIMEOUT')}）</span>}
      </span>
    );
  };
  const opinionSpan = (text, code) => {
    switch (code) {
      case 1:
        return (
          <span className="status-opinion-agree">
            <CheckCircleOutlined
              style={{ color: '#21CA55', marginRight: '2px' }}
            />
            {text}
          </span>
        );
      case 2:
        return (
          <span className="status-opinion-reject">
            <CloseCircleOutlined
              style={{ color: '#F5565C', marginRight: '2px' }}
            />
            {text}
          </span>
        );
      case 3:
        return (
          <span className="status-cell">
            <span className="status-point-undetermined" />
            {text}
          </span>
        );
      default:
        return <span>-</span>;
    }
  };
  const fitterList = [
    {
      title: intl('EMPLOYEE'),
      name: 'empId',
      copyableTag: true,
      component: (props) => (
        <PeopleSelect
          {...props}
          style={{ width: '100%' }}
          placeholder={intl('PLEASE_ENTER_EMPLOYEE_NAME')}
          labelInValue
          valueSelectKey="empId"
          empClass="CTR"
          deptCodeList={params?.deptIds}
          headers={newHeaders}
        />
      ),
    },
    {
      title: intl('DEPARTMENT'),
      name: 'deptCode',
      component: 'DeptmentSelect',
      copyableTag: true,
      mode: 'multiple',
      labelInValue: true,
      dataSource: [],
      functionId: authId,  // 传入权限
    },
    {
      title: intl('PROCESS_STATUS'),
      name: 'businessStatusCodes',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('PROCESS_TARGET'),
      name: 'targetStatusCode',
      component: 'Select',
      // onValueChangeHide: true,
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('MID_FEEDBACK_PROCESS'),
      name: 'middleFeedbackProgressCode',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('MID_FEEDBACK_CONCLUSION'),
      name: 'middleFeedbackOpinionCode',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('MID_SECOND_FEEDBACK_PROCESS'),
      name: 'middleSecondFeedbackProgressCode',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('MID_SECOND_FEEDBACK_CONCLUSION'),
      name: 'middleSecondFeedbackOpinionCode',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('PROGRESS_OF_CONVERSION_EVALUATION'),
      name: 'befullApproveProgressCode',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
    {
      title: intl('CONCLUSION_OF_SUPERIOR_CONFIRMATION'),
      name: 'befullOpinionCode',
      component: 'Select',
      mode: 'multiple',
      dataSource: [],
    },
  ];
  const probation = [
    {
      title: intl('EMPLOYEE'),
      dataIndex: 'empName',
      key: 'empName',
      render: (text, record) => (record.empLdap ? (
        <Tooltip title={`${text}（${record.empLdap}）`}>
          <span className="probation-ellipsis">{`${text}（${record.empLdap}）`}</span>
        </Tooltip>
      ) : (
        <Tooltip title={text}>
          <span className="probation-ellipsis">{text}</span>
        </Tooltip>
      )),
      width: 120,
      fixed: 'left',
    },
    {
      title: intl('JOB_NUMBER'),
      dataIndex: 'empId',
      key: 'empId',
      ellipsis: true,
      render: (text, record) => <Tooltip title={text}>{text}</Tooltip>,
      width: 90,
      fixed: 'left',
      sorter: true
    },
    {
      title: intl('JOINED_DATE'),
      dataIndex: 'entryDate',
      key: 'entryDate',
      ellipsis: true,
      render: (text, record) => (text ? (
        <Tooltip title={moment(text).format('YYYY-MM-DD')}>
          {moment(text).format('YYYY-MM-DD')}
        </Tooltip>
      ) : (
        <span>-</span>
      )),
      width: 110,
      fixed: 'left',
    },
    {
      title: intl('CONFIRMATION_DATA'),
      dataIndex: 'beFullDate',
      key: 'beFullDate',
      ellipsis: true,
      render: (text, record) => (text ? (
        <Tooltip title={moment(text).format('YYYY-MM-DD')}>
          {moment(text).format('YYYY-MM-DD')}
        </Tooltip>
      ) : (
        <span>-</span>
      )),
      width: 110,
      fixed: 'left',
      sorter: true
    },
    {
      title: intl('DEPARTMENT_PATH'),
      dataIndex: 'deptPath',
      key: 'deptPath',
      render: (text, record) => (
        <Tooltip title={text}>
           <span>{showEllipseWord(text, '>', false)}</span>
        </Tooltip>
      ),
      width: 300,
      // fixed: 'left',
    },
    {
      title: intl('SUPERIOR_NAME'),
      dataIndex: 'superiorName',
      key: 'superiorName',
      render: (text, record) => (
        <Tooltip title={`${text}（${record.superiorLdap}）`}>
          <span className="probation-ellipsis">{`${text}（${record.superiorLdap}）`}</span>
        </Tooltip>
      ),
      width: 120,
    },
    {
      title: intl('SUPERIOR_JOB_NUMBER'),
      dataIndex: 'superiorId',
      key: 'superiorId',
      ellipsis: true,
      render: (text) => <Tooltip title={text}>{text}</Tooltip>,
      width: 90,
    },
    {
      title: intl('HRBP_NAME'),
      dataIndex: 'hrbpName',
      key: 'hrbpName',
      render: (text, record) => (
        <Tooltip title={`${text}（${record.hrbpLdap}）`}>
          <span className="probation-ellipsis">{`${text}（${record.hrbpLdap}）`}</span>
        </Tooltip>
      ),
      width: 160,
    },
    {
      title: intl('HRBP_JOB_NUMBER'),
      dataIndex: 'hrbpId',
      key: 'hrbpId',
      ellipsis: true,
      render: (text) => <Tooltip title={text}>{text}</Tooltip>,
      width: 140,
    },
    {
      title: intl('CONFIRMATION_PROCESS_STATUS'),
      dataIndex: 'businessStatusName',
      key: 'businessStatusName',
      ellipsis: true,
      render: (text) => <Tooltip title={text}>{text}</Tooltip>,
      width: 120,
    },
    {
      title: intl('TRIAL_PERIOD_TARGET_PROGRESS'),
      dataIndex: 'targetStatusDescr',
      key: 'targetStatusDescr',
      ellipsis: true,
      render: (text, record) => {
        const typeObj = {
          2: 'complete',
          4: 'notstart',
        };
        let type = 'approve';
        if (record.targetStatusCode === 2 || record.targetStatusCode === 4) { type = typeObj[record.targetStatusCode]; }
        if (record.targetApproveOvertime === 2) type = 'timeout';

        return (
          <span className="status-cell">
            {!!record.targetStatusCode && (
              <span className={`status-point-${type}`} />
            )}
            {getStr(text)}
            {record.targetApproveOvertime === 2 && (
              <span style={{ color: '#F5565C' }}>（{intl('TIMEOUT')}）</span>
            )}
          </span>
        );
      },
      width: 140,
    },
    {
      title: intl('MID_FEEDBACK_PROCESS'),
      dataIndex: 'middleFeedbackProgressDescr',
      key: 'middleFeedbackProgressDescr',
      ellipsis: true,
      render: (text, record) => statusSpan(
        text,
        record.middleFeedbackOvertime,
        record.middleFeedbackProgressCode,
      ),
      width: 140,
    },
    {
      title: intl('MID_FEEDBACK_CONCLUSION'),
      dataIndex: 'middleFeedbackOpinionDescr',
      key: 'middleFeedbackOpinionDescr',
      ellipsis: true,
      render: (text, record) => opinionSpan(text, record.middleFeedbackOpinionCode),
      width: 140,
    },
    {
      title: intl('MID_SECOND_FEEDBACK_PROCESS'),
      dataIndex: 'middleSecondFeedbackProgressDescr',
      key: 'middleSecondFeedbackProgressDescr',
      ellipsis: true,
      render: (text, record) => statusSpan(
        text,
        record.middleSecondFeedbackOvertime,
        record.middleSecondFeedbackProgressCode,
      ),
      width: 140,
    },
    {
      title: intl('MID_SECOND_FEEDBACK_CONCLUSION'),
      dataIndex: 'middleSecondFeedbackOpinionDescr',
      key: 'middleSecondFeedbackOpinionDescr',
      ellipsis: true,
      render: (text, record) => opinionSpan(text, record.middleSecondFeedbackOpinionCode),
      width: 140,
    },
    {
      title: intl('PROGRESS_OF_CONVERSION_EVALUATION'),
      dataIndex: 'befullApproveProgressDescr',
      key: 'befullApproveProgressDescr',
      ellipsis: true,
      render: (text, record) => statusSpan(
        text,
        record.befullApproveOvertime,
        record.befullApproveProgressCode,
      ),
      width: 180,
    },
    {
      title: intl('CONCLUSION_OF_SUPERIOR_CONFIRMATION'),
      dataIndex: 'befullOpinionDescr',
      key: 'befullOpinionDescr',
      ellipsis: true,
      render: (text, record) => opinionSpan(text, record.befullOpinionCode),
      width: 120,
    },
    {
      title: intl('CURRENT_STATUS'),
      dataIndex: 'empStatus',
      key: 'empStatus',
      ellipsis: true,
      render: (text) => <span>{getStr(text)}</span>,
      width: 120,
    },
    {
      title: intl('TERMINATION_DATE'),
      dataIndex: 'leaveDate',
      key: 'leaveDate',
      ellipsis: true,
      render: (text) => (text ? (
        <Tooltip title={moment(text).format('YYYY-MM-DD')}>
          {moment(text).format('YYYY-MM-DD')}
        </Tooltip>
      ) : (
        <span>-</span>
      )),
      width: 110,
    },
    {
      title: intl('RESIGNATION_TYPE'),
      dataIndex: 'leaveType',
      key: 'leaveType',
      ellipsis: true,
      render: (text) => <span>{getStr(text)}</span>,
      width: 160,
    },
    {
      title: intl('TRIAL_PERIOD_DETAILS'),
      dataIndex: 'file',
      key: 'file',
      width: 100,
      fixed: 'right',
      ellipsis: true,
      render: (text, record) => {
        //targetStatusCode 试用期目标状态 0:空null 1.进行中、2.已完成、3.审核中、4-未开始
        //businessStatusCode 单据状态 10:未发起，20:已撤销，30:审批中，40:已拒绝，50:已完成，60:已终止，70:已结束
        let url = '';
        const pageSource = window.location.pathname.split('/');
        const probationStatus = !!record.businessStatusCode && record.businessStatusCode != 10;
        let role = '';
        if(window.location.pathname === '/management'){
          role = 'managementCard';
        } else if(window.location.pathname === '/hr-workspace'){
          role = 'hrWorkspaceCard';
        } else {
          role = pageSource;
        }
        if (record.taskId) {
          url = `/process/approval/todo/probation?busCode=${record.mainId}&taskId=${record.taskId}`;
        } else {
          url = `/${pageSource[1]}/probation/info?busCode=${record.mainId}&&role=${role}`;
        }
        return (
          <span>
            {!probationStatus && (!record.targetStatusCode || record.targetStatusCode === 1 || record.targetStatusCode === 4) ? (
              <span />
            ) : (
              <a
                href={probationStatus ? url : record.targetViewLink}
                style={{color: '#ff6400'}}
                rel="noreferrer"
                target="_blank"
                className="theme-text"
              >
                {intl('VIEW')}
              </a>
            )}
          </span>
        );
      },
    },
  ];

  const getProbationPoint = async (dept = DeptRef.current) => {
    if (!authId) return;
    const res = await request({
      method: 'post',
      url: `${apiHost}/gateway/bricks/api/unit/statistics/probation`,
      data: {
        deptCodes: dept || undefined,
        viewRange: ParamsRefCurrent.viewRange
      },
      headers: {
        functionId: authId,
      }
    });

    if (res.code === '100200') {
      PointRef.current = res.data;
    }
  };


  const updateParams = (viewType) => {
    const queryPending = viewType === 2;
    const queryRisky = viewType === 3;
    const queryTimeOut = viewType === 4;
    ParamsRefCurrent = {
      ...ParamsRefCurrent,
      viewRange,
      queryPending,
      queryRisky,
      queryTimeOut,
    };
  };

  useEffect(() => {
    if (!viewType) return;
    updateParams(viewType);
  }, []);

  const submitResetMiddleware = () => ({ context }) => ({
    onFormResetQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
    onFormSubmitQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
  });

  const service = async ({ values, pagination, sorter }) => {
    const sort = useGetSort(sorter);
    const sortField = Object.keys(sort)[0];
    const sortValue = sort[sortField];
    const sortObj = {};
    if (sortField === 'empId') {
      sortObj.empIdSort = sortValue;
    } else {
      delete sortObj.empIdSort;
    }
    if (sortField === 'beFullDate') {
      sortObj.beFullDateSort = sortValue;
    } else {
      delete sortObj.beFullDateSort;
    }
    const paramsValue = FormFilter.dealWithValue(fitterList, values);
    paramsValue.hasOwnProperty('empId')
      && (paramsValue.empId = paramsValue.empId.value);
    const deptCodes = paramsValue.deptCode ? paramsValue.deptCode.split(',') :  deptInfos[0].allDeptList;
    filterMulList.map((item) => {
      if (paramsValue[item]) paramsValue[item] = paramsValue[item].split(',');
    });

    const data = {
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
      ...ParamsRefCurrent,
      ...paramsValue,
      deptCodes,
      ...sortObj
    };
    delete data.deptCode; // 新接口不需要deptCode
    if(data.hasOwnProperty('businessStatusCodes') && !data.businessStatusCodes){
      data.businessStatusCodes = [];
    }
    setExportParams({ ...exportParams, ...data });
    // TODO: 因网络原因接口有时在渲染结束后才执行完毕，导致数据更新不及时，所以刷新列表时强制请求一次，待后期优化
    // TODO: formily生命周期原因获取不到最新useState，参数传值
    await getProbationPoint(deptCodes);

    if (authId) {
      return request({
        method: 'post',
        url: `${apiHost}/gateway/bricks/api/unit/statistics/page/probation/employees`,
        data,
        headers: {
          functionId: authId,
        }
      })
        .then((res) => {
          return {
            ...pagination,
            dataSource: res.data.list,
            total: res.data.total,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const { trigger, form, table } = useFormTableQuery(
    service,
    [
      submitResetMiddleware(),
      ({ actions }) => ({
        onFormInit(state) {
          getBusinessStatus(actions, FormPath);
          getTargetSettingProgress(actions, FormPath);
          getMiddleFeedbackProgress(actions, FormPath);
          getMiddleFeedback(actions, FormPath);
          getSecondMiddleFeedbackProgress(actions, FormPath);
          getBefullProgress(actions, FormPath);
          getBeFullEstimate(actions, FormPath);
          actions.setFieldState('deptCode', (state) => {
            const dhrMembers = deptInfos || [];
            state.value = dhrMembers;
          });
        },
      }),
    ],
    {
      pagination: {
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        // hideOnSinglePage: true,
        pageSizeOptions: [10, 20, 50, 100],
      },
    },
  );
  form.actions = actions;

  useEffect(() => {
    updateParams(viewType);
    trigger();
  }, [viewRange, viewType]);


  const prefixCls = 'pagelet-probation';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN}>
      <div className={prefixCls}>
        <div className="probation-modal-toolbar">
          <div className="probation-modal-radio-group">
            <Segmented
              value={viewType}
              options={probationList}
              size='samll'
              onChange={(value)=>{
                setViewType(value)
              }}
            />

            {PointRef.current?.bottomEmployee && (
              <>
                <Segmented
                  value={viewRange}
                  options={viewRangeList}
                  size='samll'
                  onChange={(value)=>{
                    setViewRange(value)
                  }}
                />
              </>
            )}
          </div>

          <div className="button-group">
            <div className='export-button-view-search' onClick={()=>{setSearchSwitch(!searchSwitch)}}>
              {`${ searchSwitch ? '收起' : '展开' }搜索条件`}
              {searchSwitch ? <UpThickOutlined /> : <DownThickOutlined /> }
            </div>
            <ExportButton
              data={exportParams}
              url="gateway/bricks/api/unit/statistics/export/probation/employees"
              authId={authId}
              defaultFile={intl('DHR_PROBATIONARY_PERIOD_SIGNBOARD')}
              intl={intl}
              headers={newHeaders}
            />
          </div>
        </div>

        <div
          className={
            searchSwitch
              ? 'advanced-search-body-show'
              : 'advanced-search-body-hide'
          }
        >
          <FormFilter
            form={form}
            filter={fitterList}
            total={table?.pagination?.total}
            intl={intl}
            headers={newHeaders}
          />
        </div>

        <EmptyRender intl={intl}>
          <DHRTable
            locale={langEn ? en_US : zh_CN}
            {...table}
            size="small"
            columns={probation}
            scroll={{
              x: 2340,
              y: 'calc(100vh - 212px)',
            }}
            rowKey={(record) => record.id}
            showQuickJumper
          />
        </EmptyRender>
      </div>
    </ConfigProvider>
  )
}

export default Pagelet;
