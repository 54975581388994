import React, { useState, useEffect } from 'react';
import StatusShow from '../../components/statusShow';
import './index.scss';
import { di18n } from '@ultra/common';
const { intl } = di18n;

export default function SuccessPage(props) {
  return (
    <div className="page-warpper">
      <StatusShow status={1}/>
    </div>
  );
};
