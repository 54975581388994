import React, { useEffect, useReducer, useMemo } from 'react';
import { ConfigProvider, Spin } from '@didi/ec-base';
import { initial, reducer } from './store'; // 全局数据
import { PublicStore, getApolloConfig } from './utils/utils';
import PageHeader from './components/PageHeader'; // 页面头部
import PageContent from './components/PageContent'; // 页面内容
import PageBottom from './components/PageBottom'; // 页面底部
import classNames from 'classnames';
import useStyle from './style';

//页面请求方法
import PageletServices from './services';

// 国际化翻译文件
import { utils, newRequest } from '@didi/pagelets-sdk';

const Pagelet = props => {
  const {
    params: { apiHost, auth, headers = {}, deptCodes = [] },
  } = props;

  const { functionId, functionName } = utils.getFunctionData(auth, '@didi/pagelet-perf-overview-supported') || {}; // 获取组件的id
  // 配置请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  newRequest.init(newHeaders); // 初始化请求头

  const prefixCls = 'pagelet-perf-overview-management';
  const wrapSSR = useStyle(prefixCls, { size: '12px' });
  const classes = classNames(prefixCls);

  const [stateStore, stateDispatch] = useReducer(reducer, initial); // 全局数据
  const contextValue = useMemo(
    () => ({
      // 存储全局数据
      stateStore,
      stateDispatch,
      props
    }),
    [stateStore],
  );
  const { loading } = stateStore;

  // 初始化接口请求
  const handleInitRequest = async () => {
    try {
      const res = await PageletServices.postDept('', {})
      const { data } = await PageletServices.postBp('', { deptCodes, topDeptCodes: res?.data.map(item => item?.deptCode) });
      const {
        assessmentInfo, pageResult,
        progressDistribution, searchParam,
        hasDiffCompareToVersion
      } = data;
      const { distributionCode } = searchParam;
      const { distributions, totalNum } = progressDistribution;

      stateDispatch({
        ...searchParam,
        assessmentInfo,
        distributions,
        totalNum, // 柱状图数量
        distributionName: distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        loading: false,
        hasDiffCompareToVersion,
      });
    } catch (e) {
      console.log(e);
    }
  };

  // 接口请求示例
  useEffect(async () => {
    handleInitRequest();
    const res = await getApolloConfig(apiHost); // 获取apollo配置
    stateDispatch({ apolloConfig: res });
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <PublicStore.Provider value={contextValue}>
        <div className={classes}>
          {
            loading ? <div className='spin'>
              <Spin />
            </div> : <>
              <PageHeader />
              <PageContent />
              <PageBottom />
            </>
          }
        </div>
      </PublicStore.Provider>
    </ConfigProvider>,
  );
};

export default Pagelet;
