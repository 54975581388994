import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import classNames from "classnames";
import CardIcon from "../CardIcon";
import MultiRootEditor from "@didi/ct-ckeditor5-build-multi-root";
import { CustomUploadAdapterPlugin } from "./UploadAdapter";
import QCode from "ct-images/qCode.png";

import "./index.scss";

let activityUrl = "${activityUrl}";

const EmailEditor = (props) => {
  const {
    labelContent = "",
    messageContent = "",
    getAllArea,
    channelCode,
    getEngStatus,
    status,
    templateCode,
    edit,
  } = props;

  const [showEng, setShowEng] = useState(true);

  useEffect(() => {
    if (labelContent) {
      init();
    }

    if (window?.editor && window?.editor !== undefined && labelContent) {
      const doc = new DOMParser().parseFromString(labelContent, "text/html");
      // 可编辑的区域
      const headerDoc = doc.getElementById("header");
      const footerDoc = doc.getElementById("footer");
      const headerEnDoc = doc.getElementById("headerEn");
      const footerEnDoc = doc.getElementById("footerEn");

      let data = {
        header: headerDoc?.innerHTML,
        headerEn: headerEnDoc?.innerHTML,
      };

      if (channelCode === "EMAIL") {
        data.footer = footerDoc?.innerHTML;
        data.footerEn = footerEnDoc?.innerHTML;
      }

      window?.editor.setData(data);
    }
  }, [labelContent]);

  useEffect(() => {
    const doc = new DOMParser().parseFromString(messageContent, "text/html");
    const docContent = new DOMParser().parseFromString(
      labelContent,
      "text/html"
    );

    if (channelCode === "EMAIL") {
      // dom没有headerEn说明英文已经隐藏
      if (doc.getElementById("headerEn") === null && !edit) {
        setShowEng(false);
        getEngStatus(false);
      } else {
        setShowEng(true);
        getEngStatus(true);
      }
    } else {
      // dom有deleteEn说明英文已经隐藏
      if (docContent.getElementById("deleteEn")) {
        setShowEng(false);
        getEngStatus(false);
      } else {
        setShowEng(true);
        getEngStatus(true);
      }
    }
  }, [messageContent, labelContent]);

  const init = () => {
    const doc = new DOMParser().parseFromString(labelContent, "text/html");
    // 可编辑的区域
    const headerDoc = doc.getElementById("header");
    const footerDoc = doc.getElementById("footer");
    const headerEnDoc = doc.getElementById("headerEn");
    const footerEnDoc = doc.getElementById("footerEn");

    let roots = {
      header: document.querySelector("#header"),
      headerEn: document.querySelector("#headerEn"),
    };

    let initialData = {
      header: headerDoc?.innerHTML,
      headerEn: headerEnDoc?.innerHTML,
    };

    if (channelCode === "EMAIL") {
      roots.footer = document.querySelector("#footer");
      roots.footerEn = document.querySelector("#footerEn");
      initialData.footer = footerDoc?.innerHTML;
      initialData.footerEn = footerEnDoc?.innerHTML;
    }

    getAllArea(doc);

    MultiRootEditor.create(
      // Define roots / editable areas:
      roots,
      // Editor configration:
      {
        initialData,
        // 设置语言为中文，需引入中文包
        language: "zh-cn",
        extraPlugins: [CustomUploadAdapterPlugin],
        toolbar: {
          items: [
            "fontSize",
            // "fontColor",
            // "FontBackgroundColor",
            "bold",
            "uploadImage",
          ],
        },
        
        fontSize: {
          options: [
            9,
            11,
            13,
            15,
            17,
            19,
            21
          ]
        }
      }
    )
      .then((editor) => {
        window.editor = editor;
        // Append toolbar to a proper container.
        const toolbarContainer = document.querySelector("#toolbar");
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);

        // Make toolbar sticky when the editor is focused.
        editor.ui.focusTracker.on("change:isFocused", () => {
          if (editor.ui.focusTracker.isFocused) {
            toolbarContainer.classList.add("sticky");
          } else {
            toolbarContainer.classList.remove("sticky");
          }
        });

        // 未启动-0 进行中-1 已归档-3 已结束-4
        // 1. 启动文案 在未启动时可以修改，其他状态不可修改
        // 2. 催办文案 未启动/进行中可以修改，其他状态不可修改
        if (
          (status !== 0 && templateCode === "NOTIFY_START") ||
          (templateCode === "NOTIFY_URGE" && status !== 0 && status !== 1)
        ) {
          editor.enableReadOnlyMode("header");
        } else {
          editor.disableReadOnlyMode("header");
        }
      })
      .catch((error) => {
        console.error("There was a problem initializing the editor.", error);
      });
  };

  const handleDeleteEng = () => {
    setShowEng(!showEng);
    getEngStatus(!showEng);
  };

  return (
    <div className="ckeditor-container">
      {
        channelCode === "EMAIL" && (
          <div id="toolbar"></div>
        )
      }
      
      <div contentEditable="true">
        <div contentEditable="false" className={classNames("ckeditor-content", {
          'ckeditor-content-email': channelCode === "EMAIL" 
        })}>
          <Tooltip placement="right" title="单击进行内容编辑" getPopupContainer={trigger => trigger.parentNode}>
            <div>
              <div id="header" />
            </div>
          </Tooltip>

          <div className="content-container">
            <div className="content-content">
              <p>本次调研的核心信息：</p>
              <ul type="disc">
                {channelCode === "EMAIL" ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <li>截止时间：(北京时间) xxxx-xx-xx hh:00</li>
                      <div className="content-tips">
                        （根据投放结束时间自动显示）
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <li>
                        调研地址：
                        <a style={{ color: "#0070c0" }} target="_blank">
                          {activityUrl}
                        </a>
                      </li>

                      <div className="content-tips">
                        （启动投放后系统自动生成答题链接）
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex" }}>
                      <li>
                        调研地址：
                        <a style={{ color: "#0070c0" }} target="_blank">
                          {activityUrl}
                        </a>
                      </li>

                      <div className="content-tips">
                        （启动投放后系统自动生成答题链接）
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <li>截止时间：(北京时间) xxxx-xx-xx hh:00</li>
                      <div className="content-tips">
                        （根据投放结束时间自动显示）
                      </div>
                    </div>
                  </>
                )}
              </ul>

              {channelCode === "EMAIL" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={QCode} width="80" />
                    <div className="content-tips content-qcode">
                      扫一扫填写问卷
                    </div>
                  </div>
                  <div className="content-tips">
                    （启动投放后系统自动生成二维码）
                  </div>
                </div>
              )}
            </div>
          </div>

          <Tooltip placement="right" title="单击进行内容编辑" getPopupContainer={trigger => trigger.parentNode}>
            <div>
              <div id="footer" />
            </div>
          </Tooltip>

          <Button
            className="editor-button"
            onClick={handleDeleteEng}
            icon={<CardIcon type="iconbianji5" />}
          >
            {showEng ? "删除英文内容" : "添加英文内容"}
          </Button>

          <Tooltip placement="right" title="单击进行内容编辑" getPopupContainer={trigger => trigger.parentNode}>
            <div className={showEng ? "" : "header-en-content"}>
              <div id="headerEn" />
            </div>
          </Tooltip>

          <div
            className={classNames("content-container", {
              "header-en-content": !showEng,
            })}
          >
            <div className="content-content">
              <p>The key information of this survey:</p>
              <ul type="disc">
                {channelCode === "EMAIL" ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <li>Deadline: (Beijing Time) xxxx-xx-xx hh:00</li>
                      <div className="content-tips">
                        （Display according to set time）
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <li>
                        Survey Link：
                        <a style={{ color: "#0070c0" }} target="_blank">
                          {activityUrl}
                        </a>
                      </li>

                      <div className="content-tips">
                        （Automatically generated by the system）
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex" }}>
                      <li>
                        Survey Link：
                        <a style={{ color: "#0070c0" }} target="_blank">
                          {activityUrl}
                        </a>
                      </li>

                      <div className="content-tips">
                        （Automatically generated by the system）
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <li>Deadline: (Beijing Time) xxxx-xx-xx hh:00</li>
                      <div className="content-tips">
                        （Display according to set time）
                      </div>
                    </div>
                  </>
                )}
              </ul>

              {channelCode === "EMAIL" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={QCode} width="80" />
                    <div className="content-tips content-qcode">
                      Scan to fill in
                    </div>
                  </div>
                  <div className="content-tips">
                    （After the launch, the system automatically generates a QR
                    code）
                  </div>
                </div>
              )}
            </div>
          </div>

          <Tooltip placement="right" title="单击进行内容编辑" getPopupContainer={trigger => trigger.parentNode}>
            <div className={showEng ? "" : "header-en-content"}>
              <div id="footerEn" />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default EmailEditor;
