import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('trainee-transfer', () => [
		{
			// base
      [`.${prefixCls}`]: {
        '.card-my-mentor-modal': {
          '.mentor-modal-title': {
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)',
            paddingBottom: '6px',
          },
          '.mentor-info-content':{
            display: 'flex',
            paddingBottom: '24px',
          },
          '.mentor-info-value': {
            lineHeight: '30px',
            width: '84%',
          },
          '.mentor-info-name': {
            minWidth: '200px',
            width: '200px',
            '.mentor-info-label': {
              paddingBottom: '2px',
            }
          },
          '.mentor-info-dept': {
            flex: 'auto',
            marginLeft: '24px',
          },
          '.ant-modal-footer': {
            padding: '16px 24px',
          },
          '.ant-select-selection-item': {
            pointerEvents: 'none',
          },
          '.copyable-tooltip-container': {
            maxWidth: '200px',
            whiteSpace: 'pre-wrap',
          },
        }
      }
		}
	])
};

export default useStyle;
