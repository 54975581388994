/*
 * @Author       : sunpenggui
 * @Date         : 2024/7/16 下午5:17
 * @Description  :
 */
import { useRegisterStyle } from '@didi/ec-base';

const useDrawerStyle = (prefixCls) => {
  return useRegisterStyle('pagelet-team-dynamic-drawer', () => [
    {
      [`.${prefixCls}-drawer,.${prefixCls}-detail-drawer`]: {
        ['.ant5-drawer-body']: {
          paddingRight: '7px !important',
        },

        ['.dynamic-list']: {
          height: 'calc(100% - 16px)',

          [`.${prefixCls}-filter`]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px',
            paddingRight: '9px',
            paddingBottom: '16px',
            userSelect: 'none',
            [`.${prefixCls}-filter-item`]: {
              display: 'flex',
              alignItems: 'center',
              ['.switch-type']: {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '4px 6px',
                borderRadius: '6px',
                background: 'rgba(0, 0, 0, 0.04)',
                ['.ant5-btn']: {
                  height: '24px',
                  padding: '0px 12px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  borderRadius: '4px',
                  borderColor: 'transparent',
                  background: 'transparent',
                  marginRight: '4px',
                  ['&:hover, &:focus']: {
                    color: 'rgba(0, 0, 0, 0.6) !important',
                    borderColor: 'transparent !important',
                    background: 'rgba(0, 0, 0, 0.08) !important',
                  },
                  ['&::after, & div']: {
                    display: 'none',
                  },
                  ['&.active']: {
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.84) !important',
                    borderColor: '#FFFFFF !important',
                    background: '#FFFFFF !important',
                    boxShadow: '0px 2px 6px 0px rgba(78, 98, 130, 0.02)',
                  }
                }
              },
              ['.switch-dynamic']: {
                marginLeft: '8px',
                ['.ant5-select']: {
                  ['.ant5-select-selector']: {
                    padding: '5px 6px',
                    borderRadius: '8px',
                    background: '#F5F5F5',
                    border: 'none',
                    ['.ant5-select-selection-item']: {
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '22px',
                      letterSpacing: 0,
                      color: 'rgba(0, 0, 0, 0.84)',
                    }
                  },
                  ['.ant5-select-arrow']: {
                    right: '6px',
                    width: '14px',
                    height: '14px',
                    color: 'rgb(12, 21, 46)',
                  },

                  ['.ant5-select-dropdown']: {
                    width: 'auto !important',
                    padding: '4px',
                    borderRadius: '8px',
                    background: '#FFFFFF',
                    boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                    ['.ant5-select-item']: {
                      fontSize: '14px',
                      lineHeight: '22px',
                      letterSpacing: 0,
                      color: 'rgba(0, 0, 0, 0.84)',
                      borderRadius: '4px',
                      ['&:hover']: {
                        background: 'rgba(0, 0, 0, 0.04)'
                      }
                    },
                    ['.ant5-select-item-option-selected']: {
                      color: '#FF6400',
                      background: '#FFF7F3',
                      fontWeight: '500',
                      ['&:hover']: {
                        background: '#FFF7F3'
                      }
                    }
                  },
                  ['&:hover ']: {
                    ['.ant5-select-selector']: {
                      background: '#F1F1F1',
                    },
                    ['.ant5-select-arrow']: {
                      opacity: '1',
                    },
                  },
                  ['&.ant5-select-focused .ant5-select-selector']: {
                    border: 'none',
                    boxShadow: 'none'
                  }
                }
              },


              ['.total-tip']: {
                marginLeft: '8px',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                letterSpacing: '0px',
                color: 'rgba(0, 0, 0, 0.72)',
              },

              ['.department-selection']: {
                ['.ehr-department-selection-new-style']: {
                  ['.ant-select']: {
                    ['.ant-select-selector']: {
                      borderColor: '#f5f5f5',
                      background: '#f5f5f5'
                    }
                  }
                }
              },
            },
          },
          [`.${prefixCls}-filter-warp`]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '16px 0',
            padding: '16px 0',
            ['.filter-warp-left']: {
              display: 'flex',
              ['.system, .system-active']: {
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.72)',
                padding: '7px 12px',
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '6px',
                marginRight: '8px',
                cursor: 'pointer',
              },
              ['.system-active']: {
                background: 'rgba(255, 100, 0, 0.1)',
                color: '#FF6400',
              }
            },
            ['.filter-warp-right']: {
              display: 'flex',
              '.form-filter-empType': {
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '.form-filter-empType-warp': {
                  marginRight: '8px',
                  paddingRight: '20px',
                  borderRadius: '8px',
                  opacity: '1',
                  background: '#FFFFFF',
                  boxSizing: 'border-box',
                  border: '1px solid rgba(0, 0, 0, 0.13)',
                  height: '32px',
                  minWidth: '88px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  '.span': {
                    fontSize: '14px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    marginLeft: '8px',
                  },
                },
                '.form-filter-icon': {
                  position: 'absolute',
                  right: '12px',
                  top: '2px',
                  '.form-filter-clear': {
                    display: 'none',
                    lineHeight: '30px',
                    '.error_circle_filled-icons': {
                      lineHeight: '14px'
                    }
                  },
                  '.form-filter-down': {
                    lineHeight: '30px',
                    '.down-thick-outlined-icons': {
                      lineHeight: '14px'
                    }
                  }
                },
                '.form-filter-dropdown': {
                  position: 'absolute',
                  zIndex: -1,
                  visibility: 'hidden',
                  background: '#FFFFFF',
                  boxShadow: '0 0 10px 0 rgba(58,39,30,0.10)',
                  padding: '12px',
                  top: 'calc(100% + 8px)',
                  left: 0,
                  width: 0,
                  borderRadius: '8px',
                  overflow: 'hidden',
                  '.ant-form-item': {
                    marginRight: 0,
                    marginBottom: 0
                  }
                },
                '.form-filter-dropdown-show': {
                  zIndex: 9,
                  width: '200px',
                  visibility: 'visible',
                  overflow: 'inherit',
                },
              },
              ['.form-filter-empType:hover']: {
                cursor: 'pointer',
                '.form-filter-empType-warp': {
                  border: '1px solid #ff7e41',
                },
                '.no-clear .form-filter-clear': {
                  display: 'block',
                },
                '.no-clear .form-filter-down': {
                  display: 'none',
                }
              }
            }
          },

          ['.ant5-spin-nested-loading']: {
            height: '100%',

            ['.ant5-spin-container']: {
              height: '100%',

              [`.${prefixCls}-content`]: {
                // display: 'grid',
                // gridTemplateColumns: '1fr',
                // // gridAutoRows: '56px',
                // gap: '12px',
                height: '100%',
                paddingRight: '7px',
                overflowY: 'auto',
                boxShadow: 'inset 0px 6px 10px -8px rgba(0, 0, 0, 0.08)',
                ['&::-webkit-scrollbar']: {
                  display: 'block',
                  width: '2px',
                },
                ['&::-webkit-scrollbar-track']: {
                  background: 'transparent',
                },

                [`.${prefixCls}-content-item`]: {
                  position: 'relative',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  '-webkit-box-orient': 'vertical',
                  '-webkit-line-clamp': '2',

                  height: '64px',
                  marginBottom: '10px',
                  padding: '10px 12px 6px',
                  fontSize: '14px',
                  borderRadius: '8px',
                  background: 'rgba(245, 245, 245, 0.6)',
                  boxSizing: 'border-box',
                  border: '0.5px solid rgba(245, 245, 245, 0.6)',
                  cursor: 'pointer',
                  ['.item-date-message']:{
                    display: 'flex',
                    alignItems: 'center',
                    visibility: 'hidden',
                    position: 'absolute',
                    right: '10px',
                    bottom: '10px',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    lineHeight: '24px',
                    color: '#FF6400',
                  },
                  ['&:hover']: {
                    background: 'rgba(255, 100, 0, 0.02)',
                    border: '0.5px solid #FF6400',

                    ['.item-date']: {
                      background: '#fffcfb',
                      display: 'none',
                      fontSize: '14px',
                    },
                    ['.item-date-message']:{
                      visibility: "visible",
                    },
                  },

                  ['.source-tag']: {
                    marginRight: '6px',
                    padding: '0px 4px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                    // border: '0.5px solid rgba(0, 179, 101, 0.5)',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: 0,
                    color: '#00B365',
                  },
                  ['.green']: {
                    color: 'rgba(0, 150, 89, 0.8)',
                    background: 'linear-gradient(0deg, rgba(0, 150, 89, 0.1), rgba(0, 150, 89, 0.1)), #FFFFFF'
                  },
                  ['.red']: {
                    color: '#F5483B',
                    background: 'linear-gradient(0deg, rgba(245, 72, 59, 0.1), rgba(245, 72, 59, 0.1)), #FFFFFF'
                  },
                  ['.blue']: {
                    color: 'rgba(10, 112, 245, 0.8)',
                    background: 'linear-gradient(0deg, rgba(10, 112, 245, 0.1), rgba(10, 112, 245, 0.1)), #FFFFFF'
                  },
                  ['.content']: {
                    width: '369px',
                  },
                  ['.content-text']: {
                    fontSize: '14px',
                    lineHeight: '22px',
                    wordBreak: 'break-all',
                    marginBottom: '0',
                    color: 'rgba(0, 0, 0, 0.72)',

                  },
                  ['.item-date']: {
                    position: 'absolute',
                    right: '10px',
                    bottom: '10px',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'right',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.36)',
                    background: '#f9f9f9',
                  },
                  ['.shadow-dom']: {
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'right',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                },

                ['.loading-tip']: {
                  height: '17px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: 'normal',
                  textAlign: 'center',
                  letterSpacing: 0,
                  color: 'rgba(0, 0, 0, 0.6)',
                }
              },

              [`.${prefixCls}-content-null`]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                ['img']: {
                  width: '120px'
                },
                ['.text-item-1']: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: '14px',
                  letterSpacing: 0,
                  color: '#2D3040',
                },
                ['.text-item-2']: {
                  marginTop: '6px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: '14px',
                  letterSpacing: 0,
                  color: '#ABACB3',
                },
              },

              [`.${prefixCls}-content-err`]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center',
                ['img']: {
                  width: '120px'
                },
                ['.text-item-1']: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  lineHeight: '14px',
                  letterSpacing: 0,
                  color: '#2D3040',
                },
                // ['.text-item-2']: {
                //   marginTop: '6px',
                //   fontSize: '12px',
                //   fontWeight: 'normal',
                //   lineHeight: '14px',
                //   letterSpacing: 0,
                //   color: '#ABACB3',
                // },
              },
            },
          },
        },
      },
      [`.${prefixCls}-detail-drawer`]: {
        ['.ant5-drawer-header']: {
          background: '#F5F5F5'
        },
        ['.ant5-drawer-body']: {
          background: '#F5F5F5'
        },
      }
    }
  ]);
};

export default useDrawerStyle;
