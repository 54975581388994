import React, { useState, useRef, useEffect, useMemo } from 'react';
import CardIcon from '../../cnps/SvgIcon';
import { useRequest } from 'ahooks';
import { Input, Spin } from 'antd';
import CustomTimeLineItemCpn from '../CustomTimeLineItemCpn';
import { getTalentProfile } from '../../services';
import { Empty } from '@didi/ec-base';
import CustomSmallBlockTabs from '../../cnps/CustomSmallTabs';
import { CurrentYearActivityInfoContext, AllProfileInfoContext } from './context';
import './index.less';
import { LangTranslate } from '../../utils';

function CustomTimeLineCpn(props) {
  const { apiHost, headers, employeeId, usePermissionResponse = true } = props;
  const scrollPenRef = useRef();
  const [curTabIdx, setCurTabIdx] = useState();

  const {
    data: profileInfo = {},
    loading,
    runAsync: fetchProfileInfo,
  } = useRequest(
    async params => {
      try {
        const res = await getTalentProfile({
          apiHost,
          headers: { ...headers },
          params: { empId: params?.employeeId },
          usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log(' PageletServices error', error);
      }
    },
    {
      manual: true,
    },
  );
  const subTabs = useMemo(() => {
    const yearsList = profileInfo?.profileInfoByTime || [];
    if (!yearsList?.length) {
      return [];
    }
    const tmpTabList = yearsList
      ?.map?.((it, idx) => ({
        key: it?.year,
        label: `${it?.year}${LangTranslate('年')}`,
        children: null,
        dataIdx: idx,
      }))
      ?.reverse?.();

    setCurTabIdx(() => tmpTabList?.[0]?.key || undefined);
    return tmpTabList;
  }, [profileInfo]);

  const curYearInfos = useMemo(() => {
    if (!curTabIdx) {
      return;
    }
    const curTabInfo = subTabs?.find(tab => tab?.key === curTabIdx) || {};
    return profileInfo?.profileInfoByTime?.[curTabInfo?.dataIdx || 0];
  }, [curTabIdx, profileInfo]);

  useEffect(() => {
    fetchProfileInfo({ employeeId: employeeId });
  }, [employeeId]);

  const handelPinOriginLoc = ({ expendAllWrapRef }) => {
    setTimeout(() => {
      const curDom = scrollPenRef?.current;
      const expendAllWrapDom = expendAllWrapRef?.current;
      if (curDom && expendAllWrapDom) {
        const oldLeft = curDom.scrollLeft;
        const newLeft = oldLeft + (expendAllWrapDom.getBoundingClientRect()?.width || 1) - 122;
        curDom.scrollLeft = newLeft;
      }
    });
  };

  const pinOnRight = () => {
    const curDom = scrollPenRef?.current;
    if (curDom) {
      curDom.scrollLeft = curDom.scrollWidth;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      pinOnRight();
    });
  }, [profileInfo, curTabIdx]);

  return (
    <div
      style={{
        height: !!profileInfo?.profileInfoByTime?.length ? 613 : '252px',
      }}
      className="talent-market-custom-time-line-cpn-wrap"
    >
      <Spin spinning={loading}>
        {!!profileInfo?.profileInfoByTime?.length || loading ? (
          <>
            <CustomSmallBlockTabs
              items={subTabs}
              onChange={newTab => {
                setCurTabIdx(() => newTab);
              }}
            />
            <AllProfileInfoContext.Provider value={profileInfo}>
              <CurrentYearActivityInfoContext.Provider value={curYearInfos}>
                <div ref={scrollPenRef} className="talent-market-custom-time-line-items-wrap">
                  {curYearInfos?.activityInfos?.map?.((it, idx) => (
                    <CustomTimeLineItemCpn
                      curTabIdx={curTabIdx}
                      handelPinOriginLoc={handelPinOriginLoc}
                      data={it}
                      isLastOne={curYearInfos?.activityInfos?.length - 1 === idx}
                      isFirstOne={idx === 0}
                    />
                  ))}
                </div>
              </CurrentYearActivityInfoContext.Provider>
            </AllProfileInfoContext.Provider>
          </>
        ) : (
          <Empty
          // description={LangTranslate('暂无数据')}
          />
        )}
      </Spin>
    </div>
  );
}

export default CustomTimeLineCpn;
