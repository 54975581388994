import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 合同关系
 * url: /api/archive/staff-contract
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getContractualRelationshipsList = (apiHost, headers, params) => request(`${apiHost}${apis.getContractualRelationshipsList}`,
  {
    method: 'get',
    headers,
    params
  }
);
