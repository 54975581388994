import React from 'react';
import { connect, mapReadPretty } from '@formily/react';
import CustomSelectSubSystemInfo from '@/cpns/CustomSelectSubSystemInfo';
import './index.less';

const CustomReadPrettyInput = connect(
  CustomSelectSubSystemInfo,
  mapReadPretty(props => (
    <div className="label-system-pagelet-baseinfo-panel-readPretty-select">
      <CustomSelectSubSystemInfo {...props} />
    </div>
  )),
);
export default CustomReadPrettyInput;
