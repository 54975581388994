import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('interview-face-process', () => [
		{
			// base
			[`.${prefixCls}`]: {
        marginBottom: '12px',
        lineHeight: '30px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'visible',

        ['.face-process-content']: {
          display: 'flex',
          flexDirection: 'row',

          ['.face-item-container']: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginRight: '60px',
            marginBottom: '8px',

            ['&:last-child']: {
              ['.line .line-right-part']: {
                display: 'none',
              }
            },

            ['.line']: {
              marginBottom: '12px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',

              ['.line-title']: {
                fontFamily: 'PingFang SC',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '22px',
                textAlign: 'justify', 
                letterSpacing: '0.5px',
                color: '#00000099',
                width: '50px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },

              ['.line-title-en']: {
                width: '68px',
              },

              ['.line-dot-part']: {
                width: '9px',
                height: '9px',
                borderRadius: '50%',
                background: '#9AA2BB',
                position: 'relative',
              },
    
              ['.line-right-part']: {
                height: '1px',
                background: '#cad2dd',
                position: 'absolute',
                width: '100px',
                left: '70px',
              },
            },
          },

          ['.face-items-box']: {
            display: 'flex',
            alignItems: 'center',

            ['&.active-box']: {
              ['.face-item-content']: {
                border: '1px solid #FF7E41',
              },
              
              ['.face-type-box']: {
                background: '#FF7E41 !important',
                color: '#FFFFFF !important',
              }
            },

            ['.face-item-content']: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              width: '122px',
              height: '148px',
              background: 'linear-gradient(184deg, #E6F2FF -1%, #FFFFFF 60%), #FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.06)',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',

              ['&:hover']: {
                border: '1px solid #84B7FA',
              },

              ['.ant5-avatar-wrap .ant5-avatar-icon']: {
                marginRight: '0 !important',
              },

              ['.item-tag-con']: {
                position: 'absolute',
                right: '36px',
                top: '46px',

                ['svg']: {
                  fontSize: '24px',
                  transform: 'scale(0.5)',
                }
              },

              ['.item-tag-auth']: {
                top: '32px',
              },

              ['.face-avatar-box']: {
                marginRight: '0 !important',
              },

              ['.face-name-box']: {
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
                letterSpacing: '0px',
                color: '#000000D6',
                marginTop: '8px',
              },

              ['.face-workNo-box']: {
                fontFamily: 'PingFang SC',
                fontSize: '12px',
                fontWeight: 'normal',
                lineHeight: '20px',
                letterSpacing: 0,
                color: '#00000099',
              },

              ['.interview-evaluation-container']: {
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'PingFang SC',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                letterSpacing: 0,
                color: '#000000B8',
                marginTop: '12px',
                marginLeft: '16px',

                ['&:hover']: {
                  cursor: 'pointer',
                  color: '#0A70F5',
                },

                ['svg']: {
                  transform: 'rotate(-90deg)',
                }
              },

              ['.interview-evaluation-container-en']: {
                fontSize: '11px',
                marginLeft: '4px'
              }
            }
          },

          ['.arrow-box']: {
            width: '38px',
            textAlign: 'center',
            color: '#B4BEC8',
          }
			  },

        ['&.interview-face-scroll-content']: {
          overflow: 'auto',

          ['>div']: {
            width: 'max-content',
          }
        }
		  }
    }
	]);
};

export default useStyle;
