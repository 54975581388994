import React, { useEffect, useState, useRef } from 'react';
import { getLocale, Tooltip } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { TimelineCard } from '@didi/ehr-timeline-card';
import { getLearnTrain } from './services';

const localLocales = manifest.locales;
const { render: { LineItem } } = TimelineCard;

const isNull = (str) => {
	if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
		return '-';
	};
	return str;
}

const Pagelet = (props) => {
  const { params: { apiHost, locales, headers, employeeId } } = props;
  const [data, setData] = useState([]);
  const divRefs = useRef([]);
  // 最大高度
  const [maxHeight, setMaxHeight] = useState(0);

  const getLearnTrainList = async () => {
    try {
      const res = await getLearnTrain(apiHost, headers, {
        employeeId,
      });
      const { data } = res;
      // 根据数据长度初始化ref数组
      divRefs.current = data?.map(() => React.createRef());
      setData(data);
    } catch (error) {
      console.log('getLearnTrain', error);
    }
  }
  useEffect(() => {
    getLearnTrainList();
  }, []);

  useEffect(() => {
    // 当数据更新后，获取每个div的高度
    const heights = divRefs.current.map(ref => ref?.current?.offsetHeight || 0);
    const newMaxHeight = Math.max(...heights);
    // 如果新的最大高度与当前的最大高度不同，则更新它
    if (newMaxHeight !== maxHeight) {
      setMaxHeight(newMaxHeight);
    }
  }, [data]);

  const prefixCls = 'pagelet-learn-training';
  const wrapSSR = useStyle(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      {
        data?.length > 0 && (
          <TimelineCard>
            {data?.map((item, index) => (
              <LineItem startTime={isNull(item?.startDate)} endTime={isNull(item?.endDate)}>
                <div className='learn-card-container' ref={divRefs?.current?.[index]} style={{height: maxHeight}}>
                  <Tooltip title={item.trainName} placement="topLeft">
                    <div className='learn-card-train'>
                      {isNull(item.trainName)}
                    </div>
                  </Tooltip>
                  <div className='learn-card-tag'>
                    {
                      item?.label?.length > 0 && item?.label?.map(single => (
                        <div className='tag'>{single}</div>
                      ))
                    }
                  </div>
                </div>
              </LineItem>
            ))}
          </TimelineCard>
        )
      }
   
    </div>,
  );
};

export default Pagelet;
