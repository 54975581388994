export const getStr = (str) => ((str || str === 0) ? str : '-');

// 导出excel表格
export const getOutExcel = (fileName, res) => {
  const blob = new Blob([res]);
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    const url = window.URL || window.webkitURL || window.moxURL;
    link.href = url.createObjectURL(blob);
    link.download = fileName;
    link.click();
    url.revokeObjectURL(link.href);
  }
};

// url解析
export function useQuery() {
  return new URLSearchParams(window.location.search);
}

export const preImageUrl = 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/cards/';
export const careImageUrl = 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/care/';
