import React, { useState, useEffect, useRef } from 'react';
import { Divider, Tooltip } from 'antd';
import './index.scss';
import { di18n, CardIcon } from '@ultra/common';

const { intl } = di18n;

const AvatarProcessImg = (props) => {
  const { data, wrapClassName } = props;
  const initSrc = 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/ct/avatar.png';
  const imgError = (e) => {
    e.target.onerror = null;
    e.target.src = initSrc
  }

  return <div className={wrapClassName}>
    <img className="navigate-q-avatar" onError={(e) => imgError(e)} src={data.avatar || initSrc} />
  </div>
}
export default function QuestionnaireProcess(props) {
  const {
    activityId,
    respondentCode,
    singleQuestionaireList,
    questionaireType,
    respondentCodePreview,
    participantWorkNo,
    index,
    activityType
  } = props;

  const [currentShow, setCurrentShow] = useState([]);
  const refNode = useRef(null);
  // 如果是人员，渲染头像
  const isINDIVIDUAL = activityType === 'INDIVIDUAL' || activityType === 'INDIVIDUAL_BP';

  // 计算左侧导航是否需要滚动
  useEffect(() => {
    let activityNode = document.getElementById(`navigate-${respondentCode}-content`)
    let activityNodeOffsetTop = activityNode?.offsetTop
    let contentClientHeight = refNode.current.offsetHeight
    // 如果高度不一致则滚动滚动轴
    if (activityNodeOffsetTop && contentClientHeight && activityNodeOffsetTop > contentClientHeight) {
      console.log(activityNodeOffsetTop - contentClientHeight, 'activityNodeOffsetTop - contentClientHeight', refNode.current.scrollTo)
      // refNode.current.scrollTo() = activityNodeOffsetTop - contentClientHeight
      refNode.current.scrollTo({
        top: (activityNodeOffsetTop - contentClientHeight) + 20,
        behavior: 'smooth'
      })
    }
  }, [currentShow])

  const renderRespondents = (item, i) => {
    return (
      item?.contentInfos?.map((opt, key) => {
        // console.log('here')
        const { contentId, activityRespondents } = opt;
        return (
          <div key={key} >
            {
              // 展现被评估对象的名字
              activityRespondents?.map(value => (
                <a
                  key={value.respondentCode}
                  href={`${document.location.origin}/questionnaire/pc/${activityId}/${contentId}/${value.respondentCode}/submit/${i}`}
                  id={`navigate-${value.respondentCode}-content`}
                >
                  <div className={`navigate-list ${respondentCode && respondentCode === value.respondentCode ? 'navigate-list-active' : ''}`}>
                    { isINDIVIDUAL && <AvatarProcessImg data={value} wrapClassName='navigate-list-avatar'/> }
                    <Tooltip
                      placement="top" title={value.activityRespondentFullName}
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      overlayClassName="navigate-text-tooltip"

                    >
                      <div
                        className='navigate-text title'>
                        {value.activityRespondentName}
                      </div>
                    </Tooltip>
                    {
                      // 被评估对象的问卷状态
                      <>
                        <Tooltip placement="top" title={value.recycleStatus === 2 ? intl.t('已完成') : intl.t('未完成')} getPopupContainer={triggerNode => triggerNode.parentNode}>
                          {
                            value.recycleStatus === 2 ? (
                              <div className="navigate-list-status-done">
                                <span><CardIcon type={`${(respondentCode && respondentCode === value.respondentCode) ? 'iconbai-yiwancheng' : 'iconyiwancheng1'}`} /></span>
                                <span className='navigate-text status'>{intl.t('已完成')}</span>
                              </div>
                            ) : (
                              <div className="navigate-list-status-nostart">
                                <span className='navigate-text status'>{intl.t('未完成')}</span>
                                <span><CardIcon type={`${(respondentCode && respondentCode === value.respondentCode) ? 'iconjiantou-bai' : 'iconjiantou-cheng'}`} /></span>
                              </div>
                            )
                          }
                        </Tooltip>
                      </>
                    }
                  </div>
                </a>
              ))
            }
          </div>
        )
      })
    )
  };

  const renderPreViewRespondents = (item) => (
    <div>
      {
        // 展现被评估对象的名字
        item?.activityRespondents?.map(value => (
          <a
            key={value.respondentCode}
            href={`${document.location.origin}/questionnaire/pc/preview/setting/${activityId}/${participantWorkNo}/${value.respondentCode}/${item.respondentTypeCode}/${index}`}
          >
            <div className={`navigate-list ${respondentCode && respondentCode === value.respondentCode ? 'navigate-list-active' : ''}`}>
             {
                isINDIVIDUAL && <AvatarProcessImg data={value} wrapClassName='navigate-list-avatar'/>
              }
              <Tooltip placement="top" title={value.activityRespondentFullName} getPopupContainer={triggerNode => triggerNode.parentNode} overlayClassName="navigate-text-tooltip">
                <div
                  className='navigate-text title'>
                  {value.activityRespondentName}
                </div>
              </Tooltip>
            </div>
          </a>
        ))
      }
    </div>
  );

  useEffect(() => {
    // 区分单人问卷和合并问卷，单人问卷每个被评估对象的问卷可以不一样
    questionaireType === 2 ? setCurrentShow([singleQuestionaireList[index]]) : setCurrentShow(singleQuestionaireList);
  }, [singleQuestionaireList]);

  // pc单人问卷左侧渲染的导航，包含被评估对象和问卷状态
  // 这个文件不知道还需要加什么注释
  // sonar强制加10行注释
  // 我不知道改写什么了
  // 再加一行
  return (
    <div className="question-navigate-pc">
      <div className="question-navigate-role" ref={refNode}>
        {
          // 遍历被评估对象的组别
          currentShow?.map((item, i) => (
            <div key={item.respondentTypeCode} className="navigate-title-card">
              <div className='navigate-title'>
                <Tooltip placement="top" title={item.respondentTypeDesc} getPopupContainer={triggerNode => triggerNode.parentNode}>
                  {item.respondentTypeDesc}
                  {
                    activityType === 'ORGANIZATION' && <>
                      {
                        (item?.recycleCount || item?.respondentCount) ? `：${item?.recycleCount}/${item?.respondentCount}` : null
                      }
                    </>
                  }
                </Tooltip>
              </div>
              {/* <Divider style={{ margin: '3px 0 10px 0' }} /> */}
              <div className="navigate-q">
                {
                  item?.contentInfos ? renderRespondents(item, i) : renderPreViewRespondents(item)
                }

              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}