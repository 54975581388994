import React from 'react';
import { Tabs } from 'antd';
import './index.less';
function CustomSmallTabs(props) {
  return (
    <div className="talent-market-custom-small-tabs">
      <Tabs tabBarGutter={4} {...props} />
    </div>
  );
}

export default CustomSmallTabs;
