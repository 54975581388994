import React from 'react';
import { LangTranslate } from '@/utils';
import { Empty } from 'antd';
import CustomReadPrettyInput from '@/cpns/CustomReadPrettyInput';
import CustomReadPrettyTreeSelect from '@/cpns/CustomReadPrettyTreeSelect';
import CustomReadPrettySelect from '@/cpns/CustomReadPrettySelect';
import CustomReadPrettyTextArea from '@/cpns/CustomReadPrettyArea';
import CustomReadPrettySelectSubSystemInfo from '@/cpns/CustomReadPrettySelectSubSystemInfo';
import CustomReadPrettyDepartmentTreeSelect from '@/cpns/CustomReadPrettyDepartmentTreeSelect';
import DepartmentTreeSelect from '@/cpns/DepartmentTreeSelect';
import CustomSelectSubSystemInfo from '@/cpns/CustomSelectSubSystemInfo';
export const PanelConfig = readPretty => {
  return {
    title: LangTranslate('标签组信息'),
    fieldsList: [
      {
        fieldLabel: LangTranslate('标签组根ID'),
        fieldName: 'rootGroupId',
        validator: [{ required: false, message: LangTranslate('必填项') }],
        component: CustomReadPrettyInput,
        componentProps: {
          readOnly: true,
          variant: 'borderless',
        },
      },
      {
        fieldLabel: LangTranslate('标签组ID'),
        fieldName: 'id',
        validator: [{ required: false, message: LangTranslate('必填项') }],
        component: CustomReadPrettyInput,
        componentProps: {
          readOnly: true,
          variant: 'borderless',
        },
      },
      {
        fieldLabel: LangTranslate('标签组名称'),
        fieldName: 'name',
        validator: [
          { required: true, message: LangTranslate('必填项') },
          { maxLength: 200, message: LangTranslate('字数超长') },
        ],
        component: CustomReadPrettyInput,
        componentProps: { placeholder: LangTranslate('请输入') },
      },
      {
        fieldLabel: LangTranslate('标签组标识'),
        fieldName: 'code',
        validator: [
          { required: false, message: LangTranslate('必填项') },
          { maxLength: 200, message: LangTranslate('字数超长') },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,
            message: LangTranslate('支持的文本格式：中文、英文、下划线、数字'),
          },
        ],
        component: CustomReadPrettyInput,
        componentProps: { placeholder: LangTranslate('请输入标签组标识') },
      },
      {
        fieldLabel: LangTranslate('标签组描述'),
        fieldName: 'description',
        validator: [
          { required: false, message: LangTranslate('必填项') },
          { maxLength: 1000, message: LangTranslate('字数超长') },
        ],
        component: CustomReadPrettyTextArea,
        componentProps: {
          autosize: { minRows: 2, maxRows: 2 },
          placeholder: LangTranslate('请输入'),
        },
      },
      {
        fieldLabel: LangTranslate('评价对象'),
        fieldName: 'subjectId',
        validator: [{ required: true, message: LangTranslate('必填项') }],
        component: CustomReadPrettySelect,
        componentProps: {
          notFoundContent: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={LangTranslate('暂无数据')} />
          ),
          placeholder: LangTranslate('请输入'),
          fieldNames: { label: 'name', value: 'id' },
          optionFilterProp: 'name',
          showSearch: true,
          allowClear: true,
        },
      },
      {
        fieldLabel: LangTranslate('归属维度'),
        fieldName: 'dimensionId',
        validator: [{ required: true, message: LangTranslate('必填项') }],
        component: CustomReadPrettyTreeSelect,
        componentProps: {
          notFoundContent: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={LangTranslate('暂无数据')} />
          ),
          placeholder: LangTranslate('请输入'),
          fieldNames: { label: 'name', value: 'id', children: 'subDimensionList' },
          showSearch: true,
          treeNodeFilterProp: 'name',
          treeDefaultExpandAll: true,
          allowClear: true,
        },
      },
      {
        fieldLabel: LangTranslate('归属系统'),
        fieldName: 'belongSystemId',
        validator: [
          {
            required: true,
            message: LangTranslate('必填项'),
          },
        ],
        component: CustomReadPrettySelectSubSystemInfo,
        componentProps: { placeholder: LangTranslate('请选择') },
      },
      {
        fieldLabel: LangTranslate('归属部门'),
        fieldName: 'dept',
        validator: [{ required: true, message: LangTranslate('必填项') }],
        component: CustomReadPrettyDepartmentTreeSelect,
        componentProps: {
          placeholder: LangTranslate('请选择'),
          maxTagCount: 2,
          maxTagTextLength: 4,
          fieldNames: {
            label: 'deptName',
            value: 'deptCode',
            children: 'childList',
          },
          showSearch: true,
          treeNodeFilterProp: 'deptName',
        },
      },
      {
        fieldLabel: LangTranslate('自定义归属权限'),
        fieldName: 'customAuth',
        validator: [
          { maxLength: 200, message: LangTranslate('字数超长') },
          { required: false, message: LangTranslate('必填项') },
        ],
        component: CustomReadPrettyInput,
        componentProps: { placeholder: LangTranslate('请输入') },
      },
    ],
  };
};
