import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('approval-list-status', () => [
		{
			// base
      [`.${prefixCls}`]: {
        '.approval-list-status': {
          display: 'flex',
          alignItems: 'center',
          //  20 已撤销
          //  30 审批中
          //  40 已拒绝
          //  50 已完成
          //  60 已终止
          //  99 成功
          '.status-dot': {
              width: '5px',
              height: '5px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: '#aeaeae',
              marginRight: '4px',
             '&.status-dot-30': {
                  backgroundColor: '#1474ff',
              },
              '&.status-dot-40, &.status-dot-60':{
                  backgroundColor: '#ff5656',
              },
              '&.status-dot-50,&.status-dot-99':{
                  backgroundColor: '#29d57a',
              }
          }
      }
      }
		}
	])
};

export default useStyle;
