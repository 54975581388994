import React, { useRef, useImperativeHandle } from 'react';
import { cloneDeep } from 'lodash';
import CTEditor from '../CTEditor';
import EditorTabs from '../EditorTabs';
import './index.scss';

// 编辑器卡片
const Editors = (props) => {
  const {
    language,
    changeLanguage,
    showTabs,
    contents,
    values = [],
    text = '问卷标题录入支持多语言，默认如下双语，如需其他语言请选择：',
    onChange,
    messageType, // 类型
    maxLength,
    editorConfigs,
    placeholderType
  } = props;

  // 返回数据
  const getEditorValue = (v) => {
    const data = cloneDeep(values);
    let languagesContents = data || [];
    if (languagesContents.filter(item => item.languageType == v.languageType)[0]) {
      languagesContents = languagesContents.map(item => {
        if (item.languageType == v.languageType) {
          item = { ...item, ...v }
        }
        return item;
      })
    } else {
      languagesContents.push(v);
    }
    languagesContents = languagesContents.sort((a, b) => a.languageType - b.languageType);
    console.log(languagesContents, '1231231')
    onChange(languagesContents);
  };

  return (
    <div
      className="langs-editor-wrap"
    >
      <div className='langs-editor-contents'>
        {contents?.length > 2 && showTabs && <EditorTabs
          tabs={contents}
          text={text}
          value={language}
          changeTab={(e) => { changeLanguage(e.target.value) }}
          showTabs={showTabs}
        />}
        <div className="questionsetting-content-line">
          <CTEditor
            currentLanguage={0}
            values={values?.filter(item => item.languageType == 0)?.[0]}
            getEditorValue={getEditorValue}
            showIcon
            messageType={messageType}
            maxLength={maxLength}
            editorConfigs={editorConfigs}
            placeholderType={placeholderType}
          />
        </div>
        {
          contents.length > 1 && <div className="questionsetting-content-line">
            <CTEditor
              currentLanguage={language}
              values={values?.filter(item => item.languageType == language)?.[0]}
              getEditorValue={getEditorValue}
              messageType={messageType}
              maxLength={maxLength}
              editorConfigs={editorConfigs}
              placeholderType={placeholderType}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default Editors;
