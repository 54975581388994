import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('members-form-filter', () => [
		{
			// base
      [`.${prefixCls}`]: {
        '.react-resizable': {
          position: 'relative',
          backgroundClip: 'padding-box',

          '.react-resizable-handle': {
            position: 'absolute',
            width: '2px',
            height: '100%',
            bottom: 0,
            right: '-1px',
            cursor: 'col-resize',
            zIndex: 1,
          },
          '.react-resizable-handle:active': {
            backgroundColor: '#FF6400',
          }
        }
      }
		}
	])
};

export default useStyle;
