// src/TreeDiagram.js
import React from 'react';
import classNames from 'classnames';
import useStyle from './style';
import OrgTree from 'react-org-tree';
import { Avatar, Tooltip, Divider } from '@didi/ec-base';
import ResignSvg from '../../assets/resign.svg';

const getBackGround = (level) => {
  let bg = '', border = '';
  switch (level) {
    case '1':
      bg = 'linear-gradient(190deg, #E8DCFF 0%, #FFFFFF 47%), #FFFFFF';
      border = '1px solid #BD9BFF';
      break;
    case '2':
      bg = 'linear-gradient(190deg, #DFE5FF 0%, #FFFFFF 47%), #FFFFFF';
      border = '1px solid #92A6FF';
      break;
    default:
      bg = 'linear-gradient(190deg, #ECF5FF 0%, #FFFFFF 47%), #FFFFFF';
      border = '1px solid #72B5FF';
      break;
  }

  return {
    bg,
    border
  };
};

// 递归函数来遍历树并构建新的结构
const transformTree = (node) => {
  // 创建新节点的副本，这里我们只复制id，可以根据需要添加更多属性
  const newNode = { ...node };
  // 在这里添加任何其他需要修改或添加的属性
  // 例如，添加一个新属性
  newNode.label = <TreeItem nodeData={node} />;

  // 如果节点有子节点，递归处理每个子节点
  if (node.children && node.children.length > 0) {
    newNode.children = node.children.map(child => transformTree(child));
  } else {
    // 如果没有子节点，确保children属性是一个空数组
    newNode.children = [];
  }

  return newNode;
}

const TreeItem = ({ nodeData }) => {
  return (
    <div
      className="node-container"
      style={{background: getBackGround(nodeData?.relationshipTypeCode)?.bg, border: nodeData.clicked ? getBackGround(nodeData?.relationshipTypeCode)?.border : '1px solid rgba(0, 0, 0, 0.06)'}}
    >
      <div>
        <Tooltip title={<>{nodeData?.name} ({nodeData?.employeeId})<Divider type="vertical" style={{background: '#fff'}} />{nodeData?.positionDesc}</>}>
          <Avatar src={nodeData?.imgUrl} username={<div>{nodeData?.name}<span style={{marginLeft:'4px'}}>({nodeData?.employeeId})</span></div>} dept={nodeData?.positionDesc} className="tree-avatar" />
        </Tooltip>

        {/* 离职标记 */}
        {nodeData?.empStatus === "I" && (
          <div className="item-tag-con">
            <ResignSvg className="card-icon" />
          </div>
        )}
      </div>
    </div>
  )
}

const TreeDiagram = ({ treeData }) => {
  const prefixCls = 'work-relationship-modal-tree-diagram';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      <div style={{display: 'flex'}}>
        {
          treeData?.map(item => {
            const data = transformTree(item);
            if (item?.employeeId) {
              return (
                <div className="complete-tree">
                  <OrgTree
                    data={data}
                    labelClassName=""
                  />
                </div>
              )
            }

            return <div>{item.tips}</div>
          })
        }
      </div>

    </div>
  );
};

export default TreeDiagram;
