import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {
  return useRegisterStyle('pagelet-employee-leave-details', () => [
    {
      // base
      [`.${prefixCls}`]: {
        ['.holiday-history-drawer']: {
          ['.holiday-history-drawer-top']: {
            position: 'sticky',
            top: '0',
          },
          ['.holiday-history-drawer-bottom']: {
            overflow: 'auto',
          },
          ['.name-id']: {
            ['.baseInfo-empname']: {
              fontWeight: 700,
            },
            ['.baseInfo-empid']: {
              marginLeft: '5px',
              fontSize: '12px',
            },
          },
          ['.bar-card']: {
            marginTop: '10px',
            border: '1px solid #eee',
            borderRadius: '5px',
            height: '180px',
            boxShadow: '2px 2px 5px #eee',
            ['.bar-days']: {
              width: '100%',
              height: '180px',
              backgroundImage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leaved-detail-bg-pc.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              color: '#000000',
              position: 'relative',
              ['.center']: {
                width: '100%',
                height: '80px',
                position: 'absolute',
                margin: 'auto',
                top: '0',
                left: '-8px',
                bottom: '0',
                paddingLeft: '26px',
                ['.all-days']: {
                  width: '100%',
                  display: 'inline-block',
                },
                ['.top-10']: {
                  width: '100%',
                  marginTop: ' -10px',
                  ['.days']: {
                    fontSize: '30px',
                    fontWeight: '700',
                  },
                },
              },
            },
          },
          ['.ant-tabs-top > .ant-tabs-nav::before,.ant-tabs-top > div > .ant-tabs-nav::before']: {
            border: 'none',
          },
          ['.to-holiday']: {
            paddingTop: '24px',
            ['.holiday-title']: {
              color: 'rgba(0, 0, 0, 0.72)',
              fontSize: '14',
              marginRight: '24px',
            },
            ['.ant-tabs-tab']: {
              fontWeight: '500',
              color: 'rgba(0, 0, 0, 0.72)',
            },
            ['.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn']: {
              color: 'rgba(0, 0, 0, 0.84)',
            },
            ['.ant-tabs-ink-bar.ant-tabs-ink-bar-animated']: {
              top: '38px',
              height: '4px',
              borderRadius: '4px',
            },
          },
          ['.leave-card']: {
            border: '1px solid #eee',
            borderRadius: '5px',
            position: 'relative',
            overflow: 'hidden',
            marginBottom: '10px',
            ['.top-logo']: {
              height: '20px',
              width: '66px',
              textAlign: 'center',
              lineHeight: '20px',
              position: 'absolute',
              // border-radius: 0 0 5px 5px,
              color: '#fff',
              top: '-1px',
              left: '20px',
              // backgroundimage: url(http://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo.svg),
            },
            ['.icon-bg']: {
              position: 'absolute',
              right: '0px',
              top: '-20px',
              color: '#fef6f2',
              fontSize: '80px',
            },
            ['.icon-bg1']: {
              color: '#fef6f2',
            },
            ['.icon-bg2']: {
              color: '#F1F6FF',
            },
            ['.icon-bg3']: {
              color: '#F0FBF8',
            },
            ['.icon-bg4']: {
              color: ' #FEF4F4',
            },
            ['.icon-bg5']: {
              color: '#FBF7FF',
            },
            [' .icon-bg7']: {
              color: ' #FEF4F4',
            },
            ['.icon-bg8']: {
              color: '#F8F3FF',
            },
            ['.icon-bg9']: {
              color: '#F1F1F1',
            },
            ['.icon-bg10']: {
              color: '#FFF9ED',
            },
            ['.icon-bg13']: {
              color: ' #f5f9ec',
            },
            ['.icon-bg14']: {
              color: '#FAFAD2',
            },
            ['.leave-all-days']: {
              width: '100%',
              marginTop: '32px',
              marginLeft: '40px',
              position: 'relative',
              fontSize: '12px',
              color: '#636363',
              ['.big-days']: {
                fontSize: '24px',
                fontWeight: '700',
                paddingRight: '4px',
              },
              ['.leaved-days']: {
                color: '#000',
              },
              ['.day-font-size']: {
                fontSize: '10px',
              },
              ['.leaved-line-days']: {
                position: 'absolute',
                height: '42px',
                width: '1px',
                left: '80px',
                backgroundColor: '#eee',
              },
              [' .social-security-land']: {
                height: '40px',
                lineHeight: '45px',
              },
            },
            ['.top-logo1']: {
              // #FF7D41,
              backgroundImage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo1.svg)',
            },
            ['.top-logo2']: {
              // #5587F2,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo2.svg)',
            },
            ['.top-logo3']: {
              // #36BF91,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo3.svg)',
            },
            ['.top-logo4']: {
              // #FA7070,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo4.svg)',
            },
            ['.top-logo5']: {
              // #A17EE6,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo5.svg)',
            },
            ['.top-logo7']: {
              // #F57AA3,
              backgroundimage: ' url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo7.svg)',
            },
            ['.top-logo8']: {
              // #A17EE6,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo8.svg)',
            },
            ['.top-logo9']: {
              // #666666,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo9.svg)',
            },
            ['.top-logo10']: {
              // #F2B230,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo10.svg)',
            },
            ['.top-logo13']: {
              // #F2B230,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo13.svg)',
            },
            ['.top-logo14']: {
              // #F2B230,
              backgroundimage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/leave-type-top-logo14.svg)',
            },
            ['.leave-table']: {
              padding: '0 24px',
              marginTop: '8px',
              ['.ant-table-cell-ellipsis']: {
                color: '#636363',
              },
            },
          },
          ['.leave-card-top1']: {
            borderTop: '2px solid #FF7D41',
          },
          ['.leave-card-top2']: {
            borderTop: '2px solid #5587F2',
          },
          ['.leave-card-top3']: {
            borderTop: '2px solid #36BF91',
          },
          ['.leave-card-top4']: {
            borderTop: '2px solid #FA7070',
          },
          ['.leave-card-top5']: {
            borderTop: '2px solid #A17EE6',
          },
          ['.leave-card-top7']: {
            borderTop: '2px solid #F57AA3',
          },
          ['.leave-card-top8']: {
            borderTop: '2px solid #A17EE6',
          },
          ['.leave-card-top9']: {
            borderTop: '2px solid #666666',
          },
          ['.leave-card-top10']: {
            borderTop: '2px solid #F2B230',
          },
          ['.leave-card-top13']: {
            borderTop: '2px solid #90C02D',
          },
          ['.leave-card-top14']: {
            borderTop: '2px solid #F4C056',
          },
        },
      },
    },
  ]);
};

export default useStyle;
