import { Button, ConfigProvider, Tooltip, getLocale, getLang, zh_CN, en_US } from '@didi/ec-base';
import { DHRTable } from '@didi/ec-ehr-base';
import { Intl } from '@didi/pagelets-sdk';
import * as formilyAntd from '@formily/antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  ExportButton,
  FormFilter,
  PeopleSelectWithAuth,
  EmptyRender
} from './components';
import { recordPageFMP } from './utils/performance';
import newRequest from './utils/newRequest';
import { showEllipseWord } from './utils/showEllipseWord';
import PageletApi from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import dayjs from 'dayjs';
const localLocales = manifest.locales || {};

import useStyle from './style';

const { useFormTableQuery, FormPath, createFormActions } = formilyAntd;

const fitteractions = createFormActions();

let startLoadTime;

const Members = (props) => {
  const {
    auth, headers, apiHost, locales, type
  } = props.params;

  const isOutSourcing = type === 'OUTSOURCING'; // 工时制外包
  const authId = auth?.id;
  const newHeaders = { ...headers, 'bricks-function-id': authId };
  const request = newRequest.init({
    ...headers,
    'bricks-function-id': authId
  });
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');

  // 员工性质
  const outSourcingEmployeeNature = [
    {
      label: intl('OUT_HRO'),
      value: 'OUT_HRO'
    },
    {
      label: intl('CTS_HRO'),
      value: 'CTS_HRO'
    },
    {
      label: intl('XINGHUO_HRO'),
      value: 'XINGHUO-HRO'
    },
    {
      label: intl('OS_HRO'),
      value: 'OS_HRO'
    }
  ];
  // 员工类型
  const outSourcingEmployeeType = [
    {
      label: 'HC类外包-组织费用',
      value: 22
    },
    {
      label: 'HC类外包-业务费用',
      value: 23
    }
  ];
  const outSourcingEmployeeNatureMap = {
    'OUT_HRO': intl('OUT_HRO'),
    'CTS_HRO': intl('CTS_HRO'),
    'XINGHUO-HRO': intl('XINGHUO_HRO'),
    'OS_HRO': intl('OS_HRO'),
  }
  const outSourcingEmployeeTypeMap = {
    '22': intl('ORG_EXPENSE'),
    '23': intl('BUSINESS_EXPENSE')
  }
  const columns = [
    {
      title: intl('JOB_NUMBER'),
      dataIndex: 'empId',
      key: 'empId',
      width: 20,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('USERNAME'),
      dataIndex: 'name',
      key: 'name',
      width: 20,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EMPLOYEE_NATURE'),
      dataIndex: 'empClassName',
      key: 'empClassName',
      width: 20,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('EMPLOYEE_TYPE'),
      dataIndex: 'empClassTypeName',
      key: 'empClassTypeName',
      width: 20,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: intl('ENTRY_DATE'),
      dataIndex: 'lastHireDate',
      key: 'lastHireDate',
      width: 20,
      ellipsis: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];
  // 工时制外包
  const fillterList = [
    {
      title: intl('USERNAME_JOB_NUMBER'),
      name: 'empId',
      component: (props) => {
        const url = `${apiHost}/gateway/dhr-workbench/dept/permissions/list?functionId=${authId}`;
        return (
          <PeopleSelectWithAuth
            style={{ width: '100%' }}
            authUrl={url}
            authId={authId}
            {...props}
            hrStatus="A"
            empClass="CTR,INT,CLB,RLB"
            labelInValue
            noCopyableTag
            valueSelectKey="empId"
            headers={newHeaders}
            type="out"
          />
        );
      },
      copyableTag: true,
      onValueChangeHide: true,
    },
    {
      title: intl('EMPLOYEE_NATURE'),
      name: 'empClass',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('EMPLOYEE_TYPE'),
      name: 'empClassType',
      component: 'Select',
      dataSource: [],
      mode: 'multiple',
      optionFilterProp: 'children',
    },
    {
      title: intl('ENTRY_DATE'),
      name: 'lastHireDate',
      component: 'RangePicker',
      autoFocus: true,
      allowEmpty: [true, true],
      startKey: 'lastHireDateBegin',
      endKey: 'lastHireDateEnd',
    },

  ];

  const service = async (data) => {
    const {
      values, pagination,sorter = {},
    } = data;

    const orderTemp = sorter?.order === 'descend' ? 'desc' : 'asc';
    const orderList = [];
    if (sorter && sorter?.field) {
      if (sorter.field === 'empClassTypeName') {
        orderList.push({
          field: "emp_class_type",
          order: sorter?.order ? orderTemp : undefined
        });
      }
      if (sorter.field === 'empClassName') {
        orderList.push({
          field: "emp_class",
          order: sorter?.order ? orderTemp : undefined
        });
      }
      if (sorter.field === 'lastHireDate') {
        orderList.push({
          field: "last_hire_date",
          order: sorter?.order ? orderTemp : undefined
        });
      }
    }
    const sortOutSourcingParams = { orderlist: orderList };
    console.log(sortOutSourcingParams, 'sortOutSourcingParams');
    const paramsValue = FormFilter.dealWithValue(fillterList, values);
    if (paramsValue.keyword) {
      paramsValue.keyword = paramsValue.keyword.key;
    }
    if (paramsValue.empId) {
      paramsValue.empId = paramsValue.empId.key;
    }

    // 请求参数饿类型转换  未在changeArr数组里则不需要转换
    const changeValuesType = {};
    const changeArr = ['empClass','empClassType'];
    Object.keys(paramsValue).map((key) => {
      if (!changeArr.includes(key) || key === 'keyword') {
        changeValuesType[key] = paramsValue[key];
        return;
      }
      changeValuesType[key] = paramsValue[key]?.split(',');
    });
    if (values.empClass?.length === 0) changeValuesType.empClass = [];
    if (values.empClassType?.length === 0) changeValuesType.empClassType = [];
    if (isOutSourcing) {
      changeValuesType.empClass = paramsValue?.empClass ? paramsValue?.empClass.split(',') : [];
      changeValuesType.empClassType = paramsValue?.empClassType ? paramsValue?.empClassType.split(',') : [];
    }

    changeValuesType.lastHireDateBegin = changeValuesType.lastHireDateBegin && changeValuesType.lastHireDateBegin;
    changeValuesType.lastHireDateEnd = changeValuesType.lastHireDateBegin && changeValuesType.lastHireDateEnd;

    let reqData = {
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
      ...changeValuesType,
      ...sortOutSourcingParams
    };
    // 传入页面数据
    const requestParams = {
      method: 'post',
      url: `${apiHost}/gateway/bricks/api/workbench/personal/page/outsourcing/employeesByManager`,
      encryption: true, // 需要加密写
      data: reqData
    };

    if (authId) {
      return request(requestParams).then(({ code, data }) => {
        if (code === '100200' && data) {
          const {
            list = [], pageNum, pageSize, total, pages,
          } = data;
          let tableData = list;
          tableData = tableData?.map((v, i) => {
            if (v.empClass) {
              return {
                ...v,
                empClassName: outSourcingEmployeeNatureMap[v.empClass],
                empClassTypeName: outSourcingEmployeeTypeMap[v.empClassType]
              }
            } else {
              return {
                ...v,
                id: i,
              }
            }
          });
          return {
            dataSource: tableData,
            ...pagination,
            pageNum,
            total,
          };
        }
        return {
          dataSource: [],
          ...pagination,
          total: 0,
        };
      }).finally(() => {
        if (isFirstFetch) {
          recordPageFMP(new Date() - startLoadTime);
          setIsFirstFetch(false);
        }
      });
    }
  };

  const submitResetMiddleware = () => ({ context }) => ({
    onFormResetQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
    onFormSubmitQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
  });

  const matchFieldValueMiddleware = (pattern, value) => ({ context }) => ({
    onFormSubmitQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      if (payload.jobFunctionList?.length <= 0) {
        payload.jobSubFuncList = [];
      }
      return next(payload);
    },
    onFormResetQuery(payload, next) {
      context.setPagination({
        ...context.pagination,
        current: 1,
      });
      return next(payload);
    },
  });

  const { form, table } = useFormTableQuery(
    service,
    [
      submitResetMiddleware(),
      matchFieldValueMiddleware('gender', 'male'),
      ({ actions }) => ({
        onFormInit() {
          actions.setFieldState('empClass', (state) => {
            FormPath.setIn(
              state,
              'props.dataSource',
              outSourcingEmployeeNature
            );
          });
          // 员工类型
          actions.setFieldState('empClassType', (state) => {
            FormPath.setIn(
              state,
              'props.dataSource',
              outSourcingEmployeeType
            );
          });
        },
      }),

    ],
    {
      pagination: {
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
      },
    },
  );

  form.actions = fitteractions;

  const prefixCls = 'pagelet-table-panel';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN}>
      <div className="pagelet-table-panel" style={{ minHeight: '400px', height: '100%', overflowY: 'auto' }}>
        <FormFilter
          form={form}
          filter={fillterList}
          total={table?.pagination?.total}
          intl={intl}
          headers={newHeaders}
        />
        <div className="table-container">
          <div className='table-wrapper'>
            <EmptyRender intl={intl}>
              <DHRTable
                {...table}
                locale={langEn ? en_US : zh_CN}
                // size="small"
                columns={columns}
                rowKey={(record) => record.id}
                showQuickJumper
              />
            </EmptyRender>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Members;
