import { isProdEnv, isTestEnv, isStageEnv, isUatEnv } from '../utils/env';

const getEnvironment = () => {
    if (isProdEnv()) return 'prod';
    if (isTestEnv()) return 'test';
    if (isStageEnv()) return 'stage';
    if (isUatEnv()) return 'uat';
    return 'prod';
}

export const AuthParamsAllEnvironment = {
    test: {
        'home': 682, //我的工作台
        'home': 685, //首页
        'home_my': 686, //个人中心
        'home_ei': 1622, //我的信息
        'MySalary': 1241, //我的工资单
        'my_holiday': 1005, //我的假期
        'home_my_selfservice': 690, //自助服务
        'HRpolicy': 697, //HR政策
        'home_team': 687, //团队管理
        'home_team_transfer_manage': 1384, //调动管理
        'home_team_transfer_manage_approve': 1385, //我的单据
        'home_team_transfer_manage_group': 1386, //团队调动记录
        'hr-workspace': 683, //HR工作台
        'hr-workspace': 694, //首页
        'hr-workspace_ei_staffinfo': 1499, // 员工信息查看
        'on-boarding': 700, //入职管理
        'hr-workspace_on-boarding_entry': 701, //入职申请
        'hr-workspace_on-boarding_list_apply': 708, //发起入职
        'hr-workspace_on-boarding_list_apply_trainee': 1191, //实习生招聘
        'hr-workspace_on-boarding_list_apply_social': 1192, //社会招聘
        'hr-workspace_on-boarding_list_apply_campus': 1101, //校园招聘
        'hr-workspace_on-boarding_list_change': 709, //变更招聘顾问
        'hr-workspace_on-boarding_background-check': 702, //招聘附件上传
        'hr-workspace_on-boarding_background-check_background-information': 710, //上传背调报告
        'hr-workspace_on-boarding_background-check_other-information': 711, //上传其他附件
        'hr-workspace_on-boarding_offer': 703, //OFFER处理
        'hr-workspace_on-boarding_tripartite': 1247, //校招签署办理
        'hr-workspace_on-boarding_procedure': 704, //入职手续办理
        'hr-workspace_on-boarding_special-procedure': 879, //特殊入职办理
        'hr-workspace_on-boarding_report': 863, //入职报表
        'hr-workspace_on-boarding_report_report-key-s': 891, //测试报表
        'hr-workspace_on-boarding_report_entry-detail': 864, //入职待审批明细表
        'hr-workspace_on-boarding_report_entry-handle-info': 865, //入职手续未完成信息表
        'hr-workspace_on-boarding_report_triple-info': 866, //校招三方查询表
        'hr-workspace_on-boarding_report_offer-option': 867, //OFFER期权查询表
        'hr-workspace_on-boarding_report_recruit-offer': 868, //招聘OFFER状态明细表
        'hr-workspace_on-boarding_report_offer-report-cb': 1424, //招聘OFFER明细表-CB
        'hr-workspace_on-boarding_report_entry-handle-log': 886, //入职手续办理操作记录表
        'hr-workspace_on-boarding_difference-report': 1378, //入职申请差异信息表
        'hr-workspace_on-boarding_report_campus-sign': 1437, //入职校招签署办理表
        'hr-workspace_transfer': 1248, //调动管理
        'hr-workspace_transfer_manage': 1249, //调动流程管理
        'hr-workspace_transfer_manage_approve': 1380, //调动流程管理-我的单据
        'hr-workspace_transfer_manage_group':1381, //调动流程管理-团队调动记录
        'hr-workspace_transfer_handle': 1250, //调动手续办理
        'hr-workspace_transfer_batchTransfer': 1769, //批量调动类型管理
        'hr-workspace_transfer_templateStore': 1775, //调动模板库
        'hr-workspace_on-transfer_report': 1479, //调动报表
        'hr-workspace_on-transfer_report_transfer-emp-handle-detail': 1474, //员工调动明细报表
        'hr-workspace_on-transfer_report_transfer-log-info': 1477, //员工调动流程信息变更操作记录表
        'home_holiday': 906, //假期管理
        'hr-workspace_holiday_process-list': 1162, //休假流程监控
        'hr-workspace_holiday_absence_process ': 1212, //休假流程监控-缺勤流程
        'hr-workspace_holiday_substitute_submit': 1231, //休假流程监控-代提流程
        'staff_holiday_query': 1007, //员工休假查询
        'staff_holiday_status': 1008, //员工假期状态管理
        'hr-workspace_holiday_manual-handle': 1408, //管理员休假更正
        'holiday_config': 1003, //假期配置
        'holiday_rule_config': 1004, //假期规则配置
        'holiday_policy_config': 1011, //假期政策配置
        'scope_of_work_plan': 1012, //工作计划适用范围配置
        'staff_work_plan': 1013, //人员工作计划配置
        'deduction_for_sick_holiday': 1014, //病假扣款比例配置
        'hr-workspace_holiday_notice-exclude-list': 1015, //通知提醒排除人列表
        'hr-workspace_holiday_manual-push': 1016, //手动调用接口
        'holiday_quota_management': 1017, //假期限额管理
        'recalculation_of_annual_holiday_quota': 1018, //年假限额重算
        'adjustment_of_annual_holiday_quota': 1019, //年假限额调整
        'adjustment_of_annual_holiday_validity': 1020, //年假有效期调整,
        'hr_workspace_holiday_leave_forecast': 1021, //年假限额预测
        'home_holiday_quota_management': 1022, //探亲假限额管理
        'parental_leave_quota_management': 1831,//育儿假限额管理
        'holiday_report': 1023, //假期报表
        'leave_absence_schedule': 1024, //休假缺勤明细表
        'hr-workspace_holiday_payroll-list': 1387, //月度休假缺勤表（payroll)
        'leave_absence_summary': 1025, //休假缺勤汇总表
        'annual_holiday_quota_query': 1026, //年假限额查询
        'home_holiday_quota_query': 1028, //探亲假余额表
        'parental_leave_details': 1823, //育儿假余额明细查询
        'parental_leave_data_migration': 2659, //育儿假数据迁移表
        'Parent_childs_list': 3111, //育儿假子女信息管理
        'staff_holiday_status_query': 1029, //员工假期状态查询
        'annual_holiday_occupation_process_record': 1030, //年假占用过程记录表
        'split_holiday_record_details': 1031, //休假记录拆分明细表
        'summary_of_annual_and_sick_holiday': 1032, //年病假余额汇总表
        'summary_of_over_annual_and_sick_holiday': 1033, //超休年病假表
        'notification_send_status': 1034, //通知提醒发送状态查询
        'special_employee_holiday_plan': 1127, //特殊人员工作计划查询
        'employee_holiday_plan': 1766, //人员工作计划查询'
        'process': 684, //审批中心
        'process': 698, //我的申请
        'process_approval': 699, //我的审批
        'process_approval_todo': 734, //我的待办
        'process_approval_done': 735, //我的已办
        'system': 731, //系统管理
        'system_process': 1045, //流程中心
        'system_process_reg': 861, //流程注册
        'system_process_handover-rules-reg': 1444, //移交规则注册
        'system_process_approver-group': 1674, //审批组注册
        'system_process_autoApproval': 1710, //超时规则注册
        'system_config': 1046, //配置中心
        'system_electronic_man-hour': 1402, //工时类型配置
        'system_config_applicationsManage': 1809, //应用管理
        'system_config_systemManage': 1810, //系统管理
        'system_electronic': 1388, //电子签中台
        'system_electronic_business-security': 1400, //业务类别配置
        'system_electronic_contract-parameters': 1390, //模版参数配置
        'system_electronic_contract-template': 1401, //合同模版配置
        'system_electronic_contract-end-date': 1389, //结束日期规则
        'system_electronic_electronic-push': 1453, //电子签手工推送
        'system_electronic_preset-verbal-trick': 1454, //预置话术配置
        'hr-workspace_probation_list': 1427, //团队试用期信息
        'hr-workspace_renew_renewal-mgmt': 1358, //续签流程管理
        'hr-workspace_renew_sign-contract': 1363, //续签手续办理
        'hr-workspace_holiday_parentingForecast': 4355, // 育儿假额度批量测算
        //试用期
        'probation_early': 4768,  //提前转正
        'cb_approve':   6203, //C&B序列审批人配置
        'sequence_approver': 6204, //调动序列审批人
        'invalid_sequence': 6205, //调动失效序列
        // 'hr_workspace_on_boarding_apply': 9999, //入职申请-发起申请 TODO 替换值
        // 'hr_workspace_on_boarding_entry': 8888, //入职申请 TODO 替换值

        //离职
        'off_boarding_handover_bench': 1042,//离职管理-离职交接办理
        'off_boarding_sscwork_bench': 1062,//离职管理-离职信息管理
        'off_boarding_send_protocol': 4236, //HR工作台-离职管理-发送协商解除协议
        'off_boarding_special_bench': 6250, //HR工作台-离职管理-特殊离职流程管理

        //合同续签
        'renew_sign_contract': 1363,//续签手续办理
        'renew_sign_contract_detail': 1363,//续签手续办理详情页
        'renew_sign_contract_synchronization': 1364, //同步电子签
        'renew_sign_contract_signed':  1365,//员工已签署
        'renew_sign_company_signed': 1366, //公司已签署
        'renew_sign_offline_signed': 1945,  //线下办理
        'renew_new_contract': 1359, //新发起合同续签',
        'renew_renewal_mgmt_new_agreement': 1360, //'新发起协议续签',

        //入职
        'accept_letter': 1480, //校招签署办理-接收函
        'trip_online': 1481, //校招签署办理-三方网签
        'trip_offline': 1482, //校招签署办理-三方纸签
        'doub_online': 1483, //校招签署办理-两方网签
        'procedure_in': 718,//入职手续办理-已入职
        'procedure_not': 719,//入职手续办理-未到岗
        'procedure_emergency': 720,//入职手续办理-紧急入职
        'procedure_remind':721, //入职手续办理-信息补充提醒
        'procedure_contract': 722,//入职手续办理-同步电子签
        'procedure_sso': 723,//入职手续办理-激活账号
        'procedure_editper': 727,//入职手续办理-编辑个人信息
        'procedure_editjob': 728,//入职手续办理-编辑职务信息
        'procedure_thrid': 729,//入职手续办理-编辑三方信息
        'eec_special': 730,//入职手续办理-EEC专用
        'special_procedure_apply': 892,//特殊入职办理-发起特殊入职
        'special_procedure_sso': 907,//特殊入职办理-生成账号
        'special_procedure_in': 908,//特殊入职办理-已入职
        'special_procedure_editjob': 910,//特殊入职办理-编辑职务信息
        'special_procedure_pr': 911,//特殊入职办理-编辑个人信息
        'special_procedure_jobcard': 912,//特殊入职办理-生成工卡
        'special_procedure_not': 913,//特殊入职办理-未到岗

        // 员工信息
        'ei_applylist_applydetail': 1500,
        // 实习生导师管理-变更确认按钮
        'tutor_list_change': 1628,
        //员工服务
        'service_appointment_approve': 1940, //'HR工作台-员工服务管理-预约服务管理-单据处理-详情'
        'service_appointment_management': 1941,  //'HR工作台-员工服务管理-预约服务管理-工单管理-详情'
        'service_proof_approve': 2382, //'HR工作台-员工服务管理-证明服务管理-单据处理-详情'
        'service_proof_management': 2381, //'HR工作台-员工服务管理-证明服务管理-工单管理-详情'
        'service_borrow_documentProcess': 4441, //'HR工作台-员工服务管理-档案服务管理-单据处理-详情'
        'service_borrow_orderManagement': 4442, //'HR工作台-员工服务管理-档案服务管理-工单管理-详情'
        'service_card_approve': 3539, //'HR工作台-员工服务管理-卡类服务管理-单据处理-详情'
        'service_card_management': 3540, //'HR工作台-员工服务管理-卡类服务管理-工单管理-详情'

        'contractBlacklist':6278  //合同主体黑名单
    },
    stage: {
        'home': 684, //我的工作台
        'home': 689, //首页
        'home_my': 690, //个人中心
        'home_ei': 1398, //我的信息
        'xinchou': 691, //我的工资单
        'my_holiday': 1023, //我的假期
        'home_my_selfservice': 693, //自助服务
        'HRpolicy': 694, //HR政策
        'hr-workspace': 685, //HR工作台
        'hr-workspace': 699, //首页
        'hr-workspace_members': 1020, //团队成员
        'hr-workspace_information': 1399, //员工信息管理
        'hr-workspace_ei_staffinfo': 1400, //员工信息查看
        'hr-workspace_ei_applylist': 1401, //员工信息审批
        'hr-workspace_ei_applyhistory': 1402, //员工信息修改记录
        'hr-workspace_transfer': 1336, //调动管理
        'hr-workspace_transfer_manage': 1337, //调动流程管理
        'hr-workspace_transfer_manage_approve': 1341, //调动流程管理-我的单据
        'hr-workspace_transfer_manage_group':1342, //调动流程管理-团队调动记录
        'hr-workspace_transfer_handle': 1338, //调动手续办理
        'hr-workspace_on-transfer_report': 1339, //调动报表
        'hr-workspace_on-transfer_report_transfer-emp-handle-detail': 1350, //员工调动明细报表
        'hr-workspace_on-transfer_report_transfer-log-info': 1353, //员工调动流程信息变更操作记录表
        'holiday': 1024, //假期管理
        'hr-workspace_holiday_process-list': 1025, //休假流程监控
        'hr-workspace_holiday_absence_process': 1032, //休假流程监控-缺勤流程
        'hr-workspace_holiday_substitute_submit': 1033, //休假流程监控-代提流程
        'staff_holiday_query': 1034, //员工休假查询
        'staff_holiday_status': 1035, //员工假期状态管理
        'hr-workspace_holiday_manual-handle': 1245, //管理员休假更正
        'holiday_config': 1036, //假期配置
        'holiday_rule_config': 1037, //假期规则配置
        'holiday_policy_config': 1038, //假期政策配置
        'scope_of_work_plan': 1039, //工作计划适用范围配置
        'staff_work_plan': 1040, //人员工作计划配置
        'deduction_for_sick_holiday': 1041, //病假扣款比例配置
        'hr-workspace_holiday_notice-exclude-list': 1042, //通知提醒排除人列表
        'hr-workspace_holiday_manual-push': 1043, //手动调用接口
        'holiday_quota_management': 1044, //假期限额管理
        'parental_leave_quota_management': 1685,//育儿假限额管理
        'recalculation_of_annual_holiday_quota': 1045, //年假限额重算
        'adjustment_of_annual_holiday_quota': 1046, //年假限额调整
        'adjustment_of_annual_holiday_validity': 1047, //年假有效期调整,
        'hr_workspace_holiday_leave_forecast': 1048, //年假限额预测
        'home_holiday_quota_management': 1049, //探亲假限额管理
        'holiday_report': 1050, //假期报表
        'leave_absence_schedule': 1051, //休假缺勤明细表
        'hr-workspace_holiday_payroll-list': 1171, //月度休假缺勤表（payroll)
        'leave_absence_summary': 1052, //休假缺勤汇总表
        'annual_holiday_quota_query': 1053, //年假限额查询
        'home_holiday_quota_query': 1054, //探亲假余额表
        'parental_leave_details': 1684, //育儿假余额明细查询
        'parental_leave_data_migration': 1811, //育儿假数据迁移表
        'Parent_childs_list': 2037, //育儿假子女信息管理
        'staff_holiday_status_query': 1055, //员工假期状态查询
        'annual_holiday_occupation_process_record': 1056, //年假占用过程记录表
        'split_holiday_record_details': 1057, //休假记录拆分明细表
        'summary_of_annual_and_sick_holiday': 1058, //年病假余额汇总表
        'summary_of_over_annual_and_sick_holiday': 1059, //超休年病假表
        'notification_send_status': 1061, //通知提醒发送状态查询
        'special_employee_holiday_plan': 1060, //特殊人员工作计划查询
        'employee_holiday_plan': 1507, //人员工作计划查询'
        'process': 687, //审批中心
        'process_apply': 705, //我的申请
        'process_approval': 706, //我的审批
        'process_approval_todo': 707, //我的待办
        'process_approval_done': 708, //我的已办
        'system': 1062, //系统管理
        'system_process': 1064, //流程中心
        'system_process_reg': 750, //流程注册
        'system_process_handover-rules-reg': 1244, //移交规则注册
        'system_process_approver-group': 1405, //审批组注册
        'system_process_autoApproval': 1481, //超时规则注册
        'system_electronic': 1328, //电子签中台
        'system_electronic_business-security': 1329, //业务类别配置
        'system_electronic_contract-parameters': 1330, //模版参数配置
        'system_electronic_contract-template': 1331, //合同模版配置
        'system_electronic_man-hour': 1332, //工时类型配置
        'system_electronic_contract-end-date': 1333, //结束日期规则
        'system_electronic_electronic-push': 1334, //电子签手工推送
        'system_electronic_preset-verbal-trick': 1334, //预置话术配置
        'hr-workspace_probation_list': 1259, //团队试用期信息
        'hr-workspace_renew_renewal-mgmt': 1227, //续签流程管理
        'hr-workspace_renew_sign-contract': 1228, //续签手续办理
        'hr-workspace_holiday_parentingForecast': 2307, // 育儿假额度批量测算
        'hr-workspace_on-boarding_procedure': 718, //入职手续办理
        'hr-workspace_on-boarding_special-procedure': 736, //特殊入职办理

        //试用期
        'probation_early': 2400,   //提前转正
        'cb_approve':   2711, //C&B序列审批人配置
        'sequence_approver': 2712, //调动序列审批人
        'invalid_sequence': 2713, //调动失效序列

        //离职
        'off_boarding_handover_bench': 984,//离职管理-离职交接办理
        'off_boarding_sscwork_bench': 982,//离职管理-离职信息管理
        'off_boarding_send_protocol': 2292, //HR工作台-离职管理-发送协商解除协议
        'off_boarding_special_bench': 2753, //HR工作台-离职管理-特殊离职流程管理

        //合同续签
        'renew_sign_contract': 1228,//续签手续办理
        'renew_sign_contract_detail': 1228,//续签手续办理详情页
        'renew_sign_contract_synchronization': 1235, //同步电子签
        'renew_sign_contract_signed':  1236,//员工已签署
        'renew_sign_company_signed': 1237, //公司已签署
        'renew_sign_offline_signed': 1686,  //线下办理
        'renew_new_contract': 1231, //新发起合同续签',
        'renew_renewal_mgmt_new_agreement': 1232, //'新发起协议续签',

        //入职
        'accept_letter': 1261, //校招签署办理-接收函
        'trip_online': 1262, //校招签署办理-三方网签
        'trip_offline': 1263, //校招签署办理-三方纸签
        'doub_online': 1264, //校招签署办理-两方网签
        'procedure_in': 724,//入职手续办理-已入职
        'procedure_not': 725,//入职手续办理-未到岗
        'procedure_emergency': 726,//入职手续办理-紧急入职
        'procedure_remind':727, //入职手续办理-信息补充提醒
        'procedure_contract': 728,//入职手续办理-同步电子签
        'procedure_sso': 729,//入职手续办理-激活账号
        'procedure_editper': 733,//入职手续办理-编辑个人信息
        'procedure_editjob': 734,//入职手续办理-编辑职务信息
        'procedure_thrid': 735,//入职手续办理-编辑三方信息
        'eec_special': 848,//入职手续办理-EEC专用
        'special_procedure_apply': 870,//特殊入职办理-发起特殊入职
        'special_procedure_sso': 871,//特殊入职办理-生成账号
        'special_procedure_in': 872,//特殊入职办理-已入职
        'special_procedure_editjob': 873,//特殊入职办理-编辑职务信息
        'special_procedure_pr': 874,//特殊入职办理-编辑个人信息
        'special_procedure_jobcard': 875,//特殊入职办理-生成工卡
        'special_procedure_not': 876,//特殊入职办理-未到岗

        //员工服务
        'service_appointment_approve': 1739, //'HR工作台-员工服务管理-预约服务管理-单据处理-详情'
        'service_appointment_management': 1740,  //'HR工作台-员工服务管理-预约服务管理-工单管理-详情'
        'service_proof_approve': 1800, //'HR工作台-员工服务管理-证明服务管理-单据处理-详情'
        'service_proof_management': 1797, //'HR工作台-员工服务管理-证明服务管理-工单管理-详情'
        'service_borrow_documentProcess': 2397, //'HR工作台-员工服务管理-档案服务管理-单据处理-详情'
        'service_borrow_orderManagement': 2398, //'HR工作台-员工服务管理-档案服务管理-工单管理-详情'
        'service_card_approve': 2100, //'HR工作台-员工服务管理-卡类服务管理-单据处理-详情'
        'service_card_management': 2101, //'HR工作台-员工服务管理-卡类服务管理-工单管理-详情'



        'contractBlacklist':2747,  //合同主体黑名单
        // 员工信息
        'ei_applylist_applydetail': 1401,
        // 实习生导师管理-变更确认按钮
        'tutor_list_change': 1407,

    },
    prod: {
        'home': 417, //我的工作台
        'home': 418, //首页
        'home_my': 419, //个人中心
        'home_team': 424, //团队管理
        'hr-workspace': 428, //HR工作台
        'hr-workspace_ei_staffinfo': 1024, // 员工信息查看
        'hr-workspace_transfer': 1004, //调动管理
        'hr-workspace_transfer_manage': 986, //调动流程管理
        'hr-workspace_transfer_manage_approve': 990, //调动流程管理-我的单据
        'hr-workspace_transfer_manage_group':991, //调动流程管理-团队调动记录
        'hr-workspace_transfer_handle': 987, //调动手续办理
        'hr-workspace_on-transfer_report': 988, //调动报表
        'holiday': 641, //假期管理
        'hr-workspace_holiday_process-list': 642, //休假流程监控
        'hr-workspace_holiday_absence_process': 649, //休假流程监控-缺勤流程
        'hr-workspace_holiday_substitute_submit': 650, //休假流程监控-代提流程
        'staff_holiday_query': 651, //员工休假查询
        'staff_holiday_status': 652, //员工假期状态管理
        'hr-workspace_holiday_manual-handle': 774, //管理员休假更正
        'holiday_config': 653, //假期配置
        'holiday_rule_config': 654, //假期规则配置
        'holiday_policy_config': 655, //假期政策配置
        'scope_of_work_plan': 656, //工作计划适用范围配置
        'staff_work_plan': 657, //人员工作计划配置
        'deduction_for_sick_holiday': 658, //病假扣款比例配置
        'hr-workspace_holiday_notice-exclude-list': 659, //通知提醒排除人列表
        'hr-workspace_holiday_manual-push': 660, //手动调用接口
        'holiday_quota_management': 661, //假期限额管理
        'recalculation_of_annual_holiday_quota': 662, //年假限额重算
        'adjustment_of_annual_holiday_quota': 663, //年假限额调整
        'adjustment_of_annual_holiday_validity': 692, //年假有效期调整,
        'hr_workspace_holiday_leave_forecast': 693, //年假限额预测
        'home_holiday_quota_management': 694, //探亲假限额管理
        'parental_leave_quota_management': 1366,//育儿假限额管理
        'holiday_report': 695, //假期报表
        'leave_absence_schedule': 696, //休假缺勤明细表
        'hr-workspace_holiday_payroll-list': 739, //月度休假缺勤表（payroll)
        'leave_absence_summary': 697, //休假缺勤汇总表
        'annual_holiday_quota_query': 698, //年假限额查询
        'home_holiday_quota_query': 699, //探亲假余额表
        'parental_leave_details': 1367, //育儿假余额明细查询
        'parental_leave_data_migration': 1365, //育儿假数据迁移表
        'Parent_childs_list': 1480, //育儿假子女信息管理
        'staff_holiday_status_query': 700, //员工假期状态查询
        'annual_holiday_occupation_process_record': 701, //年假占用过程记录表
        'split_holiday_record_details': 702, //休假记录拆分明细表
        'summary_of_annual_and_sick_holiday': 703, //年病假余额汇总表
        'summary_of_over_annual_and_sick_holiday': 704, //超休年病假表
        'notification_send_status': 706, //通知提醒发送状态查询
        'special_employee_holiday_plan': 705, //特殊人员工作计划查询
        'employee_holiday_plan': 1094, //人员工作计划查询
        'process': 432, //审批中心
        'process_apply': 433, //我的申请
        'process_approval': 434, //我的审批
        'process_approval_todo': 435, //我的待办
        'process_approval_done': 436, //我的已办
        'system': 618, //系统管理
        'system_process': 620, //流程中心
        'system_process_reg': 437, //流程注册
        'system_process_handover-rules-reg': 909, //移交规则注册
        'system_process_approver-group': 952, //审批组注册
        'system_process_autoApproval': 1055, //超时规则注册
        'system_electronic': 928, //电子签中台
        'system_electronic_business-security': 929, //业务类别配置
        'system_electronic_contract-parameters': 930, //模版参数配置
        'system_electronic_contract-template': 931, //合同模版配置
        'system_electronic_man-hour': 932, //工时类型配置
        'system_electronic_contract-end-date': 933, //结束日期规则
        'system_electronic_electronic-push': 934, //电子签手工推送
        'system_electronic_preset-verbal-trick': 935, //预置话术配置
        'hr-workspace_probation_list': 924, //团队试用期信息
        'hr-workspace_renew_renewal-mgmt': 769, //续签流程管理
        'hr-workspace_renew_sign-contract': 770, //续签手续办理
        'hr-workspace_holiday_parentingForecast': 1858, // 育儿假额度批量测算
        'hr-workspace_on-boarding_procedure': 452, //入职手续办理
        'hr-workspace_on-boarding_special-procedure': 535, //特殊入职办理

        //试用期
        'probation_early': 2144,  //提前转正
        'cb_approve': 2181, //C&B序列审批人配置
        'sequence_approver': 2182, //调动序列审批人
        'invalid_sequence': 2183, //调动失效序列

        //离职
        'off_boarding_handover_bench': 667,//离职管理-离职交接办理
        'off_boarding_sscwork_bench': 666,//离职管理-离职信息管理
        'off_boarding_send_protocol': 1708, //HR工作台-离职管理-发送协商解除协议
        'off_boarding_special_bench': 2214, //HR工作台-离职管理-特殊离职流程管理

        //合同续签
        'renew_sign_contract': 770,//续签手续办理
        'renew_sign_contract_detail': 770,//续签手续办理详情页
        'renew_sign_contract_synchronization': 797, //同步电子签
        'renew_sign_contract_signed':  798,//员工已签署
        'renew_sign_company_signed': 799, //公司已签署
        'renew_sign_offline_signed': 1214,  //线下办理
        'renew_new_contract': 773, //新发起合同续签',
        'renew_renewal_mgmt_new_agreement': 794, //'新发起协议续签',

        //入职
        'accept_letter': 829, //校招签署办理-接收函
        'trip_online': 830, //校招签署办理-三方网签
        'trip_offline': 831, //校招签署办理-三方纸签
        'doub_online': 832, //校招签署办理-两方网签
        'procedure_in': 453,//入职手续办理-已入职
        'procedure_not': 454,//入职手续办理-未到岗
        'procedure_emergency': 455,//入职手续办理-紧急入职
        'procedure_remind':456, //入职手续办理-信息补充提醒
        'procedure_contract': 457,//入职手续办理-同步电子签
        'procedure_sso': 458,//入职手续办理-激活账号
        'procedure_editper': 462,//入职手续办理-编辑个人信息
        'procedure_editjob': 463,//入职手续办理-编辑职务信息
        'procedure_thrid': 464,//入职手续办理-编辑三方信息
        'eec_special': 465,//入职手续办理-EEC专用
        'special_procedure_apply': 536,//特殊入职办理-发起特殊入职
        'special_procedure_sso': 537,//特殊入职办理-生成账号
        'special_procedure_in': 538,//特殊入职办理-已入职
        'special_procedure_editjob': 539,//特殊入职办理-编辑职务信息
        'special_procedure_pr': 540,//特殊入职办理-编辑个人信息
        'special_procedure_jobcard': 541,//特殊入职办理-生成工卡
        'special_procedure_not': 542,//特殊入职办理-未到岗

        //员工服务
        'service_appointment_approve': 1302, //'HR工作台-员工服务管理-预约服务管理-单据处理-详情'
        'service_appointment_management': 1303,  //'HR工作台-员工服务管理-预约服务管理-工单管理-详情'
        'service_proof_approve': 1354, //'HR工作台-员工服务管理-证明服务管理-单据处理-详情'
        'service_proof_management': 1353, //'HR工作台-员工服务管理-证明服务管理-工单管理-详情'
        'service_borrow_documentProcess': 2187, //'HR工作台-员工服务管理-档案服务管理-单据处理-详情'
        'service_borrow_orderManagement': 2188, //'HR工作台-员工服务管理-档案服务管理-工单管理-详情'
        'service_card_approve': 1517, //'HR工作台-员工服务管理-卡类服务管理-单据处理-详情'
        'service_card_management': 1518, //'HR工作台-员工服务管理-卡类服务管理-工单管理-详情'

        'contractBlacklist':2212,  //合同主体黑名单
        // 员工信息
        'ei_applylist_applydetail': 1025,
        // 实习生导师管理-变更确认按钮
        'tutor_list_change': 1017,
    },
    uat: {
        'home': 682, //我的工作台
        'home': 685, //首页
        'home_my': 686, //个人中心
        'home_ei': 1622, //我的信息
        'MySalary': 1241, //我的工资单
        'my_holiday': 1005, //我的假期
        'home_my_selfservice': 690, //自助服务
        'HRpolicy': 697, //HR政策
        'home_team': 687, //团队管理
        'home_team_transfer_manage': 1384, //调动管理
        'home_team_transfer_manage_approve': 1385, //我的单据
        'home_team_transfer_manage_group': 1386, //团队调动记录
        'hr-workspace': 683, //HR工作台
        'hr-workspace': 694, //首页
        'on-boarding': 700, //入职管理
        'hr-workspace_on-boarding_entry': 701, //入职申请
        'hr-workspace_on-boarding_list_apply': 708, //发起入职
        'hr-workspace_on-boarding_list_apply_trainee': 1191, //实习生招聘
        'hr-workspace_on-boarding_list_apply_social': 1192, //社会招聘
        'hr-workspace_on-boarding_list_apply_campus': 1101, //校园招聘
        'hr-workspace_on-boarding_list_change': 709, //变更招聘顾问
        'hr-workspace_on-boarding_background-check': 702, //招聘附件上传
        'hr-workspace_on-boarding_background-check_background-information': 710, //上传背调报告
        'hr-workspace_on-boarding_background-check_other-information': 711, //上传其他附件
        'hr-workspace_on-boarding_offer': 703, //OFFER处理
        'hr-workspace_on-boarding_tripartite': 1247, //校招签署办理
        'hr-workspace_on-boarding_procedure': 704, //入职手续办理
        'hr-workspace_on-boarding_special-procedure': 879, //特殊入职办理
        'hr-workspace_on-boarding_report': 863, //入职报表
        'hr-workspace_on-boarding_report_report-key-s': 891, //测试报表
        'hr-workspace_on-boarding_report_entry-detail': 864, //入职待审批明细表
        'hr-workspace_on-boarding_report_entry-handle-info': 865, //入职手续未完成信息表
        'hr-workspace_on-boarding_report_triple-info': 866, //校招三方查询表
        'hr-workspace_on-boarding_report_offer-option': 867, //OFFER期权查询表
        'hr-workspace_on-boarding_report_recruit-offer': 868, //招聘OFFER状态明细表
        'hr-workspace_on-boarding_report_offer-report-cb': 1424, //招聘OFFER明细表-CB
        'hr-workspace_on-boarding_report_entry-handle-log': 886, //入职手续办理操作记录表
        'hr-workspace_on-boarding_difference-report': 1378, //入职申请差异信息表
        'hr-workspace_on-boarding_report_campus-sign': 1437, //入职校招签署办理表
        'hr-workspace_transfer': 1248, //调动管理
        'hr-workspace_transfer_manage': 1249, //调动流程管理
        'hr-workspace_transfer_handle': 1250, //调动手续办理
        'hr-workspace_transfer_batchTransfer': 1769, //批量调动类型管理
        'hr-workspace_transfer_templateStore': 1775, //调动模板库
        'hr-workspace_on-transfer_report': 1479, //调动报表
        'hr-workspace_on-transfer_report_transfer-emp-handle-detail': 1474, //员工调动明细报表
        'hr-workspace_on-transfer_report_transfer-log-info': 1477, //员工调动流程信息变更操作记录表
        'home_holiday': 906, //假期管理
        'hr-workspace_holiday_process-list': 1162, //休假流程监控
        'hr-workspace_holiday_absence_process ': 1212, //休假流程监控-缺勤流程
        'hr-workspace_holiday_substitute_submit': 1231, //休假流程监控-代提流程
        'staff_holiday_query': 1007, //员工休假查询
        'staff_holiday_status': 1008, //员工假期状态管理
        'hr-workspace_holiday_manual-handle': 1408, //管理员休假更正
        'holiday_config': 1003, //假期配置
        'holiday_rule_config': 1004, //假期规则配置
        'holiday_policy_config': 1011, //假期政策配置
        'scope_of_work_plan': 1012, //工作计划适用范围配置
        'staff_work_plan': 1013, //人员工作计划配置
        'deduction_for_sick_holiday': 1014, //病假扣款比例配置
        'hr-workspace_holiday_notice-exclude-list': 1015, //通知提醒排除人列表
        'hr-workspace_holiday_manual-push': 1016, //手动调用接口
        'holiday_quota_management': 1017, //假期限额管理
        'recalculation_of_annual_holiday_quota': 1018, //年假限额重算
        'adjustment_of_annual_holiday_quota': 1019, //年假限额调整
        'adjustment_of_annual_holiday_validity': 1020, //年假有效期调整,
        'hr_workspace_holiday_leave_forecast': 1021, //年假限额预测
        'home_holiday_quota_management': 1022, //探亲假限额管理
        'parental_leave_quota_management': 1831,//育儿假限额管理
        'holiday_report': 1023, //假期报表
        'leave_absence_schedule': 1024, //休假缺勤明细表
        'hr-workspace_holiday_payroll-list': 1387, //月度休假缺勤表（payroll)
        'leave_absence_summary': 1025, //休假缺勤汇总表
        'annual_holiday_quota_query': 1026, //年假限额查询
        'home_holiday_quota_query': 1028, //探亲假余额表
        'parental_leave_details': 1823, //育儿假余额明细查询
        'parental_leave_data_migration': 1935, //育儿假数据迁移表
        'Parent_childs_list': 1943, //育儿假子女信息管理
        'staff_holiday_status_query': 1029, //员工假期状态查询
        'annual_holiday_occupation_process_record': 1030, //年假占用过程记录表
        'split_holiday_record_details': 1031, //休假记录拆分明细表
        'summary_of_annual_and_sick_holiday': 1032, //年病假余额汇总表
        'summary_of_over_annual_and_sick_holiday': 1033, //超休年病假表
        'notification_send_status': 1034, //通知提醒发送状态查询
        'special_employee_holiday_plan': 1127, //特殊人员工作计划查询
        'employee_holiday_plan': 1766, //人员工作计划查询'
        'process': 684, //审批中心
        'process': 698, //我的申请
        'process_approval': 699, //我的审批
        'process_approval_todo': 734, //我的待办
        'process_approval_done': 735, //我的已办
        'system': 731, //系统管理
        'system_process': 1045, //流程中心
        'system_process_reg': 861, //流程注册
        'system_process_handover-rules-reg': 1444, //移交规则注册
        'system_process_approver-group': 1674, //审批组注册
        'system_process_autoApproval': 1710, //超时规则注册
        'system_config': 1046, //配置中心
        'system_electronic_man-hour': 1402, //工时类型配置
        'system_config_applicationsManage': 1809, //应用管理
        'system_config_systemManage': 1810, //系统管理
        'system_electronic': 1388, //电子签中台
        'system_electronic_business-security': 1400, //业务类别配置
        'system_electronic_contract-parameters': 1390, //模版参数配置
        'system_electronic_contract-template': 1401, //合同模版配置
        'system_electronic_contract-end-date': 1389, //结束日期规则
        'system_electronic_electronic-push': 1453, //电子签手工推送
        'system_electronic_preset-verbal-trick': 1454, //预置话术配置
        'hr-workspace_probation_list': 1427, //团队试用期信息
        'hr-workspace_renew_renewal-mgmt': 1358, //续签流程管理
        'hr-workspace_renew_sign-contract': 1363, //续签手续办理
        'hr-workspace_holiday_parentingForecast': 4355, // 育儿假额度批量测算

        //试用期
        'probation_early': 4768,  //提前转正
        'cb_approve':   6203, //C&B序列审批人配置
        'sequence_approver': 6204, //调动序列审批人
        'invalid_sequence': 6205, //调动失效序列
        
        //合同续签
        'renew_sign_contract': 1363,//续签手续办理
        'renew_sign_contract_detail': 1363,//续签手续办理详情页
        'renew_sign_contract_synchronization': 1364, //同步电子签
        'renew_sign_contract_signed':  1365,//员工已签署
        'renew_sign_company_signed': 1366, //公司已签署
        'renew_sign_offline_signed': 1945,  //线下办理
        // 'renew_new_contract': , //新发起合同续签',
        // 'renew_renewal_mgmt_new_agreement': , //'新发起协议续签',

        'contractBlacklist':6278  //合同主体黑名单
    },
}

const AuthParams = AuthParamsAllEnvironment[getEnvironment()] || {};

export default AuthParams;