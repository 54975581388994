import React, { useEffect, useState } from 'react';
import { Button, Spin, Select, Segmented } from '@didi/ec-base';
import lodash from 'lodash';
import DepartmentSelection from "../DepartmentSelection/index.jsx";
import DynamicList from "../DynamicList/index.jsx";
import PageletServices from "../../services/index.js";
import { empTypeList, NAMES, TYPE_CODE_ENUM, typeList} from "../../constant.js";
import BottomArrow from '../../assets/bottom_arrow.svg';
import XiaLaArrow from '../../assets/xiala.svg';
import PeopleSelect from '../PeopleSelectWithAuth/PeopleSelect.jsx';
import ErrorArrow from '../../assets/error-circle-fill.svg';
import { DownThickOutlined, ErrorCircleFilled } from '@didi/iconflow/ec';

function getMaxUnitString(num) {
  if (num >= 100000000) {
    return `${(num / 100000000).toFixed(2)} 亿`;
  } else if (num >= 1000000) {
    return `${(num / 1000000).toFixed(2)} 百万`;
  } else if (num >= 10000) {
    return `${(num / 10000).toFixed(2)} 万`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(2)} 千`;
  } else {
    return `${num}`;
  }
}

function DynamicBody(props) {
  const {
    typeCodes,
    type,
    auth,
    item,
    deptCodes,
    deptInfos,
    prefixCls,
    apiHost,
    pageCode,
    systemList,
    permissionScope,
    pageSize = 10,
    subNum,
    onChange = () => { },
    showDetail = () => { },
    headers = {}
  } = props;
  const [preQuery, setPreQuery] = useState(null);
  const [initError, setInitError] = useState(false);
  const [initing, setIniting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [typeCode, setTypeCode] = useState('1');
  const [curDeptCode, setCurDeptCode] = useState([]);
  const [description, setDescription] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [list, setList] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [systemCode, setSystemCode] = useState('all');
  const [empLdap, setEmpLdap] = useState('');
  const [empValue, setEmpValue] = useState(null);
  const [clear, setClear] = useState(false);
  const [empType, setEmpType] = useState({});
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    if (typeCodes && item?.cardHeight === 1) {
      setPageNum(1);
      setTypeCode(typeCodes);
      setList([]);
    }
  }, [typeCodes])

  const initData = (query) => {
    if (!query.deptCodes[0]) {
      return;
    }
    const params = {
      "deptCodes": query.deptCodes,
      "pageNum": 1,
      "pageSize": pageSize,
      "pageType": pageCode,
      "subType": TYPE_CODE_ENUM.ALL,
      "subNum": query.subNum,
      "systemCode": query?.systemCode === 'all' ? '' : query?.systemCode,
      "empLdap": query?.empLdap,
      "empType": query?.empType?.value,
    };
    if (lodash.isEqual(query, preQuery)) {
      return
    }
    setPreQuery(query);
    setIniting(true);
    PageletServices.getAllList(apiHost, params).then((res) => {
      setList([]);
      if (res?.code === '100200' && res?.data) {
        setInitError(false);
        setIniting(false);
        const allDesc = res.data.typeInfoList || [];
        const desc = {};
        let initTypeCode
        allDesc.forEach((item) => {
          const dynamicInfo = item.dynamicInfo || {};
          // 如果total为0, 则过滤
          if (item.subCode !== TYPE_CODE_ENUM.EVENT && item.subName !== NAMES[TYPE_CODE_ENUM.EVENT]) {
            desc[item.subCode] = {
              subCode: item.subCode,
              subName: item.subName,
              list: dynamicInfo.list || [],
              hasNextPage: dynamicInfo.hasNextPage || false,
              total: Number(dynamicInfo.total) ?? 0,
              totalText: getMaxUnitString(Number(dynamicInfo.total) ?? 0)
            };
          }
        });
        setDescription(desc);
        setList(desc[typeCode]?.list)
        setPageNum(1);
        const hasNextPage = desc[typeCode]?.hasNextPage;
        setHasNextPage(hasNextPage);
      } else {
        console.log('卡片初始化错误, 展示兜底卡片!!!')
        setInitError(true);
        setIniting(false);
      }
    }, (error) => {
      console.log('卡片初始化错误, 展示兜底卡片!!!', error)
      setInitError(true);
      setIniting(false);
    }).finally(() => {
      setClear(false);
    });
  };

  const loadNext = (query, finallyCB) => {
    setFetching(true);
    PageletServices.getListByType(apiHost, query).then((res) => {
      if (res?.code === '100200' && res?.data) {
        const desc = Object.assign({}, description);
        const curList = desc[query.subType].list || []
        const resDesc = res.data
        const dynamicInfo = resDesc.dynamicInfo || {};
        desc[resDesc.subCode] = {
          subCode: resDesc.subCode,
          subName: resDesc.subName,
          list: curList.concat(dynamicInfo.list || []),
          hasNextPage: dynamicInfo.hasNextPage || false,
          total: Number(dynamicInfo.total) ?? 0,
          totalText: getMaxUnitString(Number(dynamicInfo.total) ?? 0),
        }

        setDescription(desc);
        setPageNum(query.pageNum);
        const list = desc[query.subType]?.list;
        setList(list);
        const hasNextPage = desc[typeCode]?.hasNextPage;
        setHasNextPage(hasNextPage);
        finallyCB();
      } else {
        console.log('加载下一页失败!!!')
        finallyCB();
      }
    }, (error) => {
      console.log('加载下一页失败!!!', error)
      finallyCB();
    });
  };

  // const onTypeCodeChange = () => {
  //   const list = description[typeCode]?.list;
  //   setList(list);
  //   const hasNextPage = description[typeCode]?.hasNextPage;
  //   setHasNextPage(hasNextPage);
  // }

  const onChangeType = (value) => {
    if (typeCode !== value) {
      setPageNum(1);
      setTypeCode(value);
      setList([]);
      setSystemCode('all');
      setEmpValue(null);
      setEmpLdap('');
    }
  };

  // type, curDeptCode, deptCodes, pageNum, pageSize, pageType, description, subType, fetching, hasNextPage
  const onListScroll = (query) => () => {
    if (!fetching && query.hasNextPage) {
      // 加载下一页
      const params = {
        "deptCodes": query.deptCodes,
        "pageNum": query.pageNum + 1,
        "pageSize": query.pageSize,
        "pageType": query.pageCode,
        "subType": query.typeCode,
        "subNum": query.subNum,
        "systemCode": query.systemCode === 'all' ? '' : query?.systemCode,
        "empLdap": query?.empLdap,
        "empType": query?.empType?.value,
      };
      loadNext(params, () => {
        // 失败回调
        setFetching(false);
      })
    }
  };
  const onScopeChange = (scope) => {
    onChange(scope);
    setSystemCode('all');
    setEmpValue(null);
    setEmpLdap('');
  };

  const onDeptChange = (value, item) => {
    const selectedDeptCodes = value?.length && value[0] ? value : deptCodes;
    setCurDeptCode(selectedDeptCodes);
    setSystemCode('all');
    setEmpValue(null);
    setEmpLdap('');
    if (item) {
      setClear(true);
    } else {
      setClear(false)
    }

  };

  useEffect(() => {
    if (!subNum) {
      return;
    }
    const query = {
      deptCodes,
      subNum,
      typeCode,
      systemCode,
      empLdap,
      empType
    };
    if (type === 'drawer' && curDeptCode.length > 0) {
      query.deptCodes = curDeptCode;
    }
    if (type === 'drawer' && curDeptCode?.length > 0 && clear) {
      query.clear = clear;
    }
    if (deptCodes?.length > 0 && !lodash.isEqual(query, preQuery)) {
      setDescription({});
      setPageNum(1);
      setList([]);
      setHasNextPage(false);
      initData(query);
    }
  }, [type, curDeptCode, deptCodes, preQuery, subNum, typeCode, systemCode, empLdap, clear, empType]);
  return (
    <div
      className="dynamic-list"
      style={{
        height: `${item?.cardHeight === 1 ? 'calc(100% - 44px)' : 'calc(100% - 44px)'}`,
        marginTop: `${item?.cardHeight === 1 ? '16px' : ''}`
      }}
    >
      {
        item?.cardHeight !== 1 && <div className={`${prefixCls}-filter`}>
          {
            type === 'drawer' ? (
              <div className={`${prefixCls}-filter-item`} >
                <DepartmentSelection apiHost={apiHost} auth={auth} defaultDeptCode={deptCodes} defaultDeptInfo={deptInfos} onDeptChange={onDeptChange} headers={headers} />
              </div>
            ) : null
          }
          <div className={`${prefixCls}-filter-item`}>
            <Segmented
              value={typeCode}
              options={typeList}
              size="small"
              onChange={(value) => {
                onChangeType(value); // string
              }}
            />
            <div className='switch-dynamic'>
              {permissionScope?.length > 0 && <Select
                getPopupContainer={node => node.parentNode}
                suffixIcon={<DownThickOutlined />}
                value={subNum || 2}
                onChange={onScopeChange}
              >
                {
                  permissionScope.map((item) => {
                    return (
                      <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>}
            </div>
          </div>


        </div>
      }
      {
        type === 'drawer' && typeCode === '1' && <div className={`${prefixCls}-filter-warp`}>
          <div className="filter-warp-left" >
            {
              systemList.map((v) => {
                return <div className={`${v.code === systemCode ? 'system-active' : 'system'}`} onClick={() => {
                  setSystemCode(v?.code);
                }}>
                  {v?.name}
                </div>
              })
            }
          </div>
          <div className="filter-warp-right">
            <div className='form-filter-empType'>
              <div className='form-filter-empType-warp' onClick={() => {
                setvisible(true);
              }}>
                <span className='span'>
                  人员性质
                  {empType?.value ? ' : ' : null}
                  <span>{empType?.label}</span>
                </span>
              </div>
              <span className={"form-filter-icon" + (empType?.value ? ' no-clear' : '')} >
                <div className="form-filter-down" onClick={() => {
                  setvisible(true);
                }}>
                  <DownThickOutlined />
                </div>
                <div className="form-filter-clear" onClick={() => {
                  setEmpType({});
                  setvisible(false);
                }}>
                  <ErrorCircleFilled />
                </div>
              </span>
              <div
                className={"form-filter-dropdown " + (visible ? 'form-filter-dropdown-show ' : '')}
                onMouseLeave={() => {
                  setvisible(false);
                }}
              >
                <Select
                  placeholder="请选择"
                  labelInValue
                  allowClear={false}
                  value={empType}
                  open={visible}
                  onSelect={(value) => {
                    setEmpType(value);
                    setvisible(false);
                  }}
                  options={empTypeList}
                />
              </div>
            </div>
            <PeopleSelect
              headers={headers}
              authId={auth?.id}
              style={{ width: "200px" }}
              placeholder="输入姓名/工号/邮箱前缀查询"
              // labelInValue
              listHeight={150}
              empClass="CTR,RLB,CLB,PLB,CST,INT"
              hrStatus=""
              empValue={empValue}
              allowClear={true}
              onChange={(value) => {
                setEmpValue(value);
                setEmpLdap(value);
              }}
            />
          </div>
        </div>
      }
      <div
        style={{
          height: `${item?.cardHeight === 1 ? '100%' : 'calc(100% - 48px)'}`,
        }}
      >
        <Spin spinning={initing}>
          {
            preQuery && initError && !initing && (
              <div className={`${prefixCls}-content-err`}>
                <img
                  src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
                  style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }}
                />
                <span className='text-item-1'>服务不可用，请稍候再试。</span>
              </div>
            )
          }
          {preQuery && list?.length > 0 && (
            <DynamicList
              prefixCls={prefixCls}
              pageSize={pageSize}
              list={list}
              watchObj={{
                type,
                curDeptCode,
                deptCodes,
                pageNum,
                pageSize,
                pageCode,
                description,
                typeCode,
                fetching,
                hasNextPage
              }}
              onListScroll={
                // 抽屉内滚动取curDeptCode
                onListScroll({
                  deptCodes: type === 'drawer' && curDeptCode ? curDeptCode : deptCodes,
                  pageNum,
                  pageSize,
                  pageCode,
                  description,
                  typeCode,
                  fetching,
                  hasNextPage,
                  subNum,
                  systemCode,
                  empLdap,
                  empType
                })
              }
              showDetail={showDetail}
              type={type}
            />
          )}
          {list?.length === 0 && !initError && !initing && (
            <div className={`${prefixCls}-content-null`}>
              <img
                src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/no_data_image.png'
                style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }}
              />
              <span className='text-item-1'>暂无数据</span>
              {/* <span className='text-item-2'>记得多关注大家，要劳逸结合哦～</span> */}
            </div>
          )
          }
        </Spin>
      </div>

    </div>
  )
}

export default DynamicBody;
