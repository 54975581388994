import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 亮点关注
 * url: /api/archive/medal
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */

export const getDiDiMedal = (apiHost, headers, params) => request(`${apiHost}/gateway/bricks${apis.getDiDiMedal}`,
  {
    method: 'get',
    headers,
    params,
  }
);
