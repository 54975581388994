import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-work-relationship', () => [
		{
			// base
			[`.${prefixCls}`]: {
        minHeight: '100px',
			}
		}
	]);
};

export default useStyle;
