import React, { useState, useEffect } from 'react';
import { Rate } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { di18n } from '@ultra/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Must from '../../images/must.svg';
import './index.scss';
import {
  setGeneralData,
  setValidateResult,
  updateAttributeData,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject,
  setPrevOptionIds,
  setCurOptionIds
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const { intl } = di18n;

const rateWidget = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    hiddenGroup,
    validateFlag,
    colorMap,
    blueColorMap,
    currentRespondentCode,
    generalMultiData,
    surveyBusType
  } = useSelector((state) => state.questionContent);

  const dispatch = useDispatch();
  const disabled = false;

  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    direction,
    effectOption,
    options,
    hasSerialNumber
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];
  const otherRules = rules.otherRules;
  const effect = effectData[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;

  const [radioValue, setRadioValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [hoverFlag, setHoverFlag] = useState(false);
  const [actived, setActived] = useState(0);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData && draftData[widgetKey] && options) {
      const widgetDraftData = draftData[widgetKey];
      const draftItem = widgetDraftData[currentRespondentCode];

      if (draftItem.length > 0) {
        const radio = draftItem[0].option;
        setRadioValue(radio);
        options.map((item, i) => {
          if (item.key === radio) {
            setActived(i + 1);
          }
        });
        if (effectOption.length > 0) {
          effectFun(radio);
        }
        // 初始化赋值&校验
        setValueAndValidate(draftItem);
      }
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (radioValue) {
        setRadioValue(null);
        setActived(0)
      }
    }
  }, [hidden, initFlag, radioValue])

  const setValueAndValidate = (paramData) => {
    const { option, value } = paramData[0];
    //dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: respondentsGroup[0].respondentCode, data: paramData }));
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: option ? paramData: [] }));
    dispatch(updateCurrentSubject({ data:widgetKey }));
    // 数据校验
    const widgetValidate = validateFun(rules, paramData);
    const validateData = Object.assign({}, widgetValidate);
    dispatch(setValidateResult({ id: widgetKey, data: validateData }));
  }


  // 显隐逻辑处理
  const effectFun = (option) => {
    let effectAction = []
    for (let i in effect) {
      effect[i].map((item, j) => {
        if (!effectAction.includes(item.effectId)) {
          effectAction = [...effectAction, item];
        }
      })
    }
    if (effectOption.includes(option)) {
      let effectIdArr = []
      effect[option].map((item, i) => {
        const { hidden, effectId } = item;
        effectIdArr = [...effectIdArr, effectId];
        const data = {
          hidden: hidden,
          currentRespondentCode: currentRespondentCode
        }
        dispatch(updateAttributeData({ id: effectId, data: data }));
      })
      effectAction.forEach(effItem => {
        if (!effectIdArr.includes(effItem.effectId)) {
          const data = {
            hidden: !effItem.hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effItem.effectId, data: data }));
        }
      })
    } else {
      for (let j in effectOption) {
        effect[effectOption[j]].map((item, i) => {
          const { hidden, effectId } = item;
          const data = {
            hidden: !hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effectId, data: data }));
        })
      }
    }
  }

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const { max, min, required } = rule;
    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + min + ' ' + intl.t('个选项，最多选择') + ' ' + max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ]

    if (max && max !== 0) {
      if (content.length > max) {
        return {
          error: false,
          msg: other ? msgArr[1] : msgArr[0]
        }
      }
    }

    if (min && min !== 0) {
      if (content.length < min) {
        return {
          error: false,
          msg: other ? msgArr[1] : msgArr[0]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[1]
        }
      }
      if(content.length === 1 && !content[0].option){
        return {
          error: false,
          msg: msgArr[1]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const renderRate = () => {
    return (
      <div style={{ paddingTop: '4px', display: 'inline-block' }}>
        <Rate
          value={actived}
          character={renderStyle}
          onHoverChange={rateOnhoverChange}
          onChange={rateOnChange}
          count={options.length}
        />
        <div className="rate-value">
          <div style={{ width: '50%' }}>{options[0].des}</div>
          <div>{options[options.length - 1].des}</div>
          {/* <div style={{ width: '50%' }}>非常满意</div>
          <div>非常不满意</div> */}
        </div>
      </div>
    )
  }

  const renderStyle = ({ index }) => {
    return (
      <>
        {
          (hoverFlag && index < hoverFlag) || (actived > 0 && index < actived)
            ? <div
                className="rate-base"
                style={{
                  backgroundColor: surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                  ? `${blueColorMap[index]}`
                  : `${colorMap[index]}`
                }}
              >
                {options[index].value}
              </div>
            : <div className="rate-base">{options[index].value}</div>
        }
      </>
    )
  }

  const rateOnhoverChange = (number) => {
    if (number) {
      setHoverFlag(number);
    } else {
      setHoverFlag(false);
    }
  }

  const rateOnChange = (number) => {
    let option = options?.[number - 1]?.key;

    // 记录上次选择的id
    const previewOptionList = [];
    if (radioValue) {
      previewOptionList.push(radioValue);
      dispatch(setPrevOptionIds({ data: previewOptionList }));
    }

    // 记录本次选择的id
    const curOptionList = [];
    curOptionList.push(option);
    dispatch(setCurOptionIds({ data: curOptionList }));

    setRadioValue(option);
    setActived(number);
    const data = [
      {
        option: option,
        value: ''
      }
    ]
    setUpdateData(data);
    if (effectOption.length > 0) {
      effectFun(option);
    }
  }


  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden}`}
        style={{ position: 'relative', paddingTop: '14px' }}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={`div-label ${related}`}
        >
          <div className={`textarea-div-label-${required}-${requiredIcon}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div style={{ wordBreak: 'keep-all', display: 'inline-block' }}>{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-content radio-content-related-${related}`} >
          {tips ? (
            <div className="pc-question-tip">{decodeURIComponent(tips)}</div>
          ) : null}
          {renderRate()}
        </div>
        {renderErrorMsg()}
      </div>
    </>
  );
};

export default rateWidget