
import React, { useState, useEffect } from 'react';
import SlidingPanel from '../../components/SlidingPanel';
import { di18n, CardIcon } from '@ultra/common';
import { getDrawLogin } from "../../utils/Tool";
import { Input, Button, Modal } from 'antd';
import './index.scss';
const { intl } = di18n;

export default function LotteryRedirectsPC(props) {
  const [hide, setHide] = useState(false);
  const [pageType, setPageType] = useState('PC');
  const [inputValue, setInputValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [expiredPage, setExpiredPage] = useState(false); // 是否过期页面
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const activityId = urlParams.get('id');

  // 获取设备类型
  function getDeviceType() {
    const userAgent = window.navigator.userAgent;
    if (/Mobi|Android/i.test(userAgent)) {
      return "H5"; // 移动设备
    } else {
      return "PC"; // PC 端
    }
  };

  // 验证token
  function validateToken() {
    if (!token) {
      return false;
    }

    try {
      const payload = JSON.parse(atob(token));
      console.log('payload', payload, '111', new Date().getTime())
      if (Date.now() > payload.exp) {
        setExpiredPage(true);
      }
      return Date.now() < payload.exp;
    } catch (error) {
      return false;
    }
  };

  const getDrawLoginFn = async (ldap, activityId) => {
    try {
      const res = await getDrawLogin(ldap, activityId);
      const { data } = res;
      window.location.href = data;
    } catch (error) {
      window.console.log(error);
    }
  };

  const handleOpenImg = () => {
    if (pageType === 'PC') {
      setHide(true);
    } else {
      setIsVisible(!isVisible);
    }
  };

  const getJumpFn = async () => {
    try {
      // const res = await getJump();
      window.location.href = url;
    } catch (error) {
      window.console.log(error);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setInputValue(value?.trim());
  };

  const handleBtn = () => {
    getDrawLoginFn(inputValue, JSON.parse(atob(activityId)));
  };

  useEffect(() => {
    const deviceType = getDeviceType();
    setPageType(deviceType);
    validateToken();
  }, []);

  return (
    <div className="lottery-warpper pageType">
      <div className='warpper-head'>
        {
          expiredPage ?
          <div>
              <div>
                <span>{intl.t('该页面已失效，请点击')}</span>
                <a href={`${window.location.origin}/q/${JSON.parse(atob(activityId))}`}>{`${window.location.origin}/q/${JSON.parse(atob(activityId))}`}</a>
                <span>{intl.t('链接重新抽奖')}</span>
              </div>
          </div>
            :
          <div className='center'>
            <div className='center-top'>
              <span className='top-left'>{intl.t('邮箱前缀（滴滴账号）：')}</span>
              <span onClick={handleOpenImg}  className='top-right'>{intl.t('什么是滴滴账号？')}</span>
            </div>
            <div className='input-content'>
              <Input
                onChange={handleInput}
                placeholder={intl.t('请输入')}
                style={{ height: '100%', borderRadius: '8px' }}
              />
            </div>
            <div className='btn-content'>
              <Button disabled={!inputValue.length} onClick={handleBtn} className={`login-btn ${!inputValue.length && 'disabled'}`} type='primary' >{intl.t('登录抽奖')}</Button>
            </div>
            <div className='center-bot'>
              <span>
                <CardIcon className='tan-icon' type='icontishi1' />
              </span>
              <span>
                {intl.t('请输入本人的邮箱前缀（滴滴账号），若账号输入错误则无法进行星云抽奖。请优先选择手机抽奖，体验更好。')}
              </span>
            </div>
          </div>
        }
      </div>
      <SlidingPanel isOpen={isVisible} onClose={() => setIsVisible(!isVisible)} >
        <div className='sliding-content'>
          <div className='sliding-content-head'>{intl.t('滴滴账号示例')}</div>
          <div className='sliding-content-center'>
            <div className='center-img'></div>
          </div>
          <div className='sliding-content-back'>
            <Button className='back-btn' onClick={() => setIsVisible(!isVisible)} >{intl.t('返回')}</Button>
          </div>
          <div onClick={() => setIsVisible(!isVisible)} className='sliding-content-close'>
            <CardIcon type='iconclosebeifen' />
          </div>
        </div>
      </SlidingPanel>
      <Modal
        footer={null}
        visible={hide}
        onCancel={() => setHide(false)}
      >
        <div className='modal-img' />
      </Modal>
    </div>
  );
};
