import React from 'react';
import { TreeSelect, Spin, Tooltip } from 'antd';
import { textEllipsisFormate } from '@/utils';

function LoadingTreeSelect(props) {
  const treeTitleRender = nodeData => {
    return (
      <Tooltip placement="topLeft" title={nodeData?.name}>
        {nodeData?.name?.length ? textEllipsisFormate(nodeData?.name, 30) : nodeData?.name}
      </Tooltip>
    );
  };
  return (
    <Spin spinning={props?.loading} indicator={<div />}>
      <TreeSelect {...props} treeTitleRender={treeTitleRender} />
    </Spin>
  );
}

export default LoadingTreeSelect;
