// 下拉数据
export const items = {
  'DIRECT_STAFF_CHILD': { key: 'DIRECT_STAFF_CHILD', label: '直属下级' },
  'DIRECT_AND_NEXT_STAFF_CHILD': { key: 'DIRECT_AND_NEXT_STAFF_CHILD', label: '直属+隔级下级' },
  'ALL_STAFF_CHILD': { key: 'ALL_STAFF_CHILD', label: '全部成员' },
};

// 枚举文字
export const enumText = {
  'DIRECT_STAFF_CHILD': '直属下级',
  'DIRECT_AND_NEXT_STAFF_CHILD': '直属+隔级下级',
  'ALL_STAFF_CHILD': '全部成员',
};
