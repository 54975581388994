import manifest from '../../manifest.json';
import { getLocale } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import cloneDeep from 'lodash/cloneDeep';


const langLocales = manifest.locales || {};
const locale = getLocale(langLocales);

export const LangTranslate = (originTxt, param) => {
  const intl = Intl(locale);
  let newTxt = originTxt;
  try {
    newTxt = intl(originTxt, param) || originTxt;
  } catch (error) {
    console.log('LangTranslate error originTxt:', originTxt);
    console.log('LangTranslate error detail', error);
  }

  return newTxt;
};
export const ScrollInToViewBySelector = (selector = '', option = false) => {
  setTimeout(() => {
    const curDom = document.querySelector(selector);
    curDom?.scrollIntoView?.(option || { behavior: 'smooth', block: 'center', inline: 'nearest' });
  }, 50);
  return;
};
// 超出显示省略号
export const textEllipsisFormate = (val, num) => {
  return val?.length > num ? val?.substring?.(0, num) + '...' : val;
};
let lastTimestamp = 0;
export function generateUniqueId() {
  const timestamp = Date.now();
  if (timestamp > lastTimestamp) {
    lastTimestamp = timestamp;
  } else {
    lastTimestamp++;
  }
  const increment = lastTimestamp - timestamp + 1;
  return `${timestamp}${increment}`;
}
export function insertElement(originArr, originLoc, newLoc) {
  const arr = cloneDeep(originArr)
  // 检查originLoc和newLoc是否在数组的有效范围内
  if (originLoc < 0 || originLoc >= arr.length || newLoc < 0 || newLoc > arr.length) {
    throw new Error('Invalid index');
  }

  // 取出originLoc位置的元素
  const element = arr.splice(originLoc, 1)[0];

  // 在newLoc位置插入该元素
  arr.splice(newLoc, 0, element);

  return arr;
}
