import React, { useEffect, useRef, useState } from 'react';
import DynamicItem from "../DynamicItem/index.jsx";

function DynamicList(props) {
  const { prefixCls, list, watchObj, onListScroll, showDetail, type } = props;
  const contentRef = useRef(null);
  const scrollHandler = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.clientHeight - target.scrollTop < 300) {
      onListScroll(target);
    }
  };
  useEffect(() => {
    contentRef.current?.addEventListener('scroll', scrollHandler);
    return () => {
      contentRef.current?.removeEventListener('scroll', scrollHandler);
    }
  }, [...watchObj]);

  return (
    <div className={`${prefixCls}-content`} ref={contentRef}>
      {
        list.map(item => (
          <DynamicItem prefixCls={prefixCls} type={type} info={item} showDetail={showDetail} key={item.id}/>
        ))
      }
      <div className='loading-tip'>
        {
          watchObj.fetching ? '加载中...' : !watchObj.hasNextPage ? '-- 已经是最后一项了 --' : ''
        }
      </div>
    </div>
  )
}

export default DynamicList;
