import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  getRecentSearch: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getRecentSearch}`, {
      method: 'post',
      data: body
    });
  },
  onDeptSelect: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.onDeptSelect}`, {
      method: 'post',
      data: body
    });
  },
  delRecentSearchFunc: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.delRecentSearchFunc}`, {
      method: 'post',
      data: body
    });
  },
  getDataFunc: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getDataFunc}`, {
      method: 'get',
      data: body
    });
  },
  getAuthDeptManagement: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getAuthDeptManagement}?functionId=${body.functionId}`, {
      method: 'get',
      data: body
    });
  },
  getAuthDeptOther: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getAuthDeptOther}?functionId=${body.functionId}`, {
      method: 'get',
      data: body
    });
  },

  getAnniversaryList: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getAnniversaryList}`, {
      method: 'post',
      data: body
    });
  },
  getRegularWorkerList: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getRegularWorkerList}`, {
      method: 'post',
      data: body
    });
  },
  getBirthdayList: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('care_summary');

    return await request(`${host}/${apis.getBirthdayList}`, {
      method: 'post',
      data: body
    });
  },

  // // get请求示例
  // getRequestData: async (host, params) => {
  //   const result = await request(`${host}/${apis.getList}`, {
  //     method: 'get',
  //     params
  //   });
  //
  //   return result;
  // },
  //
  // // post请求示例
  // getRequestData: async (host, data) => {
  //   const result = await request(`${host}/${apis.getList}`, {
  //     method: 'post',
  //     data,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  //
  //   return result;
  // }
}

export default PageletServices;
