import React from 'react';
import { connect, mapReadPretty } from '@formily/react';
import { Input, Tooltip } from 'antd';
import './index.less';
const CustomReadPrettyInput = connect(
  Input.TextArea,
  mapReadPretty(({ value }) => (
    <Tooltip placement="topLeft" title={value} getPopupContainer={e => e.parentElement}>
      <div
        id="label-system-pagelet-baseinfo-panel-readPretty-textarea"
        className="label-system-pagelet-baseinfo-panel-readPretty-textarea"
      >
        {value || '-'}
      </div>
    </Tooltip>
  )),
);
export default CustomReadPrettyInput;
