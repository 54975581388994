import React from "react";
import end from '../../images/end.svg';
import './index.scss'
const NotProgress = (props) => {
  const { text } = props;
  return (
    <div className="ct-component-not-progress">
      {
        <>
          <img className="icon " src={end} alt="" />
          <div>{text}</div>
        </>
      }
    </div>
  );
};

export default NotProgress;
