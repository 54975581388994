import React, { useContext } from 'react';
import BarChart from '../BarChart';
import PersonList from '../PersonList';
import NoData from '../NoData';
import { PublicStore } from '../../../../utils/utils';
import classNames from 'classnames';
import useStyle from './styles';

const ResultArea = () => {
  // 样式
  const prefixCls = 'result-area';
  const wrapSSR = useStyle('result-area');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore } = useContext(PublicStore);
  const { searchName, totalNum, list } = stateStore;

  return wrapSSR(
    <div className={classes}>
      {
        totalNum === 0 && list.length === 0 ? <>
          <NoData text={searchName ? `没有找到${searchName}的数据` : '暂无数据'} style={{ paddingTop: '73px' }} />
        </> : <>
          <BarChart />
          <PersonList />
        </>
      }
    </div>
  );
};

export default ResultArea;
