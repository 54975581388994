import React, { useState, useEffect } from "react";
import { di18n } from '@ultra/common';
import { Modal, List, NavBar, Switch, Drawer, Icon } from 'antd-mobile';
import { cloneDeep } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import request from '../../utils/request';
import { getRefuseList } from '../../utils/Tool';
import './index.scss';

const { intl } = di18n;
const { alert } = Modal;
const H5RefuseComment = props => {
  const { reasonType, activityId, refuseTypeCode, refuseQuestionnaireId } = props.match.params;
  const [open, setOpen] = useState(false);
  const [ unEditData, setUnEditData] = useState(0) // 统计不可编辑列数目
  const [activeData, setActiveData] = useState(null);
  const [refuseList, setRefuseList] = useState([]);
  const [reasonList, setReasonList] = useState([]);

  const statusEnum = {
    0: intl.t('待评价'),
    1: intl.t('已拒评'),
    2: intl.t('已评价')
  };

  // 原接口：/api/anymous/encode
  const getKey = data => request('/ctAnonymity/api/answer/anymous/encode', {
    method: 'POST',
    data
  });

  // 保存提交执行函数
  const handleOk = async () => {
    // set 数据
    const respondentCodeStr = [];
    let rejects = cloneDeep(refuseList).filter(item => {
      if (item.recycleStatus === 0 && item.status === 1) {
        respondentCodeStr.push(item?.respondentCode);
        return item;
      }
      return
    });
    rejects = rejects.map(item => {
      ['recycleStatus', 'recycleStatusName', 'rejectionDis', 'status', 'statusDis', 'respondentName', 'rejectionReasonRemark'].forEach(it => {
        delete item[it];
      });
      return item;
    });

    // 未修改直接返回
    if (!rejects?.length) {
      location.href = location.origin + `/questionnaire/navigation/h5/${activityId}`;
      return
    };
    const recycleParticipantCode = await getKey({
      encodeText: activityId, busType: 1, respondentCodeStr: respondentCodeStr.join(','), respondentTypeCode: refuseTypeCode
    });
    if (recycleParticipantCode?.code !== '100200') {
      return;
    }
    const data = {
      activityId,
      questionnaireId: refuseQuestionnaireId,
      recycleParticipantCode: recycleParticipantCode?.data,
      rejects,
      respondentTypeCode: refuseTypeCode
    }
    await batchAddRejects(data)
    location.href = location.origin + `/questionnaire/navigation/h5/${activityId}`;

  };

  // 取消执行
  const handleCancel = () => {
    props.history.push(`/navigation/h5/${activityId}`);
  };

  // 提交
  // 原接口：/api/refusal/batchAddRejects
  const batchAddRejects = data => request('/ctAnswer/api/answer/refusal/batchAddRejects', {
    method: 'POST',
    data
  });

  // 获取人员数据
  // 原接口：/api/activity/participant/getRejectReason
  const getReason = reasonTypeParam => request('/ctActivity/api/answer/activity/participant/getRejectReason', {
    method: 'GET',
    params: {
      reasonType: reasonTypeParam
    },
  });

  // 转换数据
  const handleFn = res => {
    const data = cloneDeep(res)
    let unEditDataNum = unEditData
    const _data = data.map((item, index) => {
      item.status = item.recycleStatus === 1 ? 1 : 0; // 缓存拒绝状态操作
      item.recycleStatusName = statusEnum[item.recycleStatus]
      item.statusDis = false; // 拒绝状态disabled操作
      item.rejectionReasonCode = item.rejectionReasonCode || undefined; // 拒绝原因
      item.rejectionReasonDescr = item.rejectionReasonDescr || undefined; // 拒绝原因
      item.rejectionDis = true; // 拒绝disabled原因
      item.index = index;
      if (item.recycleStatus !== 0) {
        item.statusDis = true;
        item.rejectionDis = true;
        unEditDataNum += 1
      }
      return item
    })
    setUnEditData(unEditDataNum)
    setRefuseList(_data)
  };

  const handleRejection = e => {
    const data = cloneDeep(refuseList);
    data[activeData?.index].rejectionReasonCode = e.value;
    data[activeData?.index].rejectionReasonDescr = e.label;
    setRefuseList(data);
    setOpen(false);
  };

  const sidebar = () => {
    return (
      <>
        <div className="ct-refuse-drawer-header"><span>{intl.t('请选择拒绝原因')}</span><Icon type="cross" onClick={() => { onOpenChange() }} /></div>
        <List className="ct-refuse-drawer-content">
          {reasonList.map((i, index) => {
            return (<List.Item
              key={index}
              onClick={() => handleRejection(i)}
              className={`${activeData?.rejectionReasonCode === i.value ? 'ct-refuse-drawer-content-active' : ''}`}
            >
              {intl.t(i.label)}
              {activeData?.rejectionReasonCode === i.value && <Icon type="check" />}
            </List.Item>);
          })}
        </List>
      </>
    )
  };

  const onOpenChange = () => {
    setOpen(!open);
  };

  const openFn = data => {
    setActiveData(data);
    setOpen(true);
  };

  const handleStatus = (e, index) => {
    const data = cloneDeep(refuseList);
    data[index].status = e ? 1 : 0;
    data[index].rejectionReasonCode = e ? reasonList[0].code : undefined;
    data[index].rejectionReasonDescr = e ? reasonList[0].label : '';
    data[index].rejectionDis = !e;
    setRefuseList(data);
  };

  useEffect(async () => {
    const participantCodeData = await getKey({
      encodeText: activityId, busType: 5
    });
    // 获取原因list
    const reason = await getReason(reasonType);
    if (reason.code === '100200') {
      setReasonList(reason.data.map(item => {
        item.value = item.code
        return item
      }));
    }
    // 获取数据list
    const res = await getRefuseList({
      activityId,
      participantCode: participantCodeData.data,
      participantTypeCode: refuseTypeCode
    });
    if (res.code === '100200' && res.data) {
      handleFn(res.data);
    }
  }, []);

  return (
    <>
      {/* h5不做蒙层 按跳转处理 */}

      <div style={{
        width: '100%',
      }}>
        <NavBar
          mode="light"
          className="ct-refuse-comment-h5-navbar"
          leftContent={<span style={{ fontSize: '15px' }} onClick={() => { handleCancel() }}>{intl.t('取消')}</span>}
          rightContent={<span style={{ fontSize: '15px', color: unEditData === refuseList?.length ? '#ccc' : '#4D9AFF'}} onClick={() => {
            if(unEditData === refuseList?.length) return
            alert('', <div style={{
              margin: '15px 25px 9px'
            }}>{intl.t('拒评后不可恢复，确认提交？')}</div>, [
              { text: intl.t('取消'), onPress: () => console.log('cancel') },
              {
                text: <span style={{
                  fontWeight: 600
                }}>{intl.t('确定')}</span>, onPress: () => {handleOk()}
              },
            ])
          }}>{intl.t('确定')}</span>}
        ><span style={{ fontSize: '18px' }}>{intl.t('拒评管理')}</span>
        </NavBar>
        {/* {//{if (totalList?.length > completeNum) } */}
        <Drawer
          className="my-drawer"
          contentStyle={{ paddingTop: 45 }}
          sidebarStyle={{
            borderRadius: '20px 20px 0 0',
            maxHeight: '300px',
            background: '#fff',
            zIndex: 11
          }}
          sidebar={sidebar()}
          open={open}
          overlayStyle={{ zIndex: '10' }}
          onOpenChange={onOpenChange}
          position="bottom"
        >
          <div style={{ height: '7px', backgroundColor: '#f5f5f9' }} ></div>
          <div style={{
            padding: '16px 24px',
            background: '#fff'
          }}>
            {
              refuseList.map((item, index) => {
                const { respondentName } = item;
                let name, ldap;
                if (reasonType === 'ORGANIZATION') {
                  name = respondentName;
                } else {
                  name = respondentName.substring(0, respondentName.indexOf('（')) || '-';
                  ldap = respondentName.substring(respondentName.indexOf('（') + 1, respondentName.length - 1) || '-';
                };
                return (
                  <div className="ct-refuse-comment-h5-list" key={item.respondentCode}>
                    <ul>
                      <li>{reasonType !== 'ORGANIZATION' &&
                        <div className="ct-questionaire-h5-avatars-div">
                          <img src={item.avatar || 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/ct/avatar.png'}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = "//img-hxy021.didistatic.com/static/ehr_static/imgs/ct/avatar.png"
                            }} />
                        </div>}
                      </li>
                      <li className={reasonType === 'ORGANIZATION' ? 'refuse-name-organization' : 'refuse-name-individual'}>
                        <div>{name || '-'}</div>
                        {ldap ? <span>{ldap}</span> : ''}
                      </li>
                      <li className={`refuse-status-commit${item.recycleStatus}`}>{intl.t(item.recycleStatusName) || '-'}</li>
                      <li>
                        <Switch disabled={item.statusDis} checked={item.status === 1} onChange={e => handleStatus(e, index)} color="#FF7E41" />
                      </li>
                    </ul>
                    {
                      !!item.rejectionReasonCode && <div className="ct-refuse-comment-h5-popover">
                        <span className="ct-refuse-comment-h5-popover-icon"></span>
                        <div className="ct-refuse-comment-h5-select" style={{ color: item.rejectionDis ? '#999' : 'rgba(0, 0, 0, 0.6)' }} onClick={() => { !item.rejectionDis && openFn(item) }}>
                          <div>{intl.t(item.rejectionReasonDescr)}</div>{!item.rejectionDis && <EditOutlined />}
                        </div>
                      </div>
                    }
                  </div>
                )
              })
            }
          </div>
        </Drawer>
      </div >
    </>
  );
};

export default H5RefuseComment;
