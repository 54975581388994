import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import Radium from "radium";
// eslint-disable-next-line import/no-extraneous-dependencies
import classnames from "classnames";
import { di18n } from "@ultra/common";
import "antd-mobile/dist/antd-mobile.css";
import { weakNetwork } from "weak-network";
import { getCookie } from "./utils/Tool";
import AssessedQuestionnaire from "./pages/AssessedQuestionnaire";
import QuestionnaireContinueHomeH5 from "./pages/QuestionnaireContinueHomeH5";
import AssessedQuestionnaireH5 from "./pages/AssessedQuestionnaireH5";
import QuestionnaireList from "./pages/QuestionnaireList";
import H5RefuseComment from "./pages/H5RefuseComment";
import Home from "./pages/Home";
import Navigations from "./pages/Navigations";
import Notstarted from "./pages/Notstarted";
import NoPermission from "./pages/NoPermission";
import SuccessPage from "./pages/SuccessPage";
import ExceptionPage from "./pages/ExceptionPage";
import NewEmployee from "./pages/NewEmployee";
import WeekNetwork from "./pages/WeekNetwork";
import NotFoundPage from './pages/NotFound';
import store from "./store";
import AssessedQuestionnairePreview from "./pages/AssessedQuestionnairePreview";
import AssessedQuestionnaireH5Preview from "./pages/AssessedQuestionnaireH5Preview";
import ScollerDemo from "./pages/scollerDemo";
import LotteryRedirectsPC from "./pages/LotteryRedirectsPC";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "#FF7E41",
  },
});

const styles = {
  root: {
    height: "100vh",
  },
};

const { Di18nProvider } = di18n;

const App = withRouter(() => (
  <Di18nProvider ns="ct-questionnaire-di18n" env="test">
    <div className="flex">
      <main className={classnames("flex", "w-full")}>
        <Switch>
          <Route
            key="noPermission"
            path="/no-permission"
            component={NoPermission}
            exact
          />
          {/* 进入页面跳转 */}
          <Route
            key="home"
            path="/:source/:activityId"
            component={Home}
            exact
          />
          {/* 合并问卷导航页面 */}
          <Route
            key="navigation"
            path="/navigation/:source/:activityId"
            component={Navigations}
            exact
          />
          <Route
            key="navigationPreview"
            path="/navigation/preview/:source/:activityId/:participantWorkNo"
            component={Navigations}
            exact
          />

          {/* 单人问卷导航 只存在H5 */}
          <Route
            key="h5SyncNavigation"
            path="/h5/navigation/:activityId"
            component={QuestionnaireContinueHomeH5}
            exact
          />
          <Route
            key="AssessedQuestionnaire"
            path="/pc/:activityId/:questionnaireId/:respondentCode/:env/:index"
            component={AssessedQuestionnaire}
            exact
          />
          {/* <Route key="AssessedQuestionnaireView" path="/pc/:activityId/:questionnaireId/:layoutId/:sceneId/:env/:view" component={AssessedQuestionnaire} exact /> */}
          <Route
            key="AssessedQuestionnaireH5"
            path="/h5/:activityId/:questionnaireId/:respondentCode/:index"
            component={AssessedQuestionnaireH5}
            exact
          />
          <Route key="success" path="/success" component={SuccessPage} exact />
          <Route
            key="QuestionnaireList"
            path="/questionnaireList/myActivity/:sceneId"
            component={QuestionnaireList}
            exact
          />
          <Route
            key="H5RefuseComment"
            path="/h5RefuseComment/:activityId/:refuseTypeCode/:reasonType/:refuseQuestionnaireId?"
            component={H5RefuseComment}
            exact
          />
          <Route
            key="notstarted"
            path="/activity/not/started"
            component={Notstarted}
            exact
          />
          <Route
            key="AssessedQuestionnairePreview"
            path="/pc/preview/:questionnaireId"
            component={AssessedQuestionnairePreview}
            exact
          />
          <Route
            key="AssessedQuestionnairePreviewFromSetting"
            path="/pc/preview/setting/:activityId/:participantWorkNo/:respondentCodePreview/:respondentTypeCodePreview/:index"
            component={AssessedQuestionnairePreview}
            exact
          />
          <Route
            key="AssessedQuestionnaireH5Preview"
            path="/h5/preview/:questionnaireId"
            component={AssessedQuestionnaireH5Preview}
            exact
          />
          <Route
            key="AssessedQuestionnaireH5PreviewFromSetting"
            path="/h5/preview/setting/:activityId/:participantWorkNo/:index"
            component={AssessedQuestionnaireH5Preview}
            exact
          />
          <Route
            key="ScollerDemo"
            path="/scollerDemo"
            component={ScollerDemo}
            exact
          />
          {/* {抽奖跳转页} */}
          <Route
            key="LotteryRedirectsPC"
            path="/lotteryRedirectsPC"
            component={LotteryRedirectsPC}
            exact
          />
          {/* {抽奖跳转页} */}
          <Route
            key="NewEmployee"
            path="/newEmployee"
            component={NewEmployee}
            exact
          />
          {/* 请求异常页面 */}
          <Route
            key="ExceptionPage"
            path="/exceptionPage"
            component={ExceptionPage}
            exact
          />
          {/* 弱网页面 */}
          <Route
            key="WeekNetwork"
            path="/weekNetwork"
            component={WeekNetwork}
            exact
          />
          <Route
            key="NotFoundPage"
            path="/404"
            component={NotFoundPage}
            exact
          />
        </Switch>
      </main>
    </div>
  </Di18nProvider>
));

const initWeakNetwork = () => {
  if ($CONFIG?.sdk?.weakNetwork && weakNetwork) {
    try {
      weakNetwork.init({
        unName: "common",
        apName: "ct",
        ldap: "anonymous",
        lang: getCookie("lang") === "zh-CN" ? "zh-CN" : "en-US", // 语种，zh-CN：中文；en-US： 英文
        env: "prod", // 环境---dev：测试环境，stage：预发环境，prod: 线上
      });
    } catch (ex) {
      console.warning("weakNetwork init failed!");
    }
  }
};

initWeakNetwork();

export default Radium(() => {
  return (
    <Provider store={store}>
      <Router basename="/questionnaire">
        <App />
      </Router>
    </Provider>
  );
});
