import { message } from 'antd';
import { axios } from './common';
let url = $CONFIG.customProps?.openAPI;
if (url === '//dhr-dev.intra.xiaojukeji.com') {
  url = '//dhr-test.intra.xiaojukeji.com'
}

const HTTP = axios.create({
  baseURL: `${url}/open/api`,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

HTTP.interceptors.response.use(
  ({ data }) => {
    switch (data?.code) {
      case "100200": {
        return Promise.resolve(data);
      }
      default:
        message.error(data?.msg);
        return Promise.reject({
          code: data?.code,
          error: data?.msg,
          message: data?.msg,
        });
    }
  },
  ({ response }) => {
    if (response) {
      message.error(response.data?.message?.message);
      return Promise.resolve('error');
    } else {
      return {};
    }
  },
);

HTTP.interceptors.request.use(
  (config) => {
    const cfg = { ...config };
    if (config.method === 'get') {
      if (config.params) {
        cfg.params = { ...config.params };
      }
    } else if (config.method === 'post') {
      if (config.data) {
        cfg.data = JSON.stringify(config.data);
      }
    }
    return cfg;
  },
  (error) => {
    message.info('系统错误');
  },
);

export default HTTP;
