import React, { useEffect,useState, useRef } from 'react';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { Template } from '@didi/ehr-didi-medal';
import { getDiDiMedal } from './services';
import Medal1TwoTone from './assets/medal1TwoTone.svg';
import Medal2TwoTone from './assets/medal2TwoTone.svg';
import Medal3TwoTone from './assets/medal3TwoTone.svg';
import Medal4TwoTone from './assets/medal4TwoTone.svg';

const localLocales = manifest.locales || {};

const groupIcons = {
  '1': <Medal1TwoTone />,
  '2': <Medal2TwoTone />,
  '3': <Medal3TwoTone />,
  '4': <Medal4TwoTone />,
};

const Pagelet = props => {
  const { params: { apiHost,
    locales,
    headers,
    employeeId
  } } = props;

  const ref = useRef();

  const prefixCls = 'pagelet-didi-medal';

  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);

  const [medalList, setMedalList] = useState({});
  const [max, setMax] = useState(0);

  const getMedalData = async () => {
    const res = await getDiDiMedal(apiHost, headers, { employeeId });
    if (res?.code === "100200" && res?.data?.length > 0) {
      const specialMedalList = res.data.filter(item => ['1', '2', '3'].includes(item.firstLevelDisplayOrder));
      const normalMedalList = res.data.filter(item => item.firstLevelDisplayOrder === '4');
      setMedalList({ specialMedalList, normalMedalList });
      setMax(Math.floor(ref.current?.offsetWidth / 130));
    }
  }
  useEffect(() => {
    if (employeeId) {
      getMedalData();
    }
  }, [employeeId]);

  return wrapSSR(
    <div className={classes} ref={ref}>
      {
        medalList?.specialMedalList?.length > 0 && <div className='special-medal-container'>
        {medalList?.specialMedalList?.map(item => {
          return <div key={item.id} className="talent-medal-container" style={item.medalList?.length > max ? {width: ref.current?.offsetWidth }:{}}>
            <div className='child-title'>
              <span>
                {groupIcons[item.firstLevelDisplayOrder]}
                {item.groupName}
              </span>
              <span className='child-title-line' />
            </div>
            <Template list={item.medalList} scroll={false} />
          </div>
        })
        }</div>
      }
      {
        medalList?.normalMedalList?.length > 0 && medalList?.normalMedalList?.map(item => {
          return <div key={item.id} className="talent-medal-container">
            <div className='child-title'>
              <span>
                {groupIcons[item.firstLevelDisplayOrder]}
                {item.groupName}
              </span>
              <span className='child-title-line'/>
            </div>
            <Template list={item.medalList} />
          </div>
        })
      }
    </div>
  )
};

export default Pagelet;
