import React, { useEffect, useState } from 'react';
import { getLocale } from '@didi/ec-base';
import { Popconfirm, ConfigProvider, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import manifest from '../../../../manifest.json';
import { ACTION_TYPE, TIME_RANGE_TYPE } from "./constant.js";
import { RightThickOutlined, LeftThickOutlined} from '@didi/iconflow/ec';


dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.locale('zh-cn');

// Moment转换为时间戳
const convertDayjsToTimestamp = value => dayjs.isDayjs(value) ? dayjs(value).valueOf() : value;

// 给定一个时间戳, 获取一个新的时间戳   上周上月 下周下月
const getNewTimeByTime = (value, type = ACTION_TYPE.prev, step = 0, stepType = 'days') => {
  value = convertDayjsToTimestamp(value);
  let stepTypes = stepType === 'dates' ? 'days' : stepType;
  if (type === ACTION_TYPE.prev) {
    return dayjs(value).subtract(step, stepTypes).valueOf();
  }
  if (type === ACTION_TYPE.next) {
    return dayjs(value).add(step, stepTypes).valueOf();
  }
  return value
}

// 根据给定的类型和时间, 获取一个时间范围
const getTimeRange = (type, value) => {
  value = convertDayjsToTimestamp(value);
  if (type === TIME_RANGE_TYPE.week) {
    return [dayjs(value).startOf('isoWeek'), dayjs(value).endOf('isoWeek')];
  } else if (type === TIME_RANGE_TYPE.month) {
    return [dayjs(value).startOf(TIME_RANGE_TYPE.month), dayjs(value).endOf(TIME_RANGE_TYPE.month)];
  } else {
    return [dayjs(value).startOf(TIME_RANGE_TYPE.date), dayjs(value).endOf(TIME_RANGE_TYPE.date)];
  }
}

function TimeFilter(props) {
  const { source, lang, onTimeChange, timeRangeTypeInfo, onTimeRangeType, startTime } = props;
  const [activeTime, setActiveTime] = useState(new Date().getTime()); // 时间戳 浮层外实际生效的时间   按此时间筛选
  const [timeRangeType, setTimeRangeType] = useState(TIME_RANGE_TYPE.month);
  const [selectedTime, setSelectedTime] = useState(new Date().getTime()); // 时间戳 浮层内选中的时间   未生效筛选
  useEffect(() => {
    if (lang !== 'zh-CN') {
      dayjs.locale('en');
    } else {
      dayjs.locale('zh-cn');
    }
  }, []);

  const onChange = (date, _) => {
    setSelectedTime(date);
    onTimeChange(getTimeRange(timeRangeTypeInfo, date));
  };

  const getShowTime = (type, time) => {
    if (type === TIME_RANGE_TYPE.week) {
      const times = getTimeRange(TIME_RANGE_TYPE.week, time)
      return `${times[0].format('MM.DD')}-${times[1].format('MM.DD')}`
    } else if (type === TIME_RANGE_TYPE.month) {
      const times = getTimeRange(TIME_RANGE_TYPE.month, time)
      return `${times[0].format('YYYY.MM')}`
    } else {
      const times = getTimeRange(TIME_RANGE_TYPE.date, time)
      return `${times[0].format('YYYY.MM.DD')}`
    }
  }
  // 左箭头
  const clickToPrev = (immediate, e) => {
    e.stopPropagation();
    const newTime = getNewTimeByTime(immediate ? activeTime : selectedTime, ACTION_TYPE.prev, 1, `${immediate ? timeRangeTypeInfo : timeRangeType}s`)
    // if (newTime < disabledDate[timeRangeType]) return message.error(locale.notSupportingViewingEarlierData)
    if (immediate) {
      setActiveTime(newTime);
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    } else {
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    }
  };
  // 右箭头
  const clickToNext = (immediate, e) => {
    e.stopPropagation();
    const newTime = getNewTimeByTime(immediate ? activeTime : selectedTime, ACTION_TYPE.next, 1, `${immediate ? timeRangeTypeInfo : timeRangeType}s`)
    if (immediate) {
      setActiveTime(newTime);
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    } else {
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    }
  };

  useEffect(() => {
    // if (source === 'team-care') {
    //   setDisabledDate({
    //     needDisabled: true,
    //     [TIME_RANGE_TYPE.week]: dayjs().startOf('isoWeek').valueOf(),
    //     [TIME_RANGE_TYPE.month]: dayjs().startOf(TIME_RANGE_TYPE.month).valueOf(),
    //   })
    // }
    // // 点击确定的时候防止重置时间
    // if(confirmFlag && timeRangeTypeInfo === timeRangeType){
    //   return;
    // }
    setTimeRangeType(timeRangeTypeInfo);
    setActiveTime(startTime);
    setSelectedTime(startTime);
    onTimeChange(getTimeRange(timeRangeTypeInfo, startTime));
  }, [timeRangeTypeInfo]);

  return (
    <div className="time-switch-popconfirm-content-item">
      <div className="prev" onClick={clickToPrev.bind(this, false)}>
        <LeftThickOutlined />
      </div>
      <div className="next" onClick={clickToNext.bind(this, false)}>
        <RightThickOutlined />
      </div>
      <ConfigProvider locale={lang !== 'zh-CN' ? enUS : zhCN} prefixCls='ant5' theme={{ token: { colorPrimary: '#ff6400' } }} >
        <DatePicker
          value={dayjs(selectedTime)}
          inputReadOnly
          placement={'bottomLeft'}
          suffixIcon={null}
          allowClear={false}
          getPopupContainer={node => node.parentNode}
          picker={timeRangeType}
          format={(value) => {
            if (timeRangeType === TIME_RANGE_TYPE.week) {
              return `${dayjs(value).startOf('isoWeek').format('MM.DD')}-${dayjs(value).endOf('isoWeek').format('MM.DD')}`;
            } else if (timeRangeType === TIME_RANGE_TYPE.month) {
              return `${dayjs(value).startOf('month').format('YYYY.MM')}`;
            } else {
              return `${dayjs(value).format('YYYY.MM.DD')}`;
            }
          }}
          // disabledDate={(cur) => cur.valueOf() < disabledDate[timeRangeType]}
          onChange={onChange}
        />
      </ConfigProvider>
    </div>
  )
}

export default TimeFilter;
