import React from 'react';
import classNames from 'classnames';

const DefaultCard = props => {
  const { item: { title, desc, digitalize, naviUrl, defaultFlag}, diagram } = props;

  return (
    <div className='default-card' onClick={() => window.open(naviUrl, '_blank')}>
      <div className='default-card-left'>
        <p className='title'>{title}</p>
        <p className={classNames('desc', {'desc-bold': defaultFlag === false })}>{desc}</p>
        <>{digitalize}</>
      </div>
      <div className='default-card-right'>
        {diagram}
      </div>
    </div>
  )
}

export default DefaultCard;
