import { useEffect, useMemo, useRef } from "react";

export default function useDebouncedCallback(
    func,
    wait,
    options
  ){
    const lastCallTime = useRef(null);
    const lastInvokeTime = useRef(0);
    const timerId = useRef(null);
    const lastArgs = useRef([]);
    const lastThis = useRef();
    const result = useRef();
    const funcRef = useRef(func);
    const mounted = useRef(true);

    funcRef.current = func;
    const useRAF = !wait && wait !== 0 && typeof window !== 'undefined';
    if (typeof func !== 'function') {
      throw new TypeError('Expected a function');
    }

    wait = +wait || 0;
    options = options || {};

    const leading = !!options.leading;
    const trailing = 'trailing' in options ? !!options.trailing : true; // `true` by default
    const maxing = 'maxWait' in options;
    const maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : null;

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);
    const debounced = useMemo(() => {
      const invokeFunc = (time) => {
        const args = lastArgs.current;
        const thisArg = lastThis.current;

        lastArgs.current = lastThis.current = null;
        lastInvokeTime.current = time;
        return (result.current = funcRef.current.apply(thisArg, args));
      };

      const startTimer = (pendingFunc, wait) => {
        if (useRAF) cancelAnimationFrame(timerId.current);
        timerId.current = useRAF ? requestAnimationFrame(pendingFunc) : setTimeout(pendingFunc, wait);
      };

      const shouldInvoke = (time) => {
        if (!mounted.current) return false;

        const timeSinceLastCall = time - lastCallTime.current;
        const timeSinceLastInvoke = time - lastInvokeTime.current;
        return (
          !lastCallTime.current ||
          timeSinceLastCall >= wait ||
          timeSinceLastCall < 0 ||
          (maxing && timeSinceLastInvoke >= maxWait)
        );
      };

      const trailingEdge = (time) => {
        timerId.current = null;
        if (trailing && lastArgs.current) {
          return invokeFunc(time);
        }
        lastArgs.current = lastThis.current = null;
        return result.current;
      };

      const timerExpired = () => {
        const time = Date.now();
        if (shouldInvoke(time)) {
          return trailingEdge(time);
        }
        // https://github.com/xnimorz/use-debounce/issues/97
        if (!mounted.current) {
          return;
        }
        // Remaining wait calculation
        const timeSinceLastCall = time - lastCallTime.current;
        const timeSinceLastInvoke = time - lastInvokeTime.current;
        const timeWaiting = wait - timeSinceLastCall;
        const remainingWait = maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;

        // Restart the timer
        startTimer(timerExpired, remainingWait);
      };

      const func = (...args)=> {
        const time = Date.now();
        const isInvoking = shouldInvoke(time);

        lastArgs.current = args;
        lastThis.current = this;
        lastCallTime.current = time;

        if (isInvoking) {
          if (!timerId.current && mounted.current) {
            // Reset any `maxWait` timer.
            lastInvokeTime.current = lastCallTime.current;
            // Start the timer for the trailing edge.
            startTimer(timerExpired, wait);
            // Invoke the leading edge.
            return leading ? invokeFunc(lastCallTime.current) : result.current;
          }
          if (maxing) {
            // Handle invocations in a tight loop.
            startTimer(timerExpired, wait);
            return invokeFunc(lastCallTime.current);
          }
        }
        if (!timerId.current) {
          startTimer(timerExpired, wait);
        }
        return result.current;
      };

      func.cancel = () => {
        if (timerId.current) {
          useRAF ? cancelAnimationFrame(timerId.current) : clearTimeout(timerId.current);
        }
        lastInvokeTime.current = 0;
        lastArgs.current = lastCallTime.current = lastThis.current = timerId.current = null;
      };

      func.isPending = () => {
        return !!timerId.current;
      };

      func.flush = () => {
        return !timerId.current ? result.current : trailingEdge(Date.now());
      };

      return func;
    }, [leading, maxing, wait, maxWait, trailing, useRAF]);

    return debounced;
  }
