import React from 'react';
import { getLocale, Tooltip } from '@didi/ec-base';
import manifest from '../manifest.json';
import classNames from 'classnames';
import useStyle from './style/employeeInfo';
import { ReactComponent as Employee } from './assets/employee.svg';

const localLocales = manifest.locales;

const isNull = str => {
  if (`${str}`.replace(/\s/gi, '') === '' || str === null || str === undefined) {
    return '-';
  }
  return str;
};

const EmployeeInfo = props => {
  const {
    params: { locales },
    data,
  } = props;

  const prefixCls = 'pagelet-employee-info';
  const wrapSSR = useStyle(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      <div className="employee-title">
        <Employee />
        <div className="title-info">{locale?.personalInfo}</div>
      </div>
      <div className="employee-content">
        <div className="single-info">
          <div className="single-title">{locale?.personalName}：</div>
          <Tooltip title={isNull(data?.name)} placement="topLeft">
            <div className="single-data single-data-en">{isNull(data?.name)}</div>
          </Tooltip>
        </div>
        <div className="single-info">
          <div className="single-title">{locale?.gender}：</div>
          <div className="single-data">{isNull(data?.sex)}</div>
        </div>
        <div className="single-info">
          <div className="single-title">{locale?.age}：</div>
          <div className="single-data">{isNull(data?.age)}</div>
        </div>
        <div className="single-info">
          <div className="single-title">{locale?.education}：</div>
          <div className="single-data">{isNull(data?.education)}</div>
        </div>
      </div>
    </div>,
  );
};

export default EmployeeInfo;
