import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider, Empty, zh_CN, en_US, getLang, Spin } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import RelationCollapse from './components/RelationCollapse';
import { getWorkRelationshipInfo } from './services';

const localLocales = manifest.locales;

const Pagelet = (props) => {
  const { params: { locales, apiHost, headers, employeeId, usePermissionResponse, colorPrimary = '#0A70F5' } } = props;

  const prefixCls = 'pagelet-work-relationship';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const [list, setList] = useState([]);
  const [selfInfo, setSelfInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const getWorkRelationship = async () => {
    try {
      setLoading(true);
      const res = await getWorkRelationshipInfo(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res?.data)?.length > 0) {
        const { data: { employeeId: empId, imgUrl, name, relationList } } = res;
        setSelfInfo({ imgUrl, name, empId });
        if (relationList?.length > 0) {
          const d = relationList?.map(v => {
            return {
              desc: v?.relationshipLevel,
              descCode: v?.relationshipLevelCode,
              num: v?.countSum,
              list: v?.didiRelationshipDetailVoList,
              type: v?.relationshipLevelCode,
            };
          });
          setList(d);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('getWorkRelationshipInfo', error);
    }
  }

  useEffect(() => {
    getWorkRelationship();
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls='ant5' locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{ token: { colorPrimary } }}>
      <Spin spinning={loading}>
        <div className={classes}>
          {
            list?.length > 0 && <RelationCollapse
              selfInfo={selfInfo}
              height={88}
              data={list}
              defaultLine={1}
              type='DiDiRelation'
              headers={headers}
              apiHost={apiHost}
              locales={locales}
              usePermissionResponse={usePermissionResponse}
            />
          }
          {
            !loading && list?.length === 0 && <Empty />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
