// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/errorIcon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".t-suspend-button{\n  position:fixed;\n  bottom:300px;\n  right:0;\n  width:4rem;\n  height:4rem;\n  border-radius:2rem;\n  z-index:999\n}\n\n.t-suspend-button .img{\n  height:55px;\n  width:55px;\n  display:flex;\n  flex-direction:row-reverse;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat\n}\n\n.t-suspend-button img{\n  width:100%;\n  height:100%\n}\n\n.t-suspend-button-animate{\n  transition-duration:.4s\n}\n\n.t-suspend-button-blue{\n  position:fixed;\n  bottom:300px;\n  right:0;\n  z-index:999;\n  padding:8px;\n  box-shadow:0px 0px 8px 0px rgba(0,0,0,.04),0px 6px 12px 12px rgba(0,0,0,.04),0px 6px 10px 0px rgba(0,0,0,.08);\n  border:1px solid rgba(255,255,255,.7);\n  background:linear-gradient(180deg, #E0F0FE 0%, #FFFFFF 24%, #FFFFFF 100%);\n  border-radius:6px 0px 0px 6px;\n  display:flex;\n  flex-direction:column;\n  align-items:center\n}\n\n.t-suspend-button-blue img{\n  margin-bottom:4px\n}\n\n.t-suspend-button-blue span{\n  font-family:PingFang SC,Arial,Helvetica,sans-serif;\n  font-size:10px;\n  font-weight:500;\n  line-height:12px;\n  text-align:center;\n  letter-spacing:0px;\n  color:rgba(0,0,0,.72)\n}\n\n.t-suspend-button-blue .title{\n  padding:4px;\n  background:#eff8ff;\n  border-radius:3px;\n  font-family:PingFang SC;\n  font-size:12px;\n  font-weight:500;\n  line-height:12px;\n  text-align:center;\n  letter-spacing:0px;\n  color:#3370ff;\n  margin-top:4px;\n  writing-mode:vertical-rl;\n  text-orientation:upright\n}", ""]);
// Exports
module.exports = exports;
