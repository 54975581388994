import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-header', () => [
		{ // 标题部分
			[`.${prefixCls}`]: {
				paddingTop: '9px',
				paddingLeft: '16px',
				paddingRight: '16px',
				position: 'relative',

				['.monthUrl']: {
					width: '86px ',
					height: '28px',
					position: 'absolute',
					top: '9px',
					right: '16px',
				}
			}
		}
	]);
};

export default useStyle;
