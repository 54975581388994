import { message } from '@didi/ec-base';
import axios from 'axios';
import { navigateToUrl } from 'single-spa';
import { encryptParams } from './encryption';
import { emit } from './event';
import filterAuthMapping from './filterAuthMapping';
import log from './log';
import recordPageTiming from './perf';

// ### PERF TIMING ###
const perfUrlMapping = $CONFIG.customProps.perfUrlMapping || {};
window.perf = window.perf || {};
// ### PERF TIMING ###

window.addEventListener('popstate', () => {
  window.isPageLoaded = true;
  localStorage.setItem('recordRefreshNum', 0)
  window.perf['startTime'] = new Date().getTime();
  window.perf['lastRecordPath'] = '';
  window.perf['lastRecordApi'] = '';
  if (perfUrlMapping[location.pathname]) {
    log('[perf popstate]', window.perf['startTime']);
  }
});

// const commonRequest = $CONFIG.customProps.commonRequest || [];

function makeRequest(defaultConfig = {}, headers) {
  const request = axios.create({
    baseURL: '/',
    timeout: 30000,
    withCredentials: true,
    headers: {
      ...headers,
      'x-kylin-proxy-with': 'apirequest',
    },
    ...defaultConfig,
  });

  // 处理请求返回
  const opResponse = (res) => {
    if (res?.data?.code === '100200') {
      return Promise.resolve(res?.data);
    } else if (res?.data?.code === '100403'){ // 没有权限
      emit('sidebar:hide', true)
      emit('appbar:hide', true)
      navigateToUrl('/no-permission')
      message.error('暂无数据权限')
    } else {
      return Promise.reject(res);
    }
  }

  request.interceptors.response.use(
    function(res) {
      // ### PERF TIMING ###
      if (res?.config
        && res?.config.requestPath === location.pathname
        && (window.perf['lastRecordPath'] !== location.pathname || window.perf['lastRecordApi'] !== res.config.url)
        && (perfUrlMapping[location.pathname] || []).includes(res.config.url)
      ) {
        const endTime = new Date().getTime() - window.perf['startTime'];
        const requestTime = new Date().getTime() - res.config.requestStartTime;
        log('[perf response]', window.perf['startTime'], endTime, requestTime);
        window.perf['lastRecordPath'] = location.pathname;
        window.perf['lastRecordApi'] = res.config.url;
        const requestUrl = res.config?.url;
        recordPageTiming(endTime, requestTime, requestUrl);
      }
      // ### PERF TIMING ###

      log('[request success-2]', res);

      if (res?.config.excuteResponse) {
        return res.config.excuteResponse(res);
      }
      return opResponse(res);
    },
    function(error) {
      log('[request err-2]', error);
      let res = error.response;

      if (res) {
        if (res.status === 401) {
          if (!window.redirectLogin && res.data.loginUrl) {
            window.redirectLogin = true;
            window.location.href = res.data.loginUrl;
          }
        } else if (res.status === 400 || res.status === 500 || res.status === 403) {
          // if (commonRequest.filter(c => res.config.url.startsWith(c)).length > 0) {
          //   log('[request new]');
          //   if (error?.config.excuteResponse) {
          //     return res.config.excuteResponse(res);
          //   }
          //   return opResponse(res); // 当成200处理
          // }
          if (error?.config.excuteResponse) {
            return res.config.excuteResponse(res);
          }
          return opResponse(res); // 当成200处理
        } else if (res.status === 503) {
          let recordRefreshNum = localStorage.getItem('recordRefreshNum') || 0;
          // localStorage.setItem('recordRefreshNum', Number(recordRefreshNum) + 1)
          if (Number(recordRefreshNum) >= 1) {
            sessionStorage['shutDownUrl'] = res.data.url;
            sessionStorage['shutDownContent'] = res.data.content;
            // 停机页面
            location.href = '/home/shutdown'
          } else {
            setTimeout(() => {
              localStorage.setItem('recordRefreshNum', Number(recordRefreshNum) + 1)
              location.reload();
            }, 400)
          }
          return Promise.resolve(res);
        }
      }

      return Promise.reject(res);
    }
  );

  request.interceptors.request.use(
    async config => {
      // ### PERF TIMING ###
      if (!window.perf['firstPath']) {
        window.perf['startTime'] = performance.timing.connectStart;
        window.perf['firstPath'] = location.pathname;
      }
      config.requestPath = location.pathname;
      config.requestStartTime = new Date().getTime();
      // ### PERF TIMING ###
      // TODO 需要匹配加密吗？
      if (config.url.includes('http') || config.url.includes('//')) {
        return config
      }
      config.excuteConfig && config.excuteConfig(config);
      const headers = config.headers || {};
      // 假期里面是functionId，真实请求头会转换成Functionid，其他项目保持统一，直接使用Functionid
      const functionId = headers?.functionId || headers?.Functionid;
      const authMap = filterAuthMapping();
      // 按钮的请求会自带functionId，不需要这里再次添加
      if (authMap && !functionId) {
        // 往请求头里面添加functionId
        config.headers['Functionid'] = authMap.functionId;
        config.headers['Functionname'] = encodeURI(authMap.functionName);
      }

      await encryptParams(config)
      return config
    },
    error => {
    }
  );

  return request;
}

export default makeRequest;
