import { message } from 'antd';
const axios = require('axios').default;
const HTTP = axios.create({
  baseURL: '/',
  timeout: 60000,
  withCredentials: true,
  headers: {
    'x-kylin-proxy-with': 'apirequest',
    // 'Access-Control-Allow-Origin': '*',
    // Authorization: cookie.get('ticket'),
    // post: {
    //   'Content-Type': 'application/json',
    // },
    // dhrToken: 'test'
  },
});

HTTP.interceptors.response.use(
  (res) => {
    if (res?.status=== 200) {
      return res.data;
    } else if (res?.status === 400) {
      return res.data;
    }  else if (res.status === 403) {
      // 没权限
    } else {
      // console.log('[response fail 1 from sc]', res);
      message.error(res?.message || '系统错误');
      return res.data;
    }
  }, (error) => {
    // console.log(error.response, 'error')
    const res = error.response;
    if (res) {
      switch (res.status) {
        case 401:
          if (!window.redirectLogin && res.data.loginUrl) {
            window.redirectLogin = true;
            window.location.href = res.data.loginUrl;
          }
          break;
        default:
          // console.log('[response fail 2 from sc]', error);
        //  console.log(res.data)
          message.error(res?.data.message || res?.data.msg || '系统错误');
      }
      return {}
    } else {
      // console.log('[response fail 3 from sc]', error);
      message.error('系统错误');
      return {};
    }
  },
);

HTTP.interceptors.request.use(
  config => {
    if (config.url.includes('http') || config.url.includes('//')) {
      return config
    }
    // config.url = '/api' + config.url
  
    return config
  },
  error => {
    // console.log('[request fail from sc]', error);
    message.error('系统错误');
    return {}
  },
);

export default HTTP;
