import React, { useRef, useEffect, useMemo, useContext } from 'react';
import { Modal } from 'antd';
import CardIcon from '../../cnps/SvgIcon';
import {
  CurrentYearActivityInfoContext,
  AllProfileInfoContext,
} from '../CustomTimeLineCpn/context';
import CustomAutoChart from '../CustomAutoChart';
import './index.less';

function CustomModalLineChart(props) {
  const { data = {}, isModalOpen = false, setIsModalOpen = () => {}, modalTitle = '' } = props;
  const modalBodyRef = useRef();
  const activityInfos = useContext(CurrentYearActivityInfoContext);
  const allProfileInfo = useContext(AllProfileInfoContext);
  // AllProfileInfoContext;
  const chartRef = useRef();
  const option = useMemo(() => {
    if (!isModalOpen) {
      return [{}, () => {}];
    }
    const tagHistoryInfos = data?.tagHistoryInfos;
    const curActivity = data?.tagHistoryInfos?.find(it => it?.activityId === data?.activityId);
    const xData = data?.tagHistoryInfos?.map(
      it => allProfileInfo?.activityMap?.[it?.activityId]?.activityName || '-',
    );

    const peakIndex = tagHistoryInfos?.findIndex?.(
      it => it?.activityId === curActivity?.activityId,
    );
    const itemRatio = 80 / (xData.length - 1);

    const xStart = 10 + itemRatio * peakIndex;
    const lMin_ = xStart - itemRatio / 4;
    const rMax_ = xStart + itemRatio / 4;
    const lMin = lMin_ < 10 ? 10 : lMin_;
    const rMax = rMax_ > 90 ? 90 : rMax_;
    const yData = data?.tagHistoryInfos?.map(it => it?.tagValue);
    const MaxTagLen = 10;

    const xAxisMaxLen = 5;
    const defaultDot = [
      {
        coord: [xData?.[peakIndex], yData?.[peakIndex]], // 所有点的圆心都在这里
        symbol:
          'image://http://img-ys011.didistatic.com/static/ddo_web_static/do1_FHl29nbwJPsVQmLk6Kt7',
        symbolSize: 12,
        label: {
          show: false, // 隐藏标签
        },
      },
    ];
    const genLocDot = loc => {
      return [
        {
          coord: [loc?.[0], loc?.[1]], // 所有点的圆心都在这里
          symbol:
            'image://http://img-ys011.didistatic.com/static/ddo_web_static/do1_FHl29nbwJPsVQmLk6Kt7',
          symbolSize: 12,
          label: {
            show: false, // 隐藏标签
          },
        },
      ];
    };
    return {
      ...(yData?.length > MaxTagLen
        ? {
            dataZoom: [
              {
                show: true,
                type: 'slider',
                backgroundColor: 'rgba(58,39,30,0.08)',
                fillerColor: 'rgba(58,39,30,.6)',
                borderColor: 'rgba(58,39,30,0.08)',
                showDetail: false,
                startValue: 0,
                endValue: 10,
                filterMode: 'empty',
                width: '80%',
                height: 10,
                left: 'center',
                zoomLoxk: true,
                handleSize: 10,
                bottom: 5,
              },
              {
                type: 'inside',
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
              },
            ],
          }
        : {}),
      defaultDot: defaultDot,
      yData: yData,
      xData: xData,
      genLocDot: genLocDot,
      peakIndex: peakIndex,
      emphasis: false,

      xAxis: {
        type: 'category',
        data: xData,
        axisLabel: {
          interval: 0,
        },
        triggerEvent: true,
        axisLabel: {
          interval: 0,
          rotate: 45,
          formatter: function (value) {
            return value.length > xAxisMaxLen ? value.slice(0, xAxisMaxLen) + '...' : value;
          },
        },
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [
        {
          symbol: 'none',
          z: 2,
          name: '',
          type: 'line',
          data: yData,
          emphasis: false,
          animation: false,
          markLine: {
            z: 2,
            symbol: 'none',
            data: [
              [
                {
                  coord: [xData?.[peakIndex], 0],
                },
                {
                  coord: [xData?.[peakIndex], yData?.[peakIndex]],
                },
              ],
            ],
            lineStyle: {
              normal: {
                type: 'solid',
                color: '#1E6BFF',
                width: 2,
                cap: 'round',
              },
            },
          },
          markPoint: {
            data: defaultDot,
            z: 10, // 确保标记点在其他图形元素之上
          },
        },
        {
          data: [],
          type: 'bar',
          emphasis: {
            disabled: true,
          },
          markArea: {
            emphasis: {
              disabled: true,
            },
            label: {
              show: false,
            },
            data: [
              [
                {
                  name: '',
                  xAxis: xData[peakIndex],
                },
                {
                  xAxis: xData[peakIndex],
                },
              ],
            ],
            itemStyle: {
              color: 'rgba(30, 107, 255, 0.08)',
              borderColor: 'transparent',
              borderWidth: 2,
              borderType: 'dotted',
            },
          },
        },
      ],
      grid: {
        left: '10%',
        top: '5%',
        right: '10%',
        bottom: '20%',
      },
      tooltip: {
        trigger: 'axis',
        renderMode: 'html',
        appendTo: modalBodyRef?.current,
        className: 'talent-mark-custom-modal-line-chart-tooltip-wrap',
        formatter(data) {
          const params = data?.[0] || {};
          if (params?.componentType === 'markArea') {
            const dataX = xData?.[params?.data?.name] || '-';
            const dataY = yData?.[params?.data?.name] || '-';
            const html = `
                  <div >
                    <div >${dataX}</div>
                    <div>
                      <span style='display: inline-block; width: 7px;height: 7px;'> </span>
                      <div >${dataY}</div>
                    </div>
                  </div>
              `;
            return html;
          }
          const html = `
                  <div >
                    <div >${params?.axisValue}</div>
                    <div>
                      <span style='display: inline-block; width: 7px;height: 7px;'> </span>
                      <div >${params?.value}</div>
                    </div>
                  </div>
              `;
          return html;
        },
      },
    };
  }, [data, activityInfos, allProfileInfo, isModalOpen]);
  const handleOk = () => {
    setIsModalOpen(() => false);
  };
  const handleCancel = () => {
    setIsModalOpen(() => false);
  };

  return (
    <Modal
      destroyOnClose
      width={800}
      height={500}
      title={
        <div className="talent-mark-custom-modal-line-chart-title-wrap">
          <div className="title">{data?.tagName}</div>
          <div className="close-icon" onClick={handleCancel}>
            <CardIcon type="icon-quxiao" size={16} pointer />
          </div>
        </div>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="talent-mark-custom-modal-line-chart-wrap"
      getContainer={e => e?.parentNode || document.body}
      closeIcon={null}
    >
      <div style={{ width: '100%', height: '238px' }} ref={modalBodyRef}>
        <CustomAutoChart chartRef={chartRef} option={option} depResize={isModalOpen} />
      </div>
    </Modal>
  );
}
const CustomModalLineChartWrap = props => {
  if (props?.isModalOpen) {
    return <CustomModalLineChart {...props} />;
  }
  return <></>;
};
export default CustomModalLineChartWrap;
