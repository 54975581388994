import { Button, Modal, message } from '@didi/ec-base';
import React, { useEffect, useState } from 'react';
import { FileExportOutlined } from '@didi/iconflow/ec';
import { emit, on } from '../../utils/event';
import newRequest from '../../utils/newRequest';
import useStyle from './style';

// 获取导出日期
const getExportTime = () => {
  const time = new Date()
  const year = time.getFullYear()
  let month = time.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = time.getDate()
  day = day < 10 ? `0${day}` : day
  return `${year}_${month}_${day}`
}
// 特殊报表
// const fileNames = ['员工转正名单', '员工周年名单', '员工生日名单']
const fileNames = ['EMPLOYEE_CONFIRMATION_LIST', 'EMPLOYEE_ANNIVERSARY_LIST', 'EMPLOYEE_BIRTHDAY_LIST']

const ExportButton = (props) => {
  const { intl } = props;
  const [visible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)  //防止导出过程中取消
  const [fileName, setName] = useState('')

  const request = newRequest.getInstance();

  // 获取默认文件名
const getDefaultName = (defaultName, date) => {
  for (const file of fileNames) {
    if(defaultName == intl(file)) {
      return `${date}${defaultName}`
    }
  }
  return `${getExportTime()}${defaultName}`
}

  const handleClick = () => {
    if(props.preCheck){
      const pass = props.preCheck();
      if(!pass){
        return
      }
    }

    emit('exportFile: clicked')
    on('exportFile: exceed', () => {
      message.warning(intl('OTHER_FILES_ARE_BEING_EXPORTED'))
      return
    })

    // 导出筛选项为0时
    if (props.filterNums === 0) {
      return Modal.confirm({
        title: intl('ARE_YOU_SURE_TO_EXPORT'),
        okText: intl('CONFIRM'),
        cancelText: intl('CANCEL'),
        onOk: () => setIsVisible(true),
        className: 'filterModal'
      });
    }

    setIsVisible(true)
  }

  const handleCancel = () => {
    setIsVisible(false)
  }

  const handleChange = (e) => {
    setName(e.target.value)
  }


  // 导出
  const handleExport = async () => {
    // const fileName = document.querySelector('.input-file-name').value
    if (fileName.length < 1) {
      message.warning(intl('THE_FILE_CANNOT_BE_EMPTY'));
      return
    }
    setIsDisabled(true)
    setConfirmLoading(true)
    const data = props.data
    // data['fileName'] = fileName.slice(-4) === '.xls' ? fileName : fileName + '.xls'
    data['fileName'] = fileName
    try {
      const requestBody = {
        method: 'post',
        url: props.url,
        data: data,
      }
      // if (props?.headers) {
      //   requestBody.headers = props.headers
      // }
      const res = await request(requestBody);
      if (res?.code == 100200) {
        message.success(intl('PLEASE_GO_TO_THE_DOWNLOAD_CENTER_TO_OBTAIN_THE_EXPORTED_FILE'))
        emit('exportFile: success')
        addAnimation()
      }
    } catch (e) {
      console.log("error: ", e);
      if (e.data.code == 100400) {
        message.warning(e.data.msg)
      }
    } finally {
      setConfirmLoading(false)
      setIsDisabled(false)
      handleCancel()
      // setName(getDefaultName(props.defaultFile))
    }
  }

  // 添加动画
  const addAnimation = () => {
    const X = document.querySelector('.export-button')
    const dot = document.querySelector('.ant-badge').getBoundingClientRect()
    const { x, y } = X.getBoundingClientRect()
    var newX = X.cloneNode()
    newX.style.backgroundColor = '#ED7343'
    newX.style.zIndex = 999
    newX.style.position = 'fixed'
    newX.style.left = `${x}px`
    newX.style.top = `${y}px`
    X.appendChild(newX)
    window.scrollX
    newX.className = 'dot'
    newX.style.left = `${dot.x + 75}px`
    newX.style.top = `${dot.y}px`
    newX.style.zIndex = -999
    newX.style.pointerEvents = 'none'
    newX.style.cursor = 'default'
  }

  useEffect(() => {
    setName(getDefaultName(props.defaultFile, props.date))
  }, [visible]);

  const prefixCls = 'export';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <>
      <Button size="large" type="primary" className="export-button" disabled={!fileName || !props?.data} onClick={handleClick} >
        <FileExportOutlined />
        {props.text || intl('EXPORT')}
      </Button>
      <Modal
        title={props.text || intl('EXPORT_FILE')}
        open={visible}
        className="export-modal"
        onCancel={handleCancel}
        width={460}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okText={intl('CONFIRM')}
        cancelText={intl('CANCEL')}
        onOk={handleExport}
        cancelButtonProps={{ disabled: isDisabled }}
        maskClosable={false}
      >
        <p className="fileTitle"><span>*</span> {intl('FILE_NAMING')}：</p>
        <input type="text" className="input-file-name" placeholder={intl('ENTER_LENGTH_LESS_FORTY')} maxLength="40" minLength="1" required value={fileName} onChange={handleChange} />
        <p className="file-hint">{intl('AFTER_CONFIRMATION_VIEW_IN_DOWNLOAD_CENTER')}</p>
      </Modal>
    </>
  )
}
export default ExportButton
