import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('supported-pagelet-content', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        marginBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',

        ['.ant5-spin-nested-loading']: {
          flex: 1,

          ['.ant5-spin-container']: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }
        }
      },
    },
  ]);
};

export default useStyle;
