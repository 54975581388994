import React, { useEffect, useState } from 'react';
import { getLocale, Spin } from '@didi/ec-base';
import classNames from 'classnames';
import useStyle from './style';
import { getManagerPosition, getOperateLog } from './services';
import { Template } from '@didi/ehr-grow-exp-chart';
import manifest from '../manifest.json';

const Pagelet = (props) => {
  const { params: {
    apiHost,
    locales,
    headers,
    employeeId, // 员工id
    posItemId, // 卡片id
    theme = 'blue', // 主题色
    onlyMgmt, // 是否仅看管理员
    logParams = {}, // 日志参数
  } } = props;

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  // 接口请求
  const getData = async (params={}) => {
    try {
      setLoading(true);
      const res = await getManagerPosition(apiHost, headers, {...params, employeeId, posItemId });
      setLoading(false);
      if (res.code === '100200' && res.data) {
        setData({
          xData: res?.data?.roleYearMonthVoList,
          workExperience: res?.data?.workExperience,
          jobLevelList: res?.data?.jobLevelVoList,
          foldJobLevelList: res?.data?.foldJobLevelVoList,
          yData: res?.data?.roleEmploymentOrgVoList,
          foldYData: res?.data?.foldRoleEmploymentOrgVoList
        })
      }
    } catch (error) {
      setLoading(false);
      console.log('getManagerPosition',error);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getData({ includeEmployee: !onlyMgmt });
    }
  }, [employeeId]);

  const prefixCls = 'pagelet-inventory-grow-exp-chart';
  const wrapSSR = useStyle(prefixCls);

  // 多语言优先props，再取本地
  const langLocales = locales || manifest.locales || {};
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  // 日志
  const saveOperateLog = (operate) => {
    getOperateLog(apiHost,headers,{
      "operateName": "点击",
      "operateContent": "管理任职",
      "operateParams": {
        ...logParams,
        "操作": operate,
        "被浏览人工号": employeeId
      }
    });
  }

  return wrapSSR(
    <Spin spinning={loading}>
      <div className={classes}>
        {
          data &&  <Template
            {...data}
            theme={theme}
            getData={getData}
            locales={locale}
            getOperateLog={saveOperateLog}
            onlyMgmt={onlyMgmt}
          />
        }
      </div>
  </Spin>
);
};

export default Pagelet;
