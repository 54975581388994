import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('management-pagelet-header', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        paddingLeft: '16px',
        paddingRight: '16px',
        position: 'relative',
        marginBottom: '6px',
        display: 'flex',
        alignItems: 'center',

        ['.diffTips']: {
          cursor: 'pointer',
          zIndex: 10,
          marginLeft: '6px'
        },

        ['.monthUrl']: {
          width: '86px ',
          height: '28px',
          position: 'absolute',
          top: '-4px',
          right: '16px',
        },
      },
      ['.diffTipsPopover']: {
        width: '400px',
        borderRadius: '8px',

        ['.ant5-popover-inner']: {
          background: 'linear-gradient(180deg, #FFF9E7 0%, #FFFFFF 44%), #FFFFFF',
          padding: '16px 44px 24px 16px',
          fontWeight: '400 !important'
        },

        ['.diffTipsContent']: {
          display: 'flex',
          alignItems: 'flex-start',
          ['p']: {
            marginLeft: '8px !important',
            fontSize: '14px !important',
            lineHeight: '22px !important',
            color: 'rgba(0, 0, 0, 0.6)',
            ['span']: {
              color: '#FF6400',
              cursor: 'pointer',
            }
          },

          ['img']: {
            width: 'auto'
          }
        }
      }

    },
  ]);
};

export default useStyle;
