import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import { getPersonalInfo } from './services';
import classNames from 'classnames';
import EmployeeInfo from './EmployeeInfo';
import OnboardingInfo from './OnboardingInfo';

import useStyle from './style';

const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: { locales, apiHost, headers, employeeId, usePermissionResponse },
  } = props;

  const [dataInfo, setData] = useState({});

  const handleRequest = async () => {
    try {
      const res = await getPersonalInfo(apiHost, headers, {
        employeeId,
      }, usePermissionResponse);
      const { data } = res;
      setData(data);
    } catch (error) {
      console.log('getPersonalInfo', error);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  const prefixCls = 'pagelet-inventory-employee-info';
  const wrapSSR = useStyle(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <div className='info-wrapper'>
          <EmployeeInfo params={props.params} data={dataInfo} />
          <OnboardingInfo params={props.params} data={dataInfo} />
        </div>
      </div>
    </ConfigProvider>,
  );
};

export default Pagelet;
