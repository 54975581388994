import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};


const PageletServices = {
  getRecentSearch: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.getRecentSearch}`, {
      method: 'post',
      data: body
    });
  },
  onDeptSelect: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.onDeptSelect}`, {
      method: 'post',
      data: body
    });
  },
  delRecentSearchFunc: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.delRecentSearchFunc}`, {
      method: 'post',
      data: body
    });
  },
  getDataFunc: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.getDataFunc}`, {
      method: 'get',
      data: body
    });
  },
  getAuthDeptManagement: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.getAuthDeptManagement}?functionId=${body.functionId}`, {
      method: 'get',
      data: body
    });
  },
  getAuthDeptOther: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.getAuthDeptOther}?functionId=${body.functionId}`, {
      method: 'get',
      data: body
    });
  },

  getAllList: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.getAllList}`, {
      method: 'post',
      data: body
    });
  },
  getListByType: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.getListByType}`, {
      method: 'post',
      data: body
    });
  },
  initFilter: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');

    return await request(`${host}/${apis.initFilter}`, {
      method: 'post',
      data: body
    });
  },
  initSystemList: async (host) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-dynamic');
    return await request(`${host}/${apis.initSystemList}`, {
      method: 'get',
    });
  },

}

export default PageletServices;
