import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 面试关系-被面试
 * url: /api/archive/mix/didiRelationInterview
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getInterviewInfo = (apiHost, headers, params, usePermissionResponse) => request(`${apiHost}${apis.getInterviewInfo}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);

// 是否存在简历
export const getResumeExists = (apiHost, headers, params, usePermissionResponse) => request(`${apiHost}${apis.getResumeExists}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);

/**
 * 面试关系-被面试-预览简历
 * url: /api/data/didiRelationInterviewCheck
 * method: GET
 * @param {*} params
 * @returns
 */

export const getPreviewResume = (apiHost, headers, params) => request(`${apiHost}${apis.getPreviewResume}`,
  {
    method: 'get',
    headers,
    params,
    responseType: 'arraybuffer',
    useResponse: true,
  }
);

/**
 * 面试关系-被面试-更新简历
 * url: /api/data/didiRelationInterviewUpdate
 * method: GET
 * @param {*} params
 * @returns
 */

export const saveResumeInfo = (apiHost, headers, data) => request(`${apiHost}${apis.saveResumeInfo}`,
  {
    method: 'post',
    headers,
    data,
  }
);

/**
 * 日志
 * url: /api/archive/operateLog
 * method: POST
 * @param  {object}  params
 * @return {Promise}
 */
export const getOperateLog = (apiHost, headers, data) => request(`${apiHost}${apis.getOperateLog}`,
  {
    method: 'post',
    headers,
    data
  }
);

