import React, { useEffect, useState, useRef } from 'react';
import { message } from '@didi/ec-base';
import dayjs from 'dayjs';
import FirstPic from './FlowComponents/FirstPic'
import '../style.scoped.less';
import SecondPic from './FlowComponents/SecondPic';
import DetailModal from './DetailModal';
import PageletServices from '../services';
import lodash from 'lodash';

const groupArray = arr =>{
  const result = [];
  // 从数组后面进行截取，倒数6个为一组，一直分完整个数组
  while (arr.length) {
    result.unshift(arr.splice(-6, 6));
  }
  return result;
}

const IMG_SIZE = new Map([
  ['small', {
    width: 484,
    height: 628
  }], //1400
  ['middle', {
    width: 510,
    height: 690
  }], // 1680
  ['large', {
    width: 580,
    height: 753
  }] // 1920
])



// 这是一个获取窗口尺寸的函数，宽度小于等于1400，返回small，大于1400，小于1680，返回middle，大于1680，返回large
const getWindowSize = () => {
  const doc = document.documentElement;
    // 计算文档的总宽度，包括横向滚动
  const width = Math.max(doc.scrollWidth, doc.clientWidth);
  if (width < 1400) {
    return 'small';
  } else if (width < 1680) {
    return 'middle';
  } else {
    return 'large';
  }
}

export default function Flow(props) {
  const { prefixCls, data, year, providerLocale, apiHost, newHeaders = {}, timeRangeTypeList = []} = props
  const [topData, setTopData] = useState([]);
  const [restData, setRestData] = useState([]);
  const [viewSize, setViewSize] = useState(getWindowSize()); // 查看当前查看的尺寸
  const viewSizeRef = useRef(getWindowSize());
  const [baseHeight, setBaseHeight] = useState(IMG_SIZE.get(getWindowSize()).height || IMG_SIZE.get('small').height);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detialContent, setDetialContent] = useState({});
  const detailFlagRef = useRef(false);



  const dealData = () => {
    // 处理data，这个数据记录每一年的数据，需要把每年的开始位置和结束位置计算一下
    // 一年的开始位置和结束位置，需要记录在map里面
    let dataCopy = JSON.parse(JSON.stringify(data));

    const map = new Map();
    for (let i = 0; i < dataCopy.length; i++) {
      const item = dataCopy[i];
      item.year = dayjs(item.effectDate).format('YYYY');
      if (!map.get(item.year)) {
        item.start = true;
      }
      // 计算当年开始的位置
      if (i !== 0 && item.year !== dataCopy[i - 1]?.year) {
        dataCopy[i - 1].isCurrentYearBegin = true;
      }
      // 最后一个需要变成当年开始位置
      if (i === dataCopy.length - 1) {
        item.isCurrentYearBegin = true;
      }
    }
    // dataCopy = dataCopy.splice(0, 6);
    if (dataCopy.length <= 6) {
      setTopData(dataCopy)
    } else {
      // 截取倒数第六个数据
      setTopData(dataCopy.splice(-6, 6));
      // 剩下的数据倒序分组，六个为一组
      setRestData(groupArray(dataCopy))
    }
  }

  useEffect(() => {
    const baseHeight = IMG_SIZE.get(getWindowSize()).height || IMG_SIZE.get('small').height
    setBaseHeight(baseHeight);
    dealData();
  }, [viewSize, data])

  const getViewSize = () => {
    const size = getWindowSize();
    if (size !== viewSizeRef.current) {
      setViewSize(size);
      viewSizeRef.current = size
    }
  }

  const resetViewSize = lodash.throttle(getViewSize, 100)

  useEffect(() => {
    let targetYear = year;
    if (year === 'all' && timeRangeTypeList.length) {
      targetYear = timeRangeTypeList[1].code
    }
    const target = document.getElementById(targetYear);
    target?.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});
  }, [year])

  useEffect(() => {
    window.addEventListener('resize', resetViewSize)
    return () => {
      window.removeEventListener('resize', resetViewSize)
    }
  }, [])


  const detailModal = (e) => {
    detailFlagRef.current = true;
    const params = e.situationCode === 'badge' ? {
      situationCode: e?.situationCode,
      effectDate: e?.effectDate,
      badgeName: e?.content,
    } : {
      situationCode: e?.situationCode,
      effectDate: e?.effectDate,
    }
    PageletServices.getDetail(apiHost, params, newHeaders).then(res => {
      if (res?.code === "100200") {
        const data = res?.data;
        if (data?.descriptionListMapVO.length > 0 || Object.keys(data?.descriptionMapVO).length > 0) {
          setDetailVisible(true);
          setDetialContent({
            title: e?.content,
            situationCode: e?.situationCode,
            descriptionListMapVO: data?.descriptionListMapVO || [],
            descriptionMapVO: data?.descriptionMapVO || {},
          })
        }
      }
    }).catch((error)=>{
      message.error(error)
    }).finally(() => {
      detailFlagRef.current = false;
    })
  }

  const onClick = (item) => {
    if (detailFlagRef.current) {
      return;
    }
    detailModal(item);
  }

  return (
    <>
      {/* <div id={`${prefixCls}-content-wrapper`} style={{height: `${containerHeight}px`}}> */}
      <div id={`${prefixCls}-content-wrapper`}>
        {
          restData.length > 0 && <>
            {restData.map(item => <SecondPic prefixCls={prefixCls} viewSize={viewSize} data={item} baseHeight={baseHeight} onClick={onClick}/>)}
          </>
        }
        <FirstPic prefixCls={prefixCls} viewSize={viewSize} data={topData} baseHeight={baseHeight} onClick={onClick} />
        {/* <SecondPic prefixCls={prefixCls} viewSize={viewSize} data={topData} baseHeight={baseHeight} onClick={onClick}/> */}
        {/* <FirstPic prefixCls={prefixCls} viewSize={viewSize} data={topData} baseHeight={baseHeight} onClick={onClick} /> */}

      </div>
      <DetailModal
        prefixCls={prefixCls}
        detailVisible={detailVisible}
        detialContent={detialContent}
        providerLocale={providerLocale}
        onClose={() => { setDetailVisible(false); }}
      />
    </>
  );
}
