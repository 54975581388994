import React from 'react';
import Annual from '../src/template/assets/nianjia.svg';
import Compassionate from '../src/template/assets/shijia.svg';
import Sick from '../src/template/assets/bingjia.svg';
import Marriage from '../src/template/assets/hunjia.svg';
import Maternity from '../src/template/assets/chanjia.svg';
import Prenatal from '../src/template/assets/chanjianjia.svg';
import Paternity from '../src/template/assets/peichanjia.svg';
import Funeral from '../src/template/assets/sangjia.svg';
import Family from '../src/template/assets/tanqianjia.svg';
import Parenting from '../src/template/assets/yuerjia.svg';
import ParentBack from '../src/template/assets/beiyongyuerjia.svg';

//  1-年假 2-事假 3-病假 4-婚假 5-产假 7-产前检查假 8-陪产假 9-丧假 10-探亲假 13-育儿假 14-备用育儿假
const holidayBtnConfig = {
  '1': <Annual/>,
  '2': <Compassionate/>,
  '3': <Sick/>,
  '4': <Marriage/>,
  '5': <Maternity/>,
  '7': <Prenatal/>,
  '8': <Paternity/>,
  '9': <Funeral/>,
  '10': <Family/>,
  '13': <Parenting/>,
  '14': <ParentBack/>,
}
export { holidayBtnConfig }
