import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-education', () => [
		{
			// base
			[`.${prefixCls}`]: {
        ['.ehr-timeline-card-template']: {
          padding: '4px 4px 0px 4px',
        },

				['.edu-card-container']: {
					padding: '16px',
					width: '230px',
					height: '104px',
					background: 'linear-gradient(#f0f6ff, #fff)',
          boxSizing: 'border-box',
					borderRadius: '10px',
					zIndex: 1,
					position: 'relative',
          boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.02),0px 2px 6px 6px rgba(0, 0, 0, 0.02),0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
          border: '2px solid #fff',

					['.edu-card-icon']: {
						position: 'absolute',
						bottom: '-4px',
						right: '8px',
						zIndex: -1,
					},

					['.edu-card-school']: {
						fontFamily: 'PingFang SC',
						fontSize: '14px',
						fontWeight: 500,
						lineHeight: '22px',
						letterSpacing: 0,
						color: '#000000DE',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},

					['.edu-card-major']: {
						fontFamily: 'PingFang SC',
						fontSize: '12px',
						fontWeight: 'normal',
						lineHeight: '18px',
						color: '#00000099',
						marginTop: '4px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},

					['.edu-card-tag']: {
						display: 'flex',
						marginTop: '6px',
						
						['.tag']: {
							fontSize: '10px',
							lineHeight: '14px',
							color: '#4183D9',
							padding: '2px 4px',
							borderRadius: '4px',
							fontWeight: 500,
							border: '1px solid rgba(92, 151, 228, 0.3)',
						}
					}
				}
			}
		}
	]);
};

export default useStyle;
