import React, { useEffect, useRef, useState } from 'react';
import { DHRTable } from '@didi/ec-ehr-base';
import { Button, ConfigProvider, Tooltip, getLocale, Spin, zh_CN, en_US } from '@didi/ec-base';
import EmptyRender from '../EmptyRender';
import ApprovalListStatus from '../ApprovalListStatus';

function ApplyList(props) {
  const {
    apiHost,
    data,
    onPageChange = () => { },
    pageNum,
    pageSize,
    langEn,
    intl,
    loading,
  } = props;
  const columns = [
    {
      title: '单号',
      dataIndex: 'businessCode',
      key: 'businessCode',
      width: 140,
      ellipsis: true,
      fixed: 'left',
      render: (text, record) => (
        <span
          onClick={() => {
            const applyUrl = record?.url.replace("/p", "/process/apply");
            window.open(applyUrl);
          }}
          style={{ color: "#FF7E41", cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: '流程类型',
      dataIndex: 'businessTypeName',
      key: 'businessTypeName',
      width: 97,
      render: (text) => (text || "-"),
    },
    {
      title: '实习生',
      dataIndex: 'applyEmpName',
      key: 'applyEmpName',
      width: 140,
      render: (text, record) => (
        <Tooltip title={`${text}(${record.applyEmpLdap},${record.applyEmpId})`}>
          <span>{`${text}(${record.applyEmpLdap},${record.applyEmpId})`}</span>
        </Tooltip>
      ),
    },
    {
      title: '原导师',
      dataIndex: 'oldTeacherName',
      key: 'oldTeacherName',
      width: 140,
      render: (text, record) => (
        <Tooltip title={`${text}(${record.oldTeacherLdap},${record.oldTeacherId})`}>
          <span>{`${text}(${record.oldTeacherLdap},${record.oldTeacherId})`}</span>
        </Tooltip>
      ),
    },
    {
      title: '新导师',
      dataIndex: 'newTeacherName',
      key: 'newTeacherName',
      width: 140,
      render: (text, record) => (
        <Tooltip title={`${text}(${record.newTeacherLdap},${record.newTeacherId})`}>
          <span>{`${text}(${record.newTeacherLdap},${record.newTeacherId})`}</span>
        </Tooltip>
      ),
    },
    {
      title: '发起时间',
      dataIndex: 'applyTime',
      key: 'applyTime',
      width: 140,
      render: (text) => (text || "-"),
    },
    {
      title: '审批状态',
      dataIndex: 'approvalStateName',
      key: 'approvalStateName',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <>
          {text ? (
            <ApprovalListStatus
              text={text}
              statusCode={record.approvalStateCode}
            />
          ) : (
            "-"
          )}
        </>
      )
    },
  ]
  const onChangePageSize = ({ num, size }) => {
    onPageChange(num, size);
  }
  return <div className='apply-table-wrapper'>
    <Spin spinning={loading}>
      <EmptyRender intl={intl}>
        <DHRTable
          columns={columns}
          locale={langEn ? en_US : zh_CN}
          dataSource={data?.list}
          scroll={{
            x: 1600,
            y: 'calc(100vh - 360px)',
          }}
          onChange={({ current = 1, pageSize = 10 }) => {
            const params = {
              num: current,
              size: pageSize
            };
            onChangePageSize(params);
          }}
          pagination={
            {
              current: pageNum,
              pageSize: pageSize,
              total: data.total,
              showQuickJumper: true,
              showSizeChanger: true,
            }
          }
        />
      </EmptyRender>
    </Spin>
  </div>
}
export default ApplyList;
