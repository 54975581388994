import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextareaItem } from 'antd-mobile';
import { di18n, CardIcon } from '@ultra/common';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';
import './index.scss';
import {
  setValidateResult,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
import { textHeight } from '../../utils/const';

const { intl } = di18n;
const innerHeight = window.innerHeight;

const textareaCombineWidgetMobile = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    attributeData,
    respondentsGroup,
    validateFlag,
    surveyBusType,
  } = useSelector((state) => state.questionContent);
  // console.log('processRate', processRate);
  // console.log('validateRules', validateRules);
  // console.log('document.body.scrollTop', document.body.scrollTop);
  const dispatch = useDispatch();
  const {
    label,
    labelStyle,
    tips,
    hidden,
    number,
    related,
    required
  } = attributeData[widgetKey];

  //const rules = { max: 2000, min: 10, required: true }
  const rules = validateRules[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;

  const [value, setValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);

  // 题目描述的展开收起
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    if (tips && textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 200) {
      setExpand(false);
    }
  }, []);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup) {
      let input = {};
      respondentsGroup.map((item, i) => {
        input[item.respondentCode] = '';
      });
      if (draftData[widgetKey]) {
        const widgetDraftData = draftData[widgetKey];
        for (let i in widgetDraftData) {
          const val = (widgetDraftData[i].length > 0) ? widgetDraftData[i][0].value : '';
          input = { ...input, [i]: val };
          dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: i, data: widgetDraftData[i] }));
        }
        // 数据校验
        const widgetValidate = validateFun(rules, widgetDraftData);
        let otherValidate;

        const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
        dispatch(setValidateResult({ id: widgetKey, data: validateData }));
      }
      setValue(input);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      for (let i in updateData) {
        dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: i, data: updateData[i] }));
      }
      dispatch(updateCurrentSubject({ data:widgetKey }));
      // 数据校验
      const widgetValidate = validateFun(rules);
      let otherValidate;

      const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
      dispatch(setValidateResult({ id: widgetKey, data: validateData }));
    }
  }, [updateData]);
  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag])

  const handleOnChange = (e, item) => {
    setValue({ ...value, [item]: e.length > 2000 ? e.slice(0,2000) : e });
  }

  const handleBlur = (v, item) => {
    let data = {};
    data[item] = [
      {
        option: '',
        value: value[item]?.replace(/(\s*$)/g, '')
      }
    ];
    setUpdateData(data);

  }

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const current = content ? content : value;
    const { max, min } = rule.controlRegular;
    const { required } = rule;

    const msgArr = [
      intl.t('亲，此项必填的哈~')
    ]

    if (max && max !== 0) {
      for (let i in current) {
        // 草稿模式的数据格式（{option:,value:}）
        if (content) {
          if (current[i].length > 0) {
            if (current[i][0].value.trim().length > max) {
              console.log('draft max');
              return {
                error: false,
                msg: msgArr[0]
              }
            }
          } else {
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        } else {
          if (current[i].trim().length > max) {
            console.log('user max');
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        }
      }
    }

    if (min && min !== 0) {
      for (let i in current) {
        // 草稿模式的数据格式（{option:,value:}）
        if (content) {
          if (current[i].length > 0) {
            if (current[i][0].value.trim().length < min) {
              console.log('draft min', current[i][0].value.trim());
              return {
                error: false,
                msg: msgArr[0]
              }
            }
          } else {
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        } else {
          if (current[i].trim().length < min) {
            console.log('user min', current[i].trim());
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        }
      }
    }

    if (required) {
      for (let i in current) {
        // 草稿模式的数据格式（{option:,value:}）
        if (content) {
          if (current[i].length > 0) {
            if (current[i][0].value.trim().length === 0) {
              // console.log('draft required', urrent[i][0].value.trim());
              return {
                error: false,
                msg: msgArr[0]
              }
            }
          }
          if (current[i].length === 0 || Object.keys(current).length < respondentsGroup.length) {
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        } else {
          if (current[i].trim().length === 0) {
            console.log('user required', current[i].trim());
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const formatterCount = (e = '') => {
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    const { min } = rules.controlRegular;
    let max = rules.controlRegular.max ? rules.controlRegular.max : 2000;
    if (min && Number(min)) {
      return `${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
    }
    return `${count}/${max}`;
  }

  const handleExpand = () => {
    setExpand(!expand);
  };


  const renderInput = () => {
    return (
      <>
        <div>
          {
            respondentsGroup.map((item, i) => {
              return (
                <div className="radio-textarea" key={widgetKey + 'combine-textarea-div' + item.respondentCode}>
                  <div className="radio-combine-fullname" style={{ paddingLeft: '0' }}>
                    <span className="bg">
                      {item.activityRespondentFullName}
                    </span>
                  </div>
                  <div className={classnames('textarea-combine-textarea', {
                    'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
                    'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
                  })}>
                    <TextareaItem
                      key={widgetKey + 'combine-textarea' + item.respondentCode}
                      value={value ? value[item.respondentCode] : ''}
                      placeholder={rules?.required ? '必填' : ''}
                      rows={4}
                      onChange={(e) => handleOnChange(e, item.respondentCode)}
                      onBlur={(e) => handleBlur(e, item.respondentCode)}
                      maxLength={20000}
                    />
                    <p className="ct-textarea-combine-text">
                      {formatterCount(value ? value[item.respondentCode] : '')}
                    </p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="ct-questionnaire-mobile-error ct-questionnaire-mobile-textarea-error error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden}`}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={'div-label'}
          style={{ fontSize: '14px' }}
        >
          <div className={`radio-div-label-${required}`}>
            <img src={Must} />
          </div>
          {
            number ? <div className="m-number">{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-content radio-content-related-${related}`}>
          {tips ? (
            <div className="mobile-question-tip">
              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && !expand ? (
                  <>
                    {decodeURIComponent(tips).length > 100 ? `${decodeURIComponent(tips).slice(0, 60)}...` : `${decodeURIComponent(tips).slice(0, 54)}...`}
                  </>
                ) : (
                  <>{decodeURIComponent(tips)}</>
                )
              }

              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && (
                  <div onClick={handleExpand}>
                    {expand
                      ? (
                        <div className='expand-btn-container'>
                          {intl.t('收起')}
                          <CardIcon type="iconshouqi1" className="expand-icon" />
                        </div>
                      ) : (
                        <div className='expand-btn-container'>
                          {intl.t('查看全部')}
                          <CardIcon type="iconxiala2" className="expand-icon"/>
                        </div>
                      )}
                  </div>
                )
              }
            </div>
          ) : null}
          {renderInput()}
        </div>
        {renderErrorMsg()}
      </div>
      {/* <Divider dashed /> */}
    </>
  );
};

export default textareaCombineWidgetMobile