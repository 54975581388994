import { useRegisterStyle } from '@didi/ec-base';


const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-ontheway', () => [
		{
			// base
      [`.${prefixCls}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        '.ontheway-search': {
          position: 'relative',
          minHeight: '32px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',

          '.ontheway-tab': {
            display: 'flex',
            alignItems: 'center',
            'span': {
              fontSize: '14px',
              color: 'rgba(0,0,0,0.87)'
            },
            '.ant5-segmented': {
              marginRight: '12px'
            }
          },
          '.export-button': {
            position: 'absolute',
            right: 0,
            backgroundColor: '#ff6400 !important'
          }
        },
        '.tableBox .tableBox': {
          ['.ant5-table-expanded-row-fixed .ec-ehr-base-table-empty,.ec-ehr-base-table-empty']: {
            marginBlock: '96px',
            position: 'sticky',
            left: '0',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ['img']: {
              display: 'inline-block',
              width: '180px'
            },
            ['.ec-ehr-base-table-empty-text']: {
              fontSize: '12px',
              color: '#2D3040',
              letterSpacing: '0',
              lineHeight: '14px',
            }
          }
        },
      }
		}
	])
};

export default useStyle;
