import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-benefit-info', () => [
		{
			// base
			[`.${prefixCls}`]: {
        color: chartToken.$font_color,
        fontSize: variable.size,
				width: '100%',
				minHeight: '100px',
        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute'
        },
				['.ant-tooltip']: {
					left: '79px !important',
    			top: '-40px !important',
				},
				['.ant-tooltip-arrow']: {
					display: 'none'
				},
				['.empl-visible-btn']: {
					height: 22,
					width: 22,
					padding: '1px 0 0 3px',
					marginLeft: 9,
					borderRadius: '3px',
					cursor: 'pointer',
					['span']: {
						opacity: 0.6,
					},
					'&:hover': {
						background: 'rgba(0, 0, 0, 0.04)',
						['span']: {
							opacity: 1,
						}
					},
				},
				[`.${prefixCls}-content-err`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '262px',
          justifyContent: 'center',
          ['img']: {
            width: '120px',
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
        },
				['.benefit-btn']: {
					['.right-icon']: {
						position: 'relative',
						top: '2px',
					}
				},
				['.welfare-info-content']: {
					marginTop: 12,
					['.welfare-item']: {
						padding: '8px 12px',
						marginBottom: 12,
						borderRadius: 6,
						background: '#F2F8FF',
						// backgroundImage: "url('https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/bg-welfare-item.png')",
						// backgroundRepeat: 'no-repeat',
						// backgroundPosition: 'top left',
						// backgroundSize: '416px 60px',
						border: '0.5px solid rgba(0, 0, 0, 0)',
						cursor: 'pointer',
						whiteSpace: 'nowrap',
						'&:hover': {
							border: '0.5px solid #6DA2FF',
						},
						['.sku-name']: {
							fontFamily: 'PingFang SC',
							fontSize: 14,
							fontWeight: 500,
							lineHeight: '22px',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						},
						['.empl-info']: {
							display: 'flex',
							lineHeight: '18px',
							['.empl-name']: {
								width: '50%',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							},
							['.end-date']: {
								flex: '0 1 auto',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}
						}
					},
					['.welfare-item:last-child']: {
						marginBottom: 0,
					},
					['.show-more-btn']: {
						display: 'block',
						fontFamily: 'PingFang SC',
						fontSize: '12px',
						fontWeight: 'normal',
						color: 'rgba(0, 0, 0, 0.36) !important',
						border: 'none !important',
						boxShadow: 'none !important',
						margin: '0 auto',
						padding: '0',
						height: '17px',
						lineHeight: 'normal',
						marginTop: '-4px',
						cursor: 'pointer',
						textAlign: 'center',
						'&:hover': {
							opacity: 0.72,
						},
						['img']: {
							marginBottom: 3,
						}
					}
				},
				['.welfare-text-content']: {
					marginTop: 12,
					position: 'relative',
					backgroundImage: "url('https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/bg-welfare-window.png')",
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'top left',
					backgroundSize: '416px 144px',
					border: 'none',
					borderRadius: 8,
					padding: '35px 20px',
					cursor: 'pointer',
					['.welfare_window_text']: {
						fontFamily: 'PingFang SC',
						fontSize: 18,
						fontWeight: 500,
						lineHeight: '22px',
						color: '#FFFFFF',
						marginBottom: 8
					},
					['.welfare_window_end_date']: {
						fontFamily: 'PingFang SC',
						fontSize: 14,
						fontWeight: 500,
						lineHeight: '22px',
						color: '#FFFFFF'
					},
					['img']: {
						position: 'absolute',
						width: 170,
						height: 144,
						borderRadius: '0 8px 8px 0',
						top: 0,
						right: 0
					}
				}
			}
		}
	]);
};

export default useStyle;
;