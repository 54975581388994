import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 入职信息
 * url: /api/archive/employment-info
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getOnboardingInfo = (apiHost, headers, params, usePermissionResponse) => request(`${apiHost}${apis.getOnboardingInfo}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);

export default getOnboardingInfo;
