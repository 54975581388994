import React from "react";
import {
  Button,
  Select
} from 'antd';
import classnames from 'classnames';
import { LeftOutlined, GlobalOutlined } from '@ant-design/icons';
import { setCookie } from '../../utils/Tool';
import { setLocale } from '@didi/di18n-react';
import { useSelector } from 'react-redux';
import { di18n } from '@ultra/common';
import './index.scss'

const { intl } = di18n;

const questionnaireBanner = props => {
  const {
    activityId,
    layoutData,
    currentLangDes,
    questionnaireId,
    respondentCode,
    langKeyList,
    lanSelect,
    questionaireType,
    participantWorkNo,
    lanQue,
    index
  } = props;

  const {
    lanMap,
    lanDes,
    surveyBusType,
  } = useSelector(state => state.questionContent);

  // 渲染问卷头部
  // 包含卷首语、问卷名称和切换语言按钮
  // ++
  // ++
  const renderRemark = () => {
    // 做一个容错处理，如果没有富文本卷首语，就取纯文本的卷首语
    if (layoutData.remark || layoutData.styleRemark) {
      return (
        <div className="question-main-des-pc">
          <div className="question-main-des-pc-text"></div>
          {
            layoutData.styleRemark ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(layoutData.styleRemark) }} />
              : <div>{layoutData.remark}</div>
          }
        </div>
      )
    }
    return ''
  }

  // 处理切换语言
  const handleLanChange = value => {
    const lang = lanMap[value];
    setLocale(lang, {
      cookieLocaleKey: 'lang',
    });
    setCookie('ct-lang', lang);

    if (respondentCode) {
      // 判断当前问卷是否包含当前语言的问卷，若没有，则跳转到第一个包含当前语言的问卷
      const currentPageIndex = `${questionnaireId}/${respondentCode}`;
      if (!lanQue[value].includes(currentPageIndex)) {
        props.history.push(`/pc/${activityId}/${lanQue[value][0]}/submit/${index}`);
      }
    }
    // 切换完语言后，页面重新加载所有接口
    window.location.reload();
  };

  // 语言切换组件
  const languageSelectComponent = () => {
    return (
      <>
        <span className="lang-before-icon"><GlobalOutlined /></span>
        <Select
          style={{ width: '100%' }}
          defaultValue={currentLangDes}
          key={currentLangDes}
          onChange={handleLanChange}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          {
            langKeyList.map(item => <Option value={item} key={"lan-list" + lanMap[item]}>{lanDes[item]}</Option>)
          }
        </Select>
      </>
    )
  };

  // 左上角返回首页Button
  const returnToNavigate = (style = {}) => (
    <Button
      icon={<LeftOutlined style={{ fontSize: '10px' }} />}
      className="return-to-navigete"
      onClick={handleReturnBtn}
      style={style}
    >
      {intl.t('返回首页')}
    </Button>
  );
  // 返回首页需要重新请求接口，不可以读缓存，因为有一个接口数据不一致
  const handleReturnBtn = () => {
    if (participantWorkNo) {
      location.href = `${location.origin}/questionnaire/navigation/preview/pc/${activityId}/${participantWorkNo}`;
    } else {
      location.href = `${location.origin}/questionnaire/navigation/pc/${activityId}`;
    }
  };

  const renderReturnBtn = () => {
    // 活动预览合并问卷
    if (participantWorkNo && questionaireType === 2) {
      return returnToNavigate();
    }
    if (activityId === -1) {
      return '';
    }
    if (questionaireType === 2) {
      return returnToNavigate();
    }
    return '';
  }
  return (
    <div className="questionnaire-banner-main">
      <div className="question-main-banner-title">
        {
          lanSelect ?
            <div className={classnames("multi-questionaire-lan-select", {
              'multi-questionaire-lan-select-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
            })}>
              <div>
                {languageSelectComponent()}
              </div>
            </div>
            : ''
        }
        <div className="title">
          {/* 只有合并问卷才有返回按钮 */}
          {renderReturnBtn()}
          <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(layoutData?.styleName || layoutData?.name) }} />
        </div>
        {/* 渲染问卷卷首语 */}
        {renderRemark()}
      </div>
      <div className={surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? 'question-main-banner-pic-blue' : 'question-main-banner-pic'}>
        <div className="img">
        </div>
      </div>
    </div>
  );
};

export default questionnaireBanner;
