import React, { useState, useEffect } from 'react';
import KeyComponents from './KeyComponents';
import '../../style.scoped.less';


const IMG_SIZE = new Map([
  ['small', {
    width: 484,
    height: 628
  }], //1400
  ['middle', {
    width: 510,
    height: 690
  }], // 1680
  ['large', {
    width: 580,
    height: 753
  }] // 1920
])

const IMG_POSITION = new Map([
  ['small', [[140, 46], [332, 116], [144, 232], [340, 350], [110, 412], [322, 492]]], //1400
  ['middle', [[156, 56], [354, 134], [164, 260], [370, 388], [124, 460], [358, 548]]], // 1680
  ['large', [[176, 66], [432, 150], [174, 290], [432, 430], [134, 508], [414, 604]]] // 1920
])

const BASE_HEIGHT = new Map([
  ['small', [180, 270, 350, 440, 560, 628]],
  ['middle', [190, 290, 380, 490, 600, 690]],
  ['large', [200, 300, 416, 520, 650, 753]]
])

const SecondPic = (props) => {
  const {data, viewSize = 'small', prefixCls, baseHeight, onClick = () => {}} = props

  const [curData, setCurData] = useState([])
  const [cutHeights, setCutHeights] = useState([])

  useEffect(() => {
    const positions = IMG_POSITION.get(viewSize);
    const newData = [];
    const dataCopy = JSON.parse(JSON.stringify(data));
    let len = dataCopy.length || 0

    while(len < 6) {
      dataCopy.unshift(undefined) // 填充至6
      len++;
    }
    dataCopy.forEach((item, index) => {
      if (item === undefined) {
        newData.push(undefined)
        return
      }
      const position = positions[index]
      newData.push({
        ...item,
        top: position[1],
        left: position[0]
      })
    });
    setCurData(newData)
    setCutHeights(BASE_HEIGHT.get(viewSize))
  }, [viewSize, data])

  return <>
    {data.length < 6 ? (
      <div className={`${prefixCls}-content-rest-wrapper`} style={{height: `${cutHeights[data.length - 1]}px`}}>
        <div className={`${prefixCls}-content-rest`} style={{height: `${baseHeight}px`, width: `${IMG_SIZE.get(viewSize).width || IMG_SIZE.get('small').width}px`}}>
        {/* <div className={`${prefixCls}-cloud`}></div> */}

          {
            curData.map((item, index) => item &&<KeyComponents item={item} index={index} viewSize={viewSize} prefixCls={prefixCls} onClick={() => {onClick(item)}} />)
          }
          <div className={`${prefixCls}-cloud`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    ): (<div className={`${prefixCls}-content-rest`} style={{height: `${baseHeight}px`, width: `${IMG_SIZE.get(viewSize).width || IMG_SIZE.get('small').width}px`}}>
        {/* <div className={`${prefixCls}-cloud`}></div> */}

      {
        curData.map((item, index) => item &&<KeyComponents item={item} index={index} viewSize={viewSize} prefixCls={prefixCls} onClick={() => {onClick(item)}} />)
      }
      <div className={`${prefixCls}-cloud`} style={{width: `${IMG_SIZE.get(viewSize).width || IMG_SIZE.get('small').width}px`, height: `${cutHeights[data.length - 1]}px`}}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>)}
  </>
}

export default SecondPic;
