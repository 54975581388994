import React, { useState } from "react";
import styled from "@emotion/styled";
import manifest from '../../../../manifest.json';
import classNames from 'classnames';
import { getLocale, Tooltip, Avatar, Modal, Spin, Divider, getLang } from '@didi/ec-base';
import TreeDiagram from '../TreeDiagram';
import ResignSvg from '../../assets/resign.svg';
import { getDidiWorkRelationshipInfoDetail } from '../../services';

const localLocales = manifest.locales;

const ContentCard = (props) => {
  const {
    height,
    width,
    style,
    cardBackGround,
    item,
    status,
    cardHoverBorder,
    locales
  } = props;

  const [showModal, setShowModal] = useState(false);

  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);

  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const handleDetail = async () => {
    try {
      setShowModal(true);
      setLoading(true);
      const res = await getDidiWorkRelationshipInfoDetail(props?.apiHost, props?.headers, {
        // 当前用户id
        currentEmployeeId: props?.selfInfo?.empId,
        // 被点击人id
        clickedEmployeeId: item?.empId,
        // 被点击人关系等级code
        relationshipTypeCode: item?.relationshipTypeCode,
      }, props?.usePermissionResponse);

      const { data } = res;
      setTreeData(data);
      setLoading(false);
    } catch (error) {
      console.log('getDidiWorkRelationshipInfoDetail', error);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  }

  const handleHoverDom = (event) => {
    const isIpad = navigator.userAgent.toLowerCase().match(/ipad/i) == 'ipad';// 判断是否为iPad
    if (isIpad) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <CardStyled
        height={height}
        width={width}
        style={{
          ...style,
          background: cardBackGround,
        }}
        cardHoverBorder={cardHoverBorder}
        onClick={handleDetail}
      >
        <div className="card-info-item">
          <Avatar
            src={item.imageUrl}
            className="self-avatar"
            username={
              <Tooltip
                title={<>{item?.empName} ({item?.empId})<Divider type="vertical" style={{ background: '#fff' }} />{item?.currentPosition || item?.positionDesc}</>}
              >
                <div onClick={handleHoverDom}>{item?.empName}<span style={{ marginLeft: '4px' }}>({item?.empId})</span></div>
              </Tooltip>
            }
            dept={item?.currentPosition || item?.positionDesc}
          />
          {/* 离职标记 */}
          {status === "I" && (
            <div className={classNames("item-tag-con", {
              'item-tag-con-colleague': item?.relationshipTypeCode === '4',
            })}>
              <ResignSvg className="card-icon" />
            </div>
          )}
        </div>
        {/* 标签 */}
        {
          item?.empTitleList?.length > 0 && <div className="card-info-title" onClick={handleHoverDom}>
            <Tooltip
              overlayClassName='work-relationship-card-emp-title-tooltip'
              placement='bottomLeft'
              title={
                item.empTitleList.map(v => (
                  <div>
                    <span style={{ color: 'rgba(255,255,255,0.7)', fontWeight: 500, marginRight: '2px' }}>{v.titleDesc}:</span> {v.titleHover}
                  </div>
                ))
              }
            >
              {
                getLang() === 'en-US' ? <div>
                  <span
                    style={{
                      color: item.empTitleList[0]?.titleCode <= 3 ? "#33B57A" : "#407AFF",
                      border: `1px solid ${item.empTitleList[0]?.titleCode <= 3 ? "#66C99C" : "#739DFF"}`,
                    }}
                  >
                    {item.empTitleList[0].titleDesc}
                  </span>
                  {
                    item.empTitleList?.length > 1 && (
                      <span
                        style={{
                          color: "#407AFF",
                          border: "1px solid #739DFF",
                          fontWeight: 600
                        }}
                      >
                        +{item.empTitleList?.length - 1}
                      </span>
                    )
                  }
                </div> : <div>
                  {
                    item.empTitleList.map((v) => (
                      <span
                        key={v.titleCode}
                        style={{
                          color: v?.titleCode <= 3 ? "#33B57A" : "#407AFF",
                          border: `1px solid ${v?.titleCode <= 3 ? "#66C99C" : "#739DFF"}`,
                          fontWeight: 600
                        }}
                      >
                        {v.titleDesc}
                      </span>
                    ))}
                </div>
              }
            </Tooltip>
          </div>
        }

      </CardStyled>
      <Modal
        title={locale.working}
        open={showModal}
        footer={null}
        width={800}
        getContainer={() => document.getElementsByClassName('card-info-item')?.[0]}
        className='work-detail-modal'
        onCancel={() => setShowModal(false)}
      >
        <Spin spinning={loading}>
          <TreeDiagram treeData={treeData} />
        </Spin>
      </Modal>
    </>
  );
};
export default (props) => {
  const {
    width,
    height,
    style = {},
    item,
    type,
    cardBackGround,
  } = props;
  const status = item?.empStatus || item?.hrStatus;

  return (
    <ContentCard {...props} status={status} />
  );
};
const PopoverStyled = styled.div`
  min-width: 400px;
  max-width: 600px;
  max-height:450px;
  overflow: auto;
  span.popover-info-title{
    margin-left: 3px;
    width: fit-content !important;
    margin: 12px -2px 12px 0px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    border: 1px solid #33B57A;
    padding: 0px 2px;
    border-radius: 2px;
    transform: scale(0.83);
    display: inline-block;
    }
  }
  .didi-con{
    >div{
      &:not(:last-child){
        border-bottom: 1px dashed #F1F1F1;
      }
    }
    p{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgba(0,0,0,0.85);
      margin-bottom:12px;
      &>span{
        &:nth-of-type(1){
          color: rgba(0,0,0,0.50);
          margin-right:4px;
        }
        &:nth-of-type(2){
          white-space: break-spaces;
          display: inline-table;
        }
      }
    }
  }
  .popover-con{
    p{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgba(0,0,0,0.85);
      &>span{
        color: rgba(0,0,0,0.50);
        margin-right:4px;
      }
    }
  }
  .popover-title{
    &-button{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #FF7E41;
      cursor:pointer;
      margin-left:16px;
    }
    &-name{
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0,0,0,0.85);
    }
    &-I{
      height: 17px;
      line-height: 16px;
      margin-left:8px;
      padding:0px 4px;
      border: 1px solid #9FA1A2;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #68696A;
      border-radius: 2px;
      transform: scale(0.83);
      display: inline-block;
    }
  }
`;
const CardStyled = styled.div`
  width: ${(props) => props.width || 176}px;
  height: ${(props) => props.height || 80}px;
  background: #F2F6FA;
  border-radius: 12px;
  padding: 14px 2px 12px 12px;
  margin-right: 16px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    border: ${(props) => props.cardHoverBorder} !important;
  }
  .card-info-title{
    min-height: 22px;
    margin-left: 42px;
    display: flex;
    align-items: center;
    max-width: 100%;
    width: max-content;

    span {
      line-height: 20px;
      padding: 0 2px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      border: 1px solid #33B57A;
      text-align: center;
      border-radius: 4px;
      transform: scale(0.83);
      display: inline-block;
    }

    &:hover span{
      text-shadow: 0 0 0.3px currentColor;
    }
  }
  .card-info-item{
    display: flex;
    align-items: flex-start;
    position: relative;
    flex: 1 1 auto;

    .item-tag-con {
      position: absolute;
      top: 16px;
      left: 16px;

      svg {
        font-size: 24px;
        transform: scale(0.6);
      }
    }

    .item-tag-con-colleague {
      top: 28px;
    }

    img{
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    >div{
      >div:nth-of-type(1){
        display: flex;
        font-family: PingFangSC-Medium;
        align-items:center;
        font-size: 12px;
        color: rgba(0,0,0,0.85);
        span{
          &:nth-of-type(1){
            max-width: calc(100% - 46px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:nth-of-type(2){
            width: 42px;
            margin-left: 4px;
          }
        }
      }
      >div:nth-of-type(2){
        width: 100%;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #666666;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;
