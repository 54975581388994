import React, { useEffect, useState } from 'react';
import { getLocale } from '@didi/ec-base';
import { Popconfirm, ConfigProvider, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import manifest from '../../../../manifest.json';
import { ACTION_TYPE, TIME_RANGE_TYPE } from "./constant.js";
import LeftArrow from '../../assets/left_arrow.svg';
import RightArrow from '../../assets/right_arrow.svg';
import { RightThickOutlined, LeftThickOutlined} from '@didi/iconflow/ec';


dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.locale('zh-cn');

// Moment转换为时间戳
const convertDayjsToTimestamp = value => dayjs.isDayjs(value) ? dayjs(value).valueOf() : value;

// 给定一个时间戳, 获取一个新的时间戳   上周上月 下周下月
const getNewTimeByTime = (value, type = ACTION_TYPE.prev, step = 0, stepType = 'days') => {
  value = convertDayjsToTimestamp(value);
  let stepTypes = stepType === 'dates' ? 'days' : stepType;
  if (type === ACTION_TYPE.prev) {
    return dayjs(value).subtract(step, stepTypes).valueOf();
  }
  if (type === ACTION_TYPE.next) {
    return dayjs(value).add(step, stepTypes).valueOf();
  }
  return value
}

// 根据给定的类型和时间, 获取一个时间范围
const getTimeRange = (type, value) => {
  value = convertDayjsToTimestamp(value);
  if (type === TIME_RANGE_TYPE.week) {
    return [dayjs(value).startOf('isoWeek'), dayjs(value).endOf('isoWeek')];
  } else if (type === TIME_RANGE_TYPE.month) {
    return [dayjs(value).startOf(TIME_RANGE_TYPE.month), dayjs(value).endOf(TIME_RANGE_TYPE.month)];
  } else {
    return [dayjs(value).startOf(TIME_RANGE_TYPE.date), dayjs(value).endOf(TIME_RANGE_TYPE.date)];
  }
}

const localLocales = manifest.locales;

function TimeFilter(props) {
  const { source, lang, onTimeChange, timeRangeTypeInfo, onTimeRangeType, startDate } = props;
  const locale = getLocale(localLocales);
  const [activeTime, setActiveTime] = useState(new Date().getTime()); // 时间戳 浮层外实际生效的时间   按此时间筛选
  const [timeRangeType, setTimeRangeType] = useState(timeRangeTypeInfo);
  const [selectedTime, setSelectedTime] = useState(new Date().getTime()); // 时间戳 浮层内选中的时间   未生效筛选
  const [disabledDate, setDisabledDate] = useState([{
    needDisabled: false,
    [TIME_RANGE_TYPE.month]: 0,
    [TIME_RANGE_TYPE.week]: 0,
    [TIME_RANGE_TYPE.date]: 0,
  }]);
  const [confirmFlag, setconfirmFlag] = useState(false); // 是否点击确定按钮

  useEffect(() => {
    if (lang !== 'zh-CN') {
      dayjs.locale('en');
    } else {
      dayjs.locale('zh-cn');
    }
  }, []);

  const onChange = (date, _) => {
    setSelectedTime(date);
    onTimeChange(getTimeRange(timeRangeTypeInfo, date));
  };
  // 左箭头
  const clickToPrev = (immediate, e) => {
    e.stopPropagation();
    const newTime = getNewTimeByTime(immediate ? activeTime : selectedTime, ACTION_TYPE.prev, 1, `${immediate ? timeRangeTypeInfo : timeRangeType}s`)
    if (newTime < disabledDate[timeRangeType] || newTime < disabledDate[timeRangeType][0]) return message.error(locale.notSupportingViewingEarlierData)
    if (immediate) {
      setActiveTime(newTime);
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    } else {
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    }
  };
  // 右箭头
  const clickToNext = (immediate, e) => {
    e.stopPropagation();
    const newTime = getNewTimeByTime(immediate ? activeTime : selectedTime, ACTION_TYPE.next, 1, `${immediate ? timeRangeTypeInfo : timeRangeType}s`)
    if (newTime > disabledDate[timeRangeType][1]) return message.error(locale.notSupportingViewingEarlierEndData)
    if (immediate) {
      setActiveTime(newTime);
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    } else {
      setSelectedTime(newTime);
      onTimeChange(getTimeRange(timeRangeTypeInfo, newTime));
    }
  };

  useEffect(() => {
    if (source === 'team-holidays') {
      // 当前的前一年第一天，当前的后一年最后一天
      const startYearDate = dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
      const endYearDate = dayjs().add(2, 'year').startOf('year').subtract(1, 'day').format('YYYY-MM-DD');
      const month = dayjs(startYearDate).valueOf();
      const endMonth = dayjs(endYearDate).valueOf();
      // 当前的前一年第一周周一，当前的后一年最后一周周一
      const startYearWeek = dayjs(startYearDate).startOf('week').format('YYYY-MM-DD');
      const endYearWeek = dayjs(endYearDate).startOf('week').format('YYYY-MM-DD');
      const monday = dayjs(startYearWeek).valueOf();
      const endMonday = dayjs(endYearWeek).valueOf();
      // 当天的前一年，当天的后一年
      const startDate = dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
      const endDate = dayjs().add(2, 'year').startOf('year').subtract(1, 'day').format('YYYY-MM-DD');
      const date = dayjs(startDate).valueOf();
      const enddate = dayjs(endDate).valueOf();
      setDisabledDate({
        needDisabled: true,
        [TIME_RANGE_TYPE.week]: [monday, endMonday],
        [TIME_RANGE_TYPE.month]:[month, endMonth],
        [TIME_RANGE_TYPE.date]:[date, enddate],
      })
    }
    const item = getTimeRange(timeRangeTypeInfo, startDate);
    setActiveTime(startDate);
    onTimeChange(item);
    setSelectedTime(startDate);
    setTimeRangeType(timeRangeTypeInfo)
  }, [timeRangeTypeInfo]);
  return (
    <div className="time-switch-popconfirm-content-item">
      <div className="prev" onClick={clickToPrev.bind(this, false)}>
        <LeftThickOutlined />
      </div>
      <ConfigProvider locale={lang !== 'zh-CN' ? enUS : zhCN} prefixCls='ant5' theme={{ token: { colorPrimary: '#ff6400' } }} >
        <DatePicker
          value={dayjs(selectedTime)}
          inputReadOnly
          placement={'bottomLeft'}
          suffixIcon={null}
          allowClear={false}
          getPopupContainer={node => {
            return node.parentNode.parentNode;
          }}
          picker={timeRangeType}
          format={(value) => {
            if (timeRangeType === TIME_RANGE_TYPE.week) {
              return `${dayjs(value).startOf('isoWeek').format('MM.DD')}-${dayjs(value).endOf('isoWeek').format('MM.DD')}`;
            } else if (timeRangeType === TIME_RANGE_TYPE.month) {
              return `${dayjs(value).startOf('month').format('YYYY.MM')}`;
            } else {
              return `${dayjs(value).format('YYYY.MM.DD')}`;
            }
          }}
          disabledDate={(cur) => {
            if(source === 'team-holidays' && disabledDate[timeRangeType]){
              return  cur.valueOf() < disabledDate[timeRangeType][0] || cur.valueOf() > disabledDate[timeRangeType][1];
            }
          }}
          onChange={onChange}
        />
      </ConfigProvider>
      <div className="next" onClick={clickToNext.bind(this, false)}>
        <RightThickOutlined />
      </div>
    </div>
  )
}

export default TimeFilter;
