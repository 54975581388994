export const BUSINESS_TYPE_CODE = {
  personalInfo: "PERSONAL_INFO",// 个人信息
  personalContactInfo: "PERSONAL_CONTACT_INFO",// 本人联系方式
  emergencyContactInfo: "EMERGENCY_CONTACT_INFO",// 紧急联系人
  familyInfo: "FAMILY_INFO",  // 家庭成员
  bankCardInfo: "BANK_CARD_INFO",// 银行卡
  languageInfo: "LANGUAGE_INFO",// 外语能力
  titleInfo: "TITLE_INFO",// 职称信息
  foreignInfo: "FOREIGN_INFO",// 外籍人员入离境信息
  eduInfo: "EDU_INFO",// 教育经历
  workMainInfo: "WORK_MAIN_INFO",// 工作履历
  proficiency: "A_LANG_PROFICIENCY",// 历史修改记录的外语技能
  title: "A_TITLE",// 历史修改记录的职称信息
  education: "A_EDUCATION",// 历史修改记录的教育信息
  veteran: "A_VETERAN", //历史修改记录的参军信息
};
