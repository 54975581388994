import React from 'react'

export default function AnswerCard(props) {
  const { data } = props;

  return (
    <div className='multi-dimensional-feedback-card-body-answer'>
      {
        data?.map(item => {
          return <div className='answer-content'>{item}</div>
        })
      }
    </div>
  )
}
