import React from 'react';
import ReactDOM from 'react-dom';
import SingleSpaReact from 'single-spa-react';
import Pagelet from './pagelet.jsx';

// export default SingleSpaReact({
export default SingleSpaReact.default({
  React,
  ReactDOM,
  rootComponent: Pagelet,
});
