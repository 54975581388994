import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('dhr-dept-select', () => [
		{
			// base
      '.dhr-dept-select': {
        width: '100%',
        '.ant-tree-select-dropdown': {
          padding: 0,
        },
        '.ant-tooltip': {
          maxWidth: 'initial',
        }
      },
      '.dhr-dept-select-tree-node-container': {
        display: 'flex',
        '.filter-node .dhr-dept-title': {
          color: '#FF6400!important',
        },
        '.dhr-dept-title': {
          paddingLeft: '2px',
          'p': {
            margin: 0,
          },
          '.dhr-dept-title-manager': {
            fontSize: '12px',
            color: '#AEAEAE',
            marginTop: '6px',
            lineHeight: '12px',
            paddingBottom: '2px',
          }
        },
        '.dhr-dept-select-loading': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          paddingTop: '10px',
        },
        '.tree-node-recent-search': {
          width: '220px',
          borderLeft: '1px solid #d1d1d1',
          paddingLeft: '5px',
          paddingTop: '5px',
          boxSizing: 'border-box',
          '.tree-node-recent-search-title': {
            fontSize: '12px',
            color: '#AEAEAE',
          },
          '.tree-node-recent-search-content': {
            padding: '5px 0',
            maxHeight: '256px',
            overflow: 'auto',
            '.ant-tag': {
              marginBottom: '5px',
            }
          }
        },
        '.tree-node-list': {
          flex: 'auto',
          padding: '5px 0',
        }
      }
		}
	])
}

export default useStyle;
