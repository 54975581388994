import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // get请求示例
  getRequestData: async (host, data) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-org-workbench-card');

    return await request(`${host}/${apis.getList}`, {
      method: 'POST',
      data
    });
  }
}

export default PageletServices;
