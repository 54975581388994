import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('regular-card', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        position: 'static',
        left: '0px',
        top: '0px',
        boxSizing: 'border-box',
        // minHeight: '316px',
        maxHeight: '600px',
        zIndex: '0',
        borderRadius: '8px',
        opacity: '1',
        background: '#FFFFFF',
        ['.intervalShow']: {
          marginBottom: '12px',
          ['.intervalShowContext']: {
            display: 'inline-block',
            height: '32px',
            lineHeight: '20px',
            borderRadius: '8px',
            padding: '6px 8px',
            background: 'rgba(245, 245, 245, 0.6)',
            fontSize: '12px',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.84)',
          }
        },
        ['.more']: {
          width: '100%',
          fontSize: '12px',
          fontWeight: 'normal',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          letterSpacing: '0em',
          lineHeight: '16px',
          color: 'rgba(0, 0, 0, 0.36)',
          cursor: 'pointer',
          paddingTop: '8px',
        },
        ['.more-active']: {
          boxShadow: '0px -6px 10px -8px rgba(0, 0, 0, 0.08)',
        }
      },
    },
  ]);
};

export default useStyle;
