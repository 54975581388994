import React, { useState, useEffect, useRef } from 'react';
import { message, Tooltip } from 'antd';
import { Toast } from 'antd-mobile';
import successImg from '../../images/success.svg';
import { LeftOutlined, RightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { di18n } from '@ultra/common';
import './index.scss'
const { intl } = di18n;

const MultipleOrganizes = (props) => {
  const { totalList, source, wrapWidth, toInfo, groupIndex, groupRecycleStatus } = props;
  const [showList, setShowList] = useState([]);
  const [query, setQuery] = useState({
    // currentPage: 1, // 当前页
    // pageNum: 1, // 共多少页
    // pageSize: 1, // 每页多少条
    // total: 0, // 总数
    open: false, // 展示收齐
  });
  const openFn = v => {
    if (v) {
      setShowList(totalList);
    } else {
      setShowList(totalList.slice(0, 2));
    }
    setQuery({
      ...query,
      open: v
    });
  };

  const toParentInfo = () => {
    // 已完成状态
    const completed = 2;
    if (groupRecycleStatus === completed) {
      return;
    }
    toInfo(totalList[0].questionnaireId, totalList, groupIndex);
  };

  useEffect(() => {
    if (source === 'pc') {
      setShowList(totalList);
    }
    if (source === 'h5') {
      setShowList(totalList.slice(0, 6))
    }
  }, [totalList]);

  return (
    <div className={`ct-questionnaire-${source}-portraits-organize`}>
      <div className={`ct-questionnaire-${source}-organize-con`}>
        <Tooltip placement="top" overlayInnerStyle={{ borderRadius: '4px' }} title={(groupRecycleStatus === 2 || source === 'h5') ? '' : intl.t('请评价')}>
        {/* <div style={{width: '100%', overflow: 'auto'}}> */}
          <div
            className={`ct-questionnaire-${source}-organize-list`}
            onClick={() => {
              toParentInfo()
            }}
          >
            {
              showList.map((item, index) => {
                return (<span
                  key={index}
                >
                  <div>{item.activityRespondentName}</div>
                  {item.recycleStatus === 2 && <img src={successImg} className={`ct-questionnaire-${source}-organize-success`} />}
                </span>)
              })
            }
          </div>
          {/* </div> */}
        </Tooltip>
        {
          totalList.length > 6 && source === 'h5' && (
            <div className={`ct-questionnaire-${source}-organize-page`}>
              {
                query.open ? (
                  <div onClick={() => { openFn(false) }}>
                    <span>{intl.t('收起')}</span>
                    <UpOutlined />
                  </div>
                ) : (
                  <div onClick={() => { openFn(true) }}>
                    <span>{intl.t('更多部门')}</span>
                    <DownOutlined />
                  </div>
                )
              }
            </div>
          )
        }

      </div>
    </div>
  );
};

export default MultipleOrganizes;
