const extendList = {
  '/transfer/manage/approveDetail': {
    pageletName: '@dhr/transfer',
    moduleName: 'TransFerApprovalPage'
  },
  '/transfer/manage/waterApprove': {
    pageletName: '@dhr/transfer',
    moduleName: 'WaterApprovePage'
  },
  '/renew/renewal-mgmt/renewal': {
    pageletName: '@dhr/renew',
    moduleName: 'ManagerApprovalPage'
  },
  '/probation/list/info': {
    pageletName: '@dhr/probation',
    moduleName: 'Approve'
  },
}
let originalPagelets = window.$CONFIG.customProps.pagelets || [];
originalPagelets = originalPagelets.map(item => ({ reg: new RegExp(`^${item.path.replace(/:(\w+)/g, (_, key) => `(?<${key}>[^/]+)`)}$`),...item }));

const getModuleInfo = async (props) => {
  const {
    url
  } = props;
  let { pathname, search } = window.location;
  if (url) {
    pathname = new URL(url).pathname;
    if (pathname.startsWith('/p/')) {
      pathname = pathname.replace('/p/', '/process/approval/todo/'); // 这个需要兼容跳转，后续处理吧
    }
    search = new URL(url).search;

    // 🤔特殊情况处理 有些路由没有在Apollo里配置   需要在这里匹配下
    const key = Object.keys(extendList).find((item) => pathname.indexOf(item) > -1);
    if (key) {
      return {
        pageletName: extendList[key].pageletName,
        moduleName: extendList[key].moduleName,
        moduleData: {},
        search
      }
    }
  }
  const pagelet = originalPagelets.find((item) => item.reg.test(pathname));
  const [pageletName, module] = pagelet.name.split(':');
  const [moduleName, moduleData] = module?.split('?') || [];
  return {
    pageletName,
    moduleName,
    moduleData,
    search
  }
};

export {
  getModuleInfo
};
