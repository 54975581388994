import React, { useState, useRef, useImperativeHandle } from 'react';
import { cloneDeep } from 'lodash';
import useOutsideClick from '../../hooks/useOutsideClick';
import CTEditor from '../CTEditor';
import EditorTabs from '../EditorTabs';
import './index.scss';

// 编辑器卡片
const EditorsCard = (props) => {
  const divRef = useRef(null);
  const editorValues = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
 
  const {
    language,
    changeLanguage,
    showTabs,
    contents,
    values = [],
    text = '问卷标题录入支持多语言，默认如下双语，如需其他语言请选择：',
    onChange,
    onBlur = () => {},
    onFocus = () => {},
    messageType, // 类型
    maxLength,
    showEditor,
    showDefaultStatus
  } = props;

  const handleFocus = () => {
    setIsFocus(true);
    onFocus();
  }

  const handleBlur = () => {
    if (!isFocus && showEditor) {
      return;
    }
    console.log('失焦', editorValues.current);
    setIsFocus(false);
    onBlur(editorValues.current);
  };

  useOutsideClick(divRef, handleBlur);

  // 返回数据
  const getEditorValue = (v) => {
    let languagesContents = values || [];
    if (languagesContents.filter(item => item.languageType === v.languageType)[0]) {
      languagesContents = languagesContents.map(item => {
        if (item.languageType === v.languageType) {
          item = { ...item, ...v }
        }
        return item;
      })
    } else {
      languagesContents.push(v);
    }
    languagesContents = languagesContents.sort((a, b) => a.languageType - b.languageType);
    console.log(languagesContents)
    editorValues.current = languagesContents;
    onChange(languagesContents);
  };

  return (
    <div
      className="langs-editor-wrap"
      tabIndex="-1"
      ref={divRef}
      style={{
        borderColor: isFocus || showEditor ? '#ff7d41' : 'transparent'
      }}
      onFocus={handleFocus}
    >
      {
        isFocus || showEditor ? (
          <div className="langs-editor-contents">
            {contents?.length > 2 && showTabs && <EditorTabs
              tabs={contents}
              text={text}
              value={language}
              changeTab={(e) => { changeLanguage(e.target.value) }}
            />}
            <div className="questionsetting-content-line">
              <CTEditor
                currentLanguage={0}
                values={values?.filter(item => item.languageType === 0)?.[0]}
                getEditorValue={getEditorValue}
                showIcon={contents.length > 1}
                messageType={messageType}
                maxLength={maxLength}
              />
            </div>
            {
              contents.length > 1 && <div className="questionsetting-content-line">
                <CTEditor
                  currentLanguage={language}
                  values={values?.filter(item => item.languageType === language)?.[0]}
                  getEditorValue={getEditorValue}
                  messageType={messageType}
                  maxLength={maxLength}
                />
              </div>
            }
          </div>
        ) : (
          editorValues?.current?.length > 0 && showDefaultStatus && (
            <div
              dangerouslySetInnerHTML={{
                __html: editorValues?.current?.[0].styleName,
              }}
              className="default-text"
            ></div>
          )
        )
      }

    </div>
  );
};

export default EditorsCard;
