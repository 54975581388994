import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {
  // post请求示例
  getPergUnitUser: async () => {
    const request = PageletApi.newRequest.getInstance();
    return await request(`/${apis.pergUnitUser}`, {
      method: 'post'
    });
  },
};

export default PageletServices;
