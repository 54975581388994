import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

  return useRegisterStyle('pagelet-team-holidays', () => [
    {
      // base
      [`.${prefixCls}`]: {
        // color: chartToken.$font_color,
        // fontFamily: chartToken.$font_family,
        width: '100%',
        height: '100%',
        // padding: '16px 24px',
        background: '#FFFFFF',
        borderRadius: '12px',

        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute'
        },
        [`.${prefixCls}-title`]: {
          // display: 'flex',
          // alignItems: 'center',
          color: 'rgba(0, 0, 0, 0.84)',
          padding: '16px 16px 0',
          color: 'rgba(0, 0, 0, 0.84)',
          position: 'relative',
          [`.${prefixCls}-url-icon`]: {
            marginLeft: '2px',
          },
          ['.title-info']:{
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '28px',
            letterSpacing: '0em',
          },
          ['.ec-ehr-base-card-title-text']:{
            display: 'flex',
            alignItems: 'center',
          },
          [`.${prefixCls}-title-filter`]: {
            display: 'flex',
            alignItems: 'center',
            userSelect: 'none',
            marginLeft: '16px',
            [`.${prefixCls}-time`]: {
              marginRight: '8px',
              borderRadius: '8px',
              background: 'rgba(0,0,0,0.04)',
              cursor: 'pointer',
              '.time-switch-popconfirm-content-item': {
                display: 'flex',
                padding: '5px 0px',
                position: 'relative',
                '.prev,.next': {
                  position: 'absolute',
                  top: '5px',
                  width: '20px',
                  height: '22px',
                  textAlign: 'center',
                  lineHeight: '24px',
                  zIndex: '99',
                },
                '.prev:hover, .next:hover':{
                  background: 'rgba(0, 0, 0, 0.08)',
                  borderRadius: '6px',
                  cursor: 'pointer'
                },
                '.prev': {
                  left: '4px'
                },
                '.next': {
                  right: '4px'
                },
                '.ant5-picker': {
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  '.ant5-picker-input input':{
                    textAlign: 'center',
                    width: '80px',
                    margin: '0 25px',
                    '&:hover':{
                      background: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '6px',
                      cursor: 'pointer'
                    }
                  }
                },
                '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                  boxShadow: 'none',
                }
              }
            },
            [`.${prefixCls}-scope`]: {
              marginRight: '8px',

              ['.ant5-select, .ant-select']: {
                ['.ant5-select-selector, .ant-select-selector']: {
                  padding: '5px 6px',
                  borderRadius: '8px',
                  background: 'rgba(0,0,0,0.04)',
                  border: 'none',
                  ['.ant5-select-selection-item, .ant-select-selection-item']: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                  }
                },
                ['.ant5-select-arrow, .ant-select-arrow']: {
                  right: '6px',
                  width: '14px',
                  height: '14px',
                  color: 'rgb(12, 21, 46)',
                },

                ['.ant5-select-dropdown, .ant-select-dropdown']: {
                  width: 'auto !important',
                  padding: '4px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                  ['.ant5-select-item, .ant-select-item']: {
                    minWidth: '100px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['&:hover']: {
                      background: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  ['.ant-select-item-option-selected, .ant5-select-item-option-selected']: {
                    color: '#FF6400',
                    background: '#FFF7F3',
                    ['&:hover']: {
                      background: '#FFF7F3'
                    }
                  }
                },
                ['&:hover ']: {
                  ['.ant-select-selector, .ant5-select-selector']: {
                    background: 'rgba(0, 0, 0, 0.08)',
                  },
                  ['.ant-select-arrow, .ant5-select-arrow']: {
                    opacity: '1',
                  },
                },
                ['&.ant-select-focused .ant-select-selector, &.ant5-select-focused .ant5-select-selector']: {
                  border: 'none',
                  boxShadow: 'none'
                }
              }

            },
            [`.${prefixCls}-summary`]: {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0px',
              color: 'rgba(0, 0, 0, 0.72)',
            }
          },
          [`.${prefixCls}-more`]: {
            position:'absolute',
            right: '16px',
            // top: '16px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0px',
            cursor: 'pointer',
            height: '32px',
            padding: '0 6px',
            color: 'rgba(0, 0, 0, 0.72)',
          },
          [`.${prefixCls}-more:hover`]:{
            borderRadius: '8px',
            background: 'rgba(0, 0, 0, 0.04)',
          },

          [`.${prefixCls}-time`]: {
            marginRight: '8px',
            borderRadius: '8px',
            background: '#F5F5F5',
            position: 'relative',
            ['.time-filter-container']: {
              display: 'flex',
              alignItems: 'center',
              padding: '5px 6px',
              ['.prev, .next']: {
                width: '16px',
                height: '16px',
                borderRadius: '6px',
                cursor: 'pointer',
                display: 'flex',
                ['&:hover']: {
                  background: '#F1F1F1',
                }
              },
              ['.time']: {
                margin: '0 4px',
                cursor: 'pointer',
              },
            },

            ['.ant-popover']: {
              marginTop: '-16px',
              padding: 0,
              ['.ant-popover-arrow']: {
                display: 'none',
              },
              ['.ant-popover-inner']: {
                borderRadius: '8px',
                padding: '0px',

                ['.ant-popconfirm-inner-content']: {
                  padding: '16px',
                  ['.ant-popconfirm-message']: {
                    padding: 0,
                    paddingBottom: '24px',
                    ['.ant-popconfirm-message-title']: {
                      paddingLeft: 0
                    }
                  },
                  ['.ant-popconfirm-buttons']: {
                    ['.ant-btn-default:not(:disabled):not(.ant-btn-disabled)']: {
                      height: '26px',
                      padding: '4px 8px',
                      fontSize: '14px',
                      fontWeight: 'normal',
                      lineHeight: '18px',
                      letterSpacing: 0,
                      border: 'none',
                      ['&:hover']: {
                        color: '#FF6400',
                      }
                    },
                    ['.ant-btn-primary']: {
                      background: '#FF6400',
                      borderColor: '#FF6400',
                      ['&:hover']: {
                        color: '#FFFFFF',
                        background: '#FF6400',
                        borderColor: '#FF6400',
                      }
                    }
                  }
                }
              },
              ['.time-switch-popconfirm']: {
                ['.time-switch-popconfirm-title']: {
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  letterSpacing: '0em',
                  color: 'rgba(0, 0, 0, 0.84)',
                },
                ['.time-switch-popconfirm-content']: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '261px',
                  marginTop: '8px',
                  ['.time-switch-popconfirm-content-item']: {
                    ['&.time-range-type']: {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '68px',

                      ['.switch-item']: {
                        width: '32px',
                        height: '32px',
                        padding: '5px 9px',
                        textAlign: 'center',
                        lineHeight: '22px',
                        borderRadius: '8px',
                        background: '#F5F5F5',
                        cursor: 'pointer',
                        fontWeight: 'normal',
                        letterSpacing: 0,
                        color: 'rgba(0, 0, 0, 0.84)',
                        marginRight: '6px',
                      },
                      ['.switch-item-active']: {
                        color: '#FF6400',
                        background: '#FF640014',
                      },
                    },

                    ['&.time-selection']: {
                      position: 'relative',
                      cursor: 'pointer',
                      ['.prev, .next']: {
                        position: 'absolute',
                        top: '4px',
                        zIndex: 1,
                        width: '16px',
                        height: '16px',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        ['&:hover']: {
                          background: '#F1F1F1',
                        }
                      },
                      ['.prev']: {
                        left: '5px',
                      },
                      ['.next']: {
                        right: '5px',
                      },
                      ['.time']: {
                        position: 'absolute',
                        top: '5px',
                        left: '22px',
                        width: '84px',
                        zIndex: 1,
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        letterSpacing: '0em',
                        color: 'rgba(0, 0, 0, 0.84)',
                        pointerEvents: 'none',
                      },
                      ['.ant5-picker']: {
                        position: 'relative',
                        padding: '5px 22px',
                        border: 'none',
                        borderRadius: '8px',
                        background: '#F1F1F1',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        border: '1px solid transparent',
                        ['.ant5-picker-input']: {
                          width: '84px',
                          cursor: 'pointer',
                          ['input']: {
                            fontSize: '14px',
                            cursor: 'pointer',
                            textAlign: 'center'
                          },
                        },
                      },
                      ['.ant5-picker:hover,.ant5-picker-focused']:{
                        border: '1px solid #FF6400',
                        background: '#fff'
                      }
                    }
                  }
                },
              }
            }
          },
        },
        [`.${prefixCls}-filter`]: {
            display: 'flex',
            alignItems: 'center',
            userSelect: 'none',
            padding: '16px',
            [`.${prefixCls}-time`]: {
              marginRight: '6px',
              borderRadius: '8px',
              background: 'rgba(0,0,0,0.04)',
              cursor: 'pointer',
              '.time-switch-popconfirm-content-item': {
                display: 'flex',
                padding: '5px 0px',
                position: 'relative',
                '.prev,.next': {
                  position: 'absolute',
                  top: '5px',
                  width: '20px',
                  height: '22px',
                  textAlign: 'center',
                  lineHeight: '26px',
                  zIndex: '99',
                },
                '.prev:hover, .next:hover':{
                  background: 'rgba(0, 0, 0, 0.08)',
                  borderRadius: '6px',
                  cursor: 'pointer'
                },
                '.prev': {
                  left: '4px'
                },
                '.next': {
                  right: '4px'
                },
                '.ant5-picker': {
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  '.ant5-picker-input input':{
                    textAlign: 'center',
                    width: '80px',
                    margin: '0 25px',
                    '&:hover':{
                      background: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '6px',
                      cursor: 'pointer'
                    }
                  }
                },
                '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                  boxShadow: 'none',
                }
              }
            },
            [`.${prefixCls}-scope`]: {
              marginRight: '6px',

              ['.ant5-select, .ant-select']: {
                ['.ant5-select-selector, .ant-select-selector']: {
                  padding: '5px 6px',
                  borderRadius: '8px',
                  background: 'rgba(0,0,0,0.04)',
                  border: 'none',
                  ['.ant5-select-selection-item, .ant-select-selection-item']: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                  }
                },
                ['.ant5-select-arrow, .ant-select-arrow']: {
                  right: '6px',
                  width: '14px',
                  height: '14px',
                  color: 'rgb(12, 21, 46)',
                },

                ['.ant5-select-dropdown, .ant-select-dropdown']: {
                  width: 'auto !important',
                  padding: '4px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                  ['.ant5-select-item, .ant-select-item']: {
                    minWidth: '100px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['&:hover']: {
                      background: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  ['.ant5-select-item-option-selected, .ant-select-item-option-selected']: {
                    color: '#FF6400',
                    background: '#FFF7F3',
                    fontWeight: '500',
                    ['&:hover']: {
                      background: '#FFF7F3'
                    }
                  }
                },
                ['&:hover ']: {
                  ['.ant5-select-selector, .ant-select-selector']: {
                    background: 'rgba(0, 0, 0, 0.08)',
                  },
                  ['.ant5-select-arrow']: {
                    opacity: '1',
                  },
                },
                ['&.ant5-select-focused .ant5-select-selector, &.ant-select-focused .ant-select-selector']: {
                  border: 'none',
                  boxShadow: 'none'
                }
              }

            },
            [`.${prefixCls}-summary`]: {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0px',
              color: 'rgba(0, 0, 0, 0.72)',
            }
          },
        ['.ant5-spin-nested-loading']: {
          height: '100%',
          ['.ant5-spin-container']:{
            height: '100%',
            ['.pagelet-team-holidays-content']: {
              margin: '0 8px 16px 16px',
              paddingRight: '6px',
              height: '100%',
              overflow: 'auto',
              // boxShadow: 'inset 0px 6px 10px -8px rgba(0, 0, 0, 0.08)',
              ['&::-webkit-scrollbar']: {
                display: 'block',
                width: '2px',
              },
              ['&::-webkit-scrollbar-track']: {
                background: 'transparent',
              },
              ['.holidays-warp']: {
                paddingBottom: '16px',
                ['.holidays-warp-item']: {
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  letterSpacing: '0em',
                  color: 'rgba(0, 0, 0, 0.84)',
                  ['svg']: {
                    transform: 'scale(0.66)',
                  },
                  ['span']: {
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: 'normal',
                    letterSpacing: '0em',
                    color: 'rgba(0, 0, 0, 0.7)',
                  },
                },
                ['.holidays-care-list']: {
                  ['.holidays-care-card-warp']: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '8px',
                    ['.holidays-care-card']: {
                      flexGrow: '1',
                      borderRadius: '8px',
                      height: '56px',
                      opacity: '1',
                      background: 'rgba(245, 245, 245, 0.6)',
                      boxSizing: 'border-box',
                      border: '1px solid transparent',
                      padding: '11px 0 13px 12px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      ['.holidays-care-card-content']: {
                        marginLeft: '8px',
                        ['.holidays-care-card-content-name']: {
                          width: '100px',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '16px',
                          letterSpacing: '0px',
                          color: '#333333',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        },
                        ['.holidays-care-card-content-item']: {
                          fontSize: '12px',
                          fontWeight: 'normal',
                          lineHeight: '16px',
                          letterSpacing: '0px',
                          color: 'rgba(0, 0, 0, 0.72)',
                          marginTop: '2px',
                          ['.message']:{
                            display: 'none',
                            position: 'absolute',
                            right: '6px',
                            bottom: '11px',
                            fontSize: '12px',
                            color: '#FF6400',
                            ['svg']:{
                              paddingTop: '3px',
                            }
                          },
                        },
                      },
                    },
                    ['.holidays-care-card:hover']: {
                      color: '#FF6400',
                      border: '1px solid #FF6400',
                      cursor: 'pointer',
                      background: 'rgba(255, 100, 0, 0.02)',
                      ['.holidays-care-card-content .holidays-care-card-content-item .message']:{
                        display: 'block',
                      },
                    },

                  },
                  ['.holidays-operation']: {
                    display: 'flex',
                    justifyContent: 'end',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    textAlign: 'center',
                    lineHeight: 'normal',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.6)',
                    cursor: 'pointer',
                    paddingTop: '8px',
                    '.ant-pagination-item-active a': {
                      color: '#ff6400',
                      fontWeight: 'normal',
                    },
                    'li':{
                      height: '20px',
                      minWidth: '20px',
                      lineHeight: '20px',
                      marginRight: '4px',
                      'a':{
                        fontSize: '12px',
                        lineHeight: '20px',
                        textAlign: 'center',
                      }
                    },
                    ['.expand, .collapse']: {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },

                    ['.ant-pagination-mini']: {
                      textAlign: 'right',
                      ['.ant-pagination-item']: {
                        borderRadius: '4px',

                        '&:hover': {
                          background: '#fff'
                        },

                        ['a']: {
                          padding: 0,
                        }
                      },
                      ['.ant-pagination-prev, .ant-pagination-next']: {
                        '&:hover': {
                          background: '#fff'
                        },
                        '.ant-pagination-item-link': {
                          background: 'rgba(255, 255, 255, 1)'
                        }
                      }
                    }
                  }
                },
              },


            },
          },
          ['.holidays-no-data']: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            ['img']: {
              width: '120px',
            },
            ['.text-item-1']: {
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '14px',
              letterSpacing: 0,
              color: '#2D3040',
            },
          },
          ['.holidays-abnormal']: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            ['img']: {
              width: '120px',
            },
            ['.text-item-1']: {
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '14px',
              letterSpacing: 0,
              color: '#2D3040',
            },
          },
        },
        ['.pagelet-team-holidays-content::-webkit-scrollbar']:{
          display:'block',
          width: '2px',
        },
        ['.pagelet-team-holidays-content::-webkit-scrollbar-track']: {
          background: 'transparent',
        },
      },
      ['.holidays-care-card-popver']: {
        ['.popver-content']: {
          display: 'flex',
          justifyContent:'space-between',
          fontSize: '14px',
          ['.popver-content-left']: {
            color: 'rgba(0, 0, 0, 0.6)',
            ['.popver-content-left-num']:{
              color: 'rgba(0, 0, 0, 0.84)',
            }
          },
          ['.popver-content-right']: {
            cursor: 'pointer',
            marginLeft: '24px',
            color: '#FF6400',
            display:'flex',
            alignItems: 'center',
          }
        }
      }
    }
  ]);
};

export default useStyle;
