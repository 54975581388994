import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';

const apis = manifest.apis || {};
const {request} = PageletApi;

/**
 * 集团人才盘点
 * url: /api/archive/group-talent
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getGroupTalent = (apiHost, headers, params) => request(`${apiHost}${apis.getGroupTalent}`,
  {
    method: 'get',
    headers,
    params
  }
);

export default getGroupTalent;
