import React, { useContext, useMemo, useState } from 'react';
import { Dropdown, Popover } from '@didi/ec-base';
import { CloseOutlined } from '@ant-design/icons';
import { PublicStore } from '../../utils/utils';
import classNames from 'classnames';
import useStyle from './styles';

const PageBottom = () => {
  // 样式
  const prefixCls = 'pagelet-Bottom';
  const wrapSSR = useStyle('pagelet-Bottom');
  const classes = classNames(prefixCls);
  // 状态
  const [hoverState, setHoverState] = useState(false);
  const [checkHoverState, setCheckHoverState] = useState(false);
  // 卡片状态
  const [open, setOpen] = useState(false);
  const { stateStore } = useContext(PublicStore);
  const { apolloConfig } = stateStore;
  const { helpInfo, pageLetInfo, } = apolloConfig
  const { name, options } = helpInfo;
  const { rhythmData } = pageLetInfo;

  // 打开变更
  const handleOpenChange = newOpen => setOpen(newOpen);
  // 关闭卡片
  const hide = () => setOpen(false);

  const items = useMemo(() => {
    return options?.map(item => ({
      key: item?.label,
      label: <a target="_blank" rel="noopener noreferrer" href={item?.url}>
        {item?.label}
      </a>
    }));
  }, [options]);

  if (!items) {
    return null;
  }

  return wrapSSR(
    <div className={classes}>
      <Dropdown
        menu={{ items }}
        arrow={false}
        placement="bottomLeft"
        getPopupContainer={triggerNode => triggerNode.parentNode || document.body} // 弹出框位置
      >
        <a
          onClick={(e) => e.preventDefault()}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
        >
          <p>{name}</p>
          {hoverState
            ? <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_W6nUTG5j6mhVvHuulVdM'} />
            : <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_29ftiY2RZCRT98ssn4hu'} />
          }
        </a>
      </Dropdown>
      <Popover
        overlayClassName="viewRhythm"
        placement="bottomLeft"
        trigger="click"
        content={<>
          <CloseOutlined
            onClick={hide}
            style={{ position: 'absolute', top: '15px', right: '15px', fontSize: '20px' }}
          />
          <img src={rhythmData?.url} alt="" className="src" />
        </>}
        open={open}
        onOpenChange={handleOpenChange}
      >
        <div className="popoverBtn"
          onMouseEnter={() => setCheckHoverState(true)}
          onMouseLeave={() => setCheckHoverState(false)}
        >
          {rhythmData?.urlText}
          {
            checkHoverState
              ? <img src='//img-ys011.didistatic.com/static/ddo_web_static/do1_ByWnfLYe1Ja4xpG96jv9' />
              : <img src='//img-ys011.didistatic.com/static/ddo_web_static/do1_2qTHtphB24n9mvcwjgl9' />
          }
        </div>
      </Popover>
    </div>
  );
};

export default PageBottom;
