import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

  return useRegisterStyle('pagelet-team-big-overview', () => [
    {
      // base
      [`.${prefixCls}`]: {
        width: '100%',
        height: '100%',
        // minHeight: '220px',
        minWidth: '373px',
        padding: "16px",
        background: '#fff',
        borderRadius: '12px',

        '.title': {
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          'h3': {
            margin: '0px',
            fontSize: '20px',
            fontWeight: '600',
            height: '28px',
            lineHeight: '28px',
            color: 'rgba(0, 0, 0, 0.84)',
            // padding: '1px 6px',
            borderRadius: '6px',
          },
          '.title-org': {
            display: 'flex',
            marginRight: '2px',
            padding: '5px 6px',
            cursor: 'pointer',
            borderRadius: '8px',
            'p': {
              margin: '0px',
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'right',
              color: 'rgba(0, 0, 0, 0.72)'
            },
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.04)'
            }
          }
        },
        '.title-info': {
          margin: '12px 0px 8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ['.switch-type']: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px',
            borderRadius: '6px',
            background: 'rgba(0, 0, 0, 0.04)',
            ['.ant5-btn']: {
              height: '24px',
              padding: '0px 12px',
              color: 'rgba(0, 0, 0, 0.6)',
              borderRadius: '4px',
              borderColor: 'transparent',
              background: 'transparent',
              marginRight: '4px',
              'span': {
                fontSize: '14px',
                fontWeight: '500',
              },
              ['&:hover, &:focus']: {
                color: 'rgba(0, 0, 0, 0.6) !important',
                borderColor: 'transparent !important',
                background: 'rgba(0, 0, 0, 0.08) !important',
              },
              ['&::after, & div']: {
                display: 'none',
              },
              ['&.active']: {
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.84) !important',
                borderColor: '#FFFFFF !important',
                background: '#FFFFFF !important',
                boxShadow: '0px 2px 6px 0px rgba(78, 98, 130, 0.02)',
              }
            }
          },
          ['.team-big-overview-url-icon']: {
            marginLeft: '2px'
          },
          '.title-info-tit': {
            margin: '0px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '22px',
            color: 'rgba(0, 0, 0, 0.84)',
            // padding: '1px 6px',
            borderRadius: '6px',
          },
          '.title-org': {
            display: 'flex',
            marginRight: '2px',
            padding: '5px 6px',
            cursor: 'pointer',
            borderRadius: '8px',
            'p': {
              margin: '0px',
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'right',
              color: 'rgba(0, 0, 0, 0.72)'
            },
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.04)'
            }
          }
        },
        // 搜索弹窗样式
        ['.overview-search-modal']: {
          width: '100%',
          cursor: 'inherit',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          position: 'relative',
          '.overview-search-modal-search':{
            position: 'absolute',
            right: '10px',
            top: '8px',
          },
          '.ant5-select': {
            width: '100%',
            '.ant5-select-selector': {
              background: '#FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.24)',
              '.ant5-select-selection-placeholder':{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.36)',
                fontFamily: 'PingFang SC',
              }
            },
            ['.ant5-select-dropdown']: {
              maxHeight: '450px',
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
              ['.ant5-select-item']: {
                padding: '6px 12px',
                marginBottom: '6px',
                borderRadius: '8px',
                background: 'rgba(245, 245, 245, 0.6)',
                border: '1px solid rgba(0, 0, 0, 0.04)',
              },
              ['.ant5-select-item-option-active']: {
                background: 'rgba(255, 100, 0, 0.02)',
                border: '1px solid #FF6400',
              },
              ['.item-option']: {
                display: 'flex',
                alignItems: 'center',
                ['.ec-base-avatar']: {
                  borderColor: 'rgba(0, 0, 0, 0.04)',
                  minWidth: '28px',
                  ['img']: {
                    width: '28px',
                    height: '28px',
                  }
                },
                ['.item-option-content']: {
                  width: 'calc(100% - 32px)',
                  marginLeft: '8px',
                  color: 'rgba(0, 0, 0, 0.84)',
                  ['.item-option-content-name']: {
                    marginBottom: '4px',
                    lineHeight: '18px',
                    fontWeight: '500',
                    ['.quit, .internship']: {
                      fontSize: '12px',
                      fontWeight: '600',
                      borderRadius: '4px',
                      padding: '2px 4px',
                      marginLeft: '6px',
                    },
                    ['.quit']: {
                      color: '#F5483B',
                      backgroundColor: 'rgba(245, 72, 59, 0.1)',
                    },
                    ['.internship']: {
                      color: 'rgba(0, 150, 89, 0.8)',
                      backgroundColor: 'rgba(0, 150, 89, 0.1)',
                    }
                  },
                  ['.item-option-content-dept']: {
                    marginBottom: '0',
                    lineHeight: '16px',
                  }
                }
              },
              ['.ec-base-empty']: {
                marginTop: '28px',
                ['.ant5-empty-image']: {
                  height: 'auto',
                  ['img']: {
                    width: '80px',
                    height: '66.67px'
                  }
                },
                ['.ant5-empty-description']: {
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: '12px',
                  fontWeight: 'normal',
                }
              },
              ['.data-loading']: {
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '12px',
                fontWeight: 'normal',
                margin: '25px auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '.ant5-spin': {
                  marginBottom: '4px',
                }

              }
            },
          },
        },
        '.overview-abnormal': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100% - 26px - 16px - 16px - 32px)',
          fontSize: '12px',
          color: '#2D3040',
          ['img']: {
            width: '120px',
          },
        },
        // 部门信息
        ['.dept-info-warp']: {
          borderRadius: '8px',
          background: 'rgba(245, 245, 245, 0.6)',
          padding: '12px 20px 9px',
          // display: 'flex',
          // flexWrap: 'wrap',
          display: 'grid',
          gridTemplateColumns: ' repeat(2, 50%)',
          gridGap: '8px 16px',
          '.dept-info': {
            display: 'flex',
            flexDirection: 'column',
            '.dept-info-tit': {
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '18px',
              letterSpacing: '0px',
              color: 'rgba(0, 0, 0, 0.6)',
              paddingLeft: '4px',
            },
            '.dept-info-content': {
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '18px',
              letterSpacing: '0px',
              color: 'rgba(0, 0, 0, 0.84)',
              width: '100%',
              '.dept-info-content-info': {
                maxWidth: 'calc(100% - 80px)',
                marginTop: '2px',
                cursor: 'pointer',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                padding: '2px 4px',
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '6px',
                }
              },
              '.dept-info-content-infos': {
                padding: '2px 4px'
              },
              '.dept-info-content-more': {
                marginTop: '2px',
                color: 'rgba(0, 0, 0, 0.5)',
                fontSize: '12px',
                lineHeight: '12px',
                padding: '4px',
                marginLeft: '4px',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
              },
              '.dept-info-content-more:hover': {
                cursor: 'pointer',
                background: 'rgba(0, 0, 0, 0.04)'
              }
            }

          },
        },
        '.ant5-spin': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '110px',
        },
        // 中文布局
        '.content': {
          // 宽度
          '.present': {
            '.present-employee': {
              width: '65%',
              overflow: 'hidden'
            },
            '.present-probation': {
              width: '35%',
              overflow: 'hidden'
            }
          },
          '.ongoing': {
            '.ongoing-entry': {
              width: '65%',
              overflow: 'hidden'
            },
            '.ongoing-depart': {
              width: '35%',
              overflow: 'hidden'
            }
          },

          // 基础样式
          '.present, .ongoing': {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '2%',
            '.present-employee, .present-probation, .ongoing-entry, .ongoing-depart': {
              minHeight: '66px',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              background: 'rgba(245, 245, 245, 0.6)',
              '.space': {
                padding: '4px',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                borderRadius: '4px',
                cursor: 'pointer',
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.03)',
                },
                '&:last-child': {
                  marginRight: '0px'
                },
                '.block': {
                  width: '100%',
                  'p': {
                    margin: '0px',
                    whiteSpace: 'nowrap'
                  },
                  '.block-number': {
                    marginBottom: '6px',
                    fontFamily: 'DIDI FD',
                    fontSize: '23px',
                    fontWeight: '900',
                    lineHeight: '20px',
                    color: 'rgba(0, 0, 0, 0.82)',
                    'span': {
                      fontFamily: 'DIDI FD',
                      fontSize: '14px'
                    }
                  },
                  '.block-name': {
                    width: '100%',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: 'rgba(0, 0, 0, 0.72)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  },
                  '.outsourcing': {
                    color: 'rgba(0, 0, 0, 0.82)'
                  }
                },
                '.block-expand': {
                  marginLeft: '6px',
                  marginBottom: '2px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  'p': {
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    marginTop: '2px',
                    marginBottom: '0px',
                    fontSize: '10px',
                    lineHeight: '10px',
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.72)',

                    'span': {
                      display: 'inline-block',
                      maxWidth: '40px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  }
                }
              }
            },

            // 覆盖样式
            '.present-employee, .ongoing-entry': {
              display: 'flex',
              justifyContent: 'flex-start',
              marginRight: '2%',
              overflow: 'hidden'
            },
            '.present-employee': {
              '.space': {
                flex: '1'
              }
            },
            '.present-probation': {
              '.space': {
                justifyContent: 'space-between',
                width: '100%',
                padding: '0px',
                marginRight: '0px',
                '&:hover': {
                  background: 'none',
                },
                '.block': {
                  // maxWidth: '50px',
                  width: '70%',
                  padding: '4px',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.03)',
                    borderRadius: '6px',
                  }
                },
                '.block-expand': {
                  // marginLeft: '10px',
                  'p': {
                    color: '#F5483B',
                    padding: '2px 6px',
                    'span': {
                      color: 'rgba(0, 0, 0, 0.72)'
                    },
                    '&:hover': {
                      background: 'rgba(0, 0, 0, 0.03)',
                      borderRadius: '6px',
                    }
                  }
                }
              }
            },
            '.ongoing-entry': {
              background: 'rgba(17, 159, 154, 0.05)',
              paddingBottom: '4px',
              '.space': {
                flex: '1',
                // marginRight: '2%',
                '&:hover': {
                  background: 'none',
                },
                '.block': {
                  padding: '2%',
                  '.block-number': {
                    height: '20px',
                    color: '#009659'
                  },
                  '&:hover': {
                    background: 'rgba(0, 150, 89, 0.04)',
                    borderRadius: '6px',
                  },
                },
                '.block-expand': {
                  'p': {
                    padding: '2px 6px',
                    '&:hover': {
                      background: 'rgba(0, 150, 89, 0.04)',
                      borderRadius: '6px',
                    }
                  }
                }
              },
              // width
              '.ontheway': {
                '.block': {
                  // 去掉校招
                  // width: 'auto'
                }
              },
              '.transfer-in': {
                marginRight: '0px',
              },
              '.onboarding': {
                overflow: 'hidden'
              },
            },
            '.ongoing-depart': {
              background: 'rgba(10, 112, 245, 0.05)',
              justifyContent: 'space-between',
              paddingBottom: '4px',
              '.space': {
                padding: '4px',
                // flex: '1',
                '&:hover': {
                  background: 'rgba(10, 112, 245, 0.06)',
                },
                '.block': {
                  '.block-number': {
                    color: '#0A70F5',
                  }
                }
              },
              '.off-boarding': {
                '.block': {
                  '.block-number': {
                    color: 'rgba(0, 0, 0, 0.84)'
                  }
                }
              },
              '&:last-child': {
                marginRight: '0px'
              }
            }
          }
        },

        // 英文适配
        '.content-english': {
          '.present': {
            '.present-employee': {
              '.space': {
                overflow: 'hidden'
              }
            },
          },
          '.ongoing': {
            '.ongoing-entry': {
              '.space': {
                '.block-expand': {
                  'p': {
                    'span': {
                      minWidth: '11px',
                      maxWidth: '35px'
                    }
                  }
                }
              },
              '.transfer-in': {
                overflow: 'hidden'
              },
              '.onboarding': {
                maxWidth: '50px'
              },
            },
            '.ongoing-depart': {
              '.space': {
                maxWidth: '40px'
              }
            }
          }
        }
      },

      // tooltipo
      ['.ant5-tooltip.ec-base-tooltip']: {
        '.ant5-tooltip-content': {
          '.ant5-tooltip-inner': {
            '.tooltip-title': {
              'h4': {
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: '0px'
              },
              '.span': {
                display: 'inline-block',
                marginTop: '4px'
              }
            }
          }
        }
      }
    }
  ]);
};

export default useStyle;
