import React, { useRef } from 'react';
import classNames from 'classnames';
import useStyle from './styles';

const NoData = props => {
  const { text, style } = props;

  const prefixCls = 'no-data';
  const wrapSSR = useStyle('no-data');
  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes} style={{ ...style }}>
      <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/no_data_image.png" alt="" />
      <p>{text}</p>
    </div>
  );
};

export default NoData;
