//准备初始数据
export default {
  apolloConfig: {
    pageLetInfo: {
      tipText: []
    }, // 组件的信息
    helpInfo: {}, // 帮助信息
    monthUrl: '',
    cycleUrl: '',
  }, // apollo 配置信息
  assessmentInfo: {}, // 考核信息
  stateCode: 0,
  stateDesc: '',
  empId: '',
  performanceId: '',
};
