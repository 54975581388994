import { Modal, message } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  on, request, emit
} from '@dhr/common';

// 获取导出日期
const getExportTime = () => {
  const time = new Date()
  const year = time.getFullYear()
  let month = time.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = time.getDate()
  day = day < 10 ? `0${day}` : day
  return `${year}_${month}_${day}`
}
// 特殊报表
const fileNames = ['员工转正名单', '员工周年名单', '员工生日名单']

// 获取默认文件名
const getDefaultName = (defaultName, date) => {
  for (const file of fileNames) {
    if(defaultName == file) {
      return `${date}${defaultName}`
    }
  }
  return `${getExportTime()}${defaultName}`
}

const ExportButton = (props) => {
  const { showEmployeeCare = () => { } } = props;
  const [visible, setIsVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)  //防止导出过程中取消
  const [fileName, setName] = useState('')


  const handleClick = () => {
    if(props.preCheck){
      const pass = props.preCheck();
      if(!pass){
        return
      }
    }

    emit('exportFile: clicked')
    on('exportFile: exceed', () => {
      message.warning('其他文件正在导出中，请稍后重试')
      return
    })

    // 导出筛选项为0时
    if (props.filterNums === 0) {
      return Modal.confirm({
        title: '您未选择任何筛选条件，数据量过大，导出时间较长，确认导出吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => setIsVisible(true),
        className: 'filterModal'
      });
    }

    setIsVisible(true)
  }

  const handleCancel = () => {
    setIsVisible(false)
  }

  const handleChange = (e) => {
    setName(e.target.value)
  }


  // 导出
  const handleExport = async () => {
    // const fileName = document.querySelector('.input-file-name').value
    if (fileName.length < 1) {
      message.warning('文件名不能为空');
      return
    }
    setIsDisabled(true)
    setConfirmLoading(true)
    const data = props.data
    // data['fileName'] = fileName.slice(-4) === '.xls' ? fileName : fileName + '.xls'
    data['fileName'] = fileName
    try {
      const requestBody = {
        method: 'post',
        url: props.url,
        data: data,
        encryption: props?.encryption,
      }
      if (props?.headers) {
        requestBody.headers = props.headers
      }
      const res = await request(requestBody);
      if (res?.code == 100200) {
        props?.closeDrawer();
        showEmployeeCare(false);
        message.success('请至下载中心获取导出的数据文件')
        emit('exportFile: success')
        addAnimation()
      }
    } catch (e) {
      console.log("error: ", e);
      if (e?.data?.code == 100400) {
        message.warning(e.data.msg)
      }
    } finally {
      setConfirmLoading(false)
      setIsDisabled(false)
      handleCancel()
      // setName(getDefaultName(props.defaultFile))
    }
  }

  // 添加动画
  const addAnimation = () => {
    const X = document.querySelector('#holiday-detail')
    const dot = document.querySelector('.ant-badge').getBoundingClientRect()
    const { x, y } = X.getBoundingClientRect()
    var newX = X.cloneNode()
    newX.style.backgroundColor = '#ED7343'
    newX.style.zIndex = 999
    newX.style.position = 'fixed'
    newX.style.left = `${x}px`
    newX.style.top = `${y}px`
    X.appendChild(newX)
    window.scrollX
    newX.className = 'dot'
    newX.style.left = `${dot.x + 75}px`
    newX.style.top = `${dot.y}px`
    newX.style.zIndex = -999
    newX.style.pointerEvents = 'none'
    newX.style.cursor = 'default'
  }

  useEffect(() => {
    setName(getDefaultName(props.defaultFile, props.date))
  }, [visible])

  return (
    <>
      <div className="export-buttons" onClick={handleClick} >{props.text || '导出'}</div>
      <Modal
        title={props.text || '导出文件'}
        visible={visible}
        className="export-modal"
        onCancel={handleCancel}
        width={460}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okText='确认'
        onOk={handleExport}
        cancelButtonProps={{ disabled: isDisabled }}
        maskClosable={false}
      >
        <p className="fileTitle"><span>*</span> 文件命名:</p>
        <input type="text" className="input-file-name" placeholder="请输入长度40以内字符" maxLength="40" minLength="1" required value={fileName} onChange={handleChange} />
        <p className="file-hint">确认后将生成文件，可至右上角“下载中心”查看和下载</p>
      </Modal>
    </>
  )
}
export default ExportButton
