import React from 'react';
import ReactDOM from 'react-dom';
import SingleSpaReact from 'single-spa-react';
import Pagelet from './pagelet';

export default SingleSpaReact({
  React,
  ReactDOM,
  rootComponent: Pagelet,
});
