import React, { useState, useEffect } from 'react';
import { getLocale, ConfigProvider } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import { RightThickOutlined, VisibilityOnOutlined, VisibilityOffOutlined } from '@didi/iconflow/ec';
import { Tooltip } from 'antd';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import useStyle from './style';

const welfareWindowImg = "https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/img-welfare-window-pagelet.png";
const showMoreIcon = "https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/icon-show-more.svg";
const shouqiIcon = "https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/icon-shouqi.svg"

const mockData = [{skuName: 'hahahahha', employeeName: 'hahahahah', endDate: '2022.01.01'}, {skuName: 'hahahahha', employeeName: 'hahahahah', endDate: '2022.01.01'}, {skuName: 'hahahahha', employeeName: 'heeee', endDate: '2022.01.01'}, {skuName: 'aa', employeeName: 'hahahahah', endDate: '2022.01.01'}]

const localLocales = manifest.locales || {};

const localHeaders = {
  'Accept-Language': 'zh-CN',
  'Authorization': '',
  'bricks-app-key': '653741',
  'dhrToken': 'test'
}

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, auth }, usePermissionResponse
  } = props;
  const [selects, setSelects] = useState(0);
  const [welfareProductList, setWelfareProductList] = useState([]);
  const [welfareWindowTerm, setWelfareWindowTerm] = useState({});
  const [showWelfareList, setShowWelfareList] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);
  const [serviceErr, setServiceErr] = useState(false);
  const [emplVisible, setEmplVisible] = useState(false);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  // 暂不使用多语言
  // const intl = Intl(locale);
  const intl = text => text;

  const prefixCls = 'pagelet-benefit-info';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);
  const classes = classNames(prefixCls);

  const handleRequest = async () => {
    try {
      const reqHeaders = {...localHeaders, ...headers, 'bricks-function-id': auth.id};
      const res = await PageletServices.getBenefitInfo(apiHost, {}, reqHeaders, usePermissionResponse);
      if (res?.code === '100200') {
        const {selects, welfareProductList, welfareWindowTerm} = res.data || {};
        setSelects(selects);
        setWelfareProductList(welfareProductList);
        setWelfareWindowTerm(welfareWindowTerm);
        setShowWelfareList(welfareProductList?.slice(0, 3));
      } else if (res?.code === '100501') {
        setServiceErr(true);
      }
    } catch (e) {
      setServiceErr(true);
      console.log(e);
    }
  };

  const handleShowMore = () => {
    isShowMore ? setShowWelfareList(welfareProductList.slice(0, 3)) : setShowWelfareList(welfareProductList);
    isShowMore ? setIsShowMore(false) : setIsShowMore(true);
  }

  const handleOpenBenefitSelect = () => {
    window.open('https://benefit.didichuxing.com/pc/mine-benefit-select', '_blank');
  }

  const handleOpenBenefitHome = () => {
    window.open('https://benefit.didichuxing.com/pc/index', '_blank');
  }

  const handleOpenBenefitWindow = () => {
    window.open('https://mis.diditaxi.com.cn/auth/sso/login?app_id=2100599&version=1.0&jumpto=index', '_blank')
  }
  
  const handleCheckBenefit = () => {
    if (welfareWindowTerm?.isWindowTerm) {
      handleOpenBenefitWindow();
    } else if (selects > 0) {
      handleOpenBenefitSelect();
    } else {
      handleOpenBenefitHome();
    }
  }

  const changeEmplVisible = () => {
    setEmplVisible(!emplVisible);
    localStorage.setItem('empl-visible', !emplVisible);
  }

  useEffect(() => {
    handleRequest();
    const emplVisibleDefault = localStorage.getItem('empl-visible', emplVisible) === 'true';
    setEmplVisible(emplVisibleDefault);
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <DHRCardTitle
          title={<>
            我Di福利
            <Tooltip
              title={emplVisible ? '隐藏受保人' : '显示受保人'}
              placement="topLeft"
              getPopupContainer={trigger => trigger.parentElement}
              mouseLeaveDelay={0.1}
            >
              {!welfareWindowTerm?.isWindowTerm && selects > 0 ? <div onClick={changeEmplVisible} className="empl-visible-btn">
                {emplVisible ? <VisibilityOnOutlined cursor="pointer" size={16} /> : <VisibilityOffOutlined cursor="pointer" size={16} />}
              </div> : null}
            </Tooltip>
          </>}
          type="grey"
          titleAdded={
            <DHRCardButton onClick={handleCheckBenefit}>
              查福利 <RightThickOutlined cursor="pointer" size={14} className="right-icon"/>
            </DHRCardButton>
          }
        />
        {serviceErr ? <div className={`${prefixCls}-content-err`}>
          <img
            src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
          />
          <span className='text-item-1'>服务不可用，请稍候再试。</span>
        </div> : <>
          {
            !welfareWindowTerm?.isWindowTerm && selects > 0 ? <div className="welfare-info-content">
              {
                showWelfareList?.length ? showWelfareList?.map(item => {
                  return (
                    <div className="welfare-item" onClick={handleOpenBenefitSelect}>
                      <div className="sku-name">{intl(item?.skuName)}</div>
                      <div className="empl-info">
                        <div className="empl-name">{intl('受保人')}:{emplVisible ? intl(item?.employeeName) : '***'}</div>
                        <div className="end-date">{intl('截止至')}:{item?.endDate}</div>
                      </div>
                    </div>
                  )
                }) : null
              }
              {selects > 3 && <div onClick={handleShowMore} className="show-more-btn">
                {isShowMore ? intl('收起') : `展开剩余${selects - 3}项`}
                <img src={isShowMore ? shouqiIcon : showMoreIcon} />
              </div>}
            </div> : null
          }
          {
            !welfareWindowTerm?.isWindowTerm && !selects ? <div className="welfare-text-content" onClick={handleOpenBenefitHome}>
              <div className="welfare_window_text">
                <div>{intl('快来福利平台了解')}</div>
                <div>{intl('我Di福利内容')}</div>
              </div>
              <div className="welfare_window_end_date">{intl('体验各项福利吧~')}</div>
              <img src={welfareWindowImg} />
            </div> : null
          }
          {
            welfareWindowTerm?.isWindowTerm ? <div className="welfare-text-content" onClick={handleOpenBenefitWindow}>
              <div className="welfare_window_text">
                <div>{intl('弹性福利窗口期开放啦,')}</div>
                <div>{intl('快来选吧~')}</div>
              </div>
              <div className="welfare_window_end_date">{intl('时间截止')}:{welfareWindowTerm?.choiceEndDate}</div>
              <img src={welfareWindowImg} />
            </div>: null
          }
        </>}
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
