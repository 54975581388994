import { useRegisterStyle } from '@didi/ec-base';

const useModalStyle = (prefixCls) => {
  const antModalCls = `.${prefixCls}`;

	return useRegisterStyle('upload-resume-container', () => [
		{
      ['.upload-resume-container']: {
        background: '#FFFFFF',
        border: '1px dashed rgba(0, 0, 0, 0.34)',
        borderRadius: '8px',
        height: '128px',

        ['.ant5-upload-wrapper']: {
          ['.ant5-upload-drag']: {
            ['&:hover']: {
              borderColor: '#0A70F5',
            }
          }
        },
        
        ['.upload-text']: {
          fontFamily: 'PingFang SC',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '20px',
          color: '#0A70F5',
          marginBottom: '4px',
        },
        
        ['.upload-hint']: {
          fontFamily: 'PingFang SC',
          fontSize: '12px',
          fontWeight: 'normal',
          lineHeight: '20px',
          color: '#0000005C',
        },

        ['.upload-drag-icon']: {
          marginTop: '20px',
          marginBottom: '4px',
          
          ['svg']: {
            fontSize: '16px',
          }
        },

        ['.ant5-modal-footer']: {
          ['.ant5-btn-default']: {
            ['&:hover']: {
              color: '#0A70F5 !important',
              borderColor: '#0A70F5 !important',
            }
          },
          
          ['.ant5-btn-primary']: {
            background: '#0A70F5 !important',
          }
        }
      },
		}
	]);
};

export default useModalStyle;

