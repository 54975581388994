import React from 'react';
const showEllipseWord = (str, splitWord = '>', isShowHover = true) => {
    const arr = str.split(splitWord);
    const len = arr.length;
    if (len < 4) return str;
    if (isShowHover) {
      return (
        <Tooltip title={str}>
          {`${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`}
        </Tooltip>
      );
    }
    return `${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`;
  };
export {showEllipseWord}