import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-pay-info', () => [
		{
			// base
			[`.${prefixCls}`]: {
        color: chartToken.$font_color,
        fontSize: variable.size,
				width: '100%',
				minHeight: '100px',
        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute'
        },
				[`.${prefixCls}-content-err`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '262px',
          justifyContent: 'center',
          ['img']: {
            width: '120px',
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
        },
				['.right-icon']: {
					marginLeft: 2,
				},
				['.pay-day-block']: {
					cursor: 'pointer',
					marginTop: 12,
					fontFamily: 'PingFang SC',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '20px',
					background: 'rgba(255, 255, 255, 0.8)',
					backgroundImage: "url('https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/bg-salary-right3.png'), url('https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/bg-salary-content-pagelet.png')",
					backgroundRepeat: 'no-repeat, no-repeat',
					backgroundPosition: 'bottom right, top left ',
					backgroundSize: '40px 40px, 100% 100%',
					border: '0.5px solid rgba(63, 173, 85, 0.1)',
					borderRadius: 6,
					padding: '20px 16px',
					'&:hover': {
						border: '0.5px solid #3FAD55',
					},
					['.day-num']: {
						fontWeight: 500,
						display: 'inline-block',
						margin: '0 3px',
					}
				}
			}
		}
	]);
};

export default useStyle;
;