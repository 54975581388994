import React, { useState, useEffect, useRef } from "react";
import { Spin, message } from "antd";
import "./index.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import classNames from "classnames";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Color from "./images/color.png";
import Bold from "./images/bold.png";
import { langMaps, tabsLangMaps, messageMaps } from "../../utils/Tool";

const configs = {
  // key: '11',
  stripPastedStyles: true,
  toolbarClassName: "questionsetting-controlbar-classname",
  toolbarOnFocus: true, // Toolbar is visible only when editor is focused.
  toolbar: {
    options: ["fontSize", "inline", "colorPicker"],
    colorPicker: { icon: Color },
    inline: {
      inDropdown: false,
      options: ["bold"],
      bold: { icon: Bold },
    },
    fontSize: {
      options: [
        10,
        12,
        14,
        16,
        18,
        20,
        22,
        24,
        26,
        28,
        30,
        32,
        34,
        36,
        38,
        40,
        42,
        44,
        46,
        48,
        50,
      ],
    },
  },
  wrapperStyle: { width: "100%", position: "relative" },
  editorStyle: { padding: "0 11px", overflow: "hidden" },
  toolbarStyle: { top: "-25px" },
};

const CTEditor = (props) => {
  const {
    values,
    currentLanguage, // 当前语言
    showIcon = true, // 是否展示语言icon
    editorConfigs = {}, // 富文本编辑器配置
    getEditorValue,
    view = false,
    messageType,
    maxLength,
    chRef,
    placeholderType,
  } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentClassName, setContentClassName] = useState(
    "ct-questionlib-editor"
  );
  const [loading, setLoading] = useState(false);

  const transferToEditorState = (content) => {
    const contentBlock = htmlToDraft(content);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const newEditorState = EditorState.createWithContent(contentState);
    return newEditorState;
  };

  useEffect(() => {
    if (values?.name || values?.display) {
      const obj = transferToEditorState(
        decodeURIComponent(values?.styleName || values?.name || "")
      );
      setEditorState(obj);
    } else {
      setEditorState("");
    }
  }, [values, currentLanguage]);

  // 初始化赋值
  useEffect(() => {
    const obj = transferToEditorState(
      decodeURIComponent(values?.styleName || values?.name || "")
    );
    setEditorState(obj);
  }, []);

  // 编辑器发生改变
  const handleContentChange = (e) => {
    setEditorState(e);
  };

  // 获取焦点
  const handleOnFocus = (e) => {
    window.focus = true;
    setContentClassName("ct-questionlib-editor editor-focus");
  };

  // 返回修改项的数据
  const getData = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const styleName = draftToHtml(rawContent);
    let name = "";
    rawContent.blocks.map((item) => {
      name += item.text;
    });
    name = name.replaceAll("\n", "");
    // prompt(name);
    return {
      languageType: values?.languageType || currentLanguage,
      name,
      styleName: name ? encodeURIComponent(styleName) : "",
    };
  };

  const handleOnBlur = (e) => {
    setContentClassName("ct-questionlib-editor");
    getEditorValue(getData());
  };

  // const setLoadingFalse = () => {
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 300);
  // }

  // 语言标签
  const LangIcon = () => {
    const langText = langMaps[currentLanguage];
    return (
      <div className="ct-editor-icon-content">
        <span
          className={classNames({
            "cn-language-class": currentLanguage === 0,
            "other-language-class": currentLanguage !== 0,
          })}
        >
          {langText}
        </span>
      </div>
    );
  };

  const placeholderTips = (placeholderType) => {
    let tips = "";
    switch (placeholderType) {
      case "title":
        tips = `请输入题目${tabsLangMaps[currentLanguage]}内容`;
        break;
      case "option":
        tips = `请输入选项${tabsLangMaps[currentLanguage]}内容`;
        break;
      case "questionnaireTitle":
        tips = `请输入问卷名称${tabsLangMaps[currentLanguage]}内容`;
        break;
      case "questionnairePreface":
        tips = `请输入卷首语${tabsLangMaps[currentLanguage]}内容`;
        break;
      case "questionnaireTail":
        tips = `请输入卷尾语${tabsLangMaps[currentLanguage]}内容`;
        break;
    }
    return tips;
  };

  return (
    <>
      <div
        className={classNames("ct-editor-wrap", {
          "ct-editor-wrap-view": view,
        })}
      >
        {showIcon ? <LangIcon /> : ""}
        <Editor
          ref={chRef}
          {...configs}
          {...editorConfigs}
          editorState={editorState}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={placeholderTips(placeholderType)}
          editorClassName={contentClassName}
          readOnly={view}
          onEditorStateChange={handleContentChange}
        />
      </div>
    </>
  );
};

export default CTEditor;
