import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // get请求示例
  getPerson: async (host, params) => {
    const request = PageletApi.newRequest.getInstance('holiday_person');
    const result = await request(`${host}/${apis.getPerson}`, {
      method: 'get',
      params
    });
    return result;
  },

  // post请求示例
  postRequestData: async (host, data) => {
    const request = PageletApi.newRequest.getInstance('holiday_person');
    const result = await request(`${host}/${apis.getList}`, {
      method: 'post',
      data
    });
    return result;
  }
}

export default PageletServices;
