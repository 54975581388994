import React, { useRef, useEffect, useState, useMemo } from 'react';

import './index.less';
import { Tooltip } from 'antd';
import { Popover } from 'antd';
import { LangTranslate } from '../../utils';

const GridBoxCell = props => {
  const { itx, ity, genBlockName, curBlockActivity, genCurDotStyle, activityId } = props;
  return (
    <div
      className="talent-market-custom-time-line-nine-box-cell"
      data-block-iloc={`${itx?.axisId}-${ity?.axisId}`}
      style={{
        ...genCurDotStyle({ x: itx?.axisId, y: ity?.axisId }),
        flex: 1,
        width: '172px',
        height: '96px',
        borderRadius: '12px',
        padding: '8px 10px',
      }}
    >
      <div className="talent-market-custom-time-line-nine-box-cell-title">
        {genBlockName({ x: itx?.axisId, y: ity?.axisId })}
      </div>
      {!!curBlockActivity?.length && curBlockActivity?.length < 4 && (
        <div className="talent-market-custom-time-line-nine-box-cell-activity-list">
          {curBlockActivity?.map(activity => (
            <div
              data-current-select-activity={activityId === activity?.activityId}
              className="talent-market-custom-time-line-nine-box-cell-activity-list-item"
            >
              <Tooltip destroyTooltipOnHide placement="topLeft" title={activity?.activityName}>
                {activity?.activityYear || '-'}
              </Tooltip>
            </div>
          ))}
        </div>
      )}
      {!!curBlockActivity?.length && curBlockActivity?.length > 3 && (
        <div className="talent-market-custom-time-line-nine-box-cell-activity-list">
          {curBlockActivity?.slice(0, 3)?.map(activity => (
            <div
              data-current-select-activity={activityId === activity?.activityId}
              className="talent-market-custom-time-line-nine-box-cell-activity-list-item"
            >
              <Tooltip destroyTooltipOnHide placement="topLeft" title={activity?.activityName}>
                {activity?.activityYear || '-'}
              </Tooltip>
            </div>
          ))}
          <div className="talent-market-custom-time-line-nine-box-cell-activity-list-popover-wrap">
            <Popover
              placement="bottom"
              arrow={false}
              autoAdjustOverflow
              getPopupContainer={e => e?.parentNode}
              overlayClassName="talent-market-custom-time-line-nine-box-cell-activity-list-popover"
              content={
                <div className="talent-market-custom-time-line-nine-box-cell-activity-list-popover-content">
                  {curBlockActivity?.slice(3)?.map(it => (
                    <div data-current-select-activity={activityId === it?.activityId}>
                      <Tooltip
                        placement="topLeft"
                        destroyTooltipOnHide
                        title={it?.activityName || '-'}
                      >
                        {it?.activityName || '-'}
                      </Tooltip>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="talent-market-custom-time-line-nine-box-cell-activity-list-popover-dot-inner">
                <div />
                <div />
                <div />
              </div>
            </Popover>
          </div>
        </div>
      )}
    </div>
  );
};

function CustomTimeLineHistoryNineBox(props) {
  const {
    data: { current: originData, allActivityMap },
    activityId,
    scale = 1,
  } = props;

  const XAxis = useMemo(() => originData?.axisInfo?.x_axis?.segments || [], [originData]);
  const YAxis = useMemo(
    () => [...originData?.axisInfo?.y_axis?.segments]?.reverse() || [],
    [originData],
  );
  const [curBlocksActivityIdMap, setCurBlocksActivityIdMap] = useState({});
  const gridBlocks = useMemo(() => originData?.gridBlocks, [originData]);
  useEffect(() => {
    const tmpMap = {};
    gridBlocks?.forEach(block => {
      const activityList = block?.activityYears?.map(activityId => {
        return allActivityMap?.[activityId];
      });
      tmpMap[`${block?.x_axisInfo.axisId}-${block?.y_axisInfo.axisId}`] = activityList;
    });
    setCurBlocksActivityIdMap(() => tmpMap);
  }, [gridBlocks, allActivityMap]);

  const genBlockName = ({ x, y }) => {
    const curBlock = gridBlocks?.find(
      block => block?.x_axisInfo?.axisId === x && block?.y_axisInfo?.axisId === y,
    );
    return LangTranslate('{loc}档', { loc: curBlock?.blockPosition });
  };
  const genCurDotStyle = ({ x, y }) => {
    const curBlock = gridBlocks?.find(
      block => block?.x_axisInfo?.axisId === x && block?.y_axisInfo?.axisId === y,
    );
    return {
      backgroundColor: curBlock?.blockColor,
      '--grid-blocks-history-cell-txt-color': curBlock?.selectedColor,
      '--grid-blocks-history-cell-dot-bg-color': curBlock?.selectedColor,
    };
  };

  return (
    <div
      className="talent-market-custom-time-line-history-nine-box-cpn-wrap"
      style={{ zoom: scale }}
    >
      <div className="talent-market-custom-time-line-history-nine-box-cpn-content">
        <div className="talent-market-custom-time-line-history-nine-box-cpn-row-yaxis-wrap">
          {YAxis?.map?.((ity, idy) => (
            <div className="talent-market-custom-time-line-history-nine-box-cpn-row-yaxis">
              <Tooltip destroyTooltipOnHide title={ity?.segmentLabel}>
                {ity?.segmentLabel}
              </Tooltip>
            </div>
          ))}
        </div>

        <div className="talent-market-custom-time-line-history-nine-box-cpn-row-wrap">
          {YAxis?.map?.((ity, idy) => (
            <div className="talent-market-custom-time-line-history-nine-box-cpn-row">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  columnGap: '7px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    columnGap: '6px',
                  }}
                >
                  {XAxis?.map?.((itx, idx) => (
                    <GridBoxCell
                      activityId={activityId}
                      itx={itx}
                      ity={ity}
                      genBlockName={genBlockName}
                      genCurDotStyle={genCurDotStyle}
                      curBlockActivity={curBlocksActivityIdMap?.[`${itx?.axisId}-${ity?.axisId}`]}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="talent-market-custom-time-line-history-nine-box-cpn-row-xaxis-wrap">
        {XAxis?.map(it => (
          <div>
            <Tooltip destroyTooltipOnHide title={it?.segmentLabel}>
              {it?.segmentLabel}
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomTimeLineHistoryNineBox;
