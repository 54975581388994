import React from 'react';
import { Field, useField, observer, ArrayField, ObjectField } from '@formily/react';
import { Modal } from 'antd';
import CustomTagCell from '@/cpns/CustomTagCell';
import { LangTranslate } from '@/utils';
import { FormItem, FormLayout } from '@formily/antd-v5';
import SvgIcon from '@/cpns/SvgIcon';
import CustomReadPrettyToolTipInput from '@/cpns/CustomReadPrettyToolTipInput';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

const CustomTagPoolCell = observer(() => {
  const field = useField();
  return (
    <div className="pagelet-label-system-tag-pool-config-cell-wrap">
      {field.value?.map?.((item, index) => (
        <ObjectField name={index}>
          <div className="pagelet-label-system-tag-pool-config-cell-item-wrap">
            <div className="pagelet-label-system-tag-pool-config-cell-item-top-bar">
              <div className="pagelet-label-system-tag-pool-config-cell-item-top-bar-title-wrap">
                <div className="pagelet-label-system-tag-pool-config-cell-item-top-bar-title">{`${LangTranslate('标签')}${index + 1}`}</div>
                {/*   <div className="pagelet-label-system-tag-pool-config-cell-item-top-bar-tips">
                  <div>{`${LangTranslate('标签ID')}:`}</div>
                  <div>
                    <Field
                      name="id"
                      title={null}
                      component={[Input, { variant: 'borderless', size: 'small', readOnly: true }]}
                    />
                  </div>
                </div> */}
              </div>
              {!field?.readPretty ? (
                <div className="pagelet-label-system-tag-pool-config-cell-item-btns">
                  <div
                    data-btn-disabled={index === 0}
                    className="pagelet-label-system-tag-pool-config-cell-item-btns-item"
                    onClick={() => {
                      if (index === 0) {
                        return;
                      }
                      field?.moveUp?.(index);
                    }}
                  >
                    <div
                      style={{ transform: 'rotate(180deg)' }}
                      className="tag-pool-config-cell-item-btns-item-icon"
                    >
                      <SvgIcon type="icon-move-down" size={14} pointer />
                    </div>

                    <div className="tag-pool-config-cell-item-btns-item-text">
                      {LangTranslate('上移')}
                    </div>
                  </div>
                  <div
                    data-btn-disabled={index === field?.value?.length - 1}
                    className="pagelet-label-system-tag-pool-config-cell-item-btns-item"
                    onClick={() => {
                      if (index === field?.value?.length - 1) {
                        return;
                      }
                      field?.moveDown?.(index);
                    }}
                  >
                    <div className="tag-pool-config-cell-item-btns-item-icon">
                      <SvgIcon type="icon-move-down" size={14} pointer />
                    </div>

                    <div className="tag-pool-config-cell-item-btns-item-text">
                      {LangTranslate('下移')}
                    </div>
                  </div>
                  <div
                    data-btn-disabled={field?.value?.length === 1}
                    className="pagelet-label-system-tag-pool-config-cell-item-btns-item"
                    onClick={() => {
                      if (field?.value?.length === 1) {
                        return;
                      }
                      const cModal = Modal.confirm({
                        title: LangTranslate('是否删除该标签？'),
                        content: LangTranslate('删除后无法恢复'),
                        okButtonProps: {},
                        className: 'pagelet-label-system-del-confirm-wrap',
                        footer: (
                          <div className="pagelet-label-system-del-confirm-footer">
                            <div
                              className="pagelet-label-system-del-confirm-footer-btn"
                              onClick={() => {
                                cModal?.destroy?.();
                              }}
                            >
                              {LangTranslate('取消')}
                            </div>
                            <div
                              data-btn-primary={true}
                              className="pagelet-label-system-del-confirm-footer-btn"
                              onClick={() => {
                                const oldVal = cloneDeep(field.value);
                                oldVal.splice(index, 1);
                                field.setValue(oldVal);
                                cModal?.destroy?.();
                              }}
                            >
                              {LangTranslate('删除')}
                            </div>
                          </div>
                        ),
                      });
                    }}
                  >
                    <div className="tag-pool-config-cell-item-btns-item-icon">
                      <SvgIcon type="icon-shanchu" size={16} pointer />
                    </div>
                    <div className="tag-pool-config-cell-item-btns-item-text">
                      {LangTranslate('删除标签')}
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="pagelet-label-system-tag-pool-config-cell-item-content">
              <div className="pagelet-label-system-tag-pool-config-cell-tag-base-info-wrap">
                <FormLayout layout={['vertical']} labelAlign={['left']}>
                  <div className="pagelet-label-system-tag-pool-config-cell-tag-base-info-label">
                    <Field
                      name="name"
                      title={LangTranslate('标签名称')}
                      validator={[
                        { required: true, message: LangTranslate('必填项') },

                        val =>
                          /^\s+|\s+$/.test(val)
                            ? Promise.reject(LangTranslate('前后不能有空格'))
                            : Promise.resolve(),

                        { maxLength: 200, message: LangTranslate('字数超长') },
                      ]}
                      component={[
                        CustomReadPrettyToolTipInput,
                        { placeholder: LangTranslate('请输入标签名称'), style: { height: 36 } },
                      ]}
                      decorator={[FormItem]}
                    />
                  </div>
                  <div className="pagelet-label-system-tag-pool-config-cell-tag-base-info-code">
                    <Field
                      name="code"
                      title={LangTranslate('标签标识')}
                      validator={[
                        val =>
                          /^\s+|\s+$/.test(val)
                            ? Promise.reject(LangTranslate('前后不能有空格'))
                            : Promise.resolve(),
                        { required: false, message: LangTranslate('必填项') },
                        { maxLength: 200, message: LangTranslate('字数超长') },
                        {
                          pattern: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,
                          message: LangTranslate('支持的文本格式：中文、英文、下划线、数字'),
                        },
                      ]}
                      component={[
                        CustomReadPrettyToolTipInput,
                        { placeholder: LangTranslate('请输入标签标识'), style: { height: 36 } },
                      ]}
                      decorator={[FormItem]}
                    />
                  </div>
                  <div className="pagelet-label-system-tag-pool-config-cell-tag-base-info-desc">
                    <Field
                      name="description"
                      validator={[
                        val =>
                          /^\s+|\s+$/.test(val)
                            ? Promise.reject(LangTranslate('前后不能有空格'))
                            : Promise.resolve(),

                        { maxLength: 1000, message: LangTranslate('字数超长') },
                      ]}
                      title={LangTranslate('标签描述')}
                      component={[
                        CustomReadPrettyToolTipInput,
                        { placeholder: LangTranslate('请输入标签描述'), style: { height: 36 } },
                      ]}
                      decorator={[FormItem]}
                    />
                  </div>
                </FormLayout>
              </div>
              <div className="pagelet-label-system-tag-pool-config-cell-tag-config-wrap">
                <div className="pagelet-label-system-tag-pool-config-cell-tag-config-label-wrap">
                  <div className="pagelet-label-system-tag-pool-config-cell-tag-config-label-require-icon">
                    *
                  </div>
                  <div className="pagelet-label-system-tag-pool-config-cell-tag-config-label">
                    {`${LangTranslate('标签类型')}:`}
                  </div>
                </div>
                <ObjectField
                  reactions={[
                    field => {
                      const formInstance = field.form;
                      if (field?.value?.isLocNewItem) {
                        formInstance.setFieldState(`${field.path.entire}.type`, typeField => {
                          console.log('typeField', typeField.path.entire);
                          if (typeField?.readPretty) {
                            typeField.setPattern('editable');
                          }
                        });
                      }
                    },
                  ]}
                  name="tagConfigDetail"
                  component={[CustomTagCell]}
                />
              </div>
            </div>
          </div>
        </ObjectField>
      ))}
    </div>
  );
});
export default CustomTagPoolCell;
