import React from 'react';

function CustomLogicDescLodash() {
  return (
    <div
      style={{
        width: '100%',
        height: '1px',
        background: 'rgba(0, 0, 0, 0.06)',
        margin: '12px 0 -4px',
      }}
    />
  );
}

export default CustomLogicDescLodash;
