import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

   // 获取沧海轮播图数据
  getCanghaiCarouselData: async (host, params) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-canghai-carousel');
    const result = await request(`${host}/${apis.initCanghaiCarouselData}`, {
      method: 'get',
      params
    });

    return result;
  },
}

export default PageletServices;
