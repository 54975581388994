import React, { useState, useEffect, useRef } from 'react';
import { List, Checkbox, TextareaItem, Modal, Button } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { di18n, CardIcon } from '@ultra/common';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import './index.scss';
import {
  setValidateResult,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
import { textHeight, monitorSoftKeyboard } from '../../utils/const';

const { intl } = di18n;
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;
const radioCombineWidgetMobile = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    validateFlag,
    generalMultiData,
    surveyBusType,
  } = useSelector((state) => state.questionContent);


  const dispatch = useDispatch();
  // 处理失焦和勾选的时差。当为失焦时，延迟200ms 200ms内存在勾选 不执行失焦函数
  let blurTimer = useRef(false);
  // 当为失焦时，延迟200ms 200ms内存在勾选 存储失焦的数据
  let blurData = useRef();
  const currentLangClassName = {
    'en-US': 'en-width',
    'zh-CN': 'zh-width'
  }

  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    direction,
    options
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];
  const otherRules = rules.otherRules;
  const effect = effectData[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;

  const [radioValue, setRadioValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [showOptionDes, setShowOptionDes] = useState(false);
  const [currentOptionDes, setCurrentOptionDes] = useState(false);
  const [inputValue, setInputValue] = useState();
  // 兼容有多个其他选项时，前端内存里需要存储不同other选项的输入框内容
  const [inputValueMap, setInputValueMap] = useState();
  const [inputShow, setInputShow] = useState();
  const [inputValidateStatus, setInputValidateStatus] = useState();
  const textareaRefs = React.useRef([]);
  // 矩阵题初始化时，如果存在错误，展示错误的输入框
  const getErrorInputShow = (widgetDraftData, errorOtherRules, inputShow) => {
    let errorInputShow = { ...inputShow };
    for (let i in widgetDraftData) {
      const inputVal = (widgetDraftData[i].length > 0) ? widgetDraftData[i][0].value : '';
      for (let j in errorOtherRules) {
        // 评估人的code下option匹配到other中的option时
        if (widgetDraftData[i].length > 0 && widgetDraftData[i][0].option === j) {
          // 判断是否其他选项校验规则没通过
          if (errorOtherRules[j].min && errorOtherRules[j].min > 0 && inputVal.length < errorOtherRules[j].min) {
            errorInputShow[i] = true;
          }
        };
      };
    };
    return errorInputShow;
  };

  // 选项id和value的映射，便于选择后的展示
  const [optionMap, setOptionMap] = useState();

  // 题目描述的展开收起
  const [expand, setExpand] = useState(true);
  // 文本框聚焦时
  const [textFocus, setTextFocus] = useState(false);

  useEffect(() => {
    let map = {}
    options.map((item, i) => {
      map[item.key] = item.value;
    });
    setOptionMap(map);

    // 如果题目描述的高度超过了200，默认给收起
    if (tips && textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 200) {
      setExpand(false);
    }
    // 安卓上监听软键盘
    if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1) {
      monitorSoftKeyboard(isUp => {
        if (isUp) {
          setTextFocus(true);
        } else {
          setTextFocus(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup) {
      let radio = {};
      let input = {};
      let inputShow = {};
      let inputValueMap = {};
      let inputValidateStatusMap = {};
      respondentsGroup.map((item, i) => {
        radio[item.respondentCode] = '';
        input[item.respondentCode] = '';
        inputShow[item.respondentCode] = false;
        inputValueMap[item.respondentCode] = {};
        inputValidateStatusMap[item.respondentCode] = {};

        other.map((otherItem, i) => {
          inputValueMap[item.respondentCode][otherItem] = '';
          inputValidateStatusMap[item.respondentCode][otherItem] = true;
        })
      });
      let otherValidate;
      if (draftData[widgetKey]) {
        const widgetDraftData = draftData[widgetKey];
        if (other.length > 0) {
          otherValidate = {};
          other.map((item, j) => {
            otherValidate[item] = {
              error: true,
              msg: ''
            }
          })
        }
        for (let i in widgetDraftData) {
          const val = (widgetDraftData[i].length > 0) ? widgetDraftData[i][0].option : '';
          const inputVal = (widgetDraftData[i].length > 0) ? widgetDraftData[i][0].value : '';
          const inputShowDraft = inputVal.length > 0 ? true : false;
          const inputValueMapDraft = inputVal.length > 0 ? { [val]: inputVal } : '';
          radio = { ...radio, [i]: val };
          input = { ...input, [i]: inputVal };
          inputShow = { ...inputShow, [i]: inputShowDraft };
          inputValueMap = { ...inputValueMap, [i]: inputValueMapDraft };
          dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: i, data: widgetDraftData[i] }));
          if (other.length > 0) {
            other.map((item, j) => {
              if (otherRules[item].min && otherRules[item].min > 0) {
                if (val && val.includes(item) && widgetDraftData[i].length > 0 && (inputVal.length > 0 || !inputVal) && inputVal.length < otherRules[item].min) {
                  inputValidateStatusMap[i][item] = false;
                  const currentOptionResult = {
                    error: false,
                    msg: intl.t('亲，此项必填的哈~')
                  }
                  otherValidate = { ...otherValidate, [item]: currentOptionResult };
                }
              }
            })
          }
        }
        // 数据校验
        const widgetValidate = validateFun(rules, widgetDraftData);

        const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
        dispatch(setValidateResult({ id: widgetKey, data: validateData }));
      }
      setRadioValue(radio);
      setInputValue(input);
      // 处理矩阵题初始化时，如果存在错误，展示错误
      setInputShow(getErrorInputShow(draftData[widgetKey], otherRules, inputShow));
      setInputValueMap(inputValueMap);

      setInputValidateStatus(inputValidateStatusMap);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {

      dispatch(resetInitRequired({}));

      for (let i in updateData) {
        if (JSON.stringify(generalMultiData[i][widgetKey]) != JSON.stringify(updateData[i])) {
          dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: i, data: updateData[i] }));
        }
      }
      dispatch(updateCurrentSubject({ data: widgetKey }));

      // 数据校验
      const widgetValidate = validateFun(rules);
      // 返回其他选项的规则 msg和error都存在
      let otherValidate = validateFun(otherRules, updateData, true);
      const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
      dispatch(setValidateResult({ id: widgetKey, data: validateData }));
    }
  }, [updateData]);
  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag])

  const handleOptionClick = (key, item) => {
    setInputShow({ ...inputShow, [item]: other.includes(key) });
  }

  const handleOnChange = (key, item) => {
    let data = {};
    if (blurTimer.current) {
      data = blurData.current;
      blurTimer.current = false;
    };
    setRadioValue({ ...radioValue, [item]: key });
    setTimeout(() => {
      if (textareaRefs.current[item]) {
        textareaRefs.current[item].focus({
          cursor: 'end',
        });
      };
    }, 30);
    setExpand(false);

    const option = key;
    let value = '';

    if (other.includes(option) && inputValueMap[item][option]) {
      value = inputValueMap[item][option]?.replace(/(\s*$)/g, '') || inputValueMap[item][option] || '';
    } else {
      const currentInputValueMap = { ...inputValueMap, [item]: { ...inputValueMap[item], [option]: '' } };
      setInputValueMap(currentInputValueMap);
    }

    if (otherRules[option]?.min && value.length < otherRules[option]?.min && value.length > 0) {
      setInputValidateStatus({
        ...inputValidateStatus, [item]: {
          [option]: false
        }
      });
    } else {
      setInputValidateStatus({
        ...inputValidateStatus, [item]: {
          [option]: true
        }
      });
    }

    setInputValue({ ...inputValue, [item]: value });
    data[item] = [
      {
        option: option,
        value: value
      }
    ];

    // 判断在必填且输入框内容为空的情况下，清空内容并提交
    let isEmpty = false;
    if (!inputValueMap[item][key] || (inputValueMap[item][key] && inputValueMap[item][key]?.replace(/(\s*$)/g, '').length === 0)) {
      isEmpty = true;
    }
    if (other.includes(key) && otherRules[key].required && isEmpty) {
      data[item] = [
        {
          option: '',
          value: ''
        }
      ];
      setInputValue({ ...inputValue, [item]: '' });
    }
    setUpdateData(data);
  }

  const handleChangeInput = (e, item) => {
    setInputValue({ ...inputValue, [item]: e.length > 2000 ? e.slice(0, 2000) : e });
    const currentInputValueMap = { ...inputValueMap, [item]: { [radioValue[item]]: e } };
    setInputValueMap(currentInputValueMap);
  }

  const handleBlur = (item) => {
    blurTimer.current = true;
    blurData.current = {};
    setTextFocus(false);
    let data = {};
    let value = '';
    let option = radioValue[item];
    if (other.includes(radioValue[item])) {
      value = inputValue[item]?.replace(/(\s*$)/g, '') || '';

      if (otherRules[radioValue[item]].min && value.length < otherRules[radioValue[item]].min && value.length > 0) {
        setInputValidateStatus({
          ...inputValidateStatus, [item]: {
            [option]: false
          }
        })
      } else {
        setInputValidateStatus({
          ...inputValidateStatus, [item]: {
            [option]: true
          }
        });
      }
      if (value.length === 0 && otherRules[radioValue[item]].required) {
        option = '';
        setRadioValue({ ...radioValue, [item]: option });
        setInputShow({ ...inputShow, [item]: false });
      }
    }
    blurData.current[item] = [
      {
        option: option,
        value: value
      }
    ];
    setTimeout(() => {
      if (blurTimer.current) {
        data[item] = [
          {
            option: option,
            value: value
          }
        ];
        setUpdateData(data);
      };
      blurTimer.current = false;
    }, 200);
  }

  const placeholder = (optionKey) => {
    const requiredContent = other.includes(optionKey) && otherRules[optionKey].required ? intl.t('必填') : '';
    let desContent = '';
    options.map((item, i) => {
      if (item.key === optionKey && item.des !== '') {
        desContent = '(' + item.des + ')';
      }
    });
    return requiredContent + desContent;
  }

  // 校验方法
  const validateFun = (rule, content, otherOption = false) => {
    const current = content ? content : radioValue;
    const currentInput = content ? content : inputValue;
    const { max, min, required } = rule;

    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + min + ' ' + intl.t('个选项，最多选择') + ' ' + max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ]

    // updateData只针对一个人的草稿做判断
    if (otherOption) {
      let otherResultMap = {};
      other.map((item, j) => {
        otherResultMap[item] = {
          error: true,
          msg: ''
        }
      });
      for (let i in inputValueMap) {
        if (other.length > 0) {
          other.map((item, j) => {
            if (otherRules[item].min && otherRules[item].min > 0) {
              if (radioValue[i]
                && radioValue[i].includes(item)
                && inputValueMap[i][item]
                && inputValueMap[i][item]?.replace(/(\s*$)/g, '').length < otherRules[item].min) {
                setInputValidateStatus({
                  ...inputValidateStatus, [i]: {
                    [item]: false
                  }
                });

                const currentOptionResult = {
                  error: false,
                  msg: intl.t('亲，此项必填的哈~')
                }
                otherResultMap = { ...otherResultMap, [item]: currentOptionResult };
              }
            }
          })
        }
      }
      return otherResultMap;
    }


    if (required) {
      if (!otherOption) {
        for (let i in current) {
          if (content) {
            if (current[i].length > 0) {
              if (current[i][0].option === '' || !current[i][0].option) {
                return {
                  error: false,
                  msg: msgArr[1]
                }
              }
            } else {
              return {
                error: false,
                msg: msgArr[1]
              }
            }
          } else {
            if (current[i] === '') {
              return {
                error: false,
                msg: msgArr[1]
              }
            }
          }
        }
      } else { //校验其他选项的输入框，必填的情况下，如果不输入内容，则选项不会被选中，所以理论上不会出现长度为0的情况
        for (let i in currentInput) {
          if (content) {
            if (currentInput[i].length > 0) {
              if (currentInput[i][0].value.replace(/(\s*$)/g, '').length === 0) {
                return {
                  error: false,
                  msg: msgArr[1]
                }
              }
            }
            if (currentInput[i].length === 0) {
              return {
                error: false,
                msg: msgArr[1]
              }
            }
          } else {
            if (currentInput[i]?.replace(/(\s*$)/g, '').length === 0) {
              window.console.log('user required', currentInput[i]?.replace(/(\s*$)/g, ''));
              return {
                error: false,
                msg: msgArr[1]
              }
            }
          }
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const handleCombineMobileOptionDes = (item) => {
    setCurrentOptionDes(item)
    setShowOptionDes(true);
  }

  const handleExpand = () => {
    setExpand(!expand);
  };

  const getValue = respondentCode => inputValueMap ? inputValueMap[respondentCode][radioValue[respondentCode]] : '';

  const formatterCount = (e = '', optionKey = null) => {
    // const max = 2000;
    // const min = 10;
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    let max = other.includes(optionKey) && otherRules[optionKey].max ? otherRules[optionKey].max : 2000;
    const { min } = other.includes(optionKey) ? otherRules[optionKey] : '';
    if (min && Number(min)) {
      return `${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
    }
    return `${count}/${max}`;
  };
  const header = () => {
    return (
      <>
        <div
          className={'div-label'}
          style={{ fontSize: '14px', color: '#333333', backgroundColor: '#FFFFFF' }}
        >
          <div className={`radio-div-label-${required}`}>
            <img src={Must} />
          </div>
          {
            number ? <div className="m-number">{`${number}.`}</div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-combine-content radio-content-related-${related}`}>
          {tips ? (
            <div className="mobile-question-tip">
              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && !expand ? (
                  <>
                    {decodeURIComponent(tips).length > 100 ? `${decodeURIComponent(tips).slice(0, 60)}...` : `${decodeURIComponent(tips).slice(0, 54)}...`}
                  </>
                ) : (
                  <>{decodeURIComponent(tips)}</>
                )
              }

              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && (
                  <div onClick={handleExpand}>
                    {expand
                      ? (
                        <div className='expand-btn-container'>
                          {intl.t('收起')}
                          <CardIcon type="iconshouqi1" className="expand-icon" />
                        </div>
                      ) : (
                        <div className='expand-btn-container'>
                          {intl.t('查看全部')}
                          <CardIcon type="iconxiala2" className="expand-icon" />
                        </div>
                      )}
                  </div>
                )
              }
            </div>
          ) : null}


        </div>
        <div className='combine-header'>
          <div className='combine-header-warpper'>
            {
              options.map((item, i) => {
                return (
                  <div key={widgetKey + 'combine-header' + item.key} className='combine-header-item'>
                    <div style={{ width: '3rem', textAlign: 'center', wordBreak: 'break-all' }}>
                      <span style={{ textAlign: 'center' }}>
                        {item.value}
                      </span>
                      <>
                        {
                          !item.des || (other && other.includes(item.key)) ? '' :
                            <div>
                              <a style={{ color: '#9B9C9F' }} onClick={() => handleCombineMobileOptionDes(item)}>
                                <QuestionCircleOutlined />
                              </a>
                            </div>
                        }
                      </>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }

  const renderRadio = () => {
    return (
      <>
        <List
          style={{ borderRadius: '8px' }}
          renderHeader={header()}
          className={classnames('', {
            // 安卓上如果触发了文本题聚焦则取消吸顶
            'radio-combine-cancel-sticky':
              textFocus && (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1)
          })}
        >
          {
            respondentsGroup.map((item, i) => {
              return (
                <div key={widgetKey + 'combine-radio-textarea-list' + item.respondentCode + i} className={classnames("combine-radio-textarea", {
                    'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
                    'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
                  })}>
                  <Item key={widgetKey + 'combine-radio-list' + item.respondentCode + i}>
                    <div className="radio-combine-fullname">
                      <span className="bg">{item.activityRespondentFullName}</span>
                      <span className="des">{radioValue && radioValue[item.respondentCode] ? optionMap[radioValue[item.respondentCode]] : ''}</span>
                    </div>
                    <div className="combine-item-mobile" key={widgetKey + 'combine-textarea-div' + item.respondentCode}>
                      <div className="combine-checkbox-header radio-widget-mobile">
                        {
                          options.map((opt, i) => {
                            return (
                              <CheckboxItem
                                value={opt.key}
                                key={widgetKey + opt.key + item.respondentCode}
                                checked={(radioValue && radioValue[item.respondentCode] === opt.key) ? true : false}
                                onChange={() => handleOnChange(opt.key, item.respondentCode)}
                                onClick={() => handleOptionClick(opt.key, item.respondentCode)}
                              ></CheckboxItem>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Item>
                  {
                    inputShow && inputShow[item.respondentCode] ?
                      <>
                        <TextareaItem
                          ref={textNode => textareaRefs.current[item.respondentCode] = textNode}
                          className={
                            (inputValidateStatus[item.respondentCode] && inputValidateStatus[item.respondentCode][radioValue[item.respondentCode]]) ? '' : 'ct-textarea-status'
                          }
                          id={'id' + widgetKey + 'combine-radio-textarea' + item.respondentCode}
                          key={widgetKey + 'combine-radio-textarea' + item.respondentCode}
                          style={{ backgroundColor: '#F8F8F8', borderColor: 'transparent' }}
                          value={getValue(item.respondentCode)}
                          placeholder={placeholder(radioValue[item.respondentCode])}
                          rows={4}
                          onChange={(e) => handleChangeInput(e, item.respondentCode)}
                          onBlur={() => handleBlur(item.respondentCode)}
                          onFocus={() => { setExpand(false); setTextFocus(true); }}
                        />
                        <div className="combine-radio-other-line">
                          {
                            (inputValidateStatus[item.respondentCode] &&
                              !inputValidateStatus[item.respondentCode][radioValue[item.respondentCode]]) &&
                              inputValueMap[item.respondentCode][radioValue[item.respondentCode]] &&
                              inputValueMap[item.respondentCode][radioValue[item.respondentCode]]?.replace(/(\s*$)/g, '').length > 0 ?
                              <>
                                <div className="ct-questionnaire-mobile-error error-tip">
                                  <ExclamationCircleFilled />
                                  <span>{intl.t('不满足最小字数填写要求~')}</span>
                                </div>
                              </>
                              : ''
                          }
                          <div className="ct-textarea-combine-text">
                            {formatterCount(getValue(item.respondentCode), radioValue[item.respondentCode])}
                          </div>
                        </div>
                      </> : ''
                  }
                </div>
              )
            })
          }
        </List>
      </>
    )
  }

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (validateResult[widgetKey].otherResult && Object.keys(validateResult[widgetKey].otherResult).length > 0) {
          for (let item in validateResult[widgetKey].otherResult) {
            if (Object.keys(validateResult[widgetKey].otherResult).length > 0 && !validateResult[widgetKey].otherResult[item].error) {
              return <></>
            }
          }
        }
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="ct-questionnaire-mobile-error ct-questionnaire-mobile-radio-error error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                </span>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  const onWrapTouchStart = (e) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }

    const pNode = closest(e.target, '.am-modal-content');
    if (!pNode) {
      //e.preventDefault();  
    }
  }
  const closest = (el, selector) => {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
      if (matchesSelector.call(el, selector)) {
        return el;
      }
      el = el.parentElement;
    }
    return null;
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden}`}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >

        <div className={`radio-combine-content radio-content-related-${related}`}>
          {renderRadio()}
        </div>

        {renderErrorMsg()}
      </div>
      <Modal
        visible={showOptionDes}
        transparent
        onClose={() => setShowOptionDes(false)}
        title={currentOptionDes.value}
        closable
        className="radio-combine-des-modal"
        wrapProps={{ onTouchStart: (e) => onWrapTouchStart(e) }}
      >
        <div>{currentOptionDes.des}</div>
      </Modal>
    </>
  );
};

export default radioCombineWidgetMobile