import React, { useEffect, useState } from 'react';
import { getLocale, Tooltip, Avatar } from '@didi/ec-base';
import { Intl, drawer } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import BirthDayIcon from './assets/info-birthday-icon.svg';
import Right from './assets/right-thick-o.svg';
import PageletApi from '@didi/pagelets-sdk';
import useStyle from './style';
const localLocales = manifest.locales || {};
const teamDrawer = drawer({
  title: '团队成员',
  rootClassName: 'pagelet-personal-outsourcing-drawer',
  // pageletUrl: '//127.0.0.1:8300/personal-outsourcing.runtime.min.js',
  pageletName: '@didi/pagelet-personal-outsourcing',
  pageletProps: {},
  destroyOnClose: true,
  width: '1000px',
  onClose: () => {
    teamDrawer.hide();
  },
});
const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, item, deptCodes, deptInfos, pageCode, headers = {} },
  } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth?.id,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(auth?.functionName)
  }
  PageletApi.newRequest.init(newHeaders, 'personal-info');
  const prefixCls = 'pagelet-personal-info';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);
  const [data, setData] = useState(null);
  const [initError, setInitError] = useState(false);
  const getPersonalInfo = () => {
    PageletServices.initPersonalInfo(apiHost).then((res) => {
      if (res?.code === '100200') {
        let info = res?.data;
        if (info?.isBirthDay && info?.isHireAnniversary) {
          info.title = '生日快乐' + '·' + `${info?.hireAnniversaryDt}周年快乐`;
        } else if (info?.isBirthDay) {
          info.title = '生日快乐';
        } else if (info?.isHireAnniversary) {
          info.title = `${info?.hireAnniversaryDt}周年快乐`;
        } else {
          info.title = '';
        }
        setData(info);
      } else {
        setInitError(true);
      }
    }, () => {
      setInitError(true);
    });
  }
  useEffect(() => {
    getPersonalInfo();
  }, [])

  // 正式员工、实习生、工时制外包
  const handleTeamDrawer = emplValue => {
    let authId = {};
    auth?.children.map((v) => {
      if (v?.elementTag === '@didi/pagelet-personal-outsourcing') {
        authId = {
          ...v
        }
      }
    })
    try {
      teamDrawer.show({
        dynamicDrawerProps: { title: '工时制外包' },
        dynamicPageletProps: {
          auth: authId,
          headers,
          type: emplValue,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  // 实习生列表
  const handleInternDrawer = () => {
    // 弹窗
    const close = () => {
      // 点击关闭
      drawer.destroy();
    }
    const drawer = PageletApi.drawer({
      title: '',
      width: '1000px',
      rootClassName: 'pagelet-personal-info-trainee-drawer',
      // pageletUrl: '//127.0.0.1:8003/personal-info-trainee.runtime.min.js',
      pageletName: '@didi/pagelet-personal-info-trainee',
      pageletProps: {
        auth,
        headers,
        close,
      }, // 透传给pagelet的props
      onClose: close
    });
    drawer.show();
  }
  // 组织信息
  const handleOrganization = () => {
    // 弹窗
    const close = () => {
      // 点击关闭
      drawer.destroy();
    }
    const drawer = PageletApi.drawer({
      title: '组织信息',
      width: '600px',
      rootClassName: 'pagelet-personal-info-trainee-drawer-more',
      // pageletUrl: '//127.0.0.1:9111/personal-organization.runtime.min.js',
      pageletName: '@didi/pagelet-personal-organization',
      pageletProps: {
        auth,
        headers,
        close,
        data: data
      }, // 透传给pagelet的props
      onClose: close
    });
    drawer.show();
  }
  return wrapSSR(
    <div className={classes}>
      {!initError && <div className={`${prefixCls}-info`}>
        <div className="info-avater">
          <Avatar src={data?.small || '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/avatar.png'} onClick={() => {
            window.open(`${window.location.origin}/home/ei/staffinfo/detail`, '_blank')
          }} />
          {data?.isBirthDay && <img src={BirthDayIcon} alt="" className="info-birthday-icon" />}
          {data?.title && <div className="info-birthday">
            {data?.title}
          </div>}
        </div>
        {data && <div className="info-name">
          <span className="info-name-id" onClick={() => {
            window.open(`${window.location.origin}/home/ei/staffinfo/detail`, '_blank')
          }}>{data?.name} {data?.empId}</span>
          <span className="info-name-num">今天是你成为小桔子的第 <span style={{ fontWeight: '500' }}>{data?.hireDay}</span> 天</span>
        </div>}
        {data && <div className="info-msg">
          <div style={{ maxWidth: window.innerWidth > 1440 ? '150px' : '120px' }}>
            <div className="info-msg-warp">
              <span className="info-msg-tit">上级 :</span>
              {data?.managerName ?
                <Tooltip title={<span>{data?.managerName}&nbsp;{data?.managerId}</span>} arrow={false}>
                  <span className="info-msg-content"
                    onClick={() => {window.open(`dchat://im/start_conversation?name=${data?.managerLdap}`)}}
                  >
                    <div>{data?.managerName}</div>&nbsp;{data?.managerId}
                  </span>
                </Tooltip>
                : <span className="info-msg-content">-</span>}
            </div>
            <div className="info-msg-warp">
              <span className="info-msg-tit">部门 :</span>
              <Tooltip title={<span>{data?.deptName}</span>} arrow={false}>
                <span className="info-msg-content">
                  <div>{data?.deptName}</div>
                </span>
              </Tooltip>
            </div>
          </div>
          <div style={{ maxWidth: window.innerWidth > 1440 ? '150px' : '120px' }}>
            <div className="info-msg-warp">
              <span className="info-msg-tit">HRBP :</span>
              <Tooltip title={<span>{data?.hrbpName}&nbsp;{data?.hrbpId}</span>} arrow={false}>
                <span className="info-msg-content" onClick={() => window.open(`dchat://im/start_conversation?name=${data?.hrbpLdap}`)}>
                  <div>{data?.hrbpName}</div>&nbsp;{data?.hrbpId}
                </span>
              </Tooltip>
            </div>
            <div className="info-msg-warp">
              <div className="info-msg-more-warp" onClick={() => {
                window.open(`${window.location.origin}/home/ei/staffinfo/detail`, '_blank')
              }}>
                我的信息<img src={Right} />
              </div>
              <div className="info-msg-more-warp" onClick={() => {
                handleOrganization();
              }}>
                组织信息<img src={Right} />
              </div>
            </div>
          </div>
        </div>}
      </div>
      }
      {!initError && (data?.workingHourOutsourcingNum !== '0' || data?.internCount !== '0') && <div className={`${prefixCls}-warp`}>
        <div className="warp-intern" onClick={() => { handleInternDrawer() }}>
          <span className="warp-intern-num">{data?.internCount}</span>
          <span>实习生</span>
        </div>
        <div className="warp-outsource" onClick={() => handleTeamDrawer('OUTSOURCING')}>
          <span className="warp-intern-num">{data?.workingHourOutsourcingNum}</span>
          <span>工时制外包</span>
        </div>
      </div>}
      {initError && <div className={`${prefixCls}-content-err`}>
        <img
          src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
        />
        <span className='text-item-1'>服务不可用，请稍候再试。</span>
      </div>}
    </div>,
  );
};

export default Pagelet;
