import React, { useEffect, useState, useRef } from 'react';
import { getLocale, Spin, Tooltip, en_US, zh_CN, ConfigProvider  } from '@didi/ec-base';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import useStyle from './style';
import moment from 'moment';
import orangeArrowUrl, { ReactComponent as OrangeArrow } from './assets/o-right-arrow.svg';
import noData from './assets/no-data.png';

import { RightThickOutlined, DownThickOutlined, UpThickOutlined } from '@didi/iconflow/ec';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
const localLocales = manifest.locales || {};

const Pagelet = props => {
  const { params: { apiHost, locales, auth, headers = {} } } = props;
  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN')
  const [surveyData, setSurveyData] = useState([]);
  const [expanded, setExpanded] = useState(false); // 控制是否展开
  const [loading, setLoading] = useState(false);
  const visibleItems = expanded ? surveyData : surveyData?.slice(0, 2) || []; // 默认显示两条数据
  const surveyContentRef = useRef(null);

  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(auth.functionName)
  }

  const handleRequest = async () => {
    setLoading(true);
    try {
      const data = await PageletServices.getPageActivity(apiHost, {
        headers: newHeaders,
      });
      setSurveyData(data?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const prefixCls = 'pagelet-ct-survey-feedback';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const locale = getLocale(locales || localLocales);

  const classes = classNames(prefixCls);

  const jumpToQuestionnaire = (url) => {
    window.open(url, '_blank');
  };

  const jumpToAnonymity = () => {
    window.open('https://i.xiaojukeji.com/communicate/detail/talk/10422825?from=personal', '_blank');
  };

  useEffect(() => {
    handleRequest();
  }, []);

  // useEffect(() => {
  //   if (lang !== 'zh-CN') {
  //     // moment.locale('en');
  //     setProviderLocale(en_US)
  //   } else {
  //     // moment.locale('zh-cn');
  //     setProviderLocale(zh_CN)
  //   }
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const contentElement = surveyContentRef.current;
      if (contentElement.scrollTop > 0) {
        // 如果滚动了，就添加阴影
        contentElement.classList.add('shadow');
      } else {
        // 否则移除阴影
        contentElement.classList.remove('shadow');
      }
    };

    const surveyContentElement = surveyContentRef.current;
    surveyContentElement.addEventListener('scroll', handleScroll);

    return () => {
      surveyContentElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <Spin spinning={loading}>
          <DHRCardTitle
            title={'调研反馈'}
            titleAdded={
              <DHRCardButton onClick={jumpToAnonymity}>
                {/* {locale?.anonymousScheme} <RightThickOutlined cursor="pointer" size={14} className="new-home-holiday-card-title-url-icon"/>
                 */}
                <span className='right-name'>{locale?.anonymousScheme}</span>
                <RightThickOutlined size={14} style={{ cursor: 'pointer'}}/>
              </DHRCardButton>

            }
            />
          <div className='survey-content' ref={surveyContentRef}>
            {
              visibleItems?.length > 0 ? visibleItems?.map(item => (
                <div className='content-item' key={item?.activityId} onClick={() => jumpToQuestionnaire(item?.questionnaireUrl)}>
                  <div className='item-title'>
                    <Tooltip placement='topLeft' arrow={false} title={item?.questionnaireName} >{item?.questionnaireName}</Tooltip>
                  </div>
                  <div className='item-content'>
                    <div className='content-left'>
                      <span>{locale?.deadline}：</span>
                      <span>{moment(item?.activityEndTime)?.format('YYYY.MM.DD')}</span>
                    </div>
                    <div className='content-right'>
                      <span>{locale?.defeedback}</span>
                      <OrangeArrow />
                    </div>
                  </div>
                </div>
              ))
                :
              <div className='no-data' >
                  <div className='no-data-center'>
                    <img src={noData} className='no-data-img' />
                    <div className='no-data-text'>暂无调研活动</div>
                  </div>
              </div>
            }
          </div>
          {surveyData?.length > 2 && (
              <div className='expand-button' onClick={() => setExpanded(!expanded)}>
                <span className='but-name'>
                  {expanded ?
                    <span className='arrow-content'>
                      <span>{locale?.packUp}</span>
                      <UpThickOutlined />
                    </span> :
                    <span className='arrow-content'>
                    <span>展开剩余{surveyData?.length - 2}项</span>
                    <DownThickOutlined />
                    </span>}
                </span>
              </div>
            )}
        </Spin>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
