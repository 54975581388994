import React from 'react';
import classNames from 'classnames';
import useStyle from './style/PerformanceRecordsModal';
import { Modal, Divider, Tooltip } from '@didi/ec-base';

// 绩效/反馈详情弹窗
export default function PerformanceRecordsModal(props) {
  const { visible, handleCancel, data, locale } = props;

  const prefixCls = 'pagelet-performance-records-modal';
  const wrapSSR = useStyle(prefixCls);
  const classes = classNames(prefixCls);

  return wrapSSR(
    <Modal
      className={classes}
      title={data?.title + locale?.Details}
      visible={visible}
      width={600}
      destroyOnClose
      footer={false}
      onCancel={handleCancel}
    >
      <div className='performance-records-modal-rating-info'>
        <span>{data?.name}:</span>
        <span>{data?.grade}</span>
        {
          data?.isAppeal === '1' && <>
             <span>{locale?.EscalateResult}:</span>
             <span>{data?.appealResult}</span>
          </>
        }
      </div>
      <div className='performance-records-modal-evaluator-info'>
        <span>{locale?.ManagerEvaluation}</span>
        <Divider type="vertical" style={{ height: '16px',margin: '0 12px' }} />
        <span>{locale?.From}:</span>
        {
          data?.evaluationName ? <Tooltip title={`${data?.evaluationName}(${data?.evaluationEmpId})`}>
          <span className='performance-records-modal-evaluator-info-name'><span>{data?.evaluationName}</span>({data?.evaluationEmpId})</span>
        </Tooltip> : '-'
        }
      </div>
      <div className='performance-records-modal-evaluate-info'><div>{data?.managerEvaluation}</div></div>
    </Modal>
  )
}
