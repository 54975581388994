import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('post-select-com', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        width: 'calc(100% - 14px)',
        ['.dept_wrap']: {
          position: 'relative',
          ['.dept_wrap_hover']: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '100%',
            padding: '4px',
          },
          ['.dept_more']: {
            cursor: 'pointer',
            ['.dept_wrap_hover']: {
              ['&:hover']: {
                background: 'rgba(245, 245, 245, 0.6)',
                borderRadius: '8px',
              },
            }
          },
        },

        ['.dept']: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          ['.dept_name']: {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '18px',
            letterSpacing: '0em',
            color: 'rgba(0, 0, 0, 0.72)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },

          ['.downIcon']: {
            width: '14px',
            height: '14px',
            flexShrink: '0',
          },
        },

        ['.mark']: {
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          zIndex: '998',
          cursor: 'default !important',
        },

        ['.post_wrap']: {
          position: 'absolute',
          left: '0',
          top: '26px',
          width: '200px',
          maxHeight: '240px',
          display: 'flex',
          flexDirection: 'column',
          padding: '4px',
          borderRadius: '8px',
          background: '#FFFFFF',
          boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
          overflow: 'auto',
          cursor: 'pointer !important',
          zIndex: '999',

          ['.post_item']: {
            width: '172px',
            height: '32px',
            /* 自动布局 */
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 8px',
          },

          ['.post_item:hover']: {
            borderRadius: '4px',
            opacity: '1',
            background: '#FFF7F3',
          },

          /*  岗位展示项 */
          ['.side-item-h5']: {
            lineHeight: '32px',
            cursor: 'pointer',
            borderRadius: '4px',
            fontSize: '11px',
            color: '#6D6E6F',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            padding: '0 6px 0 12px',
            position: 'relative',

            ['&:hover']: {
              background: '#F1F2F2',
              borderRadius: '4px',

              ['.side-item-content-user-img']: {
                background: '#F1F2F2 !important',
              },
            },
          },

          ['.side_wrap']: {
            ['.dept-title-h5']: {
              height: '16px',
              borderRadius: '4px',
              display: 'flex',
              fontSize: '9px',
              fontWeight: '500',
              lineHeight: '16px',
              margin: '0 12px 0 6px',
              padding: '0px 4px',
              borderRadius: '4px',
              background: 'rgba(255, 86, 59, 0.1)',
              position: 'relative',

              ['div']: {
                transform: 'scale(0.8)',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '80px',
              },

              ['.dept_title_hover']: {
                display: 'none',
                position: 'absolute',
                zIndex: '3',
                left: '0px',
                top: '26px',
                width: '100px',
                fontSize: '12px',
                fontWeight: 'normal',
                lineHeight: '18px',
                letterSpacing: '0px',
                color: '#FFFFFF',
                padding: '6px 12px',
                borderRadius: '4px',
                opacity: '1',
                background: '#212121',
                boxShadow:
                  '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 4px 6px 6px rgba(0, 0, 0, 0.02), 0px 4px 6px 0px rgba(0, 0, 0, 0.06)',
              },
            },

            ['.dept-title:hover .dept_title_hover']: {
              display: 'block',
            },
          },

          /* 选中状态 */
          ['.side-item-active-h5']: {
            lineHeight: '32px',
            cursor: 'pointer',
            borderRadius: '4px',
            padding: '0 6px 0 12px',
            backgroundColor: 'rgba(249, 239, 235, 1)',
            fontSize: '11px',
            color: '#1E1F21',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
        },

        /* 岗位选择 */
        ['.post-select']: {
          ['.post-select-loading']: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 13px 13px 13px',
          },

          ['.postSelectLine']: {
            width: '231px',
            height: '1px',
            background: '#D8D8D8',
            margin: '4px 13px',
          },

          /*  岗位展示项 */
          ['.side-item']: {
            lineHeight: '32px',
            cursor: 'pointer',
            borderRadius: '4px',
            fontSize: '14px',
            color: '#6D6E6F',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',

            ['&:hover']: {
              background: '#F1F2F2',
              borderRadius: '4px',
              color: '#FD7443',

              ['.side-item-content-user-img']: {
                background: '#F1F2F2 !important',
              },
            },
          },

          /* 选中状态 */
          ['.side-item-active']: {
            lineHeight: '32px',
            cursor: 'pointer',
            borderRadius: '4px',
            backgroundColor: 'rgba(249, 239, 235, 1)',
            fontSize: '14px',
            color: '#FD7443',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          },

          ['.side-item-content-border']: {
            width: '4px',
            height: '32px',
            background: '#FD7443',
            position: 'absolute',
            right: '253px',
            top: '0',
          },

          /* 岗位名称 */
          ['.side-item-content']: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            width: '100%',
            position: 'relative',
            padding: '0 6px 0 12px',

            ['.showIcon']: {
              position: 'absolute',
              right: '9px',
              top: '4px',
            },

            ['.side-item-content-user']: {
              display: 'flex',
              alignItems: 'center',

              ['.side-item-content-user-img']: {
                width: '24px',
                minWidth: '24px',
                height: '24px',
                marginRight: '8px',
                zIndex: '2',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',

                ['.side-item-content-user-imgs']: {
                  width: '24px',
                  minWidth: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  overflow: 'hidden',

                  ['img']: {
                    width: '100%',
                    verticalAlign: 'top',
                  },
                },

                ['.side-item-content-user-img-icon']: {
                  width: '13px',
                  height: '13px',
                  position: 'absolute',
                  bottom: '-2px',
                  right: '-6px',
                  borderRadius: '50%',
                },
              },

              ['.side-item-content-user-name']: {
                fontSize: '12px',
              },

              ['.side-item-content-user-line']: {
                minWidth: '1px',
                width: '1px',
                height: '9px',
                backgroundColor: '#DCDCDD',
                marginLeft: '6px',
                marginRight: '3px',
              },
            },

            ['.side-item-content-line1']: {
              minWidth: '12px',
              minHeight: '1px',
              marginRight: '3px',

              ['.side-item-child-line2']: {
                minWidth: '0px',
                minHeight: '32px',
                border: '1px solid #D8D8D8',
                borderTop: 'none',
                borderRight: 'none',
                marginTop: '-32px',

                ['.side-item-child-line3']: {
                  minWidth: '0px',
                  minHeight: '32px',
                  marginTop: '-32px',
                  marginLeft: '19px',

                  ['.side-item-child-line4']: {
                    minWidth: '0px',
                    minHeight: '32px',
                    marginTop: '-32px',
                    marginLeft: '-19px',

                    ['.side-item-child-line5']: {
                      minWidth: '0px',
                      minHeight: '32px',
                      marginTop: '-32px',
                      marginLeft: '-19px',
                    },
                  },
                },
              },
            },

            ['.side-item-info']: {
              fontSize: '12px',
              color: '#6D6E6F',
              fontWeight: 400,
            },

            ['.side-item-more']: {
              padding: '0 4px',
              height: '16px',
              background: '#F1F2F2',
              borderRadius: '4px',
              marginLeft: '4px',
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',

              ['div']: {
                transform: 'scale(0.8)',
              },

              ['span']: {
                transform: 'scale(0.8)',
              },
            },

            ['.side-item-more-open']: {
              padding: '0 4px',
              height: '16px',
              background: '#F9DFD9',
              borderRadius: '4px',
              marginLeft: '4px',
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',

              ['div']: {
                transform: 'scale(0.8)',
              },

              ['span']: {
                transform: 'scale(0.8)',
              },
            },
          },

          ['.side-item-idp']: {
            color: '#545659',
          },

          ['.ic-content']: {
            paddingLeft: '13px',
            lineHeight: '32px',
            cursor: 'pointer',
            borderRadius: '4px',
            fontSize: '13px',
            color: '#6D6E6F',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',

            ['.ic-contentInfo']: {
              color: 'rgba(174, 178, 179, 0.30)',
              fontSize: '12px',
              marginRight: '4px',
            },

            ['.ic-contentMore']: {
              color: '#ADADAD',
              fontSize: '12px',
            },
          },

          ['.ic-content:hover']: {
            ['.ic-contentInfo']: {
              color: '#FD7443',
            },

            ['.ic-contentMore']: {
              color: '#FD7443',
            },
          },

          ['.dept-title']: {
            textAlign: 'center',
            margin: '8px 4px',
            padding: '0 4px',
            maxWidth: '80px',
            height: '16px',
            borderRadius: '4px',
            fontSize: '9px',
            fontWeight: '500',
            lineHeight: '16px',
            background: 'rgba(255, 86, 59, 0.1)',
            color: '#FD7443',
            zIndex: '3',
            position: 'relative',

            ['.item_roleDesc']: {
              transform: 'scale(0.8)',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '80px',
            },

            ['.dept_roleDesc_hover']: {
              display: 'none',
              position: 'absolute',
              zIndex: '3',
              left: '0px',
              top: '26px',
              width: '100px',
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '18px',
              letterSpacing: '0px',
              color: '#FFFFFF',
              padding: '6px 12px',
              borderRadius: '4px',
              opacity: '1',
              background: '#212121',
              boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 4px 6px 6px rgba(0, 0, 0, 0.02), 0px 4px 6px 0px rgba(0, 0, 0, 0.06)',
            },
          },

          ['.dept-title:hover .dept_roleDesc_hover']: {
            display: 'block',
          },

          ['.dept-title-icon']: {
            width: '16px',
            height: '16px',
            marginLeft: '4px',
          },

          ['.dept-title-circle']: {
            width: '2px',
            minWidth: '2px',
            height: '2px',
            borderRadius: '50%',
            marginLeft: '4px',
          },

          /*  兼任岗文案 */
          ['.text-beyond']: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 13px)',
            fontSize: '11px',
            height: '24px',
            zIndex: '2',
            marginBottom: '8px',
          },

          ['.beyond_wrap']: {
            position: 'relative',
            width: '100%',
            ['.span-beyond']: {
              display: 'flex',
              width: 'calc(100% - 12px)',
              ['& > span']: {
                ['&:first-child']: {
                  flex: '0 1 auto',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              },
            },
            ['.dept_name_hover_text']: {
              display: 'none',
              position: 'absolute',
              zIndex: '3',
              left: '0px',
              top: '26px',
              width: '100px',
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '18px',
              letterSpacing: '0px',
              color: '#FFFFFF',
              padding: '6px 12px',
              borderRadius: '4px',
              opacity: '1',
              background: '#212121',
              boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 4px 6px 6px rgba(0, 0, 0, 0.02), 0px 4px 6px 0px rgba(0, 0, 0, 0.06)',
            },
          },

          ['.beyond_wrap:hover .dept_name_hover_text']: {
            display: 'block',
          },

          ['.downIcon']: {
            width: '14px',
            height: '14px',
            flexShrink: '0 !important',
            position: 'absolute',
            right: '0',
            top: '6px',
          },
          ['.post-select']: {
            ['.span-beyond']: {
              width: 'calc(100% - 26px)',
            },
            ['.side-item']: {
              fontSize: '12px',
            },
            ['.side-item-active']: {
              fontSize: '12px',
            },
          },
        },
      },
    },
  ]);
};

export default useStyle;
