import React, { useEffect, useState } from 'react';
import { getLocale, ConfigProvider, Divider, Tooltip } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import { getManagerInfo } from './services';
import classNames from 'classnames';
import { ReactComponent as Manage } from './assets/manage.svg';

import useStyle from './style';

const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, employeeId, usePermissionResponse },
  } = props;

  const [data, setData] = useState([]);

  const handleRequest = async () => {
    try {
      const res = await getManagerInfo(
        apiHost,
        headers,
        {
          employeeId,
        },
        usePermissionResponse,
      );
      setData(res?.data);
    } catch (e) {
      console.log('getManagerInfo', e);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  const prefixCls = 'pagelet-inventory-management-positions';
  // const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
      <div className={classes}>
        <div className="management-title">
          <Manage />
          <div className="title-info">{locale.ManagementPosition}</div>
        </div>
        <div className="management-content">
          <div className="single-info">
            <div className="single-title">{locale.MajorMgmtPosition}：</div>

              <div className="directorship" placement="topLeft">
                {data?.majorPositionList?.length > 0
                  ? data?.majorPositionList?.map(item => {
                    return (
                      <Tooltip title={item?.deptNamePath}>
                        <span className="single-data" key={item?.positionName}>
                          {item?.positionName}
                        </span>
                      </Tooltip>
                      );
                    })
                  : '-'}
              </div>
          </div>
          <div className="single-info">
            <div className="single-title">{locale.OtherMgmtPosition}：</div>
              <div className="other">
                {data?.minorPositionList?.length > 0
                  ? data?.minorPositionList?.map((item, index) => {
                    return (
                      <Tooltip title={item?.deptNamePath} placement="topLeft">
                        <span className="single-data" key={item?.positionName}>
                          {item?.positionName}
                          {index === data?.minorPositionList?.length - 1 ? (
                            ''
                          ) : (
                            <Divider type="vertical" />
                          )}
                        </span>
                      </Tooltip>
                      );
                    })
                  : '-'}
              </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Pagelet;
