import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';
const apis = manifest.apis || {};


const PageletServices = {
  // 团队假期信息初始化
  postInitHoliday: async (host, data) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-holidays');

    const result = await request(`${host}/${apis.initHoliday}`, {
      method: 'post',
      data
    });
    return result;
  },
  // 假期小类获取下一页
  postSubHoliday: async (host, data) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-holidays');

    const result = await request(`${host}/${apis.subHoliday}`, {
      method: 'post',
      data
    });
    return result;
  },
  initFilter: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-holidays');

    return await request(`${host}/${apis.initFilter}`, {
      method: 'post',
      data: body
    });
  },


}

export default PageletServices;
