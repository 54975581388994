import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-inventory-management-positions', () => [
		{
			// base
			[`.${prefixCls}`]: {
        display: 'flex',
        flexDirection: 'column',
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        padding: '16px',
        borderRadius: '10px',
        marginTop: '12px',

        ['.directorship']: {
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        ['.management-title']: {
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'PingFang SC',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0px',
          color: '#000000DE',
          marginBottom: '16px',

          ['.title-info']: {
            marginLeft: '4px',
          },
        },

        ['.management-content']: {
          marginLeft: '34px',
          display: 'flex',
          width: '100%',
        },

        ['.single-info']: {
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '22px',
          textAlign: 'left',
          letterSpacing: '0px',

          ['&:first-child']: {
            width: '30%',
            marginRight: '10px',
          },

          ['&:not(:first-child)']: {
            width: '65%',
          },

          ['.single-title']: {
            color: 'rgba(0, 0, 0, 0.36)',
          },

          ['.single-data']: {
            color: 'rgba(0, 0, 0, 0.84)',
            fontWeight: 500,
            marginTop: '4px',
            cursor: 'pointer',
          },

          ['.single-data-en']: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }
			  },

        ['.single-info-en']: {
          width: '300px',
        },

      }
		}
	]);
};

export default useStyle;
