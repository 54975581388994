import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-position-info', () => [
		{
			// base
      [`.${prefixCls}`]: {
        minHeight: '196px',
        display: 'flex',
        flexDirection: 'column',
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        padding: '16px',
        borderRadius: '10px',

        ['.position-title']: {
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'PingFang SC',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0px',
          color: '#000000DE',
          marginBottom: '16px',

          ['.title-info']: {
            marginLeft: '4px',
          },
        },

        ['.position-content']: {
          marginLeft: '34px',
        },

        ['.single-info']: {
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '22px',
          textAlign: 'left',
          letterSpacing: '0px',

          ['.single-title']: {
            color: '#0000005C'
          },

          ['.single-data']: {
            color: '#000000DE',
            fontWeight: 500,
            marginTop: '4px',
          },

          ['.single-data-more']: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
			  }
      }
		}
	]);
};

export default useStyle;
