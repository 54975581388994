import React, { useEffect, useRef } from 'react';
import { getLocale } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import useStyle from './style';
import classnames from 'classnames';


const prefixCls = 'pagelet-placeholder-card';
const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, item },
  } = props;
  const wrapSSR = useStyle(prefixCls);
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  return wrapSSR(
    <div className={classnames(prefixCls, {
      'pagelet-placeholder-card-resource': item?.resourceUrl
    })}>
      <img src={item?.resourceUrl || '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/placeholder_img.png'} alt="占位图" />
    </div>,
  );
};

export default Pagelet;
