import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';

const apis = manifest.apis || {};
const { request } = PageletApi;

export const saveTagGroup = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.saveTagGroup}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: params,
    usePermissionResponse,
  });
};
export const getTagGroupInfoMultilingual = ({
  apiHost,
  headers,
  params,
  usePermissionResponse,
}) => {
  return request(`${apiHost}/gateway/bricks${apis.getTagGroupInfoMultilingual}`, {
    method: 'GET',
    headers,
    params,
    usePermissionResponse,
  });
};
export const getTagGroup = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.getTagGroup}`, {
    method: 'GET',
    headers,
    params,
    usePermissionResponse,
  });
};
export const getTagBelongInfo = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.getTagBelongInfo}`, {
    method: 'GET',
    headers,
    params,
    usePermissionResponse,
  });
};
export const getAllSubSystemInfo = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.getAllSubSystemInfo}`, {
    method: 'GET',
    headers,
    params,
    usePermissionResponse,
  });
};
export const getTagGroupVersion = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.getTagGroupVersion}`, {
    method: 'GET',
    headers,
    params,
    usePermissionResponse,
  });
};
export const getTagGroupLanguageList = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.getTagGroupLanguageList}`, {
    method: 'GET',
    headers,
    params,
    usePermissionResponse,
  });
};
// ;
export const checkDimensionAndTagGroupDept = ({
  apiHost,
  headers,
  params,
  usePermissionResponse,
}) => {
  return request(`${apiHost}/gateway/bricks${apis.checkDimensionAndTagGroupDept}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: params,
    usePermissionResponse,
  });
};
export const authScopeOrgTree = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.authScopeOrgTree}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: params,
    usePermissionResponse,
  });
};
export const updateTagGroupBelong = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.updateTagGroupBelong}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: params,
    usePermissionResponse,
  });
};
export const updateTagCommonLanguage = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.updateTagCommonLanguage}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: params,
    usePermissionResponse,
  });
};
export const getObjectAndDimsTree = ({ apiHost, headers, params, usePermissionResponse }) => {
  return request(`${apiHost}/gateway/bricks${apis.getObjectAndDimsTree}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    data: params,
    usePermissionResponse,
  });
};
