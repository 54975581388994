import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-content', () => [
		{ // 标题部分
			[`.${prefixCls}`]: {
				marginTop: '29px',
				marginBottom: '12px',
				paddingLeft: '16px',
				paddingRight: '16px',
				flex: 1,
				display: 'flex',
				flexDirection: 'column',

				['p']: {
					paddingLeft: '12px',
					marginBottom: '4px',
					fontFamily: '苹方- 简',
					fontSize: '12px',
					lineHeight: '18px',
					color: 'rgba(0, 0, 0, 0.84)',
					position: 'relative',
					display: 'flex',
					alignItems: 'center',

					['.state']: {
						lineHeight: '12px',
						borderRadius: '4px',
						background: 'rgba(11, 187, 141, 0.102)',
						padding: '3px 4px',
						fontFamily: '苹方-简',
						fontSize: '12px',
						fontWeight: 500,
						color: '#3FAD55'
					},

					// 圆点
					['&:before']: {
						width: '4px',
						height: '4px',
						content: "''",
						display: 'inline-block',
						position: 'absolute',
						top: '7px',
						left: '0',
						borderRadius: '50%',
						background: 'rgba(0, 0, 0, 0.84)'
					},
					['&:last-child']: {
						marginBottom: '0px'
					}
				},
				['.perfJumpBtn']: {
					fontFamily: 'PingFang SC',
					fontSize: '14px',
					fontWeight: 500,
					color: '#FF6400',
					marginTop: '42px',
					lineHeight: '32px',
					borderRadius: '8px',
					background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF',
					border: '1px solid #FF6400',
					textAlign: 'center',
					cursor: 'pointer',
					['&:hover']: {
						background: 'rgba(255, 100, 0, 0.04)'
					}
				}
			}
		}
	]);
};

export default useStyle;
