import React, { useEffect, useState } from 'react';
import { getLocale, Spin, Empty, ConfigProvider, zh_CN, en_US, getLang } from '@didi/ec-base';
import classNames from 'classnames';
import { getManagerPosition, getOperateLog } from './services';
import { Template } from '@didi/ehr-grow-exp-chart';
import manifest from '../manifest.json';
import './style.scoped.less';

const Pagelet = (props) => {
  const { params: {
    apiHost,
    locales,
    headers,
    employeeId, // 员工id
    theme = 'blue', // 主题色
    onlyMgmt, // 是否仅看管理员
    logParams = {}, // 日志参数
    usePermissionResponse, // 无权限是否跳转
    effectDate = '',// 日期
  } } = props;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // 接口请求
  const getData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await getManagerPosition(apiHost, headers, { ...params, employeeId, effectDate }, usePermissionResponse);
      setLoading(false);
      if (res.code === '100200' && res.data) {
        setData({
          xData: res?.data?.roleYearMonthVoList,
          workExperience: res?.data?.workExperience,
          jobLevelList: res?.data?.jobLevelVoList,
          foldJobLevelList: res?.data?.foldJobLevelVoList,
          yData: res?.data?.roleEmploymentOrgVoList,
          foldYData: res?.data?.foldRoleEmploymentOrgVoList
        });
      } else {
        setData(null);
      }
    } catch (error) {
      setLoading(false);
      console.log('getManagerPosition', error);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getData({ includeEmployee: !onlyMgmt });
    }
  }, [employeeId, effectDate]);

  const prefixCls = 'pagelet-grow-exp-chart';

  // 多语言优先props，再取本地
  const langLocales = locales || manifest.locales || {};
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  // 日志
  const saveOperateLog = (operate) => {
    getOperateLog(apiHost, headers, {
      "operateName": "点击",
      "operateContent": "管理任职",
      "operateParams": {
        ...logParams,
        "操作": operate,
        "被浏览人工号": employeeId
      }
    }, usePermissionResponse);
  }

  return (
    <ConfigProvider prefixCls='ant5' locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{
      token: { colorPrimary: theme === 'orange' ? '#FF6400' : '#0a70f5' }
    }}>
      <Spin spinning={loading}>
        <div className={classes}>
          {
            data && <Template
              {...data}
              theme={theme}
              getData={getData}
              locales={locale}
              getOperateLog={saveOperateLog}
              onlyMgmt={onlyMgmt}
            />
          }
          {
            (!data && !loading) && <Empty style={{ marginBottom: '12px' }} />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
