import React from 'react';
import PropTypes from 'prop-types';

const NextArrow = ({ onClick, className, style }) => {
  return (
    <button className={className} style={style} onClick={onClick} />
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

const PrevArrow = ({ onClick, className, style }) => {
  return (
    <button className={className} style={style} onClick={onClick} />
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export { NextArrow, PrevArrow };
