import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('no-data', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        flex: 1,
        textAlign: 'center',
        borderRadius: '8px',

        ['img']: {
          width: '80px'
        },

        ['p']: {
          fontFamily: 'PingFang SC',
          fontSize: '12px',
          lineHeight: '16px',
          color: 'rgba(0, 0, 0, 0.6)'
        }
      },
    },
  ]);
};

export default useStyle;
