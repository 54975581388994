import React from "react";
import { Modal, Button } from "antd";
import CardIcon from '../CardIcon';

import "./index.scss";

function PromptModal(props) {
  const {
    modalClass = "", // 弹框类名，ct-promptmodal-bgred红色背景，ct-promptmodal-bgorange橙色背景，ct-promptmodal-bggreen绿色背景，不传则无背景
    closeTagFlag = true, // 是否展示右上角关闭小按钮，默认展示
    modalOpenFlag = false, // modal的状态，true打开，false关闭，默认关闭
    setModalOpenFlag, // 设置modal的状态
    promptImgSrc = "", // 弹窗内图片地址
    titleText = "", // 大标题
    discribeText = "", // 小标题
    contentList = [], // 内容，数组形式传入
    okText = "OK", // 确认按钮的文案
    cancelText = "", // 取消按钮的文案，不传则没有取消按钮
    handleOk = null, // 点击确认按钮的回调函数
    handleCancel = null, // 点击取消按钮的回调函数
    footer,
  } = props;

  const okFn = () => {
    if (handleOk) {
      handleOk();
    } else {
      setModalOpenFlag(false);
    }
  };

  const cancelFn = () => {
    if (handleCancel) {
      handleCancel();
    }
    setModalOpenFlag(false);
  };

  const setFooterFn = () => {
    if (cancelText) {
      return [
        <Button className="basis-button" onClick={cancelFn}>
          {cancelText}
        </Button>,
        <Button className="basis-button" bgType="gradient" onClick={okFn}>
          {okText}
        </Button>,
      ];
    }
    return [
      <Button className="basis-button" bgType="gradient" onClick={okFn}>
        {okText}
      </Button>,
    ];
  };

  return (
    <Modal
      className={`ct-promptmodal ${modalClass}`}
      width={600}
      visible={modalOpenFlag}
      onOk={okFn}
      zIndex={1000}
      onCancel={cancelFn}
      closeIcon={<CardIcon type="iconclosebeifen" />}
      closable={closeTagFlag}
      footer={footer || setFooterFn()}
    >
      {promptImgSrc ? (
        <div className="ct-promptmodal-center">
          {/* todo: 有图片的弹出框需要去掉背景色、插入图片promptImgSrc */}
          <div
            className="ct-promptmodal-center-img"
            style={{ background: "wheat" }}
          />
          <p className="ct-promptmodal-center-title">{titleText}</p>
          <div className="ct-promptmodal-center-contents">
            {contentList &&
              contentList.map((item) => (
                <p key={item} className="ct-promptmodal-center-contents-item">
                  {item}
                </p>
              ))}
          </div>
        </div>
      ) : (
        <div className="ct-promptmodal-left">
          <p className="ct-promptmodal-left-title">{titleText}</p>
          {discribeText ? (
            <p className="ct-promptmodal-left-discribe">{discribeText}</p>
          ) : null}
          <div className="ct-promptmodal-left-contents">
            {contentList &&
              contentList.map((item) => (
                <p key={item} className="ct-promptmodal-left-contents-item">
                  {item}
                </p>
              ))}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default PromptModal;
