import React, { useRef } from "react";
import { message, Tooltip } from "antd";
import CardIcon from '../CardIcon';
import './index.scss';

const TooltipListItem = ({ data }) => {
    const handleCopy = () => {
        copySource.current.select();
        document.execCommand('copy');
        message.success('复制成功');
    };
    const copySource = useRef(null);
    return <div className="copyable-tooltip-container">
        {data}
        <span onClick={handleCopy} style={{ color: '#FF7E41', cursor: 'pointer', marginLeft: '5px' }}>
            <CardIcon type="iconfuzhi" className="copyable-tooltip-icon" />
        </span>
        <input ref={copySource} value={data} />
    </div>
}

const CopyableTag = (props) => {

    const TooltipList = props.tooltiplist && props.multiple && props.tooltiplist.map(v => <TooltipListItem data={v} />)
    if (props.noCopyableTag) {
        return props.children
    }
    if (props.noCtrl) {
        if (!props.tooltiplist || !props.tooltiplist.length) {
            return props.children
        }
        return <Tooltip
            overlayClassName="copyable-tooltip"
            title={props.multiple ? TooltipList : <TooltipListItem data={props.tooltiplist} />}>
            {props.children}
        </Tooltip>
    }
    return <Tooltip
        visible={(!props.tooltiplist || (props.multiple && !props.tooltiplist.length)) ? false : props.tooltipvisible}
        title={props.multiple ? TooltipList : <TooltipListItem data={props.tooltiplist} />}
        overlayClassName="copyable-tooltip"
        onVisibleChange={props.onVisibleChange}>
        {props.children}
    </Tooltip>
}

export default CopyableTag;
