import manifest from '../../manifest.json';
import { getLocale } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';

const langLocales = manifest.locales || {};
const locale = getLocale(langLocales);

export const LangTranslate = (originTxt, param) => {
  const intl = Intl(locale);
  let newTxt = originTxt;
  try {
    newTxt = intl(originTxt, param) || originTxt;
  } catch (error) {
    console.log('LangTranslate error originTxt:', originTxt);
    console.log('LangTranslate error detail', error);
  }

  return newTxt;
};
