import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

  return useRegisterStyle('pagelet-personal-info-trainee', () => [
    {
      // base
      [`.${prefixCls}`]: {
        [`.${prefixCls}-drawer-content`]: {
          ['.ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav']: {
            position: 'absolute',
            top: '5px',
            left: '20px',
            ['.ant-tabs-tab']: {
              padding: '12px 8px 4px',
              marginRight: '16px',
              ['.ant-tabs-tab-btn']: {
                color: 'rgba(0, 0, 0, 0.72)',
                fontSize: '18px',
                fontWeight: '500',
              },
            },
            ['.ant-tabs-tab-active']: {
              ['.ant-tabs-tab-btn']: {
                color: 'rgba(0, 0, 0, 0.84)',
                fontWeight: '500',
              },
            },
            ['.ant-tabs-ink-bar.ant-tabs-ink-bar-animated']: {
              width: ' 22px !important',
              marginLeft: '43px',
              height: '4px',
              borderRadius: '4px',
            },
          },
          ['.ant-tabs-bottom>.ant-tabs-nav:before, .ant-tabs-bottom>div>.ant-tabs-nav:before, .ant-tabs-top>.ant-tabs-nav:before, .ant-tabs-top>div>.ant-tabs-nav:before']: {
            border: 'none',
          },
          ['.ant5-drawer-body']:{
            padding: '0px 16px 16px 28px',
          },
          ['.trainee-tit']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '18px',
            letterSpacing: '0px',
            color: 'rgba(0, 0, 0, 0.7216)',
            display: 'flex',
            alignItems: 'center',
            margin: '16px 0px',
          },
          ['.apply-table-wrapper']: {
            marginTop: '18px',
          },
          ['.ec-ehr-base-table-empty']:{
            width: '100% !important',
          }
        }
      }
    }
  ]);
};

export default useStyle;
