import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('result-area', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 12px',
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.05)',
      },
    },
  ]);
};

export default useStyle;
