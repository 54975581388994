import React, { useState, useEffect } from 'react';
import { Pagination, ConfigProvider} from 'antd';
import { Spin, Avatar } from '@didi/ec-base';
// import { Avatar} from '@didi/ec-base';
import PageletApi from '@didi/pagelets-sdk';
import RedRightArrow from '../../assets/red_right_arrow.svg';

const CareCard = (props) => {
  const {
    data: {
      empName = '',
      leaveDays = '',
      small = '',
      empId = '',
    },
    handleDrawer,
  } = props;


  return (
      <div className="holidays-care-card">
          <div className="holidays-care-card-avatar">
            <Avatar src={small ? small : '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/avatar.png'} />
            {/* <img src={small ? small : '//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/avatar_new.png'} alt="" /> */}
          </div>
          <div className="holidays-care-card-content">
            <div className="holidays-care-card-content-name">
              <span style={{fontWeight: '500px'}}>{empName}</span><span style={{fontWeight: 'normal' }}>&nbsp;{empId}</span>
            </div>
            <div className="holidays-care-card-content-item">
              <span>{leaveDays}天</span>
              <span
                className="message"
                onClick={() => {
                  handleDrawer()
                }}
              >
                <span>详情</span>
                <RedRightArrow />
              </span>
            </div>
          </div>
      </div>
  )
}

function HolidayDetails(props) {
  const { detailsLoading, subCode, subName, pageSize, hasNext, list, total, onClickMoreData = () => { }, auth, headers, deptCodes, startDate, endDate, subordinate } = props;
  const [pageNums, setPageNums] = useState(1);
  const [loading, setLoading] = useState(false);
  const onChange = (page) => {
    setLoading(true);
    onClickMoreData(subCode, page);
    setPageNums(page);
  }
  useEffect(()=>{
    if(!detailsLoading){
      setLoading(false);
    }
  },[detailsLoading]);
  useEffect(()=>{
    setPageNums(1);
  },[deptCodes, startDate, endDate, subordinate])
  const handleDrawer = (empId) => {
    const drawer = PageletApi.drawer({
      title: '员工休假详情',
      width: '600px',
      pageletName: '@didi/pagelet-employee-leave-details',
      // pageletUrl: '//127.0.0.1:9004/employee-leave-details.runtime.js', // 使用pageletUrl
      pageletProps: { empId, headers, auth }, // 透传给pagelet的props
      onClose: () => {
        // 点击关闭
        drawer.destroy();
      }
    });
    drawer.show();
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#ff6400' }}}>
    <div className="holidays-care-list">
      <Spin spinning={loading}>
        <div className='holidays-care-card-warp'>
          {
            list.map((item) => (
              <CareCard data={item} subName={subName} handleDrawer={()=> {handleDrawer(item?.empId)}} />
            ))
          }
        </div>
      </Spin>
      {hasNext && <div className="holidays-operation">
        <Pagination size="small"  pageSize={pageSize} showSizeChanger={false} current={pageNums} total={total} onChange={onChange} />
      </div>}
    </div>
    </ConfigProvider>
  );
}

export default HolidayDetails;
