import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // get请求示例
  // getRequestData: async (host, params) => {
  //   const result = await PageletApi.request(`${host}/${apis.getList}`, {
  //     method: 'get',
  //     params
  //   });

  //   return result;
  // },
  getSalaryInfo: async (host, params, headers, usePermissionResponse) => {
    const result = await PageletApi.request(`${host}/gateway/bricks${apis.getCbInfo}`, {
      method: 'get',
      headers,
      params,
      usePermissionResponse
    });

    return result;
  }
}

export default PageletServices;
