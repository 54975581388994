import { Spin } from '@didi/ec-base';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ResizableTable from '../ResizableTable';

export default forwardRef((props, ref) => {
  const {
    className,
    pagination,
    loadDataFunc,
    defaultChoice,
    pageSizeOptions = ['10', '20', '50', '100'],
    dataSource,
    date,
    onResonse,
    columns,
    ...otherProps
  } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const getData = async ({
    current = page.current,
    pageSize = page.pageSize,
    sorter = {},
    ...curFilters
  } = {}, change) => {

    const params = {
      pageNum: change || current,
      pageSize,
      sorter,
      ...filter,
      ...curFilters,
    };
    if(change) {
      // 清空表格
      setData([]);
      setPage({ current: 1, pageSize: 10, total: 0 });
      setFilter({
        ...filter,
        ...curFilters,
      });
    }
    for (let i in params) {
      if (!params[i]) { delete params[i]; }
    }
    setFetching(true);
    const res = await loadDataFunc(params);
    setFetching(false);
    if (res?.code === '100200') {
      const {data} = res;
      let tableData = data?.data || data?.list;
      if (tableData) {
        tableData = tableData.map((v, i)=>{
          return {
            ...v,
            id: i
          }
        })
        setData(tableData);
        setPage({
          current: data.pageNum,
          pageSize: data.pageSize,
          total: data.total,
        });
        setFilter({
          ...filter,
          ...curFilters,
        });
        onResonse && onResonse(data)
      } else if (!data.data && params.pageNum > 1) {
        // 如果当前非第一页，且数据为空，则加载前一页
        getData({ current: params.pageNum - 1 });
      } else {
        setData([]);
        setPage({ current: 1, pageSize: 10, total: 0 });
        setFilter({
          ...filter,
          ...curFilters,
        });
      }
    } else {
      setData([]);
      setPage({ current: 1, pageSize: 10, total: 0 });
      setFilter({
        ...filter,
        ...curFilters,
      });
    }

  };
  useImperativeHandle(ref, () => ({
    loadData: (params) => {
      getData(params);
    },
  }));

  useEffect(() => {
    if (dataSource) {
      setData(dataSource);
      return
    }
  }, []);

  useEffect(()=>{
    getData({}, 1);
  }, [defaultChoice, date])

  return (
    <Spin spinning={fetching}>
      <ResizableTable
        ref={ref}
        {...props}
        dataSource={data}
        pagination={{
          current: page.current,
          pageSize: page.pageSize,
          total: page.total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions,
          // hideOnSinglePage: true,
        }}
        onChange={(e, filters, sorter) => {
          let {current, pageSize }= e;
          if (!dataSource) getData({ current, pageSize, sorter});
        }}
      />
    </Spin>
  );
});
