import React, { useEffect, useState, useRef } from 'react';
import { getLocale, Tooltip, Divider } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import { getContractualRelationshipsList } from './services';
import { TimelineCard } from '@didi/ehr-timeline-card';
import classNames from 'classnames';

import useStyle from './style';

const localLocales = manifest.locales || {};

const { render: { LineItem } } = TimelineCard;

const isNull = (str) => {
	if (`${str}`.replace(/\s/ig, '') === '' || str === null || str === undefined) {
		return '-';
	};
	return str;
}

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, employeeId },
  } = props;

  const [data, setData] = useState([]);
  const divRefs = useRef([]);
  // 最大高度
  const [maxHeight, setMaxHeight] = useState(0);

  const getRelationshipsDetail = async () => {
    try {
      const res = await getContractualRelationshipsList(apiHost, headers, {
        employeeId
      });
      const { data } = res;
      // 根据数据长度初始化ref数组
      divRefs.current = data?.map(() => React.createRef());
      setData(data);
    } catch (error) {
      console.log('getContractualRelationshipsList', error);
    }
  };

  useEffect(() => {
    getRelationshipsDetail();
  }, []);

  useEffect(() => {
    // 当数据更新后，获取每个div的高度
    const heights = divRefs.current.map(ref => ref?.current?.offsetHeight || 0);
    const newMaxHeight = Math.max(...heights);
    // 如果新的最大高度与当前的最大高度不同，则更新它
    if (newMaxHeight !== maxHeight) {
      setMaxHeight(newMaxHeight);
    }
  }, [data]);

  const prefixCls = 'pagelet-contractual-relationships';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      {
        data?.length > 0 &&
          <TimelineCard>
            {
               data.map((item, index) => (
                  <LineItem startTime={isNull(item?.contractBegin)} endTime={isNull(item?.contractEnd)}>
                    <div className='contract-card-container' ref={divRefs?.current?.[index]} style={{height: maxHeight}}>
                      <Tooltip title={item.companyDesc} placement="topLeft">
                        <div className='contract-card-train'>
                          {isNull(item.companyDesc)}
                        </div>
                      </Tooltip>
                      <div className='contract-card-tag'>
                        {item.contractType}{item.contractType && item.contractPeriod && <Divider type="vertical" />}{item.contractPeriod}
                      </div>
                    </div>
                  </LineItem>
               ))
            }
          </TimelineCard>



      }
    </div>,
  );
};

export default Pagelet;
