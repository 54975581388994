import React from "react";
import { Input } from "antd";
import { cloneDeep } from "lodash";
import { langMaps, tabsLangMaps } from "../../utils/Tool";
import classnames from "classnames";
import "./index.scss";

// 语言标签
const LangIcon = ({ currentLanguage }) => {
  const langText = langMaps[currentLanguage];
  return (
    <div className="ct-editor-icon-content">
      <span
        className={classnames({
          "cn-language-class": currentLanguage === 0,
          "other-language-class": currentLanguage !== 0,
        })}
      >
        {langText}
      </span>
    </div>
  );
};

// 选项编辑器
const OptionEditor = (props) => {
  const { language, contents, value = [], onChange } = props;

  const triggerChange = (changedValue) => {
    onChange?.({});
  };

  // 返回数据
  const getEditorValue = (v) => {
    const data = cloneDeep(value);
    let languagesContents = data || [];
    if (
      languagesContents.filter(
        (item) => item.languageType === v.languageType
      )[0]
    ) {
      languagesContents = languagesContents.map((item) => {
        if (item.languageType === v.languageType) {
          item = { ...item, ...v };
        }
        return item;
      });
    } else {
      languagesContents.push(v);
    }
    languagesContents = languagesContents.sort(
      (a, b) => a.languageType - b.languageType
    );
    onChange(languagesContents);
  };

  return (
    <div className="option-editor-container">
      <div className="option-editor-content">
        <LangIcon currentLanguage={0} />
        <Input placeholder={`请输入${tabsLangMaps[0]}内容`} />
      </div>
      {contents.length > 1 && (
        <div className="option-editor-content">
          <LangIcon currentLanguage={language} />
          <Input placeholder={`请输入${tabsLangMaps[language]}内容`} />
        </div>
      )}
    </div>
  );
};

export default OptionEditor;
