import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const Atmosphere = props => {
  const { data, showImg, width } = props;
  const atmosphereRef = useRef(null);
  const myChartRef = useRef(null);

  useEffect(() => {
    const chartDom = atmosphereRef.current;
    if (!chartDom) return;
    myChartRef.current = echarts.init(chartDom, null, { resize: true });    
    const option = {
      tooltip: {
        trigger: 'axis',
        padding: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        extraCssText: 'box-shadow: 0px 0px 3px 0px rgba(190, 198, 218, 0.8);',
        formatter: params => {
          let result = `<span style="color: rgba(0, 0, 0, 0.84); font-size: 12px;">${params[0]?.name?.split('.')?.[1]}月团队eNPS值</span><br>`;
          params.forEach(param => {
            result += `
              <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background-color: ${param?.color}; margin-left: 5px;"></span>
              <span style="font-size: 10px; color: rgba(0, 0, 0, 0.72)">${param.seriesName}</span>: 
              <span style="color: rgba(0, 0, 0, 0.84); font-size: 12px;">${param.value}${param?.value === '-' ? '' : '%'}</span>
              <br>`;
          });
          return result;
        }
      },
      legend: {
        show: false
      },
      grid: {
        left: 0,
        right: 0,
        bottom: '10',
        top: '10',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: data.map(item => item.date.slice(2)),
        axisTick: {
          show: true,
          alignWithLabel: true
        }
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: [
        {
          name: '公司',
          type: 'line',
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(10, 112, 245, 0.12)' },
              { offset: 1, color: 'rgba(10, 112, 245, 0)' }
            ])
          },
          data: data.map(item => item.companyEnps === '' ? '-' : Number(item.companyEnps?.replace('%', '')))
        },
        {
          name: '部门',
          type: 'line',
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(71, 200, 141, 0.12)' },
              { offset: 1, color: 'rgba(71, 200, 141, 0)' }
            ])
          },
          data: data?.map(item => item.companyEnps === '' ? '-' : Number(item.deptEnps?.replace('%', '')))
        }
      ]
    };

    myChartRef?.current?.setOption(option);
    return () => {
      myChartRef?.current?.dispose();
    };
  }, [data]);

  useEffect(() => {  
    myChartRef.current?.resize();
  }, [width]);

  return (
    showImg === false
      ? <div ref={atmosphereRef} className='echarts-atmosphere'></div>
      : <div className='echarts-atmosphere-img' style={{ backgroundImage: "url('//img-hxy021.didistatic.com/static/ehr_static/imgs/canghai/cards/zh_CN/4.png')" }} />
  );
}

export default Atmosphere;
