import React from 'react';
import { Select, Spin, Tooltip } from 'antd';
import { textEllipsisFormate } from '@/utils';

function LoadingSelect(props) {
  const optionRender = option => {
    return (
      <Tooltip placement="topLeft" title={option?.label}>
        {option?.label?.length ? textEllipsisFormate(option?.label, 30) : option?.label}
      </Tooltip>
    );
  };
  const labelRender = labVal => {
    return (
      <Tooltip placement="topLeft" title={labVal?.label}>
        {labVal?.label}
      </Tooltip>
    );
  };

  return (
    <Spin spinning={props?.loading} indicator={<div />}>
      <Select {...props} optionRender={optionRender} labelRender={labelRender} />
    </Spin>
  );
}

export default LoadingSelect;
