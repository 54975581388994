import React, { useState, useRef, useMemo, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { ConfigProvider as ECConfigProvider, zh_CN, en_US, getLang } from '@didi/ec-base';
import TagConfig from '@/pages/TagConfig';
import TagPoolConfig from '@/pages/TagPoolConfig';
import { Tabs } from 'antd';
import { LangTranslate } from '@/utils';
import { CurrentPageTypeInfoContext, BricksInfoContext, HandelRefContext } from './context.jsx';
import ChangeBelongDeptModalFomily from '@/cpns/ChangeBelongDeptModalFomily';
import MultipleLangConfigModal from '@/cpns/MultipleLangConfigModal';
import classNames from 'classnames';
const FontFamily =
  '"Helvetica Neue", Helvetica, Arial, "PingFang SC","Heiti SC","Hiragino Sans GB","Microsoft YaHei",sans-serif';

import './index.less';

function Pagelet(props) {
  const {
    params: {
      apiHost,
      locales,
      headers,
      employeeId,
      history,
      getHeaderInfo,
      orgDeptTree,
      usePermissionResponse = true,
      handleRef,
      showContent: originShowContent,
    },
  } = props;
  const searchParams = new URLSearchParams(window.location.search);
  const tagPoolId = searchParams?.get('tagPoolId');
  const readPretty = searchParams?.get('readPretty');
  const urlPageType = searchParams?.get('pageType');
  const [pageType, setPageType] = useState('tagConfig');
  const [currentModalParams, setCurrentModalParams] = useState('tagConfig');
  const [visibleLangModal, setVisibleLangModal] = useState(false);
  const [showContent, setShowContent] = useState(originShowContent || false);
  const [visibleChangeAffiliationModal, setVisibleChangeAffiliationModal] = useState(false);
  const temDataRef = useRef({});

  useEffect(() => {
    setPageType(() => (urlPageType === 'tagPoolConfig' ? urlPageType : 'tagConfig'));
  }, [urlPageType]);

  const BricksInfoContextVal = useMemo(
    () => ({
      headers: headers,
      apiHost,
      getHeaderInfo,
      orgDeptTree,
      usePermissionResponse,
    }),
    [headers, apiHost, getHeaderInfo, orgDeptTree, usePermissionResponse],
  );
  console.log('BricksInfoContextVal', BricksInfoContextVal);
  useEffect(() => {
    setShowContent(() => originShowContent);
  }, [originShowContent]);

  useEffect(() => {
    handleRef.current = {
      visibleLangModal,
      setVisibleLangModal: ({
        visible = false,
        tagPoolId = '',
        refresh = false,
        afterCallBack = () => {},
      }) => {
        setVisibleLangModal(() => visible);
        setCurrentModalParams(() => ({ tagPoolId, refresh, afterCallBack }));
      },
      visibleChangeAffiliationModal,
      setVisibleChangeAffiliationModal: ({
        visible = false,
        tagPoolId = '',
        refresh = false,
        afterCallBack = () => {},
      }) => {
        setVisibleChangeAffiliationModal(() => visible);
        setCurrentModalParams(() => ({ tagPoolId, refresh, afterCallBack }));
      },
      showContent,
      setShowContent,
    };
  }, []);

  const currentPageTypeInfoContextVal = useMemo(
    () => ({
      pageType,
      setPageType,
      temDataRef,
    }),
    [pageType],
  );
  const newClassName = classNames('label-system-Pagelet-content-wrap', {
    'label-system-Pagelet-show-content-wrap': showContent,
  });
  return (
    <ConfigProvider
      prefixCls={'label-system-Pagelet-antd'}
      theme={{
        token: {
          colorPrimary: '#FF7813',
          fontFamily: FontFamily,
        },
      }}
    >
      <HandelRefContext.Provider value={handleRef}>
        <BricksInfoContext.Provider value={BricksInfoContextVal}>
          <ECConfigProvider
            prefixCls="talent-market-Pagelet-antd"
            locale={getLang() === 'en-US' ? en_US : zh_CN}
            theme={{
              token: {
                colorPrimary: '#0a70f5',
              },
            }}
          >
            <CurrentPageTypeInfoContext.Provider value={currentPageTypeInfoContextVal}>
              <div
                style={{ fontFamily: FontFamily, position: 'relative' }}
                className={newClassName}
              >
                {!!showContent && (
                  <Tabs
                    destroyInactiveTabPane
                    className="label-system-Pagelet-content-not-visible-tab"
                    activeKey={pageType}
                    items={[
                      {
                        key: 'tagConfig',
                        label: LangTranslate('标签配置'),
                        children: <TagConfig history={history} readPretty={readPretty} />,
                      },
                      {
                        key: 'tagPoolConfig',
                        label: LangTranslate('标签组配置'),
                        children: <TagPoolConfig history={history} readPretty={readPretty} />,
                      },
                    ]}
                  />
                )}
                <MultipleLangConfigModal
                  visible={visibleLangModal}
                  setVisible={setVisibleLangModal}
                  currentModalParams={currentModalParams}
                />
                <ChangeBelongDeptModalFomily
                  visible={visibleChangeAffiliationModal}
                  setVisible={setVisibleChangeAffiliationModal}
                  currentModalParams={currentModalParams}
                />
              </div>
            </CurrentPageTypeInfoContext.Provider>
          </ECConfigProvider>
        </BricksInfoContext.Provider>
      </HandelRefContext.Provider>
    </ConfigProvider>
  );
}

export default Pagelet;
