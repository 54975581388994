import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../../manifest.json';
const apis = manifest.apis || {};


const PageletServices = {
  initCardData: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-care');

    return await request(`${host}/${apis.initCardData}`, {
      method: 'post',
      data: body,
    });
  },
  getNextDataByQuery: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-care');

    return await request(`${host}/${apis.getNextDataByQuery}`, {
      method: 'post',
      data: body,
    });
  },

  initFilter: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-team-care');

    return await request(`${host}/${apis.initFilter}`, {
      method: 'post',
      data: body
    });
  },

  // // get请求示例
  // getRequestData: async (host, params) => {
  //   const result = await PageletApi.request(`${host}/${apis.getList}`, {
  //     method: 'get',
  //     params
  //   });
  //
  //   return result;
  // },
  //
  // // post请求示例
  // getRequestData: async (host, data) => {
  //   const result = await PageletApi.request(`${host}/${apis.getList}`, {
  //     method: 'post',
  //     data,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  //
  //   return result;
  // }
}

export default PageletServices;
