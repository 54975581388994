import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('members-copyable-tag', () => [
		{
			// base
      '.copyable-tooltip': {
        '&.ant-tooltip': {
          maxWidth: 'initial'
        }
      },
      '.copyable-tooltip-container': {
        overflow: 'hidden',
        position: 'relative',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'space-between',
        'input': {
          position: 'absolute',
          right: '-40px',
          width: '30px',
        }
      }
    }
	])
}

export default useStyle;
