import React from 'react';

import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2111040_295i9p3fiwv.js',
});
export default (props) => {
  const { type, className = '', style = {}, onClick } = props;
  return (
    <IconFont type={type} className={`card-icon ${className}`} aria-hidden="true" style={{ ...style }} onClick={onClick} />
  )
}