import React, { useContext } from 'react';
import { BricksInfoContext } from '@/context';
import { getAllSubSystemInfo } from '@/services';
import { useRequest } from 'ahooks';
import { Select, Tooltip } from 'antd';
import { textEllipsisFormate } from '@/utils';

import './index.less';

// 归属系统选择组件
function DepartmentTreeSelect(props) {
  const BricksInfoContextVal = useContext(BricksInfoContext);
  const { data: subSystemInfo = [], loading: getSubSystemInfoLoading } = useRequest(
    async params => {
      try {
        const res = await getAllSubSystemInfo({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params,
          usePermissionResponse: BricksInfoContext?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { refreshDeps: [BricksInfoContextVal] },
  );
  const optionRender = option => {
    return (
      <Tooltip placement="topLeft" title={option?.label}>
        {option?.label?.length ? textEllipsisFormate(option?.label, 30) : option?.label}
      </Tooltip>
    );
  };
  const labelRender = labVal => {
    return (
      <Tooltip placement="topLeft" title={labVal?.label}>
        {labVal?.label}
      </Tooltip>
    );
  };
  return (
    <div>
      <Select
        {...props}
        value={props?.value || undefined}
        options={subSystemInfo}
        fieldNames={{
          label: 'name',
          value: 'code',
        }}
        showSearch={true}
        optionFilterProp={'name'}
        optionRender={optionRender}
        labelRender={labelRender}
      />
    </div>
  );
}

export default DepartmentTreeSelect;
