import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import '../../style.scoped.less';

const imgs = [
  'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/birthday.png',
  'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/probation.png',
  'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/probation-change.png',
  'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/onboarding.png',
  'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/zhounian.png',
  'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/team-building.png',
]

const SIGNPOST_LEFT_IMAGE = 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/signpost-left.png'
const SIGNPOST_RIGHT_IMAGE = 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/signpost-right.png'

// 指示牌位置
const SIGN_POSITION = new Map([
  // left top
  ['small', [[-48, -36], [-46, -38], [-76, -30], [-36, -34], [-50, -40], [-48, -28]]],
  ['middle', [[-60, -30], [-60, -40], [-84, -30], [-46, -28], [-60, -46], [-64, -28]]],
  ['large', [[-58, -36], [-60, -40], [-82, -32], [-62, -28], [-68, -46], [-70, -32]]]
])

const KeyComponents = (props) => {
  const {item = {}, index = 0, viewSize = 'small', prefixCls, onClick = () => {}} = props;
  const {
    content,
    effectDate,
    isCurrentYearBegin,
    top,
    left,
    start,
    year,
    hasNew,
    iconUrl
  } = item;
  const [position, setPosition] = useState(SIGN_POSITION.get(viewSize));
  const [maxSize, setMaxSize] = useState(10);

  useEffect(() => {
    setPosition(SIGN_POSITION.get(viewSize || 'small'))
  }, [viewSize])

  useEffect(() => {
    if (hasNew) {
      setMaxSize(9)
    }
  }, [hasNew])

  return <div style={{
      position: 'absolute',
      top,
      left,
      // right: index % 2 === 1 ? rightPosition.get(viewSize)[index % 6] : '',
      width: '142px',
      textAlign: 'left',
      zIndex: 20
      }}>
            { start && <div id={`${year}`} style={{height: 0, position: 'absolute', top: '-40px'}}></div>}
        {
          isCurrentYearBegin && <div style={{position: 'relative', zIndex: 10}}>
            <img src={index % 2 === 0 ? SIGNPOST_LEFT_IMAGE : SIGNPOST_RIGHT_IMAGE} style={{position: 'absolute', left: `${position[index][0]}px`, top: `${position[index][1]}px`}} width={70} height={90}/>
            <span
              style={{
                position: 'absolute',
                left: (position[index][0] + (index % 2 === 0 ? 8 : 10)) + 'px',
                top: (position[index][1] + (index % 2 === 0 ? 26 : 17)) + 'px',
                color: '#F3F7EE',
                transform: `rotate(${index % 2 === 0 ? -13 : 8}deg)`,
                fontSize: '12px'
              }}
              >
              {dayjs(effectDate).format('YYYY.MM')}
            </span>
          </div>
        }
        <img
          src={iconUrl}
          width={70}
          height={70}
          className={`${prefixCls}-content-img`}
          onClick={onClick} style={{position: 'relative', zIndex: 20, cursor: 'pointer'}}
          />
        <div className={`${prefixCls}-content-popover`} onClick={onClick}>
          <p>
            {content && (content.length > maxSize ? `${content.substring(0, maxSize - (hasNew ? 1 : 0))}...` : content)}
            {
              hasNew && <span className={`${prefixCls}-content-popover-new`}>new</span>
            }
            {/* {content} */}
          </p>
          <span>
            {
              effectDate ? dayjs(effectDate).format('YYYY.MM.DD') : '等待点亮'
            }
          </span>
        </div>
    </div>;
};

export default KeyComponents;
