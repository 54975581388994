import React, { useState } from 'react';
import { Radio } from 'antd';
import { useField, connect, mapReadPretty } from '@formily/react';
import './index.less';

const CustomRadioGroup = ({ value, onChange }) => {
  const filed = useField();
  const { option } = filed?.componentProps;
  const handelOnChange = ({ target: { value } }) => {
    onChange(value);
  };
  return (
    <Radio.Group
      className="pagelet-label-system-custom-radio-group"
      options={option}
      onChange={handelOnChange}
      value={value}
    />
  );
};
const CustomRadioGroupWrap = connect(
  CustomRadioGroup,
  mapReadPretty(props => (
    <div className="pagelet-label-system-custom-radio-group-read-pretty">
      <CustomRadioGroup {...props} onChange={() => {}} />
    </div>
  )),
);

export default CustomRadioGroupWrap;
