import React, { useEffect, useState } from 'react';
import { getLocale, Spin, Button, ConfigProvider, Empty, zh_CN, en_US, getLang } from '@didi/ec-base';
import manifest from '../../manifest.json';
import classNames from 'classnames';
import useStyle from './style';
import { getPositionProcess, getPositionProcessDownload, getOperateLog } from './services';
import { Template } from '@didi/ehr-job-position-chart';
import DownArrowOutlined from './assets/DownArrowOutlined.svg';
import html2canvas from 'html2canvas';

const localLocales = manifest.locales || {};

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost, locales, headers, employeeId, auth, usePermissionResponse },
  } = props;

  const downloadAuth = auth?.find(item => item?.elementTag === 'archive-position-process-download');

  const prefixCls = 'pagelet-job-position-chart';
  const wrapSSR = useStyle(prefixCls);

  // 多语言优先 props，再取本地
  // const langLocales = localLocales;
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const classes = classNames(prefixCls);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [downLoading, setDownLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await getPositionProcess(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data) {
        setData({ ...res.data });
      }
    } catch (e) {
      setLoading(false);
      console.log('getPositionProcesse',e);
    }
  };

  const exportData = () => {
		setDownLoading(true);
    let imgContainerDom = document.querySelector('.ehr-jobposition-content');
		let copyDom = imgContainerDom.cloneNode(true);
		copyDom.classList.add('ehr-jobposition-cloneNode');
		document.body.appendChild(copyDom);
		let cloneNode = document.querySelector('.ehr-jobposition-cloneNode');
		let leftWidth = cloneNode.querySelector('.ehr-jobposition-content-left').offsetWidth;
		let maxWidth = cloneNode.querySelector('.ehr-jobposition-content-right-content').offsetWidth + 20;
		let maxHeight = cloneNode.querySelector('.ehr-jobposition-content-right-content').offsetHeight + 20;
		cloneNode.querySelector('.ehr-jobposition-content-right').style.width = maxWidth + 'px';
    cloneNode.querySelector('.ehr-jobposition-content-right').style.height = maxHeight + 'px';
		cloneNode.style.width = maxWidth + leftWidth + 20 + 'px';
		html2canvas(cloneNode, {
			scale: 1
		}).then(function (canvas) {
			document.body.removeChild(cloneNode);
      var dataImg = canvas.toDataURL('image/png');
      var parts = dataImg.split(';base64,');
      const downQueryHeader = { ...headers, 'bricks-function-id': downloadAuth?.functionId };
			getPositionProcessDownload(apiHost, downQueryHeader ,{
				imageData: parts[1],
				employeeId
			}).then((res) => {
        setDownLoading(false);
        if (res?.code === '100200' && res?.data) {
					window.open(res.data, '_self');
				}
      }).catch(error => {
        setDownLoading(false);
        console.log('getPositionProcessDownload', error);
      });
      getOperateLog(apiHost, downQueryHeader, {
        operateName: "下载",
        operateContent: "岗位任职",
        operateParams: { "被下载人工号": employeeId }
      })
		});
  };

  // 接口请求
  useEffect(() => {
    if (employeeId) {
      getData();
    }
  }, [employeeId]);

  return wrapSSR(
    <ConfigProvider prefixCls='ant5' locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{
      token: {
        colorPrimary: '#0a70f5'
      }
    }}>
      <Spin spinning={loading}>
        <div className={classes}>
          <div className='job-position-detail'>
            <div className='detail-info'>
              {
                data?.promotionNum > 0 && <span>{locale?.Promoted}<span>{data?.promotionNum}</span>{data?.promotionNum > 1 ? locale?.times : locale?.time}</span>
              }
              {
                data?.mobilizeNum > 0 && <span>{locale?.DepartmentRoutechanged}<span>{data?.mobilizeNum}</span>{data?.mobilizeNum > 1 ? locale?.times : locale?.time}</span>
              }
              {
                data?.jobLevelStayTime > 0 && <span>{locale?.CurrentjoblevelDuration}<span>{data?.jobLevelStayTime}</span>{data?.jobLevelStayTime > 1 ? locale?.years : locale?.year}</span>
              }
            </div>
          {
            downloadAuth &&  Object.keys(data)?.length > 0 && <Button
              type="text"
              icon={<DownArrowOutlined className='card-icon'/>}
              onClick={exportData}
              loading={downLoading}
              className="downLoad-btn"
            >
              {locale?.Download}
            </Button>
          }
          </div>
          {
            Object.keys(data)?.length > 0 && <Template {...data} locales={locale} />
          }
          {
            (!data || Object.keys(data).length === 0) && !loading && <Empty style={{ marginBottom: '12px' }} />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
