import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  // 人才搜索接口
  getSearchData: async (host, params) => {
    const request = PageletApi.newRequest.getInstance('@didi/pagelet-canghai-talent-search');
    const result = await request(`${host}/${apis.initSearchCanghaiData}`, {
      method: 'get',
      params
    });

    return result;
  },
}

export default PageletServices;
