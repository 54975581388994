import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { getNewEmployeeLink } from '../../utils/Tool';

const text = '您不在本次参与调研的名单范围内';

// 新员工用户根据链接来到这个空页面，调用后端接口根据返回值，进行相应跳转
export default () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // 获取查询参数
  const sceneId = queryParams.get('sceneId');
  const researchTag = queryParams.get('researchTag');

  const getNewEmployeeLinkFn = async () => {
    try {
      const res = await getNewEmployeeLink(sceneId, researchTag);
      const { deliveryRule, reachUrl } = res?.data;
      if (reachUrl) {
        window.location.href = reachUrl;
        return;
      }
      if (deliveryRule === 'PROFESSIONAL_SERVICES') {
        sessionStorage.setItem('surveyBusType_error', deliveryRule);
        sessionStorage.setItem('mesData', text);
        history.push('/exceptionPage');
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  useEffect(() => {
    getNewEmployeeLinkFn();
  }, []);

  return (
    <div className="new-employee" style={{ width: '100vw', height: '100vh' }} />
  );
};
