import React, { useEffect, useState, useRef } from 'react';
import { getLocale, Spin, ConfigProvider, Tag, Empty, zh_CN, en_US, getLang } from '@didi/ec-base';
import classNames from 'classnames';
import PerformanceChart from './PerformanceChart';
import useStyle from './style';
import { getPerformance, getOperateLog } from './services';
import manifest from '../../manifest.json';

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';
const X_PER_WIDTH = 88;

const Pagelet = props => {
  const { params: { apiHost,
    locales,
    headers,
    employeeId,
    usePermissionResponse
  } } = props;

  const prefixCls = 'pagelet-performance-records';
  const wrapSSR = useStyle(prefixCls);

  const ref = useRef();
  const scrollContainerRef = useRef();

  // 多语言优先props，再取本地
  const langLocales = locales || manifest.locales || {};
  const locale = getLocale(langLocales);
  const classes = classNames(prefixCls);

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true);

  // 接口请求
  const getData = async () => {
    try {
      setLoading(true);
      const res = await getPerformance(apiHost, headers, { employeeId }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res?.data).length > 0){
        setData(res.data);
      }
    } catch (error) {
      console.log('getPerformance', error);
    }
  };

  // 日志
  const saveOperateLog = (chartTitle) => {
    getOperateLog(apiHost, headers, {
      "operateName": "点击",
      "operateContent": chartTitle + "评价",
      "operateParams": {
        "被浏览人工号": employeeId
      }
    }, usePermissionResponse);
  }

  useEffect(() => {
    if (employeeId) {
      getData();
    }
  }, [employeeId]);

  useEffect(() => {
    if (data?.yearList?.length > 0) {
      setTimeout(() => {
        scrollContainerRef.current.scrollLeft = X_PER_WIDTH * data?.yearList?.length;
      }, 0);
    }
  }, [data?.yearList]);

  return wrapSSR(
    <ConfigProvider prefixCls='ant5' locale={getLang() === 'en-US' ? en_US : zh_CN} theme={{
      token: {
        colorPrimary: '#0a70f5'
      }
    }}>
    <Spin spinning={loading}>
      <div className={classes} ref={ref}>
        <div className='performance-record-detail-info'>
          {
            data?.total > 0 && <div>
              <div>{locale?.AnualPerformance}:</div>
              <div>{locale?.Participated}<span>{data?.total}</span>{data?.total > 1 ? locale?.times : locale?.time}</div>
              {
                data?.high > 0 && <div>{locale?.highPerformance}<span>{data?.high}</span>{data?.high>1?locale?.times:locale?.time}</div>
              }
              {
                data?.low > 0 && <div>{locale?.lowPerformance}<span>{data?.low}</span>{data?.low>1?locale?.times:locale?.time}</div>
              }
              {
                data?.appeal > 0 && <div>{locale?.escalate}<span>{data?.appeal}</span>{data?.appeal>1?locale?.times:locale?.time}</div>
              }
              {
                data?.tagList?.map(item => {
                  return <Tag style={{ color: '#4183D9', borderColor: '#aac7eb', marginLeft: '6px',fontWeight: 500 }}>{item}</Tag>
                })
              }
            </div>
          }
          {
            data?.feedbackTotal > 0 && <div>
              <div>{locale?.MidYearFeedback}:</div>
              <div>{locale?.Participated}<span>{data?.feedbackTotal}</span>{data?.feedbackTotal > 1 ? locale?.times : locale?.time}</div>
              {
                data?.aboveExpectation > 0 && <div>{locale?.AboveExpectations}<span>{data?.aboveExpectation}</span>{data?.aboveExpectation > 1 ? locale?.times : locale?.time}</div>
              }
              {
                data?.belowExpectation > 0 && <div>{locale?.NeedToImprove}<span>{data?.belowExpectation}</span>{data?.belowExpectation > 1? locale?.times : locale?.time}</div>
              }
            </div>
          }
        </div>
        {
          data && Object.keys(data).length > 0 && <div ref={scrollContainerRef} style={{ overflow: 'auto' }}>
            <div style={{ width: `${X_PER_WIDTH * data?.yearList?.length}px`, minWidth: '100%' }}>
              <PerformanceChart
                xAxisData={data?.yearList}
                performanceData={data?.performanceList}
                feedbackData={data?.feedbackList}
                locale={locale}
                saveOperateLog={saveOperateLog}
              />
            </div>
          </div>
        }
        {
          !loading && (!data||Object.keys(data).length === 0) && <Empty />
        }
      </div>
    </Spin>
  </ConfigProvider>
  );
};

export default Pagelet;
