// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/H5Foreword.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".question-main-des-mobile{\n  padding:2.7rem 2.2rem 1rem 2.2rem;\n  background-color:#f7f8fa;\n  position:relative;\n  font-size:13px;\n  color:#676778\n}\n\n.ct-lang-h5-select{\n  position:absolute;\n  z-index:10;\n  padding:0 2px;\n  line-height:30px;\n  height:30px;\n  border:1px solid rgba(0,0,0,.4);\n  border-radius:6px;\n  top:1rem;\n  right:1.71rem;\n  font-size:13px;\n  color:#333\n}\n\n.ct-lang-h5-select .am-list-extra{\n  display:none\n}\n\n.ct-lang-h5-select .am-list-item .am-list-line .am-list-arrow-horizontal{\n  display:none\n}\n\n.ct-lang-h5-select .am-list-line{\n  padding:0 16px 0 0;\n  border-bottom:none !important\n}\n\n.ct-lang-h5-select .am-list-item{\n  min-height:30px;\n  background-color:transparent;\n  padding-left:8px\n}\n\n.ct-lang-h5-select .am-list-item .am-list-line .am-list-content{\n  font-size:12px;\n  line-height:30px;\n  padding-top:0px;\n  padding-bottom:0px\n}\n\n.ct-lang-h5-select .am-list-item .am-list-line .am-list-content::after{\n  width:0;\n  height:0;\n  border:4px solid transparent;\n  border-top-color:#666;\n  content:\"\";\n  position:absolute;\n  right:10px;\n  top:13px\n}\n\n.ct-lang-pc-select{\n  float:right;\n  margin:24px 170px 0 0;\n  font-weight:600;\n  font-size:14px\n}\n\n.ct-lang-pc-select span:nth-child(1){\n  margin-right:7px;\n  cursor:pointer\n}\n\n.ct-lang-pc-select span:nth-child(2){\n  display:inline-block;\n  width:1px;\n  height:10px;\n  background:#d8d8d8\n}\n\n.ct-lang-pc-select span:nth-child(3){\n  cursor:pointer;\n  margin-left:7px\n}\n\n.ct-lang-select-icon svg{\n  width:10px\n}\n\n.question-main-des-mobile::before{\n  content:\"\";\n  left:24px;\n  top:.8rem;\n  position:absolute;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right bottom;\n  width:26px;\n  height:17px\n}", ""]);
// Exports
module.exports = exports;
