import React, { useRef, useEffect, useMemo, useContext } from 'react';
import { Modal, Tooltip } from 'antd';
import CardIcon from '../../cnps/SvgIcon';
import dayjs from 'dayjs';
import { LangTranslate } from '../../utils';
import {
  CurrentYearActivityInfoContext,
  AllProfileInfoContext,
} from '../CustomTimeLineCpn/context';
import './index.less';

function CustomModalTagHistoryList(props) {
  const {
    data = {},
    tagPoolInfo = {},
    isModalOpen = false,
    successorTag = false,
    isSelectPeopleItem = false,
    setIsModalOpen = () => {},
    modalTitle = '',
  } = props;

  const modalBodyRef = useRef();
  const activityInfos = useContext(CurrentYearActivityInfoContext);
  const allProfileInfo = useContext(AllProfileInfoContext);
  // AllProfileInfoContext;
  const handleOk = () => {
    setIsModalOpen(() => false);
  };
  const handleCancel = () => {
    setIsModalOpen(() => false);
  };

  const dealHistoryInfo = useMemo(() => {
    if (successorTag) {
      const [successor, readiness] = data?.tagInfos;
      const empTagHistoryInfos = successor?.tagHistoryInfos;
      const readinessTagHistoryInfos = readiness?.tagHistoryInfos;

      const dealAllByActivityId = empTagHistoryInfos?.map(it => ({
        successor: it,
        readiness: readinessTagHistoryInfos?.find(vt => vt?.activityId === it?.activityId),
      }));
      return dealAllByActivityId?.map(ot => {
        const { successor: empInfo, readiness: readinessLabel } = ot;
        const curActivityInfoObj = allProfileInfo?.activityMap?.[empInfo?.activityId];

        const selectionEmployees = empInfo?.selectionEmployees || [];
        const empList = empInfo?.tagLabelValue.split(',');
        const empInfos = empList?.map?.(empId =>
          selectionEmployees?.find(it => it?.employeeId === empId),
        );
        const readinessList = readinessLabel?.tagLabelValue?.split(',') || [];
        const dealEmpReadiness = empInfos
          ?.map((xt, xdx) =>
            !xt?.employeeName && !readinessList?.[xdx]
              ? '-'
              : `${xt?.employeeName || '-'}(${xt?.employeeId || '-'})～${readinessList?.[xdx] || '-'}`,
          )
          .join(';');
        return {
          label: `${curActivityInfoObj?.activityName || '-'} (${dayjs(curActivityInfoObj?.activityStartTime).format('MM-DD')} ~ ${dayjs(
            curActivityInfoObj?.activityEndTime,
          ).format('MM-DD')})`,
          value: dealEmpReadiness,
          isCurrentActivity: empInfo?.activityId === data?.tagInfos?.[0]?.activityId,
        };
      });
    }
    if (!data?.tagHistoryInfos?.length) {
      return [];
    }
    if (isSelectPeopleItem) {
      return data?.tagHistoryInfos?.map(item => {
        const curActivityInfoObj = allProfileInfo?.activityMap?.[item?.activityId];

        const { selectionEmployees, tagValue } = item;
        const empIds = tagValue?.split?.(',') || [];
        const newVal = empIds
          ?.map(empId => {
            const curEmp = selectionEmployees?.find(it => it?.employeeId === empId);
            return !curEmp?.employeeName && !curEmp?.employeeId
              ? '-'
              : `${curEmp?.employeeName || '-'}(${curEmp?.employeeId || '-'})`;
          })
          ?.join?.(',');
        return {
          label: `${curActivityInfoObj?.activityName || '-'} (${dayjs(curActivityInfoObj?.activityStartTime).format('MM-DD')} ~ ${dayjs(
            curActivityInfoObj?.activityEndTime,
          ).format('MM-DD')})`,
          value: newVal,
          isCurrentActivity: item?.activityId === data?.activityId,
        };
      });
    }
    return data?.tagHistoryInfos?.map?.(it => {
      const curActivityInfoObj = allProfileInfo?.activityMap?.[it?.activityId];

      return {
        label: `${curActivityInfoObj?.activityName || '-'} (${dayjs(curActivityInfoObj?.activityStartTime).format('MM-DD')} ~ ${dayjs(
          curActivityInfoObj?.activityEndTime,
        ).format('MM-DD')})`,
        value: it?.tagLabelValue,
        isCurrentActivity: it?.activityId === data?.activityId,
      };
    });
  }, [data, activityInfos, allProfileInfo]);

  return (
    <Modal
      destroyOnClose
      width={400}
      title={
        <div className="talent-mark-custom-modal-tag-history-title-wrap">
          <div className="title">
            <Tooltip
              destroyTooltipOnHide
              des
              placement="topLeft"
              title={successorTag ? tagPoolInfo?.tagPoolName || '-' : data?.tagName}
            >
              {successorTag ? tagPoolInfo?.tagPoolName || '-' : data?.tagName}
            </Tooltip>
          </div>
          <div className="close-icon" onClick={handleCancel}>
            <CardIcon type="icon-quxiao" size={16} pointer />
          </div>
        </div>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="talent-mark-custom-modal-tag-history-wrap"
      getContainer={e => e?.parentNode || document.body}
      closeIcon={null}
    >
      <div
        style={{ width: '100%' }}
        ref={modalBodyRef}
        className="talent-mark-custom-modal-tag-history-body-wrap"
      >
        {dealHistoryInfo?.map((it, idx) => (
          <div
            data-last-one={idx === dealHistoryInfo?.length - 1}
            className="talent-mark-custom-modal-tag-history-body-list-item"
          >
            <div>
              <div>
                <div />
              </div>
              <div />
            </div>
            <div>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Tooltip destroyTooltipOnHide placement="topLeft" title={it?.label || '-'}>
                  {it?.label || '-'}
                </Tooltip>
                {it?.isCurrentActivity && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <CardIcon type="icon-shuomingicon" size={12} />
                    </div>
                    <div
                      style={{
                        marginLeft: '2px',
                        fonSize: '10px',
                        fontWeight: 'normal',
                        lineHeight: '16px',
                      }}
                    >
                      {LangTranslate('当前查看')}
                    </div>
                  </div>
                )}
              </div>
              <div data-is-current-activity={it?.isCurrentActivity}>
                <Tooltip destroyTooltipOnHide placement="topLeft" title={it?.value || '-'}>
                  {it?.value || '-'}
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}

const CustomModalTagHistoryListWrap = props => {
  if (props?.isModalOpen) {
    return <CustomModalTagHistoryList {...props} />;
  }
  return null;
};

export default CustomModalTagHistoryListWrap;
