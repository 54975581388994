import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

  return useRegisterStyle('work-relationship-collapse-container', () => [
    {
      ['.work-relationship-card-emp-title-tooltip']: {
        maxWidth: '500px!important',
      },

      // base
      [`.${prefixCls}`]: {
        display: 'flex',

        ['.work-relation-collapse-container-right-content']: {
          flex: '1',

          ['&>div:nth-of-type(1) .c-item:before']: {
            height: '50%',
            top: '50px',
          },

          ['&>div:last-of-type .c-item:before']: {
            height: '50px',
            top: '0px',
          },

          // 是第一个且是最后一个 竖线隐藏
          ['&>div:last-of-type:nth-of-type(1) .c-item:before']: {
            display: 'none',
          }
        },

        ['.work-relation-collapse-container-left-selft']: {
          paddingRight: '20px',
          position: 'relative',

          // 横线
          ['&::before']: {
            content: '""',
            width: '20px',
            height: '1px',
            background: '#A1ABC9',
            position: 'absolute',
            top: '50px',
            right: '0px',
          },

          ['.self-info-container']: {
            width: '60px',
            height: '88px',
            border: '1px solid rgba(0, 0, 0, 0.06)',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },

          ['.ec-base-avatar-icon']: {
            width: '30px !important',
            height: '30px !important',
          },

          ['.ant5-avatar-wrap .ant5-avatar-icon']: {
            marginRight: '0 !important',
          },

          ['span']: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '18px',
            color: 'rgba(0, 0, 0, 0.84)',
            marginTop: '8px',
            whiteWpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '40px',
            textAlign: 'center',
          }
        }
      }
    }
  ]);
};

export default useStyle;
