import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';

const Change = ({ data, showImg, width }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const myChartRef = useRef(null);

  // 初始化图表
  const initializeChart = () => {
    const chartDom = chartRef.current;
    if (chartDom) {
      myChartRef.current = echarts.init(chartDom, null, { resize: true });

      // 处理数据
      const seriesData = data?.map(item => ({
        name: item?.terminationTypeDesc,
        value: item?.terminationCount,
        groupId: item?.terminationType
      }));
  
      // 被动离职和主动离职对应的颜色
      const colorMap = {
        '1': 'rgb(247, 134, 134)',
        '0': 'rgb(255, 236, 120)'
      }

      // 设置起始角度，防止有的数字展示不全
      const firstRatio = Number(data?.[0]?.ratio) * 100;
      const epsilon = 0.0001;
      let startAngleNum;
      if (firstRatio === null || isNaN(firstRatio)) {
        startAngleNum = 180;
      } else {
        const adjustedFirstRatio = firstRatio + epsilon;
        switch (true) {
          case adjustedFirstRatio < 30:
            startAngleNum = 240;
            break;
          case adjustedFirstRatio < 40:
            startAngleNum = 220;
            break;
          case adjustedFirstRatio < 65:
            startAngleNum = 180;
            break;
          case adjustedFirstRatio < 80:
            startAngleNum = 140;
            break;
          default:
            startAngleNum = 120;
        }
      }
              
      const option = getChartOptions(seriesData, colorMap, startAngleNum);
      myChartRef.current.setOption(option);
    }
  };

  // 获取图表配置
  const getChartOptions = (seriesData, colorMap, startAngleNum) => ({
    legend: {
      show: false,
    },
    series: [{
      type: "pie",
      radius: ['55%', '75%'],
      minAngle: 30,
      startAngle: startAngleNum,
      clockwise: false, // 逆时针转
      itemStyle: {
        borderRadius: 6,
        borderColor: '#fff',
        borderWidth: 2,
        color: params => colorMap[params?.data?.groupId]
      },
      label: {
        position: 'inner',
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: [6, 4],
        shadowColor: 'rgba(0, 0, 0, 0.10)',
        shadowBlur: 8,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        zlevel: 10000,
        formatter: params => {
          return params?.data?.groupId === '0'
            ? `{dotyellow| ●} {text|${params.name}}: {value|${params.value}}`
            : `{dot| ●} {text|${params.name}}: {value|${params.value}}`;
        },
        rich: {
          dotyellow: {
            fontSize: 10,
            color: 'rgb(255, 236, 120)'
          },
          dot: {
            fontSize: 10,
            color: 'rgb(247, 134, 134)'
          },
          text: {
            color: 'rgba(0, 0, 0, 0.72)',
            fontSize: 12
          },
          value: {
            color: 'rgba(0, 0, 0, 0.84)',
            fontSize: 16,
            fontWeight: 900,
          }
        }
      },
      labelLine: {
        show: false,
        showAbove: true
      },
      emphasis: {
        disabled: true
      },
      data: seriesData
    }]
  });  

  useEffect(() => {
    initializeChart();
    return () => {
      chartInstance?.current?.dispose();
    };
  }, [data]);

  useEffect(() => {  
    myChartRef.current?.resize();
  }, [width]);

  return (
    showImg === false
      ? <div ref={chartRef} className='echarts-change' />
      : <div className='echarts-change-img' style={{ backgroundImage: "url('//img-hxy021.didistatic.com/static/ehr_static/imgs/canghai/cards/zh_CN/3.png')" }} />
  );
};

export default Change;
