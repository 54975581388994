const recordPageTiming = (time, requestTime, requestUrl) => {

  const records = {
    url_path: location.pathname,
    page_time: time,
    request_time: requestTime,
    url_search: location.search,
    request_url: requestUrl,
    system_name: 'dhr',
    is_https: location.protocol === 'https:',
    is_frist_load: !window.isPageLoaded,
  }

  const { isIntra } = $CONFIG.status || {};
  if (typeof isIntra !== 'undefined') {
    records.is_intra = isIntra;
  }

  const perfLimitTime = $CONFIG?.customProps?.perfLimitTime || 30000;
  if (time < perfLimitTime) {
    Omega?.trackEvent && Omega.trackEvent('ep_dhr_perf_bt', '', records);
  }

  if (localStorage.perf) {
    const perfDebugId = 'perfDebug';
    let perfDebugElem = document.getElementById(perfDebugId);
    if (!perfDebugElem) {
      perfDebugElem = document.createElement('span');
      perfDebugElem.style.cssText = 'position:sticky;bottom:0;pointer-events:none;font-size:12px;padding:8px;opacity:0.5';
      perfDebugElem.id = perfDebugId;
      document.body.appendChild(perfDebugElem);
    }
    perfDebugElem.innerHTML = `
      <span style="color:${time > 1500 ? 'red' : 'green'}">${time}</span>&nbsp;&nbsp;<span style="color:${requestTime > 800 ? 'red' : 'green'}">${requestTime}</span>
    `
  }
}

export default recordPageTiming;