import React, { useEffect, useState } from "react";
import newRequest from '../../utils/newRequest';
import PeopleSelect from './PeopleSelect';

const PeopleSelectWithAuth = (props)=>{
  const request = newRequest.getInstance();
  const {authUrl,authId,authParams,disabled,...others} = props;

  const [loading,setLoading] = useState(true)
  const [auth,setAuth] = useState({})

  useEffect(()=>{
    const getData= async ()=>{
      if (!authId) return;
      const res = await request({
        method: 'get',
        url: authUrl,
        params:authParams
      });
      setLoading(false)
      setAuth({deptCodeList: res.data?.deptIds})
    }
    getData()
  },[])


  return <PeopleSelect
    {...others}
    {...auth}
    loading={loading}
    hasAuth
    disabled={!loading?disabled:loading}
  />
}

export default PeopleSelectWithAuth
