import { createStore } from 'redux';
import request from './utils/request';

const initialState = {
  // 菜单
  // menuData: [],
  menuUrl: '/global-cybertron/menu/list',
  menuData: [],
  menuCurrentId: 0,
  request, // 每个项目自己的request
  appbarCustomize: {
    configView: null, // 框架设置信息
    pageletName: '', // 自定义的服务名称
    useWelcome: true, // 是否使用新手引导服务
    welcomeData: [], // 优先级小于welcomeUrl
    welcomeUrl: '/gateway/dhr-workbench/card/detail?cardCode=systemLead',
    parcel: '@dhr/cards:AppbarCustomize' //自定义顶部组件
  },
  // 没有侧边菜单的页面
  hideSideBarPath: ['/no-permission', '/message'],
  currentPage: '1',
  // logo
  logoInfo: {
      title: '', // 系统名称
      logoImgUrl: 'http://img-hxy021.didistatic.com/static/ehr_static/dihr/static/dhr/imgs/logo.png',
      logoStyle: {
        width: '161px'
      }
  },

  // 个人信息
  userInfo: {
    // data: null,
    // url: '/global-cybertron/employee', // 请求个人信息的url
    // logoutUrl: '/global-cybertron/sso/logout', // 退出url
    // isLogoutUrl: false,
  },

  // 卡片布局信息
  cardLayout: {
    useCardLayout: true,
    cardPagetName: '@canghai/home'
  },
  // 子服务store信息
  workspaceMessage: {
    activeSearchData: {
      activityStatus: null, // 活动状态
      yearAnnual: null, // 活动年份
      activityType: null, // 活动调研
      displayStatus: null, // 活动列表展示状态
    },
    // 活动详情
    activityData: null,
    // 场景详情
    sceneData: null,
    // bp引导
    guideData: null,
  }
};

function reducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_MENU_DATA': {
      return {
        ...state,
        menuData: action.payload,
      }
    }
    case 'SET_MEUN_CUTTENT_ID': {
      return {
        ...state,
        menuCurrentId: action.payload,
      }
    }
    case 'SET_APPBAR_CUSTOMIZE': {
      return {
        ...state,
        appbarCustomize: action.payload,
      }
    }
    case 'SET_USER_INFO': {
      return {
        ...state,
        userInfo: action.payload,
      }
    }
    case 'SET_AUTH_DATA': {
      return {
        ...state,
        authData: action.payload,
      }
    }
    case 'SET_CURRENT_PAGE': {
      return {
        ...state,
        currentPage: action.payload,
      }
    }
    case 'CHANGE_SEARCH_DATA': {
      return {
        ...state,
        workspaceMessage: {
          ...state.workspaceMessage,
          activeSearchData: action.payload
        }
      }
    }
    case 'CHANGE_SCENE_DATA': {
      return {
        ...state,
        workspaceMessage: {
          ...state.workspaceMessage,
          sceneData: action.payload
        }
      }
    }
    case 'CHANGE_ACTIVITY_DATA': {
      return {
        ...state,
        workspaceMessage: {
          ...state.workspaceMessage,
          activityData: action.payload
        }
      }
    }
    case 'CHANGE_ACTIVITY_GUIDE': {
      return {
        ...state,
        workspaceMessage: {
          ...state.workspaceMessage,
          guideData: action.payload
        }
      }
    }
    case 'CLEAR_SCENE_ACTIVITY_DATA': {
      return {
        ...state,
        workspaceMessage: {
          ...state.workspaceMessage,
          activityData: null,
          sceneData: null,
          guideData: null,
        }
      }
    }
    default:
      return state;
  }
}

export const storeInstance = createStore(reducer);