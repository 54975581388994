import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
  return useRegisterStyle('ddo-header', () => [
    {
      // 标题部分
      [`.${prefixCls}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '12px',
        ['&.header-box']: {
          boxShadow: '0px 6px 10px -8px rgba(0, 0, 0, 0.08)',
        },
        ['.head_top']: {
          width: 'calc(100% - 68px)',
          display: 'flex',
          alignItems: 'center',

          ['.title']: {
            height: '24px',
            zIndex: '1',
            fontFamily: 'PingFang SC',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '0em',
            color: 'rgba(0, 0, 0, 0.84)',
            ['&:before']: {
              content: '""',
              display: 'inline-block',
              width: '3px',
              height: '12px',
              color: '#FF6400',
              backgroundColor: '#FF6400',
              marginRight: '5px',
              marginBottom: '2px',
              verticalAlign: 'middle',
              borderRadius: '3px',
            },
          },

          ['.head_post']: {
            width: 'calc(100% - 82px)',
            display: 'flex',
          },

          ['.line']: {
            width: '1px',
            height: '14px',
            borderRadius: '3px',
            opacity: '1',
            background: 'rgba(0, 0, 0, 0.16)',
            display: 'inline-block',
            margin: '6px 2px 0 6px',
          },
        },

        ['.target_find']: {
          /* 自动布局 */
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '4px 0px 4px 4px',
          zIndex: '1',
          opacity: '1',
          cursor: 'pointer',
          borderRadius: '6px',
          ['&:hover']: {
            background: 'rgba(0, 0, 0, 0.04)',
          },

          ['.target_btn']: {
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '18px',
            color: 'rgba(0, 0, 0, 0.72)',
          },

          ['.rightIcon']: {
            width: '14px',
            height: '14px',
            flexShrink: '0',
          },
        },
      },
    },
  ]);
};

export default useStyle;
