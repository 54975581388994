import { useRegisterStyle } from '@didi/ec-base';

const useStyle = prefixCls => {
	return useRegisterStyle('pagelet-Bottom', () => [
		{
			// 标题部分
			[`.${prefixCls}`]: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingLeft: '16px',
				paddingRight: '16px',
				paddingBottom: '12px',

				['&>a']: {
					padding: ' 4px',
					display: 'inline-flex',
					alignItems: 'center',
					['&:hover']: {
						background: 'rgba(0, 0, 0, 0.04)',
						borderRadius: '6px'
					}
				},
				// 卡片文字
				['.popoverBtn']: {
					padding: '4px',
					display: 'inline-block',
					marginLeft: '8px',
					color: 'rgba(0, 0, 0, 0.72)',
					cursor: 'pointer',
					['&:hover']: {
						background: 'rgba(0, 0, 0, 0.04)',
						borderRadius: '6px',
						color: '#ff7e41'
					}
				},

			},
			['.viewRhythm']: {
				width: '600px',

				['.ant5-popover-title']: {
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',

					// 卡片标题
					['div']: {
						fontFamily: 'PingFang SC',
						fontSize: '20px',
						fontWeight: 600,
						lineHeight: '28px',
						color: 'rgba(0, 0, 0, 0.84)'
					},
					// 关闭
					['span']: {
						fontSize: '20px',
					},
				},

				// 标题
				['img']: {
					width: '100%'
				}
			}
		},
	]);
};

export default useStyle;
