import makeRequest from './makeRequest';

const generatorHTTP = (defaultConfig, anotherConfig = {}, headers) => {
  const request = makeRequest(defaultConfig, headers);

  const HTTP = (cfg1, cfg2 = {}) => (typeof cfg1 === 'string'
    ? request(cfg1, {...cfg2, ...anotherConfig})
    : request({...cfg1, ...anotherConfig}));

  HTTP.get = (url, config = {}) => {
    return request.get(url, {
      ...(config || {}),
      ...anotherConfig
    })
  };

  HTTP.post = (url, params, config = {}) => request.post(url, params || {}, {
    ...config,
    ...anotherConfig
  });

  HTTP.put = (url, params, config = {}) => request.put(url, params || {}, {
    ...config,
    ...anotherConfig
  });

  HTTP.delete = (url, config = {}) => request.delete(url, {
    ...config,
    ...anotherConfig
  });

  HTTP.head = (url, config = {}) => request.head(url, {
    ...config,
    ...anotherConfig
  });

  HTTP.options = (url, config = {}) => request.head(url, {
    ...config,
    ...anotherConfig
  });

  HTTP.patch = (url, params, config = {}) => request.patch(url, params || {}, {
    ...config,
    ...anotherConfig
  });
  return HTTP;
}

export default generatorHTTP;
