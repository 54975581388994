import React, { useState, useEffect } from 'react';
import { di18n } from '@ultra/common';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal, Button, Select, Switch, message, Popconfirm, Spin,
} from 'antd';
import { cloneDeep } from 'lodash';
import {
  changeRefuseTypeCode,
} from '../../store/navigationStore/portraits'; // 引入actions
import request from '../../utils/request';
import { getRefuseList } from '../../utils/Tool';
import './index.scss';

const { intl } = di18n;
const { Option } = Select;
const RefuseComment = props => {
  const {
    source, participantCode, reasonType, activityId,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ unEditData, setUnEditData] = useState(0) // 统计不可编辑列数目
  const [modalData, setModalData] = useState({
    loading: false,
    visible: false,
  });
  const [refuseList, setRefuseList] = useState([]);
  const [reasonList, setReasonList] = useState([]);

  const statusEnum = {
    0: intl.t('待评价'),
    1: intl.t('已拒评'),
    2: intl.t('已评价'),
  };
  const {
    refuseTypeCode,
    refuseTypeName,
    refuseQuestionnaireId,
  } = useSelector(state => state.portraits);

  const handleOk = async () => {
    const respondentCodeStr = [];
    let rejects = cloneDeep(refuseList).filter(item => {
      if (item.recycleStatus === 0 && item.status === 1) {
        respondentCodeStr.push(item?.respondentCode);
        return item;
      }
    });
    rejects = rejects.map(item => {
      ['recycleStatus', 'recycleStatusName', 'rejectionDis', 'status', 'statusDis', 'respondentName', 'rejectionReasonRemark'].map((it) => {
        delete item[it];
      });
      return item;
    });
    if (!rejects?.length) {
      changeModalData({ loading: false, visible: false });
      dispatch(changeRefuseTypeCode(''));
      return
    }
    let recycleParticipantCode = await getKey({
      encodeText: activityId, busType: 1, respondentCodeStr: respondentCodeStr.join(','), respondentTypeCode: refuseTypeCode
    });
    if (recycleParticipantCode?.code !== '100200') return;
    let data = {
      activityId,
      questionnaireId: refuseQuestionnaireId,
      recycleParticipantCode: recycleParticipantCode?.data,
      rejects,
      respondentTypeCode: refuseTypeCode
    }
    changeModalData({ loading: true });
    let res = await batchAddRejects(data)
    if (res.code === '100200') {
      message.success(intl.t('拒评成功'));
      window.location.reload();
    } else {
      changeModalData({ loading: false });
    }
  };
  const handleCancel = () => {
    changeModalData({ visible: false });
    dispatch(changeRefuseTypeCode(''));
  };

  const changeModalData = (v) => {
    setModalData({ ...modalData, ...v });
  };

  // 原接口：/api/refusal/batchAddRejects
  const batchAddRejects = (data) => request('/ctAnswer/api/answer/refusal/batchAddRejects', {
    method: 'POST',
    data
  });

  // 获取匿名key, busType随便传的，后端王斌说只要不是一就行
  // 原接口：/api/anymous/encode
  const getKey = (data) =>
    request('/ctAnonymity/api/answer/anymous/encode', {
      method: 'POST',
      data
    });

  // 原接口：/api/activity/participant/getRejectReason
  const getReason = (reasonType) => request('/ctActivity/api/answer/activity/participant/getRejectReason', {
    method: 'GET',
    params: {
      reasonType
    },
  });

  const handleFn = (res) => {
    const data = cloneDeep(res)
    let unEditDataNum = unEditData
    let _data = data.map(item => {
      item.status = item.recycleStatus === 1 ? 1 : 0; // 缓存拒绝状态操作
      item.recycleStatusName = statusEnum[item.recycleStatus]
      item.statusDis = false; // 拒绝状态disabled操作
      item.rejectionReasonCode = item.rejectionReasonCode || undefined; // 拒绝原因
      item.rejectionDis = true; // 拒绝disabled原因
      if (item.recycleStatus !== 0) {
        item.statusDis = true;
        item.rejectionDis = true;
        unEditDataNum += 1
      }
      return item
    })
    setUnEditData(unEditDataNum)
    setRefuseList(_data);
  };

  const handleRejection = (e, index) => {
    const data = cloneDeep(refuseList);
    data[index].rejectionReasonCode = e;
    data[index].rejectionReasonDescr = reasonList.filter(item => item.code === e)[0].label;
    setRefuseList(data)
  };

  const handleStatus = (e, index) => {
    const data = cloneDeep(refuseList);
    data[index].status = e ? 1 : 0;
    data[index].rejectionReasonCode = e ? reasonList[0]?.code : undefined;
    data[index].rejectionReasonDescr = e ? reasonList[0]?.label : '';
    data[index].rejectionDis = !e;
    setRefuseList(data)
  };

  useEffect(async () => {
    changeModalData({ visible: true });
    if (refuseTypeCode) {
      // 获取原因list
      let reason = await getReason(reasonType);
      if (reason.code === '100200') setReasonList(reason.data);
      // 获取数据list
      setLoading(true);
      let res = await getRefuseList({
        activityId,
        participantCode,
        participantTypeCode: refuseTypeCode,
      });
      if (res.code === '100200' && res.data) handleFn(res.data);
      setLoading(false);
    };
  }, []);

  return (
    <>
      {/* h5不做蒙层 按跳转处理, 需在父组件跳转 */}
      {
        source === 'pc' && (
          <div>
            <Modal
              width={800}
              height={500}
              visible={modalData.visible}
              title={intl.t('拒评管理')}
              style={{ borderRadius: '8px', overflow: 'hidden' }}
              className="ct-refuse-comment-modal"
              onCancel={() => { handleCancel() }}
              footer={[
                <span
                  style={{
                    float: 'left',
                    fontSize: '14px',
                    color: 'rgba(0,0,0,0.60)',
                    marginLeft: '6px'
                  }}
                  key="total"
                >
                  {intl.t('共{num}人', { num: refuseList?.length })}
                </span>,
                <Button
                  style={{
                    margin: '0 8px 0 auto'
                  }}
                  key="back"
                  onClick={() => handleCancel()}
                >
                  {intl.t('取消')}
                </Button>,
                <Popconfirm
                  getPopupContainer={trigger => trigger.parentNode}
                  placement="topRight"
                  title={intl.t('拒评后不可恢复，确认提交？')}
                  onConfirm={() => handleOk()}
                  okText={intl.t('确定')}
                  disabled={unEditData === refuseList?.length}
                  cancelText={intl.t('取消')}
                >
                  <Button key="submit" type="primary" loading={modalData.loading} disabled={unEditData === refuseList?.length}>
                    {intl.t('确定')}
                  </Button>
                </Popconfirm>

              ]}
            >
              <div className="ct-refuse-comment-pc-header">
                <div>{intl.t(refuseTypeName)}</div>
                <div>{intl.t('状态')}</div>
                <div>{intl.t('是否拒评')}</div>
                <div>{intl.t('拒绝原因')}</div>
              </div>
              <Spin spinning={loading} className="ct-refuse-comment-pc-content-spin">
                <div className="ct-refuse-comment-pc-content">
                  {
                    refuseList.map((item, index) => {
                      return (
                        <ul className="ct-refuse-comment-pc-list" key={item.respondentCode}>
                          <li>{item.respondentName || '-'}</li>
                          <li><span className={`refuse-spot${item.recycleStatus}`}></span>{item.recycleStatusName || '-'}</li>
                          <li><Switch size="small" disabled={item.statusDis} checked={item.status === 1} onChange={(e) => handleStatus(e, index)} /></li>
                          <li>
                            <Select
                              size="small"
                              style={{ width: 120 }}
                              disabled={item.rejectionDis}
                              placeholder={intl.t('请选择')}
                              value={item.rejectionReasonCode}
                              onChange={(e) => handleRejection(e, index)}
                              getPopupContainer={trigger => trigger.parentNode}
                            >

                              {
                                reasonList.map((reasonInfo, reasonIndex) => <Option key={reasonIndex} value={reasonInfo.code}>{intl.t(reasonInfo.label)}</Option>)
                              }
                            </Select>
                          </li>
                        </ul>
                      )
                    })
                  }
                </div>
              </Spin>
            </Modal>
          </div>
        )
      }

    </>
  );
};

export default RefuseComment;
