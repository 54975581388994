import React from "react";
import "./index.scss";

const SlidingPanel = ({ isOpen, onClose, children }) => {
  return (
    <>
      <div className={`sliding-panel ${isOpen ? "slide-up" : "slide-down"}`}>
        <div className="panel-content">
          {children}
        </div>
      </div>
      {isOpen && <div className="overlay" onClick={onClose}></div>}{" "}
      {/* 确保蒙层在面板下面 */}
    </>
  );
};

export default SlidingPanel;
