import AuthParams from "../static/MenuFunctionIds";
import MenuMapping from "../static/MenuName";

const AllMenus = [
  // {
  //   reg: /^\/process\/approval\/todo\/holiday$/,
  //   tag: 'home_team_transfer_manage'
  // },
  // {
  //   reg: /^\/process\/approval\/todo$/,
  //   tag: 'home_team_transfer_manage_approve'
  // },
  // {
  //   reg: /^\/process\/approval\/[a-zA-Z]+\/mentorChange$/,
  //   tag: 'home_team_transfer_manage_mentor'
  // },
  // {
  //   reg: /\/hr-workspace\/on-boarding\/[a-zA-Z]+\/apply$/,
  //   tag: 'hr_workspace_on_boarding_apply'
  // },
  {
    reg: /\/hr-workspace\/on-boarding\/entry$/,
    tag: 'hr_workspace_on_boarding_entry'
  },
  {
    reg: /\/hr-workspace\/on-boarding\/tripartite$/,
    tag: 'hr-workspace_on-boarding_tripartite'
  },
  {
    reg: /\/hr-workspace\/on-boarding\/procedure$/,
    tag: 'hr-workspace_on-boarding_procedure'
  },
  {
    reg: /\/hr-workspace\/on-boarding\/special-procedure$/,
    tag: 'hr-workspace_on-boarding_special-procedure'
  },
  {
    reg: /\/hr-workspace\/on-boarding\/special-procedure\/apply$/,
    tag: 'special_procedure_apply'
  },
  {
    reg: /\/hr-workspace\/renew\/sign-contract$/,
    tag: 'renew_sign_contract'
  },
  {
    reg: /\/hr-workspace\/renew\/sign-contract\/renewal$/,
    tag: 'renew_sign_contract_detail'
  },
  {
    reg: /\/hr-workspace\/off-boarding\/handover-bench/,
    tag: 'off_boarding_handover_bench'
  },
  {
    reg: /\/hr-workspace\/off-boarding\/sscwork-bench/,
    tag: 'off_boarding_sscwork_bench'
  },
  {
    reg: /\/hr-workspace\/off-boarding\/send-protocol$/,
    tag: 'off_boarding_send_protocol'
  },
  {
    reg: /\/hr-workspace\/off-boarding\/special-bench\/apply-special$/,
    tag: 'off_boarding_special_bench'
  },





  {
    reg: /\/process\/approval\/todo/,
    tag: 'process_approval_todo'
  },
  {
    reg: /\/process\/approval\/done/,
    tag: 'process_approval_done'
  },
  // 员工信息
  // hr-workspace/ei/applylist/applydetail
  {
    reg: /\/hr-workspace\/ei\/applylist\/applydetail$/,
    tag: 'ei_applylist_applydetail'
  },
  // //ei/applyhistory
]

// 有这么一个需求，根据当前页面的url来匹配AllMenus中的tag
// 如果匹配到就返回tag，如果没有匹配到就返回null
export const filterAuthMapping = () => {
  const url = location.pathname;
  for (let i = 0; i < AllMenus.length; i++) {
    if (AllMenus[i].reg.test(url)) {
      const tag = AllMenus[i].tag
      return {
        functionName: MenuMapping[tag],
        functionId: AuthParams[tag]
      }
    }
  }
  return null
}

export default filterAuthMapping;
