const locales = {
  en: {
    test: 'test'
  },
  zh: {
    test: '测试'
  }
}

export default locales;
