import React, { useState, useEffect } from 'react';
import KeyComponents from './KeyComponents';
import '../../style.scoped.less';


const IMG_SIZE = new Map([
  ['small', {
    width: 484,
    height: 628
  }], //1400
  ['middle', {
    width: 510,
    height: 690
  }], // 1680
  ['large', {
    width: 580,
    height: 753
  }] // 1920
])

const IMG_POSITION = new Map([
  // left top
  ['small', [[140, 46], [332, 116], [144, 232], [340, 350], [110, 412], [322, 492]]], //1400

  ['middle', [[156, 56], [354, 134], [164, 260], [370, 388], [124, 460], [358, 548]]], // 1680
  // ['middle', [[156, 56], [394, 134], [164, 260], [390, 388], [124, 460], [378, 548]]], // 1680
  ['large', [[176, 66], [432, 150], [174, 290], [432, 430], [134, 508], [414, 604]]] // 1920
])

const BASE_HEIGHT = new Map([
  ['small', [420, 420, 420, 440, 560, 628]],
  ['middle', [490, 490, 490, 490, 600, 690]],
  ['large', [520, 520, 520, 520, 650, 753]]
])


const FirstPic = (props) => {
  const {data = [], viewSize = 'small', prefixCls, baseHeight, onClick = () => {}} = props

  const [curData, setCurData] = useState([])
  const [dataLength, setDataLength] = useState(6)
  const [cutHeights, setCutHeights] = useState([])

  useEffect(() => {
    const positions = IMG_POSITION.get(viewSize);
    const newData = [];
    const dataCopy = JSON.parse(JSON.stringify(data));
    let len = dataCopy.length || 0
    setDataLength(len);
    // {/* 避免没有填满整个图片，所以要倒着填充，空白上半部分的 */}
    // todo 如果没有塞满，那么需要填充空白，置灰节点注意一下
    //节点数量小于等于2个，且节点中没有“转正”时：前端补充“转正”、“周年”的灰色假节点；
    // 节点数量小于等于2个，且节点中有“转正”时：前端补充“周年”灰色假节点；
    if (len < 3) {
      if (dataCopy.some(item => item?.situationCode === 'trail')) {
        dataCopy.unshift({
          content: '周年',
          iconUrl: 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/gray-week-1.png',
        })
      } else {
        dataCopy.unshift( {
          content: '周年',
          iconUrl: 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/gray-week-1.png',
        }, {
          iconUrl: 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/gray-probation-1.png',
          content: '转正',
        })
      }
    }
    while(dataCopy.length < 6) {
      dataCopy.unshift(undefined) // 填充至6
      len++;
    }
    dataCopy.forEach((item, index) => {
      if (item === undefined) {
        newData.push(undefined)
        return
      }
      const position = positions[index]
      newData.push({
        ...item,
        top: position[1],
        left: position[0]
      })
    });
    setCurData(newData)
    setCutHeights(BASE_HEIGHT.get(viewSize))
  }, [viewSize, data])

  return <>
    {

      data.length < 6 ? <div className={`${prefixCls}-content-wrapper`} style={{height: `${cutHeights[data.length - 1]}px`}}>
        <div className={`${prefixCls}-content`} style={{
          height: `${baseHeight}px`,
          width: `${IMG_SIZE.get(viewSize).width || IMG_SIZE.get('small').width}px`,
          backgroundImage: `url('https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi-bg001.png?${new Date().getTime()}')`
        }}>
          {
            curData.map((item, index) => item && <KeyComponents item={item} index={index} viewSize={viewSize} prefixCls={prefixCls} onClick={() => {onClick(item)}} />)
          }
          <div className={`${prefixCls}-cloud`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div> : <div className={`${prefixCls}-content`} style={{
        height: `${baseHeight}px`,
        width: `${IMG_SIZE.get(viewSize).width || IMG_SIZE.get('small').width}px`,
        backgroundImage: `url('https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/didi-bg001.png?${new Date().getTime()}')`
      }}>
        {
          curData.map((item, index) => item && <KeyComponents item={item} index={index} viewSize={viewSize} prefixCls={prefixCls} onClick={() => {onClick(item)}} />)
        }
        <div className={`${prefixCls}-cloud`} style={{width: `${IMG_SIZE.get(viewSize).width || IMG_SIZE.get('small').width}px`, height: `${cutHeights[data.length - 1]}px`}}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
      </div>
    }
  </>
}

export default FirstPic;
