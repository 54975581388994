import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Modal, Form, Input } from 'antd';
import CustomSegmented from '@/cpns/CustomSegmented';
import { LangTranslate, ScrollInToViewBySelector } from '@/utils';
import { useRequest } from 'ahooks';
import { getTagGroupLanguageList, updateTagCommonLanguage } from '@/services';
import { BricksInfoContext } from '@/context';
import { CurrentVersionContent } from './context';
import CustomLangObjInput from './CustomLangObjInput';
import './index.less';
import { Spin } from 'antd';
import { message } from 'antd';
import CustomFormItem from './CustomFormItem';

const MultipleLangConfigModal = props => {
  const { visible, setVisible, currentModalParams } = props;
  const [formInstance] = Form.useForm();
  const [currentVersion, setCurrentVersion] = useState();
  const BricksInfoContextVal = useContext(BricksInfoContext);

  const handleCancel = () => {
    setVisible(() => false);
  };
  const {
    data: tagPoolLangList = {},
    loading: fetchTagPoolLangListLoading,
    runAsync: fetchTagPoolLangList,
  } = useRequest(
    async params => {
      try {
        const res = await getTagGroupLanguageList({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data?.reduce((pre, cur) => {
          pre[cur.version] = cur;
          return pre;
        }, {});
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContextVal] },
  );

  const { loading: updateTagLangsLoading, runAsync: runUpdateTagLangs } = useRequest(
    async params => {
      try {
        const res = await updateTagCommonLanguage({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, BricksInfoContextVal },
  );

  const versionList = useMemo(() => {
    const tmpVersionList =
      Object.keys(tagPoolLangList)?.map(key => ({
        key: key,
        label: `V${key}`,
        children: null,
      })) || [];
    return [...tmpVersionList].reverse();
  }, [tagPoolLangList]);

  const isTgaPool = useMemo(
    () => Object.values(tagPoolLangList)?.[0]?.type === 2,
    [tagPoolLangList],
  );

  useEffect(() => {
    setCurrentVersion(() => versionList?.[0]?.key);
  }, [versionList]);

  useEffect(() => {
    const param = Object.keys(tagPoolLangList)?.reduce?.((pre, curVersion) => {
      const curTagPool = tagPoolLangList?.[curVersion];
      pre[curVersion] = {
        nameList: {
          zh_CN: curTagPool?.nameList?.find(it => it?.languageType === 'zh_CN'),
          en_US: curTagPool?.nameList?.find(it => it?.languageType === 'en_US'),
          es_ES: curTagPool?.nameList?.find(it => it?.languageType === 'es_ES'),
          pt_BR: curTagPool?.nameList?.find(it => it?.languageType === 'pt_BR'),
        },
        descList: {
          zh_CN: curTagPool?.descList?.find(it => it?.languageType === 'zh_CN'),
          en_US: curTagPool?.descList?.find(it => it?.languageType === 'en_US'),
          es_ES: curTagPool?.descList?.find(it => it?.languageType === 'es_ES'),
          pt_BR: curTagPool?.descList?.find(it => it?.languageType === 'pt_BR'),
        },
        tagLanguageDtoList: curTagPool?.tagLanguageDtoList?.map(curTag => {
          return {
            nameList: {
              zh_CN: curTag?.nameList?.find(it => it?.languageType === 'zh_CN'),
              en_US: curTag?.nameList?.find(it => it?.languageType === 'en_US'),
              es_ES: curTag?.nameList?.find(it => it?.languageType === 'es_ES'),
              pt_BR: curTag?.nameList?.find(it => it?.languageType === 'pt_BR'),
            },
            descList: {
              zh_CN: curTag?.descList?.find(it => it?.languageType === 'zh_CN'),
              en_US: curTag?.descList?.find(it => it?.languageType === 'en_US'),
              es_ES: curTag?.descList?.find(it => it?.languageType === 'es_ES'),
              pt_BR: curTag?.descList?.find(it => it?.languageType === 'pt_BR'),
            },
            tagOptionLanguageDtoList: curTag?.tagOptionLanguageDtoList?.map(curOption => {
              return {
                nameList: {
                  zh_CN: curOption?.nameList?.find(it => it?.languageType === 'zh_CN'),
                  en_US: curOption?.nameList?.find(it => it?.languageType === 'en_US'),
                  es_ES: curOption?.nameList?.find(it => it?.languageType === 'es_ES'),
                  pt_BR: curOption?.nameList?.find(it => it?.languageType === 'pt_BR'),
                },
                descList: {
                  zh_CN: curOption?.descList?.find(it => it?.languageType === 'zh_CN'),
                  en_US: curOption?.descList?.find(it => it?.languageType === 'en_US'),
                  es_ES: curOption?.descList?.find(it => it?.languageType === 'es_ES'),
                  pt_BR: curOption?.descList?.find(it => it?.languageType === 'pt_BR'),
                },
              };
            }),
          };
        }),
      };
      return pre;
    }, {});
    formInstance.setFieldsValue({ ...param });
  }, [tagPoolLangList]);

  useEffect(() => {
    if (!currentModalParams?.tagPoolId) {
      return;
    }
    fetchTagPoolLangList({ tagGroupId: currentModalParams?.tagPoolId });
  }, [currentModalParams]);

  const handelOk = async () => {
    try {
      const validateRes = await formInstance.validateFields(true);
      console.log('validateRes', validateRes);
      const res = formInstance.getFieldsValue(true);
      console.log('handelOk res', res);

      const tmpLangs = [];
      Object.values(res).map(tagPoolLang => {
        tmpLangs.push(Object.values(tagPoolLang.descList));
        tmpLangs.push(Object.values(tagPoolLang.nameList));
        tagPoolLang?.tagLanguageDtoList?.map?.(curTagLang => {
          tmpLangs.push(Object.values(curTagLang.descList));
          tmpLangs.push(Object.values(curTagLang.nameList));
          curTagLang?.tagOptionLanguageDtoList?.map?.(curOptionLang => {
            tmpLangs.push(Object.values(curOptionLang.descList));
            tmpLangs.push(Object.values(curOptionLang.nameList));
          });
        });
      });
      const newAllLangs = tmpLangs?.flat?.(Infinity) || [];
      const updateRes = await runUpdateTagLangs(newAllLangs);
      if (updateRes?.resultFlag) {
        await message.success(LangTranslate('操作成功'), 0.5);
      } else {
        message.warning(updateRes.msg);
      }
      try {
        currentModalParams?.afterCallBack?.();
      } catch (error) {
        console.log('error', error);
      }
      handleCancel();
    } catch (error) {
      ScrollInToViewBySelector('.label-system-Pagelet-antd-form-item-explain-error');
      console.log('error', error);
    }
  };
  return (
    <Modal
      className="pagelet-label-system-multiple-lang-config-modal"
      title={!!isTgaPool ? LangTranslate('多语言配置-标签组') : LangTranslate('多语言配置-标签')}
      handelOk
      open={visible}
      onOk={handelOk}
      onCancel={handleCancel}
      getContainer={false}
      width={800}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={fetchTagPoolLangListLoading || updateTagLangsLoading}>
        <CurrentVersionContent.Provider value={currentVersion}>
          <CustomSegmented
            activeKey={currentVersion}
            onChange={setCurrentVersion}
            items={versionList}
          />
          <Form
            className="pagelet-label-system-multiple-lang-config-modal-form"
            layout={'vertical'}
            form={formInstance}
            initialValues={{}}
            autoComplete="off"
          >
            {/* 标签组名称&描述 */}
            {!!isTgaPool && (
              <div className="pagelet-label-system-multiple-lang-config-modal-form-tagPoolBaseInfoLangsWrap">
                <div className="pagelet-label-system-multiple-lang-config-modal-form-tagPoolTitleLangs">
                  <div className="tagPoolTitleLangs-title">{LangTranslate('标签组名称')}</div>
                  <div className="tagPoolTitleLangs-langs">
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) => {
                            console.log('validator', [currentVersion, 'nameList', 'zh_CN'], value);
                            return value?.content?.length > 200
                              ? Promise.reject()
                              : Promise.resolve();
                          },
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'nameList', 'zh_CN']}
                      label={`${LangTranslate('中文')}:`}
                    >
                      <CustomLangObjInput placeholder="" readOnly variant="borderless" />
                    </CustomFormItem>
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) => {
                            console.log('validator', [currentVersion, 'nameList', 'en_US'], value);
                            return value?.content?.length > 200
                              ? Promise.reject()
                              : Promise.resolve();
                          },
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'nameList', 'en_US']}
                      label={`${LangTranslate('英文')}:`}
                    >
                      <CustomLangObjInput placeholder="" />
                    </CustomFormItem>
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) =>
                            value?.content?.length > 200 ? Promise.reject() : Promise.resolve(),
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'nameList', 'es_ES']}
                      label={`${LangTranslate('西班牙语')}:`}
                    >
                      <CustomLangObjInput placeholder="" />
                    </CustomFormItem>
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) =>
                            value?.content?.length > 200 ? Promise.reject() : Promise.resolve(),
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'nameList', 'pt_BR']}
                      label={`${LangTranslate('巴西葡萄牙语')}:`}
                    >
                      <CustomLangObjInput placeholder="" />
                    </CustomFormItem>
                  </div>
                </div>
                <div className="pagelet-label-system-multiple-lang-config-modal-form-tagPoolDescLangs">
                  <div className="tagPoolDescLangs-title">{LangTranslate('标签组描述')}</div>
                  <div className="tagPoolDescLangs-langs">
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) =>
                            value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'descList', 'zh_CN']}
                      label={`${LangTranslate('中文')}:`}
                    >
                      <CustomLangObjInput placeholder="" readOnly variant="borderless" />
                    </CustomFormItem>
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) =>
                            value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'descList', 'en_US']}
                      label={`${LangTranslate('英文')}:`}
                    >
                      <CustomLangObjInput placeholder="" />
                    </CustomFormItem>
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) =>
                            value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'descList', 'es_ES']}
                      label={`${LangTranslate('西班牙语')}:`}
                    >
                      <CustomLangObjInput placeholder="" />
                    </CustomFormItem>
                    <CustomFormItem
                      rules={[
                        {
                          validator: (_, value) =>
                            value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                          message: LangTranslate('字数超长'),
                        },
                      ]}
                      name={[currentVersion, 'descList', 'pt_BR']}
                      label={`${LangTranslate('巴西葡萄牙语')}:`}
                    >
                      <CustomLangObjInput placeholder="" />
                    </CustomFormItem>
                  </div>
                </div>
              </div>
            )}
            {/* 标签选项 */}
            <div className="pagelet-label-system-multiple-lang-config-modal-form-tagPoolTagListLangWrap">
              {tagPoolLangList?.[currentVersion]?.tagLanguageDtoList?.map?.((tagItem, idx) => (
                <div className="tagPoolTagListLangWrap-item">
                  <div className="tagPoolTagListLangWrap-item-name-wrap">
                    {!!isTgaPool ? (
                      <div className="tagPoolTagListLangWrap-item-title-order-label">
                        {`${LangTranslate('标签')}${idx + 1}`}
                      </div>
                    ) : (
                      <div className="tagPoolTagListLangWrap-item-title-order-label">
                        {LangTranslate('标签名称')}
                      </div>
                    )}

                    <div className="tagPoolTagListLangWrap-item-title-langs">
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 200 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'nameList', 'zh_CN']}
                        label={`${LangTranslate('中文')}:`}
                      >
                        <CustomLangObjInput placeholder="" readOnly variant="borderless" />
                      </CustomFormItem>
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 200 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'nameList', 'en_US']}
                        label={`${LangTranslate('英文')}:`}
                      >
                        <CustomLangObjInput placeholder="" />
                      </CustomFormItem>
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 200 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'nameList', 'es_ES']}
                        label={`${LangTranslate('西班牙语')}:`}
                      >
                        <CustomLangObjInput placeholder="" />
                      </CustomFormItem>
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 200 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'nameList', 'pt_BR']}
                        label={`${LangTranslate('巴西葡萄牙语')}:`}
                      >
                        <CustomLangObjInput placeholder="" />
                      </CustomFormItem>
                    </div>
                  </div>
                  <div className="tagPoolTagListLangWrap-item-desc-wrap">
                    <div className="tagPoolTagListLangWrap-item-desc-label">
                      {LangTranslate('标签描述')}
                    </div>
                    <div className="tagPoolTagListLangWrap-item-desc-langs">
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'descList', 'zh_CN']}
                        label={`${LangTranslate('中文')}:`}
                      >
                        <CustomLangObjInput placeholder="" readOnly variant="borderless" />
                      </CustomFormItem>
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'descList', 'en_US']}
                        label={`${LangTranslate('英文')}:`}
                      >
                        <CustomLangObjInput placeholder="" />
                      </CustomFormItem>
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'descList', 'es_ES']}
                        label={`${LangTranslate('西班牙语')}:`}
                      >
                        <CustomLangObjInput placeholder="" />
                      </CustomFormItem>
                      <CustomFormItem
                        rules={[
                          {
                            validator: (_, value) =>
                              value?.content?.length > 1000 ? Promise.reject() : Promise.resolve(),
                            message: LangTranslate('字数超长'),
                          },
                        ]}
                        name={[currentVersion, 'tagLanguageDtoList', idx, 'descList', 'pt_BR']}
                        label={`${LangTranslate('巴西葡萄牙语')}:`}
                      >
                        <CustomLangObjInput placeholder="" />
                      </CustomFormItem>
                    </div>
                  </div>
                  <div
                    data-no-data={!tagItem?.tagOptionLanguageDtoList?.length}
                    className="tagPoolTagListLangWrap-item-option-list-wrap"
                  >
                    {tagItem?.tagOptionLanguageDtoList?.map?.((optionItem, idy) => (
                      <div className="tagPoolTagListLangWrap-item-option-list-item">
                        <div className="tagPoolTagListLangWrap-item-option-list-item-name-wrap">
                          <div className="tagPoolTagListLangWrap-item-option-list-item-title-order-label">
                            {`${LangTranslate('选项')}${idy + 1}`}
                          </div>
                          <div className="tagPoolTagListLangWrap-item-option-list-item-title-langs">
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 200
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'nameList',
                                'zh_CN',
                              ]}
                              label={`${LangTranslate('中文')}:`}
                            >
                              <CustomLangObjInput placeholder="" readOnly variant="borderless" />
                            </CustomFormItem>
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 200
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'nameList',
                                'en_US',
                              ]}
                              label={`${LangTranslate('英文')}:`}
                            >
                              <CustomLangObjInput placeholder="" />
                            </CustomFormItem>
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 200
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'nameList',
                                'es_ES',
                              ]}
                              label={`${LangTranslate('西班牙语')}:`}
                            >
                              <CustomLangObjInput placeholder="" />
                            </CustomFormItem>
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 200
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'nameList',
                                'pt_BR',
                              ]}
                              label={`${LangTranslate('巴西葡萄牙语')}:`}
                            >
                              <CustomLangObjInput placeholder="" />
                            </CustomFormItem>
                          </div>
                        </div>
                        <div className="tagPoolTagListLangWrap-item-option-list-item-desc-wrap">
                          <div className="tagPoolTagListLangWrap-item-option-list-item-desc-label">
                            {LangTranslate('选项说明')}
                          </div>
                          <div className="tagPoolTagListLangWrap-item-option-list-item-desc-langs">
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 1000
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'descList',
                                'zh_CN',
                              ]}
                              label={`${LangTranslate('中文')}:`}
                            >
                              <CustomLangObjInput placeholder="" readOnly variant="borderless" />
                            </CustomFormItem>
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 1000
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'descList',
                                'en_US',
                              ]}
                              label={`${LangTranslate('英文')}:`}
                            >
                              <CustomLangObjInput placeholder="" />
                            </CustomFormItem>
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 1000
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'descList',
                                'es_ES',
                              ]}
                              label={`${LangTranslate('西班牙语')}:`}
                            >
                              <CustomLangObjInput placeholder="" />
                            </CustomFormItem>
                            <CustomFormItem
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value?.content?.length > 1000
                                      ? Promise.reject()
                                      : Promise.resolve(),
                                  message: LangTranslate('字数超长'),
                                },
                              ]}
                              name={[
                                currentVersion,
                                'tagLanguageDtoList',
                                idx,
                                'tagOptionLanguageDtoList',
                                idy,
                                'descList',
                                'pt_BR',
                              ]}
                              label={`${LangTranslate('巴西葡萄牙语')}:`}
                            >
                              <CustomLangObjInput placeholder="" />
                            </CustomFormItem>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Form>
        </CurrentVersionContent.Provider>

        <div className="pagelet-label-system-multiple-lang-config-modal-btns">
          <div
            onClick={handleCancel}
            className="pagelet-label-system-multiple-lang-config-modal-item"
          >
            {LangTranslate('取消')}
          </div>
          <div
            data-type-primary
            onClick={handelOk}
            className="pagelet-label-system-multiple-lang-config-modal-item"
          >
            {LangTranslate('确定')}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
const MultipleLangConfigModalWrap = props => {
  if (props?.visible) {
    return <MultipleLangConfigModal {...props} />;
  }
  return null;
};
export default MultipleLangConfigModalWrap;
