import React, { useContext } from 'react';
import { PublicStore, handleJumpUrl, recordTrackEvent } from '../../utils/utils';

import classNames from 'classnames';
import useStyle from './styles';

const PageContent = () => {
  // 样式
  const prefixCls = 'pagelet-content';
  const wrapSSR = useStyle('pagelet-content');
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, props } = useContext(PublicStore);
  const { apolloConfig, stateDesc, stateCode, assessmentInfo, empId, performanceId } = stateStore;
  const { pageLetInfo } = apolloConfig;
  const { tipText } = pageLetInfo;
  const {
    params: { apiHost },
  } = props;
  const MID_PERIOD_TYPES = [Number('3'), Number('5'), Number('7'), Number('9')];

  // 跳转绩效
  const jumpEvent = () => {
    const qs = handleJumpUrl(
      {
        performanceId,
        targetEmpId: empId,
        state: stateCode,
        periodType: assessmentInfo?.periodType || '10'
      },
      `&assessmentId=${assessmentInfo?.assessmentId}&scene=1&hasConfirmBtnFlag=1`
    );
    const url = MID_PERIOD_TYPES.includes(assessmentInfo?.periodType)
      ? `/new/perf/overallEvaluation/midSelfSummary?${qs}`
      : `/new/perf/overallEvaluation/endSelfSummary?${qs}`;
    window.open(`${apiHost}${url}`, '_blank');
    if (apiHost.includes('o.didichuxing.com')) {
      recordTrackEvent(); // 记录埋点
    }
  };

  const jumpText = () => {
    if (stateCode === Number('100')) {
      return '员工自评';
    } else if (stateCode === Number('170')) {
      return '结果确认';
    } else {
      return '前往绩效';
    }
  };

  return wrapSSR(
    <div className={classes}>
      <p>
        <span>当前阶段：</span>
        {stateDesc && <span className='state'>{stateDesc}</span>}
      </p>
      {(tipText[stateCode] || [])?.map(item => (
        <p>
          <span>{item}</span>
        </p>
      ))}
      <div className='perfJumpBtn' onClick={jumpEvent}>{jumpText()}</div>
    </div>
  );
};

export default PageContent;
