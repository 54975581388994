// 跳转到目标页面
export const handleLink = (deptInfo, apiHost) => {
  const { deptId = '', empId = '' } = deptInfo;
  window.open(`${apiHost}/new/ddo?empId=${empId}&deptId=${deptId}`);
};

export const getCurDeptInfo = (options, id) => {
  let foundNode = null;
  options.forEach(ele => {
    if (ele.id === id) {
      foundNode = ele;
      return;
    } else if (ele.childrenNode && ele.childrenNode.length > 0) {
      const result = getCurDeptInfo(ele.childrenNode, id);
      if (result) {
        foundNode = result;
        return;
      }
    }
  });
  return foundNode;
};

export const handlePostData = GData => {
  for (let u of GData) {
    for (let i of u.childrenNode) {
      if (i.id === u.childrenNode[u.childrenNode.length - 1].id) {
        i.notShow1 = true;
      }
      for (let j of i.childrenNode) {
        if (j.id === i.childrenNode[i.childrenNode.length - 1].id) {
          j.notShow1 = true;
        }
        if (i.id === u.childrenNode[u.childrenNode.length - 1].id) {
          j.notShow3 = true;
        }
        for (let k of j.childrenNode) {
          if (k.id === j.childrenNode[j.childrenNode.length - 1].id) {
            k.notShow1 = true;
          }
          if (j.id === i.childrenNode[i.childrenNode.length - 1].id) {
            k.notShow3 = true;
          }
          if (i.id === u.childrenNode[u.childrenNode.length - 1].id) {
            k.notShow4 = true;
          }
          for (let l of k.childrenNode) {
            if (l.id === k.childrenNode[k.childrenNode.length - 1].id) {
              l.notShow1 = true;
            }
            if (k.id === j.childrenNode[j.childrenNode.length - 1].id) {
              l.notShow3 = true;
            }
            if (j.id === i.childrenNode[i.childrenNode.length - 1].id) {
              l.notShow4 = true;
            }
            if (i.id === u.childrenNode[u.childrenNode.length - 1].id) {
              l.notShow5 = true;
            }
          }
        }
      }
    }
  }
  return GData;
};

// 目标排序：规则： 一级排序 业务类>团队类， 二级排序：进行中>已完成（已完成待确认>已完成）>已暂停>已终止
export const sortedListFn = list => {
  const business = list
    ?.filter(bus => bus.classification === Number('1'))
    ?.sort((a, b) => a.completeState - b.completeState); // 业务类目标
  const team = list
    ?.filter(bus => bus.classification === Number('2'))
    ?.sort((a, b) => a.completeState - b.completeState); // 团队类目标
  return [...business, ...team];
};
