import React from 'react';
import { connect, mapReadPretty } from '@formily/react';
import { Input } from 'antd';
import './index.less';
import { Tooltip } from 'antd';
const CustomReadPrettyInput = connect(
  Input,
  mapReadPretty(({ value }) => (
    <Tooltip placement="topLeft" title={value}>
      <div className="label-system-pagelet-belong-dept-modal-readPretty-input">{value}</div>
    </Tooltip>
  )),
);
export default CustomReadPrettyInput;
