import React from 'react';
import { Field, useField } from '@formily/react';
import { Input } from 'antd';
import { FormItem, FormLayout } from '@formily/antd-v5';
import { LangTranslate } from '@/utils';
import CustomReadPrettyToolTipInput from '@/cpns/CustomReadPrettyToolTipInput';

import './index.less';

const CustomInput = props => {
  const { value, onChange } = props;
  return (
    <CustomReadPrettyToolTipInput
      {...props}
      value={value?.val}
      onChange={e => onChange?.({ ...value, val: e?.target?.value })}
    />
  );
};

function CustomTagIndicatorCpn() {
  return (
    <div className="pagelet-label-system-another-item-cell-indicator-wrap">
      <FormLayout layout={['vertical']} labelAlign={['left']}>
        <div className="pagelet-label-system-another-item-cell-indicator-item">
          <Field
            decorator={[FormItem]}
            name="dataSource"
            title={LangTranslate('数据源')}
            validator={[
              { required: true, message: LangTranslate('必填项') },
              val =>
                /^\s+|\s+$/.test(val?.val)
                  ? Promise.reject(LangTranslate('前后不能有空格'))
                  : Promise.resolve(),

              (oriVal, _, ctx) => {
                const val = oriVal?.val;
                if (!val?.length) {
                  return Promise.reject(LangTranslate('必填项'));
                }
                if (val?.length > 200) {
                  return Promise.reject(LangTranslate('字数超长'));
                }
              },
            ]}
            component={[
              CustomInput,
              { style: { height: 36 }, placeholder: LangTranslate('输入指标计算的数据来源') },
            ]}
          />
        </div>
        <div className="pagelet-label-system-another-item-cell-indicator-item">
          <Field
            decorator={[FormItem]}
            name="apiUrl"
            title={LangTranslate('接口地址')}
            validator={[
              { required: true, message: LangTranslate('必填项') },
              val =>
                /^\s+|\s+$/.test(val?.val)
                  ? Promise.reject(LangTranslate('前后不能有空格'))
                  : Promise.resolve(),
              (oriVal, _, ctx) => {
                const val = oriVal?.val;
                if (!val?.length) {
                  return Promise.reject(LangTranslate('必填项'));
                }
                if (val?.length > 200) {
                  return Promise.reject(LangTranslate('字数超长'));
                }
              },
            ]}
            component={[
              CustomInput,
              { style: { height: 36 }, placeholder: LangTranslate('输入接口地址') },
            ]}
          />
        </div>
        <div className="pagelet-label-system-another-item-cell-indicator-item">
          <Field
            title={
              <div className="pagelet-label-system-another-item-cell-indicator-item-desc-title-wrap">
                <div className="pagelet-label-system-another-item-cell-indicator-item-desc-title">{`${LangTranslate('使用说明')}:`}</div>
                <div className="pagelet-label-system-another-item-cell-indicator-item-desc-tips">
                  {LangTranslate('非必填')}
                </div>
              </div>
            }
            decorator={[FormItem, { colon: false }]}
            name="indicatorLogicDesc"
            validator={[
              val =>
                /^\s+|\s+$/.test(val?.val)
                  ? Promise.reject(LangTranslate('前后不能有空格'))
                  : Promise.resolve(),

              (oriVal, _, ctx) => {
                const val = oriVal?.val;

                if (val?.length > 200) {
                  return Promise.reject(LangTranslate('字数超长'));
                }
              },
            ]}
            component={[
              CustomInput,
              {
                style: { height: 36 },
                placeholder: LangTranslate('输入可以说明使用方法，例如入参，出参等'),
              },
            ]}
          />
        </div>
      </FormLayout>
    </div>
  );
}

export default CustomTagIndicatorCpn;
