import React from 'react';
import dayjs from 'dayjs';
import './index.less';
import { LangTranslate } from '@/utils';
import { Tooltip } from 'antd';

function CustomVersionPanel(props) {
  const { versionList, onChange, currentSelectVersion } = props;

  return (
    <div className="talent-market-custom-version-panel">
      {versionList?.map?.(version => (
        <div
          data-is-last={version?.latest}
          data-id={version?.id}
          data-Select-id={currentSelectVersion}
          data-is-select={currentSelectVersion === version?.id}
          className="talent-market-custom-version-panel-item"
        >
          <div className="talent-market-custom-version-panel-item-left">
            <div className="talent-market-custom-version-panel-item-left-dot" />
            <div className="talent-market-custom-version-panel-item-left-line" />
          </div>
          <div className="talent-market-custom-version-panel-item-right">
            <div className="talent-market-custom-version-panel-item-name">
              <span
                onClick={() => {
                  if (version?.id !== currentSelectVersion) {
                    onChange(version?.id);
                  }
                }}
              >
                {version?.latest ? LangTranslate('当前版本') : `V${version?.groupVersion}`}
              </span>
            </div>

            <div className="talent-market-custom-version-panel-item-time">
              <Tooltip title={`${LangTranslate('生效时间')}: ${dayjs(version?.effectiveTime).format('YYYY.MM.DD HH:mm')}`} placement='left' >
                <div className="talent-market-custom-version-panel-item-time-label">
                  {`${LangTranslate('生效时间')}:`}
                </div>
                <div className="talent-market-custom-version-panel-item-time-content">
                  {dayjs(version?.effectiveTime).format('YYYY.MM.DD HH:mm')}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomVersionPanel;
