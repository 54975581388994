import React from 'react';
import { Tabs } from 'antd';
import './index.less';
function CustomSegmented(props) {
  return (
    <div className="pagelet-label-system-tag-custom-segmented">
      <Tabs tabBarGutter={4} {...props} />
    </div>
  );
}

export default CustomSegmented;
