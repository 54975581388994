import React, { useEffect } from 'react';
import { getLocale, Avatar} from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';

import useStyle from './style';
import lockUrl, { ReactComponent as Lock } from './assets/lock.svg';
// 图片建议手动上传到 gift 使用
// import comment from './assets/comment.png';

const localLocales = manifest.locales || {};

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const defaultImg = 'https://img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/avatar.png'

const Pagelet = props => {
  const {
    params: { apiHost, locales, data },
  } = props;
  const organizationData = JSON.parse(JSON.stringify(data));
  const {
    ldap,
    empId,
    jobLevelDesc,
    small,
    name,
    deptPath,
    jobcodeDesc,
    managerName,
    managerLdap,
    managerSmall,
    emailAddr,
    hrbpHead1Name,
    hrbpHead1Ldap,
    hrbpHead1Small,
    hrbpName,
    hrbpLdap,
    hrbpSmall,
    hrbpManagerName,
    hrbpManagerLdap,
    hrbpManagerSmall,
    hrbpPmoList,
    crossLevelSuperiorName,
    crossLevelSuperiorLdap,
    crossLevelSuperiorSmall,
    financialBpName,
    financialBpLdap,
    taxationBpName,
    taxationBpLdap,
    legalBpName,
    legalBpLdap,
    financialBpList,
    taxationBpList,
    legalBpList,
    phone,
    jobFunctionDesc,
    jobSubFunctionDesc,
    jobFamilyDesc
  } = organizationData;

  const personnelInfo = (name, ldap) => {
    return <>
      {ldap ?
        <span className="others-name-new">
          <span onClick={() => window.open(`dchat://im/start_conversation?name=${ldap}`)}>{`${name}(${ldap})`}</span>
        </span>
        : '-'
      }
    </>
  }

  const getStr = (str) => {
    return (str || str === 0) ? str : '-'
  }

  const getJobFunction = (jobFunctionDesc, jobSubFunctionDesc, jobFamilyDesc) => {
    if (jobFunctionDesc && jobSubFunctionDesc && jobFamilyDesc) {
      return `${jobFunctionDesc}>${jobSubFunctionDesc}>${jobFamilyDesc}`
    } else if (jobFunctionDesc && jobSubFunctionDesc) {
      return `${jobFunctionDesc}>${jobSubFunctionDesc}`
    } else if (jobFunctionDesc) {
      return `${jobFunctionDesc}`
    } else {
      return '-'
    }
  }

  const prefixCls = 'pagelet-personal-organization';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      <div className="personal-organization-modal">
        <div className="personal-organization-modal-baseinfo personal-organization-modal-block">
          <div className="personal-organization-modal-block-title">基本信息</div>
          <div className="personal-organization-modal-block-info personal-organization-margin-bottom-16">
            <div className="personal-organization-modal-large-base">
              {/* <div className="personal-organization-modal-img"> */}
                <Avatar src={small || defaultImg}/>
              {/* </div> */}
              {ldap && <div className="personal-organization-modal-name">{`${name}(${ldap})`}</div>}
            </div>
          </div>
          <div className="personal-organization-modal-item personal-organization-margin-bottom-16">
            <div className="personal-organization-modal-label">
              部门路径：
            </div>
            <div className="personal-organization-modal-value">
              {getStr(deptPath)}
            </div>
          </div>
          <div className="personal-organization-modal-item personal-organization-modal-both personal-organization-margin-bottom-16">
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                直接上级：
              </div>
              <div className="personal-organization-modal-value">
                {managerLdap ? (<div
                  className="personal-organization-modal-base personal-organization-modal-pointer"
                  onClick={() => window.open(`dchat://im/start_conversation?name=${managerLdap}`)}
                >
                  <div className="personal-organization-modal-img">
                    <Avatar src={managerSmall || defaultImg} />
                  </div>
                  <div className="personal-organization-modal-name">{personnelInfo(managerName, managerLdap)}</div>
                </div>) : '-'}
              </div>
            </div>
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                隔级上级：
              </div>
              <div className="personal-organization-modal-value">
                {crossLevelSuperiorLdap ? (<div
                  className="personal-organization-modal-base personal-organization-modal-pointer"
                  onClick={() => window.open(`dchat://im/start_conversation?name=${crossLevelSuperiorLdap}`)}
                >
                  <div className="personal-organization-modal-img">
                    <Avatar src={crossLevelSuperiorSmall || defaultImg} />
                  </div>
                  <div className="personal-organization-modal-name">{personnelInfo(crossLevelSuperiorName, crossLevelSuperiorLdap)}</div>
                </div>) : '-'}
              </div>
            </div>
          </div>
          <div className="personal-organization-modal-item personal-organization-modal-both">
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                岗位名称：
              </div>
              <div className="personal-organization-modal-value">
                {getStr(jobcodeDesc)}
              </div>
            </div>
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                岗位序列：
              </div>
              <div className="personal-organization-modal-value">
                {getJobFunction(jobFunctionDesc, jobSubFunctionDesc, jobFamilyDesc)}
              </div>
            </div>
          </div>
        </div>
        <div className="personal-organization-modal-HRinfo personal-organization-modal-block">
          <div className="personal-organization-modal-block-title">人力支持</div>
          <div className="personal-organization-modal-item personal-organization-modal-both personal-organization-margin-bottom-16">
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                HRBP：
              </div>
              <div className="personal-organization-modal-value">
                {hrbpLdap ? (<div
                  className="personal-organization-modal-base personal-organization-modal-pointer"
                  onClick={() => window.open(`dchat://im/start_conversation?name=${hrbpLdap}`)}
                >
                  <div className="personal-organization-modal-img">
                    <Avatar src={hrbpSmall || defaultImg} />
                  </div>
                  <div className="personal-organization-modal-name">{personnelInfo(hrbpName, hrbpLdap)}</div>
                </div>) : '-'}
              </div>
            </div>
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                HRBP 经理：
              </div>
              <div className="personal-organization-modal-value">
                {hrbpManagerLdap ? (<div
                  className="personal-organization-modal-base personal-organization-modal-pointer"
                  onClick={() => window.open(`dchat://im/start_conversation?name=${hrbpManagerLdap}`)}
                >
                  <div className="personal-organization-modal-img">
                    <Avatar src={hrbpManagerSmall || defaultImg} />
                  </div>
                  <div className="personal-organization-modal-name">{personnelInfo(hrbpManagerName, hrbpManagerLdap)}</div>
                </div>) : '-'}
              </div>
            </div>
          </div>
          <div className="personal-organization-modal-item personal-organization-modal-both">
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                HRBP HEAD：
              </div>
              <div className="personal-organization-modal-value">
                {hrbpHead1Ldap ? (<div
                  className="personal-organization-modal-base personal-organization-modal-pointer"
                  onClick={() => window.open(`dchat://im/start_conversation?name=${hrbpHead1Ldap}`)}
                >
                  <div className="personal-organization-modal-img">
                    <Avatar src={hrbpHead1Small || defaultImg} />
                  </div>
                  <div className="personal-organization-modal-name">{personnelInfo(hrbpHead1Name, hrbpHead1Ldap)}</div>
                </div>) : '-'}
              </div>
            </div>
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                HRBP PMO：
              </div>
              <div className="personal-organization-modal-value">
                {
                  (Array.isArray(hrbpPmoList) && hrbpPmoList?.length > 0) ? hrbpPmoList.map(item =>{
                    return item?.ldap ? (
                      <div
                        className="personal-organization-modal-base personal-organization-modal-pointer"
                        onClick={() => window.open(`dchat://im/start_conversation?name=${item.ldap}`)}
                      >
                        <div className="personal-organization-modal-img">
                          <Avatar src={item.small || defaultImg} />
                        </div>
                        <div className="personal-organization-modal-name">{personnelInfo(item.name, item.ldap)}</div>
                      </div>
                    ) : '-'
                  }) : '-'
                }
              </div>
            </div>
          </div>
        </div>
        <div className="personal-organization-modal-businessinfo personal-organization-modal-block">
          <div className="personal-organization-modal-block-title">业务支持</div>
          <div className="personal-organization-modal-item personal-organization-modal-both personal-organization-margin-bottom-16">
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                财务BP：
              </div>
              <div className="personal-organization-modal-value">
                {
                  (Array.isArray(financialBpList) && financialBpList?.length > 0) ? financialBpList.map(item =>{
                    return item?.ldap ? (
                      <div
                        className="personal-organization-modal-base personal-organization-modal-pointer"
                        onClick={() => window.open(`dchat://im/start_conversation?name=${item.ldap}`)}
                      >
                        <div className="personal-organization-modal-img">
                          <Avatar src={item.small || defaultImg} />
                        </div>
                        <div className="personal-organization-modal-name">{personnelInfo(item.name, item.ldap)}</div>
                      </div>
                    ) : '-'
                  }) : '-'
                }
              </div>
            </div>
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                税务BP：
              </div>
              <div className="personal-organization-modal-value">
                {
                  (Array.isArray(taxationBpList) && taxationBpList?.length > 0) ? taxationBpList.map(item =>{
                    return item?.ldap ? (
                      <div
                        className="personal-organization-modal-base personal-organization-modal-pointer"
                        onClick={() => window.open(`dchat://im/start_conversation?name=${item.ldap}`)}
                      >
                        <div className="personal-organization-modal-img">
                          <Avatar src={item.small || defaultImg} />
                        </div>
                        <div className="personal-organization-modal-name">{personnelInfo(item.name, item.ldap)}</div>
                      </div>
                    ) : '-'
                  }) : '-'
                }
              </div>
            </div>
          </div>
          <div className="personal-organization-modal-item">
            <div className="personal-organization-modal-both-card">
              <div className="personal-organization-modal-label">
                法务BP：
              </div>
              <div className="personal-organization-modal-value">
                {
                  (Array.isArray(legalBpList) && legalBpList?.length > 0) ? legalBpList.map(item =>{
                    return item?.ldap ? (
                      <div
                        className="personal-organization-modal-base personal-organization-modal-pointer"
                        onClick={() => window.open(`dchat://im/start_conversation?name=${item.ldap}`)}
                      >
                        <div className="personal-organization-modal-img">
                          <Avatar src={item.small || defaultImg} />
                        </div>
                        <div className="personal-organization-modal-name">{personnelInfo(item.name, item.ldap)}</div>
                      </div>
                    ) : '-'
                  }) : '-'
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
  );
};

export default Pagelet;
