import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('person-list', () => [
		{ // 标题部分
			[`.${prefixCls}`]: {
				width: 'calc(100% - 212px)',
				marginLeft: '12px',
				display: 'flex',
				flexDirection: 'column',

				['.title']: {
					marginBottom: '6px',
					fontFamily: 'PingFang SC',
					fontSize: '12px',
					lineHeight: '18px',
					color: 'rgba(0, 0, 0, 0.6)'
				},

				['.personScroll']: {
					overflow: 'auto',
					height: '200px',

					['.personItem']: {
						display: 'flex',
						marginBottom: '6px',
						padding: '5px 10px 5px 8px',
            border: '1px solid rgba(245, 245, 245, 0.6)',
						borderRadius: '8px',
						background: 'rgba(245, 245, 245, 0.6)',
						cursor: 'pointer',
						['&:hover']: {
							border: '1px solid #FF6400'
						},
						['&:last-child']: {
							marginBottom: '0px'
						},

						// 头像
						['.head']: {
							marginRight: '8px',
						},

						// 姓名
						['.personName']: {
							lineHeight: '16px',
							width: 'calc(100% - 33px)',

							['.name']: {
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								color: 'rgba(0, 0, 0, 0.84)',

								['span']: {
									fontSize: '13px',
									lineHeight: '16px',
									['&:first-child']: {
										fontFamily: '苹方-简',
										fontWeight: '500',
										fontSize: '14px',
										color: 'rgba(0, 0, 0, 0.84)'
									},
									['&:last-child']: {
										marginLeft: '4px',
										fontFamily: 'PingFang SC',
										fontWeight: '400',
										fontSize: '12px',
										color: '#333333'
									}
								}
							},

							['.tag']: {
								marginTop: '2px',

								['span']: {
									fontFamily: 'PingFang SC',
									fontSize: '10px',
									marginRight: '6px',
									['&:last-child']: {
										marginRight: '0px',
									},
								},
								['.follow']: {
									color: '#FF6400'
								},
								['.operat']: {
									color: '#00B365'
								}
							}
						}
					}
				}
			}
		}
	]);
};

export default useStyle;
