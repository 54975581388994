import React, { useState, useEffect, useRef } from 'react';
import { Button, Pagination, Spin } from 'antd';
import { di18n } from '@ultra/common';
import request from '../../utils/request';
import nothing from '../../images/nothing.png';
import TopGuide from '../../components/TopGuide';
import { getCookie } from '../../utils/Tool';

import './index.scss';

const { intl } = di18n;
export const DEFAULT_PAGE_NUM = 1;
export const DEFAULT_PAGE_SIZE = 100;

export default function QuestionnaireList(props) {
  const { match: { params: { sceneId } } } = props;
  const ctLang = getCookie('lang');
  const [langKeyList, setLangKeyList] = useState([0, 1]);
  const [list, setList] = useState();
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(DEFAULT_PAGE_NUM);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const getQuestionnaireList = async () => {
    const res = await request({
      method: 'get',
      url: '/ctActivity/api/answer/activity/myCurrentActivity',
      params: {
        sceneId,
        pageNum,
        pageSize,
      }
    });
    const { data, total } = res;
    setList(data);
    setTotal(total);
  };

  const getTitle = () => (sceneId === '11' ? '新员工体验调研' : '我的反馈');

  const getQusTitle = item => {
    const { activityName, englishActivityName, questionnaireName, enQuestionnaireName } = item;
    let text = '';
    if (sceneId === '11') {
      if (ctLang === 'zh-CN') {
        text = questionnaireName;
      } else {
        text = enQuestionnaireName || questionnaireName;
      }
    } else if (ctLang === 'zh-CN') {
      text = activityName;
    } else {
      text = englishActivityName || activityName;
    }
    return text;
  };

  useEffect(() => {
    getQuestionnaireList();
  }, [pageNum, pageSize]);

  function handlePageChange(page, pageSize) {
    setPageNum(page);
    setPageSize(pageSize);
  }

  return (
    <div className="questionnaire-list-container">
      <TopGuide source="pc" title={intl.t(getTitle())} questionDes="" langKeyList={langKeyList} />
      <div>
        {
          list?.length === 0 ? (
            <div className="no-questionnaire">
              <img src={nothing} alt="nothing" />
              <div>
                {intl.t('暂无需要您参与反馈的调研问卷～')}
              </div>
            </div>
          )
            : (
              <>
                <div className="questionnaire-list-content">
                  {
                    list?.map((item, index) => (
                      <div className="questionnaire-single" key={item.activityId}>
                        <div className="questionnaire-list-name">
                          {index + 1}
                          .
                          {' '}
                          {
                            getQusTitle(item)
                          }
                        </div>

                        <div className="questionnaire-list-link">
                          {intl.t('反馈地址')}
                          ：
                          <Button
                            type="link"
                            onClick={() => { window.open(item.questionnaireUrl); }}
                          >
                            {item.questionnaireUrl}
                          </Button>
                        </div>
                      </div>
                    ))
                  }
                </div>

                {
                  total > DEFAULT_PAGE_SIZE && (
                    <Pagination
                      total={total}
                      showSizeChanger
                      showQuickJumper
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                      defaultCurrent={DEFAULT_PAGE_NUM}
                      current={pageNum}
                      onChange={handlePageChange}
                      showTotal={total => `全部: 共 ${total} 条`}
                    />
                  )
                }
              </>
            )
        }
      </div>
    </div>
  );
}
