import { message } from '@didi/ec-base';
import generatorHTTP from './generatorHTTP';

const excuteConfig = (config) => {
  config.baseURL = '/';
  if (config.url.includes('http') || config.url.includes('//')) {
    // no action
  } else if (config.url.includes('holiday')) {
    // no action
  } else if (config.url.includes('probation')) {
    // no action
  } else if (config.url.includes('empInfo')) {
    // no action
  } else if (config.url.includes('gateway/employee')) {
    // no action
  } else if (config.url.includes('transfer')) {
    // no action
  } else if (config.url.includes('gateway/leave')) {
    // no action
  } else {
    // config.url = `/gateway/dhr-workbench${config.url}`;
    config.url = `${config.url}`;
  }
};

const excuteResponse = (res) => {
  if (res?.data?.code === '100200') {
    return res.data;
  } if (res?.data?.code === '100400') {
    return res.data;
  } if (res.data.code === '100403') {
    // 没权限
  } else {
    if (res.headers['content-type'] === 'application/msexcel') return res.data;
    message.error(res?.data?.msg || res?.data?.message || '系统错误');
    return res.data;
  }
};

// const HTTP = generatorHTTP({}, {
//   excuteConfig,
//   excuteResponse,
// });

const HTTPRequest = (headers) => {

  return generatorHTTP({}, {
    excuteConfig,
    excuteResponse,
    headers
  });
}

export default HTTPRequest;
