import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-form-filter', () => [
		{
      '.ant-form.form-filter.pagelet-form-filter': {
        background: 'rgba(0, 0, 0, 0.04)',
        marginBottom: '0px',
        padding: '0px',
        transition: 'all 1s',
        maxHeight: '0px',
        overflow: 'hidden'
      },
      '.ant-form.form-filter.pagelet-form-filter.form-filter-visible': {
        padding: '12px',
        maxHeight: '200px',
        overflow: 'visible'
      },

			// base
      '.ant-form.form-filter': {
        borderRadius: '8px',
        '.form-filter-container': {
          display: 'flex',
          flexWrap: 'wrap'
        },
        '.table-detail': {
          fontSize: '12px',
          color: '#333333',
          display: 'inline-flex',
          alignItems: 'center',
          'span, .ant-btn': {
            height: '18px',
            lineHeight: '18px',
          },
          '.empty-this': {
            color: '#333',
            '&:hover': {
              color: '#FF6400',
            }
          },
          '.ant-btn': {
            padding: '0px 12px',
            marginBottom: '2px'
          },
          '.has-many,.empty-this': {
            display: 'none',
          },
          '.has-many': {
            padding: '0 16px',
            position: 'relative',
            cursor: 'pointer',
            '&:hover': {
              color: '#FF6400',
            },
            '&::after': {
              display: 'block',
              content: "''",
              height: '12px',
              width: '1px',
              position: 'absolute',
              right: 0,
              top: '0px',
              background: '#ccc',
            }
          },
          '.form-filter-icon-empty': {
            marginRight: '5px',
          }
        },
        '.has-select': {
          '.has-many,.empty-this': {
            display: 'inline-block',
          }
        }
      },
      '.form-filter-item': {
        position: 'relative',
        marginRight: '12px',
        minWidth: '98px',
        padding: '0 12px',
        marginBottom: '12px',
        height: '29px',
        fontSize: '12px',
        color: '#646464',
        border: '1px solid #CCCCCC',
        borderRadius: '4px',
        background: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '.form-filter-down, .form-filter-clear': {
          display: 'inline-block',
          width: '14px',
        },
        '&.has-value': {
          border: '1px solid #FF6400',
        },
        '&:hover': {
          border: '1px solid #FF6400',
          '&.has-value': {
            '.form-filter-clear': {
              display: 'block',
            },
            '.form-filter-down': {
              display: 'none',
            },
            '& .no-clear .form-filter-clear': {
              display: 'none',
            },
            '& .no-clear .form-filter-down': {
              display: 'block',
            }
          },
          '.form-filter-label': {
            color: '#FF6400',
          }
        },
        '.form-filter-label': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '.form-filter-clear': {
              display: 'none',
            },
            '.form-filter-icon': {
              paddingLeft: '4px',
            },
            '&.has-value': {
              border: '1px solid #FF6400',
            }
        },
        '.form-filter-dropdown': {
            position: 'absolute',
            zIndex: -1,
            visibility: 'hidden',
            background: '#FFFFFF',
            boxShadow: '0 0 10px 0 rgba(58,39,30,0.10)',
            padding: '12px',
            top: 'calc(100% + 8px)',
            left: 0,
            width: 0,
            borderRadius: '8px',
            overflow: 'hidden',
            '.ant-form-item': {
              marginRight: 0,
              marginBottom: 0
            }
        },
        '.form-filter-dropdown-show': {
          zIndex: 9,
          width: '320px',
          visibility: 'visible',
          overflow: 'inherit',
          '&.form-filter-dropdown-deptmentselect': {
            width: '345px',
            '.ant-checkbox-wrapper': {
              fontSize: '12px',
              marginLeft: '5px',
            }
          },
          '&.form-filter-dropdown-deptmentselect-has-child': {
            width: '442px',
          }
        },
        '.dhr-dept-select, .dhr-dept-select-filter-container .ant-form-item': {
          flex: 1
        },
        '.ant-picker': {
          width: '100%',
        }
      },
      '.form-filter-item-disabled': {
        background: '#f5f5f5',
        cursor: 'not-allowed',
        color: '#CCCCCC',
        '&:hover': {
          border: '1px solid #CCCCCC',
          '&.has-value': {
            '.form-filter-clear': {
              display: 'block',
            },
            '.form-filter-down': {
              display: 'none',
            }
          },
          '.form-filter-label': {
            color: '#CCCCCC',
          }
        }
      },
      '.rende-filter-tip': {
        fontSize: '12px',
        '.rende-filter-tip-item': {
          margin: '5px 0',
          display: 'flex',
          '.rende-filter-tip-value': {
            flex: 1
          }
        }
      }
		}
	])
};

export default useStyle;
