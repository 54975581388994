export const HOME_PAGE_CATEGORY_IMG_MAP = {
  medical: {
    categoryCode: 'medical',
    imageUrl: 'medical_bigSize_with_text.png',
    categoryName: '医疗报销',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9441',
  },
  maternity: {
    categoryCode: 'maternity',
    imageUrl: 'maternity_bigSize_with_text.png',
    categoryName: '生育服务',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9441',
  },
  settled: {
    categoryCode: 'settled',
    imageUrl: 'settled_bigSize_with_text.png',
    categoryName: '落户服务',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9625?page=1',
  },
  residence_beijing: {
    categoryCode: 'residence_beijing',
    imageUrl: 'residence_beijing_bigSize_with_text.png',
    categoryName: '工作居住证',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9571?page=1',
  },
  residence_shanghai: {
    categoryCode: 'residence_shanghai',
    imageUrl: 'residence_shanghai_bigSize_with_text.png',
    categoryName: '居住证积分',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/10357?page=1',
  },
  hrfile: {
    categoryCode: 'hrfile',
    imageUrl: 'hrfile_bigSize_with_text.png',
    categoryName: '人事档案',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9637?page=1',
  },
  allowance: {
    categoryCode: 'allowance',
    imageUrl: 'allowance_bigSize_with_text.png',
    categoryName: '政府补贴',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/10217?page=1',
  },
  social: {
    categoryCode: 'social',
    imageUrl: 'social_bigSize_with_text.png',
    categoryName: '社保服务',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9441',
  },
  fund: {
    categoryCode: 'fund',
    imageUrl: 'fund_bigSize_with_text.png',
    categoryName: '公积金',
    qaLink: 'https://i.xiaojukeji.com/service/question/list/9441',
  }
};

export const BASE_URL = '//img-hxy021.didistatic.com/static/ehr_static/imgs/yuyue/';
