import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {
  return useRegisterStyle('pagelet-inventory-employee-info', () => [
    {
      // base
      [`.${prefixCls}`]: {
        ['.info-wrapper']: {
          display: 'flex',
          marginBottom: '12px',
          width: '100%',
        }
      },
    },
  ]);
};

export default useStyle;
