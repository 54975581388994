import React, { useEffect, useState, useRef } from 'react';
import Annual from '../src/assets/nianjia.svg';
import Compassionate from '../src/assets/shijia.svg';
import Sick from '../src/assets/bingjia.svg';
import Marriage from '../src/assets/hunjia.svg';
import Maternity from '../src/assets/chanjia.svg';
import Prenatal from '../src/assets/chanjianjia.svg';
import Paternity  from '../src/assets/peichanjia.svg';
import Family  from '../src/assets/tanqianjia.svg';
import Parenting  from '../src/assets/yuerjia.svg';
import ParentBack  from '../src/assets/beiyongyurejia.svg';
import Funeral  from '../src/assets/sangjia.svg';

const leaveBgConfig = {
  1: {
    type: 'annual',
    title: '年假',
    icon: Annual,
  },
  2: {
    type: 'compassionate',
    title: '事假',
    icon: Compassionate
  },
  3: {
    type: 'sick',
    title: '病假',
    icon: Sick
  },
  4: {
    type: 'marriage',
    title: '婚假',
    icon: Marriage
  },
  5: {
    type: 'maternity',
    title: '产假',
    icon: Maternity
  },
  7: {
    type: 'prenatal',
    title: '产检假',
    icon: Prenatal
  },
  8: {
    type: 'paternity',
    title: '陪产假',
    icon: Paternity
  },
  10: {
    type: 'family',
    title: '探亲假',
    icon: Family
  },
  13: {
    type: 'parenting',
    title: '育儿假',
    icon: Parenting
  },
  14: {
    type: 'parentBack',
    title: '备用假',
    icon: ParentBack
  },
  9: {
    type: 'funeral',
    title: '丧假',
    icon: Funeral
  }
};

const holidayTypeList = {
  annual: 1,
  compassionate: 2,
  sick: 3,
  marriage: 4,
  maternity: 5,
  prenatal: 7,
  paternity: 8,
  funeral: 9,
  family: 10,
  parenting: 13,
  parentBack: 14
};

export { leaveBgConfig, holidayTypeList };
