import React from "react";
import { Radio } from "antd";
import { tabsLangMaps } from "../../utils/Tool";
import CardIcon from "../CardIcon";
import classnames from "classnames";

import "./index.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorTabs = (props) => {
  const {
    value,
    tabs,
    changeTab,
    text = "题目录入支持多语言，默认中英双语，如需其他语言请选择：",
    showTabs,
  } = props;

  return (
    <div
      className={classnames("langs-editor-tabs", {
        "langs-editor-contents-tab": showTabs,
      })}
    >
      <div className="langs-editor-tabs-text">
        <div className="tixing-icon">
          <CardIcon type="icontixing" />
        </div>
        {text}
      </div>

      <Radio.Group
        value={value}
        onChange={changeTab}
        className="editor-lan-div"
        size="small"
      >
        {tabs?.map((item) => {
          if (item > 0) {
            return (
              <Radio.Button value={item} className="editor-lan-btn" key={item}>
                {tabsLangMaps[item]}
              </Radio.Button>
            );
          }
        })}
      </Radio.Group>
    </div>
  );
};

export default EditorTabs;
