import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('export-button', () => [
		{
			// base
      '.export-button': {
        backgroundColor: '#FF6400 !important',
        'span': {
          marginLeft: '4px',
        },
      },
      '.fileTitle': {
        fontSize: '14px',
        color: '#333333',
        marginBottom: '5px',
        'span': {
          fontSize: '15px',
          color: '#FE4A68',
        }
      },
      '.input-file-name': {
        width: '412px',
        height: '33px',
        padding: '7px 0 6px 12px',
        lineHeight: '20px',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.6)',
        outline: 'none',
      },
      '.input-file-name::placeholder': {
        color: '#999999',
      },
      '.input-file-name:focus': {
        borderStyle: 'solid',
        borderColor: '#FF6400',
        boxShadow: '0 0 1px #FF6400',
      },
      '.file-hint': {
        fontSize: '12px',
        color: '#AEAEAE',
        padding: '4px 0px 0 12px',
        marginBottom: 0,
      },
      '.dot': {
        position: 'fixed',
        transition: 'width .5s, height .5s, left .2s, top .2s, border-radius .5s, z-index .1s .2s',
        width: '6px',
        height: '6px',
        borderRadius: '100%',
        boxShadow: '0 0 0 1px #fff',
      }
		}
	])
}

export default useStyle;
