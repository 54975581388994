import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';
import { color } from 'echarts';

const useDrawerStyle = (prefixCls) => {
  console.log(prefixCls, 9999)

  const antPrefixCls = prefixCls.split('-')[0]; // ant xxx-drawer

  return useRegisterStyle('drawer', () => [
    {
        ['.holiday-used-options']: {
          display: 'flex',
          justifyContent: 'space-between',
          ['.holiday-warp']:{
            display: 'flex',
            alignItems: 'center',
            ['.holiday-used-scope']: {
              marginRight: '6px',
              marginTop:'22px',
              ['.ant-select']: {
                ['.ant-select-selector']: {
                  padding: '5px 6px',
                  borderRadius: '8px',
                  background: 'rgba(0,0,0,0.04)',
                  border: 'none',
                  ['.ant-select-selection-item']: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                  }
                },
                ['.ant-select-arrow']: {
                  right: '6px',
                  width: '14px',
                  height: '14px',
                  color: 'rgb(12, 21, 46)',
                },

                ['.ant-select-dropdown']: {
                  width: 'auto !important',
                  padding: '4px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                  ['.ant-select-item']: {
                    minWidth: '100px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['&:hover']: {
                      background: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  ['.ant-select-item-option-selected']: {
                    color: '#FF6400',
                    background: '#FFF7F3',
                    fontWeight: '500',
                    ['&:hover']: {
                      background: '#FFF7F3'
                    }
                  }
                },
                ['&:hover ']: {
                  ['.ant-select-selector']: {
                    background: '#F1F1F1',
                  },
                  ['.ant-select-arrow']: {
                    opacity: '1',
                  },
                },
                ['&.ant-select-focused .ant-select-selector']: {
                  border: 'none',
                  boxShadow: 'none'
                }
              }

            },
            ['.year-month-week']: {
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.72)',
              fontSize: '12px',
              lineHeight: '18px',
              marginTop: '22px',
              ['.option-btn']: {
                marginLeft: '8px',
                padding: '4px 12px',
                background: '#F5F5F5',
                cursor: 'pointer',
                borderRadius: '6px',
              },
              ['.pagelet-leave-summary-title-filter']: {
                display: 'flex',
                alignItems: 'center',
                userSelect: 'none',
                marginLeft: '16px',
                ['.pagelet-leave-summary-time']: {
                  marginRight: '6px',
                  borderRadius: '8px',
                  background: 'rgba(0,0,0,0.04)',
                  cursor: 'pointer',
                  '.time-switch-popconfirm-content-item': {
                    display: 'flex',
                    padding: '5px 0px',
                    position: 'relative',
                    '.prev,.next': {
                      position: 'absolute',
                      top: '5px',
                      width: '20px',
                      height: '22px',
                      textAlign: 'center',
                      lineHeight: '28px',
                      zIndex: '999',
                    },
                    '.prev:hover, .next:hover':{
                      background: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '6px',
                      cursor: 'pointer'
                    },
                    '.prev': {
                      left: '4px'
                    },
                    '.next': {
                      right: '4px'
                    },
                    '.ant5-picker': {
                      background: 'none',
                      border: 'none',
                      padding: '0px',
                      '.ant5-picker-input input':{
                        textAlign: 'center',
                        width: '80px',
                        margin: '0 25px',
                        '&:hover':{
                          background: 'rgba(0, 0, 0, 0.08)',
                          borderRadius: '6px',
                          cursor: 'pointer'
                        }
                      }
                    },
                    '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                      boxShadow: 'none',
                    }
                  }
                },
                ['.pagelet-leave-summary-scope']: {
                  marginRight: '6px',

                  ['.ant-select']: {
                    ['.ant-select-selector']: {
                      padding: '5px 6px',
                      borderRadius: '8px',
                      background: 'rgba(0,0,0,0.04)',
                      border: 'none',
                      ['.ant-select-selection-item']: {
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        letterSpacing: 0,
                        color: 'rgba(0, 0, 0, 0.84)',
                      }
                    },
                    ['.ant-select-arrow']: {
                      right: '6px',
                      width: '14px',
                      height: '14px',
                      color: 'rgb(12, 21, 46)',
                    },

                    ['.ant-select-dropdown']: {
                      width: 'auto !important',
                      padding: '4px',
                      borderRadius: '8px',
                      background: '#FFFFFF',
                      boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                      ['.ant-select-item']: {
                        minWidth: '100px',
                        fontSize: '14px',
                        lineHeight: '22px',
                        letterSpacing: 0,
                        color: 'rgba(0, 0, 0, 0.84)',
                        borderRadius: '4px',
                        ['&:hover']: {
                          background: 'rgba(0, 0, 0, 0.04)'
                        }
                      },
                      ['.ant-select-item-option-selected']: {
                        color: '#FF6400',
                        background: '#FFF7F3',
                        fontWeight: '500',
                        ['&:hover']: {
                          background: '#FFF7F3'
                        }
                      }
                    },
                    ['&:hover ']: {
                      ['.ant-select-selector']: {
                        background: 'rgba(0, 0, 0, 0.08)',
                      },
                      ['.ant-select-arrow']: {
                        opacity: '1',
                      },
                    },
                    ['&.ant-select-focused .ant-select-selector']: {
                      border: 'none',
                      boxShadow: 'none'
                    }
                  }

                },
                [`.${prefixCls}-summary`]: {
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '18px',
                  letterSpacing: '0px',
                  color: 'rgba(0, 0, 0, 0.72)',
                }
              },
              ['.selected-option']: {
                background: '#F76100',
                color: '#FFFFFF',
              },
              ['.option-data-select']: {
                marginLeft: '8px',
                marginRight: '2px',
                background: '#F5F5F5',
                zIndex: '999',
                borderRadius: '6px',
                ['.ant-picker']: {
                  border: 'none',
                  background: '#F5F5F5',
                },
                ['.ant-picker-body']: {
                  ['thead']: {
                    ['th:first-child']: {
                      display: 'none',
                    },
                  },
                  ['tbody']: {
                    ['.ant-picker-week-panel-row']: {
                      ['td:first-child']: {
                        display: 'none',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
  ]);
};

export default useDrawerStyle;
