import React, { useState } from 'react';
import { getLocale, ConfigProvider, message, Upload, Modal } from '@didi/ec-base';
import { saveResumeInfo, getOperateLog } from '../../services';
import useModalStyle from './style/modal';
import UploadSvg from '../../assets/upload.svg';
import manifest from '../../../../manifest.json';

const { Dragger } = Upload;
const apis = manifest.apis || {};

const localLocales = manifest.locales;

const UploadResume = props => {
  const { isModalOpen, handleOk, handleCancel, modalType, employeeId, apiHost, headers, uploadResumeFunctionId, locales } = props;

  const { getPrefixCls } = React.useContext(ConfigProvider.ConfigContext);
  const modalPrefixCls = getPrefixCls('modal', null);
  const wrapModalSSR = useModalStyle(modalPrefixCls);

  const [fileLists, setFileLists] = useState([]);
  const [fileRes, setFileRes] = useState(null);

  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const uploadProps = {
    accept: '.doc, .docx, .pdf',
    action: `${apiHost}${apis.uploadResume}`,
    maxCount: 1,
    headers: {
      ...headers,
      'bricks-function-id': uploadResumeFunctionId
    },
    beforeUpload: file => {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        message.error(`${file.name}${locale.oversizeTip}`);
        return Upload.LIST_IGNORE;
      }
      return isLt20M;
    },
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        setFileLists(fileList);
        setFileRes(file?.response?.data);
      }
    },
    onPreview: () => {
      return null;
    },
  };

  // 导入
  const handleClick = async () => {
    try {
      getOperateLog(apiHost, headers, {
        operateName: "点击",
        operateContent: "面试关系",
        operateParams: {
          "操作": modalType === 'add' ? "上传简历": "更新简历",
          "被预览员工工号": employeeId,
        }
      })

      const header = {
        ...headers,
        'bricks-function-id': uploadResumeFunctionId
      }
      if (!fileLists?.length) {
        message.warning(locale.uploadTip);
        return;
      }
      const res = await saveResumeInfo(apiHost, header, { ...fileRes, empId: employeeId });
      const { code, data } = res;
      if (code === '100200') {
        message.success(data);
        handleOk();
      }
    } catch (error) {
      console.log('saveResumeInfo', error);
    }
  };

  return wrapModalSSR(
    <Modal
      title={modalType === 'add' ? locale.uploadResume : locale.updateResume}
      width={600}
      visible={isModalOpen}
      onOk={handleClick}
      onCancel={handleCancel}
      okText={locale.export}
      cancelText={locale.cancel}
      destroyOnClose
      maskClosable={false}
      className='upload-resume-container'
    >
      <div style={{ marginBottom: '12px' }}>{locale.exportResume}:</div>
      <Dragger {...uploadProps}>
        <div className="upload-drag-icon">
          <UploadSvg className="card-icon" />
        </div>
        <p className="upload-text">{locale.dropFile}</p>
        <p className="upload-hint">{locale.supportFile}</p>
      </Dragger>
    </Modal>
  );
};

export default UploadResume;
