import React, { useState, useEffect } from 'react';
import { Field, ObjectField, useField, observer, VoidField } from '@formily/react';
import { Input, Modal, Tooltip } from 'antd';
import SvgIcon from '@/cpns/SvgIcon';
import { LangTranslate, insertElement } from '@/utils';
import { FormItem } from '@formily/antd-v5';
import CustomReadPrettyToolTipInput from '@/cpns/CustomReadPrettyToolTipInput';
import DragSortWrap from '@/cpns/DragSortWrap';
import classnames from 'classnames';
import './index.less';
import cloneDeep from 'lodash/cloneDeep';

const itemRender = (provided, snapshot, item, index) => {
  const field = useField();
  const newClassName = classnames('pagelet-label-system-option-item-sub-item-drop-handle', {
    'pagelet-label-system-option-item-sub-item-drop-handle-disabled':
      !!field?.value?.[index]?.otherOption,
  });

  return (
    <div ref={provided?.innerRef} {...(provided?.draggableProps || {})}>
      <div className="pagelet-label-system-option-item-sub-item-wrap" key={index}>
        {!field?.readPretty ? (
          <div {...provided?.dragHandleProps} className={newClassName}>
            <SvgIcon type="icon-drop-handel" size={10} pointer />
          </div>
        ) : null}

        <div className="pagelet-label-system-option-item-sub-item">
          <ObjectField name={index}>
            <div className="pagelet-label-system-option-item-sub-item-label">
              <Field hidden name="id" component={[Input, {}]} />
              <div className="pagelet-label-system-option-item-sub-item-name">
                {!field?.readPretty ? (
                  <div className="pagelet-label-system-tag-config-page-content-body-label-require-icon">
                    *
                  </div>
                ) : null}

                <Field
                  name="name"
                  validator={[
                    val =>
                      /^\s+|\s+$/.test(val)
                        ? Promise.reject(LangTranslate('前后不能有空格'))
                        : Promise.resolve(),

                    { required: true, message: LangTranslate('必填项') },
                    { maxLength: 200, message: LangTranslate('字数超长') },
                  ]}
                  reactions={[
                    field => {
                      const formInstance = field.form;
                      if (!!field?.parent?.value?.otherOption) {
                        field.setComponentProps({
                          disabled: true,
                        });
                        formInstance.setFieldState(
                          `${field.parent.parent.path.entire}.otherBtnWrap`,
                          curField => {
                            curField?.setDisplay?.('none');
                          },
                        );
                      }
                    },
                  ]}
                  component={[
                    CustomReadPrettyToolTipInput,
                    {
                      placeholder: LangTranslate('请输入选项名称'),
                      style: { height: 36 },
                    },
                  ]}
                  decorator={[FormItem]}
                />
              </div>
            </div>
            <div className="pagelet-label-system-option-item-sub-item-desc">
              <Field
                name="description"
                validator={[
                  val =>
                    /^\s+|\s+$/.test(val)
                      ? Promise.reject(LangTranslate('前后不能有空格'))
                      : Promise.resolve(),

                  { maxLength: 1000, message: LangTranslate('字数超长') },
                ]}
                component={[
                  CustomReadPrettyToolTipInput,
                  {
                    placeholder: LangTranslate('非必填，可以配置多语言'),
                    addonBefore: `${LangTranslate('选项说明')}:`,
                    style: { height: 36 },
                  },
                ]}
                decorator={[FormItem]}
              />
            </div>
            <div className="pagelet-label-system-option-item-sub-item-value">
              <Field
                name="val"
                component={[
                  CustomReadPrettyToolTipInput,
                  { placeholder: LangTranslate('输入赋值'), style: { height: 36 } },
                ]}
                validator={[
                  val =>
                    /^\s+|\s+$/.test(val)
                      ? Promise.reject(LangTranslate('前后不能有空格'))
                      : Promise.resolve(),

                  (val, _, ctx) => {
                    if (!val?.length) {
                      return Promise.resolve();
                    }
                    if (val?.length > 200) {
                      return Promise.reject(LangTranslate('最多可输入200字符'));
                    }

                    if (!/^(-?\d+(\.\d{1,2})?|0(\.\d{1,2})?)$/.test(val)) {
                      return Promise.reject(
                        LangTranslate('仅支持有理数（保留2位小数）'),
                      );
                    }
                  },
                ]}
                decorator={[FormItem]}
              />
            </div>
          </ObjectField>
        </div>
        {!field?.readPretty ? (
          <div
            id="pagelet-label-system-option-item-sub-del-btns"
            className="pagelet-label-system-option-item-sub-del-btns"
          >
            <div
              data-btn-disabled={field?.value?.length === 1}
              onClick={() => {
                if (field?.value?.length === 1) {
                  return;
                }
                const cModal = Modal.confirm({
                  title: LangTranslate('是否删除该选项？'),
                  content: LangTranslate('删除后无法恢复'),
                  okButtonProps: {},
                  className: 'pagelet-label-system-del-confirm-wrap',
                  footer: (
                    <div className="pagelet-label-system-del-confirm-footer">
                      <div
                        className="pagelet-label-system-del-confirm-footer-btn"
                        onClick={() => {
                          cModal?.destroy?.();
                        }}
                      >
                        {LangTranslate('取消')}
                      </div>
                      <div
                        data-btn-primary={true}
                        className="pagelet-label-system-del-confirm-footer-btn"
                        onClick={() => {
                          if (!!field?.value?.[field?.value?.length - 1]?.otherOption) {
                            field.form.setFieldState(
                              `${field.path.entire}.otherBtnWrap`,
                              curField => {
                                curField?.setDisplay?.('visible');
                              },
                            );
                          }
                          field?.remove(index);
                          cModal?.destroy?.();
                        }}
                      >
                        {LangTranslate('删除')}
                      </div>
                    </div>
                  ),
                });
              }}
            >
              <SvgIcon type="icon-shanchu" size={16} pointer />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

const OptionItemCell = observer(({ value }) => {
  const field = useField();
  const { hasAnother = true } = field?.componentProps;
  const doReorder = (list, originLoc, newLoc) => {
    const newLit = insertElement(list, originLoc, newLoc);
    field.setValue(newLit);
  };

  return (
    <div className="pagelet-label-system-option-item-cell-wrap">
      <div className="pagelet-label-system-option-item-cell-content">
        <DragSortWrap
          doReorder={doReorder}
          idPrefix={field.path.entire}
          itemRender={itemRender}
          list={cloneDeep(field.value)}
        />

      </div>
      {!field.readPretty && (
        <div className="pagelet-label-system-option-item-cell-footer-btns-wrap">
          <div
            className="pagelet-label-system-option-item-cell-footer-btn"
            onClick={() => {
              if (!!field?.value?.[field?.value?.length - 1]?.otherOption) {
                field?.insert?.(field?.value?.length - 1, {});
              } else {
                field?.push?.({});
              }
            }}
          >
            <div className="pagelet-label-system-option-item-cell-footer-btn-icon">
              <SvgIcon type="icon-add" size={16} pointer />
            </div>
            <div>{LangTranslate('添加选项')}</div>
          </div>
          {!!hasAnother && (
            <>
              <VoidField name="otherBtnWrap">
                <div className="split-line" />
                <div
                  className="pagelet-label-system-option-item-cell-footer-btn"
                  onClick={() => {
                    field?.push?.({ otherOption: 1, name: '其他' });
                  }}
                >
                  <div className="pagelet-label-system-option-item-cell-footer-btn-icon">
                    <SvgIcon type="icon-add" size={16} pointer />
                  </div>
                  <div>{LangTranslate('添加「其他」选项')}</div>
                </div>
              </VoidField>
            </>
          )}
        </div>
      )}
    </div>
  );
});

export default OptionItemCell;
