import React, { useState, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import classnames from 'classnames';
import { Spin } from 'antd';
import {
  getSubject,
  getSubjectAll,
  getSubjectPreview,
  getSubjectPreviewFromSetting
} from '../../utils/Tool';
import RadioWidget from '../radioWidget';
import MultiWidget from '../multiWidget';
import TextareaWidget from '../textareaWidget';
import RateWidget from '../rateWidget';
import RateWidgetMobile from '../rateWidgetMobile';
import RadioWidgetMobile from '../radioWidgetMobile';
import MultiWidgetMobile from '../multiWidgetMobile';
import TextareaWidgetMobile from '../textareaWidgetMobile';
import RadioCombineWidget from '../radioCombineWidget';
import RadioCombineQuestionWidget from '../radioCombineQuestionWidget';
import TextareaCombineWidget from '../textareaCombineWidget';
import TextareaCombineWidgetMobile from '../textareaCombineWidgetMobile';
import RadioCombineWidgetMobile from '../radioCombineWidgetMobile';
import RadioCombineQuestionWidgetMobile from '../radioCombineQuestionWidgetMobile';
import './index.scss';
import {
  getQuestionData,
  updateLoadStatus,
  getProcessRate,
  updateFetchMore,
  updateLoadAll,
  getSubjectIndex
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const renderWidget = props => {
  const { pageDataAll, itemType, multi, renderType, reviewType, respondentTypeCodePreview, isMobile = false } = props;
  // 每次请求5个块的数据
  const limitCount = $CONFIG.customProps.groupCount ? $CONFIG.customProps.groupCount : 5;
  const [nextIndex, setNextIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // 判断当前问卷是否为预览
  const [isPreView, setIsPreView] = useState(false);

  const dispatch = useDispatch();

  const {
    attributeData,
    subjectsDataGroupList,
    respondentTypeCode,
    currentLang,
    activityId,
    loading,
    respondentsGroup,
    loadAll,
    participantWorkNo,
    surveyBusType,
  } = useSelector(state => state.questionContent);

  const respondentsGroupCode = respondentsGroup?.map(item => item.respondentCode);
  const {
    questionnaireSubjectVersionIds,
    questionnaireVersionId,
    questionnaireId,
    subjectCount,
    showQuestionnaireSubjectIds
  } = pageDataAll;

  useDeepCompareEffect(() => {
    // 用户侧问卷，按块获取题目数据
    if (renderType === 1 && reviewType === 2) {
      setIsPreView(false);
      fetchMore();
    }

    // 问卷库预览，按块获取题目数据
    if (renderType === 1 && reviewType === 1) {
      setIsPreView(true);
      fetchMore('preview');
    }
  }, [pageDataAll]);

  // 一次性获取所有题目数据
  useEffect(async () => {
    if (renderType === 2) {
      const { questionnaireVersionId } = pageDataAll;
      const { data } = await getSubjectAll(currentLang, questionnaireVersionId, respondentTypeCode, activityId);
      data.map(item => {
        dispatch(getQuestionData({ data: item }));
      });
    }
  }, [respondentTypeCode]);

  useEffect(() => {
    if (Object.keys(attributeData).length > 0) {
      dispatch(updateLoadStatus({ data: false }));
    }

    if (Object.keys(attributeData).length === subjectCount) {
      dispatch(updateLoadAll({ data: true }));
    }
  }, [attributeData]);

  const handleSubject = async (type, item) => {
    // 问卷预览
    if (type) {
      // 问卷库渲染块 问卷预览
      if (showQuestionnaireSubjectIds) {
        const { data } = await getSubjectPreview(currentLang, item, questionnaireId, pageDataAll?.versionCode, limitCount);
        return { data };
      }
      // 活动配置侧渲染块 问卷预览
      const { data } = await getSubjectPreviewFromSetting(activityId, participantWorkNo, currentLang, questionnaireVersionId, item, respondentTypeCodePreview, limitCount, pageDataAll?.versionCode );
      return { data };
    }

    // 用户侧渲染
    const { data, extraData } = await getSubject(currentLang, item, questionnaireVersionId, respondentTypeCode, activityId, respondentsGroupCode?.join(','), limitCount);
    return { data, extraData };
  };

  const fetchMore = async type => {
    if (nextIndex !== 0) {
      dispatch(updateFetchMore({ data: true }));
    }
    // 按组加载 start
    const questionnaireSubjectIdsSet = showQuestionnaireSubjectIds || questionnaireSubjectVersionIds;

    if (questionnaireSubjectIdsSet?.length) {
      const item = questionnaireSubjectIdsSet[nextIndex];

      const { data, extraData } = await handleSubject(type, item);
      data.map(detail => {
        dispatch(getQuestionData({ data: detail, fetchMore: true }));
      });

      setNextIndex(nextIndex + limitCount);
      dispatch(getProcessRate({ data: extraData }));

      if (!((nextIndex + limitCount) < questionnaireSubjectIdsSet.length)) {
        setHasMore(false);
      }
    } else {
      dispatch(updateLoadStatus({ data: false }));
    }
    // 重置为 false ，保证下次点击的时候，fetchMore 为 false, 执行联动隐藏题逻辑
    if (nextIndex !== 0) {
      dispatch(updateFetchMore({ data: false }));
    }

    // 按组加载 end
  };

  const renderQuestionaireWidget = () => (
    subjectsDataGroupList.map(group => {
      const noHidden = group.filter(item => !attributeData[item?.id]?.hidden)?.length === 0;
      return (
        <div
          key={`${group[0].id}groupDiv`}
          className={classnames(`widget-group-${itemType}`, {
            'widget-group-hidden': noHidden
          })}
        >
          {
            group.map(item => {
              dispatch(getSubjectIndex({ data: item.id }));
              if (item.type === 1 || item.type === 10) {
                return (
                  itemType === 1 ? <RadioWidget widgetKey={item.id} key={`${item.id}radioWidget`} />
                    : <RadioWidgetMobile widgetKey={item.id} key={`${item.id}radioWidget`} />
                );
              }

              if (item.type === 2) {
                return (
                  itemType === 1 ? <MultiWidget widgetKey={item.id} key={`${item.id}multiWidget`} />
                    : <MultiWidgetMobile widgetKey={item.id} key={`${item.id}multiWidget`} />
                );
              }

              if (item.type === 3) {
                return (
                  itemType === 1 ? <TextareaWidget widgetKey={item.id} key={`${item.id}textareaWidget`} />
                    : <TextareaWidgetMobile widgetKey={item.id} key={`${item.id}textareaWidget`} />
                );
              }
              if (item.type === 6) {
                return (
                  itemType === 1 ? <RateWidget widgetKey={item.id} key={`${item.id}rateWidget`} />
                    : <RateWidgetMobile widgetKey={item.id} key={`${item.id}rateWidgetMobile`} />
                );
              }
              return '';
            })
          }
        </div>
      );
    })
  );

  const renderMultiQuestionaireWidget = () => (
    subjectsDataGroupList.map(group => (
      <div
        key={`${group[0].id}groupDiv`}
        className={`widget-group-${itemType}`}
      >
        {
          group.map(item => {
            // 1
            if (item.type === 1 || item.type === 10) {
              return (
                itemType === 1 ? <RadioCombineWidget widgetKey={item.id} key={`${item.id}radioWidget`} />
                  : <RadioCombineWidgetMobile widgetKey={item.id} key={`${item.id}radioWidget`} />
              );
            }

            // 3
            if (item.type === 3) {
              return (
                itemType === 1 ? <TextareaCombineWidget widgetKey={item.id} key={`${item.id}textareaWidget`} />
                  : <TextareaCombineWidgetMobile widgetKey={item.id} key={`${item.id}textareaWidget`} />
              );
            }
            return '';
          })
        }
      </div>
    ))
  );

  const renderEnpsQuestionaireWidget = () => (
    subjectsDataGroupList.map(group => {
      const noHidden = group.filter(item => !attributeData[item?.id]?.hidden)?.length === 0;
      return (
        <div
          key={`${group[0].id}groupDiv`}
          className={classnames(`widget-group-${itemType}`, {
            'widget-group-hidden': noHidden
          })}
        >
          {
            group.map((item, j) => {
              dispatch(getSubjectIndex({ data: item.id }));
              // console.log(item, attributeData[item.id],'item')
              if (item.type === 1 || item.type === 10) {
                return (
                  itemType === 1 ? <RadioWidget widgetKey={item.id} key={`${item.id}radioWidget`} />
                    : <RadioWidgetMobile widgetKey={item.id} key={`${item.id}radioWidget`} />
                );
              }

              if (item.type === 2) {
                return (
                  itemType === 1 ? <MultiWidget widgetKey={item.id} key={`${item.id}multiWidget`} />
                    : <MultiWidgetMobile widgetKey={item.id} key={`${item.id}multiWidget`} />
                );
              }

              if (item.type === 3) {
                return (
                  itemType === 1 ? <TextareaWidget widgetKey={item.id} key={`${item.id}textareaWidget`} />
                    : <TextareaWidgetMobile widgetKey={item.id} key={`${item.id}textareaWidget`} />
                );
              }
              if (item.type === 6) {
                return (
                  itemType === 1 ? <RateWidget widgetKey={item.id} key={`${item.id}rateWidget`} />
                    : <RateWidgetMobile widgetKey={item.id} key={`${item.id}rateWidgetMobile`} />
                );
              }

              if (item.type === 50) {
                return (
                  itemType === 1 ? <RadioCombineQuestionWidget widgetKey={item.id} key={`${item.id}radioCombineQuestionWidget`} />
                    : <RadioCombineQuestionWidgetMobile widgetKey={item.id} key={`${item.id}radioCombineQuestionWidgetMobile`} />
                );
              }

              return '';
            })
          }
        </div>
      );
    })
  );

  const renderWidgetType = () => {
    if (multi === 1) {
      return renderQuestionaireWidget();
    }
    if (multi === 2) {
      return renderMultiQuestionaireWidget();
    }
    if (multi === 3) {
      return renderEnpsQuestionaireWidget();
    }
    return '';
  };

  const handleInfiniteOnLoad = () => {
    if (!loadAll) {
      fetchMore(isPreView ? 'preview' : null);
    }
  };

  const renderLoader = () => (
    <div className="render-loader">
      <Spin />
    </div>
  );

  // console.log('subjectsDataGroupList=====', subjectsDataGroupList);
  return (
    <>
      {/* 需要等待题目属性初始化完毕之后再渲染组件，因为题目初始化包括题目数据结构初始化 */}
      {!loading
        ? (
          <div
            className={
              surveyBusType === 'NON_PROFESSIONAL_SERVICES' && !isMobile
                ? subjectsDataGroupList?.length > 0 && 'render-warpper-blue'
                : 'render-warpper'
            }
          >
            <InfiniteScroll
              dataLength={subjectsDataGroupList.length + 4}
              next={handleInfiniteOnLoad}
              hasMore={hasMore}
            // onScroll={handleOnScoll}
              loader={loading && renderLoader()}
              style={{ overflow: 'unset' }}
            >
              {renderWidgetType()}
            </InfiniteScroll>
          </div>
        )
        : ''}
    </>
  );
};

export default renderWidget;
