import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';

import { Tooltip } from 'antd';
import { createForm, onFieldInputValueChange, onFieldValueChange } from '@formily/core';
import { FormProvider, Field, ObjectField, useField, observer } from '@formily/react';
import { FormItem, Space, FormLayout } from '@formily/antd-v5';
import CustomPanelFieldsComponent from '@/cpns/CustomPanelFieldsComponent';

import { getObjectAndDimsTree } from '@/services';

import { BricksInfoContext } from '@/context';
import { useRequest } from 'ahooks';
import './index.less';

function TagPoolIInfoPen(props) {
  const { title = '', titleTips = '', fieldsList, fieldName = 'panelInfos', panelFormRef } = props;
  const BricksInfoContextVal = useContext(BricksInfoContext);
  const ggFlag = useRef(false);

  const {
    data,
    runAsync: runGetNewOptions,
    loading: getNewOptionsLoading,
  } = useRequest(
    async params => {
      try {
        console.log(
          'BricksInfoContext?.usePermissionResponse,',
          BricksInfoContext?.usePermissionResponse,
        );
        const res = await getObjectAndDimsTree({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: { ...params, tagFlag: false },
          usePermissionResponse: BricksInfoContext?.usePermissionResponse,
        });
        return [
          res?.data?.subjectDtoList || [],
          res?.data?.subjectDtoList?.map(it => ({
            name: it?.name,
            id: it?.id,
            disabled: true,
            subDimensionList: it?.subDimensionList,
          })),
        ];
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContext] },
  );

  const form = useMemo(() => {
    const form = createForm({
      effects() {
        onFieldValueChange('panelInfos.subjectId', async field => {
          const dimsVal = form.getValuesIn('panelInfos.dimensionId');

          if (!field?.value?.length) {
            const [_, dimsTree] = await runGetNewOptions({});
            form.setFieldState('panelInfos.dimensionId', dimsField => {
              dimsField.setComponentProps({
                treeData: dimsTree,
              });
            });
            return;
          }
          if (dimsVal?.length && field.selfModified) {
            form.setFieldState('panelInfos.dimensionId', dimsField => {
              dimsField?.setValue(undefined);
              ggFlag.current = true;
            });
          }

          const [_, dimsTree] = await runGetNewOptions({ tagSubjectIdList: [field?.value] });
          form.setFieldState('panelInfos.dimensionId', dimsField => {
            dimsField.setComponentProps({
              treeData: dimsTree,
            });
          });
        });
        onFieldValueChange('panelInfos.dimensionId', async field => {
          const subjectVal = form.getValuesIn('panelInfos.subjectId');
          if (!ggFlag.current) {
            if (!!subjectVal?.length && !!field?.value?.length && !field?.selfModified) {
              return;
            }
          } else {
            const subjectValTmp = form.getValuesIn('panelInfos.subjectId');
            form.setValuesIn('panelInfos.subjectId', '');
            form.setValuesIn('panelInfos.subjectId', subjectValTmp);
            ggFlag.current = false;
          }

          if (!field?.value?.length) {
            const [newSubjectIdList] = await runGetNewOptions({});

            form.setFieldState('panelInfos.subjectId', subjectField => {
              subjectField.setComponentProps({ options: newSubjectIdList || [] });
            });
            return;
          }

          const [newSubjectIdList] = await runGetNewOptions({
            tagDimensionIdList: [field?.value],
          });

          form.setFieldState('panelInfos.subjectId', subjectField => {
            subjectField.setComponentProps({ options: newSubjectIdList || [] });
          });
        });
      },
    });

    panelFormRef.current = form;
    return form;
  }, []);

  useEffect(() => {
    if (getNewOptionsLoading) {
      form.setFieldState('panelInfos.*(subjectId,dimensionId)', field => {
        field.setComponentProps({ loading: getNewOptionsLoading });
      });
    } else {
      form.setFieldState('panelInfos.*(subjectId,dimensionId)', field => {
        field.setComponentProps({ loading: getNewOptionsLoading });
        field.setComponentProps({
          treeDefaultExpandAll: true,
        });
      });
    }
  }, [getNewOptionsLoading]);

  useEffect(() => {
    form.setFieldState(`${fieldName}`, field => {
      field?.setComponentProps({
        fieldsList,
      });
    });
  }, [form, fieldsList]);

  return (
    <div className="pagelet-label-system-custom-info-panel-wrap">
      <div className="pagelet-label-system-custom-info-panel-head">
        <div className="pagelet-label-system-custom-info-panel-title">{title}</div>
      </div>
      <div className="pagelet-label-system-custom-info-panel-form-fields-wrap">
        <FormProvider form={form}>
          <FormLayout layout={['vertical']} labelAlign={['left']}>
            <ObjectField name={fieldName} component={[CustomPanelFieldsComponent]} />
          </FormLayout>
        </FormProvider>
      </div>
    </div>
  );
}

export default TagPoolIInfoPen;
