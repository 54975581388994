import React, { useState, useEffect, useRef } from "react";
import { di18n } from "@ultra/common";
import classnames from "classnames";
import { getLocale, setLocale } from "@didi/di18n-react";
import SuspendButton from "../../components/SuspendButton";
import {
  setCookie,
  getCookie,
  setEncode,
  sendAnswerBySubject,
  getProcessAll,
  getPage,
  getLayout,
  sendAnswer,
  getActivityDetail,
  getKey,
  getSurveyInfo,
  getQuesDateFn,
  networkOutage,
  getErrorLocationSort,
} from "../../utils/Tool";
import { cloneDeep } from 'lodash';
import { Modal, Toast, Progress, Button } from "antd-mobile";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import Introduction from "../../components/Introduction";
import RenderWidget from "../../components/renderWidget";
import "./index.scss";
import soSmallheart from "../../images/footremarkheart.svg";
import { getHideSubjectIds } from '../../utils/questionnaire';

import {
  getRespondentCode,
  setRespondentTypeCode,
  getProcessRate,
  updateValidateFlag,
  updateCurrentRespondentCode,
  setCurrentLang,
  setActivityId,
  updateCurrentSubject,
  setErrorListSoreMap,
  hiddenAllAttributeData,
  setSurveyBusType,
  updateFetchMore
} from "../../store/questionnaireNew/questionContentSlice"; // 引入actions
const alert = Modal.alert;
const succeed = `${location.origin}/questionnaire/success`;
const { intl } = di18n;
var saveTimer = null;
let timestamp;
export default function AssessedQuestionnaireH5(props) {
  const dispatch = useDispatch();
  const loadTimer = useRef(false);
  const [activityStatus, setActivityStatus] = useState(null);
  const [pageRemark, setPageRemark] = useState("");
  const [pageRemarkStyle, setPageRemarkStyle] = useState("");
  const [pageFooting, setPageFooting] = useState("");

  const [langKeyList, setLangKeyList] = useState([]);
  // 判断当前问卷是否已经提交
  const [pageSubmitStatus, setPageSubmitStatus] = useState(true);
  const [dataStatus, setDataStatus] = useState(true);
  const [encodeFlag, setEncodeFLag] = useState();

  // 当前问卷只能有唯一的key
  const currentPageKey = useRef("");

  // 2.0新增
  const [pageDataAll, setPageDataAll] = useState();

  // 区别单人问卷和多人问卷, 1:单人 2:合并 3:调研问卷
  const [questionaireType, setQuestionaireType] = useState();
  // 区别单人问卷和多人问卷的展示, 1:单人 2:合并 3:调研问卷
  const [questionaireTypeShow, setQuestionaireTypeShow] = useState();

  const [showErrorIcon, setShowErrorIcon] = useState(false);

  // 提交问卷加loading
  const [spinning, setSpinning] = useState(false);

  const { activityId, questionnaireId, respondentCode, index } =
    props.match.params;

  // 存在其他选项题型时未失焦点提交，有200ms的延时，导致数据不准确 提交时等数据更新再触发提交
  const errors = useRef({
    errorList: {},
    errorListMap: [],
    generalMultiData: null,
  });
  const {
    processRate,
    generalMultiData,
    respondentsGroup,
    respondentTypeCode,
    errorList,
    initRequired,
    loading,
    currentLang,
    lanMap,
    currentSubject,
    currentRespondentCode,
    loadAll,
    respondentCodeArr,
    validateFlag,
    errorListMap,
    validateResult,
    errorListSoreMap,
    // 修改的题目Id
    cancelTokenQesId,
    attributeData,
    surveyBusType,
    prevOptionIds,
    fetchMore
  } = useSelector((state) => state.questionContent);

  // 根据不同的主题给类名
  const getClassNames = () => {
    let prefixCls = '';
    let mainPrefixCls = '';
    switch (surveyBusType) {
      case 'PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
      case 'NON_PROFESSIONAL_SERVICES':
        prefixCls = 'question-main-banner-mobile question-main-banner-mobile-blue';
        mainPrefixCls = 'flex questionnaire-main questionnaire-main-blue';
        break;
      default:
        prefixCls = 'question-main-banner-mobile';
        mainPrefixCls = 'flex questionnaire-main';
        break;
    }

    return {
      prefixCls,
      mainPrefixCls,
    };
  };

  // h5 cookie失效拦截
  const encodeFilter = (data) => {
    if (data === "699999") {
      alert(
        intl.t("提示"),
        intl.t("检测到您长时间未填写问卷，已与服务器断开，请刷新后再填问卷！"),
        []
      );
      return;
    }
  };

  useEffect(() => {
    errors.current = {
      ...errors.current,
      errorList,
      errorListMap,
      generalMultiData,
    };
  }, [errorList, errorListMap, generalMultiData])

  useEffect(() => {
    if (initRequired === -1 && currentSubject) {
      getModelData(1);
    }
  }, [generalMultiData]);

  const title = useRef("");
  useEffect(async () => {
    const ctLang = getCookie("ct-lang");
    const lang = getLocale("lang");
    if (ctLang) {
      if (lang !== ctLang) {
        setLocale(ctLang, {
          cookieLocaleKey: "lang",
        });
      }
    }
    const current = ctLang ? ctLang : lang;
    let currentMap = 0;
    lanMap.map((item, i) => {
      if (item === current) {
        currentMap = i;
      }
    });
    dispatch(setCurrentLang({ data: currentMap }));
    // 进入页面先获取当前问卷的主题：PROFESSIONAL_SERVICES-暖橙色；NON_PROFESSIONAL_SERVICES-科技蓝
    const {data: {surveyBusType}} = await getSurveyInfo(activityId);
    dispatch(setSurveyBusType({ data: surveyBusType }));

    const { data } = await setEncode({ encodeText: activityId, busType: 2 });
    if (data) {
      setEncodeFLag(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      dispatch(setActivityId({ data: activityId }));
      let avaliableLan = currentLang;
      if (questionnaireId && activityId && currentLang > -1 && encodeFlag) {
        // 获取侧边问卷名称及状态，传给process组件渲染侧边栏问卷名称
        const activity = await getActivityDetail(activityId);
        encodeFilter(activity?.code);
        setActivityStatus(activity?.data?.activityStatus);

        let questionaireFlag;
        // 判断问卷类型
        if (activity?.data) {
          switch (activity?.data?.questionnaireLayoutType) {
            case 0: // 单人问卷
              setQuestionaireType(1);
              questionaireFlag = 1;
              break;
            case 1: // 合并问卷
              setQuestionaireType(2);
              questionaireFlag = 2;
              break;
            case 2: // 调研问卷
              setQuestionaireType(3);
              setQuestionaireTypeShow(3);
              dispatch(updateCurrentRespondentCode({ data: respondentCode }));
              questionaireFlag = 3;
              break;
            default:
              console.log("undefined questionnaireLayoutType");
          }
        }

        // ！！！如果为合并问卷，则需要判断respondentGroup的索引值
        // 修改索引
        const activityData = activity.data.respondentGroup[index];
        dispatch(
          setRespondentTypeCode({ data: activityData.respondentTypeCode })
        );

        let respondentParam;
        let lanType;
        if (questionaireFlag === 1) {
          if (activityData.contentInfos.length === 0) {
            props.history.replace(`/h5/navigation/${activityId}`);
            return;
          }
          activityData.contentInfos.map((item, i) => {
            const itemRespondentCode =
              item.activityRespondents[0].respondentCode;
            if (itemRespondentCode === respondentCode) {
              respondentParam =
                activityData.contentInfos[i].activityRespondents;
              lanType = activityData.contentInfos[i].languageTypes;
            }
          });
        }
        if (questionaireFlag === 2) {
          if (activityData.contentInfos.length === 0) {
            props.history.replace(`/navigation/h5/${activityId}`);
            return;
          }
          respondentParam = activityData.contentInfos[0].activityRespondents;
          lanType = activityData.contentInfos[0]?.languageTypes;
        }
        if (questionaireFlag === 3) {
          respondentParam = activityData.contentInfos[0].activityRespondents;
          lanType = activityData.contentInfos[0]?.languageTypes;
        }

        // 处理respondentCode

        dispatch(getRespondentCode({ data: respondentParam }));
        if (questionaireFlag !== 3) {
          if (respondentParam && respondentParam.length > 1) {
            setQuestionaireTypeShow(2);
          } else {
            setQuestionaireTypeShow(1);
            dispatch(updateCurrentRespondentCode({ data: respondentCode }));
          }
        }
        // 判断当前问卷支持的语言与cookie里存的是否匹配，如果不匹配就把cookie切成问卷返回的第一个语言
        if (!lanType?.includes(currentLang)) {
          if (lanType?.includes(1)) {
            // 若问卷包含英文则请求英文问卷
            avaliableLan = 1;
          } else {
            // 若无英文，则请求问卷的第一个语言
            avaliableLan = lanType?.[0];
          }
          setLocale(lanMap[avaliableLan], {
            cookieLocaleKey: "lang",
          });
          setCookie("ct-lang", lanMap[avaliableLan]);
          dispatch(setCurrentLang({ data: avaliableLan }));
        }

        //const questionnaireId = activityData.contentInfos[0].contentId;
        //const questionnaireId = '107065948376158208'
        const { data } = await getPage(
          avaliableLan,
          questionnaireId,
          activityId,
          activityData.respondentTypeCode
        );
        setPageDataAll(data);

        if (activity.data?.activityStatus !== 1) {
          // 如果活动状态不为1，则弹出提示活动未开始或已经结束
          props.history.push(`/activity/not/started`);
          return;
        }

        // 获取问卷进度
        const process = await getProcessAll(
          activityId,
          activityData.respondentTypeCode
        );

        const processData = process.data;
        if (questionaireFlag === 1) {
          for (let item in processData) {
            if (
              processData[item].questionnaireId === questionnaireId &&
              processData[item].respondentCode === respondentCode
            ) {
              if (processData[item].recycleStatus === 2) {
                setSubmitStatusFn(questionaireFlag);
                return;
              } else {
                setPageSubmitStatus(false);
              }
            }
          }
        } else {
          if (processData[0].groupRecycleStatus === 2) {
            setSubmitStatusFn(questionaireFlag);
            return;
          } else {
            setPageSubmitStatus(false);
          }
        }
      }
      // 获取渲染题目数据
      let layout;
      layout = await getLayout(questionnaireId);

      const layoutDataAll = layout.data;
      let layoutDataAllKeyList = [];
      layoutDataAll &&
        layoutDataAll.map((item) => {
          layoutDataAllKeyList.push(item.languageType);
        });
      setLangKeyList(layoutDataAllKeyList);
      for (let item in layoutDataAll) {
        if (layoutDataAll[item].languageType === avaliableLan) {
          const layoutData = layoutDataAll[item];
          title.current = layoutData.styleName || layoutData.name;
          setPageRemark(layoutData.remark);
          setPageRemarkStyle(layoutData.styleRemark);
          setPageFooting(layoutData.pageFooting);
          setDataStatus(false);
        }
      }
    })();
  }, [questionnaireId, activityId, currentLang, encodeFlag]);

  const showAlert = (type) => {
    const alertInstance = alert(
      "",
      intl.t("是否确认提交问卷，提交后无法修改？"),
      [
        {
          text: (
            <span style={{ color: "#000000", opacity: 0.6 }}>
              {intl.t("回去改改")}
            </span>
          ),
          onPress: () => console.log("cancel"),
          style: "default",
        },
        {
          text: intl.t("确定"),
          onPress: () => {
            if (loadTimer.current === false) {
              loadTimer.current = true;
              handleSubmit(type);
              setTimeout(() => {
                loadTimer.current = false;
              }, 3000);
            }
          },
        },
      ]
    );
    setTimeout(() => {
      // 可以调用close方法以在外部close
      alertInstance.close();
    }, 500000);
  };

  const handleSubmit = (v) => {
    getModelData(v);
  };

  // 保存草稿，单题提交
  const setDraft = async () => {
    let subjectDraft = {};
    for (let i in generalMultiData) {
      subjectDraft[i] = {};
      subjectDraft[i][currentSubject] = generalMultiData[i][currentSubject];
    }

    const draftData = {
      activityId,
      questionnaireId,
      respondentTypeCode,
      subjects: subjectDraft,
    };
    // 弱网时 通过cancelTokenQesId  拼接save队列名称
    if (cancelTokenQesId) {
      draftData.cancelTokenQesId = cancelTokenQesId;
    }

    if (questionaireTypeShow !== 2) {
      // 找到需要联动隐藏的id
      const { hideSubjectIds = [], cloneData } = getHideSubjectIds({
        generalMultiData, attributeData, currentSubject, prevOptionIds, currentRespondentCode
      });
      hideSubjectIds.forEach(item => {
        cloneData[item].hidden = true;
      });
      dispatch(updateFetchMore({ data: false }));
      if (!fetchMore) {
        dispatch(hiddenAllAttributeData({ list: hideSubjectIds, respondentTypeCode, cloneData }));
      }
    }

    const { data, code } = await sendAnswerBySubject(draftData);
    encodeFilter(code);
    dispatch(getProcessRate({ data }));
    dispatch(updateCurrentSubject({ data: '' }));
  };

  // 提交问卷，提交所有题目数据
  const setAnswer = async () => {
    const { errorList, generalMultiData } = errors.current;
    dispatch(updateValidateFlag({ data: true }));
    setSpinning(true);
    if (errorList.widget === 0 && errorList.other === 0) {
      if (initRequired === -1 || processRate?.progressRate !== 0) {
        const keyData = await getKey(
          activityId,
          1,
          respondentCodeArr.toString(),
          respondentTypeCode
        );
        if (keyData.code === "100200") {
          const { data, code } = await sendAnswer(
            activityId,
            generalMultiData,
            questionnaireId,
            respondentTypeCode
          );
          encodeFilter(code);
          if (data === "success") {
            setSubmitStatusFn();
            return;
          }
        }
      } else {
        let showNum;
        if (processRate?.progressRate === 0 && initRequired !== -1) {
          // 进度为0，说明未填写，则取初始化的必填数量
          showNum = initRequired;
        } else {
          showNum = errorList.widget + errorList.other;
        }
        Toast.offline(
          intl.t("还有{num}个题目未填写", { num: showNum }),
          2,
          () => {
            setSpinning(false);
            setShowErrorIcon(true);
          }
        );
        return;
      }
    } else {
      let showNum;
      if (processRate?.progressRate === 0 && initRequired !== -1) {
        // 进度为0，说明未填写，则取初始化的必填数量
        showNum = initRequired;
      } else {
        // showNum = errorList.widget === 0 ? errorList.other : errorList.widget;
        showNum = errorList.widget + errorList.other;
      }
      Toast.offline(
        intl.t("还有{num}个题目未填写", { num: showNum }),
        2,
        () => {
          setSpinning(false);
          setShowErrorIcon(true);
        }
      );
      return;
    }
  };

  // 点击提交、保存时做的数据校验和数据提交 1：保存 2：提交
  const getModelData = async (submitType) => {
    if (submitType === 1) {
      setDraft();
    }
    if (submitType === 2) {
      setAnswer();
    }
  };

  function componentWillUnmount() {
    clearInterval(saveTimer);
  }
  useEffect(() => componentWillUnmount(), []);

  useEffect(() => {
    if (
      activityId &&
      questionnaireId &&
      respondentCode &&
      currentPageKey &&
      pageSubmitStatus &&
      respondentTypeCode
    ) {
      const keyTimer = setInterval(async () => {
        const getQuesDate = await getQuesDateFn(
          activityId,
          questionnaireId,
          respondentCode,
          currentPageKey.current,
          respondentTypeCode
        );
        // getVersionNumber接口 异常处理
        const networkNumber = networkOutage(getQuesDate);
        encodeFilter(getQuesDate.code);
        if (networkNumber && getQuesDate.data) {
          if (currentPageKey.current) {
            if (getQuesDate.data !== String(currentPageKey.current)) {
              clearInterval(keyTimer);
              clearInterval(saveTimer);
              alert(
                intl.t("提示"),
                intl.t(
                  "您有新打开的问卷页面，当前页面已失效，请关闭后至新页面进行填写"
                ),
                []
              );
              return;
            } else {
              if (pageSubmitStatus && pageSubmitStatus === 2) {
                clearInterval(keyTimer);
              }
            }
          } else {
            currentPageKey.current = getQuesDate.data;
          }
        }
      }, 3000);
    }
  }, [
    activityId,
    questionnaireId,
    respondentCode,
    pageSubmitStatus,
    respondentTypeCode,
  ]);

  const goback = (val) => {
    if (val) {
      if (val === 1) {
        location.replace(
          `${location.origin}/questionnaire/h5/navigation/${activityId}/`
        );
        return;
      }
      if (val === 2) {
        location.replace(
          `${location.origin}/questionnaire/navigation/h5/${activityId}/`
        );
        return;
      }
      if (val === 3) {
        window.location.replace(succeed);
        return;
      }
    } else {
      window.location.replace(succeed);
      return;
    }
  };

  function setSubmitStatusFn(val) {
    if (questionaireType === 1) {
      location.replace(
        `${location.origin}/questionnaire/h5/navigation/${activityId}/`
      );
      return;
    }
    if (questionaireType === 2) {
      location.replace(
        `${location.origin}/questionnaire/navigation/h5/${activityId}/`
      );
      return;
    }
    if (questionaireType === 3) {
      location.replace(`${succeed}?id=${activityId}`);
      return;
    }
    // 已提交问卷点回退按钮场景
    if (!questionaireType) {
      goback(val);
      return;
    }
  }

  const renderResult = () => {
    // console.log(questionaireTypeShow, 'questionaireTypeShow')
    if (questionaireTypeShow === 1) {
      return renderQuestionaire();
    }

    if (questionaireTypeShow === 2) {
      return renderMultiQuestionaire();
    }

    if (questionaireTypeShow === 3) {
      return renderEnpsQuestionaire();
    }
  };

  const renderQuestionaire = () => {
    return (
      <>
        {pageDataAll ? (
          <RenderWidget
            pageDataAll={pageDataAll}
            itemType={2}
            multi={1}
            reviewType={2}
            renderType={1}
            isMobile
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const renderMultiQuestionaire = () => {
    return (
      <>
        {pageDataAll ? (
          <RenderWidget
            pageDataAll={pageDataAll}
            itemType={2}
            multi={2}
            reviewType={2}
            renderType={1}
            isMobile
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const renderEnpsQuestionaire = () => {
    return (
      <>
        {pageDataAll ? (
          <RenderWidget
            pageDataAll={pageDataAll}
            itemType={2}
            multi={3}
            reviewType={2}
            renderType={1}
            isMobile
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const renderErrorIcon = () => {
    return (
      <SuspendButton
        number={errorList.widget + errorList.other}
        onClick={handleError}
      ></SuspendButton>
    );
  };
  useEffect(() => {
    if (validateFlag) {
      const { errorListMap } = errors.current;
      if (errorListMap && errorListMap.length) {
        getErrorListSort();
      } else {
        dispatch(setErrorListSoreMap({ data: [] }));
      }
    }
  }, [errorListMap, validateFlag]);

  let getErrorListSort = async () => {
    const { errorListMap } = errors.current;
    timestamp = Date.now();
    const { data } = await getErrorLocationSort(
      questionnaireId,
      respondentTypeCode,
      errorListMap,
      timestamp
    );
    if (timestamp == data.timestamp) {
      dispatch(setErrorListSoreMap({ data: data.orderSubjects }));
    }

    setSpinning(false);
  };
  const handleError = () => {
    // 合并问卷增加历史页面，为了浏览器退回操作，PS:时间紧迫，暂时没有更好的方法，玉燕同学后续可以继续调研
    // if(questionaireType === 2){
    //   const pushStateTimeout = setTimeout(() => {
    //     let href = location.href
    //     window.history.pushState(null, '', `${location.origin}/questionnaire/navigation/h5/${activityId}`)
    //     window.history.pushState(null, '', `${href}#${errorListMap[0]}`)
    //     window.location.href=`#${errorListMap[0]}`
    //     clearTimeout(pushStateTimeout)
    //   }, 200)
    // } else {
    //   window.location.href=`#${errorListMap[0]}`
    // }
    document.querySelector(`#id_${errorListSoreMap[0]}`).scrollIntoView({
      block: 'center',
      smooth: 'smooth',
    });
  };

  return (
    <>
      {!dataStatus ? (
        <Spin
          spinning={loading}
          tip={intl.t("问卷加载中，请稍后…")}
          wrapperClassName="w-full"
        >
          {validateFlag && showErrorIcon && errorListMap.length > 0
            ? renderErrorIcon()
            : ""}
          <div className="w-full flex">
            <div className={surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? "question-main-blue" : "question-main"}>
              <Progress
                percent={processRate?.progressRate}
                position="fixed"
                barStyle={{
                  borderWidth: "7px 3px 0 3px",
                  borderImage: surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? "#0A70F5" : "linear-gradient(to right,rgb(4, 192, 116), rgb(15, 145, 89)) 1 3",
                  height: "7px",
                  borderRadius: "0 3px 3px 0",
                }}
                style={{ height: "7px" }}
              />
              {
                processRate?.progressRate >= 0 && (
                  <div
                    className={classnames("ct-h5-rogress-number", {
                      'ct-h5-rogress-number-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                    })}
                    style={{ left: surveyBusType === 'NON_PROFESSIONAL_SERVICES' ? `${processRate?.progressRate < 80 ? processRate?.progressRate : 80}%` : `${processRate?.progressRate < 88 ? processRate?.progressRate : 88}%` }}
                  >
                    {
                      surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                        ? `共${processRate?.allSubjectCount}题,进度${processRate?.progressRate}%`
                        : `${processRate?.progressRate}%`
                    }
                  </div>
                )
              }
              <Introduction
                title={title.current}
                questionDes={pageRemark}
                pageRemarkStyle={pageRemarkStyle}
                langKeyList={langKeyList}
                getClassNames={getClassNames()}
              />
              <Spin spinning={spinning}>{renderResult()}</Spin>
              {pageFooting ? (
                <div className="question-main-des-mobile-footer">
                  {pageFooting}
                </div>
              ) : (
                ""
              )}
              <div className={classnames("question-subumit-mobile", {
                'question-subumit-mobile-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
              })}>
                {loadAll ? (
                  <Button
                    className="question-submit-mobile-submit"
                    onClick={() => {
                      showAlert(2);
                    }}
                  >
                    {intl.t("提交")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {/* <div
                className="question-footer-concluding-remarks"
                style={{
                  textAlign: 'center',
                  background: surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                    ? 'linear-gradient(90deg, #CFE3FE 0%, #D1E3FF 5%, #D3E4FE 11%, #D9EAFF 37%, #E3F1FF 54%, #E3EEFF 70%, #DEE9FF 88%, #DDEBFE 99%)'
                    : '#FFFDFB'
                  }}
                >
                <span>{intl.t("感谢您的帮助与支持")}</span>
                <img style={{ padding: "0 0 4px 6px" }} src={soSmallheart} />
              </div> */}
            </div>
          </div>
        </Spin>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </>
  );
}
