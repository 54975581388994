import React, { useEffect, useState, useRef } from 'react';
import { getLocale, Spin, ConfigProvider, en_US, zh_CN } from '@didi/ec-base';
import { RightThickOutlinedCommonDirection } from '@didi/ec-base-icon';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as antd from 'antd';
import PageletApi from '@didi/pagelets-sdk';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import { RightThickOutlined, LeftThickOutlined} from '@didi/iconflow/ec';
import { holidayBtnConfig } from '../../utils/config.js';
import useStyle from './style';
import manifest from '../../manifest.json';
import PageletServices from './services';
import Right from './assets/right-thick.svg';
import TimeFilter from './components/TimeFilter/timeFilter.js';
import ScopeFilter from './components/ScopeFilter';
import HolidayDetails from './components/HolidayDetails';
import { timeRangeTypeList, TIME_RANGE_TYPE } from './constant.js'
const prefixCls = 'pagelet-team-holidays';
const localLocales = manifest.locales || {};

const Pagelet = (props) => {
  const {
    params: { apiHost = '//dhr-dev.intra.xiaojukeji.com', locales, item, pageCode, headers = {},
      // permissionScope,
      auth, deptCodes, deptInfos },
  } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionName: window.encodeURI(auth.functionName)
  };
  PageletApi.newRequest.init(newHeaders, '@didi/pagelet-team-holidays');
  const classes = classNames(prefixCls);
  const wrapSSR = useStyle(prefixCls);
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);

  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN');
  const [providerLocale, setProviderLocale] = React.useState({});

  const [abnormal, setAbnormal] = useState(false);//是否展示异常页面
  const [totalDays, settotalDays] = useState(0); // 全部请假总天数
  const [totalNum, settotalNum] = useState(0); // 全部请假总人数
  const [holidayLeaveList, setHolidayLeaveList] = useState([]); // 假期小类列表
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [subordinate, setSubordinate] = useState(''); // 下属类别
  const [permissionScope, setPermissionScope] = useState([]);
  const [dateList, setDateList] = useState({startDate: new Date().getTime(), endDate: new Date().getTime()});
  const [timeRangeType, setTimeRangeType] = useState(TIME_RANGE_TYPE.month);
  const [startDate, setStartDate] = useState(new Date().getTime());
  const [endDate, setEndDate] = useState(new Date().getTime());


  const openDawer = () => {
    // 弹窗
    const close = () => {
      // 点击关闭
      drawer.destroy();
    }
    const drawer = PageletApi.drawer({
      title: '',
      width: '1000px',
      rootClassName: 'pagelet-leave-summary-drawer',
      pageletName: '@didi/pagelet-leave-summary',
      // pageletUrl: '//127.0.0.1:9803/leave-summary.runtime.js', // 使用pageletUrl
      pageletProps: {
        defaultDeptCode: deptCodes,
        defaultDeptInfo: deptInfos,
        pageType: pageCode,
        subordinate,
        auth,
        timeRangeType,
        startDate,
        endDate,
        close,
        headers,
        permissionScope
      }, // 透传给pagelet的props
      onClose: close
    });
    drawer.show();
  }



  useEffect(() => {
    if (lang !== 'zh-CN') {
      setProviderLocale(en_US)
    } else {
      setProviderLocale(zh_CN)
    }
  }, [])

  const getHolidayLeaveList = () => {
    setLoading(true);
    PageletServices.postInitHoliday(apiHost, {
      deptCodes,
      startDate: dateList?.startDate,
      endDate: dateList?.endDate,
      subType: 0,
      pageNum,
      pageSize,
      searchType: subordinate,
      pageType: pageCode,
    }).then((data) => {
      if (data?.code === '100200') {
        const totalDays = data?.data?.totalDays;
        const totalNum = data?.data?.totalNum;
        const holidayList = data?.data?.holidayLeaveList;
        settotalDays(totalDays);
        settotalNum(totalNum);
        setHolidayLeaveList([...holidayList]);
      } else {
        setAbnormal(true);
      }
    }, () => {
      setAbnormal(true);
    }).finally(() => {
      setLoading(false);
    });
  }
  // 点击分页
  const onClickMoreData = (subType, pageNum) => {
    setDetailsLoading(true);
    PageletServices.postSubHoliday(apiHost, {
      deptCodes,
      startDate: dateList?.startDate,
      endDate: dateList?.endDate,
      subType: subType,
      pageNum,
      pageSize,
      searchType: subordinate,
      pageType: pageCode,
    }).then((data) => {
      const holidayInfo = [...holidayLeaveList];
      const { subName, empInfoList } = data?.data
      holidayInfo.map((v) => {
        if (v.subName === subName) {
          v.empInfoList = empInfoList
        }
      });
      setHolidayLeaveList([...holidayInfo]);
    }).finally(() => {
      setDetailsLoading(false);
    })
  }

  const initFilter = () => {
    const params = {
      // "deptCodes": deptCodes,
      "pageType": pageCode,
      // "module": 'team-holidays'
    };
    PageletServices.initFilter(apiHost, params).then((res) => {
      if (res?.code === '100200' && res?.data) {
        const list = res?.data;
        let listInfo = [];
        let subordinateInfo = '2';
        list.map((v) => {
          if (v?.selected) {
            subordinateInfo = v?.code
          }
        });
        setPermissionScope(res?.data || []);
        setSubordinate(subordinateInfo)
      } else {
        console.log('卡片初始化错误, 展示兜底卡片!!!')
      }
    }, (error) => {
      console.log('卡片初始化错误, 展示兜底卡片!!!', error)
    });
  };

  useEffect(() => {
    if (deptCodes?.length > 0) {
      initFilter();
    }
  }, [deptCodes]);

  // 接口请求示例
  useEffect(() => {
    if (pageCode && subordinate && Object.keys(dateList).length > 0 && deptCodes && timeRangeType && !loading) {
      getHolidayLeaveList();
    }
  }, [pageCode, subordinate, dateList, deptCodes]);


  // 时间
  const onTimeChange = (time) => {
    const startTime = dayjs(time[0]).valueOf();
    const endTime = dayjs(time[1]).valueOf();
    setStartDate(startTime);
    setEndDate(endTime);
    setDateList({
      startDate: startTime,
      endDate: endTime,
    })

  };
  const onTimeRangeType = (v, item) => {
    setTimeRangeType(v);
    if (v === TIME_RANGE_TYPE.week) {
      setStartDate(dayjs().startOf('isoWeek').valueOf());
      setEndDate(dayjs().endOf('isoWeek').valueOf());
      setDateList({
        startDate: dayjs().startOf('isoWeek').valueOf(),
        endDate: dayjs().endOf('isoWeek').valueOf()
      })
    } else if (v === TIME_RANGE_TYPE.month) {
      setStartDate(dayjs().startOf(TIME_RANGE_TYPE.month).valueOf());
      setEndDate(dayjs().endOf(TIME_RANGE_TYPE.month).valueOf());
      setDateList({
        startDate: dayjs().startOf(TIME_RANGE_TYPE.month).valueOf(),
        endDate: dayjs().endOf(TIME_RANGE_TYPE.month).valueOf()
      })
    } else {
      setStartDate(dayjs().startOf(TIME_RANGE_TYPE.date).valueOf());
      setEndDate(dayjs().endOf(TIME_RANGE_TYPE.date).valueOf());
      setDateList({
        startDate: dayjs().startOf(TIME_RANGE_TYPE.date).valueOf(),
        endDate: dayjs().endOf(TIME_RANGE_TYPE.date).valueOf()
      })
    }
  }
  // 下属
  const onScopeChange = (scope) => {
    setSubordinate(scope);
  };
  // 获取假种icon
  const getHolidaysIcon = (item) => {
    return holidayBtnConfig[item];
  }
  return wrapSSR(
    <ConfigProvider
      prefixCls='ant5'
      locale={providerLocale}
    >
      <div className={classes}>
        <div className={`${prefixCls}-title`}>
          <DHRCardTitle
            title={
              <>
              <div>团队假期</div>
              {item?.cardHeight === 1 && <div className={`${prefixCls}-title-filter`}>
                <div className={`${prefixCls}-scope`}>
                  <ScopeFilter
                    subordinate={timeRangeType}
                    list={timeRangeTypeList}
                    onScopeChange={onTimeRangeType}
                  />
                </div>
                <div className={`${prefixCls}-time`}>
                  <TimeFilter
                    source='team-holidays'
                    lang={lang}
                    timeRangeTypeInfo={timeRangeType}
                    startDate={startDate}
                    onTimeChange={onTimeChange}
                    onTimeRangeType={onTimeRangeType}
                  />
                </div>
              </div>}
              </>
            }
            showPrefixTag={false}
            type="grey"
            titleAdded={
              <DHRCardButton onClick={() => {openDawer();}}>
                详情 <RightThickOutlined cursor="pointer" size={14} className={`${prefixCls}-url-icon`}/>
              </DHRCardButton>
            }
          />
        </div>
        {item?.cardHeight !== 1 && <div className={`${prefixCls}-filter`}>
          <div className={`${prefixCls}-scope`}>
            <ScopeFilter
              subordinate={timeRangeType}
              list={timeRangeTypeList}
              onScopeChange={onTimeRangeType}
            />
          </div>
          <div className={`${prefixCls}-time`}>
            <TimeFilter
              source='team-holidays'
              lang={lang}
              timeRangeTypeInfo={timeRangeType}
              startDate={startDate}
              onTimeChange={onTimeChange}
              onTimeRangeType={onTimeRangeType}
            />
          </div>
          <div className={`${prefixCls}-scope`}>
            {
              permissionScope?.length > 0 && <ScopeFilter subordinate={subordinate} list={permissionScope} onScopeChange={onScopeChange} />
            }
          </div>
          {!!totalNum && !!totalDays && <div className={`${prefixCls}-summary`}>
            {totalNum}人，{totalDays}天
          </div>}
        </div>}
        <div
          style={{
            height: `${item?.cardHeight === 1 ? 'calc(100% - 80px)' : 'calc(100% - 132px)'}`,
            marginTop: `${item?.cardHeight === 1 ? '16px' : ''}`
          }}
        >
          <Spin spinning={loading}>
            {holidayLeaveList.length > 0 && !abnormal && <div className={`${prefixCls}-content`}>
              {holidayLeaveList.map((v) => {
                return v && <div className="holidays-warp">
                  <div className="holidays-warp-item">
                    {getHolidaysIcon(v.subCode)}
                    {v.subName}
                    <span>（{v.subNum}人，{v.subDays}天）</span>
                  </div>
                  {v.empInfoList && <HolidayDetails
                    subName={v.subName}
                    subCode={v.subCode}
                    list={v.empInfoList}
                    hasNext={v.hasNext}
                    onClickMoreData={onClickMoreData}
                    total={v.subNum}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    auth={auth}
                    headers={headers}
                    deptCodes={deptCodes}
                    startDate={dateList?.startDate}
                    endDate={dateList?.endDate}
                    subordinate={subordinate}
                    detailsLoading={detailsLoading}
                  />}
                </div>
              })
              }
            </div>}
            {holidayLeaveList?.length === 0 && !abnormal && !loading && <div className="holidays-no-data">
              <img
                src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/no_data_image.png'}
                style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }}
              />
              <span className="text-item-1">暂无数据</span>
            </div>}
            {abnormal && <div className="holidays-abnormal">
              <img
                src={'//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'}
                style={{ width: `${item?.cardHeight === 1 ? '80px' : '120px'}` }}
              />
              <span className="text-item-1">服务不可用，请稍候再试。</span>
            </div>}
          </Spin>
        </div>
      </div>
    </ConfigProvider >
  );
};

export default Pagelet;

