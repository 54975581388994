import React, { useState, useEffect } from 'react';
import { getLocale } from '@didi/ec-base';
import { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import PageletApi from '@didi/pagelets-sdk';
import { DHRCardTitle } from '@didi/ec-ehr-base';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { DownThickOutlinedCommonDirection, UpThickOutlinedCommonDirection } from '@didi/ec-base-icon';
import './style.scoped.less';

// 图片建议手动上传到 gift 使用
// import comment from './assets/comment.png';

const localLocales = manifest.locales || {};

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, headers},
  } = props;

  const [hrData, setHrdata] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [initError, setInitError] = useState(false);
  const [largeScreen, setLargeScreen] = useState(false);

  // console.log(apiHost,'apiHost')
  // const authId = PageletApi.utils.getFunctionId(auth, 'personal-hr');
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionId: auth.id,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
  };
  PageletApi.newRequest.init(newHeaders, 'personal-hr');

  const handleRequest = async () => {
    try {
      const data = await PageletServices.initPersonalInfo(apiHost);

      // const authId = auth?.id;
      // const data = await PageletServices.initPersonalInfo(apiHost,{}, {
      //   ...headers,
      //   functionId: authId,
      //   'bricks-function-id': authId,
      // });
      // setHrdata([...data?.data, ...data?.data, ...data?.data])

      if (data?.code === '100200') {
        setHrdata(data?.data)
      } else {
        setInitError(true);
      }
    } catch (e) {
      setInitError(true)

    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  const prefixCls = 'pagelet-personal-hr';
  const variable = { size: '12px' };

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  return (
    <div className={classes}>
      <DHRCardTitle title={'人力系统'} />
      {!initError && <div id='personal-hr-card' className="personal-hr-card presonal-hr-all">
        {
          hrData?.map((item, index) => {
            if (item.hasAuth) {
              return (
                <div
                  className={`personal-hr-item ${item.linkUrl !== '' ? 'personal-hr-item-hasUrl' : ''}`}
                  onClick={() => {
                    if (item.linkUrl) {
                      return window.open(item.linkUrl);
                    } else {
                      return false;
                    }
                  }}
                >
                  <span className="personal-hr-item-icon">
                    <img src={item.iconUrl} />
                  </span>
                  <span className="personal-hr-item-title">
                    {item.title}
                  </span>
                </div>
              )
            } else {
              return null;
            }
          })
        }
      </div>}
      {/* {(hrData?.length > 6 && !initError) && <div
        className="personal-hr-showbtn"
        onClick={() => {setShowAll(!showAll);}}
      >
        {
          showAll ?
            <div className="personal-hr-show-btn"><span>收起</span><UpThickOutlinedCommonDirection size={14} className={`${prefixCls}-headerurl-icon`} /></div>
            : <div className="personal-hr-show-btn"><span>展开全部</span><DownThickOutlinedCommonDirection size={14} className={`${prefixCls}-headerurl-icon`} /></div>
        }
      </div>} */}
      {initError && <div className="personal-hr-content-err">
        <img
          src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
        />
        <span className='text-item-1'>服务不可用，请稍候再试。</span>
      </div>}
    </div>
  );
};

export default Pagelet;
