/*
 * @Author       : sunpenggui
 * @Date         : 2024/7/9 下午3:00
 * @Description  :
 */
import React from 'react';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
// 沧海档案链接，注；只提供了测试、预发、线上的地址
const profile = {
  dev: '//canghai.mix-test.didichuxing.com/talent/view',
  test: '//canghai.mix-test.didichuxing.com/talent/view',
  stage: '//canghai-stable.didichuxing.com/talent/view',
  uat: '//canghai.mix-test.didichuxing.com/talent/view',
  online: '//canghai.didichuxing.com/talent/view',
};
const getEnv = () => {
  if (/.*dev.*/.test(location.hostname)) return 'dev';
  if (/.*test.*/.test(location.hostname)) return 'test';
  if (/.*stage.*/.test(location.hostname)) return 'stage';
  if (/.*uat.*/.test(location.hostname)) return 'uat';
  return 'online';
};

const showEllipseWord = (str, splitWord = '>', isShowHover = true) => {
  const arr = str.split(splitWord);
  const len = arr.length;
  if (len < 4) return str;
  if (isShowHover) {
    return (
      <Tooltip title={str}>
        {`${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`}
      </Tooltip>
    );
  }
  return `${arr[0]}${splitWord}${arr[1]}${splitWord}...${splitWord}${arr[len - 1]}`;
};
export const timeRangeTypeList =[
  {
    code: 'month',
    name: '月'
  },
  {
    code: 'week',
    name: '周'
  },
  {
    code: 'date',
    name: '日'
  }
]

export const CARE_CODE_ENUM = {
  ALL: '0',
  BIRTHDAY: '1',
  ANNIVERSARY: '2',
  REGULAR_WORKER: '3'
}

export const SORTER_CODE = {
  deptEntryDt: 'hireDateSort',
  deptPath: 'deptIdSort',
  birthdate: 'birthdayDateSort'
}

const turnPositiveList = [
  {
    title: '姓名', dataIndex: 'name', key: 'name',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 100,
    fixed: 'left',
  },
  {
    title: '工号', dataIndex: 'emplid', key: 'emplid',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 90,
  },
  {
    title: '公司邮箱', dataIndex: 'emailAddr', key: 'emailAddr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 200,
  },
  {
    title: '性别', dataIndex: 'sexName', key: 'sexName',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 60,
  },
  {
    title: '入职日期', dataIndex: 'deptEntryDt', key: 'deptEntryDt',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 110,
  },
  {
    title: '转正日期', dataIndex: 'estimatePositiveDate', key: 'estimatePositiveDate',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 110,
  },
  {
    title: '劳动合同工作地', dataIndex: 'locationDescr', key: 'locationDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 120,
  },
  {
    title: '部门路径', dataIndex: 'deptPath', key: 'deptPath',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <Tooltip title={text}>
         <span>{showEllipseWord(text, '>', false)}</span>
      </Tooltip>
    ),
    width: 300,
  },
  {
    title: '部门编号', dataIndex: 'deptCodePath', key: 'deptCodePath',
    ellipsis: true,
    render: (text, record) => {
      return (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      )
    },
    width: 80,
  },
  {
    title: '直接上级', dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 140,
  },
  {
    title: '岗位名称', dataIndex: 'jobDesc', key: 'jobDesc',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 180,
  },
  {
    title: '职级', dataIndex: 'supvLvlIdDescr', key: 'supvLvlIdDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 80,
  },
  {
    title: '员工档案',
    dataIndex: 'file',
    key: 'file',
    width: 80,
    fixed: 'right',
    ellipsis: true,
    render: (text, record) => {
      let tit= "";
      const useInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
      const today = dayjs(new Date()).format('YYYY-MM-DD');
      if(dayjs(record?.deptEntryDt).valueOf() === dayjs(today).valueOf()){
        tit = '请在第二天查看当天入职员工的档案'
      }
      if(record?.emplid === useInfo?.empId){
        tit = '不可查看本人的档案'
      }
      if(tit){
        return <Tooltip title={tit}>
          <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>查看</span>
        </Tooltip>
      } else {
        return <a href={`${profile[getEnv()]}?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text" style={{color: '#FF6400'}}>查看</a>
      }
    },
  }

]
const anniversaryList = [
  {
    title: '姓名', dataIndex: 'name', key: 'name',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 100,
    fixed: 'left',
  },
  {
    title: '工号', dataIndex: 'emplid', key: 'emplid',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 90,
  },
  {
    title: '公司邮箱', dataIndex: 'emailAddr', key: 'emailAddr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 200,
  },
  {
    title: '性别', dataIndex: 'sexName', key: 'sexName',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 60,
  },
  {
    title: '入职日期', dataIndex: 'deptEntryDt', key: 'deptEntryDt',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 110,
  },
  {
    title: '本月周年', dataIndex: 'anniversaryDt', key: 'anniversaryDt',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 100,
  },
  {
    title: '劳动合同工作地', dataIndex: 'locationDescr', key: 'locationDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 120,
  },
  {
    title: '部门路径', dataIndex: 'deptPath', key: 'deptPath',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <Tooltip title={text}>
        <span>{showEllipseWord(text, '>', false)}</span>
      </Tooltip>
    ),
    width: 300,
  },
  {
    title: '部门编号', dataIndex: 'deptCodePath', key: 'deptCodePath',
    ellipsis: true,
    render: (text, record) => {
      return (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      )
    },
    width: 80,
  },
  {
    title: '直接上级', dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 140,
  },
  {
    title: '岗位名称', dataIndex: 'jobDesc', key: 'jobDesc',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 180,
  },
  {
    title: '职级', dataIndex: 'supvLvlIdDescr', key: 'supvLvlIdDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 60,
  },
  {
    title: '员工档案',
    dataIndex: 'file',
    key: 'file',
    width: 80,
    fixed: 'right',
    ellipsis: true,
    render: (text, record) => {
      let tit= "";
      const useInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
      const today = dayjs(new Date()).format('YYYY-MM-DD');
      if(dayjs(record?.deptEntryDt).valueOf() === dayjs(today).valueOf()){
        tit = '请在第二天查看当天入职员工的档案'
      }
      if(record?.emplid === useInfo?.empId){
        tit = '不可查看本人的档案'
      }
      if(tit){
        return <Tooltip title={tit}>
          <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>查看</span>
        </Tooltip>
      } else {
        return <a href={`${profile[getEnv()]}?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text" style={{color: '#FF6400'}}>查看</a>
      }
    },
  }

]
const birthdayList = [
  {
    title: '姓名', dataIndex: 'name', key: 'name',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 100,
    fixed: 'left',
  },
  {
    title: '工号', dataIndex: 'emplid', key: 'emplid',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 90,
  },
  {
    title: '公司邮箱', dataIndex: 'emailAddr', key: 'emailAddr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 200,
  },
  {
    title: '人员性质', dataIndex: 'emplClassDescr', key: 'emplClassDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 80,
  },
  {
    title: '性别', dataIndex: 'sexName', key: 'sexName',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 60,
  },
  {
    title: '生日', dataIndex: 'birthdate', key: 'birthdate',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 100,
  },
  {
    title: '劳动合同工作地', dataIndex: 'locationDescr', key: 'locationDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 120,
  },
  {
    title: '部门路径', dataIndex: 'deptPath', key: 'deptPath',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <Tooltip title={text}>
        <span>{showEllipseWord(text, '>', false)}</span>
      </Tooltip>
    ),
    width: 300,
  },
  {
    title: '部门编号', dataIndex: 'deptCodePath', key: 'deptCodePath',
    ellipsis: true,
    render: (text, record) => {
      return (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      )
    },
    width: 80,
  },
  {
    title: '直接上级', dataIndex: 'deptManagementLdap', key: 'deptManagementLdap',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 140,
  },
  {
    title: '岗位名称', dataIndex: 'jobDesc', key: 'jobDesc',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 180,
  },
  {
    title: '职级', dataIndex: 'supvLvlIdDescr', key: 'supvLvlIdDescr',
    ellipsis: true,
    render: (text, record) => (
      <Tooltip title={text}>
        {text}
      </Tooltip>
    ),
    width: 60,
  },
  {
    title: '员工档案',
    dataIndex: 'file',
    key: 'file',
    width: 80,
    fixed: 'right',
    ellipsis: true,
    render: (text, record) => {
      let tit= "";
      const useInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
      const today = dayjs(new Date()).format('YYYY-MM-DD');
      if(dayjs(record?.deptEntryDt).valueOf() === dayjs(today).valueOf()){
        tit = '请在第二天查看当天入职员工的档案'
      }
      if(record?.emplid === useInfo?.empId){
        tit = '不可查看本人的档案'
      }
      if(tit){
        return <Tooltip title={tit}>
          <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>查看</span>
        </Tooltip>
      } else {
        return <a href={`${profile[getEnv()]}?empId=${record.emplid}`} rel="noreferrer" target="_blank" className="theme-text" style={{color: '#FF6400'}}>查看</a>
      }
    },
  }

]

export const NAMES = {
  [CARE_CODE_ENUM.REGULAR_WORKER]: '转正',
  [CARE_CODE_ENUM.ANNIVERSARY]: '周年',
  [CARE_CODE_ENUM.BIRTHDAY]: '生日',
}
export const NAMES_LIST = [
  {
    label: '周年',
    value: CARE_CODE_ENUM.ANNIVERSARY
  },
  {
    label: '生日',
    value: CARE_CODE_ENUM.BIRTHDAY
  },
  {
    label: '转正',
    value: CARE_CODE_ENUM.REGULAR_WORKER
  },
]

export const COLUMNS = {
  [CARE_CODE_ENUM.REGULAR_WORKER]: turnPositiveList,
  [CARE_CODE_ENUM.ANNIVERSARY]: anniversaryList,
  [CARE_CODE_ENUM.BIRTHDAY]: birthdayList,
}

export const APIS = {
  [CARE_CODE_ENUM.REGULAR_WORKER]: 'getRegularWorkerList',
  [CARE_CODE_ENUM.ANNIVERSARY]: 'getAnniversaryList',
  [CARE_CODE_ENUM.BIRTHDAY]: 'getBirthdayList',
}
