const PROD_URL = 'dhr.didichuxing.com';
const PRE_URL = 'dhr-stage.intra.xiaojukeji.com';
const TEST_URL = 'dhr-test.intra.xiaojukeji.com';
const DEV_URL = 'dhr-dev.intra.xiaojukeji.com';
const UAT_URL = 'dhr-uat.intra.xiaojukeji.com';
const STAGE_URL = 'dhr-stage.intra.xiaojukeji.com';

const isProdEnv = () => window.location.hostname === PROD_URL;
const isPreEnv = () => window.location.hostname === PRE_URL;
const isTestEnv = () => window.location.hostname === TEST_URL;
const isDevEnv = () => window.location.hostname === DEV_URL;
const isUatEnv = () => window.location.hostname === UAT_URL;
const isStageEnv = () => window.location.hostname === STAGE_URL;

export {
  isProdEnv,
  isPreEnv,
  isTestEnv,
  isDevEnv,
  isUatEnv,
  isStageEnv
}