import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Divider } from "antd";
import classnames from 'classnames';
import ErrorIconBlue from "../../images/errorIconBlue.svg";
import { getErrorLocationSort } from "../../utils/Tool";
import { setErrorListSoreMap } from "../../store/questionnaireNew/questionContentSlice";
import "./index.scss";

// 为了保证返回顺序值准确性，所以用时间戳来确定返回值准确性
let timestamp;
// pc问卷右侧进度条
const questionnaireErrorLocationBlue = (props) => {
  const dispatch = useDispatch();

  const { questionnaireId, respondentTypeCode } = props;
  const [isClick, setIsClick] = useState(false); 

  const {
    errorListMap,
    validateFlag,
    attributeData,
    generalMultiData,
    currentRespondentCode,
    processRate,
    initRequired,
    errorListSoreMap,
  } = useSelector((state) => state.questionContent);

  const renderHiddenNumber = (hiddenKey) => {
    const relatedId = attributeData[hiddenKey]?.relatedKey;
    const relatedOption =
      generalMultiData[currentRespondentCode]?.[relatedId]?.[0]?.option;
    const hiddenNumber =
      attributeData?.[hiddenKey]?.hiddenSubjectIndex?.[relatedOption];
    const relatedNumber = attributeData?.[relatedId]?.number;
    return hiddenNumber ? relatedNumber + "-" + hiddenNumber : "";
    // return relatedNumber + '-' + hiddenNumber
  };

  useEffect(() => {
    if (validateFlag) {
      getErrorListSort();
    }
  }, [errorListMap, validateFlag]);

  const renderString = (item) => {
    return attributeData[item].number
      ? attributeData[item].number
      : renderHiddenNumber(item);
  };

  const getErrorListSort = async () => {
    timestamp = Date.now();
    const { data } = await getErrorLocationSort(
      questionnaireId,
      respondentTypeCode,
      errorListMap,
      timestamp
    );
    if (timestamp == data.timestamp) {
      dispatch(setErrorListSoreMap({ data: data.orderSubjects }));
    }
  };
  const renderNumber = () => {
    return errorListSoreMap.map((item, i) => {
      if (i < 5) {
        return (
            <Button
                key={`error-btn-${item}`}
                onClick={() => {
                    document.querySelector(`#id_${item}`).scrollIntoView({block: 'center'});
                }}
                className="error-btn-wapper"
            >
                第{renderString(item)}题
            </Button>
        );
      }
      if (i === 5) {
        return (
          <div style={{ padding: "6px 0" }} key={`error-btn-${item}`}>
            <div className="error-btn-wapper more">...</div>
          </div>
        );
      }
    });
  };

  return (
    <>
      {validateFlag && !props.saveModalConfirm.visible && errorListSoreMap?.length > 0 && (
        <div className="question-right-location-blue">
          <img
            style={{ width: "38px" }}
            src={ErrorIconBlue}
          />
          <span>点击补答</span>
          <Divider className="blue-divider" />
          {renderNumber()}
        </div>
      )}
    </>
  );
};

export default questionnaireErrorLocationBlue;
