import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};


const PageletServices = {
  getRecentSearch: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.getRecentSearch}`, {
      method: 'post',
      data: body,
    });
  },
  delRecentSearchFunc: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.delRecentSearchFunc}`, {
      method: 'post',
      data: body
    });
  },
  onDeptSelect: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.onDeptSelect}`, {
      method: 'post',
      data: body
    });
  },
  getDataFunc: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.getDataFunc}`, {
      method: 'get',
      data: body
    });
  },
  getAuthDeptManagement: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.getAuthDeptManagement}?functionId=${body.functionId}`, {
      method: 'get',
      data: body
    });
  },
  getAuthDeptOther: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.getAuthDeptOther}?functionId=${body.functionId}`, {
      method: 'get',
      data: body
    });
  },
  // 未休假接口
  getUsableTeam: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.teamUsable}`, {
      method: 'post',
      data: body
    });
  },
  // 已休假接口
  getUsedTeam: async (host, body = {}) => {
    const request = PageletApi.newRequest.getInstance('holiday_summary');
    return await request(`${host}/${apis.teamUsed}`, {
      method: 'post',
      data: body
    });
  },
}

export default PageletServices;
