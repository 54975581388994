import React, { useEffect, useRef } from "react";
import succeedIcon from '../../images/succeed.svg';
import { di18n } from '@ultra/common';
import { useDispatch } from 'react-redux';
import {
  updateLoadStatus
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const { intl } = di18n;
const questionnaireOfSingleSubmited = props => {
  const dispatch = useDispatch();
  const {
    renderBanner,
    navigate,
    questionaireType
  } = props;
  const leftDomRef = useRef(null)

  useEffect(() => {
    dispatch(updateLoadStatus({ data: false }));
    // 动态计算模块高度
    if (leftDomRef.current.children[0]) leftDomRef.current.children[0].style.height = `${document.body.clientHeight - leftDomRef.current.offsetTop}px`
  }, [])
  
  // 渲染单人问卷提交后的模板
  // 里面的组件由于与其他问卷模板公用，所以用父组件传进来的方法渲染
  return (
    <div className="question-of-single-content">
      <div className="question-single-main-banner-pc">
          {/* 渲染问卷头部 */}
          {renderBanner}
        </div>
      <div className="flex questionnaire-main ">
        
        {/* 如果是合并问卷则不渲染导航 */}
        {questionaireType === 2
          ? <div className="question-left" style={{ width: '10%' }} /> : (
            <div className="question-left" ref={leftDomRef}>
              {/* 渲染左侧导航 */}
              {navigate}
            </div>
          )}
        <div className="question-main-single-pc">
          <div className='question-succeed'>
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={succeedIcon} />
              </div>
              <div style={{ textAlign: 'center' }}>
                <div className='succeed-des'>
                  {
                    intl.t('您已提交成功，感谢您的反馈')
                  }
                </div>
                {/* 这一段很奇怪 */}
                <div>Successfully submitted. Thanks for your feedback!</div>
              </div>
            </div>
          </div>
        </div>
        {/* 单人问卷和合并问卷样式区分 */}
        {questionaireType === 2
          ? <div className="question-right" style={{ width: '10%' }} />
          : <div className="question-right" />}
      </div>
    </div>
  );
};

export default questionnaireOfSingleSubmited;
