import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {
  return useRegisterStyle('pagelet-perf-overview-supported', () => [
    {
      // base
      [`.${prefixCls}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '16px 0',
        borderRadius: '12px',
        background:
          'linear-gradient(179deg, rgba(255, 255, 255, 0) -1%, #FFFFFF 36%), linear-gradient(93deg, #FFF3E2 0%, #FFFFFF 52%, #FCC7B8 96%)',
        fontSize: variable.size,

        ['p']: {
          margin: '0',
        },

        ['.spin']: {
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },
    },
  ]);
};

export default useStyle;
