/*
 * @Author       : sunpenggui
 * @Date         : 2024/7/9 下午3:00
 * @Description  :
 */

export const TYPE_CODE_ENUM = {
  ALL: 0,
  PERSONNEL: 1,
  ORGANIZATION: 2,
  EVENT: 3,
}

export const NAMES = {
  [TYPE_CODE_ENUM.PERSONNEL]: '人员',
  [TYPE_CODE_ENUM.ORGANIZATION]: '组织',
  [TYPE_CODE_ENUM.EVENT]: '事件',
}

/**
 * 绿色：入职、调动
 * 红色：离职
 * 蓝色：合同、试用期、组织
 */
export const SOURCE_COLOR_MAP = {
  '入职': 'green',
  '调动': 'green',
  '离职': 'red',
  '合同': 'blue',
  '试用期': 'blue',
  '组织': 'blue',
  'default': 'blue'
}

export const empTypeList = [
  {value: 'CTR', label: '正式员工'},
  {value: 'INT', label: '实习生'}
]


export const typeList = [{
  value: '1',
  label:'人员',
},{
  value: '2',
  label: '组织',
},]
