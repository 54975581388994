import React, { useState, useRef, useMemo } from 'react';
import { Select, Spin } from '@didi/ec-base';
import Icon from '@ant-design/icons';
import Search from '../../../../assets/search.svg';
import { debounce } from '../../../../utils/utils';

// 搜索框组件
function DebounceSelect({ fetchOptions, debounceTimeout = Number('500'), ...props }) {
  // 加载态
  const [fetching, setFetching] = useState(false);
  // 搜索值
  const [options, setOptions] = useState([]);
  // 搜索的内容
  const [searchVal, setSearchVal] = useState('');
  // 被点击
  const [clicked, setClicked] = useState(false);
  // 依据值
  const fetchRef = useRef(0);

  // 搜索主体
  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      setSearchVal(value);
      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    if (props.options && !fetchRef.current) {
      setOptions(props.options);
    }

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, props?.options]);

  // 未找到的展示内容
  const fetchingData = fetching ? <Spin size="small" /> : <>抱歉，未找到相应的小桔子</>;
  // 返回组件
  return (
    <Select
      showSearch={true}
      className="debounce-select"
      popupClassName="debounce-select-popup"
      optionLabelProp="text"
      filterOption={false}
      maxTagCount="responsive"
      onSearch={value => {
        debounceFetcher(value);
        setOptions([]); // 更新输入框的值，立刻清空下拉框options
      }}
      onClick={() => setClicked(true)}
      getPopupContainer={triggerNode => triggerNode.parentNode || document.body} // 弹出框位置
      notFoundContent={!searchVal ? null : fetchingData}
      options={options}
      allowClear={clicked ? true : false}
      placeholder={clicked ? '输入姓名/工号' : ''}
      style={clicked ? { flex: 1 } : { width: '32px' }}
      {...props}
    />
  );
}

export default DebounceSelect;
