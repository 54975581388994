import { Select, Spin, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import newRequest from '../../utils/newRequest';
import useDebouncedCallback from '../../utils/useDebouncedCallback';
import CopyableTag from '../CopyableTag';
const { Option } = Select;

import useStyle from './style';


const PeopleSelectEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <p>没有结果</p>
    </div>
  );

const PeopleSelect = (props)=>{
  const [peopleList,setPeopleList] = useState([])
  const [tooltipvisible,settooltipvisible]= useState(false)
  const [tooltipvalue,settooltipvalue] = useState(undefined)
  const [loading,setloading]= useState(false)
  const [fristloading,setfristloading]= useState(true)
  const [srollLoading,setSrollLoading] = useState(false)
  const istreeOpen = useRef(false)
  const isLastPage = useRef(false)
  const currentPage = useRef(0)
  const currentSearchWord = useRef('')
  const {mode,valueSelectKey,getlabelKey,empClass,hrStatus,deptCodeList,hasAuth,dsfDataSource, ...other} = props;
  const request = newRequest.getInstance();

  const searchFetch = async (keyword, pageNum,callback=()=>{})=>{
    if(!keyword){
      setPeopleList([])
      setloading(false)
      isLastPage.current = false
      currentSearchWord.current = ''
      setfristloading(true)
      return;
    }
    if(isLastPage.current && pageNum > 1){
      setSrollLoading(false)
      return
    }
    if(pageNum > 1){
      setSrollLoading(true)
    }
    if(pageNum === 1){
      setPeopleList([])
    }
    currentPage.current = pageNum;
    currentSearchWord.current = keyword;
    setloading(true)
    const getALL = {
      method: 'post',
      url: `/open/api/open/v1/emp/component/searchAll`,
      data:{keyword, pageNum, empClass, hrStatus, dsfDataSource}
    }
    const res = await request((hasAuth || typeof deptCodeList !== 'undefined')?{
      method: 'post',
      url: `/open/api/open/v1/emp/component/search`,
      data:{keyword, pageNum, empClass, hrStatus,dsfDataSource,deptCodeList}
    }: getALL);

    if(currentSearchWord.current !== keyword){
      return
    }
    setfristloading(false)
    setloading(false)
    callback(res.data)
    if(res.data && res.data.list){
      if(pageNum !== currentPage.current){
        return
      }
      setSrollLoading(false)
      isLastPage.current = res.data.isLastPage
      if(pageNum === 1){
        setPeopleList(res.data.list)
      }else{
        setPeopleList([...peopleList,...res.data.list])
      }
    }
  }

  const debounceSearch = useDebouncedCallback(searchFetch, 500)

  const onSearch = (keyword)=>{
    debounceSearch(keyword,1)
  }

  const onVisibleChange = (visible)=>{
    if(istreeOpen.current && visible){
      return
    }
    settooltipvisible(visible)
  }
  const onDropdownVisibleChange = (open)=>{
    if(open){
      settooltipvisible(false)
    }
    istreeOpen.current = open
  }

  const selectThis = (value, valueObject)=>{
    if(props.mode==='multiple'){
      settooltipvalue(valueObject.map(i=>i.tooltip))
    }else{
      settooltipvalue(valueObject.tooltip)
    }
    if(props.labelInValue && value){
      value.copyableTag = props.mode==='multiple'?valueObject.map(i=>i.tooltip):valueObject.tooltip
    }
    props.onChange && props.onChange(value,valueObject)
  }

  const scroll = (e)=>{
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      debounceSearch(currentSearchWord.current,currentPage.current+1)
    }
  }

  const options = peopleList.map(i => <Option value={i[valueSelectKey || 'ldap']} label={getlabelKey ? getlabelKey(i) : (mode === 'multiple' ? i.name : `${i.name}(${i.ldap}) ${i.empId}`)} tooltip={`${i.name}(${i.ldap}) ${i.empId}, ${i.deptPath}`} data={i}>
    <Tooltip title={<><div>{`${i.name}(${i.ldap}) ${i.empId}`}</div><div>{`${i.deptPath}`}</div></>} overlayClassName="copyable-tooltip">
      <div className="dhr-people-select-label-item">
        <div className="dhr-people-select-label-detail">{`${i.name}(${i.ldap}) ${i.empId}`}</div>
        <div className="dhr-people-select-deptPath">&lrm;{i.deptPath}&lrm;</div>
      </div>
    </Tooltip>
  </Option>);

  const prefixCls = 'dhr-people-select';
  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <div className="dhr-people-select">
      <CopyableTag tooltiplist={tooltipvalue} multiple={mode==='multiple'} onVisibleChange={onVisibleChange} tooltipvisible={tooltipvisible} noCopyableTag={props.noCopyableTag}>
        <Select
          mode={mode}
          placeholder="请输入姓名/邮箱前缀/工号"
          showSearch
          onSearch={onSearch}
          onDropdownVisibleChange={onDropdownVisibleChange}
          style={{ width: 250 }}
          optionLabelProp="label"
          filterOption={false}
          onPopupScroll={scroll}
          maxTagCount={1}
          maxTagPlaceholder={(v)=>{return <div>+{v.length}</div>}}
          notFoundContent={loading ? <Spin size="small" /> : (fristloading?null:<PeopleSelectEmpty/>)}
          {...other}
          getPopupContainer={trigger => trigger.parentNode}
          onChange={selectThis}
          dropdownRender={menu => (
            <div>
              {menu}
              {srollLoading && <div className="dhr-people-select-loading">
                <Spin />
              </div>}
            </div>
          )}
        >
          {options}
        </Select>
      </CopyableTag>
    </div>
  )
}

export default PeopleSelect;
