import { useRegisterStyle } from '@didi/ec-base';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-didi-medal', () => [
		{
			// base
      [`.${prefixCls}`]: {
        ['.child-title']: {
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'PingFang SC',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          color: '#000000DE',
          marginBottom: '16px',

          ['&>span:first-of-type']: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '12px',

            ['>svg']: {
              marginRight: '4px',
            },

            ['.card-icon']: {
              marginLeft: '4px',
              cursor: 'pointer',
            }
          },

          ['.child-title-line']: {
            flex: '1',
            height: '1px',
            background: 'linear-gradient(270deg, #EEF2FF 0%, #D5D8E4 99%)'
          }
        },

        ['.special-medal-container']:{
          display: 'flex',
          flexWrap: 'wrap',

          ['>div']: {
            marginBottom: '40px',

            ['&:not(:last-of-type)']: {
              marginRight: '16px',
            },

          }
        },
			}
		}
	]);
};

export default useStyle;
