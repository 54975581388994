import React, { useEffect, useState } from 'react';
import { Select } from '@didi/ec-base';
import { DownThickOutlined } from '@didi/iconflow/ec';

import useStyle from './style';

function ScopeFilter(props) {
  const { list, onScopeChange, subordinate, prefixCls } = props;
  const [scope, setScope] = useState(null);
  const onSelectChange = v => {
    setScope(v);
    onScopeChange(v);
  };
  useEffect(() => {
    if(subordinate){
      setScope(subordinate);
    }
  }, [subordinate])

  const wrapSSR = useStyle(prefixCls);

  return wrapSSR(
    <div className={`${prefixCls}-scope`}>
      <Select
        placement='bottomRight'
        getPopupContainer={node => node.parentNode}
        suffixIcon={<DownThickOutlined />}
        value={scope}
        onChange={onSelectChange}
        dropdownStyle={{
          width: '100px'
        }}
      >
        {
          list.map((item) => {
            return (
              <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
            )
          })
        }
      </Select>
    </div>
  )
}

export default ScopeFilter;
