import React, { useState, useEffect } from 'react';

const Organization = props => {
  const { data, showImg } = props;
  const [ countMinWidth, setCountMinWidth ] = useState(15)

  useEffect(() => {
    // 动态计算最小宽度，以防数字展示不全
    const handleResize = () => {
      if(data?.length > 0){
        const dom = [];
        data?.forEach((item, index) => {
          dom.push(document.getElementById(`percentage-bar-span-${index}`)?.offsetWidth + 10);
        });
        setCountMinWidth(dom);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  return (
    showImg === false ? <div className='echarts-organization'>
      <table>
      {data?.map((item, index) => (
        <>
          <tr>
            <th className='job-level-name'>{item?.jobLevelName}</th>
            <th className='count'>
              <div class="percentage-bar" style={{ width: item?.ratio, minWidth: countMinWidth[index] + 'px' }}>
                <span id={`percentage-bar-span-${index}`}>{item?.count}</span>
              </div>
            </th>
            <th className='ratio'>{item?.ratio}</th>
          </tr>
        </>
      ))}
      </table>
    </div>
    : <div className='echarts-organization-img' style={{ backgroundImage: "url('//img-hxy021.didistatic.com/static/ehr_static/imgs/canghai/cards/zh_CN/1.png')" }} />
  )
}

export default Organization;
