import React from 'react';
import { preImageUrl } from '../../../utils/common';

const NoData = () => (
  <div className="no-data-placeholder">
    <img src={`${preImageUrl}nothing.svg`} />
    暂无数据
  </div>
);

export default NoData;
