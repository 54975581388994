import { message } from 'antd';
import { axios } from './common';
import { getCookieValue } from './Tool';

const forbiddenPage = `${location.origin}/questionnaire/no-permission`;

const HTTP = axios.create({
  baseURL: '/',
  timeout: 30000,
  withCredentials: true,
  headers: {
    // eslint-disable-next-line quote-props
    'ct_role': getCookieValue(),
    'Content-Type': 'application/json',
    'x-kylin-proxy-with': 'apirequest',
  },
});

// 处理请求返回
const opResponse = res => {
  const { data: { data, code, msg } } = res;
  switch (code) {
    case '100200': {
      return Promise.resolve(data);
    }
    case '190017': {
      location.replace(forbiddenPage);
      break;
    }
    case '19000020': {
      location.replace(forbiddenPage);
      break;
    }
    default:
      message.error(msg);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        code,
        error: msg,
        message: msg,
      });
  }
};

HTTP.interceptors.response.use(
  (res) => {
    return opResponse(res);
  },
  ({ response }) => {
    if (response) {
      // message.error(response.data?.message);
      if (response.status === 401) {
        // if (!window.redirectLogin && response.data.loginUrl) {
        //   window.redirectLogin = true;
        //   window.location.href = response.data.loginUrl;
        // }
        if ($CONFIG.customProps.newRequest) {
          if (!window.redirectLogin && response.data.loginUrl) {
          	window.redirectLogin = true;
          	window.location.href = response.data.loginUrl; 
          }
        } else {
          location.reload();
        }
      } else if (response.status === 400 || response.status === 500 || response.status === 403) {
        return opResponse(response); // 当成200处理
      } else if (response.status === 413) {
        message.error('图片大小不能超过10M，上传失败!')
      }
      // message.info('系统错误');
      // return {};
      return Promise.reject(response);
    }
    // message.info('系统错误');
    return {};
  },
);

HTTP.interceptors.request.use(
  config => {
    const cfg = { ...config };
    if (config.method === 'get') {
      if (config.params) {
        cfg.params = { ...config.params };
      }
    } else if (config.method === 'post') {
      if (config.data) {
        cfg.data = JSON.stringify(config.data);
      }
    }
    cfg.headers = {
      ...cfg.headers,
      'ct_role': getCookieValue(),
    }
    return cfg;
  },
  error => {
    message.info('系统错误');
  },
);

export default HTTP;
