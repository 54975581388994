import React, { useEffect, useRef, useState } from 'react';
import PageletServices from '../../services';
import { DHRTable } from '@didi/ec-ehr-base';
import { zh_CN, en_US, Spin } from '@didi/ec-base';
import EmptyRender from '../EmptyRender';
import TraineeTransfer from '../TraineeTransfer';
import ApprovalListStatus from '../ApprovalListStatus';
function TraineeList(props) {
  const {
    apiHost,
    headers,
    authId,
    data,
    pageNum,
    pageSize,
    langEn,
    intl,
    loading,
    onPageChange = () => { },
    getList = () => { }
  } = props;
  const columns = [
    {
      title: '实习生姓名',
      dataIndex: 'empName',
      key: 'empName',
      width: 140,
      ellipsis: true,
      fixed: 'left',
      render: (text) => (text || "-"),
    },
    {
      title: '工号',
      dataIndex: 'empId',
      key: 'empId',
      width: 97,
      render: (text) => (text || "-"),
    },
    {
      title: '入职日期',
      dataIndex: 'entryDate',
      key: 'entryDate',
      width: 140,
      render: (text) => (text || "-"),
    },
    {
      title: '预计毕业日期',
      dataIndex: 'expectedGraduationDate',
      key: 'expectedGraduationDate',
      width: 140,
      render: (text) => (text || "-"),
    },
    {
      title: '导师开始日期',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 140,
      render: (text) => (text || "-"),
    },
    {
      title: '导师结束日期',
      dataIndex: 'endTime',
      key: 'endTime',
      width: 140,
      render: (text) => (text || "-"),
    },
    {
      title: '在职状态',
      dataIndex: 'hrStatusName',
      key: 'hrStatusName',
      width: 168,
      render: (text) => (text || "-"),
    },
    {
      title: '离职日期',
      dataIndex: 'terminationDt',
      key: 'terminationDt',
      width: 140,
      render: (text) => (text || "-"),
    },
    {
      title: '审批状态',
      dataIndex: 'approvalStateName',
      key: 'approvalStateName',
      width: 168,
      render: (text, record) => (
        <>
          {text ? (
            <ApprovalListStatus
              text={text}
              statusCode={record.approvalStateCode}
            />
          ) : (
            "-"
          )}
        </>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      fixed: 'right',
      ellipsis: true,
      render: (text, record) => {
        if (record.hrStatusCode !== "A" || record.approvalStateCode === "30") {
          return;
        }
        return (
          <span style={{ color: '#FF6400' }} onClick={() => { setinternInfo(record) }}>
            移交
          </span>
        );
      },
    },
  ]
  const [internInfo, setinternInfo] = useState(undefined);
  const onChangePageSize = ({ num, size }) => {
    onPageChange(num, size);
  }
  const closeModal = () => {
    console.log(222)
    setinternInfo(undefined);
  };
  const successCallback = () => {
    getList();
  };
  return <div className='trainee-table-wrapper'>
    <Spin spinning={loading}>
      <EmptyRender intl={intl}>
        <DHRTable
          columns={columns}
          locale={langEn ? en_US : zh_CN}
          dataSource={data?.list}
          scroll={{
            x: 1600,
            y: 'calc(100vh - 360px)',
          }}
          onChange={({ current = 1, pageSize = 10 }) => {
            const params = {
              num: current,
              size: pageSize
            };
            onChangePageSize(params);
          }}
          pagination={
            {
              current: pageNum,
              pageSize: pageSize,
              total: data.total,
              showQuickJumper: true,
              showSizeChanger: true,
            }
          }
        />
      </EmptyRender>
    </Spin>
    <TraineeTransfer
      internInfo={internInfo}
      closeModal={closeModal}
      successCallback={successCallback}
      headers={headers}
      authId={authId}
      apiHost={apiHost}
    />
  </div>
}
export default TraineeList;
