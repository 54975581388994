import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  setGeneralData,
  setValidateResult,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
import { di18n } from '@ultra/common';
import './index.scss';
const { intl } = di18n;

const { TextArea } = Input;
const textareaWidget = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    generalData,
    validateRules,
    validateResult,
    respondentsGroup,
    attributeData,
    validateFlag,
    currentRespondentCode,
    surveyBusType
  } = useSelector((state) => state.questionContent);
  const dispatch = useDispatch();
  const placeholder = '';
  const {
    label,
    labelStyle,
    tips,
    hidden,
    number,
    related,
    required,
    hasSerialNumber
  } = attributeData[widgetKey];
  // console.log(attributeData[widgetKey], 'attributeData[widgetKey]')
  const rules = validateRules[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;

  const [value, setValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [focusClass, setFocusClass] = useState('')

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData && draftData[widgetKey]) {
      const widgetDraftData = draftData[widgetKey];
      const draftItem = widgetDraftData[currentRespondentCode];
      if (draftItem.length > 0) {
        const input = draftItem[0].value;
        setValue(input);

        // 初始化赋值&校验
        setValueAndValidate(draftItem);
      }
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);
  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (value) {
        setValue(null);
      }
    }
  }, [hidden, initFlag, value])

  const setValueAndValidate = (paramData) => {
    const { value } = paramData[0];
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: paramData }));
    dispatch(updateCurrentSubject({ data:widgetKey }));
    // 数据校验
    const widgetValidate = validateFun(rules, value);
    dispatch(setValidateResult({ id: widgetKey, data: widgetValidate }));

  }

  const handleOnChange = (e) => {
    setValue(e.target.value);
  }

  const handleBlur = (v) => {
    setFocusClass('');
    const data = [
      {
        option: '',
        value: value ? value?.replace(/(\s*$)/g, '') : ''
      }
    ];
    setUpdateData(data);
  }

  const handleFocus = () => {
    setFocusClass('textarea-focus');
  }
  const validateFun = (rule, content) => {
    const { required } = rule;
    const { max, min } = rules.controlRegular;
    const msgArr = [
      intl.t('亲，此项必填的哈~')
    ]

    if (max && max !== 0) {
      if (content && (content.length > max)) {
        return {
          error: false,
          msg: msgArr[0]
        }
      }
    }

    if (min && min !== 0) {
      if (content && (content.length < min)) {
        return {
          error: false,
          msg: msgArr[0]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[0]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }

  const formatterCount = (e = '') => {
    const { max, min } = rules.controlRegular;
    const maxDefault= 2000;
    const maxLength = max || maxDefault;
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    if (min && Number(min)) {
      return {
        formatter() {
          return `${intl.t('为了反馈更有效，')}${intl.t('请至少输入{num}字', { num: min })}, ${count}/${maxLength}`;
        }
      }
    }
    return {
      formatter() {
        return  `${count}/${maxLength}`;
      }
    }
  };

  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>

      <div className={`textarea-div-hidden-${hidden}`} style={{ position: 'relative', paddingBottom: '14px', paddingTop: '14px'}} key={widgetKey} id={`id_${widgetKey}`}>
        <div
          className={`div-label ${related} textarea-div-label `}
        >
          <div className={`textarea-div-label-${required}-${requiredIcon}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div style={{ wordBreak: 'keep-all', display: 'inline-block' }}>{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={classnames("textarea-content", {
          'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
          'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
        })}>
          {
            tips ? <div className="pc-question-tip">{decodeURIComponent(tips)}</div> : null
          }
          <TextArea
            value={value}
            placeholder={decodeURIComponent(placeholder)}
            rows={4}
            showCount={formatterCount(value)}
            onChange={handleOnChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={focusClass}
            maxLength={2000}
          />
        </div>
        {renderErrorMsg()}
      </div>
    </>
  );
};

export default textareaWidget