// 团队成员、人员性质
export const teamEmplValueMap = {
  'CTR': 'CTR',
  'INT': 'INT',
  'OUTSOURCING': 'OUTSOURCING'
}
// 人员性质 反显 - 工时制外包
export const teamEmplOutsourcingLabelMap = {
  'OUT_HRO': '外包工时制', // '外包工时制',
  'CTS_HRO': '客服工时制', //'客服工时制'
  'XINGHUO-HRO': '星火工时制', // '星火工时制'
  'OS_HRO-HRO': '橙心工时制' // '橙心工时制'
}
// 人员性质 反显 - 其他
export const teamEmplLabelMap = {
  'CTR': 'FULL-TIME_EMPLOYEE', // '正式员工',
  'INT': 'INTERN', //'实习生'
}
export const teamTitleMap = {
  [teamEmplValueMap.CTR]: 'FULL-TIME_EMPLOYEE',
  [teamEmplValueMap.INT]: 'INTERN',
  [teamEmplValueMap.OUTSOURCING]: 'HOURLY_OUTSOURCING',
}
export const onBoardingTypeMap = {
  'all': 'all',
  'social': 'social',
  'campus': 'campus'
}
export const onTheWayTypeMap = {
  'tomorrow': 'tomorrow',
  'today': 'today',
  'offBoarding': 'offBoarding'
}
export const onTheWayTitleMap = {
  [onTheWayTypeMap.tomorrow]: 'IN_TRANSIT', // '在途人数',
  [onTheWayTypeMap.today]: 'EXPECTED_ONBOARD_TODAY', // '今天预计入职',
  [onTheWayTypeMap.offBoarding]: 'EXPECTED_DEPART_EMPLOYEE' // '预计离职人员',
}
export const transferKeyMap = {
  'into': 'into',
  'out': 'out'
}
export const transferTitleMap = {
  [transferKeyMap.into]: 'EXPECTED_TRANSFER_IN_EMPLOYEE', // '预计转入人员',
  [transferKeyMap.out]: 'EXPECTED_TRANSFER_OUT_EMPLOYEE' // '预计转出人员'
}
