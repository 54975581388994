import React, { useState, useEffect, useRef } from 'react';
import { Radio, Input, List, Tooltip, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { di18n, CardIcon } from '@ultra/common';
import Must from '../../images/must.svg';
import classnames from 'classnames';
import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import './index.scss';
import {
  setValidateResult,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions

const { intl } = di18n;
const { TextArea } = Input;

const radioCombineQuestionWidget = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    validateFlag,
    currentRespondentCode,
    generalMultiData,
    surveyBusType,
  } = useSelector((state) => state.questionContent);
  const dispatch = useDispatch();
  // 处理失焦和勾选的时差。当为失焦时，延迟200ms 200ms内存在勾选 不执行失焦函数
  let blurTimer = useRef(false);
  // 当为失焦时，延迟200ms 200ms内存在勾选 存储失焦的数据
  let blurData = useRef();

  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    direction,
    options,
    assess,
    hasSerialNumber
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];
  const otherRules = rules.otherRules;
  // const otherRules = {
  //   [other]: {
  //     required: true
  //   }
  // }
  // console.log('otherRules', otherRules);
  const effect = effectData[widgetKey];
  const requiredIcon = (related || number === 1) ? true : false;


  const [radioValue, setRadioValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [inputValue, setInputValue] = useState();
  // 兼容有多个其他选项时，前端内存里需要存储不同other选项的输入框内容
  const [inputValueMap, setInputValueMap] = useState();
  const [inputShow, setInputShow] = useState();
  const [inputValidateStatus, setInputValidateStatus] = useState();
  const [draftDataUpdate, setDraftDataUpdate] = useState();
  const textareaRefs = React.useRef([]);

  // 矩阵题初始化时，如果存在错误，展示错误的输入框
  const getErrorInputShow = (widgetDraftData = [], errorOtherRules, inputShow) => {
    let errorInputShow = { ...inputShow };
    widgetDraftData?.map(i => {
      const inputVal = i.value || '';
      for (let j in errorOtherRules) {
        // 评估人的code下option匹配到other中的option时
        if (i.option === j) {
          // 判断是否其他选项校验规则没通过
          if (errorOtherRules[j].min && errorOtherRules[j].min > 0 && inputVal.length < errorOtherRules[j].min) {
            errorInputShow[i.assessCode] = true;
          }
        };
      };
    });
    return errorInputShow;
  };


  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (assess) {
      let radio = {};
      let input = {};
      let inputShow = {};
      let inputValueMap = {};
      let inputValidateStatusMap = {};
      assess.map((item, i) => {
        radio[item.assessCode] = (radioValue && radioValue[item.assessCode]) ? radioValue[item.assessCode] : '';
        input[item.assessCode] = '';
        inputShow[item.assessCode] = false;
        inputValueMap[item.assessCode] = {};
        inputValidateStatusMap[item.assessCode] = {};
        other.map((otherItem, i) => {
          inputValueMap[item.assessCode][otherItem] = '';
          inputValidateStatusMap[item.assessCode][otherItem] = true;
        })
      });
      let otherValidate;
      if (draftData[widgetKey]) {
        const widgetDraftData = draftData[widgetKey]?.[currentRespondentCode];

        if (other.length > 0) {
          otherValidate = {};
          other.map((item, j) => {
            otherValidate[item] = {
              error: true,
              msg: ''
            }
          })
        }
        dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: widgetDraftData }));
        let updateData = {};
        widgetDraftData?.map((draftItem, i) => {
          const val = draftItem.option;
          const inputVal = draftItem.value;
          const inputValueMapDraft = {
            [val]: inputVal
          };
          radio = { ...radio, [draftItem.assessCode]: val };
          input = { ...input, [draftItem.assessCode]: inputVal };
          inputValueMap = { ...inputValueMap, [draftItem.assessCode]: inputValueMapDraft };
          updateData = { ...updateData, [draftItem.assessCode]: draftItem }
          if (other.length > 0) {
            other.map((item, j) => {
              if (otherRules[item].min && otherRules[item].min > 0) {
                if (val && val.includes(item) && ((inputVal.length > 0 || !inputVal) && inputVal.length < otherRules[item].min)) {
                  inputValidateStatusMap[draftItem.assessCode][item] = false;
                  const currentOptionResult = {
                    error: false,
                    msg: intl.t('亲，此项必填的哈~')
                  }
                  otherValidate = { ...otherValidate, [item]: currentOptionResult };
                }
              }
            })
          }
          // 因为没有在generalData里存备份，所以在此处需要单独对草稿数据做一个存储
          setDraftDataUpdate(updateData);
          // 数据校验
          const widgetValidate = validateFun(rules, widgetDraftData);
          const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
          dispatch(setValidateResult({ id: widgetKey, data: validateData }));

        })
      }
      setRadioValue(radio);
      setInputValue(input);
      // 处理矩阵题初始化时，如果存在错误，展示错误
      setInputShow(getErrorInputShow(draftData[widgetKey]?.[currentRespondentCode], otherRules, inputShow));
      setInputValueMap(inputValueMap);
      setInputValidateStatus(inputValidateStatusMap);
      setInitFlag(true);

    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));

      let data = [];
      for (let i in updateData) {
        // 如果option和value为空的其他选项，则不需要存储到generalData里
        if (!(updateData[i]?.option === '' && updateData[i]?.value === '')) {
          data.push(updateData[i]);
        }
      };

      if (JSON.stringify(generalMultiData[currentRespondentCode][widgetKey]) != JSON.stringify(data)) {
        dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data }));
      }
      dispatch(updateCurrentSubject({ data: widgetKey }));

      // 数据校验
      const widgetValidate = validateFun(rules);
      let otherValidate = validateFun(otherRules, updateData, true);

      const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
      dispatch(setValidateResult({ id: widgetKey, data: validateData }));
    }
  }, [updateData]);
  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag])


  const handleOnChange = (e, item) => {
    let data = updateData ? updateData : draftDataUpdate;
    if (blurTimer.current) {
      data = { ...data, ...blurData.current };
      blurTimer.current = false;
    };
    const option = e.target.value;
    const newRadioValue = { ...radioValue, [item]: option };
    setRadioValue(newRadioValue);
    let value = '';

    if (other.includes(option) && inputValueMap[item][option]) {
      value = inputValueMap?.[item][option]?.replace(/(\s*$)/g, '') || inputValueMap?.[item]?.[option] || '';
    } else {
      const currentInputValueMap = { ...inputValueMap, [item]: { ...inputValueMap[item], [option]: '' } };
      setInputValueMap(currentInputValueMap);
    }

    if (otherRules[option]?.min && value.length < otherRules[option]?.min && value.length > 0) {
      setInputValidateStatus({
        ...inputValidateStatus, [item]: {
          [option]: false
        }
      });
    } else {
      setInputValidateStatus({
        ...inputValidateStatus, [item]: {
          [option]: true
        }
      });
    }

    setInputValue({ ...inputValue, [item]: value });

    data = {
      ...data, [item]: {
        option: option,
        value: value,
        assessCode: item
      }
    }
    let isEmpty = false;
    if (!inputValueMap[item][option] || (inputValueMap[item][option] && inputValueMap[item][option]?.replace(/(\s*$)/g, '').length === 0)) {
      isEmpty = true;
    }
    if (other.includes(option) && otherRules[option].required && isEmpty) {
      data[item] = {
        option: '',
        value: '',
        assessCode: item
      };
      setInputValue({ ...inputValue, [item]: '' });
    }
    setUpdateData(data);
  }

  const handleChangeInput = (e, item) => {
    const input = e.target.value;
    setInputValue({ ...inputValue, [item]: input });
    const currentInputValueMap = { ...inputValueMap, [item]: { [radioValue[item]]: input } };
    setInputValueMap(currentInputValueMap);
  }

  const handleOptionClick = (key, item) => {
    setInputShow({ ...inputShow, [item]: other.includes(key) });
    setTimeout(() => {
      if (textareaRefs.current[item]) {
        textareaRefs.current[item].focus({
          cursor: 'end',
        });
      };
    }, 100);
  }

  const handleBlur = (item) => {
    blurTimer.current = true;
    let show = false;
    let data = updateData ? updateData : draftDataUpdate;
    blurData.current = { ...data };
    let value = '';
    let option = radioValue[item];
    if (other.includes(radioValue[item])) {
      value = inputValue?.[item] ? inputValue[item]?.replace(/(\s*$)/g, '') : '';
      if (otherRules[radioValue[item]].min && value.length < otherRules[radioValue[item]].min && value.length > 0) {
        // document.body.addEventListener("click", disabledClick, true);
        setInputValidateStatus({
          ...inputValidateStatus, [item]: {
            [option]: false
          }
        });
        show = true;
      } else {
        setInputValidateStatus({
          ...inputValidateStatus, [item]: {
            [option]: true
          }
        });
      }
      if (value.length === 0 && otherRules[radioValue[item]].required) {
        option = '';
        setRadioValue({ ...radioValue, [item]: option });
      }
    }
    blurData.current[item] = {
      option: option,
      value: value,
      assessCode: item
    };
    setInputShow({ ...inputShow, [item]: show });
    setTimeout(() => {
      if (blurTimer.current) {
        data = {
          ...data, [item]: {
            option: option,
            value: value,
            assessCode: item
          }
        }
        setUpdateData(data);
      };
      blurTimer.current = false;
    }, 200);
  }


  const formatterCount = (e = '', optionKey = null) => {
    let min, max;
    if (other.includes(optionKey)) {
      min = otherRules[optionKey].min;
      max = otherRules[optionKey].max ? otherRules[optionKey].max : 2000;
    }
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    if (min && Number(min)) {
      return {
        formatter() {
          return `${intl.t('为了反馈更有效，')}${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
        }
      }
    }
    return {
      formatter() {
        return `${count}/${max}`;
      }
    }
  };

  const placeholder = (optionKey) => {
    const requiredContent = other.includes(optionKey) && otherRules[optionKey].required ? intl.t('必填') : '';
    let desContent = '';
    options.map((item, i) => {
      if (item.key === optionKey && item.des !== '') {
        desContent = '(' + item.des + ')';
      }
    });
    return requiredContent + desContent;
  }

  const getValue = assessCode => inputValueMap ? inputValueMap[assessCode][radioValue[assessCode]] : '';

  // 校验方法
  const validateFun = (rule, content, otherOption = false) => {
    const current = content ? content : radioValue;
    const currentInput = content ? content : inputValue;
    const { max, min, required } = rule;

    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + min + ' ' + intl.t('个选项，最多选择') + ' ' + max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ]


    // updateData只针对一个人的草稿做判断
    if (otherOption) {
      let otherResultMap = {};
      other.map((item, j) => {
        otherResultMap[item] = {
          error: true,
          msg: ''
        }
      });
      for (let i in inputValueMap) {
        if (other.length > 0) {
          other.map((item, j) => {
            if (otherRules[item].min && otherRules[item].min > 0) {
              if (radioValue[i]
                && radioValue[i].includes(item)
                && inputValueMap[i][item]
                && inputValueMap[i][item]?.replace(/(\s*$)/g, '').length < otherRules[item].min) {
                setInputValidateStatus({
                  ...inputValidateStatus, [i]: {
                    [item]: false
                  }
                });
                const currentOptionResult = {
                  error: false,
                  msg: intl.t('亲，此项必填的哈~')
                }
                otherResultMap = { ...otherResultMap, [item]: currentOptionResult };
              }
            }
          })
        }
      }
      return otherResultMap;
    }


    if (required) {
      let requiredLen = assess.length;
      if (!otherOption) {
        if (content) {
          if (current.length < requiredLen) {
            return {
              error: false,
              msg: msgArr[1]
            }
          } else {
            for (let i in current) {
              if (current[i].option === '') {
                return {
                  error: false,
                  msg: msgArr[1]
                }
              }
            }
          }
        } else {
          for (let i in current) {
            if (current[i] === '') {
              return {
                error: false,
                msg: msgArr[1]
              }
            }
          }
        }
      } else { //校验其他选项的输入框，必填的情况下，如果不输入内容，则选项不会被选中，所以理论上不会出现长度为0的情况
        if (content) {
          if (currentInput.length > 0) {
            for (let i in currentInput) {
              if (currentInput[i].value === '') {
                return {
                  error: false,
                  msg: msgArr[1]
                }
              }
            }
          }
        } else {
          for (let i in currentInput) {
            if (currentInput[i]?.replace(/(\s*$)/g, '').length === 0) {
              window.console.log('user required', currentInput[i]?.replace(/(\s*$)/g, ''));
              return {
                error: false,
                msg: msgArr[1]
              }
            }
          }
        }
      }
      return {
        error: true,
        msg: ''
      }
    }
  }


  const header = () => {
    return (
      <>
        <div
          className={'div-label'}
        >
          <div className={`textarea-div-label-${required}-${requiredIcon}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div style={{ wordBreak: 'keep-all', display: 'inline-block' }}>{number}. </div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`radio-content radio-content-related-${related}`}>
          {tips ? (
            <div className="pc-question-tip">{decodeURIComponent(tips)}</div>
          ) : null}
        </div>
        <div className='combine-header-pc'>
          <div style={{ width: '200px' }}></div>
          <div style={{ flex: '1', display: 'flex', justifyContent: 'space-around' }}>
            {
              options.map((item, i) => {
                return (
                  <div key={widgetKey + 'combine-header' + item.key} style={{
                    width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'
                  }}>
                    <span style={{ textAlign: 'center' }}>{item.value}</span>
                    <>
                      {
                        !item.des || ((other.length > 0) && other.includes(item.key)) ? '' :
                          <span className="radio-combine-option-des">
                            <Tooltip
                              placement="top"
                              title={item.des}
                              overlayInnerStyle={{
                                borderRadius: '4px', fontSize: '12px', backgroundColor: '#212121',
                                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.02), 0 4px 6px 6px rgba(0,0,0,0.02), 0 4px 6px 0 rgba(0,0,0,0.06)'
                              }}
                              getPopupContainer={triggerNode => triggerNode.parentNode}>
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </span>
                      }
                    </>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }




  const renderRadio = () => {
    return (
      <>
        <List
          id={`id_${widgetKey}`}
          dataSource={assess}
          header={header()}
          renderItem={(item, i) => {
            return <List.Item key={widgetKey + 'combine-radio-list' + item.assessCode} className={classnames('radio-combine-textarea', {
                    'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
                    'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
                  })}>
              <div className='combine-item' key={widgetKey + 'combine-radio-div' + item.assessCode}>
                <div style={{ cursor: 'pointer' }}>
                  <Tooltip
                    placement="top"
                    title={item.assessName}
                    overlayInnerStyle={{
                      borderRadius: '4px', fontSize: '12px', backgroundColor: '#212121',
                      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.02), 0 4px 6px 6px rgba(0,0,0,0.02), 0 4px 6px 0 rgba(0,0,0,0.06)'
                    }}
                    getPopupContainer={() => document.getElementById(widgetKey)}
                  >
                    <div className='combine-header-name' key={widgetKey + 'combine-radio-span' + item.assessCode}>{item.assessName}</div>
                  </Tooltip>
                </div>
                <div style={{ flex: '1' }} key={widgetKey + 'combine-radio-group' + item.assessCode}>
                  <Radio.Group
                    key={widgetKey + 'combine-textarea-radio' + item.assessCode}
                    onChange={(e) => handleOnChange(e, item.assessCode)}
                    value={radioValue ? radioValue[item.assessCode] : ''}
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    {
                      options.map((opt, i) => {
                        return (
                          <Radio
                            value={opt.key}
                            key={widgetKey + opt.key + item.assessCode}
                            style={{ width: '120px', margin: 0, paddingLeft: '50px' }}
                            onClick={() => handleOptionClick(opt.key, item.assessCode)}
                          >
                            {
                              inputValue
                                && inputValue[item.assessCode].length > 0
                                && radioValue[item.assessCode] === opt.key
                                && other.includes(opt.key) ?
                                <CardIcon type="iconwenbenchakan" /> : ''
                            }
                          </Radio>
                        )
                      })
                    }
                  </Radio.Group>
                </div>
              </div>
              {
                inputShow && inputShow[item.assessCode] ?
                  <div style={{ width: '100%' }}>
                    <Form.Item
                      help={(inputValidateStatus[item.assessCode]
                        && inputValidateStatus[item.assessCode][radioValue[item.assessCode]]) ? '' : (
                        <div className="error-tip">
                          <ExclamationCircleFilled />
                          <span style={{ paddingLeft: '5px' }}>
                            {intl.t('不满足最小字数填写要求~')}
                          </span>
                        </div>
                      )}
                      validateStatus={(inputValidateStatus[item.assessCode]
                        && inputValidateStatus[item.assessCode][radioValue[item.assessCode]]) ? '' : 'error'}>
                      <TextArea
                        ref={textNode => textareaRefs.current[item.assessCode] = textNode}
                        key={widgetKey + 'combine-textarea' + item.assessCode}
                        value={getValue(item.assessCode)}
                        placeholder={placeholder(radioValue[item.assessCode])}
                        // disabled={disabled}
                        rows={4}
                        maxLength={2000}
                        onChange={(e) => handleChangeInput(e, item.assessCode)}
                        onBlur={() => handleBlur(item.assessCode)}
                        showCount={formatterCount(getValue(item.assessCode), radioValue[item.assessCode])}
                      />
                    </Form.Item>
                  </div> : ''
              }
            </List.Item>
          }}
        />

      </>
    )
  }
  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        // 有其他选项的校验没通过，不展示该题的错误提示
        if (validateResult[widgetKey].otherResult && Object.keys(validateResult[widgetKey].otherResult).length > 0) {
          for (let item in validateResult[widgetKey].otherResult) {
            if (Object.keys(validateResult[widgetKey].otherResult).length > 0 && !validateResult[widgetKey].otherResult[item].error) {
              return <></>
            }
          }
        }
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <span style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </span>
              </div>
            </>
          )
        }
      }
    }

    return ''
  }

  return (
    <>
      <div
        className={`radio-div-hidden-${hidden}`}
        style={{ position: 'relative', paddingTop: '14px' }}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >

        <div className={`radio-content radio-content-related-${related}`}>
          {renderRadio()}
        </div>

        {renderErrorMsg()}
      </div>
    </>
  );
};

export default radioCombineQuestionWidget