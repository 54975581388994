import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-org-workbench-card', () => [
		{
			// base
			[`.${prefixCls}`]: {
        width: '100%',
        height: '100%',
        minWidth: '373px',
        background: '#fff',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',

        ['.org-workbench-card-title']: {
          marginBottom: '0px',
          // padding: '16px 16px 8px',
          padding: '16px 16px 16px',
          fontFamily: 'PingFang SC',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '28px',
          letterSpacing: '0em',
          color: 'rgba(0, 0, 0, 0.84)'
        },

        ['.org-workbench-card-content']: {
          // paddingTop: '8px',
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',

          ['.org-workbench-card-content-org']: {
            padding: '0 16px 24px',

            ['.org-workbench-card-content-org-title']: {
              display: 'flex',
              justifyContent: 'space-between',

              '.title-name': {
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',

                'h6': {
                  margin: '0px',
                  marginLeft: '4px',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '22px',
                  letterSpacing: '0px',
                  color: 'rgba(0, 0, 0, 0.84)',
                }
              },
              '.title-detail': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: '18px',
                textAlign: 'right',
                color: 'rgba(0, 0, 0, 0.72)',
                padding: '5px 6px',
                borderRadius: '8px',
                position: 'relative',
                top: '-5px',
                cursor: 'pointer',

                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.04)'
                }
              }
            },

            ['.org-workbench-card-content-org-container']: {
              display: 'flex',

              '.dept-left, .dept-right': {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '12px',
                borderRadius: '8px',
                background: 'rgba(245, 245, 245, 0.6)',

                '.dept-box': {
                  padding: '9px 4px',
                  flex: '1',

                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.03)',
                    borderRadius: '4px'
                  },

                  '.dept-box-name': {
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingBottom: '6px',
                    height: '28px',
                    '.dept-box-name-value': {
                      fontFamily: 'DIDI FD',
                      fontSize: '23px',
                      fontWeight: 900,
                      lineHeight: '20px',
                      color: 'rgba(0, 0, 0, 0.84)',
                    },
                    '.dept-box-name-label': {
                      fontFamily: 'PingFang SC',
                      fontSize: '12px',
                      lineHeight: '14px',
                      fontWeight: 900,
                      color: 'rgba(0, 0, 0, 0.84)',
                    }
                  },
                  '.ec-base-ellipse': {
                    marginTop: '-3px',
                  },
                  '.dept-box-desc': {
                    fontFamily: 'PingFang SC',
                    fontSize: '10px',
                    fontWeight: 'normal',
                    lineHeight: '16px',

                    textAlign: 'justify',
                    color: 'rgba(0, 0, 0, 0.72)',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                    '& > svg': {
                      marginLeft: '2px',
                      paddingTop: '1px'
                    }
                  }
                }
              },
              '.dept-left': {
                width: '40%',
                marginRight: '8px',

                '.dept-box:first-child': {
                  flex: 'none',
                  width: '60%',
                }
              },
              '.dept-right': {
                width: '60%'
              }
            }
          },


          ['.org-workbench-card-content-planning']: {
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',

            '.title-name': {
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              padding: '0 16px',

              'h6': {
                margin: '0px',
                marginLeft: '4px',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
                letterSpacing: '0px',
                color: 'rgba(0, 0, 0, 0.84)',
              }
            },
            '.planning-content': {
              flex: '1',
              overflow: 'hidden',
              img: {
                // width: '100%',
                width: '108%',
                height: '100%',
                objectFit: 'contain'
              }
            }
          }
        }
			}
		}
	]);
};

export default useStyle;
