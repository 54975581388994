import { ConfigProvider } from '@didi/ec-base';
import React from 'react';
import Empty from './empty.svg';
import Nothing from './nothing.svg';

const EmptyRender = (props) => {
  const { intl } = props;

  const customizeRenderEmpty = () => (
    <div style={{ padding: '80px 0 120px' }}>
      <div style={{ textAlign: 'center' }}><img width="150px" style={{ display: 'inline-block' }} height="150px" src={props.isCb ? Empty : Nothing} /></div>
      <span style={{ color: '#9B9B9B' }}>{props.isCb ? intl('NO_DOCUMENT') : intl('NO_DATA')}</span>
    </div>

  )
  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <div className="tableBox">
        {props.children}
      </div>
    </ConfigProvider>
  )
};

export default EmptyRender;
