import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {

	return useRegisterStyle('pagelet-inventory-grow-exp-chart', () => [
		{
			// base
			[`.${prefixCls}`]: {
        minHeight: '186px',
			}
		}
	]);
};

export default useStyle;
