import React from 'react';
import { connect, mapReadPretty } from '@formily/react';
import LoadingTreeSelect from '@/cpns/LoadingTreeSelect';

import './index.less';
const CustomReadPrettyInput = connect(
  LoadingTreeSelect,
  mapReadPretty(props => (
    <div className="label-system-pagelet-baseinfo-panel-readPretty-tree-select">
      <LoadingTreeSelect {...props} />
    </div>
  )),
);
export default CustomReadPrettyInput;
