import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { TreeSelect, Modal, Form, Input, Select, message, Empty } from 'antd';
import { LangTranslate } from '@/utils/';
import {
  getTagBelongInfo,
  updateTagGroupBelong,
  checkDimensionAndTagGroupDept,
  getObjectAndDimsTree,
} from '@/services';
import CustomSelectSubSystemInfo from '@/cpns/CustomSelectSubSystemInfo';
import { useRequest } from 'ahooks';
import { BricksInfoContext } from '@/context';
import DepartmentTreeSelect from '@/cpns/DepartmentTreeSelect';
import { Spin } from 'antd';
import { useObjAndDimData } from '@/hooks';
import { createForm, onFieldValueChange } from '@formily/core';
import { FormItem, FormLayout } from '@formily/antd-v5';
import CustomReadPrettyInput from '@/cpns/CustomReadPrettyInputForBelongModal';
import { FormProvider, Field, ObjectField } from '@formily/react';
import LoadingSelect from '@/cpns/LoadingSelect';
import LoadingTreeSelect from '@/cpns/LoadingTreeSelect/';
import './index.less';

const ChangeBelongDeptModalFomily = props => {
  const { visible, setVisible, currentModalParams } = props;
  const belongInfosFormRef = useRef();
  const ggFlag = useRef(false);
  // const [formInstance] = Form.useForm();
  const subjectId = Form.useWatch('subjectId', formInstance);
  const dimensionId = Form.useWatch('dimensionId', formInstance);
  const [subAndDimsOptions, setSubAndDimsOptions] = useState({ subj: [], dims: [] });

  const BricksInfoContextVal = useContext(BricksInfoContext);
  const { data: [objOption, dimsTree] = [], loading: objAndDimsTreeLoading } = useObjAndDimData();
  const {
    data,
    runAsync: runGetNewOptions,
    loading: getNewOptionsLoading,
  } = useRequest(
    async params => {
      try {
        const res = await getObjectAndDimsTree({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: BricksInfoContextVal?.headers,
          params: { ...params, tagFlag: false },
          usePermissionResponse: BricksInfoContext?.usePermissionResponse,
        });
        return [
          res?.data?.subjectDtoList || [],
          res?.data?.subjectDtoList?.map(it => ({
            name: it?.name,
            id: it?.id,
            disabled: true,
            subDimensionList: it?.subDimensionList,
          })),
        ];
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContext] },
  );

  useEffect(() => {
    formInstance.setFieldState('belongInfos.subjectId', filed => {
      filed.setComponentProps({ options: objOption });
    });
    formInstance.setFieldState('belongInfos.dimensionId', filed => {
      filed.setComponentProps({
        treeData: dimsTree,
      });
    });
  }, [objOption, dimsTree]);

  const handleCancel = () => {
    setVisible(false);
  };

  const {
    loading: checkDimensionAndTagGroupDeptLoading,
    runAsync: runCheckDimensionAndTagGroupDept,
  } = useRequest(
    async params => {
      try {
        const res = await checkDimensionAndTagGroupDept({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContextVal] },
  );

  const { loading: updateTagGroupBelongLoading, runAsync: runUpdateTagGroupBelong } = useRequest(
    async params => {
      try {
        const res = await updateTagGroupBelong({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return res?.data;
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContextVal] },
  );

  const {
    data: tagPoolBelongInfo = {},
    loading: runGetTagBelongInfoLoading,
    runAsync: runGetTagBelongInfo,
  } = useRequest(
    async params => {
      try {
        const res = await getTagBelongInfo({
          apiHost: BricksInfoContextVal?.apiHost,
          headers: { ...(BricksInfoContextVal?.headers || {}) },
          params: params,
          usePermissionResponse: BricksInfoContextVal?.usePermissionResponse,
        });
        return { ...res?.data, dept: res?.data?.dept?.split?.(',') };
      } catch (error) {
        console.log('PageletServices error', error);
      }
    },
    { manual: true, refreshDeps: [BricksInfoContextVal] },
  );

  // 表单
  const formInstance = useMemo(
    () =>
      createForm({
        effects() {
          onFieldValueChange('belongInfos.subjectId', async field => {
            const dimsVal = formInstance.getValuesIn('belongInfos.dimensionId');

            if (!field?.value?.length) {
              const [_, dimsTree] = await runGetNewOptions({});
              formInstance.setFieldState('belongInfos.dimensionId', dimsField => {
                dimsField.setComponentProps({
                  treeData: dimsTree,
                });
              });
              return;
            }
            if (dimsVal?.length && field.selfModified) {
              formInstance.setFieldState('belongInfos.dimensionId', dimsField => {
                dimsField?.setValue(undefined);
                ggFlag.current = true;
              });
            }

            const [_, dimsTree] = await runGetNewOptions({ tagSubjectIdList: [field?.value] });
            formInstance.setFieldState('belongInfos.dimensionId', dimsField => {
              dimsField.setComponentProps({
                treeData: dimsTree,
              });
            });
          });
          onFieldValueChange('belongInfos.dimensionId', async field => {
            const subjectVal = formInstance.getValuesIn('belongInfos.subjectId');
            if (!ggFlag.current) {
              if (!!subjectVal?.length && !!field?.value?.length && !field?.selfModified) {
                return;
              }
            } else {
              const subjectValTmp = formInstance.getValuesIn('belongInfos.subjectId');
              formInstance.setValuesIn('belongInfos.subjectId', '');
              formInstance.setValuesIn('belongInfos.subjectId', subjectValTmp);
              ggFlag.current = false;
            }

            if (!field?.value?.length) {
              const [newSubjectIdList] = await runGetNewOptions({});

              formInstance.setFieldState('belongInfos.subjectId', subjectField => {
                subjectField.setComponentProps({ options: newSubjectIdList || [] });
              });
              return;
            }
            const [newSubjectIdList] = await runGetNewOptions({
              tagDimensionIdList: [field?.value],
            });

            formInstance.setFieldState('belongInfos.subjectId', subjectField => {
              subjectField.setComponentProps({ options: newSubjectIdList || [] });
            });
          });
        },
      }),
    [],
  );

  // 确认调整
  const handelOk = async () => {
    try {
      await formInstance.validate('*');
      const newBelongInfo = formInstance.getValuesIn('belongInfos');
      const params = {
        ...newBelongInfo,
        dept: newBelongInfo?.dept?.join?.(',') || newBelongInfo?.dept,
      };

      const checkRes = await runCheckDimensionAndTagGroupDept(params);
      if (!checkRes?.resultFlag) {
        message.warning(checkRes?.msg);
        formInstance.setValuesIn('belongInfos.dept', checkRes?.dept.split?.(','));
        return;
      }
      const okRes = await runUpdateTagGroupBelong(params);
      if (!okRes?.resultFlag) {
        message.warning(okRes?.msg);
        return;
      }
      await message.success(LangTranslate('操作成功'), 0.5);

      setVisible(() => false);
      try {
        currentModalParams?.afterCallBack?.();
      } catch (error) {
        console.log('error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    formInstance.setFieldState('belongInfos.*(subjectId,dimensionId)', field => {
      field.setComponentProps({ loading: objAndDimsTreeLoading });
    });
  }, [objAndDimsTreeLoading]);

  useEffect(() => {
    formInstance.setFieldState('belongInfos.*(subjectId,dimensionId)', field => {
      field.setComponentProps({ loading: getNewOptionsLoading });
    });
  }, [getNewOptionsLoading]);

  useEffect(() => {
    // formInstance.setFieldsValue(tagPoolBelongInfo);
    formInstance.setValues({ belongInfos: tagPoolBelongInfo });
  }, [formInstance, tagPoolBelongInfo]);

  useEffect(() => {
    if (!currentModalParams?.tagPoolId) {
      return;
    }
    runGetTagBelongInfo({ tagGroupId: currentModalParams?.tagPoolId });
  }, [currentModalParams]);

  return (
    <Modal
      className="pagelet-label-system-change-belong-dept-modal"
      title={LangTranslate('调整归属')}
      handelOk
      open={visible}
      onOk={handelOk}
      onCancel={handleCancel}
      getContainer={false}
      width={600}
      footer={null}
      maskClosable={false}
    >
      <Spin
        spinning={
          updateTagGroupBelongLoading ||
          runGetTagBelongInfoLoading ||
          checkDimensionAndTagGroupDeptLoading
        }
      >
        <div className="pagelet-label-system-change-belong-dept-modal-from">
          <FormProvider form={formInstance}>
            <FormLayout layout={['vertical']} labelAlign={['left']}>
              <ObjectField name="belongInfos">
                <div className="pagelet-label-system-change-belong-dept-modal-form-base-info">
                  <Field
                    name="name"
                    title={
                      tagPoolBelongInfo?.type === 1
                        ? LangTranslate('标签名称')
                        : LangTranslate('标签组名称')
                    }
                    readPretty
                    decorator={[FormItem]}
                    component={[CustomReadPrettyInput]}
                  />
                  <Field
                    name="sourceSystemName"
                    title={LangTranslate('系统来源')}
                    decorator={[FormItem]}
                    readPretty
                    component={[CustomReadPrettyInput]}
                  />
                  <Field
                    name="creator"
                    title={LangTranslate('创建人')}
                    decorator={[FormItem]}
                    readPretty
                    component={[CustomReadPrettyInput]}
                  />
                </div>
                <div className="pagelet-label-system-change-belong-dept-modal-form-detail-info">
                  <Field
                    name="subjectId"
                    validator={[{ required: true, message: LangTranslate('必填项') }]}
                    title={LangTranslate('评价对象')}
                    decorator={[FormItem]}
                    component={[
                      LoadingSelect,
                      {
                        placeholder: LangTranslate('请选择'),
                        showSearch: true,
                        optionFilterProp: 'name',
                        allowClear: true,
                        fieldNames: { label: 'name', value: 'id' },
                        notFoundContent: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={LangTranslate('暂无数据')}
                          />
                        ),
                      },
                    ]}
                  />
                  <Field
                    name="dimensionId"
                    validator={[{ required: true, message: LangTranslate('必填项') }]}
                    title={LangTranslate('归属维度')}
                    decorator={[FormItem]}
                    component={[
                      LoadingTreeSelect,
                      {
                        virtual: false,
                        fieldNames: { label: 'name', value: 'id', children: 'subDimensionList' },
                        treeNodeFilterProp: 'name',
                        treeDefaultExpandAll: true,
                        placeholder: LangTranslate('请选择'),
                        showSearch: true,
                        optionFilterProp: 'name',
                        allowClear: true,
                        notFoundContent: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={LangTranslate('暂无数据')}
                          />
                        ),
                      },
                    ]}
                  />
                  <Field
                    name="belongSystemId"
                    validator={[{ required: true, message: LangTranslate('必填项') }]}
                    title={LangTranslate('归属系统')}
                    decorator={[FormItem]}
                    component={[
                      CustomSelectSubSystemInfo,
                      {
                        virtual: false,
                        placeholder: LangTranslate('请选择'),
                        allowClear: true,
                        notFoundContent: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={LangTranslate('暂无数据')}
                          />
                        ),
                      },
                    ]}
                  />
                  <Field
                    name="dept"
                    validator={[{ required: true, message: LangTranslate('必填项') }]}
                    title={LangTranslate('归属部门')}
                    decorator={[FormItem]}
                    component={[DepartmentTreeSelect]}
                  />
                  <Field
                    name="customAuth"
                    validator={[
                      { maxLength: 200, message: LangTranslate('字数超长') },
                      { required: false, message: LangTranslate('必填项') },
                    ]}
                    title={LangTranslate('自定义归属权限')}
                    decorator={[FormItem]}
                    component={[Input, { placeholder: LangTranslate('请输入') }]}
                  />
                </div>
              </ObjectField>
            </FormLayout>
          </FormProvider>
        </div>

        <div className="pagelet-label-system-change-belong-dept-modal-btns">
          <div
            onClick={handleCancel}
            className="pagelet-label-system-change-belong-dept-modal-item"
          >
            {LangTranslate('取消')}
          </div>
          <div
            data-type-primary
            onClick={handelOk}
            className="pagelet-label-system-change-belong-dept-modal-item"
          >
            {LangTranslate('确定')}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
const ChangeAffiliationDeptModalWrap = props => {
  if (props?.visible) {
    return <ChangeBelongDeptModalFomily {...props} />;
  }
  return null;
};
export default ChangeAffiliationDeptModalWrap;
