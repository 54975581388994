import React, { useRef } from 'react';
import { PieChart } from '@didi/ec-base';

export default function RateRecoveryPieChart(props) {
  const { answerRate } = props;
  const chartRef = useRef();

  return (
    <div style={{ width: '16px', marginLeft: '6px'}}>
      <PieChart
        ref={chartRef}
        height='16px'
        options={{
          tooltip: {
            show: false
          },
          legend: {
            show: false
          },
          color: ['#21C086','#def6ed'],
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '100%',
              label:{
                show :false
              },
              hoverAnimation: false,
              emphasis:{
                disabled: true
              },
              data: [
                { value: answerRate },
                { value: 100 - answerRate },
              ],
            }
          ]
        }}
      />
    </div>
  )
}
