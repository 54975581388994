/**
 * 埋点
 * category： 埋点类别
 * action： 埋点名称
 */
export class Mirror {
  constructor(category) {
    this.category = category;
  }
  action(action) {
    window.taotieCommandQueue = window.taotieCommandQueue || [];
    window.taotieCommandQueue.push({
      command: 'sendEventlogMessageToKafka',
      parameter: {
        category: this.category, // 埋点类别
        action    // 埋点名称
      }
    });
  }
}
