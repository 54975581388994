import React from 'react';
import OptionItemCell from '@/cpns/OptionItemCell';
import { LangTranslate } from '@/utils';
import CustomTagTextAreaCpn from '@/cpns/CustomTagTextAreaCpn';
import CustomTagSelectPeopleCpn from '@/cpns/CustomTagSelectPeopleCpn';
import CustomTagIndicatorCpn from '@/cpns/CustomTagIndicatorCpn';
import { Empty } from 'antd';

import CustomReadPrettyInput from '@/cpns/CustomReadPrettyInput';
import CustomReadPrettyTreeSelect from '@/cpns/CustomReadPrettyTreeSelect';
import CustomReadPrettySelect from '@/cpns/CustomReadPrettySelect';
import CustomReadPrettyTextArea from '@/cpns/CustomReadPrettyArea';
import CustomReadPrettySelectSubSystemInfo from '@/cpns/CustomReadPrettySelectSubSystemInfo';
import CustomReadPrettyDepartmentTreeSelect from '@/cpns/CustomReadPrettyDepartmentTreeSelect';

export const TagTypeXCpnMap = {
  1: {
    label: LangTranslate('单选'),
    value: 1,
    disabled: false,
    cpn: OptionItemCell,
  },
  2: {
    label: LangTranslate('多选'),
    value: 2,
    disabled: false,
    cpn: OptionItemCell,
  },
  3: {
    label: LangTranslate('文本输入'),
    value: 3,
    disabled: false,
    cpn: CustomTagTextAreaCpn,
  },
  4: {
    label: LangTranslate('选人组件'),
    value: 4,
    disabled: false,
    cpn: CustomTagSelectPeopleCpn,
  },
  5: {
    label: LangTranslate('计算公式'),
    value: 5,
    disabled: false,
    cpn: <div />,
  },
  6: {
    label: LangTranslate('条件表达式'),
    value: 6,
    disabled: false,
    cpn: <div />,
  },
  7: {
    label: LangTranslate('指标'),
    value: 7,
    disabled: false,
    cpn: CustomTagIndicatorCpn,
  },
};
export const PanelConfig = readPretty => ({
  title: LangTranslate('标签信息'),
  fieldsList: [
    {
      fieldLabel: LangTranslate('标签组根ID'),
      fieldName: 'rootGroupId',
      validator: [{ required: false, message: LangTranslate('必填项') }],
      component: CustomReadPrettyInput,
      componentProps: {
        readOnly: true,
        variant: 'borderless',
      },
    },
    {
      fieldLabel: LangTranslate('标签ID'),
      fieldName: 'id',
      validator: [{ required: false, message: LangTranslate('必填项') }],
      component: CustomReadPrettyInput,
      componentProps: {
        readOnly: true,
        variant: 'borderless',
      },
    },
    {
      fieldLabel: LangTranslate('标签名称'),
      fieldName: 'name',
      validator: [
        { required: true, message: LangTranslate('必填项') },
        { maxLength: 200, message: LangTranslate('字数超长') },
      ],
      component: CustomReadPrettyInput,
      componentProps: { placeholder: LangTranslate('请输入') },
    },
    {
      fieldLabel: LangTranslate('标签标识'),
      fieldName: 'code',
      validator: [
        { required: false, message: LangTranslate('必填项') },
        { maxLength: 200, message: LangTranslate('字数超长') },
        {
          pattern: /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,
          message: LangTranslate('支持的文本格式：中文、英文、下划线、数字'),
        },
      ],
      component: CustomReadPrettyInput,
      componentProps: { placeholder: LangTranslate('请输入标签标识') },
    },
    {
      fieldLabel: LangTranslate('标签描述'),
      fieldName: 'description',
      validator: [
        { required: false, message: LangTranslate('必填项') },
        { maxLength: 1000, message: LangTranslate('字数超长') },
      ],
      component: CustomReadPrettyTextArea,
      componentProps: {
        autosize: { minRows: 2, maxRows: 2 },
        placeholder: LangTranslate('请输入'),
      },
    },
    {
      fieldLabel: LangTranslate('评价对象'),
      fieldName: 'subjectId',
      validator: [{ required: true, message: LangTranslate('必填项') }],
      component: CustomReadPrettySelect,
      componentProps: {
        placeholder: LangTranslate('请输入'),
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        optionFilterProp: 'name',
        allowClear: true,
        notFoundContent: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={LangTranslate('暂无数据')} />
        ),
      },
    },
    {
      fieldLabel: LangTranslate('归属维度'),
      fieldName: 'dimensionId',
      validator: [{ required: true, message: LangTranslate('必填项') }],
      component: CustomReadPrettyTreeSelect,
      componentProps: {
        placeholder: LangTranslate('请输入'),
        fieldNames: { label: 'name', value: 'id', children: 'subDimensionList' },
        showSearch: true,
        treeNodeFilterProp: 'name',
        allowClear: true,
        treeDefaultExpandAll: true,
        notFoundContent: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={LangTranslate('暂无数据')} />
        ),
      },
    },
    {
      fieldLabel: LangTranslate('归属系统'),
      fieldName: 'belongSystemId',
      validator: [
        {
          required: true,
          message: LangTranslate('必填项'),
        },
      ],
      component: CustomReadPrettySelectSubSystemInfo,
      componentProps: { placeholder: LangTranslate('请选择') },
    },
    {
      fieldLabel: LangTranslate('归属部门'),
      fieldName: 'dept',
      validator: [{ required: true, message: LangTranslate('必填项') }],
      component: CustomReadPrettyDepartmentTreeSelect,
      componentProps: {
        placeholder: LangTranslate('请选择'),
        maxTagCount: 2,
        maxTagTextLength: 4,
        fieldNames: {
          label: 'deptName',
          value: 'deptCode',
          children: 'childList',
        },
        showSearch: true,
        treeNodeFilterProp: 'deptName',
      },
    },
    {
      fieldLabel: LangTranslate('自定义归属权限'),
      fieldName: 'customAuth',
      validator: [
        { maxLength: 200, message: LangTranslate('字数超长') },
        { required: false, message: LangTranslate('必填项') },
      ],
      component: CustomReadPrettyInput,
      componentProps: { placeholder: LangTranslate('请输入') },
    },
  ],
});
