import React, { useEffect } from 'react';
import { getLocale } from '@didi/ec-base';
import locales from './locales';
import classNames from 'classnames';
import useStyle from './style';
import PageletServices from './services';
import Lock from './assets/lock.svg';

// CDN的形式来使用业务组件
// import { Template } from '@didi/ehr-template';
// import { Loader } from '@didi/pagelets-loader';

const Pagelet = props => {
  const { apiHost } = props;

  // 接口请求示例
  // useEffect(async () => {
  //   const data = await PageletServices.getRequestData(apiHost, { a: 1 });
  //   console.log(data);
  // }, []);

  const prefixCls = 'pagelet-team-calendar';
  const wrapSSR = useStyle(prefixCls);
  const locale = getLocale(locales);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <div className={classes}>
      {/* 多语言使用 */}
      <p>这是团队日历 {locale.test}</p>

      {/* svg使用 */}
      <Lock />

      {/* component & pagelet 加载 */}
      {/* <Template /> */}
      {/* <Loader name="@didi/pagelet-xxx" /> */}
    </div>,
  );
};

export default Pagelet;
