import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import EmailEditor from "ct-components/EmailEditor";

import "./index.scss";

const EmailTemplate = (props) => {
  const {
    detail,
    getFormInfo,
    getAllArea,
    channelCode,
    getEngStatus,
    status,
    templateCode,
  } = props;
  const [form] = Form.useForm();

  // 解决编辑邮件标题后会自动隐藏邮件英文问题
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(false);
    form.setFieldsValue({
      messageTitle: detail.messageTitle,
    });
  }, [detail]);

  return (
    <Form
      form={form}
      layout="vertical"
      className="email-form-container"
      onValuesChange={(_, allValues) => {
        setEdit(true);
        getFormInfo(allValues);
      }}
    >
      <Form.Item
        label={channelCode === "EMAIL" ? "邮件主题：" : "D-Chat主题："}
        rules={[{ required: true }]}
        name="messageTitle"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={channelCode === "EMAIL" ? "邮件内容：" : "D-Chat内容："}
        rules={[{ required: true }]}
        name="messageContent"
      >
        <EmailEditor
          labelContent={detail.labelContent}
          messageContent={detail.messageContent}
          getAllArea={getAllArea}
          channelCode={channelCode}
          getEngStatus={getEngStatus}
          status={status}
          templateCode={templateCode}
          edit={edit}
        />
      </Form.Item>
    </Form>
  );
};

export default EmailTemplate;
