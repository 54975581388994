import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { List, Checkbox, TextareaItem } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { di18n, CardIcon } from '@ultra/common';
import classnames from 'classnames';
import Must from '../../images/must.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import './index.scss';
import {
  setGeneralData,
  setValidateResult,
  updateAttributeData,
  setProcessRate,
  setGeneralMultiData,
  resetInitRequired,
  updateCurrentSubject,
  setPrevOptionIds,
  setCurOptionIds
} from '../../store/questionnaireNew/questionContentSlice'; // 引入actions
import { textHeight } from '../../utils/const';

const { intl } = di18n;
const { TextArea } = Input;
const CheckboxItem = Checkbox.CheckboxItem;

const multiWidgetMobile = (props) => {
  const { widgetKey } = props;
  const {
    draftData,
    validateRules,
    validateResult,
    effectData,
    attributeData,
    respondentsGroup,
    validateFlag,
    currentRespondentCode,
    surveyBusType
  } = useSelector((state) => state.questionContent);
  // console.log('processRate', processRate);
  // console.log('validateResult', validateResult);
  // console.log('generalData', generalData);
  const dispatch = useDispatch();

  const {
    label,
    labelStyle,
    other,
    tips,
    hidden,
    number,
    related,
    required,
    mDirection,
    effectOption,
    exclusive,
    options,
    hasSerialNumber
  } = attributeData[widgetKey];
  const rules = validateRules[widgetKey];
  const otherRules = rules.otherRules;

  const effect = effectData[widgetKey];
  const [multiValue, setMultiValue] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [updateData, setUpdateData] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [disabledFlag, setDisabledFlag] = useState(false);

  const [expand, setExpand] = useState(true);

  useEffect(() => {
    if (tips && textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 200) {
      setExpand(false);
    }
  }, []);

  useEffect(() => {
    // 初始化单选的数据结构以及将草稿值赋予value
    if (respondentsGroup && draftData) {
      let inputValueInit = {}
      other.map((item, i) => {
        inputValueInit[item] = '';
      })
      const widgetDraftData = draftData[widgetKey];
      if (widgetDraftData) {
        const draftItem = widgetDraftData[currentRespondentCode];
        if (draftItem.length > 0) {
          const multi = draftItem.map((item, i) => {
            inputValueInit[item.option] = item.value;
            if (effectOption.length > 0) {
              effectFun(item.option);
            }
            return item.option
          })
          setMultiValue(multi);
          if (rules.controlRegular && rules.controlRegular.max) {
            if (multi.length < Number(rules.controlRegular.max)) {
              setDisabledFlag(false);
            } else {
              setDisabledFlag(true);
            }
          }

          // 初始化赋值&校验
          setValueAndValidate(draftItem);
        }
      }
      setInputValue(inputValueInit);
      setInitFlag(true);
    } else {
      setInitFlag(true);
    }
  }, [draftData[widgetKey], respondentsGroup]);

  useEffect(() => {
    if (updateData) {
      dispatch(resetInitRequired({}));
      setValueAndValidate(updateData);
    }
  }, [updateData]);

  useEffect(() => {
    if (attributeData && initFlag) {
      dispatch(setProcessRate({ validateResult, attributeData }));
    }
  }, [updateData, validateResult[widgetKey], initFlag]);

  // hidden为true时，需要删除当前组件的值
  useEffect(() => {
    if (initFlag && hidden) {
      if (multiValue) {
        setMultiValue(null);
      }
      if (inputValue) {
        setInputValue(null);
      }
    }
  }, [hidden, initFlag, multiValue, inputValue])

  const setValueAndValidate = (paramData) => {
    dispatch(setGeneralData({ id: widgetKey, data: paramData }));
    dispatch(updateCurrentSubject({ data:widgetKey }));
    dispatch(setGeneralMultiData({ id: widgetKey, respondentCode: currentRespondentCode, data: paramData }));
    // 数据校验
    const widgetValidate = validateFun(rules, paramData);
    let otherValidate;
    paramData.map((item, i) => {
      if (other.includes(item.option)) {
        otherValidate = { ...otherValidate, [item.option]: validateFun(otherRules[item.option], item.value, true) };
      }
    });
    const validateData = Object.assign({}, widgetValidate, { otherResult: otherValidate });
    dispatch(setValidateResult({ id: widgetKey, data: validateData }));
  }

  const handleOnChange = (e, val) => {
    let newVal;
    if (multiValue) {
      if (!multiValue.includes(val)) {
        newVal = [...multiValue, val];
        if (exclusive) {
          if (val === exclusive) {
            newVal = [exclusive];
          } else {
            if (newVal.includes(exclusive)) {
              const index = newVal.indexOf(exclusive);
              newVal.splice(index, 1);
            }
          }
        }
      } else {
        const index = multiValue.indexOf(val);
        // 不能直接设置，否则导致对象引用，发生报错
        newVal = [...multiValue];
        newVal.splice(index, 1);
      }
    } else {
      newVal = [val];
    }

    setMultiValue(newVal);

    // 记录上次选择的id
    if (multiValue) {
      dispatch(setPrevOptionIds({ data: multiValue }));
    }
    // 记录本次选择的id
    dispatch(setCurOptionIds({ data: newVal }));

    if (rules.controlRegular && rules.controlRegular.max) {
      if (newVal.length < Number(rules.controlRegular.max)) {
        setDisabledFlag(false);
      } else {
        setDisabledFlag(true);
      }
    }
    const data = newVal.map((item, i) => {
      return {
        option: item,
        value: (inputValue && inputValue[item]) ? inputValue[item] : ''
      }
    });
    setUpdateData(data);

    // 每次选中这个选项都清空其对应的输入框
    const updateData = { ...inputValue, [val]: '' };
    setInputValue(updateData);
    const checked = {
      option: val,
      status: e.target.checked
    }
    if (effectOption.length > 0) {
      effectFun(checked);
    }

  }

  const handleChangeInput = (e, optionKey) => {
    const updateData = { ...inputValue, [optionKey]: e.length > 2000 ? e.slice(0,2000) : e };
    setInputValue(updateData);
  }
  const handleBlur = (v) => {
    const data = multiValue.map((item, i) => {
      return {
        option: item,
        value: inputValue?.[item] ? inputValue[item]?.replace(/(\s*$)/g, '') : ''
      }
    });
    setUpdateData(data);
  }

  // 显隐逻辑处理
  const effectFun = (option) => {
    // 刚进来的时候没有status
    if (option.status === undefined) {
      if (effectOption.includes(option)) {
        effect[option].map(item => {
          const { hidden, effectId } = item;
          const data = {
            hidden: hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effectId, data: data }));
        })
      }
    }

    // 只对本次修改的选项做属性修改
    if (option.status) {
      if (effectOption.includes(option.option)) {
        effect[option.option].map((item, i) => {
          const { hidden, effectId } = item;
          const data = {
            hidden: hidden,
            currentRespondentCode: currentRespondentCode
          }
          dispatch(updateAttributeData({ id: effectId, data: data }));
        })
      }
    }

    if (!option.status) {
      let lastEffectId = [];
      // 记录本次选项操作之前的选项集所影响的题目id，这些题目不修改其属性
      multiValue.map((itemOpt, j) => {
        if (effectOption.includes(itemOpt) && itemOpt !== option.option) {
          effect[itemOpt].map((item, i) => {
            lastEffectId.push(item.effectId);
          })
        }
      });
      if (effectOption.includes(option.option)) {
        effect[option.option].map((item, i) => {
          const { hidden, effectId } = item;
          const data = {
            hidden: !hidden,
            currentRespondentCode: currentRespondentCode
          }
          // 剔除其他选项影响的题目id
          if (!lastEffectId.includes(effectId)) {
            dispatch(updateAttributeData({ id: effectId, data: data }));
          }
        })
      }
    }
  }

  // 校验方法
  const validateFun = (rule, content, other = false) => {
    const { max, min, controlRegular, required } = rule;
    const msgArr = [
      intl.t('亲，本题要求最少选择') + ' ' + controlRegular?.min + ' ' + intl.t('个选项~'),
      intl.t('亲，本题要求最少选择') + ' ' + controlRegular?.min + ' ' + intl.t('个选项，最多选择') + ' ' + controlRegular?.max + ' ' + intl.t('个选项~'),
      intl.t('亲，此项必填的哈~')
    ]

    if (controlRegular) {
      if (controlRegular.min) {
        if (content.length < Number(controlRegular.min)) {
          if (controlRegular.max) {
            return {
              error: false,
              msg: msgArr[1]
            }
          } else {
            return {
              error: false,
              msg: msgArr[0]
            }
          }
        }
      }
    }

    if (max && max !== 0) {
      if (content.length > max) {
        return {
          error: false,
          msg: msgArr[2]
        }
      }
    }

    if (min && min !== 0) {
      if (content.length < min) {
        return {
          error: false,
          msg: msgArr[2]
        }
      }
    }

    if (required) {
      if (!content || content.length === 0) {
        return {
          error: false,
          msg: msgArr[2]
        }
      }
    }
    return {
      error: true,
      msg: ''
    }
  }
  const optionItem = () => {
    return options.map((item, i) => {
      if (multiValue && (multiValue.includes(item.key))) {
        return {
          key: item.key,
          value: item.value,
          checked: true,
          des: item.des,
          bgClassName: 'checked-bg-mobile'
        }
      } else {
        return {
          key: item.key,
          value: item.value,
          checked: false,
          des: item.des,
          bgClassName: ''
        }
      }
    })

  }

  const renderRadio = () => {
    if (mDirection === 'two') {
      const optionItemArr = optionItem();
      return (
        <>
          <List>
            {
              optionItemArr.map((opt, k) => {
                const lefKey = opt;
                const rightKey = optionItemArr[k + 1];
                let leftDisabled, rightDisabled
                if (!multiValue) {
                  leftDisabled = false;
                  rightDisabled = false;
                } else {
                  if (disabledFlag && !multiValue.includes(lefKey.key)) {
                    leftDisabled = true;
                  } else {
                    leftDisabled = false;
                  }
                  if (disabledFlag && !multiValue.includes(rightKey?.key)) {
                    rightDisabled = true;
                  } else {
                    rightDisabled = false;
                  }
                }
                if (k % 2 == 0) {
                  return (
                    <div
                      key={widgetKey + lefKey.key + 'row'}
                      style={{ display: 'flex' }}
                    >
                      <div key={widgetKey + 'div left' + lefKey.key} style={{ width: '50%', paddingRight: '5px', wordBreak: 'break-all' }}>
                        <CheckboxItem
                          className={lefKey.bgClassName}
                          value={lefKey.key}
                          key={widgetKey + lefKey.key}
                          checked={lefKey.checked}
                          disabled={leftDisabled}
                          onChange={(e) => handleOnChange(e, lefKey.key)}
                          className={classnames('checkbox-item-orange', {
                            'checkbox-item-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                          })}
                        >
                          <div>
                            <div>{decodeURIComponent(lefKey.value)}</div>
                            <List.Item.Brief>{lefKey.des}</List.Item.Brief>
                          </div>
                        </CheckboxItem >
                        {
                          ((other.length > 0) &&
                            multiValue &&
                            other.includes(lefKey.key) &&
                            multiValue.includes(lefKey.key)) ?
                            renderInput(lefKey.key) : ''
                        }
                      </div>
                      {
                        rightKey ?
                          <div key={widgetKey + 'div right' + rightKey.key} style={{ width: '50%', wordBreak: 'break-all' }}>
                            <CheckboxItem
                              className={rightKey.bgClassName}
                              value={rightKey.key}
                              key={widgetKey + rightKey.key}
                              checked={rightKey.checked}
                              disabled={rightDisabled}
                              onChange={(e) => handleOnChange(e, rightKey.key)}
                              className={classnames('checkbox-item-orange', {
                                'checkbox-item-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                              })}
                            >
                              <div>
                                <div>{decodeURIComponent(rightKey.value)}</div>
                                <List.Item.Brief>{rightKey.des}</List.Item.Brief>
                              </div>
                            </CheckboxItem >
                            {
                              ((other.length > 0) &&
                                multiValue &&
                                other.includes(rightKey.key) &&
                                multiValue.includes(rightKey.key)) ?
                                renderInput(rightKey.key) : ''
                            }
                          </div> : ''
                      }
                    </div>
                  )
                }
              })
            }
          </List>
        </>
      );
    } else {
      return (
        <>
          <List>
            {
              optionItem().map((opt, k) => {
                let disabled
                if (!multiValue) {
                  disabled = false;
                } else {
                  if (disabledFlag && !multiValue.includes(opt.key)) {
                    disabled = true;
                  } else {
                    disabled = false;
                  }
                }
                return (
                  <div key={widgetKey + 'div' + opt.key}>
                    <CheckboxItem
                      className={opt.bgClassName}
                      value={opt.key}
                      key={widgetKey + opt.key}
                      checked={opt.checked}
                      disabled={disabled}
                      onChange={(e) => handleOnChange(e, opt.key)}
                      className={classnames('checkbox-item-orange', {
                        'checkbox-item-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES'
                      })}
                    >
                      <div>
                        <div>{decodeURIComponent(opt.value)}</div>
                        {
                          !((other.length > 0) && other.includes(opt.key)) ? <List.Item.Brief>{opt.des}</List.Item.Brief> : null
                        }

                      </div>
                    </CheckboxItem >
                    {
                      ((other.length > 0) &&
                        multiValue &&
                        other.includes(opt.key) &&
                        multiValue.includes(opt.key)) ?
                        renderInput(opt.key) : ''
                    }
                  </div>
                )
              })
            }
          </List>
        </>
      );
    }
  }

  const handleExpand = () => {
    setExpand(!expand);
  };

  const formatterCount = (e = '', optionKey = null) => {
    // const max = 2000;
    // const min = 10;
    const { min } = otherRules[optionKey];
    const max = otherRules[optionKey].max ? otherRules[optionKey].max : 2000;
    const count = e?.replace(/(\s*$)/g, '')?.length || 0;
    if (min && Number(min)) {
      return `${intl.t('请至少输入{num}字', { num: min })}, ${count}/${max}`;
    }
    return `${count}/${max}`;
  };

  const placeholder = (optionKey) => {
    const requiredContent = otherRules[optionKey].required ? intl.t('必填') : '';
    let desContent = '';
    options.map((item, i) => {
      if (item.key === optionKey && item.des !== '') {
        desContent = '(' + item.des + ')';
      }
    });
    return requiredContent + desContent;
  }

  const renderInput = (optionKey) => {
    return (
      <div className={classnames("radio-textarea", {
          'textarea-content-blue': surveyBusType === 'NON_PROFESSIONAL_SERVICES',
          'textarea-content-orange': surveyBusType !== 'NON_PROFESSIONAL_SERVICES'
        })}>
        <TextareaItem
          className={
            `${validateFlag ? (validateResult[widgetKey]?.otherResult ?
              (validateResult[widgetKey]?.otherResult[optionKey]?.error ? '' : 'ct-textarea-status') : '') : ''}`
          }
          key={widgetKey + 'textarea'}
          placeholder={placeholder(optionKey)}
          rows={2}
          onChange={(e) => handleChangeInput(e, optionKey)}
          onBlur={handleBlur}
          defaultValue={inputValue[optionKey]}
        />
        <p className="ct-multi-textarea-text">
          {formatterCount(inputValue[optionKey], optionKey)}
        </p>
      </div>
    )
  }
  const renderErrorMsg = () => {
    const { required } = rules;
    if (validateFlag) {
      if (validateResult[widgetKey]) {
        if (!validateResult[widgetKey].error && required) {
          return (
            <>
              <div className="error-tip">
                <ExclamationCircleFilled />
                <div style={{ paddingLeft: '5px' }}>
                  {validateResult[widgetKey].msg ? validateResult[widgetKey].msg : intl.t('亲，此项必填的哈~')}
                  {/* {intl.t(validateResult[widgetKey].msg)} */}
                </div>
              </div>
            </>
          )
        }
      }
    }
    return ''
  }

  return (
    <>
      <div
        className={`multi-div-hidden-${hidden} question-related-${related}`}
        style={{ position: 'relative' }}
        key={widgetKey}
        id={`id_${widgetKey}`}
      >
        <div
          className={'div-label'}
          style={{ fontSize: '14px' }}
        >
          <div className={`radio-div-label-${required}`}>
            <img src={Must} />
          </div>
          {
            number && hasSerialNumber ? <div className="m-number">{number}.</div> : ''
          }
          {
            labelStyle ? <div className="label-line" dangerouslySetInnerHTML={{ __html: decodeURIComponent(labelStyle) }} />
              : <div>{decodeURIComponent(label)}</div>
          }
        </div>
        <div className={`multi-content multi-content-related-${related}`}>
          {tips ? (
            <div className="mobile-question-tip">
              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && !expand ? (
                  <>
                    {decodeURIComponent(tips).length > 100 ? `${decodeURIComponent(tips).slice(0, 60)}...` : `${decodeURIComponent(tips).slice(0, 54)}...`}
                  </>
                ) : (
                  <>{decodeURIComponent(tips)}</>
                )
              }

              {
                textHeight(12, 'PingFangSC-Regular', decodeURIComponent(tips))?.height > 60 && (
                  <div onClick={handleExpand}>
                    {expand
                      ? (
                        <div className='expand-btn-container'>
                          {intl.t('收起')}
                          <CardIcon type="iconshouqi1" className="expand-icon" />
                        </div>
                      ) : (
                        <div className='expand-btn-container'>
                          {intl.t('查看全部')}
                          <CardIcon type="iconxiala2" className="expand-icon"/>
                        </div>
                      )}
                  </div>
                )
              }
            </div>
          ) : null}

          {renderRadio()}
        </div>

        {renderErrorMsg()}
      </div>
    </>
  );
};

export default multiWidgetMobile