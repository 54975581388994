const _cache = {};

// 触发事件
const emit = (name, data) => {
  let fns = _cache[name]
  if(Array.isArray(fns)) {
    fns.forEach((fn) => {
      fn(data)
    });
  }
  // window.dispatchEvent(new CustomEvent(name, { detail: data }));
}

// 监听事件
const on = (name, callback) => {
  const fns = (_cache[name] = _cache[name] || []);
  if (fns.indexOf(callback) === -1) {
    fns.push(callback);
  }
  // window.addEventListener(
  //   name,
  //   evt => callback(evt.detail, evt),
  // );
}

// 取消事件
const off = (name, callback) => {
  const fns = _cache[name];
  if (Array.isArray(fns)) {
    if (callback) {
      let index = fns.indexOf(callback)
      if(index !== -1) {
        fns.splice(index, 1);
      }
    } else {
      fns.length = 0
    }
  }
  // window.removeEventListener(
  //   name,
  //   evt => callback(evt.detail, evt),
  // );
}

export {
  emit,
  on,
  off,
}
