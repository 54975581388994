import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

	return useRegisterStyle('pagelet-work-experienced', () => [
		{
			// base
			[`.${prefixCls}`]: {
        ['.ehr-timeline-card-template']: {
          padding: '4px 4px 0px 4px',
        },

				['.work-card-container']: {
					padding: '16px 16px 12px',
					width: '230px',
					minHeight: '104px',
					borderRadius: '10px',
          position: 'relative',
					zIndex: 1,
          background: 'linear-gradient(#f0f6ff, #fff)',
          boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.02),0px 2px 6px 6px rgba(0, 0, 0, 0.02),0px 2px 6px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
          border: '2px solid #fff',

          ['.work-card-icon']: {
            position: 'absolute',
            bottom: '-4px',
            right: '8px',
						zIndex: -1,
          },

					['.work-card-school']: {
						fontFamily: 'PingFang SC',
						fontSize: '14px',
						fontWeight: 500,
						lineHeight: '22px',
						letterSpacing: 0,
						color: '#000000DE',
            overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					},

          ['.word-card-content']: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '4px',

            ['.work-card-major']: {
              fontFamily: 'PingFang SC',
              fontSize: '12px',
              fontWeight: 'normal',
              lineHeight: '18px',
              color: '#00000099',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },


					['.work-card-tag']: {
						display: 'flex',
						flexWrap: 'wrap',
						marginTop: '6px',
						
						['.tag']: {
							fontSize: '10px',
							lineHeight: '14px',
							color: '#4183D9',
							padding: '2px 4px',
							borderRadius: '4px',
							border: '1px solid rgba(92, 151, 228, 0.3)',
              marginRight: '4px',
							marginBottom: '4px',
              fontWeight: 500,
						},
					}
				}
			}
		}
	]);
};

export default useStyle;
