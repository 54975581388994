import React from 'react';
import { Field, useField, observer } from '@formily/react';
import { FormItem } from '@formily/antd-v5';
import { LangTranslate } from '@/utils';
import './index.less';

const CustomPanelFieldsComponent = observer(() => {
  const field = useField();
  const { fieldsList = [] } = field?.componentProps;
  return (
    <div className="pagelet-label-system-tag-pool-info-panel-fields-cpn-wrap">
      {fieldsList.map(item => (
        <Field
          name={item?.fieldName}
          title={item?.fieldLabel}
          component={[item?.component, item?.componentProps]}
          validator={[
            ...item?.validator,
            val =>
              /^\s+|\s+$/.test(val)
                ? Promise.reject(LangTranslate('前后不能有空格'))
                : Promise.resolve(),
          ]}
          decorator={FormItem}
        />
      ))}
    </div>
  );
});

export default CustomPanelFieldsComponent;
