import React, { useRef, useEffect, useState } from 'react';
import CardIcon from '../../cnps/SvgIcon';
import { LangTranslate } from '../../utils';

import { Tooltip } from 'antd';
import CustomModalLineChart from '../CustomModalLineChart';
import CustomModalTagHistoryList from '../CustomModalTagHistoryList';
import './index.less';

function CustomTalentMarketTimelineListLargeTextItem({
  tagInfo,
  tagPoolInfo,
  noBorder = false,
  closeHistory = () => {},
  openHistory = () => {},
  showTagScenarioLevel = false,
  isSingleItem,
}) {
  return (
    <div
      data-talent-market-list-item-no-border={noBorder}
      className="talent-market-custom-time-line-item-body-cpn-list-item-one-by-one-large"
    >
      <div>
        <div>
          <Tooltip
            destroyTooltipOnHide
            placement="topLeft"
            title={`${isSingleItem ? tagPoolInfo?.tagPoolName : tagInfo?.tagName || '-'}:`}
          >{`${isSingleItem ? tagPoolInfo?.tagPoolName : tagInfo?.tagName || '-'}:`}</Tooltip>
        </div>
        {tagInfo?.tagHistoryInfos?.length > 1 && (
          <div onClick={openHistory} style={{ display: 'flex', color: 'rgba(0, 0, 0, 0.24)' }}>
            <Tooltip
              arrow={false}
              destroyTooltipOnHide
              placement="topLeft"
              title={LangTranslate('历史记录')}
            >
              <CardIcon
                style={{
                  color: 'rgba(0, 0, 0, 0.24)',
                }}
                type="icon-biaoqianlishi"
                size={16}
                pointer
              />
            </Tooltip>
          </div>
        )}
        {tagPoolInfo?.scenarioLevel === 1 && showTagScenarioLevel && (
          <div className="talent-market-custom-time-line-item-body-cpn-list-item-scenarioLevel">
            <Tooltip destroyTooltipOnHide placement="topLeft" title={LangTranslate('集团标签')}>
              {LangTranslate('集团标签')}
            </Tooltip>
          </div>
        )}
      </div>
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-one-by-one-large-content">
        <Tooltip destroyTooltipOnHide placement="topLeft" title={tagInfo?.tagLabelValue || '-'}>
          {tagInfo?.tagLabelValue || '-'}
        </Tooltip>
      </div>
    </div>
  );
}

function CustomTalentMarketTimelineListSmallTextItem({
  tagInfo,
  tagPoolInfo,
  noBorder = false,
  closeHistory = () => {},
  openHistory = () => {},
  showTagScenarioLevel = false,
  isSingleItem,
}) {
  return (
    <div
      data-talent-market-list-item-no-border={noBorder}
      className="talent-market-custom-time-line-item-body-cpn-list-item-one-by-one-small"
    >
      <div>
        <Tooltip
          destroyTooltipOnHide
          placement="topLeft"
          title={`${isSingleItem ? tagPoolInfo?.tagPoolName : tagInfo?.tagName || '-'}`}
        >{`${isSingleItem ? tagPoolInfo?.tagPoolName : tagInfo?.tagName || '-'}`}</Tooltip>
      </div>
      <div>:</div>
      <div>
        <Tooltip destroyTooltipOnHide placement="topLeft" title={tagInfo?.tagLabelValue || '-'}>
          {tagInfo?.tagLabelValue || '-'}
        </Tooltip>
      </div>
      {tagInfo?.tagHistoryInfos?.length > 1 && (
        <div onClick={openHistory} style={{ display: 'flex', color: 'rgba(0, 0, 0, 0.24)' }}>
          <Tooltip
            arrow={false}
            destroyTooltipOnHide
            placement="topLeft"
            title={LangTranslate('历史记录')}
          >
            <CardIcon
              style={{
                color: 'rgba(0, 0, 0, 0.24)',
              }}
              type="icon-biaoqianlishi"
              size={16}
              pointer
            />
          </Tooltip>
        </div>
      )}
      {tagPoolInfo?.scenarioLevel === 1 && showTagScenarioLevel && (
        <div className="talent-market-custom-time-line-item-body-cpn-list-item-scenarioLevel">
          <Tooltip destroyTooltipOnHide placement="topLeft" title={LangTranslate('集团标签')}>
            {LangTranslate('集团标签')}
          </Tooltip>
        </div>
      )}
    </div>
  );
}
function CustomTalentMarketTimelineListPeopleItem({
  data,
  tagInfo,
  tagPoolInfo,
  noBorder = false,
  closeHistory = () => {},
  openHistory = () => {},
  showTagScenarioLevel = false,
  isSingleItem,
}) {
  const selectionEmployees = tagInfo?.selectionEmployees || [];
  const empList = tagInfo?.tagLabelValue?.split(',');
  const empInfos = empList?.map?.(empId =>
    selectionEmployees?.find(it => it?.employeeId === empId),
  );
  return (
    <div
      data-talent-market-list-item-no-border={noBorder}
      className="talent-market-custom-time-line-item-body-cpn-list-item-single-people"
    >
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-single-people-top">
        <Tooltip
          destroyTooltipOnHide
          placement="topLeft"
          title={`${isSingleItem ? tagPoolInfo?.tagPoolName : tagInfo?.tagName || '-'}:`}
        >
          {`${isSingleItem ? tagPoolInfo?.tagPoolName : tagInfo?.tagName || '-'}:`}
        </Tooltip>
        {tagInfo?.tagHistoryInfos?.length > 1 && (
          <div
            onClick={openHistory}
            style={{ display: 'flex', marginLeft: '6px', color: 'rgba(0, 0, 0, 0.24)' }}
          >
            <Tooltip
              arrow={false}
              destroyTooltipOnHide
              placement="topLeft"
              title={LangTranslate('历史记录')}
            >
              <CardIcon
                style={{
                  color: 'rgba(0, 0, 0, 0.24)',
                }}
                type="icon-biaoqianlishi"
                size={16}
                pointer
              />
            </Tooltip>
          </div>
        )}
        {tagPoolInfo?.scenarioLevel === 1 && showTagScenarioLevel && (
          <div
            style={{ marginLeft: '6px' }}
            className="talent-market-custom-time-line-item-body-cpn-list-item-scenarioLevel"
          >
            <Tooltip destroyTooltipOnHide placement="topLeft" title={LangTranslate('集团标签')}>
              {LangTranslate('集团标签')}
            </Tooltip>
          </div>
        )}
      </div>
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-single-people-emps">
        {empInfos?.map?.(emp => (
          <div className="talent-market-custom-time-line-item-body-cpn-list-item-single-people-item">
            {!!tagInfo?.tagLabelValue?.length && (
              <div className="talent-market-custom-time-line-item-body-cpn-list-item-single-people-item-avatar">
                <img
                  src={
                    emp?.avatar ||
                    '//img-ys011.didistatic.com/static/ddo_web_static/do1_yTmZShSluchXfuRLr3cQ'
                  }
                  alt="avatar"
                />
              </div>
            )}

            <div className="talent-market-custom-time-line-item-body-cpn-list-item-single-people-item-name">
              <Tooltip destroyTooltipOnHide placement="topLeft" title={emp?.employeeName || '-'}>
                {emp?.employeeName || '-'}
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export function CustomTalentMarketTimelineListSuccessorItem({
  data,
  tagInfos,
  tagPoolInfo,
  noBorder = false,
  closeHistory = () => {},
  openHistory = () => {},
  showTagScenarioLevel = false,
}) {
  const [successor, readiness] = data?.tagInfos;
  const selectionEmployees = successor?.selectionEmployees || [];
  const empList = successor?.tagLabelValue.split(',');
  const empInfos = empList?.map?.(empId =>
    selectionEmployees?.find(it => it?.employeeId === empId),
  );
  const readinessList = readiness?.tagLabelValue?.split(',') || [];
  return (
    <div className="talent-market-custom-time-line-item-body-cpn-list-item-successor">
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-successor-top-info">
        <div>
          <Tooltip destroyTooltipOnHide placement="topLeft" title={`${data?.tagPoolName || '-'}:`}>
            {`${data?.tagPoolName || '-'}:`}
          </Tooltip>
        </div>
        {successor?.tagHistoryInfos?.length > 1 && (
          <div onClick={openHistory} style={{ display: 'flex', color: 'rgba(0, 0, 0, 0.24)' }}>
            <Tooltip
              arrow={false}
              destroyTooltipOnHide
              placement="topLeft"
              title={LangTranslate('历史记录')}
            >
              <CardIcon
                style={{
                  color: 'rgba(0, 0, 0, 0.24)',
                }}
                type="icon-biaoqianlishi"
                size={16}
                pointer
              />
            </Tooltip>
          </div>
        )}
        {data?.scenarioLevel === 1 && showTagScenarioLevel && (
          <div className="talent-market-custom-time-line-item-body-cpn-list-item-scenarioLevel">
            <Tooltip destroyTooltipOnHide placement="topLeft" title={LangTranslate('集团标签')}>
              {LangTranslate('集团标签')}
            </Tooltip>
          </div>
        )}
      </div>
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-successor-emp-list">
        {empInfos?.map?.((emp, idx) => (
          <>
            {emp?.employeeName?.length > 0 ? (
              <div className="talent-market-custom-time-line-item-body-cpn-list-item-successor-emp-list-item">
                <div style={{ width: '26px', height: '26px' }}>
                  <img
                    style={{ width: '26px', height: '26px' }}
                    src={
                      emp?.avatar ||
                      '//img-ys011.didistatic.com/static/ddo_web_static/do1_yTmZShSluchXfuRLr3cQ'
                    }
                    alt="avatar"
                  />
                </div>
                <div>
                  <Tooltip
                    destroyTooltipOnHide
                    placement="topLeft"
                    title={emp?.employeeName || '-'}
                  >
                    {emp?.employeeName || '-'}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    destroyTooltipOnHide
                    placement="topLeft"
                    title={readinessList?.[idx] || '-'}
                  >
                    {readinessList?.[idx] || '-'}
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className="talent-market-custom-time-line-item-body-cpn-list-item-successor-emp-list-item">
                -
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

function CustomTalentMarketTimelineListMap({
  data = {},
  tagInfo = {},
  tagInfos = {},
  noBorder = false,
  successorTag = false,
  showTagScenarioLevel = false,
  isSingleItem = false,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  if (successorTag) {
    return (
      <>
        <CustomTalentMarketTimelineListSuccessorItem
          tagPoolInfo={data}
          data={data}
          noBorder={noBorder}
          closeHistory={() => setModalVisible(() => false)}
          openHistory={() => setModalVisible(() => true)}
          showTagScenarioLevel={showTagScenarioLevel}
        />
        {modalVisible && (
          <CustomModalTagHistoryList
            tagPoolInfo={data}
            successorTag
            isModalOpen={modalVisible}
            setIsModalOpen={setModalVisible}
            data={data}
          />
        )}
      </>
    );
  }
  if ([1].includes(tagInfo?.dataSource) && [1].includes(tagInfo?.tagType)) {
    return (
      <>
        <CustomTalentMarketTimelineListSmallTextItem
          tagPoolInfo={data}
          noBorder={noBorder}
          tagInfo={tagInfo}
          closeHistory={() => setModalVisible(() => false)}
          openHistory={() => setModalVisible(() => true)}
          showTagScenarioLevel={showTagScenarioLevel}
          isSingleItem={isSingleItem}
        />
        <CustomModalTagHistoryList
          isModalOpen={modalVisible}
          setIsModalOpen={setModalVisible}
          data={tagInfo}
        />
      </>
    );
  }
  if ([1].includes(tagInfo?.dataSource) && [2].includes(tagInfo?.tagType)) {
    return (
      <>
        <CustomTalentMarketTimelineListLargeTextItem
          tagPoolInfo={data}
          noBorder={noBorder}
          tagInfo={tagInfo}
          closeHistory={() => setModalVisible(() => false)}
          openHistory={() => setModalVisible(() => true)}
          showTagScenarioLevel={showTagScenarioLevel}
          isSingleItem={isSingleItem}
        />
        <CustomModalTagHistoryList
          isModalOpen={modalVisible}
          setIsModalOpen={setModalVisible}
          data={tagInfo}
        />
      </>
    );
  }
  if ([1].includes(tagInfo?.dataSource) && [3].includes(tagInfo?.tagType)) {
    return (
      <>
        <CustomTalentMarketTimelineListLargeTextItem
          tagPoolInfo={data}
          noBorder={noBorder}
          tagInfo={tagInfo}
          closeHistory={() => setModalVisible(() => false)}
          openHistory={() => setModalVisible(() => true)}
          showTagScenarioLevel={showTagScenarioLevel}
          isSingleItem={isSingleItem}
        />
        <CustomModalTagHistoryList
          isModalOpen={modalVisible}
          setIsModalOpen={setModalVisible}
          data={tagInfo}
        />
      </>
    );
  }
  if ([1].includes(tagInfo?.dataSource) && [4].includes(tagInfo?.tagType)) {
    return (
      <>
        <CustomTalentMarketTimelineListPeopleItem
          tagPoolInfo={data}
          data={data}
          noBorder={noBorder}
          tagInfo={tagInfo}
          closeHistory={() => setModalVisible(() => false)}
          openHistory={() => setModalVisible(() => true)}
          showTagScenarioLevel={showTagScenarioLevel}
          isSingleItem={isSingleItem}
          isSelectPeopleItem
        />
        <CustomModalTagHistoryList
          isModalOpen={modalVisible}
          setIsModalOpen={setModalVisible}
          data={tagInfo}
          isSelectPeopleItem
        />
      </>
    );
  }
  if ([2, 3].includes(tagInfo?.dataSource)) {
    if (tagInfo?.dataSource === 2) {
      return (
        <>
          <CustomTalentMarketTimelineListSmallTextItem
            tagPoolInfo={data}
            closeHistory={() => setModalVisible(() => false)}
            openHistory={() => setModalVisible(() => true)}
            noBorder={noBorder}
            tagInfo={tagInfo}
            showTagScenarioLevel={showTagScenarioLevel}
            isSingleItem={isSingleItem}
          />
          <CustomModalLineChart
            data={tagInfo}
            isModalOpen={modalVisible}
            setIsModalOpen={setModalVisible}
          />
        </>
      );
    }
    return (
      <>
        <CustomTalentMarketTimelineListSmallTextItem
          tagPoolInfo={data}
          closeHistory={() => setModalVisible(() => false)}
          openHistory={() => setModalVisible(() => true)}
          noBorder={noBorder}
          tagInfo={tagInfo}
          showTagScenarioLevel={showTagScenarioLevel}
          isSingleItem={isSingleItem}
        />
        <CustomModalTagHistoryList
          data={tagInfo}
          isModalOpen={modalVisible}
          setIsModalOpen={setModalVisible}
        />
      </>
    );
  }
  return 'notDefine';
}

function CustomTimeLineItemBodyCpnListItem(props) {
  const { data } = props;
  if (data?.tagInfos?.[0]?.successorTag) {
    return (
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-wrap">
        <CustomTalentMarketTimelineListMap
          data={data}
          tagInfos={data?.tagInfos}
          noBorder
          successorTag
          showTagScenarioLevel
        />
      </div>
    );
  }
  if (data?.tagInfos?.length === 1) {
    const curTagInfo = data?.tagInfos[0];
    return (
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-wrap">
        <CustomTalentMarketTimelineListMap
          data={data}
          tagInfos={data?.tagInfos}
          tagInfo={curTagInfo}
          showTagScenarioLevel={true}
          isSingleItem
        />
      </div>
    );
  }

  if (data?.tagInfos?.length > 1) {
    const curTagInfoList = data?.tagInfos;
    return (
      <div className="talent-market-custom-time-line-item-body-cpn-list-item-wrap">
        <div className="talent-market-custom-time-line-item-body-cpn-list-item-one-by-multiple">
          <div className="talent-market-custom-time-line-item-body-cpn-list-item-one-by-multiple-title">
            <Tooltip
              placement="topLeft"
              destroyTooltipOnHide
              title={`${data?.tagPoolName}`}
            >{`${data?.tagPoolName}:`}</Tooltip>
            {data?.scenarioLevel === 1 && (
              <div
                style={{ marginLeft: '6px' }}
                className="talent-market-custom-time-line-item-body-cpn-list-item-scenarioLevel"
              >
                <Tooltip destroyTooltipOnHide placement="topLeft" title={LangTranslate('集团标签')}>
                  {LangTranslate('集团标签')}
                </Tooltip>
              </div>
            )}
          </div>
          <div className="talent-market-custom-time-line-item-body-cpn-list-item-one-by-multiple-list-wrap">
            {curTagInfoList?.map(tagInfo => (
              <CustomTalentMarketTimelineListMap
                showTagScenarioLevel={false}
                data={data}
                tagInfos={data?.tagInfos}
                noBorder
                tagInfo={tagInfo}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomTimeLineItemBodyCpnListItem;
