import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';

const apis = manifest.apis || {};
const { request } = PageletApi;

/**
 * 管理任职
 * url: /api/archive/manager-position
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */

export const getManagerPosition = (apiHost, headers, params) => request(`${apiHost}${apis.getManagerPosition}`,
  {
    method: 'get',
    headers,
    params,
  }
);

/**
 * 日志
 * url: /api/archive/operateLog
 * method: POST
 * @param  {object}  params
 * @return {Promise}
 */

export const getOperateLog = (apiHost, headers, data) => request(`${apiHost}${apis.getOperateLog}`,
  {
    method: 'post',
    headers,
    data,
  }
);
